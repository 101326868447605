import * as constants from "../../constants/pensionskasse";
import { LOG_OUT } from "../../constants";

export interface AcceptedMutationsStatePKCockpit {
  acceptedMutationsCockpit: any;
  total: number;
  warnings: number;
  errors: number;
}

const getInitialState = () => {
  return {
    acceptedMutationsCockpit: [],
    total: 0,
    warnings: 0,
    errors: 0,
  };
};

const acceptedMutationsCockpit = (
  state: AcceptedMutationsStatePKCockpit = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.FETCHED_ACCEPTED_MUTATIONS_COCKPIT: {
      const acceptedMutations = [
        ...state.acceptedMutationsCockpit,
        ...action.payload,
      ];
      return {
        ...state,
        acceptedMutationsCockpit: acceptedMutations,
        acceptedMutationsFetched: false,
      };
    }
    case constants.FETCHED_ACCEPTED_MUTATIONS_COUNT_COCKPIT: {
      return {
        ...state,
        total: action.payload.total,
        warnings: action.payload.warnings,
        errors: action.payload.errors,
        acceptedMutationsCountFetched: false,
      };
    }
    case LOG_OUT: {
      return { ...getInitialState() };
    }
  }

  return state;
};

export default acceptedMutationsCockpit;
