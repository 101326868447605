import * as React from 'react';
import {Button, ButtonColors, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {FormattedMessage} from "react-intl";
import {ReactComponent as IconCheckmarkSlim} from "../../assets/icons/checkmark-slim.svg";
import PdfViewer from "../../../PdfViewer";
import {ReactComponent as Arrow} from "../../../../assets/icons/arrow-right-action.svg";

interface Props {
    onClose: any;
    pdfFileId: any;
    provider: any;
    pdfFilter: any;
    pdfLink?: any;
}

interface IState {
}

class SuccessModal extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className="modal">
                <div className="d-flex align-items-center justify-content-between mb-94">
                    <Button
                        className="z-index-1000"
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        onClick={this.props.onClose}>
                        <Arrow className="rotate-180 mr-16"/>
                        <FormattedMessage id="app.back" defaultMessage="Zurück"/>
                    </Button>
                </div>
                <div className="Overview">
                    <PdfViewer
                        link={this.props.pdfLink}
                        fileId={this.props.pdfFileId}
                        provider={this.props.provider}
                        filters={this.props.pdfFilter}
                        closePdf={this.props.onClose}/>
                </div>
            </div>
        );
    }
}

export default SuccessModal;
