import * as React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {RootState} from "../../../../reducers";
import {connect} from "react-redux";
import styles from "./MutationExpandableRowBottom.module.scss"
import {Button, ButtonColors, ButtonStyles, ButtonTypes, Input} from "icr-shared-components";
import {useState} from "react";

interface IProps {
    intl: any;
    loading: any;
    onCancel: any;
    onSubmit: any;
}

const MutationExpandableRowBottom = ({onSubmit, onCancel, loading}: IProps) => {

    const [text, setText] = useState("");

    const onChange = (text) => {
        setText(text);
    }

    return (
        <div className={styles.mutationExpandableRowBottom}>

            <p className="mb-12"><b>Durch Löschen dieser Mutation wird diese nicht verarbeitet.</b></p>
            <p className="mb-32">Bitte geben Sie einen Grund für die Löschung an - dieser wird dem Arbeitgeber mitgeteilt.</p>
            <Input
                type="textarea"
                maxLength={1000}
                inputWrapperClass="text-input"
                id="deleteMutationInput"
                name="deleteMutationInput"
                placeholder="Grund angeben"
                value={text}
                onChange={(name, value) => onChange(value)}
                validationMessageConfig={{config: {message: ""}, visible: false}}>
                Grund*
            </Input>

            <div className="modal-buttons">
                <Button
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={() => onCancel()}>
                    <FormattedMessage id="app.cancel" defaultMessage="Abbrechen"/>
                </Button>
                <Button
                    submit={true}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={text == "" || loading}
                    onClick={(e) => {
                        onSubmit(text)
                        setTimeout(() => {
                            setText("");
                        }, 1000);
                    }}>
                    <FormattedMessage id="app.submit" defaultMessage="Bestätigen"/>
                </Button>
            </div>

        </div>
    );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    loading: state.app.loading
})

const connector = connect(mapStateToProps, null);
export default connector(injectIntl(MutationExpandableRowBottom));
