import * as React from 'react';
import {RootState} from "../../reducers";
import {Dispatch} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";

import styles from './Multiselect.module.scss';

interface IProps {
    options: any;
    onChange: any;
    selected: any;
    selectAll: any;
    deselectAll: any;
}

interface IState {
    selected: any;
}

class Multiselect extends React.Component<IProps, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            selected: []
        };
    }

    private listRef = null;

    public componentDidMount() {
    }

    public componentWillUnmount() {
        // window.removeEventListener('keydown', this.handleKeyPress);
    }

    public componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("dropdown componenentDidUpdate", this.props.value);
    }

    handleOnChange = (e, keypress?) => {
        const value = e.target.value;
        const checked = e.target.checked;

        let selected = this.props.selected ? this.props.selected : [];

        console.log(value, checked);
        console.log(selected);

        if (keypress) {
            if (checked) {
                selected.splice(selected.indexOf(value), 1);
            } else if (!checked) {
                selected.push(value);
            }
        } else {
            if (checked && !selected.includes(value)) {
                selected.push(value);
            } else if (!checked && selected.includes(value)) {
                selected.splice(selected.indexOf(value), 1);
            }
        }


        this.props.onChange && this.props.onChange(selected)
    };

    checked = (option) => {
        return this.props.selected.includes(option.value);
    }

    render() {
        const {options, selected} = this.props;
        return (
            options.map((option, idx) => {
                return (
                    <label htmlFor={'multiSelectOption' + idx}
                           className={styles.label}>
                        <span className={styles.text}>{option.label}</span>
                        <input
                            id={'multiSelectOption' + idx}
                            className={styles.input}
                            type="checkbox"
                            value={option.value}
                            checked={this.checked(option)}
                            onChange={(e) => this.handleOnChange(e)}
                            onKeyPress={(e) => {
                                var code = e.keyCode || e.which;
                                if (code === 13) {
                                    this.handleOnChange(e, true)
                                }
                            }}/>
                        <i className={styles.checkMark}/>
                    </label>
                );

            })
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Multiselect);
