import React from "react";
import styles from "./PersonenExpandableRowTop.module.scss";
import { ReactComponent as IconEdit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as IconContact } from "../../../../assets/icons/personalien.svg";
import { ReactComponent as IconPen } from "../../../../assets/icons/pen.svg";
import { formatNumber, parseDate } from "../../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Status,
  StatusColors,
} from "icr-shared-components";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import { mutationFormTypes } from "../../../../constants";
import classNames from "classnames";
import { ReactComponent as MaleIcon } from "../../../../assets/icons/male.svg";
import { ReactComponent as FemaleIcon } from "../../../../assets/icons/female.svg";
import Tooltip from "rc-tooltip";

interface Props {
  intl: IntlShape;
  entities: any;
  insurant: Insurant;
  onEditClick: (
    insurantId: string,
    mutationFormType: string,
    message?: string
  ) => void;
  onDetailsClick: (
    insurantId: string,
    modalPageType: string,
    message?: string
  ) => void;
}

const PersonenExpandableRowTop = ({
  insurant,
  entities,
  onEditClick,
  onDetailsClick,
}: Props) => {
  const isActive = insurant?.attributes?.employee.isActive;
  const lohn =
    !!insurant.attributes.payroll?.grossSalary &&
    formatNumber(insurant.attributes.payroll.grossSalary);
  const sex =
    insurant.attributes.person.sex === 1 ? (
      <MaleIcon className="ml-1" />
    ) : insurant.attributes.person.sex === 2 ? (
      <FemaleIcon className="ml-1" />
    ) : null;
  return (
    <div className={styles.personenExpandableRowTop}>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.mobileHeader}>
          <FormattedMessage id="app.name" defaultMessage="Name" />
        </span>
        <p className="">
          <b>
            {insurant.attributes.person.name +
              " " +
              insurant.attributes.person.firstname}
            <div
              className="toggle-child"
              onClick={() =>
                onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
              }
            >
              <Tooltip
                trigger={["hover"]}
                placement="top"
                overlay={"Bearbeiten"}
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
              >
                <IconPen />
              </Tooltip>
            </div>
          </b>
        </p>
      </div>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.mobileHeader}>
          <FormattedMessage id="app.birthday" defaultMessage="Geburtstag" />
        </span>
        <p>
          {parseDate(insurant.attributes.person.birthDate, "yyyy/dd")}
          {sex}
          <div
            className="toggle-child"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </p>
      </div>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.mobileHeader}>
          <FormattedMessage
            id="app.personen.list.card.top.socialSecurityNumber"
            defaultMessage="AHV-Nr. / Vers.-Nr."
          />
        </span>
        <p>
          {insurant.attributes.person.socialSecurityNumber}
          <br />
          {insurant.attributes.employee.insEmployeeId}
        </p>
      </div>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.mobileHeader}>
          <FormattedMessage
            id="app.personen.list.card.top.grossSalary"
            defaultMessage="Lohn"
          />
        </span>
        <p>
          {lohn ? "CHF " + lohn : null}
          <div
            className="toggle-child"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </p>
      </div>
      <div className={styles.column}>
        <span className={styles.mobileHeader}>
          <FormattedMessage id="app.status" defaultMessage="Status" />
        </span>
        <Status
          text={!isActive ? "inaktiv" : "aktiv"}
          color={!isActive ? StatusColors.DISABLED : StatusColors.HIGHLIGHT}
        />
      </div>
      <div className={styles.column}>
        <Button
          className="edit-icon mr-24"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onEditClick(insurant.id, "")}
        >
          <Tooltip
            trigger={["hover"]}
            placement="top"
            overlay={"Mutation erfassen"}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
          >
            <IconEdit />
          </Tooltip>
        </Button>
        <Button
          className="contact-icon"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onDetailsClick(insurant.id, "")}
        >
          <Tooltip
            trigger={["hover"]}
            placement="top"
            overlay={"Versichertendossier"}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
          >
            <IconContact />
          </Tooltip>
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(PersonenExpandableRowTop));
