import { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view.svg";
import classNames from "classnames";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getParameterByName } from "../../../../utils/toSearchParams";
import { toastr } from "react-redux-toastr";
import { passwordReset } from "../../../../actions/arbeitgeber/user";
import { Controller, useForm } from "react-hook-form";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../utils/validationTools";
import { setLoading } from "../../../../actions";

interface Props {
  intl: any;
  history: any;
  passwordReset: any;
}

interface IState {
  username: string;
  password: string;
  passwordInputType: string;
  showValidations: boolean;
  loading: boolean;
}

function NewPassword(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const isPensionskasse = window.location.pathname.includes("/pensionskasse/");

  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      password: "",
    },
  });

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      formCustomization: {
        components: {
          password: {
            required: true,
          },
        },
        messages: {},
      },
      requiredFields: {
        password: !!getValues("password"),
      },
    });
  }, []);

  useEffect(() => {
    const token = getParameterByName("token", null);
    if (!token) {
      props.history.push("/login");
    }
  },[]);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const togglePasswordVisible = () => {
    setPasswordInputType(passwordInputType == "password" ? "text" : "password");
  };

  const onSubmit = () => {
    const password = getValues("password");
    const token = getParameterByName("token", null);

    if (token) {
      setLoading(true);
      props.passwordReset(password, token).then((res) => {
        setIsLoading(false);
        if (res !== "OK") return;
        toastr.success(
          props.intl.formatMessage({
            id: "app.newpassword",
            defaultMessage: "New password",
          }),
          props.intl.formatMessage({
            id: "app.passwordwaschanged",
            defaultMessage: "Your password was changed",
          })
        );
        setTimeout(
          () =>
            props.history.push(
              isPensionskasse ? "/pensionskasse/login" : "/login"
            ),
          1500
        );
      });
    }
  };

  return (
    <div>
      <div className="login-title">
        <FormattedMessage
          id="app.setnewpassword"
          defaultMessage="Set new password"
        />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Controller
          control={control}
          name="password"
          render={({ field: { value } }) => (
            <Input
              id="newPassword"
              name="newPassword"
              value={value}
              onChange={(name, inputValue) => {
                setValue("password", inputValue);

                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "password",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );

                setError("password", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "password",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );
                setError("password", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              validationMessageConfig={{
                visible: false,
                config: {
                  message: errors?.password?.message,
                  type: errors?.password?.type as ValidationMessageType,
                },
              }}
              type={passwordInputType}
              placeholder={props.intl.formatMessage({
                id: "app.newpassword",
                defaultMessage: "New password",
              })}
            >
              <FormattedMessage
                id="app.newpassword"
                defaultMessage="New password"
              />
              <div className="pointer" onClick={togglePasswordVisible}>
                <ViewIcon
                  className={classNames("icon-show-password", {
                    gray: passwordInputType == "password",
                  })}
                />
              </div>
            </Input>
          )}
        />
        <Button
          submit
          className="mt-80"
          isDisabled={isLoading || isDisabledButton}
          onClick={() => onSubmit()}
          type={ButtonTypes.Standard}
          color={ButtonColors.Action}
          style={ButtonStyles.Primary}
        >
          <FormattedMessage id="app.submit" defaultMessage="Bestätigen" />
        </Button>
      </form>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  passwordReset: bindActionCreators(passwordReset, dispatch),
});
const connector = connect(null, mapDispatchToProps);
export default withRouter(connector(injectIntl(NewPassword)));
