import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Input,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
} from "icr-shared-components";
import {
  prepareEmployersDropdownOptions,
  prepareMutationTypesDropdownOptions,
} from "../../../../utils/react";

export const ForReviewSideBarFilterNames = {
  forReviewMutationsNameFilter: "forReviewMutationsNameFilter",
  forReviewMutationsEventFilter: "forReviewMutationsEventFilter",
  forReviewMutationsFirmaFilter: "forReviewMutationsFirmaFilter",
};

export const AcceptedSideBarFilterNames = {
  acceptedMutationsNameFilter: "acceptedMutationsNameFilter",
  acceptedMutationsEventFilter: "acceptedMutationsEventFilter",
  acceptedMutationsFirmaFilter: "acceptedMutationsFirmaFilter",
};

export const DeletedSideBarFilterNames = {
  deletedMutationsNameFilter: "deletedMutationsNameFilter",
  deletedMutationsEventFilter: "deletedMutationsEventFilter",
  deletedMutationsFirmaFilter: "deletedMutationsFirmaFilter",
};

interface Props {
  employers: any;
  mutationTypes: any;
  mutationsEventFilter: any;
  isForReviewSideBar: boolean;
  nameInputFilter: string;
  filterInputValue: string;
  filterFunction: (name: any, value: any, date?: any, employers?: any) => void;
  mutationTypeInputName: string;
  firmInputId: string;
  firmInputName: string;
  firmInputKey: string;
  firmInputValue: any;
  forReviewMutationsOnlyWarningsFilter?: any;
  forReviewMutationsOnlyErrorsFilter?: any;
  forReviewMutationsNoWarningsErrorsFilter?: any;
  inputNameMutationsTakeoverDateFrom?: string;
  inputNameMutationsTakeoverDateUntil?: string;
  inputNameMutationsDateFrom?: string;
  inputNameMutationsDateUtil?: string;
  mutationsTakeOverDateFrom?: any;
  mutationsTakeOverDateUntil?: any;
  mutationDateFrom?: any;
  mutationDateUntil?: any;
}

const SidebarFilterForm = (props: Props) => {
  const employerOptions = prepareEmployersDropdownOptions(props.employers);
  const mutationTypeOptions = prepareMutationTypesDropdownOptions(
    props.mutationTypes
  );
  const selectedMutationType = props.mutationTypes
    ? Object.values(props.mutationTypes).find(
        (mutationType) =>
          mutationType["attributes"]["name"] === props.mutationsEventFilter
      )
    : null;

  return (
    <>
      <p className="sidebar-header">Personen suchen</p>
      <Input
        id="filterTypeInbox"
        name={props.nameInputFilter}
        key="filterTypeInbox"
        inputWrapperClass="dropdown-input filter-input"
        placeholder={"Name, Geburtstag, AHV Nr."}
        value={props.filterInputValue}
        onChange={(name, value) => props.filterFunction(name, value)}
        validationMessageConfig={null}
        type="text"
        inputWrapperStyle={{}}
        filtering
      ></Input>
      <Input
        id="filterTypeInbox"
        name={props.mutationTypeInputName}
        key="filterTypeInbox"
        inputWrapperClass="dropdown-input filter-input"
        placeholder={"Mutationsart wählen"}
        value={
          selectedMutationType
            ? selectedMutationType["attributes"]["translation"]
            : props.mutationsEventFilter
        }
        onSelected={(name, value) => {
          const mutationType = Object.values(props.mutationTypes).find(
            (mutationType) =>
              mutationType["attributes"]["translation"] === value
          );
          if (mutationType) {
            props.filterFunction(name, mutationType["attributes"]["name"]);
          } else {
            props.filterFunction(name, value);
          }
        }}
        onChange={(name, value) => props.filterFunction(name, value)}
        selectOptions={mutationTypeOptions}
        validationMessageConfig={null}
        type="text"
        inputWrapperStyle={{}}
        filtering
      ></Input>
      <Input
        id={props.firmInputId}
        name={props.firmInputName}
        key={props.firmInputKey}
        inputWrapperClass="dropdown-input filter-input"
        placeholder={"Firma wählen"}
        value={props.firmInputValue}
        onSelected={(name, value) => props.filterFunction(name, value)}
        onChange={(name, value) =>
          props.filterFunction(name, value, null, true)
        }
        selectOptions={employerOptions}
        validationMessageConfig={null}
        type="text"
        inputWrapperStyle={{}}
      ></Input>
      {props.isForReviewSideBar ? (
        <>
          <p className="sidebar-header mt-5 mb-5">Hinweisart einschränken</p>
          <Input
            checked={props.forReviewMutationsOnlyWarningsFilter}
            checkboxLabel={"Nur Warnungen"}
            name="forReviewMutationsOnlyWarningsFilter"
            onChange={(name, value) => props.filterFunction(name, value)}
            type="checkbox"
            inputWrapperClass="radio-input filter-input"
          ></Input>
          <Input
            checked={props.forReviewMutationsOnlyErrorsFilter}
            checkboxLabel={"Nur Fehlermeldungen"}
            name="forReviewMutationsOnlyErrorsFilter"
            onChange={(name, value) => props.filterFunction(name, value)}
            type="checkbox"
            inputWrapperClass="radio-input filter-input"
          ></Input>
          <Input
            checked={props.forReviewMutationsNoWarningsErrorsFilter}
            checkboxLabel={"Nur ohne Fehler/Warnungen"}
            name="forReviewMutationsNoWarningsErrorsFilter"
            onChange={(name, value) => props.filterFunction(name, value)}
            type="checkbox"
            inputWrapperClass="radio-input filter-input"
          ></Input>
        </>
      ) : (
        <>
          <p className="sidebar-header">Übernahmedatum von/bis</p>
          <div className="row">
            <div className="col-6">
              <Input
                id="filterYearInbox"
                name={props.inputNameMutationsTakeoverDateFrom}
                key="filterYearInbox"
                inputWrapperClass="w-100 filter-input"
                placeholder={"TT.MM.JJ"}
                value={props.mutationsTakeOverDateFrom}
                onChange={(name, value) =>
                  props.filterFunction(name, value, true)
                }
                validationMessageConfig={null}
                type="date"
                filtering
                inputWrapperStyle={{}}
              ></Input>
            </div>
            <div className="col-6">
              <Input
                id="filterMonthInbox"
                name={props.inputNameMutationsTakeoverDateUntil}
                key="filterMonthInbox"
                inputWrapperClass="w-100 filter-input"
                placeholder={"TT.MM.JJ"}
                value={props.mutationsTakeOverDateUntil}
                onChange={(name, value) =>
                  props.filterFunction(name, value, true)
                }
                validationMessageConfig={null}
                type="date"
                filtering
                inputWrapperStyle={{}}
              ></Input>
            </div>
          </div>
          <p className="sidebar-header">Mutationsdatum von/bis</p>
          <div className="row">
            <div className="col-6">
              <Input
                id="filterYearInbox"
                name={props.inputNameMutationsDateFrom}
                key="filterYearInbox"
                inputWrapperClass="w-100 filter-input"
                placeholder={"TT.MM.JJ"}
                value={props.mutationDateFrom}
                onChange={(name, value) =>
                  props.filterFunction(name, value, true)
                }
                validationMessageConfig={null}
                type="date"
                filtering
                inputWrapperStyle={{}}
              ></Input>
            </div>
            <div className="col-6">
              <Input
                id="filterMonthInbox"
                name={props.inputNameMutationsDateUtil}
                key="filterMonthInbox"
                inputWrapperClass="w-100 filter-input"
                placeholder={"TT.MM.JJ"}
                value={props.mutationDateUntil}
                onChange={(name, value) =>
                  props.filterFunction(name, value, true)
                }
                validationMessageConfig={null}
                type="date"
                filtering
                inputWrapperStyle={{}}
              ></Input>
            </div>
          </div>
        </>
      )}
      <Button
        className="ml-auto"
        type={ButtonTypes.Text}
        color={ButtonColors.Action}
        size={ButtonSizes.Small}
        onClick={() => props.filterFunction("clear", "")}
      >
        <FormattedMessage
          id="app.resettodefault"
          defaultMessage="Zurücksetzen"
        />
      </Button>
    </>
  );
};

export default SidebarFilterForm;
