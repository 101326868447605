import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../reducers/index';
import { Dispatch } from 'redux';
import * as actions from '../../../actions';
import { Button, ButtonColors, ButtonStyles, ButtonTypes, Input, ValidationMessageType } from "icr-shared-components";
import { Controller, useForm } from "react-hook-form";
import './FormCsvImport.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { validateInputMessage } from '../../../utils/validationTools';
import { fetchFormCustomization } from '../../../utils/form';
import formName from "../../../constants/forms/formName.json";
import { ValidationFormProps, ValidationMessage } from '../../../@types/forms/agp/formValidation';
import { prepareEmployersDropdownOptions } from '../../../utils/react';


interface Props {
    checkFormValidity: any;
    closeModal: any;
    employers: any;
    intl: any;
    loading: any;
    submitAction: any;
    showValidations: boolean;
}

function FormCsvImport(props: Props) {

    const [isDisabledButton, setIsDisabledButton] = useState(true)
    const [formConfig, setFormConfig] = useState<ValidationFormProps>({
        formCustomization: {
            components: {},
            messages: {}
        },
        requiredFields: {}
    })

    const firmaOptions = useSelector((state: RootState) => {
        return prepareEmployersDropdownOptions(
            state.app.employers
        );
    });

    const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

    useEffect(() => {
        getFetchFormCustomization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsDisabledButton(!Object.keys(formConfig.requiredFields).every(key => formConfig.requiredFields[key]))
    }, [formConfig])

    const {
        setValue,
        getValues,
        setError,
        control,
        formState: { errors },
    } = useForm({
        criteriaMode: "all",
        defaultValues: {
            upload: {
                employerId: "",
                fileName: "",
                file: null
            }
        },
    });

    const getFetchFormCustomization = async () => {
        const response = await fetchFormCustomization(globalSettings.attributes.tenantCode, formName["formName.formCsvImport"])
        if (response.data) {
            let formComponentsCustomization = {}
            response["data"].attributes?.components.map(item => formComponentsCustomization[item.id] = item.componentAttributes)
            let requiredFields = {}
            Object.keys(formComponentsCustomization).map(key => formComponentsCustomization[key].required && (requiredFields[key] = !!getValues("upload")[key]));
            setFormConfig({
                ...formConfig,
                formCustomization: {
                    components: formComponentsCustomization,
                    messages: response["data"].attributes?.translations
                },
                requiredFields
            })
        } else {
            setFormConfig({
                ...formConfig,
                formCustomization: {
                    components: {
                        file: {
                            required: true,
                        },
                        employerId: {
                            required: true,
                        },
                    },
                    messages: {}
                },
                requiredFields: {
                    file: !!getValues("upload.file"),
                    employerId: !!getValues("upload.employerId"),
                }
            })
        }
    }

    return (
        <form className="form"
            onSubmit={(e) => {
                console.log("onsubmit", e);
                e.preventDefault();
            }}>
            <fieldset disabled={false}>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Controller
                            control={control}
                            name={"upload.file"}
                            render={({ field: { value } }) => (
                                <Input
                                    isFocused
                                    type="file"
                                    maxLength={10}
                                    inputWrapperClass="file-input"
                                    hidden={formConfig.formCustomization.components["file"]?.hidden}
                                    id="fileInput"
                                    name="file"
                                    placeholder=""
                                    value={getValues("upload.fileName")}
                                    onChange={(name, value, file) => {
                                        setValue("upload.fileName", value);
                                        setValue("upload.file", file);

                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "file",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.file", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    onBlur={() => {
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "file",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.file", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    validationMessageConfig={{
                                        visible: props.showValidations,
                                        config: {
                                            message: errors?.upload?.file?.message,
                                            type: errors?.upload?.file?.type
                                        }
                                    }}
                                >
                                    <FormattedMessage id="app.uploadfile" defaultMessage="Datei hochladen" />
                                    {formConfig.formCustomization.components["file"]?.required ? ' *' : ''}
                                </Input>
                            )}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <Controller
                            control={control}
                            name={"upload.employerId"}
                            render={({ field: { value } }) => (
                                <Input
                                    type="text"
                                    label="label"
                                    inputWrapperClass="text-input"
                                    hidden={formConfig.formCustomization.components["employerId"]?.hidden}
                                    id="employerInput"
                                    name="employerId"
                                    placeholder="Firma wählen"
                                    value={props.employers && props.employers[value] ? props.employers[value]["attributes"]["name"] : value}
                                    selectOptions={firmaOptions}
                                    onSelected={(name, value) => {
                                        setValue("upload.employerId", value);

                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "employerId",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.employerId", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    onBlur={() => {
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "employerId",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.employerId", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    validationMessageConfig={{
                                        visible: props.showValidations,
                                        config: {
                                            message: errors?.upload?.employerId?.message,
                                            type: errors?.upload?.employerId?.type as ValidationMessageType
                                        }
                                    }}
                                    sort>
                                    <FormattedMessage id="app.company" defaultMessage="Firma" />
                                    {formConfig.formCustomization.components["employerId"]?.required ? ' *' : ''}
                                </Input>
                            )} />
                    </div>
                    <div className="col-12 col-sm-6">
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="modal-buttons">
                            <Button
                                onClick={props.closeModal}
                                type={ButtonTypes.Text}
                                color={ButtonColors.Action}>
                                <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
                            </Button>
                            <Button
                                submit={true}
                                isLoading={props.loading}
                                isDisabled={props.loading || isDisabledButton}
                                type={ButtonTypes.Standard}
                                color={ButtonColors.Action}
                                style={ButtonStyles.Primary}
                                onClick={(e) => props.submitAction(e, getValues("upload"))}>
                                <FormattedMessage id="app.importnow" defaultMessage="JETZT IMPORTIEREN" />
                            </Button>
                        </div>

                    </div>
                </div>
            </fieldset>
        </form>
    );
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        loading: state.app.loading,
        showValidations: state.app.showValidations
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FormCsvImport));
