import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../reducers';
import {bindActionCreators, Dispatch} from 'redux';
import * as actions from '../../../../actions';
import {Blank, Button, ButtonColors, ButtonTypes, ExpandableRow, PlusButton} from 'icr-shared-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {isRowDisabled} from '../../../../utils/react';
import PersonenExpandableRowTop from "../PersonenExpandableRowTop";
import PersonenExpandableRowBottom from "../PersonenExpandableRowBottom";
import classNames from "classnames";
import {ProcessingStates} from "../../../../models";
import {ReactComponent as PlusIcon} from "../../../../assets/icons/plus-round.svg";
import styles from "./PersonenTab.module.scss";
import {filterPersons, setInsurantsPageNo, setLoading} from "../../../../actions";
import {fetchEmployees} from "../../../../actions/arbeitgeber/insurants";


const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || '20';

interface State {
    expandedRow: any;
    filtersChanged: any;
    showMoreSpinner: any;
}

interface Props {
    intl: any;
    employers: any;
    entities: any;
    openModal: any;
    insurants: any;
    moreInsurants: boolean;
    insurantsPageNo: number;
    onEditClick: any;
    onNewEntryClick: any;
    isLoading: boolean;
    onDetailsClick: any;
    filtersChanged: any;
    actionsButton: any;

    morePadding: boolean;

    insurantsFetched: boolean;
    filterInsurants: any;
    setLoading: any;
    fetchEmployees: any;
    setInsurantsPageNo: any;
    filterByNamePerson: string;
    filterByFirmaPerson: string;
    filterByAlsoInactivePerson: boolean;
    filterByOnlyChangedPerson: boolean;
}

class PersonenTab extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            expandedRow: null,
            filtersChanged: 0,
            showMoreSpinner: false
        }
    }

    rowChanged = (expanded, row) => {
        this.setState({expandedRow: row});
        // if (expanded) {
        //     document.querySelector('.main-content-container.insurances').classList.add("expanded");
        // } else {
        //     document.querySelector('.main-content-container.insurances').classList.remove("expanded");
        // }
    }

    allEmployersCommitted = (employersObj) => {
        if (employersObj && Object.keys(employersObj).length > 0) {
            const employers = Object.values(employersObj);
            const uncommitedEmployers = employers.filter(employer => employer["attributes"]["processingState"] != 2);
            return !uncommitedEmployers.length;
        } else {
            return true;
        }
    }

    filtersAreApplied = () => {
        return !!(this.props.filterByNamePerson || this.props.filterByFirmaPerson || this.props.filterByAlsoInactivePerson || this.props.filterByOnlyChangedPerson);
    }

    clearFilters = () => {
        this.props.filterInsurants({name: "clear"});
        this.props.setInsurantsPageNo(0);
        this.props.setLoading(true);
        this.props.fetchEmployees().then(() => {
            this.props.setLoading(false);
            this.props.setInsurantsPageNo(1);
        })
    }

    render() {
        const allEmployersCommitted = this.allEmployersCommitted(this.props.employers);
        return (
            <div className="content-container">

                {!!(this.props.insurantsFetched || this.props.insurants.length) && <>
                    <div className="top-action-buttons mt-40" style={{display:"flex"}}>
                        {this.props.actionsButton()?.map(item=> item.visible && <Button
                            className={classNames({"disabled-create-insurant-button": allEmployersCommitted},"mr-40")}
                            type={ButtonTypes.Text}
                            color={ButtonColors.Action}
                            onClick={() => !allEmployersCommitted && item.action()}>
                            {item.content}
                        </Button>)}
                    </div>
                    <div className={classNames(styles.listView, {[styles.morePadding]: this.props.morePadding})}>
                        <div className={styles.listHeader}>
                            <div className={styles.column}>
                                <FormattedMessage id="app.name" defaultMessage="Name"/>
                            </div>
                            <div className={styles.column}>
                                <FormattedMessage id="app.birthday" defaultMessage="Geburtstag"/>
                            </div>
                            <div className={styles.column}>
                                <FormattedMessage id="app.personen.list.header.socialSecurityNumber" defaultMessage="AHV-Nr. / Vers.-Nr."/>
                            </div>
                            <div className={styles.column}>
                                <FormattedMessage id="app.personen.list.header.grossSalary" defaultMessage="Lohn"/>
                            </div>
                            <div className={styles.column}>
                                <FormattedMessage id="app.status" defaultMessage="Status"/>
                            </div>
                            <div className={styles.column}>
                                <div style={{width: "11.5rem"}} />
                            </div>
                        </div>

                        {this.props.insurants.length > 0 ?
                            this.props.insurants.map((insurant, index) => {
                                return <ExpandableRow
                                    className={classNames("mb-24", {transparent: false})}
                                    index={index}
                                    expandable={true}
                                    key={`insurant_row_${index}`}
                                    expanded={this.state.expandedRow}
                                    length={this.props.filtersChanged}
                                    translucent={isRowDisabled(insurant)}
                                    rowChanged={(expanded) => this.rowChanged(expanded, index)}
                                    content={<PersonenExpandableRowTop
                                                insurant={insurant}
                                                onDetailsClick={(id) => this.props.onDetailsClick(id)}
                                                onEditClick={(id, type) => this.props.onEditClick(id, type)}/>}
                                    more={<PersonenExpandableRowBottom
                                                insurant={insurant}
                                                onEditClick={(id, type)  => this.props.onEditClick(id, type)} /> }/>
                            }) :
                            <Blank
                                onButtonClick={() => this.clearFilters()}
                                // buttonText="Filter zurücksetzen"
                                showButton={this.filtersAreApplied()}
                                text={this.props.intl.formatMessage({id: "app.noinsurants", defaultMessage: "Keine Personen vorhanden"
                            })}
                            />}
                    </div>
                </>}
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        insurants: state.insurants.insurants,
        employers: state.app.employers,
        entities: state.app.entities,

        insurantsFetched: state.insurants.insurantsFetched,
        filterByNamePerson: state.insurants.filterByNamePerson,
        filterByFirmaPerson: state.insurants.filterByFirmaPerson,
        filterByAlsoInactivePerson: state.insurants.filterByAlsoInactivePerson,
        filterByOnlyChangedPerson: state.insurants.filterByOnlyChangedPerson,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        filterInsurants: val => dispatch(filterPersons(val)),
        setInsurantsPageNo: val => dispatch(setInsurantsPageNo(val)),
        fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PersonenTab));
