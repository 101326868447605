import * as React from 'react';
import {Blank, Button, ButtonColors, ButtonTypes, ExpandableRow, StatusColors, StatusShape} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from 'react-redux';
import Modal from 'react-modal';
import {RootState} from '../../../../reducers/index';
import Spinner from '../../../../components/Spinner';

import InsurantDetailsRowTop from "../InsurantDetailsRowTop";
import InsurantDetailsRowBottom from "../InsurantDetailsRowBottom";
import styles from "./InsurantDetailsModalContent.module.scss";
import InsurantDetailsMutationExpandableRowTop from "../InsurantDetailsMutationExpandableRowTop";
import MutationTypeDropdown from "../../../MutationTypeDropdown/MutationTypeDropdown";
import InsurantDetailPopup from "../InsurantDetailPopup"

interface Props {
    intl: any;
    onClose: any;
    insurantData: any;
    entities: any;
    onEditClick: any;
    insurantMutations: any;
    onPdfClick: any;
    onSubmitRevert: any;
    isLoading:boolean;
}

interface IState {
    expandedRow: boolean;
    filtersChanged: number;
    showMoreSpinner: boolean;
    insurantMutations: any;
    popupIsOpen: boolean;
    mutationId: string;
    employeeId: string;
}

class InsurantDetailsModalContent extends React.Component<Props, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            expandedRow: null,
            filtersChanged: 0,
            showMoreSpinner: false,
            insurantMutations: this.props.insurantMutations,
            popupIsOpen: false,
            mutationId: "",
            employeeId: "",
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>, snapshot?: any) {
        const {insurantMutations} = this.props;

        if (prevProps.insurantMutations !== insurantMutations && this.state.insurantMutations !== insurantMutations) {
            this.setState({insurantMutations})
        }
    }

    setPopup(value:boolean, employeeId?:string, id?:string) {
        console.log('got',value, employeeId, id)
        this.setState({popupIsOpen:value, mutationId:id, employeeId:employeeId})
    }

    onSubmit(reason?:string) {
        this.setState({popupIsOpen:false})
        this.props.onSubmitRevert({id:this.state.mutationId, employeeId:this.state.employeeId, reason})
    }

    render() {
        const {expandedRow, insurantMutations} = this.state;
        const {insurantData, entities, intl, onPdfClick, onEditClick} = this.props;

        return (
            <div>
                {this.props.isLoading && <Spinner lowZIndex relative/>}
                <Modal isOpen={this.state.popupIsOpen}
                       onRequestClose={() => this.setPopup(false)}
                       contentLabel="Small Modal"
                       className="small-modal"
                       overlayClassName="small-modal-overlay">

                    <InsurantDetailPopup
                        onSubmit={(reason)=>this.onSubmit(reason)}
                        mutationId={this.state.mutationId}
                        onCancel={() => this.setPopup(false)}/>
                </Modal>


                <div className="row mb-48 px-20">
                    <div className="col-12 col-md-6">
                        <h1 className="mb-32 mb-md-0">Aktuelle Daten</h1>
                    </div>

                    <div className="col-12 col-md-6">
                        <Button
                            className="ml-auto"
                            type={ButtonTypes.Text}
                            color={ButtonColors.Action}
                            onClick={() => onEditClick("")}>
                            <FormattedMessage id="app.makemutation" defaultMessage="Mutation vornehmen"/>
                        </Button>
                    </div>
                </div>

                <ExpandableRow
                    expandable
                    isExpanded
                    content={<InsurantDetailsRowTop insurant={insurantData}/>}
                    more={<InsurantDetailsRowBottom entities={entities} insurant={insurantData} onEditClick={onEditClick}/>}
                />

                <div className="d-flex align-items-center justify-content-between mt-58">
                    <h1><FormattedMessage id="app.history" defaultMessage="Verlauf" /></h1>

                    <MutationTypeDropdown
                        data={this.props.insurantMutations}
                        setFilteredData={value => this.setState({insurantMutations: value})}
                    />
                </div>

                <div className={styles.listView}>
                    {insurantMutations.length > 0 && (
                        <div className={styles.listHeader}>
                            <div className={styles.column}>Mutationstyp / Gültig ab</div>

                            <div className={styles.column}>
                                <span>
                                    <FormattedMessage id="app.pervValue" defaultMessage="bisher"/>
                                </span>
                                <span>
                                    <FormattedMessage id="app.new" defaultMessage="neu"/>
                                </span>
                            </div>

                            <div className={styles.column}>Mutationsdatum / Bearbeiter</div>

                            <div className={styles.column}>
                                <div style={{width: "2.4rem"}}/>
                            </div>

                            {/* <div className="column">
                                    Bearbeitungsdatum/Bearbeiter
                             </div> */}
                            {/* <div className="column">
                                    <div style={{width: "2.3rem"}} />
                            </div> */}
                        </div>
                    )}

                    {insurantMutations.length > 0 ?
                        insurantMutations.map((mutation, index) => (
                            <ExpandableRow
                                className="mb-24"
                                statusBar={mutation.attributes.isDeleted ? {
                                    text: mutation.attributes.reasonForDeletion,
                                    color: StatusColors.ERROR,
                                    shape: StatusShape.BOTTOM_ROUNDED
                                } : null}
                                content={
                                    <InsurantDetailsMutationExpandableRowTop
                                        mutation={mutation}
                                        setPopup={(item:boolean, employeeId:string, id?:string)=>this.setPopup(item,employeeId,id)}
                                        onDownloadClick={() => onPdfClick(mutation.attributes.fileId)}
                                        expanded={index == expandedRow} //the row is expanded if the expandedRow == index
                                        toggleExpand={() => this.setState({expandedRow: expandedRow == index ? null : index})}
                                    />     
                                }
                            />
                        )) : (
                            <Blank
                                onButtonClick={() => null}
                                // buttonText="Filter zurücksetzen"
                                text={intl.formatMessage({
                                    id: "app.nomutations",
                                    defaultMessage: "Keine Mutationen vorhanden"
                                })}
                            />
                        )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        isLoading: state.app.loading,
    }
}

export default connect(mapStateToProps)(injectIntl(InsurantDetailsModalContent));
