import {
    emptyStringsToNull,
    isString,
    parseToApiDate,
    removeEmptyObjects,
    removeNullProperties
} from "../../utils/react";
import {emptyMutationPayload, minimumInsurantPatchPayload, minimumInsurantPayload} from "../../constants/payloads";

export const prepareBackendValidationObject = (formObjName, data) => {
    return {
        ...data,
        [formObjName]: {
            "person": {},
            "address": {},
            "payroll": {},
            "employee": {},
            "unpaidVacation": {}
        }
    }
}

export const prepareCreateAddressPayload = (insurantId, data) => {
    return  {
        "data": {
            // "id": insurantId,
            "id": "00000000-0000-0000-0000-000000000000",
            "type": "address",
            "attributes": {
                "personId": data.insurantId,
                "validFrom": parseToApiDate(data.validFrom),
                "validUntil": parseToApiDate(data.validUntil),
                "address1": data.address1,
                "address2": data.address2,
                "address3": null,
                "address4": null,
                "phoneNumber": data.phoneNumber,
                "mobileNumber": data.mobileNumber,
                "email": data.email,
                "zipCode": data.zipCode,
                "city": data.city,
                "country": data.country,
                "processingState": 1,
                "processingStateName": "Changed",
                "id": "00000000-0000-0000-0000-000000000000"
            }
        }
    };
}

export const prepareUpdateAddressPayload = (data) => {
    return {
        "data": {
            "id": data.id,
            "type": "address",
            "attributes": {
                "validFrom": data.validFrom?.length ? parseToApiDate(data.validFrom) : null,
                "validUntil": data.validUntil?.length ? parseToApiDate(data.validUntil) : null,
                "address1": data.address1,
                "address2": data.address2,
                "zipCode": data.zipCode,
                "city": data.city,
                "country": data.country,
                "email": data.email,
                "phoneNumber": data.phoneNumber,
                "mobileNumber": data.mobileNumber,
            }
        }
    };
}

export const prepareCreatePayrollPayload = (payrollData) => {
    let data = emptyStringsToNull(payrollData?.payroll);
    if (data.grossSalary && data.activityRate) {
        data.activityRate = parseFloat(data.activityRate);
        if (isString(data.grossSalary)) {
            data.grossSalary = parseFloat(data.grossSalary.replace(/'/g, ''));
        } else {
            data.grossSalary = parseFloat(data.grossSalary);
        }
    }
    return {
        "data": {
            "id": "00000000-0000-0000-0000-000000000000",
            "type": "payroll",
            "attributes": {
                "validFrom": parseToApiDate(data.validFrom),
                "validUntil": null,
                "grossSalary": data.grossSalary,
                "bonus": data.bonus,
                "activityRate": data.activityRate,
                "id": "00000000-0000-0000-0000-000000000000"
            }
        }
    };
}

export const prepareUpdatePayrollPayload = (data) => {
    data = emptyStringsToNull(data);
    if (data.grossSalary && data.activityRate) {
        data.activityRate = parseFloat(data.activityRate);
        if (isString(data.grossSalary)) {
            data.grossSalary = parseFloat(data.grossSalary.replace(/'/g, ''));
        } else {
            data.grossSalary = parseFloat(data.grossSalary);
        }
    }
    return {
        "data": {
            "id": data.id,
            "type": "payroll",
            "attributes": {
                "validFrom": data.validFrom?.length ? parseToApiDate(data.validFrom) : null,
                "validUntil": data.validUntil?.length ? parseToApiDate(data.validUntil) : null,
                "grossSalary": data.grossSalary,
                "bonus": data.bonus,
                "activityRate": data.activityRate,
            }
        }
    };
}

export const prepareCreateUnpaidvacationPayload = (data) => {
    data = emptyStringsToNull(data);
    return {
        "data": {
            "id": data.id,
            "type": "unpaid-vacation",
            "attributes": {
                "validFrom": parseToApiDate(data.validFrom),
                "validUntil": parseToApiDate(data.validUntil),
                "contributionCoverage": data.contributionCoverage
            }
        }
    };
}

export const prepareUpdateUnpaidvacationPayload = (data) => {
    data = emptyStringsToNull(data);
    return {
        "data": {
            "id": data.id,
            "type": "unpaid-vacation",
            "attributes": {
                "validFrom": data.validFrom?.length ? parseToApiDate(data.validFrom) : null,
                "validUntil": data.validUntil?.length ? parseToApiDate(data.validUntil) : null,
                "contributionCoverage": data.contributionCoverage,
            }
        }
    };
}
export const preparePatchMutationPayload = (data, formObj) => {
    let payload = emptyMutationPayload;
    payload.data = data;
    Object.keys(formObj).forEach((formObjName) => {
        if (payload.data.attributes?.values?.[formObjName] && formObj[formObjName]) {
            payload.data.attributes.values[formObjName] = {...formObj[formObjName]};
        }
    })
    payload = emptyStringsToNull(payload);
    let grossSalary = payload.data.attributes?.values?.payroll?.grossSalary;
    let activityRate = payload.data.attributes?.values?.payroll?.activityRate;
    if (grossSalary && activityRate) {
        activityRate = parseFloat(activityRate);
        if (isString(grossSalary)) {
            grossSalary = parseFloat(grossSalary.replace(/'/g, ''));
        } else {
            grossSalary = parseFloat(grossSalary);
        }
        payload.data.attributes.values.payroll.grossSalary = grossSalary;
        payload.data.attributes.values.payroll.activityRate = activityRate;
    }
    if (payload.data?.attributes?.employerId) {
        delete payload.data?.attributes?.employerId;
    }
    delete payload.data.attributes.fileId;
    delete payload.data.attributes.eventDate;
    delete payload.data.attributes.timeStamp;
    delete payload.data.attributes.mutationType;
    delete payload.data.attributes.processingStateName;
    delete payload.data.attributes.processingState;
    delete payload.data.attributes.userId;
    delete payload.data.attributes.username;
    delete payload.data.attributes.firstname;
    delete payload.data.attributes.name;
    delete payload.data.attributes.socialSecurityNumber;
    return payload;
}

export const prepareCreateNewInsurantPayload = (data) => {
    // const payload = minimumInsurantPayload;
    let payload = {"data": {
        "type": "insurant",
        "attributes": {
            "person": {
                "name": null,
                "firstname": null,
                "birthDate": null,
                "socialSecurityNumber": null,
                "sex": null,
                "civilStatus": null,
                "contactName": null,
                "contactFirstname": null,
                "contactaddress": null,
                "contactPhoneNumber": null,
                "contactEmail": null,
                "dateOfDeath": null,
                "divorceDate": null,
                "marriageDate": null
            },
            "address": {
                "validFrom": null,
                "validUntil": null,
                "address1": null,
                "zipCode": null,
                "city": null,
                "country": null
            },
            "payroll": {
                "validFrom": null,
                "grossSalary": null,
                "activityRate": null,
            },
            "employee": {
                "employerId": null,
                "entryDate": null,
                "unemployabilityDate": null,
                "quittingDate": null,
                "retirementDate": null
            },
            "Payoutaddress": null,
            "Type": "insurant"
        },
        "relationships": {},
        "links": {}
    }}
    payload.data.attributes.person = {...payload.data.attributes.person, ...data.person};
    payload.data.attributes.address = {...payload.data.attributes.address, ...data.address};
    payload.data.attributes.payroll = {...payload.data.attributes.payroll, ...data.payroll};
    payload.data.attributes.employee = {...payload.data.attributes.employee, ...data.employee};
    payload.data.attributes.person.birthDate = parseToApiDate(payload.data.attributes.person.birthDate);
    payload.data.attributes.address.validFrom = parseToApiDate(payload.data.attributes.address.validFrom);
    payload.data.attributes.payroll.validFrom = parseToApiDate(payload.data.attributes.payroll.validFrom);
    payload.data.attributes.employee.entryDate = parseToApiDate(payload.data.attributes.employee.entryDate);
    let grossSalary = payload.data.attributes?.payroll?.grossSalary;
    let activityRate = payload.data.attributes?.payroll?.activityRate;
    if (grossSalary && activityRate) {
        activityRate = parseFloat(activityRate);
        if (isString(grossSalary)) {
            grossSalary = parseFloat(grossSalary.replace(/'/g, ''));
        } else {
            grossSalary = parseFloat(grossSalary);
        }
        payload.data.attributes.payroll.grossSalary = grossSalary;
        payload.data.attributes.payroll.activityRate = activityRate;
    }
    return emptyStringsToNull(payload);
}

export const preparePatchInsurantPayload = (data) => {
    const payload = minimumInsurantPayload;
    payload.data.attributes = data;
    payload.data.attributes?.person?.birthDate && (payload.data.attributes.person.birthDate = parseToApiDate(payload.data.attributes.person.birthDate));
    payload.data.attributes?.person?.marriageDate && (payload.data.attributes.person.marriageDate = parseToApiDate(payload.data.attributes.person.marriageDate));
    payload.data.attributes?.person?.divorceDate && (payload.data.attributes.person.divorceDate = parseToApiDate(payload.data.attributes.person.divorceDate));
    payload.data.attributes?.person?.dateOfDeath && (payload.data.attributes.person.dateOfDeath = parseToApiDate(payload.data.attributes.person.dateOfDeath));
    payload.data.attributes?.employee?.entryDate && (payload.data.attributes.employee.entryDate = parseToApiDate(payload.data.attributes.employee.entryDate));
    payload.data.attributes?.employee?.unemployabilityDate && (payload.data.attributes.employee.unemployabilityDate = parseToApiDate(payload.data.attributes.employee.unemployabilityDate));
    payload.data.attributes?.employee?.quittingDate && (payload.data.attributes.employee.quittingDate = parseToApiDate(payload.data.attributes.employee.quittingDate));
    payload.data.attributes?.employee?.retirementDate && (payload.data.attributes.employee.retirementDate = parseToApiDate(payload.data.attributes.employee.retirementDate)); 
    if (payload.data.attributes.employee) {
        delete payload.data.attributes.employee["payoutAddress"];
        delete payload.data.attributes.employee["payoutZIP"];
        delete payload.data.attributes.employee["payoutCity"];
        delete payload.data.attributes.employee["payoutIBAN"];
        delete payload.data.attributes.employee["birthDate"];
    }
    // if (!payload.data.attributes.payoutAddress) {
    //     // @ts-ignore
    //     payload["data"]["attributes"]["payoutAddress"] = {};
    // }
    return emptyStringsToNull(removeEmptyObjects(removeNullProperties(payload)));
}
