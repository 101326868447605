import * as constants from "../../constants/pensionskasse";
import { LOG_OUT } from "../../constants";

export interface AcceptedMutationsStatePK {
  acceptedMutations: any;
  acceptedMutationsPageNo: number;
  moreAcceptedMutations: boolean;
  acceptedMutationsFetched: boolean;
  acceptedMutationsCountFetched: boolean;
  acceptedMutationsNameFilter: string;
  acceptedMutationsEventFilter: string;
  acceptedMutationsFirmaFilter: string;
  acceptedMutationsTakeoverDateFrom: string;
  acceptedMutationsTakeoverDateUntil: string;
  acceptedMutationsMutationDateFrom: string;
  acceptedMutationsMutationDateUntil: string;
  acceptedMutationsProgress: any;
  total: number;
  warnings: number;
  errors: number;
}

const getInitialState = () => {
  return {
    acceptedMutations: [],
    acceptedMutationsPageNo: 0,
    moreAcceptedMutations: true,
    acceptedMutationsFetched: false,
    acceptedMutationsCountFetched: false,
    acceptedMutationsNameFilter: "",
    acceptedMutationsEventFilter: "",
    acceptedMutationsFirmaFilter: "",
    acceptedMutationsTakeoverDateFrom: "",
    acceptedMutationsTakeoverDateUntil: "",
    acceptedMutationsMutationDateFrom: "",
    acceptedMutationsMutationDateUntil: "",
    acceptedMutationsProgress: [],
    total: 0,
    warnings: 0,
    errors: 0,
  };
};

// console.log("app.ts", getInitialState());

const acceptedMutations = (
  state: AcceptedMutationsStatePK = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.FETCHED_ACCEPTED_MUTATIONS_BY_PROGRESS: {
      const newItems = action.payload?.filter(
        (item) => !state.acceptedMutations.some((rm) => rm.id === item.id)
      );
      const newAcceptedMutations = newItems.concat(state.acceptedMutations);
      return {
        ...state,
        acceptedMutations: newAcceptedMutations,
      };
    }

    case constants.FETCHED_ACCEPTED_MUTATIONS: {
      const acceptedMutations =
        state.acceptedMutationsPageNo == 0 || action.page == 0
          ? [...action.payload.data]
          : [...state.acceptedMutations, ...action.payload.data];
      const moreAcceptedMutations = !!action.payload.links.next;
      return {
        ...state,
        acceptedMutations: acceptedMutations,
        acceptedMutationsFetched: true,
        moreAcceptedMutations,
      };
    }

    case constants.SET_ACCEPTED_MUTATIONS_PAGE_NO: {
      return {
        ...state,
        acceptedMutationsPageNo: action.payload,
      };
    }

    case constants.FETCHED_ACCEPTED_MUTATIONS_COUNT: {
      return {
        ...state,
        total: action.payload.total,
        warnings: action.payload.warnings,
        errors: action.payload.errors,
        acceptedMutationsCountFetched: true,
      };
    }

    case constants.FETCHED_ACCEPTED_MUTATIONS_PROGRESS: {
      return { ...state, acceptedMutationsProgress: action.payload };
    }

    case constants.SET_ACCEPTED_MUTATIONS_FETCHED: {
      return { ...state, acceptedMutationsFetched: false };
    }

    case constants.SET_ACCEPTED_MUTATIONS_FILTERING: {
      switch (action.payload.name) {
        case "forReviewMutationsNameFilter":
        case "deletedMutationsNameFilter":
        case "acceptedMutationsNameFilter":
          return {
            ...state,
            acceptedMutationsNameFilter: action.payload.value,
          };
        case "forReviewMutationsEventFilter":
        case "deletedMutationsEventFilter":
        case "acceptedMutationsEventFilter":
          return {
            ...state,
            acceptedMutationsEventFilter: action.payload.value,
          };
        case "forReviewMutationsFirmaFilter":
        case "deletedMutationsFirmaFilter":
        case "acceptedMutationsFirmaFilter":
          return {
            ...state,
            acceptedMutationsFirmaFilter: action.payload.value,
          };
        case "acceptedMutationsTakeoverDateFrom":
          return {
            ...state,
            acceptedMutationsTakeoverDateFrom: action.payload.value,
          };
        case "acceptedMutationsTakeoverDateUntil":
          return {
            ...state,
            acceptedMutationsTakeoverDateUntil: action.payload.value,
          };
        case "acceptedMutationsMutationDateFrom":
          return {
            ...state,
            acceptedMutationsMutationDateFrom: action.payload.value,
          };
        case "acceptedMutationsMutationDateUntil":
          return {
            ...state,
            acceptedMutationsMutationDateUntil: action.payload.value,
          };
        default: {
          return {
            ...state,
            acceptedMutationsNameFilter: "",
            acceptedMutationsEventFilter: "",
            acceptedMutationsFirmaFilter: "",
            acceptedMutationsTakeoverDateFrom: "",
            acceptedMutationsTakeoverDateUntil: "",
            acceptedMutationsMutationDateFrom: "",
            acceptedMutationsMutationDateUntil: "",
          };
        }
      }
    }

    case LOG_OUT: {
      return { ...getInitialState() };
    }
  }

  return state;
};

export default acceptedMutations;
