import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers/index';
import { Dispatch } from 'redux';
import * as actions from '../../actions/';
import './Drawer.scss';
import classNames from 'classnames';

interface State {
}

interface Props {
  opened: boolean;
}

class Drawer extends React.Component<Props, State> {
  constructor(props: any) {
    console.log("Drawer constructor called");

    super(props);
  }

  componentDidMount() {
    console.log('Drawer app did mount', this.props);
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className={classNames('drawerContainer', {
        opened: this.props.opened
      })}>
        <div className="drawerContent">
            {this.props.opened && this.props.children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
  }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
