import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers/index";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../actions/";
import styles from "./PdfViewer.module.scss";
import Spinner from "../Spinner";
import { logOutLocally } from "../../actions/";
import { toastr } from "react-redux-toastr";
import {
  fetchInsurantsPdf,
  fetchInvoicesPdf,
  fetchMutationsPdf,
  fetchPdfByFileId,
} from "../../actions/arbeitgeber/pdf";

import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

interface State {
  loading: boolean;
}

interface Props {
  fileId?: string;
  provider?: string;
  filters?: string;
  link?: string;
  closePdf?: () => void;
  logOutLocally: () => void;
  token: string;
}

export enum PdfProviders {
  INSURANTS = "insurants",
  MUTATIONS = "mutations",
  INVOICES = "invoices",
}

const PdfViewer: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pdfFile, setPdfFile] = useState("");

  useEffect(() => {
    setLoading(true);

    if (props.link) {
      applyHtml(props.link);
      //   fetchPdfByLink(props.link)
      //     .then((res) => {
      //       if (res.status == 200) {
      //         res.text().then((data) => applyHtml(data));
      //       } else {
      //         props.closePdf();
      //         if (res.status == 401) {
      //           logOutLocally();
      //         }
      //         res.json().then((err) => {
      //           if (err?.errors?.length) {
      //             err?.errors?.forEach((err) => {
      //               toastr.error(err["title"], err["detail"] || "");
      //             });
      //           }
      //         });
      //       }
      //     })
      //     .catch((err) => {
      //       props.closePdf();
      //       toastr.error("Error", "There was an issue!");
      //     });
    } else {
      if (props.provider == PdfProviders.MUTATIONS) {
        applyHtml(fetchMutationsPdf(props.filters));
      } else if (props.provider == PdfProviders.INSURANTS) {
        applyHtml(fetchInsurantsPdf(props.filters));
      } else if (props.provider == PdfProviders.INVOICES) {
        applyHtml(fetchInvoicesPdf());
      } else if (props.fileId) {
        applyHtml(fetchPdfByFileId(props.fileId));
      }
    }
  }, []);

  const applyHtml = (html) => {
    // if (html?.errors?.length) {
    //   props.closePdf();
    // }
    // setLoading(false);
    // if (pdfViewerRef.current) {
    //   (window as any).jQuery(pdfViewerRef.current).html(html);
    // }
    setPdfFile(html);
    setLoading(false);
  };

  const renderToolbar = (Toolbar: (props: any) => any) => (
    <Toolbar>
      {(slots: any) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div style={{ alignItems: "center", display: "flex" }}>
            <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToPreviousPage />
            </div>
            <div
              style={{
                padding: "0px 2px",
                color: "white",
                alignItems: "center",
                display: "flex",
              }}
            >
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>

            <div
              style={{ alignItems: "center", display: "flex", margin: "auto" }}
            >
              <div style={{ padding: "0px 2px" }}>
                <ZoomOut />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <Zoom />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <ZoomIn />
              </div>
            </div>

            <div style={{ padding: "0px 2px" }}>
              <Download />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Print />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
  });

  const defaultScale =
    window.innerHeight < 1060 ? 1.0 : SpecialZoomLevel.PageFit;

  return loading ? (
    <Spinner relative pdf />
  ) : (
    <div className={styles.pdfViewerContainer}>
      <Viewer
        theme="dark"
        plugins={[defaultLayoutPluginInstance]}
        defaultScale={defaultScale}
        onDocumentLoad={(e) => {}}
        httpHeaders={{ Authorization: "Bearer " + props.token }}
        fileUrl={pdfFile}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  ...ownProps,
  ...state.app,
  token: state.app.authentication.token,
});
const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
  logOutLocally: bindActionCreators(logOutLocally, dispatch),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PdfViewer);
