import apiCaller from "../../utils/api";
import {ACTION, wrap} from "../index";
import * as constants from '../../constants';
import {invoicesQueryParams} from "../../utils/toSearchParams";
import api from "../../utils/api";

export const setInvoicesFetched = (payload: any): ACTION => ({
    type: constants.SET_INVOICES_FETCHED,
    payload
});

export const fetchInvoices = (length, page) => {
    const params = invoicesQueryParams(length, page);
    return async (dispatch) => {
        return api.get(`invoices${params}`)
            .then(response => {
                dispatch({"type": constants.FETCHED_INVOICES, "payload": response['data']});
                return response;
            }).catch(err => err);
    }
};

export const fetchOpenInvoices = () => {
    return async (dispatch) => {
        return api.get(`invoices?page=size:10,page:0&filter=status[eq]:0`)
            .then(response => {
                dispatch({"type": constants.FETCHED_OPEN_INVOICES, "payload": response['data']});
                return response;
            }).catch(err => err);
    }
};
