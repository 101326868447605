import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import FormSalary from "../../../Forms/FormSalary";
import styles from "./NewPayrollModalContent.module.scss";
import { Blank, ExpandableRow } from "icr-shared-components";
import PayrollExpandableRowTop from "./PayrollExpandableRowTop";

interface Props {
  payrolls: any;
  intl: IntlShape;
  initialData: any;
  closeModal: () => void;
  disabled: boolean;
  isDirty: boolean;
  submitAction: (event, value) => void;
  history: any;
  insurantMutations: any;
  handlePayrollAddEditOrRemoveModal: (payroll, mutationFormTypes) => void;
}

function NewPayrollModalContent({
  payrolls,
  intl,
  handlePayrollAddEditOrRemoveModal,
  ...props
}: Props) {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles["salary-title"]}>
        <FormattedMessage
          id="app.modals.title.newPayroll.salary"
          defaultMessage="Arbeitspensum/Lohn"
        />
      </h1>

      <div className={styles["form-wrapper"]}>
        <FormSalary {...props} />
      </div>

      <h1 className={styles["course-title"]}>
        <FormattedMessage
          id="app.modals.title.newPayroll.course"
          defaultMessage="Verlauf"
        />
      </h1>

      <div>
        <div className={styles["list-header"]}>
          <div className={styles.column}>
            <FormattedMessage
              id="app.payrolls.list.header.validFrom"
              defaultMessage="Gültig ab"
            />
          </div>
          <div className={styles.column}>
            <FormattedMessage
              id="app.payrolls.list.header.validUntil"
              defaultMessage="Gültig bis"
            />
          </div>
          <div className={styles.column}>
            <FormattedMessage
              id="app.payrolls.list.header.grossSalary"
              defaultMessage="AHV-Jahreslohn"
            />
          </div>
          <div className={styles.column}>
            <FormattedMessage
              id="app.payrolls.list.header.activityRate"
              defaultMessage="Beschäftigungsgrad"
            />
          </div>
          <div className={styles.column}>
            <div style={{ width: "7rem" }} />
          </div>
        </div>

        {payrolls.length ? (
          payrolls.map((payroll) => (
            <ExpandableRow
              className="mb-24"
              content={
                <PayrollExpandableRowTop
                  payroll={payroll}
                  handlePayrollAddEditOrRemoveModal={(mutationFormTypes) =>
                    handlePayrollAddEditOrRemoveModal(
                      payroll,
                      mutationFormTypes
                    )
                  }
                />
              }
            />
          ))
        ) : (
          <Blank
            text={intl.formatMessage({
              id: "app.payrolls.list.emptyMessage",
              defaultMessage: "Keine Löhne vorhanden",
            })}
          />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {};
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(NewPayrollModalContent));
