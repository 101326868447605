import api from "../../utils/api";
import * as constants from "../../constants/";

export const fetchUnpaidVacations = (insurantId) => {
    return async (dispatch) => {
        return api.get(`insurants/${insurantId}/unpaid-vacations/`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_UNPAIDVACATIONS, "payload": response['data'] });
                return response;
            })
            .catch(err => err);
    }
};

export const createUnpaidVacation = (insurantId, data) => {
    return async (dispatch) => {
        return api.post(`insurants/${insurantId}/unpaid-vacations/`, data)
            .then(response => response)
            .catch(err => err);
    }
};

export const patchUnpaidVacation = (insurantId, unpaidVacationId, data) => {
    return async (dispatch) => {
        return api.patch(`insurants/${insurantId}/unpaid-vacations/${unpaidVacationId}/`, data)
            .then(response => response)
            .catch(err => err);
    }
};

export const deleteUnpaidVacation = (insurantId, unpaidVacationId) => {
    return async (dispatch) => {
        return api.delete(`insurants/${insurantId}/unpaid-vacations/${unpaidVacationId}/`)
            .then(response => response)
            .catch(err => err);
    }
};
