import React from "react";
import styles from "./CompanyDetailsRowTop.module.scss";
import {injectIntl} from "react-intl";
import {ReactComponent as EmployerIcon} from "../../../../assets/icons/arbeitgeber.svg";
import {TriggerPopup} from "icr-shared-components";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface Props extends RouteComponentProps{
    intl: any;
    history: any;
    company: any;
}

const CompanyDetailsRowTop = ({company, history}: Props) => {
    return (
        <div className={styles.companyDetailsRowTop}>
            <div className={styles.column}>
                <span className={styles.headerText}>
                    Organisatorische Einheit / Arbeitgeber
                </span>
                <p><EmployerIcon className="mr-12" /> {company.attributes.name}</p>
            </div>
            <div className={styles.column}>
                <span className={styles.headerText}>Arbeitgeber Nr.</span>
                <p>{company.attributes.extEmployerId}</p>
            </div>
            <div className={styles.column}>
                <span className={styles.headerText}>Kassen Nr.</span>
                <p>{company.attributes.extParentId}</p>
            </div>
            <div className={styles.column}>
                <span className={styles.headerText}>Bemerkung</span>
                <p>{company.attributes.description}</p>
            </div>

            <TriggerPopup
                position={window.innerWidth < 767 ? "left" : null}
                classNamesPopup={styles.triggerPopup}
                content={
                    <div className={styles.triggerPopupItem}
                        onClick={() => history.push("/")}>
                        Versicherte anzeigen
                    </div>}
                trigger="click" />
        </div>
    )
}


export default withRouter(injectIntl(CompanyDetailsRowTop))
