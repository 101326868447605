import api from "../../utils/api";
import * as constants from '../../constants/pensionskasse';

export const fetchAddresses = (insurantId) => {
    return async (dispatch) => {
        return api.get(`insurants/${insurantId}/addresses/`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_ADDRESSES, "payload": response['data'] });
            }).catch(err => err);
    }
};

export const createAddress = (insurantId, data) => {
    return async (dispatch) => {
        return api.post(`insurants/${insurantId}/addresses/`, data)
            .then(response => {
                dispatch({ "type": constants.FETCHED_ADDRESSES, "payload": response['data'] });
            }).catch(err => err);
    }
};

export const patchAddress = (insurantId, addressId, data) => {
    return async (dispatch) => {
        return api.patch(`insurants/${insurantId}/addresses/${addressId}/`, data)
            .then(response => {
                dispatch({ "type": constants.FETCHED_ADDRESSES, "payload": response['data'] });
            }).catch(err => err);
    }
};

export const deleteAddress = (insurantId, addressId) => {
    return async (dispatch) => {
        return api.delete(`insurants/${insurantId}/addresses/${addressId}/`)
            .then(response => response)
            .catch(err => err);
    }
};
