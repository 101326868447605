import * as React from 'react';
import {Button, ButtonColors, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {injectIntl} from "react-intl";
import {RootState} from "../../../../reducers";
import {connect} from "react-redux";
import {inspect} from "util";
import styles from "./WarningsPopup.module.scss";
import {ReactComponent as CloseIcon} from "../../../../assets/icons/close.svg";

interface Props {
    intl: any;
    onSubmitAll: any;
    onSubmitWithoutWarnings: any;
    onCancel: any;
    loading: boolean;
}

interface IState {
}

class WarningsPopup extends React.Component<Props, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        return (
            <div className={styles.warningsModal}>

                <div className={styles.warningsModalCloseButton}
                    onClick={() => this.props.onCancel()}>
                    <CloseIcon />
                </div>

                <h1 className="mb-16">Achtung</h1>
                <p className="mb-48  text-medium">Es gibt Mutationen mit Warnungen.
                    Welche Mutationen möchten Sie übernehmen?</p>


                    <Button
                        className="mx-auto"
                        isDisabled={this.props.loading}
                        type={ButtonTypes.Standard}
                        style={ButtonStyles.Primary}
                        color={ButtonColors.Action}
                        onClick={() => this.props.onSubmitWithoutWarnings()}>
                        alle ohne Warnung
                    </Button>
                    <Button
                        className="mt-16 mx-auto"
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        isDisabled={this.props.loading}
                        onClick={() => this.props.onSubmitAll()}>
                        alle übernehmen
                    </Button>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    loading: state.app.loading
});
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(WarningsPopup));
