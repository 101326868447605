import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import { dateFilter, parseDate } from "../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import "./FormSalary.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { ReactComponent as IconHelp } from "../../../assets/icons/help.svg";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import { fetchFormCustomization } from "../../../utils/form";
import { Controller, useForm } from "react-hook-form";
import {
  buildValidationBasedOnEmployerDates,
  formHasChanged,
  validateInputMessage,
  EValidationTestType,
} from "../../../utils/validationTools";
import formName from "../../../constants/forms/formName.json";
import Spinner from "../../Spinner";
import { Input } from "../../Input";
import { EValidationMessageType } from "../../ValidationMessage";

interface Props {
  intl: any;
  entities?: any;
  initialData: any;
  closeModal: any;
  isDirty?: boolean;
  isLoading: boolean;
  disabled: boolean;
  submitAction: any;
  employers: any;
  insurantsObj: any;
  showValidations: boolean;
}

const FormSalary = ({
  initialData,
  submitAction,
  isLoading,
  closeModal,
  intl,
  employers,
  disabled,
  showValidations,
  isDirty,
}: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      payroll: {
        ...initialData,
        entryDate: initialData.entryDate
          ? parseDate(initialData.entryDate)
          : "",
        validFrom: initialData.validFrom
          ? parseDate(initialData.validFrom)
          : "",
        grossSalary: initialData.grossSalary ?? "",
        activityRate: initialData.activityRate ?? "",
      },
    },
  });

  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const currentTenant = globalSettings?.attributes?.tenantCode;

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.formSalary"]
    );
    if (response.data) {
      const employerConfiguration = employers[initialData.employerId];

      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("payroll")[key])
      );

      // Build custom frontend validation
      formComponentsCustomization = buildValidationBasedOnEmployerDates(
        employerConfiguration,
        formComponentsCustomization,
        "validFrom"
      );

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            validFrom: {
              required: true,
              interval: {
                fieldToCompare: "entryDate",
              },
            },
            grossSalary: {
              required: true,
            },
            activityRate: {
              required: true,
            },
          },
          messages: {},
        },
        requiredFields: {
          validFrom: !!getValues("payroll.validFrom"),
          grossSalary: !!getValues("payroll.grossSalary"),
          activityRate: !!getValues("payroll.activityRate"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("payroll"),
        entryDate: initialData.entryDate
          ? parseDate(initialData.entryDate)
          : "",
        validFrom: initialData.validFrom
          ? parseDate(initialData.validFrom)
          : "",
        grossSalary: initialData.grossSalary ?? "",
        activityRate: initialData.activityRate ?? "",
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const haveCustomValidationsValidFrom = (): EValidationTestType[] => {
    const arr = [EValidationTestType.dateIsNotBeforeEntrydate];
    !!employers[initialData.employerId]?.attributes.changesAllowedFrom &&
      arr.push(EValidationTestType.dateBetween);
    return !!formConfig.formCustomization.components["validFrom"]?.date
      ?.dayFilter
      ? arr.concat(
          dateFilter(
            formConfig.formCustomization.components["validFrom"]?.date
              ?.dayFilter
          ) as any
        )
      : arr;
  };

  const isPayrollTooltipVisible =
    intl.formatMessage({
      id: "app.forms.payroll.grossSalary.tooltip",
    }) != "app.forms.payroll.grossSalary.tooltip";

  return (
    <>
      {isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset disabled={disabled}>
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="payroll.validFrom"
                  render={({ field: { value } }) => (
                    <Input
                      type="date"
                      key="validFromInput"
                      hidden={
                        formConfig.formCustomization.components["validFrom"]
                          ?.hidden
                      }
                      maxLength={10}
                      inputWrapperClass="text-input"
                      id="validFrom"
                      name="validFrom"
                      placeholder={intl.formatMessage({
                        id: "app.validfvalidFromrom",
                        defaultMessage: "Gültig ab",
                      })}
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue("payroll.validFrom", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("payroll"),
                            "validFrom",
                            haveCustomValidationsValidFrom,
                            formConfig.requiredFields
                          );

                        setError("payroll.validFrom", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("payroll"),
                            "validFrom",
                            haveCustomValidationsValidFrom,
                            formConfig.requiredFields
                          );

                        setError("payroll.validFrom", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.payroll?.validFrom?.message,
                          type: errors?.payroll?.validFrom
                            ?.type as EValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.validFrom"
                        defaultMessage="Gültig ab"
                      />
                      {formConfig.formCustomization.components["validFrom"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />

              {/* Lohn */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="payroll.grossSalary"
                  render={({ field: { value } }) => (
                    <Input
                      isDirty={isDirty}
                      type="money"
                      hidden={
                        formConfig.formCustomization.components["grossSalary"]
                          ?.hidden
                      }
                      inputWrapperClass="text-input lohn-input"
                      id="grossSalary"
                      name="grossSalary"
                      placeholder={intl.formatMessage({
                        id:
                          currentTenant === "PKSH"
                            ? "app.forms.eintritt.grossSalaryPKSH.placeholder"
                            : "app.forms.eintritt.grossSalary.placeholder",
                        defaultMessage:
                          currentTenant === "PKSH"
                            ? "Jahreslohn in CHF"
                            : "Lohn eingeben",
                      })}
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("payroll.grossSalary", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("payroll"),
                            "grossSalary",
                            () => [EValidationTestType.isNumber],
                            formConfig.requiredFields
                          );
                        setError("payroll.grossSalary", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("payroll"),
                            "grossSalary",
                            () => [EValidationTestType.isNumber],
                            formConfig.requiredFields
                          );
                        setError("payroll.grossSalary", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.payroll?.grossSalary?.message,
                          type: errors?.payroll?.grossSalary
                            ?.type as EValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id={
                          currentTenant === "KUSP"
                            ? "app.forms.eintritt.grossSalaryKusnacht"
                            : currentTenant === "PKSH"
                            ? "app.forms.eintritt.grossSalaryPKSH"
                            : "app.forms.eintritt.grossSalary"
                        }
                        defaultMessage="Lohn"
                      />
                      {formConfig.formCustomization.components["grossSalary"]
                        ?.required
                        ? " *"
                        : ""}
                      {isPayrollTooltipVisible && (
                        <Tooltip
                          trigger={["click"]}
                          placement="top"
                          overlay={
                            <FormattedMessage
                              id="app.forms.eintritt.grossSalary.tooltip"
                              defaultMessage="Tooltip"
                            />
                          }
                          arrowContent={
                            <div className="rc-tooltip-arrow-inner" />
                          }
                        >
                          <div className="help-icon pointer">
                            <IconHelp />
                          </div>
                        </Tooltip>
                      )}
                    </Input>
                  )}
                />
              </div>

              {/* Beschäftingungsgrad */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="payroll.activityRate"
                  render={({ field: { value } }) => (
                    <Input
                      isDirty={isDirty}
                      type="percentage"
                      key="activityRateInput"
                      hidden={
                        formConfig.formCustomization.components["activityRate"]
                          ?.hidden
                      }
                      inputWrapperClass="text-input"
                      id="activityRate"
                      name="activityRate"
                      placeholder={intl.formatMessage({
                        id: "app.enterlevelofemployment",
                        defaultMessage: "Beschäftigungsgrad in %",
                      })}
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("payroll.activityRate", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("payroll"),
                            "activityRate",
                            () => [EValidationTestType.isBeschaftingungsgrad],
                            formConfig.requiredFields
                          );
                        setError("payroll.activityRate", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("payroll"),
                            "activityRate",
                            () => [EValidationTestType.isBeschaftingungsgrad],
                            formConfig.requiredFields
                          );
                        setError("payroll.activityRate", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.payroll?.activityRate?.message,
                          type: errors?.payroll?.activityRate
                            ?.type as EValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.forms.eintritt.activityRate"
                        defaultMessage="Beschäftingungsgrad"
                      />
                      {formConfig.formCustomization.components["activityRate"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-md-6" />

              <div className="col-12 col-md-6">
                <div className="modal-buttons">
                  <Button
                    onClick={closeModal}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    className="modal-main-button primary width-1"
                    submit={true}
                    isLoading={isLoading}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={
                      isLoading ||
                      isDisabledButton ||
                      !formHasChanged(
                        initialFormToCompare,
                        getValues("payroll")
                      )
                    }
                    onClick={(e) => {
                      submitAction(e, getValues());
                    }}
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(FormSalary)));
