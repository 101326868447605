import * as React from 'react';
import {Blank, Button, ButtonColors, ButtonTypes, ExpandableRow} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import FirmaExpandableRowTop from "../CompaniesExpandableRowTop";
import ExpandableRowMore from "../CompaniesExpandableRowBottom";
import companies from "../../../../constants/DummyData/companies.json";
import styles from "./ComaniesList.module.scss";
import CompaniesExpandableRowTop from "../CompaniesExpandableRowTop";
import CompaniesExpandableRowBottom from "../CompaniesExpandableRowBottom";
import classNames from "classnames";
import {ReactComponent as PlusIcon} from "../../../../assets/icons/plus-round.svg";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {fetchCompanies, setCompaniesFilter, setCompaniesPageNo} from "../../../../actions/pensionskasse/companies";
import {connect} from "react-redux";

interface State {

}

interface Props {
    intl: any;
    fetched: boolean;
    companies: any;
    filtersChanged: any;
    onDetailsClick: any;

    companiesNameFilter: string;

    setLoading: any;
    fetchCompanies: any;
    setCompaniesPageNo: any;
    setCompaniesFilter: any;
    companiesFetched: boolean;
}

class CompaniesList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    clearFilters = () => {
        this.props.setCompaniesFilter({ name: "clear" });
        this.props.setCompaniesPageNo(0);
        this.props.setLoading(true);
        this.props.fetchCompanies().then(() => {
            this.props.setLoading(false);
            this.props.setCompaniesPageNo(1);
        });
    }

    filtersAreApplied = () => {
        return !!this.props.companiesNameFilter;
    }

    render() {
        return (
            <div>
                {/*<div className="top-action-buttons mt-40">*/}
                {/*    <Button*/}
                {/*        className={classNames({"disabled-create-insurant-button": true})}*/}
                {/*        type={ButtonTypes.Text}*/}
                {/*        color={ButtonColors.Action}*/}
                {/*        onClick={() => null}>*/}
                {/*        <PlusIcon className="mr-12" />*/}
                {/*        Organisatorische Einheit / Arbeitgeber hinzufügen*/}
                {/*    </Button>*/}
                {/*</div>*/}
                {this.props.companiesFetched &&
                <div className={styles.listView}>
                    <div className={styles.listHeader}>
                        <div className={styles.column}>
                            Organisatorische Einheit / Arbeitgeber
                        </div>
                        <div className={styles.column}>
                            Arbeitgeber Nr.
                        </div>
                        <div className={styles.column}>
                            Kassen Nr.
                        </div>
                        <div className={styles.column}>
                            Bemerkung
                        </div>
                        <div className={styles.column}>
                            <div style={{width: "2.4rem"}} />
                        </div>
                    </div>
                    {this.props.companies.length ?
                        this.props.companies.map((company, idx) => (
                        <ExpandableRow
                            isExpanded
                            expandable={!!(company.attributes.childCompanies.length)}
                            className="mb-24"
                            key={`companies_row_${idx}`}
                            content={<CompaniesExpandableRowTop
                                company={company}
                                onDetailsClick={() => this.props.onDetailsClick(company.id)}/>}
                            more={<CompaniesExpandableRowBottom
                                companies={company.attributes.childCompanies}
                                onDetailsClick={() => this.props.onDetailsClick(company.id)}/>}/>
                    )) : <Blank
                            onButtonClick={this.clearFilters}
                            showButton={this.filtersAreApplied()}
                            text={this.props.intl.formatMessage({id: "app.nocompanies", defaultMessage: "Keine Firmen vorhanden" })}/>}
                </div>}
            </div>
        );
    }


}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    companiesFetched: state.companies.companiesFetched,
    companiesNameFilter: state.companies.companiesNameFilter,
});
const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    setCompaniesPageNo: (val) => dispatch(setCompaniesPageNo(val)),
    setCompaniesFilter: (val) => dispatch(setCompaniesFilter(val))
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(injectIntl(CompaniesList));
