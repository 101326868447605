import * as React from "react";
import { injectIntl } from "react-intl";
import { RootState } from "../../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../../actions";
import {
  fetchAcceptedMutations,
  fetchDeletedMutations,
  fetchForReviewMutations,
  setAcceptedMutationsFilter,
  setAcceptedMutationsPageNo,
  setDeletedMutationsFilter,
  setDeletedMutationsPageNo,
  setForReviewMutationsFilter,
  setForReviewMutationsPageNo,
} from "../../../../actions/pensionskasse/mutations";
import { connect } from "react-redux";
import { isDate } from "../../../../utils/validationTests";
import SidebarFilterForm, {
  AcceptedSideBarFilterNames,
  DeletedSideBarFilterNames,
  ForReviewSideBarFilterNames,
} from "../SidebarFilterForm";

interface State {}

interface Props {
  intl: any;
  match: any;
  history: any;
  insurants: any;
  employers: any;
  entities: any;
  setLoading: any;
  firmaOptions: any;
  mutationTypes: any;
  mutationTypeOptions: any;
  deletedMutations: any;
  fetchForReviewMutations: any;
  fetchAcceptedMutations: any;
  fetchDeletedMutations: any;
  deletedMutationsFetched: boolean;
  setDeletedMutationsPageNo: any;
  deletedMutationsPageNo: any;
  acceptedMutationsPageNo: any;
  forReviewMutationsPageNo: any;
  setForReviewMutationsPageNo: any;
  setAcceptedMutationsPageNo: any;
  setDeletedMutationsFilter: any;
  setAcceptedMutationsFilter: any;
  setForReviewMutationsFilter: any;
  deletedMutationsNameFilter: any;
  deletedMutationsEventFilter: any;
  deletedMutationsFirmaFilter: any;
  deletedMutationsTakeoverDateFrom: any;
  deletedMutationsTakeoverDateUntil: any;
  deletedMutationsMutationDateFrom: any;
  deletedMutationsMutationDateUntil: any;
}

class DeletedSidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  private debounce = null;

  filterDeletedMutations = (name, value, date?, employers?) => {
    this.props.setDeletedMutationsFilter({ name, value });
    if (name === "clear") {
      this.props.setForReviewMutationsFilter({ name, value });
      this.props.setAcceptedMutationsFilter({ name, value });
    }
    if (name === DeletedSideBarFilterNames.deletedMutationsNameFilter) {
      this.props.setForReviewMutationsFilter({
        name: ForReviewSideBarFilterNames.forReviewMutationsNameFilter,
        value,
      });
      this.props.setAcceptedMutationsFilter({
        name: AcceptedSideBarFilterNames.acceptedMutationsNameFilter,
        value,
      });
    } else if (name === DeletedSideBarFilterNames.deletedMutationsFirmaFilter) {
      this.props.setForReviewMutationsFilter({
        name: ForReviewSideBarFilterNames.forReviewMutationsFirmaFilter,
        value,
      });
      this.props.setAcceptedMutationsFilter({
        name: AcceptedSideBarFilterNames.acceptedMutationsFirmaFilter,
        value,
      });
    } else if (name === DeletedSideBarFilterNames.deletedMutationsEventFilter) {
      this.props.setForReviewMutationsFilter({
        name: ForReviewSideBarFilterNames.forReviewMutationsEventFilter,
        value,
      });
      this.props.setAcceptedMutationsFilter({
        name: AcceptedSideBarFilterNames.acceptedMutationsEventFilter,
        value,
      });
    }

    if (employers === true && value !== "") {
      return;
    }

    if (date) {
      if (!isDate(value) || this.props[name] == value) {
        return;
      }
    }

    this.props.setDeletedMutationsPageNo(0);
    if (
      name === DeletedSideBarFilterNames.deletedMutationsNameFilter ||
      name === DeletedSideBarFilterNames.deletedMutationsFirmaFilter ||
      name === DeletedSideBarFilterNames.deletedMutationsEventFilter ||
      name === "clear"
    ) {
      this.props.setForReviewMutationsPageNo(0);
      this.props.setAcceptedMutationsPageNo(0);
    }

    this.props.setLoading(true);
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.props.fetchDeletedMutations().then(() => {
        this.props.setLoading(false);
        this.props.setDeletedMutationsPageNo(
          this.props.deletedMutationsPageNo + 1
        );
      });

      if (
        name === DeletedSideBarFilterNames.deletedMutationsNameFilter ||
        name === DeletedSideBarFilterNames.deletedMutationsFirmaFilter ||
        name === DeletedSideBarFilterNames.deletedMutationsEventFilter ||
        name === "clear"
      ) {
        this.props.fetchForReviewMutations().then(() => {
          this.props.setLoading(false);
          this.props.setForReviewMutationsPageNo(
            this.props.forReviewMutationsPageNo + 1
          );
        });

        this.props.fetchAcceptedMutations().then(() => {
          this.props.setLoading(false);
          this.props.setAcceptedMutationsPageNo(
            this.props.acceptedMutationsPageNo + 1
          );
        });
      }
    }, 500);
  };

  render() {
    const { employers, mutationTypes } = this.props;
    return (
      <div className="sidebar-container">
        <SidebarFilterForm
          employers={employers}
          mutationTypes={mutationTypes}
          mutationsEventFilter={this.props.deletedMutationsEventFilter}
          isForReviewSideBar={false}
          nameInputFilter="deletedMutationsNameFilter"
          filterInputValue={this.props.deletedMutationsNameFilter}
          filterFunction={this.filterDeletedMutations}
          mutationTypeInputName="deletedMutationsEventFilter"
          firmInputId="filterTypeInbox"
          firmInputName="deletedMutationsFirmaFilter"
          firmInputKey="filterTypeInbox"
          firmInputValue={
            this.props.employers[this.props.deletedMutationsFirmaFilter]
              ? this.props.employers[this.props.deletedMutationsFirmaFilter][
                  "attributes"
                ]["name"]
              : this.props.deletedMutationsFirmaFilter
          }
          inputNameMutationsTakeoverDateFrom="deletedMutationsTakeoverDateFrom"
          inputNameMutationsTakeoverDateUntil="deletedMutationsTakeoverDateUntil"
          inputNameMutationsDateFrom="deletedMutationsMutationDateFrom"
          inputNameMutationsDateUtil="deletedMutationsMutationDateUntil"
          mutationsTakeOverDateFrom={
            this.props.deletedMutationsTakeoverDateFrom
          }
          mutationsTakeOverDateUntil={
            this.props.deletedMutationsTakeoverDateUntil
          }
          mutationDateFrom={this.props.deletedMutationsMutationDateFrom}
          mutationDateUntil={this.props.deletedMutationsMutationDateUntil}
        />
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    mutationTypes: state.app.mutationTypes,
    deletedMutations: state.deletedMutations.deletedMutations,
    deletedMutationsFetched: state.deletedMutations.deletedMutationsFetched,
    deletedMutationsNameFilter:
      state.deletedMutations.deletedMutationsNameFilter,
    deletedMutationsEventFilter:
      state.deletedMutations.deletedMutationsEventFilter,
    deletedMutationsFirmaFilter:
      state.deletedMutations.deletedMutationsFirmaFilter,
    deletedMutationsTakeoverDateFrom:
      state.deletedMutations.deletedMutationsTakeoverDateFrom,
    deletedMutationsTakeoverDateUntil:
      state.deletedMutations.deletedMutationsTakeoverDateUntil,
    deletedMutationsMutationDateFrom:
      state.deletedMutations.deletedMutationsMutationDateFrom,
    deletedMutationsMutationDateUntil:
      state.deletedMutations.deletedMutationsMutationDateUntil,
    forReviewMutationsPageNo: state.forReviewMutations.forReviewMutationsPageNo,
    acceptedMutationsPageNo: state.acceptedMutations.acceptedMutationsPageNo,
    deletedMutationsPageNo: state.deletedMutations.deletedMutationsPageNo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    fetchDeletedMutations: bindActionCreators(fetchDeletedMutations, dispatch),
    setDeletedMutationsPageNo: (val) =>
      dispatch(setDeletedMutationsPageNo(val)),
    setDeletedMutationsFilter: (val) =>
      dispatch(setDeletedMutationsFilter(val)),
    setAcceptedMutationsFilter: (val) =>
      dispatch(setAcceptedMutationsFilter(val)),
    setForReviewMutationsFilter: (val) =>
      dispatch(setForReviewMutationsFilter(val)),
    fetchForReviewMutations: bindActionCreators(
      fetchForReviewMutations,
      dispatch
    ),
    fetchAcceptedMutations: bindActionCreators(
      fetchAcceptedMutations,
      dispatch
    ),
    setForReviewMutationsPageNo: (val) =>
      dispatch(setForReviewMutationsPageNo(val)),
    setAcceptedMutationsPageNo: (val) =>
      dispatch(setAcceptedMutationsPageNo(val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeletedSidebar));
