import * as constants from "../../constants/pensionskasse";
import { act } from "react-dom/test-utils";
import { LOG_OUT } from "../../constants";

export interface DeletedMutationsStatePK {
  deletedMutations: any;
  deletedMutationsPageNo: number;
  moreDeletedMutations: boolean;
  deletedMutationsFetched: boolean;
  deletedMutationsCountFetched: boolean;
  deletedMutationsNameFilter: string;
  deletedMutationsEventFilter: string;
  deletedMutationsFirmaFilter: string;
  deletedMutationsTakeoverDateFrom: string;
  deletedMutationsTakeoverDateUntil: string;
  deletedMutationsMutationDateFrom: string;
  deletedMutationsMutationDateUntil: string;
  total: number;
  warnings: number;
  errors: number;
}

const getInitialState = () => {
  return {
    deletedMutations: [],
    deletedMutationsPageNo: 0,
    moreDeletedMutations: true,
    deletedMutationsFetched: false,
    deletedMutationsCountFetched: false,
    deletedMutationsNameFilter: "",
    deletedMutationsEventFilter: "",
    deletedMutationsFirmaFilter: "",
    deletedMutationsTakeoverDateFrom: "",
    deletedMutationsTakeoverDateUntil: "",
    deletedMutationsMutationDateFrom: "",
    deletedMutationsMutationDateUntil: "",
    total: 0,
    warnings: 0,
    errors: 0,
  };
};

// console.log("app.ts", getInitialState());

const deletedMutations = (
  state: DeletedMutationsStatePK = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.FETCHED_DELETED_MUTATIONS: {
      const deletedMutations =
        state.deletedMutationsPageNo == 0 || action.page == 0
          ? [...action.payload]
          : [...state.deletedMutations, ...action.payload];
      return {
        ...state,
        deletedMutations: deletedMutations,
        deletedMutationsFetched: true,
      };
    }

    case constants.SET_DELETED_MUTATIONS_PAGE_NO: {
      let moreDeletedMutations =
        !(
          state.deletedMutations.length <
          (state.deletedMutationsPageNo + 1) * constants.PAGINAION_SIZE
        ) || state.deletedMutationsPageNo == 0;
      return {
        ...state,
        deletedMutationsPageNo: action.payload,
        moreDeletedMutations: moreDeletedMutations,
      };
    }

    case constants.FETCHED_DELETED_MUTATIONS_COUNT: {
      return {
        ...state,
        total: action.payload.total,
        warnings: action.payload.warnings,
        errors: action.payload.errors,
        deletedMutationsCountFetched: true,
      };
    }

    case constants.SET_DELETED_MUTATIONS_FETCHED: {
      return { ...state, deletedMutationsFetched: false };
    }

    case constants.SET_DELETED_MUTATIONS_FILTERING: {
      switch (action.payload.name) {
        case "forReviewMutationsNameFilter":
        case "deletedMutationsNameFilter":
        case "acceptedMutationsNameFilter":
          return { ...state, deletedMutationsNameFilter: action.payload.value };
        case "forReviewMutationsEventFilter":
        case "deletedMutationsEventFilter":
        case "acceptedMutationsEventFilter":
          return {
            ...state,
            deletedMutationsEventFilter: action.payload.value,
          };
        case "forReviewMutationsFirmaFilter":
        case "deletedMutationsFirmaFilter":
        case "acceptedMutationsFirmaFilter":
          return {
            ...state,
            deletedMutationsFirmaFilter: action.payload.value,
          };
        case "deletedMutationsTakeoverDateFrom":
          return {
            ...state,
            deletedMutationsTakeoverDateFrom: action.payload.value,
          };
        case "deletedMutationsTakeoverDateUntil":
          return {
            ...state,
            deletedMutationsTakeoverDateUntil: action.payload.value,
          };
        case "deletedMutationsMutationDateFrom":
          return {
            ...state,
            deletedMutationsMutationDateFrom: action.payload.value,
          };
        case "deletedMutationsMutationDateUntil":
          return {
            ...state,
            deletedMutationsMutationDateUntil: action.payload.value,
          };
        default: {
          return {
            ...state,
            deletedMutationsNameFilter: "",
            deletedMutationsEventFilter: "",
            deletedMutationsFirmaFilter: "",
            deletedMutationsTakeoverDateFrom: "",
            deletedMutationsTakeoverDateUntil: "",
            deletedMutationsMutationDateFrom: "",
            deletedMutationsMutationDateUntil: "",
          };
        }
      }
    }

    case LOG_OUT: {
      return { ...getInitialState() };
    }
  }

  return state;
};

export default deletedMutations;
