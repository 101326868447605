import * as constants from '../../constants';

export interface UploadState {
    outbox: any;
    inbox: any;
    uploading: any;
    inboxPageNo: any;
    outboxPageNo: any;
    moreInbox: any;
    moreOutbox: any;
    inboxSuccess: boolean;
    outboxSuccess: boolean;
    filterNameInbox: any;
    filterFirmaInbox: any;
    filterTypeInbox: any;
    filterYearInbox: any;
    filterMonthInbox: any;
    filterOnlyNewInbox: any;
    filterNameOutbox: any;
    filterFirmaOutbox: any;
    filterTypeOutbox: any;
    filterYearOutbox: any;
    filterMonthOutbox: any;
    filterOnlyNewOutbox: any;
}

const getInitialState = () => {
    return {
        inbox: [],
        outbox: [],
        uploading: [],
        inboxPageNo: 0,
        outboxPageNo:0,
        moreInbox: true,
        moreOutbox: true,
        inboxSuccess: false,
        outboxSuccess: false,
        filterNameInbox: "",
        filterFirmaInbox: "",
        filterTypeInbox: "",
        filterYearInbox: "",
        filterMonthInbox: "",
        filterOnlyNewInbox: false,
        filterNameOutbox: "",
        filterFirmaOutbox: "",
        filterTypeOutbox: "",
        filterYearOutbox: "",
        filterMonthOutbox: "",
        filterOnlyNewOutbox: false,
    }
};

// console.log("app.ts", getInitialState());

const upload = (state: UploadState = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.UPDATE_FILE_STATUS: {
            const fileId = action.payload;
            const inbox = state.inbox.map(file => {
                if(file.attributes.file.fileId == fileId) {
                    return {
                        ...file,
                        attributes: {
                            ...file.attributes,
                            hasBeenDownloaded: true
                        }
                    }
                } else {
                    return file;
                }

            })
            return { ...state, inbox: inbox }
        }

        case constants.FETCHED_OUTBOX: {
            var outbox = [];
            if(action.payload == null) {
                return { ...state, outbox: outbox, moreOutbox: false, outboxSuccess: true };
            }
            var moreOutbox = !(action.payload.length < constants.PAGINAION_SIZE);
            if(state.outboxPageNo == 0 || action.page == 0) {
                outbox = [ ...action.payload ];
            } else {
                outbox = [ ...state.outbox, ...action.payload ];
            }
            return { ...state, outbox: outbox, moreOutbox: moreOutbox, outboxSuccess: true };
        }

        case constants.FETCHED_INBOX: {
            var inbox = [];
            if(action.payload == null) {
                return { ...state, inbox: inbox, moreInbox: false, inboxSuccess: true };
            }
            var moreInbox = !(action.payload.length < constants.PAGINAION_SIZE);
            if(state.inboxPageNo == 0 || action.page == 0) {
                inbox = [ ...action.payload ];
            } else {
                inbox = [ ...state.inbox, ...action.payload ];
            }
            return { ...state, inbox: inbox, moreInbox: moreInbox, inboxSuccess: true };
        }

        case constants.SET_INBOX_PAGE_NO: {
            let moreInbox = !(state.inbox.length < ((state.inboxPageNo + 1) * constants.PAGINAION_SIZE));
            return { ...state, inboxPageNo: action.payload, moreInbox: moreInbox}
        }

        case constants.SET_OUTBOX_PAGE_NO: {
            let moreOutbox = !(state.outbox.length < ((state.outboxPageNo + 1) * constants.PAGINAION_SIZE));
            return { ...state, outboxPageNo: action.payload, moreOutbox: moreOutbox}
        }

        case constants.ADD_NEW_FILE: {
            let file = action.payload;
            let outbox = [ ...state.outbox ];
            let uploading = [ ...state.uploading ];
            let progress = {
                fileId: file.id,
                progressMax: 0,
                progressValue: 0,
                finished: false,
                failed: false,
            }
            uploading.unshift(progress);
            outbox.unshift(file);
            return { ...state, outbox: outbox, uploading: uploading };
        }

        case constants.UPDATE_PROGRESS: {
            let fileId = action.payload.fileId;
            let uploading = state.uploading.map((progress) => {
                if(progress.fileId == fileId) {
                    let newProgress = progress;
                    newProgress.progressMax = action.payload.progressMax;
                    newProgress.progressValue = action.payload.progressValue;
                    newProgress.finished = action.payload.finished;
                    return newProgress;
                } else {
                    return progress;
                }
            })
            return { ...state, uploading: uploading };
        }

        case constants.SET_INBOX_FILTER: {
            if(action.payload.name === "clear") {
                return {
                    ...state,
                    filterNameInbox: "",
                    filterFirmaInbox: "",
                    filterTypeInbox: "",
                    filterYearInbox: "",
                    filterMonthInbox: "",
                    filterOnlyNewInbox: false
                }
            } else if (action.payload.name === "filterNameInbox") {
                return { ...state, filterNameInbox: action.payload.value };
            } else if (action.payload.name === "filterFirmaInbox") {
                return { ...state, filterFirmaInbox: action.payload.value };
            } else if (action.payload.name === "filterTypeInbox") {
                return { ...state, filterTypeInbox: action.payload.value };
            } else if (action.payload.name === "filterYearInbox") {
                return { ...state, filterYearInbox: action.payload.value};
            } else if (action.payload.name === "filterMonthInbox") {
                return { ...state, filterMonthInbox: action.payload.value};
            } else if (action.payload.name === "filterOnlyNewInbox") {
                return { ...state, filterOnlyNewInbox: action.payload.value};
            } else {
                return { ...state };
            }
        }

        case constants.SET_OUTBOX_FILTER: {
            if(action.payload.name === "clear") {
                return {
                    ...state,
                    filterNameOutbox: "",
                    filterFirmaOutbox: "",
                    filterTypeOutbox: "",
                    filterYearOutbox: "",
                    filterMonthOutbox: "",
                    filterOnlyNewOutbox: false
                }
            } else if (action.payload.name === "filterNameOutbox") {
                return { ...state, filterNameOutbox: action.payload.value };
            } else if (action.payload.name === "filterFirmaOutbox") {
                return { ...state, filterFirmaOutbox: action.payload.value };
            } else if (action.payload.name === "filterTypeOutbox") {
                return { ...state, filterTypeOutbox: action.payload.value };
            } else if (action.payload.name === "filterYearOutbox") {
                return { ...state, filterYearOutbox: action.payload.value};
            } else if (action.payload.name === "filterMonthOutbox") {
                return { ...state, filterMonthOutbox: action.payload.value};
            } else if (action.payload.name === "filterOnlyNewOutbox") {
                return { ...state, filterOnlyNewOutbox: action.payload.value};
            } else {
                return { ...state };
            }
        }

        case constants.LOG_OUT: {
            console.log("log out upload");
            return { ...getInitialState() };
        }

    }

    return state;
};

export default upload;
