import api from "../../utils/api";
import { ACTION, wrap } from "../index";
import * as constants from "../../constants";
import { insurantsQueryParams } from "../../utils/toSearchParams";

export const setInsurantsFetched = (payload: any): ACTION => ({
  type: constants.SET_INSURANTS_FETCHED,
  payload,
});

export const getPersonBySsn = (ssn) => {
  // return async (dispatch) => {
  return api
    .get(`people/${ssn}/`)
    .then((response) => response)
    .catch((err) => err);
  // }
};

export const fetchInsurantById = (id) => {
  return async (dispatch) => {
    return api
      .get(`insurants/${id}/`)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const createInsurant = (payload) => {
  return async (dispatch) => {
    dispatch({ type: constants.LOADING, payload: true });
    return api
      .post(`insurants/?ignoreWarnings=true`, payload)
      .then((response) => {
        dispatch({ type: "CLEAR_ENTRY_FORM_VALUE", payload: undefined });
        return response;
      })
      .catch((err) => err);
  };
};

export const editInsurant = (payload, insurantId) => {
  return async (dispatch) => {
    dispatch({ type: constants.LOADING, payload: true });
    return api
      .patch(`insurants/${insurantId}/`, payload)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const fetchEmployees = (length, page) => {
  const params = insurantsQueryParams(length, page);
  return async (dispatch) => {
    return api
      .get(`insurants${params}`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_INSURANTS,
          payload: response["data"],
          page: page,
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchLocalEmployees = ({ contentId, payloadType, payload }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.FETCH_UPDATE_INSURANTS,
      contentId,
      payloadType,
      payload,
    });
  };
};

export const processInsurant = (data) => {
  return async (dispatch) => {
    return api
      .post(`insurants/archived/`, data)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const fetchExcelImports = (data) => {
  return api
    .post(`excel-imports`, {
      data: {
        type: "excel-import",
        attributes: {
          employerId: data.employerId,
          payRaisePercent: data.payRaisePercent,
          validFrom: data.validFrom,
          roundingFactor: data.roundingFactor,
        },
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const fetchExcelFiles = (token: string) => {
  return api
    .get(`files?token=${token}`, { responseType: "arraybuffer" })
    .then((response) => response)
    .catch((err) => err);
};
