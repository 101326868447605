import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import { dateFilter, parseDate } from "../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import "./FormUnpaidVacation.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { defined } from "../../../utils/variableEvaluation";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { ReactComponent as IconHelp } from "../../../assets/icons/help.svg";
import { fetchFormCustomization } from "../../../utils/form";
import { EValidationTestType } from "../../../utils/validationTools";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import formName from "../../../constants/forms/formName.json";
import { useForm, Controller } from "react-hook-form";
import {
  formHasChanged,
  validateInputMessage,
} from "../../../utils/validationTools";
import Spinner from "../../Spinner";

interface Props {
  initialData: any;
  submitAction: any;
  closeModal: any;
  civilStatusOptions?: any;
  exitReasonOptions?: any;
  entities?: any;
  isLoading: boolean;
  checkFormValidity: any;
  intl: any;
  employers: any;
  history: any;
  disabled: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
  setLoading: (isLoading: boolean) => void;
}

const FormUnpaidVacation = ({
  initialData,
  submitAction,
  isLoading,
  history,
  disabled,
  showValidations,
}: Props) => {
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      unpaidVacation: {
        ...initialData,
        validFrom: initialData.validFrom
          ? parseDate(initialData.validFrom)
          : "",
        validUntil: initialData.validUntil
          ? parseDate(initialData.validUntil)
          : "",
        contributionCoverage: defined(initialData.contributionCoverage)
          ? initialData.contributionCoverage
          : globalSettings.attributes.tenantCode === "PROPUBLIC"
          ? 3
          : null,
      },
    },
  });

  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.formUnpaidVacation"]
    );
    if (response.data) {
      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("unpaidVacation")[key])
      );
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            validFrom: {
              hidden: false,
              required: true,
              date: {
                dayFilter: 0,
              },
            },
            validUntil: {
              hidden: false,
              required: true,
              interval: {
                fieldToCompare: "validFrom",
                intervalType: 5,
                minIntervalValue: 30,
                maxIntervalValue: 730,
                minBegin: 0,
                maxEnd: 0,
              },
              date: {
                dayFilter: 0,
              },
            },
            contributionCoverage: {
              hidden: false,
              required: false,
            },
            contributionCoverage1: {
              hidden: false,
              required: false,
            },
            contributionCoverage2: {
              hidden: false,
              required: false,
            },
            contributionCoverage3: {
              hidden: false,
              required: false,
            },
            contributionCoverage4: {
              hidden: false,
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          validFrom: !!getValues("unpaidVacation.validFrom"),
          validUntil: !!getValues("unpaidVacation.validUntil"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("unpaidVacation"),
        validFrom: initialData.validFrom
          ? parseDate(initialData.validFrom)
          : "",
        validUntil: initialData.validUntil
          ? parseDate(initialData.validUntil)
          : "",
        contributionCoverage: defined(initialData.contributionCoverage)
          ? initialData.contributionCoverage
          : globalSettings.attributes.tenantCode === "PROPUBLIC"
          ? 3
          : null,
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const prepareInputName = useCallback((name: string) => {
    return name.includes("contributionCoverage")
      ? "contributionCoverage"
      : name;
  }, []);

  // keep on every file
  const haveCustomValidations = (formId: string): EValidationTestType[] => {
    const validations = [EValidationTestType.isUntilHigherThenFrom];
    !!formConfig.formCustomization.components[formId]?.date?.dayFilter &&
      validations.push(
        dateFilter(
          formConfig.formCustomization.components[formId]?.date?.dayFilter
        ) as any
      );
    formConfig.formCustomization.components[formId].interval?.fieldToCompare &&
      validations.push(EValidationTestType.isValidUnpaidVacation);
    return validations;
  };

  const haveCustomValidationsValidFrom = (
    formId: string
  ): EValidationTestType[] => {
    const validations = [EValidationTestType.dateIsOneMonthAfterEntryDate];

    !!formConfig.formCustomization.components[formId]?.date?.dayFilter &&
      validations.push([
        dateFilter(
          formConfig.formCustomization.components[formId]?.date?.dayFilter
        ),
      ] as any);

    return validations;
  };

  const checkIfHasSpar = () => {
    let arr = [];
    if (
      !formConfig.formCustomization.components["contributionCoverage1"]
        ?.hidden ||
      !formConfig.formCustomization.components["contributionCoverage2"]?.hidden
    ) {
      arr = [
        formConfig.formCustomization.components["contributionCoverage1"]
          ?.hidden,
        formConfig.formCustomization.components["contributionCoverage2"]
          ?.hidden,
      ];
    } else {
      return false;
    }
    return arr.filter((item) => !item);
  };

  const checkIfHasNur = () => {
    let arr = [];
    if (
      !formConfig.formCustomization.components["contributionCoverage3"]
        ?.hidden ||
      !formConfig.formCustomization.components["contributionCoverage4"]?.hidden
    ) {
      arr = [
        formConfig.formCustomization.components["contributionCoverage3"]
          ?.hidden,
        formConfig.formCustomization.components["contributionCoverage4"]
          ?.hidden,
      ];
    } else {
      return false;
    }
    return arr.filter((item) => !item);
  };

  const checkIfHasCheckboxVisibles = Object.keys(
    formConfig.formCustomization.components
  )
    .filter((item) => item.includes("contribution"))
    .every((item) => formConfig.formCustomization.components[item].hidden);

  return (
    <>
      {isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset disabled={disabled}>
            <div className="row">
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="unpaidVacation.validFrom"
                  render={({ field: { value } }) => (
                    <Input
                      isFocused
                      type="date"
                      hidden={
                        formConfig.formCustomization.components["validFrom"]
                          ?.hidden
                      }
                      id={"validFrom"}
                      name="validFrom"
                      placeholder=""
                      maxLength={10}
                      inputWrapperClass="text-input"
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue("unpaidVacation.validFrom", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("unpaidVacation"),
                            "validFrom",
                            haveCustomValidationsValidFrom,
                            formConfig.requiredFields
                          );
                        setError("unpaidVacation.validFrom", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("unpaidVacation"),
                            "validFrom",
                            haveCustomValidationsValidFrom,
                            formConfig.requiredFields
                          );

                        setError("unpaidVacation.validFrom", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.unpaidVacation?.validFrom?.message,
                          type: errors?.unpaidVacation?.validFrom?.type,
                        },
                      }}
                    >
                      <FormattedMessage id="app.from" defaultMessage="Von" />
                      {formConfig.formCustomization.components["validFrom"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="unpaidVacation.validUntil"
                  render={({ field: { value } }) => (
                    <Input
                      type="date"
                      hidden={
                        formConfig.formCustomization.components["validUntil"]
                          ?.hidden
                      }
                      id={"validUntil"}
                      name="validUntil"
                      placeholder=""
                      maxLength={10}
                      inputWrapperClass="text-input"
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue("unpaidVacation.validUntil", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("unpaidVacation"),
                            "validUntil",
                            haveCustomValidations,
                            formConfig.requiredFields
                          );
                        setError("unpaidVacation.validUntil", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("unpaidVacation"),
                            "validUntil",
                            haveCustomValidations,
                            formConfig.requiredFields
                          );

                        setError("unpaidVacation.validUntil", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.unpaidVacation?.validUntil?.message,
                          type: errors?.unpaidVacation?.validUntil?.type,
                        },
                      }}
                    >
                      <FormattedMessage id="app.to" defaultMessage="Bis" />
                      {formConfig.formCustomization.components["validUntil"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>
              {!checkIfHasCheckboxVisibles && (
                <>
                  <div className="col-12 col-sm-12">
                    <div className="form-title unpaid-vacations">
                      Wie wird mit den Risiko- und Sparbeiträgen während des
                      unbezahlten Urlaubs verfahren?
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    {checkIfHasSpar() && checkIfHasNur() && (
                      <div className="form-subtitle">
                        Spar- und Risikobeiträge
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-sm-6">
                    {checkIfHasSpar() && checkIfHasNur() && (
                      <div className="form-subtitle">Nur Risikobeiträge</div>
                    )}
                  </div>
                  <div className="col-12 col-sm-6">
                    <Controller
                      control={control}
                      name="unpaidVacation.contributionCoverage"
                      render={({ field: { value } }) => (
                        <Input
                          type="checkbox"
                          hidden={
                            formConfig.formCustomization.components[
                              "contributionCoverage1"
                            ]?.hidden
                          }
                          inputWrapperClass="checkbox-input unpaidvacation-checkbox"
                          id={"contributionCoverage1"}
                          name={"contributionCoverage1"}
                          checkboxLabel={
                            <span className="z-index-high">
                              <b>Versicherter</b> zahlt Arbeitgeber- und
                              Arbeitnehmerbeiträge
                              <Tooltip
                                trigger={["click"]}
                                placement="top"
                                overlay={
                                  <span>
                                    Risiko- und Sparbeiträge werden für die Zeit
                                    des unbezahlten Urlaubs allein vom
                                    Versicherten übernommen.
                                  </span>
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div className="help-icon pointer">
                                  <IconHelp />
                                </div>
                              </Tooltip>
                            </span>
                          }
                          value={value != null ? value == 1 : false}
                          checked={value != null ? value == 1 : false}
                          onChange={(name, valueInput) => {
                            setValue(
                              "unpaidVacation.contributionCoverage",
                              valueInput ? 1 : null
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage1",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          onBlur={() => {
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage1",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message:
                                errors?.unpaidVacation?.contributionCoverage
                                  ?.message,
                              type: errors?.unpaidVacation?.contributionCoverage
                                ?.type,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className={`col-12 ${
                      !checkIfHasSpar() ? "col-sm-12" : "col-sm-6"
                    }`}
                  >
                    <Controller
                      control={control}
                      name="unpaidVacation.contributionCoverage"
                      render={({ field: { value } }) => (
                        <Input
                          type="checkbox"
                          hidden={
                            formConfig.formCustomization.components[
                              "contributionCoverage3"
                            ]?.hidden
                          }
                          inputWrapperClass="checkbox-input unpaidvacation-checkbox"
                          id={"contributionCoverage3"}
                          name={"contributionCoverage3"}
                          checkboxLabel={
                            <span className="z-index-high">
                              <b>Versicherter</b> zahlt Arbeitgeber- und
                              Arbeitnehmerbeiträge
                              <Tooltip
                                trigger={["click"]}
                                placement="top"
                                overlay={
                                  <span>
                                    Risikobeiträge werden für die Zeit des
                                    unbezahlten Urlaubs allein vom Versicherten
                                    übernommen. Die Sparbeiträge werden
                                    ausgesetzt.
                                  </span>
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div className="help-icon pointer">
                                  <IconHelp />
                                </div>
                              </Tooltip>
                            </span>
                          }
                          disabled={
                            globalSettings.attributes.tenantCode === "PROPUBLIC"
                          }
                          value={value != null ? value == 3 : false}
                          checked={value != null ? value == 3 : false}
                          onChange={(name, valueInput) => {
                            setValue(
                              "unpaidVacation.contributionCoverage",
                              valueInput ? 3 : null
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage3",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          onBlur={() => {
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage3",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message:
                                errors?.unpaidVacation?.contributionCoverage
                                  ?.message,
                              type: errors?.unpaidVacation?.contributionCoverage
                                ?.type,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <Controller
                      control={control}
                      name="unpaidVacation.contributionCoverage"
                      render={({ field: { value } }) => (
                        <Input
                          type="checkbox"
                          hidden={
                            formConfig.formCustomization.components[
                              "contributionCoverage2"
                            ]?.hidden
                          }
                          inputWrapperClass="checkbox-input unpaidvacation-checkbox absolute-icon"
                          id={"contributionCoverage2"}
                          name={"contributionCoverage2"}
                          checkboxLabel={
                            <span>
                              <b>Versicherter</b> und <b>Arbeitgeber</b> zahlen
                              jeweilige Beiträge
                              <Tooltip
                                trigger={["click"]}
                                placement="top"
                                overlay={
                                  <span>
                                    Risiko- und Sparbeiträge werden für die Zeit
                                    des unbezahlten Urlaubs zwischen
                                    Versichertem und Arbeitgeber aufgeteilt.
                                  </span>
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div className="help-icon pointer">
                                  <IconHelp />
                                </div>
                              </Tooltip>
                            </span>
                          }
                          value={value != null ? value == 2 : false}
                          checked={value != null ? value == 2 : false}
                          onChange={(name, valueInput) => {
                            setValue(
                              "unpaidVacation.contributionCoverage",
                              valueInput ? 2 : null
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage2",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          onBlur={() => {
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage2",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message:
                                errors?.unpaidVacation?.contributionCoverage
                                  ?.message,
                              type: errors?.unpaidVacation?.contributionCoverage
                                ?.type,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className={`col-12 ${
                      !checkIfHasSpar() ? "col-sm-12" : "col-sm-6"
                    }`}
                  >
                    <Controller
                      control={control}
                      name="unpaidVacation.contributionCoverage"
                      render={({ field: { value } }) => (
                        <Input
                          type="checkbox"
                          hidden={
                            formConfig.formCustomization.components[
                              "contributionCoverage4"
                            ]?.hidden
                          }
                          inputWrapperClass="checkbox-input unpaidvacation-checkbox absolute-icon"
                          id={"contributionCoverage4"}
                          name={"contributionCoverage4"}
                          checkboxLabel={
                            <span className="z-index-high">
                              <b>Versicherter</b> und <b>Arbeitgeber</b> zahlen
                              jeweilige Beiträge
                              <Tooltip
                                trigger={["click"]}
                                placement="top"
                                overlay={
                                  <span>
                                    Risikobeiträge werden für die Zeit des
                                    unbezahlten Urlaubs zwischen Versichertem
                                    und Arbeitgeber aufgeteilt. Die Sparbeiträge
                                    werden ausgesetzt.
                                  </span>
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div className="help-icon pointer">
                                  <IconHelp />
                                </div>
                              </Tooltip>
                            </span>
                          }
                          value={value != null ? value == 4 : false}
                          checked={value != null ? value == 4 : false}
                          onChange={(name, valueInput) => {
                            setValue(
                              "unpaidVacation.contributionCoverage",
                              valueInput ? 4 : null
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage4",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          onBlur={() => {
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage4",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message:
                                errors?.unpaidVacation?.contributionCoverage
                                  ?.message,
                              type: errors?.unpaidVacation?.contributionCoverage
                                ?.type,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    {!formConfig.formCustomization.components[
                      "contributionCoverage"
                    ]?.hidden && (
                      <div className="form-subtitle">Keine Beiträge</div>
                    )}
                  </div>
                  <div className="col-12 col-sm-6"></div>
                  <div className="col-12 col-sm-6">
                    <Controller
                      control={control}
                      name="unpaidVacation.contributionCoverage"
                      render={({ field: { value } }) => (
                        <Input
                          type="checkbox"
                          hidden={
                            formConfig.formCustomization.components[
                              "contributionCoverage"
                            ]?.hidden
                          }
                          inputWrapperClass="checkbox-input unpaidvacation-checkbox one-line"
                          id={"contributionCoverage"}
                          name={"contributionCoverage"}
                          checkboxLabel={
                            <span className="z-index-high">
                              Versicherungsdeckung wird ausgesetzt
                              <Tooltip
                                placement="top"
                                overlay={
                                  <span>
                                    Risiko- und Sparbeiträge werden für die Zeit
                                    des unbezahlten Urlaubs ausgesetzt.
                                  </span>
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div className="help-icon pointer">
                                  <IconHelp />
                                </div>
                              </Tooltip>
                            </span>
                          }
                          value={value != null ? value == 0 : false}
                          checked={value != null ? value == 0 : false}
                          onChange={(name, valueInput) => {
                            setValue(
                              "unpaidVacation.contributionCoverage",
                              valueInput ? 0 : null
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          onBlur={() => {
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("unpaidVacation"),
                                "contributionCoverage",
                                null,
                                formConfig.requiredFields,
                                prepareInputName
                              );
                            setError(
                              "unpaidVacation.contributionCoverage",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message:
                                errors?.unpaidVacation?.contributionCoverage
                                  ?.message,
                              type: errors?.unpaidVacation?.contributionCoverage
                                ?.type,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-sm-6"></div>
                </>
              )}
              <div className="col-12 col-sm-6"></div>
              <div className="col-12 col-md-6">
                <div className="modal-buttons">
                  <Button
                    onClick={() => history.goBack()}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    className="modal-main-button primary width-1"
                    submit={true}
                    isLoading={isLoading}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={
                      isLoading ||
                      isDisabledButton ||
                      (formConfig.requiredFields["contributionCoverage"] !==
                        undefined &&
                        typeof getValues(
                          "unpaidVacation.contributionCoverage"
                        ) !== "number") ||
                      !formHasChanged(
                        initialFormToCompare,
                        getValues("unpaidVacation")
                      )
                    }
                    onClick={(e) => {
                      submitAction(e, getValues());
                    }}
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    isLoading: state.app.loading,
    employers: state.app.employers,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(FormUnpaidVacation))
);
