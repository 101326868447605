import React, { Children, cloneElement, isValidElement } from "react";
import { Redirect, Route } from "react-router-dom";
import { expiredAlert, isTokenExpired } from "../../utils/react";
import Login from "../../containers/Login";

interface Props {
  path: any;
  authentication: any;
  location: any;
  children: any;
}

const LoginRoute: React.FC<Props> = ({
  path,
  authentication,
  location,
  children,
}) => (
  <Route
    path={path}
    render={(props) => {
      console.log("REDICRECT TO /");
      const isPensionskasse =
        window.location.pathname.includes("/pensionskasse/");
      if (
        !isTokenExpired(
          authentication.token,
          authentication.expirationDate,
          authentication.step
        )
      ) {
        const to = `${isPensionskasse ? "/pensionskasse" : ""}/login`;
        return <Redirect to={to} />;
      } else {
        const childrenWithProps = Children.map(children, (child) => {
          // Checking isValidElement is the safe way and avoids a TS error too.
          if (isValidElement(child)) {
            return cloneElement(child, { ...props });
          }

          return child;
        });

        return <div>{childrenWithProps}</div>;
      }
    }}
  />
);

export default LoginRoute;
