import { InputValidationReturn } from "../@types/forms/formUtils";
import {
  FrontEndCustomValidation,
  IntervalSettings,
} from "../@types/forms/agp/formValidation";
import { differenceInDays, isAfter, isBefore } from "date-fns";
import {
  buildDateFromString,
  calculateAgeFromDates,
  formatErrorMessage,
  IntervalType,
  parseDate,
  parseToApiDate,
} from "./react";
import { EValidationMessageType } from "../components/ValidationMessage";
import { EValidationTestType } from "./validationConfigs";
import { defined, exists } from "./variableEvaluation";
import errorMessages from "../constants/forms/formMessages.json";
import moment from "moment";
import { CivilStatusCodes } from "../models";

export const VALIDATION_ROOT: IDictionary<
  (
    value1: string,
    validateAgainstValues?: string,
    intervalSettings?: IntervalSettings,
    frontEndCustomValidations?: FrontEndCustomValidation,
    message?: any
  ) => any
> = {
  hasValue: (value: string) => hasValue(value),
  hasAnyValue: (value: string) => hasAnyValue(value),
  isDate: (value1: string, validateAgainstValues?: string) =>
    value1 ? isDate(value1) : true,
  isValidUnpaidVacation: (
    value1: string,
    validateAgainstValues?,
    intervalSettings?
  ) => isValidUnpaidVacation(value1, validateAgainstValues, intervalSettings),
  isFirstDayOfTheMonth: (value1: string) => isFirstDayOfTheMonth(value1),
  isLastDayOfTheMonth: (value1: string) => isLastDayOfTheMonth(value1),
  isPasswordMatch: (value1: string, value2: string) =>
    isPasswordMatch(value1, value2),
  isEmail: (value: string) => isEmail(value),
  isPhoneNumber: (value: string) => isPhoneNumber(value),
  isPin: (value: string) => isPin(value),
  isPassword: (value: string) => isPassword(value),
  isSsn: (value: string) => isSsn(value),
  isBeschaftingungsgrad: (value: string) => isBeschaftingungsgrad(value),
  isNumber: (value: string) => isNumber(value),
  isPositive: (value: string) => isPositive(value),
  isText: (value: string) => isText(value),
  isDegree: (value: string) => isDegree(value),
  dateIsNotInTheFuture: (value: string) => dateIsNotInTheFuture(value),
  dateBetween: (
    value: string,
    validateAgainstValues?,
    intervalSettings?,
    frontEndCustomValidations?
  ) =>
    dateBetween(
      value,
      validateAgainstValues,
      intervalSettings,
      frontEndCustomValidations
    ),
  dateIsNotBeforeEntrydate: (validFrom: string, entryDate: string) =>
    dateIsNotBeforeEntrydate(validFrom, entryDate),
  dateIsNotBeforeBirthdate: (date: string, birthDate: string) =>
    dateIsNotBeforeBirthdate(date, birthDate),
  civilStatusDateNotBeforeBirthDate: (date: string, civilStatusDate: string) =>
    civilStatusDateNotBeforeBirthDate(date, civilStatusDate),
  isActivityRate: (value: string) => isActivityRate(value),
  dateIsOneMonthAfterEntryDate: (validFrom: string, entryDate: string) =>
    dateIsOneMonthAfterEntryDate(validFrom, entryDate),
  ageRangeEntryDate: (value: string, validateAgainstValues: any) =>
    ageRangeEntryDate(value, validateAgainstValues),
  ageRangeBirthdate: (value: string, validateAgainstValues: any) =>
    ageRangeBirthdate(value, validateAgainstValues),
  isValidGender: (value: string | number) => isValidGender(value),
  isValidExcelFile: (value: any) => isValidExcelFile(value),
  isValidExcelFileSize: (value: any) => isValidExcelFileSize(value),
  dateIsUntilToday: (value: any) => dateIsUntilToday(value),
  isUntilHigherThenFrom: (value: string, validateAgainstValues: string) =>
    isUntilHigherThenFrom(value, validateAgainstValues),
  insurantAgeBefore: (
    value: string,
    validateAgainstValues?,
    intervalSettings?,
    frontEndCustomValidations?
  ) =>
    insurantAgeBefore(
      value,
      validateAgainstValues,
      intervalSettings,
      frontEndCustomValidations
    ),
};

export function testsFailed(
  validationTests,
  value1,
  validateAgainstValues?,
  intervalSettings?,
  frontEndCustomValidations?
): InputValidationReturn[] {
  let resultValidation = [];
  validationTests.filter((testKey: EValidationTestType) => {
    const validation = VALIDATION_ROOT[testKey](
      value1,
      validateAgainstValues,
      intervalSettings,
      frontEndCustomValidations
    );
    return !validation.valid && resultValidation.push(validation);
  });
  return resultValidation;
}

export function hasValue(value: string): InputValidationReturn {
  if (defined(value) && value !== "") {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }
  return {
    message: errorMessages["hasValue"],
    type: EValidationMessageType.Error,
    valid: false,
  };
}

export function hasAnyValue(value: string): InputValidationReturn {
  if (defined(value)) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }
  return {
    message: errorMessages["hasValue"],
    type: EValidationMessageType.Error,
    valid: false,
  };
}

export function isFirstDayOfTheMonth(value: string): InputValidationReturn {
  if (/\d{2}\.\d{2}\.\d{4}/gm.test(value)) {
    const parts = value.split(".");
    const day = parseInt(parts[0], 10);
    if (day === 1) {
      return {
        message: "",
        type: null,
        valid: true,
      };
    } else {
      return {
        message: errorMessages["isFirstDayOfTheMonth"],
        type: EValidationMessageType.Error,
        valid: false,
      };
    }
  }
  return {
    message: errorMessages["isFirstDayOfTheMonth"],
    type: EValidationMessageType.Error,
    valid: false,
  };
}

export function isLastDayOfTheMonth(value: string): InputValidationReturn {
  if (/\d{2}\.\d{2}\.\d{4}/gm.test(value)) {
    const parts = value.split(".");
    const lastDay = new Date(
      parseInt(parts[2]),
      parseInt(parts[1]),
      0
    ).getDate();
    return lastDay === parseInt(parts[0])
      ? {
          message: "",
          type: null,
          valid: true,
        }
      : {
          message: errorMessages["isLastDayOfTheMonth"],
          type: EValidationMessageType.Error,
          valid: false,
        };
  }
  return {
    message: errorMessages["isLastDayOfTheMonth"],
    type: EValidationMessageType.Error,
    valid: false,
  };
}

export function isPasswordMatch(
  value1: string,
  value2: string
): InputValidationReturn {
  return exists(value1) && exists(value2) && value1 === value2
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isPasswordMatch"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isUntilHigherThenFrom(
  value: string,
  validateAgainstValues: string
) {
  if (
    isAfter(
      new Date(parseToApiDate(value)),
      new Date(parseToApiDate(validateAgainstValues))
    )
  ) {
    console.log("entrou1");
    return {
      message: "",
      type: null,
      valid: true,
    };
  } else {
    console.log("entrou2");

    return {
      message: errorMessages["isUntilHigherThenFrom"],
      type: EValidationMessageType.Error,
      valid: false,
    };
  }
}

export function isEmail(value: string): InputValidationReturn {
  if (value == "" || !value) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase())
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isEmail"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isPhoneNumber(value: string): InputValidationReturn {
  if (value == "" || !value) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }
  return value.length >= 6
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isPhoneNumber"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isPin(value: string): InputValidationReturn {
  return value.length >= 4
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isPin"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isPassword(value: string): InputValidationReturn {
  return value &&
    value.length >= 8 &&
    /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(value)
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isPassword"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isSsn(value: string): InputValidationReturn {
  if (checkOldSsn(value) || checkNewSsn(value)) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  } else {
    return {
      message: errorMessages["isSsn"],
      type: EValidationMessageType.Error,
      valid: false,
    };
  }
}

export function isDate(value: string): InputValidationReturn {
  // First check for the pattern
  const invalid = {
    message: errorMessages["isDate"],
    type: EValidationMessageType.Error,
    valid: false,
  };
  const valid = {
    message: "",
    type: null,
    valid: true,
  };

  if (
    !(
      /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value) ||
      /^\d{4}\-\d{1,2}\-\d{1,2}/.test(value)
    )
  )
    return invalid;

  // Parse the date parts to integers
  var parts = value.split(".");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);
  console.log("parts1: ", parts, year, month, day);

  if (parts.length < 3) {
    parts = value.split("-");
    day = parseInt(parts[2], 10);
    month = parseInt(parts[1], 10);
    year = parseInt(parts[0], 10);
  }
  console.log("parts2: ", parts, year, month, day);

  // let year_str = year.toString();
  // if(year_str.length == 2) {
  //     if(parseInt(year_str) < 45) {
  //         year_str = "20" + year_str;
  //         year = parseInt(year_str, 10);
  //     } else {
  //         year_str = "19" + year_str;
  //         year = parseInt(year_str, 10);
  //     }
  // }

  // console.log("YEAR", year_str, year);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return invalid;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;

  // Check the range of the day

  return day > 0 && day <= monthLength[month - 1] ? valid : invalid;
}

export const isValidUnpaidVacation = (
  value: string,
  validateAgainstValues: string,
  intervalSettings: IntervalSettings,
  messages?: any
): InputValidationReturn => {
  let message = "";
  let valid = false;
  let isFebruary = false;

  const from = buildDateFromString(validateAgainstValues);
  const until = buildDateFromString(value);

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  // const globalSettings = { attributes: { tenantCode: "PROPUBLIC"}}
  const isProPublic =
    globalSettings?.attributes?.tenantCode &&
    globalSettings?.attributes?.tenantCode.toLowerCase();
  //check if is february
  if (
    validateAgainstValues.split(".")[1] === "02" &&
    value.split(".")[1] === "02" &&
    isProPublic !== "PKSH" &&
    isProPublic !== "SHP"
  ) {
    isFebruary = true;
  }

  // const from = new Date(validateAgainstValues);
  // const until = new Date(value);

  if (!isDate(value) || !isDate(validateAgainstValues)) {
    return {
      message,
      type: null,
      valid: true,
    };
  }

  // Max and Min until date
  //   const minUntil = addDays(from, intervalSettings.minBegin);
  //   const maxUntil = addDays(from, intervalSettings.maxEnd);

  // Map interval types
  if (intervalSettings?.intervalType === 5) {
    const difference = differenceInDays(until, from);
    if (difference > intervalSettings.maxIntervalValue)
      message = formatErrorMessage({
        message: errorMessages["isUnpaidVacation"][1],
        minValue: parseDate(validateAgainstValues),
      });
    else if (difference < intervalSettings.minIntervalValue - 1 && !isFebruary)
      message = formatErrorMessage({
        message: errorMessages["isUnpaidVacation"][0],
        minValue: parseDate(validateAgainstValues),
        maxValue:
          intervalSettings.minIntervalValue / 30 < 1
            ? `${intervalSettings.minIntervalValue} Tage`
            : `${Math.floor(intervalSettings.minIntervalValue / 30)} Monat`,
      });
    else {
      valid = !valid;
    }
  }

  return {
    message,
    type: message ? EValidationMessageType.Error : null,
    valid,
  };
};

export function isBeschaftingungsgrad(value: string): InputValidationReturn {
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const isPKSH =
    globalSettings?.attributes?.tenantCode?.toLowerCase() === "pksh";

  if (!/[!@#%$^&*()_+\-=\[\]{};':"\\|,<>\/?]/.test(value) && value) {
    var val = parseFloat(value);

    if (val > (isPKSH ? 100 : 140) || isNaN(val)) {
      const errorMsg = errorMessages["isBeschaftingungsgrad"];
      return {
        message: isPKSH ? errorMsg.replace("140", "100") : errorMsg,
        type: EValidationMessageType.Error,
        valid: false,
      };
    } else {
      return {
        message: "",
        type: null,
        valid: true,
      };
    }
  } else {
    const errorMsg = errorMessages["isBeschaftingungsgrad"];
    return {
      message: isPKSH ? errorMsg.replace("140", "100") : errorMsg,
      type: EValidationMessageType.Error,
      valid: false,
    };
  }
  // return /^([0-9]%?|[1-9][0-9]%?|[1][0-3][0-9]%?|140+%?)$/gm.test(value);
}

export function isNumber(value: string): InputValidationReturn {
  if (!value) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }
  return /^[^a-zA-Z]*$/g.test(value)
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isNumber"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isPositive(value: string): InputValidationReturn {
  return /^(?!-.*$).*/g.test(value)
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isPositive"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isText(value: string): InputValidationReturn {
  return /^[^0-9]*$/g.test(value)
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isText"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isDegree(value: string): InputValidationReturn {
  return /^([0-9]%?|[1-9][0-9]%?|100%?)$/gm.test(value)
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isDegree"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function dateIsNotInTheFuture(value): InputValidationReturn {
  const today = new Date();
  const date = buildDateFromString(parseDate(value));

  let valid = false;
  let message = "Geburtsdatum unzulässig.";
  if (isDate(value) && date < today) {
    valid = true;
    message = "";
  }

  return {
    message: message,
    type: valid ? null : EValidationMessageType.Error,
    valid,
  };
}

export function insurantAgeBefore(
  value: string,
  validateAgainstValues?,
  intervalSettings?,
  frontEndCustomValidations?
): InputValidationReturn {
  let message = "";
  let valid = true;

  const date = new Date();
  const birthDate = new Date(validateAgainstValues);

  const age = date.getFullYear() - birthDate.getFullYear();
  if (intervalSettings && intervalSettings.maxEnd && +value === 0) {
    valid = age >= intervalSettings.minBegin;
    message = `Versicherte Person muss über ${intervalSettings.minBegin} Jahre alt sein`;
  }

  return {
    message: message,
    type: valid ? null : EValidationMessageType.Error,
    valid,
  };
}

export function dateBetween(
  value: string,
  validateAgainstValues?,
  intervalSettings?,
  frontEndCustomValidations?
): InputValidationReturn {
  let message = "";
  let valid = false;

  if (
    frontEndCustomValidations &&
    frontEndCustomValidations?.dateBetween?.from &&
    frontEndCustomValidations?.dateBetween?.until
  ) {
    const from = buildDateFromString(
      parseDate(frontEndCustomValidations?.dateBetween?.from)
    );
    const to = buildDateFromString(
      parseDate(frontEndCustomValidations?.dateBetween?.until)
    );
    const check = buildDateFromString(parseDate(value));

    valid = check >= from && check <= to;

    if (!valid) {
      return {
        message: valid
          ? message
          : formatErrorMessage({
              message: errorMessages["dateBetween"][2],
              minValue: parseDate(frontEndCustomValidations?.dateBetween?.from),
              maxValue: parseDate(
                frontEndCustomValidations?.dateBetween?.until
              ),
            }),
        type: valid ? null : EValidationMessageType.Error,
        valid,
      };
    }
  } else {
    if (
      !isDate(value) ||
      !intervalSettings?.minBegin ||
      !intervalSettings?.maxEnd
    ) {
      return {
        message,
        type: null,
        valid: true,
      };
    }

    const difference = IntervalType(
      intervalSettings?.intervalType,
      parseDate(validateAgainstValues),
      value
    );

    if (
      difference >= intervalSettings?.minBegin &&
      difference <= intervalSettings?.maxEnd
    ) {
      valid = true;
    }
  }

  return {
    message: valid
      ? message
      : formatErrorMessage({
          message:
            errorMessages["dateBetween"][
              intervalSettings?.intervalType === 8 ||
              intervalSettings?.intervalType === 7 ||
              intervalSettings?.intervalType === 5
                ? 3
                : 2
            ],
          minValue: parseDate(intervalSettings?.minBegin),
          maxValue: parseDate(intervalSettings?.maxEnd),
          aux:
            intervalSettings?.intervalType === 8
              ? "Jahre"
              : intervalSettings?.intervalType === 7
              ? "Monate"
              : "Tage",
        }),
    type: valid ? null : EValidationMessageType.Error,
    valid,
  };
}

export const dateIsOneMonthAfterEntryDate = (
  validFrom: string,
  entryDate: any
) => {
  const dateValidFrom = buildDateFromString(validFrom);
  const dateEntryDate = new Date(entryDate?.entryDate ?? entryDate);

  dateEntryDate.setMonth(dateEntryDate.getMonth() + 1);

  if (isDate(validFrom) && dateValidFrom < dateEntryDate) {
    return {
      message: errorMessages["dateIsOneMonthAfterEntryDate"],
      type: EValidationMessageType.Error,
      valid: false,
    };
  }
  return {
    message: "",
    type: null,
    valid: true,
  };
};

export const dateIsNotBeforeEntrydate = (
  validFrom: string,
  entryDate: string
) => {
  if (isBefore(new Date(validFrom), new Date(entryDate))) {
    return {
      message: formatErrorMessage({
        message: errorMessages["dateIsNotBeforeEntrydate"],
        minValue: parseDate(entryDate),
      }),
      type: EValidationMessageType.Error,
      valid: false,
    };
  }
  return {
    message: "",
    type: null,
    valid: true,
  };
};

export function civilStatusDateNotBeforeBirthDate(
  value: string,
  validateAgainstValues: any
): InputValidationReturn {
  const civilStatusDate =
    validateAgainstValues.civilStatus === CivilStatusCodes.Married
      ? validateAgainstValues?.marriageDate
      : validateAgainstValues.civilStatus === CivilStatusCodes.Divorced
      ? validateAgainstValues?.divorceDate
      : null;

  if (!isDate(value) || !civilStatusDate) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }

  if (
    buildDateFromString(value).getTime() >=
    (civilStatusDate.includes("-")
      ? new Date(civilStatusDate).getTime()
      : buildDateFromString(civilStatusDate).getTime())
  ) {
    return {
      message: errorMessages["civilStatusDateNotBeforeBirthDate"],
      type: EValidationMessageType.Error,
      valid: false,
    };
  }
  return {
    message: "",
    type: null,
    valid: true,
  };
}

export function dateIsNotBeforeBirthdate(
  value: string,
  validateAgainstValues: any
): InputValidationReturn {
  const birthDate = validateAgainstValues?.birthDate;

  if (!isDate(value) || !birthDate || !value) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }

  if (
    (value.includes("-")
      ? new Date(value).getTime()
      : buildDateFromString(value).getTime()) <=
    (birthDate.includes("-")
      ? new Date(birthDate).getTime()
      : buildDateFromString(birthDate).getTime())
  ) {
    return {
      message: errorMessages["dateIsNotBeforeBirthdate"],
      type: EValidationMessageType.Error,
      valid: false,
    };
  }
  return {
    message: "",
    type: null,
    valid: true,
  };
}

export const dateIsUntilToday = (date: string): InputValidationReturn => {
  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const formattedTomorrow = parseToApiDate(
    tomorrow.toLocaleDateString("de-CH", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );

  let valid = false;
  let message = "Ungültiges Datum";
  if (isBefore(new Date(parseToApiDate(date)), new Date(formattedTomorrow))) {
    valid = true;
    message = "";
  }

  return {
    message: message,
    type: valid ? null : EValidationMessageType.Error,
    valid,
  };
};

export function isActivityRate(value: string): InputValidationReturn {
  const number_value = parseFloat(value);
  return number_value > 0 && number_value <= 100
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isActivityRate"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function ageRangeEntryDate(
  value: string,
  validateAgainstValues: any
): InputValidationReturn {
  const birthDate = validateAgainstValues?.birthDate;
  if (!birthDate || !value) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }
  if (!isDate(birthDate) || !isDate(value)) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }

  const date = new Date(birthDate);
  const entryDate = new Date(value);
  const age = calculateAgeFromDates(entryDate, date);

  console.log(age);
  return age >= 18 && age <= 70
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isActivityRate"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function ageRangeBirthdate(
  value: string,
  validateAgainstValues: any
): InputValidationReturn {
  const entryDate = validateAgainstValues?.entryDate;
  if (!entryDate || !value) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }
  if (!isDate(entryDate) || !isDate(value)) {
    return {
      message: "",
      type: null,
      valid: true,
    };
  }

  const date = new Date(entryDate);
  const birthDate = new Date(value);
  const age = calculateAgeFromDates(date, birthDate);

  console.log(age);
  return age >= 18 && age <= 70
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isActivityRate"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isValidGender(value: any): InputValidationReturn {
  return value !== "0" && value !== 0
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isValidGender"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isValidExcelFile(value: File): InputValidationReturn {
  const ExcelExt = "xlsx";
  return value && value.name.split(".").pop() == ExcelExt
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isValidExcelFile"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

export function isValidExcelFileSize(value: File): InputValidationReturn {
  const maxFileSize = 50000000; //50MB
  return value && value.size > 0 && value.size <= maxFileSize
    ? {
        message: "",
        type: null,
        valid: true,
      }
    : {
        message: errorMessages["isValidGender"],
        type: EValidationMessageType.Error,
        valid: false,
      };
}

// utils
export function isValid(
  validationTests,
  value1,
  validateAgainstValues?
): boolean {
  return (
    testsFailed(validationTests, value1, validateAgainstValues).length === 0
  );
}

export function isInputValid(
  inputValues,
  validationTests,
  inputName1: string,
  validateAgainstValues?: any
) {
  if (defined(validationTests)) {
    return isValid(
      validationTests,
      inputValues[inputName1],
      validateAgainstValues
    );
  } else {
    return true;
  }
}
export function checkOldSsn(str): boolean {
  if (str == null || str == "" || str.length != 14) {
    return false;
  }
  var ziffern = [
    parseInt(str.substr(0, 1)),
    parseInt(str.substr(1, 1)),
    parseInt(str.substr(2, 1)),
    parseInt(str.substr(4, 1)),
    parseInt(str.substr(5, 1)),
    parseInt(str.substr(7, 1)),
    parseInt(str.substr(8, 1)),
    parseInt(str.substr(9, 1)),
    parseInt(str.substr(11, 1)),
    parseInt(str.substr(12, 1)),
    parseInt(str.substr(13, 1)),
  ];
  var res = str.split(/[\.]/);
  if (res != null) {
    if (
      res[0].length != 3 ||
      res[1].length != 2 ||
      res[2].length != 3 ||
      res[3].length != 3
    ) {
      return false;
    }
  } else {
    return false;
  }
  var gewicht = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  var sum = 0;
  for (var i = 0; i < ziffern.length - 1; i++) {
    sum += ziffern[i] * gewicht[i];
  }
  var pruef = 11 - (sum % 11);
  if (pruef != ziffern[ziffern.length - 1]) {
    return false;
  }
  return true;
}

export function checkNewSsn(str): boolean {
  if (str == null || str == "" || str.length != 16) {
    return false;
  }
  var ziffern = [
    parseInt(str.substr(0, 1)),
    parseInt(str.substr(1, 1)),
    parseInt(str.substr(2, 1)),
    parseInt(str.substr(4, 1)),
    parseInt(str.substr(5, 1)),
    parseInt(str.substr(6, 1)),
    parseInt(str.substr(7, 1)),
    parseInt(str.substr(9, 1)),
    parseInt(str.substr(10, 1)),
    parseInt(str.substr(11, 1)),
    parseInt(str.substr(12, 1)),
    parseInt(str.substr(14, 1)),
    parseInt(str.substr(15, 1)),
  ];
  var res = str.split(/[\.]/);
  if (res != null) {
    if (
      res[0].length != 3 ||
      res[1].length != 4 ||
      res[2].length != 4 ||
      res[3].length != 2
    ) {
      return false;
    }
  } else {
    return false;
  }
  var sum = 0;
  for (var i = 0; i < ziffern.length - 1; i++) {
    if (i % 2 == 0) {
      sum += ziffern[i];
    } else {
      sum += ziffern[i] * 3;
    }
  }
  var pruef = sum - (sum % 10) + 10 - sum;
  pruef = pruef == 10 ? 0 : pruef;
  if (pruef != ziffern[ziffern.length - 1]) {
    return false;
  }
  return true;
}

(window as any).GenerateSSN = () => {
  let str = Math.random().toString();
  let ahv = [7, 5, 6];
  for (let i = 0; i < 9; i++) {
    ahv.push(parseInt(str[i + 2]));
  }

  let pruef = 0;
  for (let i = 0; i < ahv.length; i++) {
    let mult = i % 2 == 0 ? 1 : 3;
    pruef += ahv[i] * mult;
  }

  let last = Math.ceil(pruef / 10) * 10 - pruef;
  ahv.push(last);

  return (
    "" +
    ahv[0] +
    ahv[1] +
    ahv[2] +
    "." +
    ahv[3] +
    ahv[4] +
    ahv[5] +
    ahv[6] +
    "." +
    ahv[7] +
    ahv[8] +
    ahv[9] +
    ahv[10] +
    "." +
    ahv[11] +
    ahv[12]
  );
};

export function isMonth(value: string) {
  return value != "0";
}

export function isYear(value: string): boolean {
  if (value) {
    return !(value.length < 4);
  }
  return true;
}

export const checkValidationDate = (value: string) => {
  let validation = {
    valid: false,
    message: "",
    type: null,
  };
  if (value.length === 10) {
    validation = isDate(value) as any;
    validation.valid = !validation.valid;
  }
  return {
    visible: validation.valid,
    config: {
      message: "",
      type: validation.type,
    },
  };
};
