import React from "react";
import { formatNumber, parseDate } from "../../../../utils/react";
import { FormattedMessage, injectIntl } from "react-intl";
import { mutationFormTypes } from "../../../../constants";
import { ReactComponent as IconPen } from "../../../../assets/icons/pen.svg";
import styles from "./PersonenExpandableRowBottom.module.scss";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import classNames from "classnames";
import Tooltip from "rc-tooltip";

interface Props {
  intl: any;
  insurant: any;
  employers: any;
  entities: any;
  onEditClick: any;
}

const PersonenExpandableRowBottom = ({
  insurant,
  employers,
  entities,
  onEditClick,
}: Props) => {
  const lohn =
    !!insurant.attributes.payroll?.grossSalary &&
    "CHF " + formatNumber(insurant.attributes.payroll.grossSalary);
  // const bonus = !!(insurant.attributes.payroll?.bonus) && "CHF " + formatNumber(insurant.attributes.payroll.bonus);
  const employerName =
    employers?.[insurant.attributes.employee.employerId]?.attributes.name;
  const kassennummer =
    employers?.[insurant.attributes.employee.employerId]?.attributes
      .extParentId;
  return (
    <div className={styles.personenExpandableRowBottom}>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          <FormattedMessage
            id="app.personen.card.bottom.grossSalary"
            defaultMessage="Lohn"
          />
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{lohn}</p>
      </div>
      {/* <div className={classNames(styles.column, "show-child-on-hover")}>
                <span className={styles.headerText}>
                    Bonus
                    <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.NewPayroll)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <div className="help-icon pointer">
                <IconPen />
              </div>
            </Tooltip>
                    </div>
                </span>
                <p>{bonus}</p>
            </div> */}
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          Lohn gültig ab
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{parseDate(insurant.attributes.payroll?.validFrom)}</p>
      </div>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          Zivilstand
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Zivilstand)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>
          {
            entities?.CivilStatus?.values[
              insurant.attributes.person.civilStatus
            ]
          }
        </p>
      </div>
      {/* <div className={classNames(styles.column, "show-child-on-hover")}>
                <span className={styles.headerText}>
                    Firma
                    <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.Versicherteninfos)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <div className="help-icon pointer">
                <IconPen />
              </div>
            </Tooltip>
                    </div>
                </span>
                <p></p>
            </div> */}
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          Eintrittsdatum
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{parseDate(insurant.attributes.employee.entryDate, "yyyy/dd")}</p>
      </div>

      <div className={styles.column} />

      <div className={styles.break} />

      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          Arbeitgeber
          {/* <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.Versicherteninfos)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <div className="help-icon pointer">
                <IconPen />
              </div>
            </Tooltip>
                    </div> */}
        </span>
        <p>{employerName}</p>
      </div>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          Kassennummer
          {/* <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.Versicherteninfos)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <div className="help-icon pointer">
                <IconPen />
              </div>
            </Tooltip>
                    </div> */}
        </span>
        <p>{kassennummer}</p>
      </div>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          <FormattedMessage
            id="app.personen.card.bottom.activityRate"
            defaultMessage="Arbeitpensum"
          />
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>
          {insurant.attributes.payroll?.activityRate}
          {insurant.attributes.payroll?.activityRate ? "%" : null}
        </p>
      </div>
      <div
        className={classNames(
          styles.column,
          "show-child-on-hover show-on-mobile"
        )}
      >
        <span className={styles.headerText}>
          Adresse
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewAddress)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>
          <span>
            {insurant.attributes.address?.address1 &&
              insurant.attributes.address?.address1 + " "}
            <br />
            {insurant.attributes.address?.zipCode &&
              insurant.attributes.address?.zipCode + " "}
            {insurant.attributes.address?.city &&
              insurant.attributes.address?.city}
          </span>
        </p>
      </div>

      <div className={styles.column} />

      <div className={styles.break} />

      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          <FormattedMessage
            id="app.mutationcard.values.person.costCenter"
            defaultMessage="Kostenstelle"
          />
          {/* <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.Versicherteninfos)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <div className="help-icon pointer">
                <IconPen />
              </div>
            </Tooltip>
                    </div> */}
        </span>
        <p>{insurant.attributes.employee.costCenter}</p>
      </div>
      <div className={classNames(styles.column, "show-child-on-hover")}>
        <span className={styles.headerText}>
          Versicherungsnummer
          {/* <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.Versicherteninfos)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <div className="help-icon pointer">
                <IconPen />
              </div>
            </Tooltip>
                    </div> */}
        </span>
        <p>{insurant.attributes.employee.insEmployeeId}</p>
      </div>

      <div className={styles.column} />

      <div className={styles.column} />

      <div className={styles.column} />
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(PersonenExpandableRowBottom));
