import React from "react";
import {ReactComponent as IconContact} from "../../../../assets/icons/Icon_Personalien.svg";
import {Button, EButtonType} from "../../../Button";
import RecursiveRow from "./RecursiveRow";


interface IProps {
    companies: any;
    onDetailsClick: any;
}

const CompaniesExpandableRowBottom = ({companies, onDetailsClick}: IProps) => (
        <RecursiveRow
            companies={companies}
            onDetailsClick={onDetailsClick}/>
)

export default CompaniesExpandableRowBottom;
