import {Blank, ExpandableRow} from "icr-shared-components";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {RootState} from "../../../../reducers";
import ReceivedExpandableRowContent from "../ReceivedExpandableRowContent";
import FileExpandableRowMore from "../FileExpandableRowMore";
import classNames from "classnames";
import styles from "./ReceivedTab.module.scss";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {
    fetchInbox,
    fetchOutbox,
    initializeDownload,
    initializeUpload, uploadFile,
    uploadPart
} from "../../../../actions/arbeitgeber/upload";

interface Props {
    intl: any;
    handleOpenFilters: () => void;
    filtersChanged: number;
    downloadFile: (arg: any) => void;
    setLoading: any;
    fetchInbox: any;
    setInboxPageNo: any;
    filterInbox: any;

    inbox: any[];
    employers: any[];
    inboxFetched: boolean;

    filterNameInbox: string;
    filterFirmaInbox: string;
    filterTypeInbox: string;
    filterYearInbox: string;
    filterMonthInbox: string;
    filterOnlyNewInbox: boolean;

    downloadingFiles: any;
}

interface State {
    expandedRow: any;
}

class ReceivedTab extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            expandedRow: null,
        }
    }

    clearFilters = () => {
        this.props.setInboxPageNo(0);
        this.props.filterInbox({name: "clear"});
        this.props.setLoading(true);
        this.props.fetchInbox().then(() => {
            this.props.setLoading(false);
            this.props.setInboxPageNo(1);
        })
    }

    filtersAreApplied = () => {
        return !!(this.props.filterNameInbox ||
            this.props.filterFirmaInbox ||
            this.props.filterTypeInbox ||
            this.props.filterYearInbox ||
            this.props.filterMonthInbox ||
            this.props.filterOnlyNewInbox);
    };

    render() {
        return (
            <div className={styles.listView}>
                <div className={styles.listHeader}>
                    <div className={styles.column}>
                        <FormattedMessage id="app.data" defaultMessage="Name"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.employer" defaultMessage="Arbeitgeber"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.date" defaultMessage="Datum"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage
                            id="app.erstellt"
                            defaultMessage="Erstellt von"
                        />
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.date" defaultMessage="Status"/>
                    </div>
                    <div className={styles.column} style={{marginRight: "3rem"}} />
                </div>

                {this.props.inbox.length > 0 ?
                    this.props.inbox.map((file, index) =>
                            <ExpandableRow
                                // expandable
                                // index={index}
                                // expanded={this.state.expandedRow}
                                // length={this.props.filtersChanged}
                                key={`received-tab-expandable-row-${index}`}
                                className={`mb-24`}
                                hasBeenDownloaded={file.attributes.hasBeenDownloaded}
                                // rowChanged={(expanded) => this.setState({expandedRow: index})}
                                content={<ReceivedExpandableRowContent file={file} isDownloading={this.props.downloadingFiles.includes(file.attributes.file.fileId)} downloadFile={this.props.downloadFile}/>}
                                // more={<FileExpandableRowMore employers={this.props.employers} entry={file}/>}
                                />
                    ) : <Blank
                            onButtonClick={() => this.clearFilters()}
                            // buttonText="Filter zurücksetzen"
                            showButton={this.filtersAreApplied()}
                            text={this.props.intl.formatMessage({
                                id: "app.nofilesreceived",
                                defaultMessage: "Keine empfangene Dateien vorhanden"
                            })}/>}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    inbox: state.upload.inbox,
    employers: state.app.employers,
    inboxFetched: state.upload.inboxSuccess,

    filterNameInbox: state.upload.filterNameInbox,
    filterFirmaInbox: state.upload.filterFirmaInbox,
    filterTypeInbox: state.upload.filterTypeInbox,
    filterYearInbox: state.upload.filterYearInbox,
    filterMonthInbox: state.upload.filterMonthInbox,
    filterOnlyNewInbox: state.upload.filterOnlyNewInbox
});
const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
    filterInbox: (val) => dispatch(actions.filterInbox(val)),
    setInboxPageNo: (val) => dispatch(actions.setInboxPageNo(val)),
    fetchInbox: bindActionCreators(fetchInbox, dispatch)
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(injectIntl(ReceivedTab));
