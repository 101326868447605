import * as React from 'react';
import {useState} from 'react';
import {injectIntl} from "react-intl";
import {RootState} from "../../../../reducers";
import {connect} from "react-redux";
import styles from "./MutationExpandableRowErrorBottom.module.scss"
import {Button, ButtonColors, ButtonSizes, ButtonStyles, ButtonTypes} from "icr-shared-components";

interface IProps {
    intl: any;
    loading: any;
    onSubmit: any;
    hasWarning?: boolean;
    canArchiveManually: boolean;
    canArchiveAutomatically: boolean;
}

enum Tabs {
    CommitMutation = "CommitMutation",
    RemoveMutation = "RemoveMutation"
}

enum RadioValues {
    Auto = "Auto",
    Manual = "Manual"
}

const MutationExpandableRowErrorBottom = ({onSubmit, loading, hasWarning, canArchiveManually, canArchiveAutomatically }: IProps) => {
    const isMobile = window.innerWidth <= 822;
    const [currentTab, setCurrentTab] = useState(!hasWarning ? Tabs.CommitMutation : Tabs.RemoveMutation);
    const [radioValue, setRadioValue] = useState(RadioValues.Manual);
    const isMobileSize = window.innerWidth <= 768;

    return (
        <div className={styles.mutationExpandableRowErrorBottom}>

            <div className="row mt-16">
                <div className="col-sm-12 col-md-6 mb-48 mb-md-0">
                    <div className={styles.textContent}>
                        <p><b>Mutation erneut übernehmen</b></p>
                        <p className="mt-16">Manchmal kann eine erneute Datensendung das <br/> Problem lösen.</p>
                    </div>
                    <Button
                        submit={true}
                        type={ButtonTypes.Standard}
                        color={ButtonColors.Action}
                        style={ButtonStyles.Primary}
                        isDisabled={loading || !canArchiveAutomatically}
                        size={isMobile ? ButtonSizes.Full : ButtonSizes.Auto}
                        onClick={(e) => onSubmit(false)}>
                        Erneut übernehmen
                    </Button>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className={styles.textContent}>
                        <p><b>Mutation manuell in PEKA erfasst</b></p>
                        <p className="mt-16">Die Daten konnten nicht an PEKA übermittelt werden. <br/> Bitte erfassen Sie diese manuell in PEKA.</p>
                    </div>
                    <Button
                        submit={true}
                        type={ButtonTypes.Standard}
                        color={ButtonColors.Action}
                        style={ButtonStyles.Secondary}
                        isDisabled={loading || !canArchiveManually}
                        size={isMobile ? ButtonSizes.Full : ButtonSizes.Auto}
                        onClick={(e) => onSubmit(true)}>
                        Manuell erfasst
                    </Button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    loading: state.app.loading
})

const connector = connect(mapStateToProps, null);
export default connector(injectIntl(MutationExpandableRowErrorBottom));
