import React from 'react'
import classNames from 'classnames'

import styles from './StickyActions.module.scss'
import {Button, ButtonColors, ButtonTypes} from "icr-shared-components";
import {calculateStickyActionsBottomPosition} from "../../utils/react";
import {ReactComponent as WhiteCloseIcon} from "../../assets/icons/close-white.svg";
import {ReactComponent as MoreIcon} from "../../assets/icons/more.svg";

interface State {
   expanded: boolean;
   menuBottomPosition: number;
}

interface Props {
   actions: Array<Action>
   className?: string;
   style?: object
}

interface Action {
   action: () => void;
   content: any;
   visible: boolean;
   className?: any;
}

export class StickyActions extends React.Component<Props, State> {
   constructor(props: any) {
      super(props)
      this.state = {
         expanded: false,
         menuBottomPosition: 0
      }

   }

   private ref = React.createRef<any>();

   componentDidMount() {
      setInterval(() => {
         const menuBottomPosition = -(this.ref?.current?.getBoundingClientRect().bottom - window.screen.height);
         if(menuBottomPosition != this.state.menuBottomPosition) {
            // console.log(menuBottomPosition);
            this.setState({menuBottomPosition: menuBottomPosition});
         }
      }, 1)
   }

   componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
      // console.log(this.ref);
      // console.log(this.ref.current.getBoundingClientRect());
   }

   calculateStickyActionsBottomPosition = (itemsCount) => {
      const itemHeight = 72;
      const stickyActionsContainerPadding = 40;
      return -(itemsCount * itemHeight) - stickyActionsContainerPadding;
   }

   determineButtonStyle = () => {
      // const {menuBottomPosition} = this.state;
      const menuBottomPosition = -(this.ref?.current?.getBoundingClientRect().bottom - window.innerHeight);
      const menuVisibility = this.ref?.current?.clientHeight + menuBottomPosition;
      return menuVisibility < 72;
   }

   render() {
      const {expanded} = this.state;
      const {actions, className, style} = this.props;
      const visibleStickyActions = actions.filter(action => action.visible).length;
      const buttonFixed = this.determineButtonStyle();
      const stickyActionsBottomPosition = expanded ? 0 : `${this.calculateStickyActionsBottomPosition(visibleStickyActions)}px`;

      return (
         <ul className={classNames(styles.stickyActions, className, {expanded: expanded})}
             ref={this.ref}
             style={{
                bottom: `${stickyActionsBottomPosition}`
             }}>
            <button
               className={classNames(styles.stickyActionButton)}
               onClick={() => this.setState({expanded: !expanded})}
               style={buttonFixed ? {position: "fixed", bottom: "92px"} : {position: "absolute", top: "-76px"}}>
               {expanded ? <WhiteCloseIcon/> :<MoreIcon/>}
            </button>

            {actions.map(action => (
               action.visible &&
               <li className={classNames(styles.stickyActionItem, action.className)}>
                  <Button
                     type={ButtonTypes.Text}
                     color={ButtonColors.Action}
                     onClick={() => action.action()}>
                     {action.content}
                  </Button>
               </li>
            ))}
         </ul>
      )
   }
}
