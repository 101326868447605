import React from "react";
import "./BatchJob.scss";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  ButtonStyles,
} from "icr-shared-components";
import { ReactComponent as ImportIcon } from "../../../assets/icons/arrow-import.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/arrow-export.svg";
import { FormattedMessage } from "react-intl";

interface Props {
  importButton:any;
  exportButton:any;
}


const BatchJob = ({importButton, exportButton}:Props) => {
  return (
    <div>
      <p className="label">
      <FormattedMessage id="app.excel.excelTitle" defaultMessage="Um viele Lohnänderungen gleichzeitig zu erfassen, können Sie in drei
        einfachen Schritten die Mutationen erstellen:" />
      </p>

      <div className="label-options">
      <FormattedMessage id="app.excel.excelInfo1" defaultMessage="1. PD erstellt eine Excel-Datei mit allen Mitarbeitern" /><br />
      <FormattedMessage id="app.excel.excelInfo2" defaultMessage="2. Sie passen Lohn und Beschäftigungsgrad in der Excel-Datei an" /><br />
      <FormattedMessage id="app.excel.excelInfo3" defaultMessage="3. Excel-Datei importieren und PD generiert automatisch alle notwendigen Mutationen" />
      </div>

      <p className="label"><FormattedMessage id="app.excel.excelQuestion" defaultMessage="Was möchten sie tun?" /></p>

      <div className="btn-line">
      <Button
          onClick={importButton}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
        >
          <FormattedMessage id="app.excel.button.export" defaultMessage="Datei importieren" />
          <ImportIcon className="ml-24"/>
        </Button>
        <Button
          onClick={exportButton}
          className="mr-32"
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
        >
          <FormattedMessage id="app.excel.button.import" defaultMessage="Datei erstellen" />
          <DownloadIcon className="ml-24"/>
        </Button>
        
      </div>
    </div>
  );
};

export default BatchJob;
