import React, {Children, cloneElement, isValidElement} from "react";
import {Redirect, Route} from "react-router-dom";
import {expiredAlert, isTokenExpired} from "../../utils/react";
import Login from "../../containers/Login";

interface Props {
    path: any
    authentication: any;
    location: any;
    children: any;
    exact?: boolean;
}

const AuthRoute: React.FC<Props> = ({ path, authentication, location, children, exact }) => (
    <Route
        path={path}
        exact={exact}
        render={props => {
              if(isTokenExpired(authentication.token, authentication.expirationDate)) {
                  return <Redirect to="/" />
              } else {
                  const childrenWithProps = Children.map(children, child => {
                      // Checking isValidElement is the safe way and avoids a TS error too.
                      if (isValidElement(child)) {
                          return cloneElement(child, { ...props })
                      }

                      return child;
                  });

                  return <div>{childrenWithProps}</div>
              }
          }}
    />
)

export default AuthRoute;
