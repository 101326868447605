import * as React from 'react';
import style from './ProcessingMutations.module.scss';
import { ReactComponent as ProcessingSpinner } from "../../assets/icons/process-spinner.svg";

export interface Props {
    count: number;
}

const ProcessingMutations = (props: Props) => (
    <div className={style.processingMutations}>
        <span className={style.text}/>
        <ProcessingSpinner className={style.spinner} />
        <b>{props.count}</b>
    </div>
);

export default ProcessingMutations;
