import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions/";
import {
  fetchExcelImports,
  fetchExcelFiles,
} from "../../../actions/arbeitgeber/insurants";
import {
  dateFilter,
  parseDate,
  prepareEmployersDropdownOptions,
} from "../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import formName from "../../../constants/forms/formName.json";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import { Controller, useForm } from "react-hook-form";
import { fetchFormCustomization } from "../../../utils/form";
import { validateInputMessage } from "../../../utils/validationTools";
import { EValidationTestType } from "../../../utils/validationConfigs";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/arrow-export.svg";

interface Props {
  intl: any;
  checkFormValidity: any;
  civilStatusOptions?: any;
  closeModal: any;
  entities?: any;
  exitReasonOptions?: any;
  initialData: any;
  isLoading: boolean;
  disabled: boolean;
  submitAction: any;
  employers: any;
  insurantsObj: any;
  history: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
  filterInsurants: any;
  filterByFirmaPerson: any;
  fetchExcelTemplate: any;
  fetchExcelDownload: any;
  fetchExcelImports: any;
  fetchExcelFiles: any;
  setLoading: any;
  goBack: () => void;
}

const FormExcel = (props: Props) => {
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      formExcel: {
        validFrom: "",
        payRaisePercent: "",
        payroll: "",
        employerId: "",
        type: "0",
      },
    },
  });

  useEffect(() => {
    getFetchFormCustomization();
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig]);

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.formExcel"]
    );
    if (response.data) {
      let formComponentsCustomization = {};

      response["data"].attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );
      let requiredFields = {};

      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("formExcel")[key])
      );

      if (!!formComponentsCustomization["type"]?.defaultValue) {
        setValue(
          "formExcel.type",
          formComponentsCustomization["type"].defaultValue
        );
        if (formComponentsCustomization["type"].defaultValue === "1") {
          requiredFields.hasOwnProperty("payRaisePercent") &&
            delete requiredFields["payRaisePercent"];
          requiredFields.hasOwnProperty("payroll") &&
            delete requiredFields["payroll"];
        }
      }

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: response["data"].attributes?.translations,
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            employerId: {
              required: true,
            },
            validFrom: {
              required: true,
              date: {
                dayFilter: 1,
              },
            },
            payRaisePercent: {
              required: false,
            },
            payroll: {
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          employerId: !!getValues("formExcel.employerId"),
          validFrom: !!getValues("formExcel.validFrom"),
        },
      });
    }
  };

  const haveCustomValidationsFormDate = (): EValidationTestType[] => {
    return !!formConfig.formCustomization.components["validFrom"]?.date
      ?.dayFilter
      ? [
          dateFilter(
            formConfig.formCustomization.components["validFrom"]?.date
              ?.dayFilter
          ),
        ]
      : null;
  };

  const typeOptions = [
    {
      value: 0,
      label: "Generelle prozentuelle Anpassung",
    },
    {
      value: 1,
      label: "Individuelle Anpassung",
    },
  ];

  return (
    <form
      className="form"
      onSubmit={(e) => {
        console.log("onsubmit", e);
        e.preventDefault();
      }}
    >
      <fieldset disabled={props.disabled}>
        <div className="row">
          {/* Firma */}
          <div className="col-12 col-sm-6">
            <Controller
              control={control}
              name={"formExcel.employerId"}
              render={({ field: { value } }) => (
                <Input
                  type="text"
                  id="firmaSelect"
                  name="firmaSelect"
                  key="firmaSelect"
                  inputWrapperClass="dropdown-input"
                  placeholder={props.intl.formatMessage({
                    id: "app.defaultEmployer",
                    defaultMessage: "Default Arbeitgeber",
                  })}
                  onSelected={(name, inputValue) => {
                    setValue("formExcel.employerId", inputValue);
                    const validation: ValidationMessage = validateInputMessage(
                      formConfig.formCustomization,
                      getValues("formExcel"),
                      "employerId",
                      null,
                      formConfig.requiredFields
                    );
                    setError("formExcel.employerId", validation);

                    validation?.valid &&
                      setFormConfig({
                        ...formConfig,
                        requiredFields: validation?.valid,
                      });

                    props.filterInsurants({ name, inputValue });
                  }}
                  onBlur={() => {
                    const validation: ValidationMessage = validateInputMessage(
                      formConfig.formCustomization,
                      getValues("formExcel"),
                      "employerId",
                      null,
                      formConfig.requiredFields
                    );
                    setError("formExcel.employerId", validation);

                    validation?.valid &&
                      setFormConfig({
                        ...formConfig,
                        requiredFields: validation?.valid,
                      });
                  }}
                  value={
                    props.employers[value]
                      ? props.employers[value]["attributes"]["name"]
                      : ""
                  }
                  selectOptions={prepareEmployersDropdownOptions(
                    props.employers
                  )}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: errors?.formExcel?.employerId?.message,
                      type: errors?.formExcel?.employerId
                        ?.type as ValidationMessageType,
                    },
                  }}
                  sort
                >
                  <FormattedMessage
                    id="app.employer"
                    defaultMessage="Arbeitgeber"
                  />
                  {formConfig.formCustomization.components["employerId"]
                    ?.required
                    ? " *"
                    : ""}
                </Input>
              )}
            />
          </div>

          {/* Lohnanpassung gültig ab */}
          <div className="col-12 col-sm-6">
            <Controller
              control={control}
              name={"formExcel.validFrom"}
              render={({ field: { value } }) => (
                <Input
                  type="date"
                  maxLength={10}
                  inputWrapperClass="text-input"
                  id="validFrom"
                  name="validFrom"
                  placeholder={props.intl.formatMessage({
                    id: "app.excel.label.date",
                    defaultMessage: "DD.MM.JJJJ",
                  })}
                  value={value && value.length >= 10 ? parseDate(value) : value}
                  onChange={(name, inputValue) => {
                    setValue("formExcel.validFrom", inputValue);
                    const validation: ValidationMessage = validateInputMessage(
                      formConfig.formCustomization,
                      getValues("formExcel"),
                      "validFrom",
                      haveCustomValidationsFormDate,
                      formConfig.requiredFields
                    );
                    setError("formExcel.validFrom", validation);

                    validation?.valid &&
                      setFormConfig({
                        ...formConfig,
                        requiredFields: validation?.valid,
                      });
                  }}
                  onBlur={() => {
                    const validation: ValidationMessage = validateInputMessage(
                      formConfig.formCustomization,
                      getValues("formExcel"),
                      "validFrom",
                      haveCustomValidationsFormDate,
                      formConfig.requiredFields
                    );
                    setError("formExcel.validFrom", validation);
                    validation?.valid &&
                      setFormConfig({
                        ...formConfig,
                        requiredFields: validation?.valid,
                      });
                  }}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: errors?.formExcel?.validFrom?.message,
                      type: errors?.formExcel?.validFrom
                        ?.type as ValidationMessageType,
                    },
                  }}
                >
                  <FormattedMessage
                    id="app.excel.label.validFrom"
                    defaultMessage="Lohnanpassung gültig ab"
                  />
                  {formConfig.formCustomization.components["validFrom"]
                    ?.required
                    ? " *"
                    : ""}
                </Input>
              )}
            />
          </div>

          <div className="col-12 col-sm-6">
            {!formConfig.formCustomization.components["type"]
              ?.hidden && (
              <Controller
                control={control}
                name={"formExcel.type"}
                render={({ field: { value } }) => (
                  <Input
                    type="text"
                    id="firmaSelect"
                    name="firmaSelect"
                    key="firmaSelect"
                    inputWrapperClass="dropdown-input"
                    placeholder={props.intl.formatMessage({
                      id: "app.choosecompany",
                      defaultMessage: "Typ",
                    })}
                    onSelected={(name, inputValue) => {
                      setValue("formExcel.type", inputValue);
                      if (inputValue === 1) {
                        const newRequiredField = formConfig.requiredFields;
                        newRequiredField.hasOwnProperty("payRaisePercent") &&
                          delete newRequiredField["payRaisePercent"];
                        newRequiredField.hasOwnProperty("payroll") &&
                          delete newRequiredField["payroll"];
                        setFormConfig({
                          ...formConfig,
                          requiredFields: newRequiredField,
                        });
                        setValue("formExcel.payRaisePercent", "");
                        setValue("formExcel.payroll", "");
                      } else {
                        const newRequiredField = formConfig.requiredFields;
                        !newRequiredField.hasOwnProperty("payRaisePercent") &&
                          formConfig.formCustomization.components[
                            "payRaisePercent"
                          ]?.required &&
                          (newRequiredField["payRaisePercent"] = false);
                        !newRequiredField.hasOwnProperty("payroll") &&
                          formConfig.formCustomization.components["payroll"]
                            ?.required &&
                          (newRequiredField["payroll"] = false);
                        setFormConfig({
                          ...formConfig,
                          requiredFields: newRequiredField,
                        });
                      }
                      const validation: ValidationMessage =
                        validateInputMessage(
                          formConfig.formCustomization,
                          getValues("formExcel"),
                          "type",
                          null,
                          formConfig.requiredFields
                        );
                      setError("formExcel.type", validation);

                      validation?.valid &&
                        setFormConfig({
                          ...formConfig,
                          requiredFields: validation?.valid,
                        });

                      props.filterInsurants({ name, inputValue }); //is it necessary?
                    }}
                    onBlur={() => {
                      const validation: ValidationMessage =
                        validateInputMessage(
                          formConfig.formCustomization,
                          getValues("formExcel"),
                          "type",
                          null,
                          formConfig.requiredFields
                        );
                      setError("formExcel.employerId", validation);

                      validation?.valid &&
                        setFormConfig({
                          ...formConfig,
                          requiredFields: validation?.valid,
                        });
                    }}
                    value={
                      typeOptions[parseInt(value)]
                        ? typeOptions[value].label
                        : ""
                    }
                    selectOptions={typeOptions}
                    validationMessageConfig={{
                      visible: props.showValidations,
                      config: {
                        message: errors?.formExcel?.employerId?.message,
                        type: errors?.formExcel?.employerId
                          ?.type as ValidationMessageType,
                      },
                    }}
                    sort
                  >
                    <FormattedMessage
                      id="app.excel.label.type"
                      defaultMessage="Typ"
                    />
                  </Input>
                )}
              />
            )}
          </div>
          <div className="col-12 col-sm-6"></div>

          {/* Lohnerhöhung */}
          {parseInt(getValues("formExcel.type")) === 0 && (
            <>
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name={"formExcel.payRaisePercent"}
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id="payRaisePercent"
                      name=""
                      placeholder={"0"}
                      value={value}
                      onChange={(name, inputValue) => {
                        setValue("formExcel.payRaisePercent", inputValue);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("formExcel"),
                            "payRaisePercent",
                            () => [EValidationTestType.isNumber],
                            formConfig.requiredFields
                          );
                        setError("formExcel.payRaisePercent", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("formExcel"),
                            "payRaisePercent",
                            () => [EValidationTestType.isNumber],
                            formConfig.requiredFields
                          );
                        setError("formExcel.payRaisePercent", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.formExcel?.payRaisePercent?.message,
                          type: errors?.formExcel?.payRaisePercent
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.forms.lohnliste.salaryIncrease.label"
                        defaultMessage="Generelle Lohnanpassung"
                      />
                      {formConfig.formCustomization.components[
                        "payRaisePercent"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Rundung  */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name={"formExcel.payroll"}
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id="payroll"
                      name=""
                      placeholder={"0"}
                      value={value}
                      onChange={(name, inputValue) => {
                        setValue("formExcel.payroll", inputValue);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("formExcel"),
                            "payroll",
                            () => [
                              EValidationTestType.isNumber,
                              EValidationTestType.isPositive,
                            ],
                            formConfig.requiredFields
                          );
                        setError("formExcel.payroll", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("formExcel"),
                            "payroll",
                            () => [
                              EValidationTestType.isNumber,
                              EValidationTestType.isPositive,
                            ],
                            formConfig.requiredFields
                          );
                        setError("formExcel.payroll", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.formExcel?.payroll?.message,
                          type: errors?.formExcel?.payroll
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.forms.lohnliste.round.label"
                        defaultMessage="Rundung Jahreslohn"
                      />
                      {formConfig.formCustomization.components["payroll"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>
            </>
          )}

          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-sm-6">
            <div className="modal-buttons">
              <Button
                onClick={() => props.goBack()}
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
              >
                <FormattedMessage id="app.back" defaultMessage="zurück" />
              </Button>
              <Button
                submit={true}
                isLoading={props.isLoading}
                type={ButtonTypes.Standard}
                color={ButtonColors.Action}
                style={ButtonStyles.Primary}
                isDisabled={props.isLoading || isDisabledButton}
                onClick={() => {
                  const data = {
                    formExcel: {
                      ...getValues("formExcel"),
                      payRaisePercent: Number(
                        getValues("formExcel.payRaisePercent")
                      ),
                      roundingFactor:
                        getValues("formExcel.payroll").length === 0 ||
                        getValues("formExcel.payroll") === "0"
                          ? null
                          : Number(getValues("formExcel.payroll")),
                    },
                  };
                  delete data?.formExcel.payroll;
                  props.submitAction(data);
                }}
              >
                <FormattedMessage
                  id="app.pp.excel.export.submit"
                  defaultMessage="Datei herunterladen"
                />
                <DownloadIcon className="ml-24" />
              </Button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    filterByFirmaPerson: state.insurants.filterByFirmaPerson,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    filterInsurants: (val) => dispatch(actions.filterPersons(val)),
    fetchExcelImports: (val) => dispatch(fetchExcelImports(val) as any),
    fetchExcelFiles: (val) => dispatch(fetchExcelFiles(val) as any),
    setLoading: (val) => dispatch(actions.setLoading(val)),
  };
}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(FormExcel)));
