import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, ButtonColors, ButtonSizes, ButtonTypes, Input } from "icr-shared-components";
import { RootState } from "../../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../../actions";
import { filterPersons, setInsurantsPageNo } from "../../../../actions";
import { connect } from "react-redux";
import { prepareEmployersDropdownOptions } from "../../../../utils/react";
import { fetchEmployees } from "../../../../actions/arbeitgeber/insurants";
import { ReactComponent as ExcelIcon } from "../../../../assets/icons/xlsx-download.svg";
import DownloadIcon from "../../../DownloadIcon";
import "./PersonenSidebar.scss";

interface Props {
    intl: any;
    employers: any;
    setLoading: any;
    setPersonsPageNo: any;
    fetchEmployees: any;
    filterInsurants: any;
    filterByNameMutations: any;
    filterByTypeMutations: any;
    filterByFirmaMutations: any;
    filterByAlsoClosedMutations: any;
    insurantsPageNo: any;
    setInsurantsPageNo: any;
    onFiltersChanged: any;
    filterByNamePerson: any;
    filterByFirmaPerson: any;
    filterByOnlyChangedPerson: any;
    filterByAlsoInactivePerson: any;
    openPdfModal: any;
    openExcelModal: any;
    excelImportEnabled: boolean;
}

interface State {

}

class PersonenSidebar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

    }

    private debounce = null;

    filterPersons = (name, value, employers?) => {
        this.props.filterInsurants({ name, value });

        if (employers === true && value !== "") {
            return;
        }

        this.props.setInsurantsPageNo(0);
        this.props.setLoading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.props.fetchEmployees().then(() => {
                this.props.setLoading(false);
                this.props.setInsurantsPageNo(this.props.insurantsPageNo + 1);
            })
        }, 500);
    }

    render() {
        const { employers } = this.props;
        const employerOptions = prepareEmployersDropdownOptions(employers);

        return (
            <div>
                <div className="paddingContainer">
                    <p className="colorGrey mb-4">
                        <FormattedMessage id="app.searchpeople" defaultMessage="Personen suchen" />
                    </p>
                    <Input
                        type='text'
                        id="personSearch"
                        name="personSearch"
                        key="personSearch"
                        inputWrapperClass="text-input filter-input"
                        placeholder={
                            this.props.intl.formatMessage({ id: "app.namebirthdayssn", defaultMessage: "Name, Geburtstag" })
                        }
                        value={this.props.filterByNamePerson}
                        onChange={(name, value) => this.filterPersons(name, value)}
                        inputWrapperStyle={{}}
                        filtering>
                    </Input>
                    <Input
                        id="firmaSelect"
                        name="firmaSelect"
                        key="firmaSelect"
                        inputWrapperClass="dropdown-input filter-input"
                        placeholder={this.props.intl.formatMessage({
                            id: "app.choosecompany",
                            defaultMessage: "Firma wählen"
                        })}
                        value={this.props.employers[this.props.filterByFirmaPerson] ? this.props.employers[this.props.filterByFirmaPerson]['attributes']['name'] : this.props.filterByFirmaPerson}
                        onSelected={(name, value) => this.props.filterByFirmaPerson != value && this.filterPersons(name, value)}
                        onChange={(name, value) => this.filterPersons(name, value, true)}
                        selectOptions={employerOptions}
                        validationMessageConfig={null/* this.validationMessageConfig() */}
                        type='text'
                        sort>
                    </Input>
                    <Input
                        name="onlyChangedChekbox"
                        checked={this.props.filterByOnlyChangedPerson}
                        checkboxLabel={this.props.intl.formatMessage({
                            id: "app.showchangedonly",
                            defaultMessage: "Nur geänderte zeigen"
                        })}
                        onChange={(name, value) => this.filterPersons(name, value)}
                        type='checkbox'
                        inputWrapperClass="radio-input filter-input">
                    </Input>
                    <Input
                        checked={this.props.filterByAlsoInactivePerson}
                        checkboxLabel={this.props.intl.formatMessage({
                            id: "app.alsoshowinactive",
                            defaultMessage: "Auch inaktive zeigen"
                        })}
                        name="alsoInactiveCheckbox"
                        onChange={(name, value) => this.filterPersons(name, value)}
                        type='checkbox'
                        inputWrapperClass="radio-input filter-input">
                    </Input>
                    <Button
                        className="ml-auto"
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        size={ButtonSizes.Small}
                        onClick={() => this.filterPersons('clear', 'clear')}>
                        <FormattedMessage id="app.resettodefault" defaultMessage="Zurücksetzen" />
                    </Button>
                </div>
                <div className="breaker" />
                <div className="paddingContainer">
                    <p className="colorGrey mt-40 mb-26">
                        <FormattedMessage id="app.generateDocument" defaultMessage="Dokument generieren" />
                    </p>

                    <div className="d-flex justify-content-between cursor-pointer"
                        onClick={() => this.props.openPdfModal()}>
                        <p><FormattedMessage id="app.personen.sidebar.personenList.pdfButton" defaultMessage="Versichertenliste" /></p>
                        <DownloadIcon text="PDF" color="#145685" />
                    </div>
                    {/* {this.props.excelImportEnabled ? <div className="d-flex justify-content-between mt-28 cursor-pointer"
                        onClick={() => this.props.openExcelModal()}>
                        <p><FormattedMessage id="app.personen.sidebar.personenList.excelButton" defaultMessage="Lohnliste Massenanpassung" /></p>
                        <ExcelIcon />
                    </div> : null} */}
                </div>

            </div>
        )
    }
}


const mapStateToProps = (state: RootState, props: any) => ({
    ...props,
    employers: state.app.employers,
    insurants: state.insurants.insurants,
    insurantsPageNo: state.insurants.insurantsPageNo,
    filterByNamePerson: state.insurants.filterByNamePerson,
    filterByFirmaPerson: state.insurants.filterByFirmaPerson,
    filterByAlsoInactivePerson: state.insurants.filterByAlsoInactivePerson,
    filterByOnlyChangedPerson: state.insurants.filterByOnlyChangedPerson,
});

const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
    setInsurantsPageNo: val => dispatch(setInsurantsPageNo(val)),
    filterInsurants: val => dispatch(filterPersons(val)),
    fetchEmployees: bindActionCreators(fetchEmployees, dispatch),

});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(injectIntl(PersonenSidebar));
