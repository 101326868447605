import api from "../utils/api";
import { setGlobalSettings } from "./index";

const apiUrl = process.env.REACT_APP_REST_API_URL;
// const apiUrl = "https://api.arbeitgeber.pensiondynamics.ch/jsonapi/v1/";

export const globalSettings = () => {
    return async (dispatch) => {
        return api.get(`${apiUrl}global-settings`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                dispatch(setGlobalSettings(response.data));
                localStorage.setItem('global-settings', JSON.stringify(response.data))
                return response;
            }).catch(err => {
                return null
            });
    }
};