import React from 'react'
import styles from './StickyButton.module.scss'
import {Button, ButtonColors, ButtonSizes, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {ReactComponent as IconArrowRight} from "../../assets/icons/arrow-right-action.svg";
import classNames from "classnames";
import {ReactComponent as WhiteCloseIcon} from "../../assets/icons/close-white.svg";

interface State {
   expanded: boolean;
   scrolled: boolean;
   menuBottomPosition: number;
}

interface Props {
   onButtonClick: () => void;
   tabs?: Array<Tab>
   className?: string;
   isDisabledByValidation?: boolean;
   canSubmitAll: boolean;
   onClearValidation?: () => void;
}

interface Tab {
   label: string;
   count: number;
   persist?: boolean;
}

export class StickyButton extends React.Component<Props, State> {
   constructor(props: any) {
      super(props)
      this.state = {
         expanded: false,
         scrolled: false,
         menuBottomPosition: 0
      }

   }

   private ref = React.createRef<any>();

   componentDidMount() {
      const element = document.querySelector(".content-container") || document.querySelector(".main-content-container-left");
      element.addEventListener("scroll", this.handleScroll);
      setInterval(() => {
         const menuBottomPosition = -(this.ref?.current?.getBoundingClientRect().bottom - window.screen.height);
         if(menuBottomPosition != this.state.menuBottomPosition) {
            this.setState({menuBottomPosition: menuBottomPosition});
         }
      }, 1)
   }

   componentWillUnmount() {
      document.removeEventListener("scroll", this.handleScroll);
   }

   private previous_scroll_top = null;
   handleScroll = e => {
      const element = document.querySelector(".content-container") || document.querySelector(".main-content-container-left");
      const element_top = element?.scrollTop;
      if((element_top > this.previous_scroll_top) && !this.state.scrolled) {
         this.setState({scrolled: true});
      }
      this.previous_scroll_top = element_top;
   }

   determineButtonStyle = () => {
      const menuBottomPosition = -(this.ref?.current?.getBoundingClientRect().bottom - window.innerHeight);
      const menuVisibility = this.ref?.current?.clientHeight + menuBottomPosition;
      return menuVisibility < 72;
   }

   floatingButtonContent = () => {
      return (
          <>
             <span>Mutationen übernehmen</span>
             <IconArrowRight/>
          </>
      )
   }

   render() {
      const {expanded, scrolled} = this.state;
      const {tabs, isDisabledByValidation, className} = this.props;
      const buttonFixed = this.determineButtonStyle();
      const isPensionskasse = window.location.pathname.includes("/pensionskasse/");

      return (
          <div className={classNames(styles.stickyButton, className, {[styles.expanded]: expanded}, {[styles.disabled]: isDisabledByValidation})}
               ref={this.ref}>
             <button
                 className={classNames(styles.stickyButtonFloatingButton, {[styles.scrolled]: scrolled})}
                 onClick={() => this.setState({expanded: !expanded, scrolled: true})}
                 style={buttonFixed ? {position: "fixed", bottom: "92px"} : {position: "absolute", top: "-76px"}}>
                {expanded ? <WhiteCloseIcon/> : this.floatingButtonContent()}
             </button>

             <div className={styles.stickyButtonTabs}>
                {tabs.map(tab =>
                    !!(tab.count || tab.persist) && <div className={styles.stickyButtonTab}>
                       <div className={styles.label}>{tab.label}</div>
                       <div className={styles.count}>{tab.count}</div>
                    </div>
                )}
             </div>

             <Button
               icon={this.props.canSubmitAll ? <IconArrowRight /> : null}
               type={ButtonTypes.Standard}
               isDisabled={!this.props.canSubmitAll}
               color={ButtonColors.White}
               style={ButtonStyles.Primary}
               className={styles.stickyButtonButton}
               onClick={() => this.props.onButtonClick()}>
               <p>{isPensionskasse ? "übernehmen" : "Abgeben"}</p>
            </Button>

            {isDisabledByValidation && <div className={styles.filtersRow}>
               <span>Aktive Filter verhindern das {isPensionskasse ? "übernehmen" : "Abgeben"}.</span>
               <Button
                    type={ButtonTypes.Text}
                    size={ButtonSizes.Small}
                    onClick={() => this.props.onClearValidation()}>
                    Filter zurücksetzen
                </Button>
             </div>}
          </div>
      )
   }
}
