import React, {Component, useState} from "react";
import {RootState} from "../../../../reducers";
import {Blank, ExpandableRow} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import Spinner from "../../../Spinner";
import SentExpandableRowContent from "../SentExpandableRowContent";
import {connect} from "react-redux";
import UploadHeader from "../UploadHeader";
import FileExpandableRowMore from "../FileExpandableRowMore";
import styles from "./SentTab.module.scss";
import classNames from "classnames";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {fetchInbox, fetchOutbox} from "../../../../actions/arbeitgeber/upload";

interface Props {
    intl: any;
    handleOpenFilters: () => void;
    filtersChanged: number;
    downloadFile: (arg: any) => void;

    outbox: any[];
    employers: any[];
    outboxSuccess: boolean;
    uploading: any;

    filterOutbox: any;
    setOutboxPageNo: any;
    fetchOutbox: any;
    setLoading: any;

    filterNameOutbox: string;
    filterFirmaOutbox: string;
    filterTypeOutbox: string;
    filterYearOutbox: string;
    filterMonthOutbox: string;

    downloadingFiles: any;
}

interface State {
    expandedRow: any;
}

class SentTab extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            expandedRow: null,
        }
    }

    clearFilters = () => {
        this.props.setOutboxPageNo(0);
        this.props.filterOutbox({name: "clear"});
        this.props.setLoading(true);
        this.props.fetchOutbox().then(() => {
            this.props.setLoading(false);
            this.props.setOutboxPageNo(1);
        })
    }

    filtersAreApplied = () => {
        return !!(this.props.filterNameOutbox ||
            this.props.filterFirmaOutbox ||
            this.props.filterTypeOutbox ||
            this.props.filterYearOutbox ||
            this.props.filterMonthOutbox);
    };

    render() {
        return (
            <div className={styles.listView}>
                <div className={styles.listHeader}>
                    <div className={styles.column}>
                        <FormattedMessage id="app.data" defaultMessage="Name"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.employer" defaultMessage="Arbeitgeber"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.date" defaultMessage="Datum"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage
                            id="app.erstellt"
                            defaultMessage="Erstellt von"
                        />
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.date" defaultMessage="Status"/>
                    </div>
                    <div className={styles.column}>
                        <div style={{width: "3rem"}}/>
                    </div>
                </div>

                {this.props.outbox.length > 0 ?
                    this.props.outbox.map((file, index) =>
                        <ExpandableRow
                            // expandable
                            // index={index}
                            // expanded={this.state.expandedRow}
                            // length={this.props.filtersChanged}
                            key={`sent-expandable-row-${index}`}
                            progress={!!this.props.uploading[index]}
                            className={classNames("mb-24", styles.listItem)}
                            // rowChanged={(expanded) => this.setState({expandedRow: index})}
                            content={<SentExpandableRowContent file={file} isDownloading={this.props.downloadingFiles.includes(file.attributes.file.fileId)} uploading={this.props.uploading[index]} downloadFile={this.props.downloadFile}/>}
                            // more={<FileExpandableRowMore employers={this.props.employers} entry={file}/>}
                            />
                    ) : <Blank
                            onButtonClick={this.clearFilters}
                            // buttonText="Filter zurücksetzen"
                            showButton={this.filtersAreApplied()}
                            text={this.props.intl.formatMessage({
                                id: "app.nofilessent",
                                defaultMessage: "Keine gesendete Dateien vorhanden"
                            })}/>}
            </div>
        );
    }

}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    outbox: state.upload.outbox,
    employers: state.app.employers,
    outboxSuccess: state.upload.outboxSuccess,
    uploading: state.upload.uploading,

    filterNameOutbox: state.upload.filterNameOutbox,
    filterFirmaOutbox: state.upload.filterFirmaOutbox,
    filterTypeOutbox: state.upload.filterTypeOutbox,
    filterYearOutbox: state.upload.filterYearOutbox,
    filterMonthOutbox: state.upload.filterMonthOutbox,
    filterOnlyNewOutbox: state.upload.filterOnlyNewOutbox,
});
const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
    filterOutbox: (val) => dispatch(actions.filterOutbox(val)),
    setOutboxPageNo: (val) => dispatch(actions.setOutboxPageNo(val)),
    fetchOutbox: bindActionCreators(fetchOutbox, dispatch),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(injectIntl(SentTab));
