import * as constants from "../../constants/pensionskasse";
import { LOG_OUT } from "../../constants";

export interface ForReviewMutationsStatePK {
  forReviewMutations: any;
  forReviewMutationsPageNo: number;
  moreForReviewMutations: boolean;
  forReviewMutationsFetched: boolean;
  forReviewMutationsCountFetched: boolean;
  forReviewMutationsNameFilter: string;
  forReviewMutationsEventFilter: string;
  forReviewMutationsFirmaFilter: string;
  forReviewMutationsOnlyWarningsFilter: boolean;
  forReviewMutationsOnlyErrorsFilter: boolean;
  forReviewMutationsNoWarningsErrorsFilter: boolean;
  forReviewMutationsProgress: any;
  canSubmitAll: boolean;
  total: number;
  warnings: number;
  errors: number;
}

const getInitialState = () => {
  return {
    forReviewMutations: [],
    forReviewMutationsPageNo: 0,
    moreForReviewMutations: true,
    forReviewMutationsFetched: false,
    forReviewMutationsCountFetched: false,
    forReviewMutationsNameFilter: "",
    forReviewMutationsEventFilter: "",
    forReviewMutationsFirmaFilter: "",
    forReviewMutationsOnlyWarningsFilter: false,
    forReviewMutationsOnlyErrorsFilter: false,
    forReviewMutationsNoWarningsErrorsFilter: false,
    forReviewMutationsProgress: [],
    canSubmitAll: false,
    total: 0,
    warnings: 0,
    errors: 0,
  };
};

const forReviewMutations = (
  state: ForReviewMutationsStatePK = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.FETCHED_FOR_REVIEW_MUTATIONS_BY_PROGRESS: {
      const newItems = action.payload?.filter(
        (item) => !state.forReviewMutations.some((rm) => rm.id === item.id)
      );
      const newForReviewMutations = newItems.concat(state.forReviewMutations);
      return {
        ...state,
        forReviewMutations: newForReviewMutations,
      };
    }

    case constants.FETCHED_FOR_REVIEW_MUTATIONS: {
      const forReviewMutations =
        state.forReviewMutationsPageNo == 0 || action.page == 0
          ? [...action.payload.data]
          : [...state.forReviewMutations, ...action.payload.data];
      const moreForReviewMutations = !!action.payload.links.next;
      return {
        ...state,
        forReviewMutations: forReviewMutations,
        forReviewMutationsFetched: true,
        moreForReviewMutations,
      };
    }

    case constants.FETCHED_CAN_SUBMIT_ALL: {
      return { ...state, canSubmitAll: action.payload.canSubmitAll };
    }

    case constants.SET_FOR_REVIEW_MUTATIONS_PAGE_NO: {
      return { ...state, forReviewMutationsPageNo: action.payload };
    }

    case constants.FETCHED_FOR_REVIEW_MUTATIONS_COUNT: {
      return {
        ...state,
        total: action.payload.total,
        warnings: action.payload.warnings,
        errors: action.payload.errors,
        forReviewMutationsCountFetched: true,
      };
    }

    case constants.FETCHED_FOR_REVIEW_MUTATIONS_PROGRESS: {
      return { ...state, forReviewMutationsProgress: action.payload };
    }

    case constants.SET_FOR_REVIEW_MUTATIONS_FETCHED: {
      return { ...state, forReviewMutationsFetched: false };
    }

    case constants.REMOVE_MUTATION_MANUAL_COMMIT: {
      let errors = state.errors;
      let warnings = state.warnings;
      const updatedMutationslist = state.forReviewMutations.filter((item) => {
        if (item.id === action.payload && item.attributes.hasError) {
          errors--;
        }
        if (item.id === action.payload && item.attributes.warning) {
          warnings--;
        }
        return item.id !== action.payload;
      });
      return {
        ...state,
        forReviewMutations: updatedMutationslist,
        total: updatedMutationslist.length,
        warnings,
        errors,
      };
    }

    case constants.SET_FOR_REVIEW_MUTATIONS_FILTERING: {
      switch (action.payload.name) {
        case "forReviewMutationsNameFilter":
        case "deletedMutationsNameFilter":
        case "acceptedMutationsNameFilter":
          return {
            ...state,
            forReviewMutationsNameFilter: action.payload.value,
          };
        case "forReviewMutationsEventFilter":
        case "deletedMutationsEventFilter":
        case "acceptedMutationsEventFilter":
          return {
            ...state,
            forReviewMutationsEventFilter: action.payload.value,
          };
        case "forReviewMutationsFirmaFilter":
        case "deletedMutationsFirmaFilter":
        case "acceptedMutationsFirmaFilter":
          return {
            ...state,
            forReviewMutationsFirmaFilter: action.payload.value,
          };
        case "forReviewMutationsOnlyWarningsFilter": {
          const value = action.payload.value;
          return {
            ...state,
            forReviewMutationsOnlyWarningsFilter: action.payload.value,
            forReviewMutationsOnlyErrorsFilter: value
              ? false
              : state.forReviewMutationsOnlyErrorsFilter,
            forReviewMutationsNoWarningsErrorsFilter: value
              ? false
              : state.forReviewMutationsNoWarningsErrorsFilter,
          };
        }
        case "forReviewMutationsOnlyErrorsFilter": {
          const value = action.payload.value;
          return {
            ...state,
            forReviewMutationsOnlyErrorsFilter: action.payload.value,
            forReviewMutationsOnlyWarningsFilter: value
              ? false
              : state.forReviewMutationsOnlyWarningsFilter,
            forReviewMutationsNoWarningsErrorsFilter: value
              ? false
              : state.forReviewMutationsNoWarningsErrorsFilter,
          };
        }
        case "forReviewMutationsNoWarningsErrorsFilter": {
          const value = action.payload.value;
          return {
            ...state,
            forReviewMutationsNoWarningsErrorsFilter: action.payload.value,
            forReviewMutationsOnlyWarningsFilter: value
              ? false
              : state.forReviewMutationsOnlyWarningsFilter,
            forReviewMutationsOnlyErrorsFilter: value
              ? false
              : state.forReviewMutationsOnlyErrorsFilter,
          };
        }

        default: {
          return {
            ...state,
            forReviewMutationsNameFilter: "",
            forReviewMutationsEventFilter: "",
            forReviewMutationsFirmaFilter: "",
            forReviewMutationsOnlyWarningsFilter: false,
            forReviewMutationsOnlyErrorsFilter: false,
            forReviewMutationsNoWarningsErrorsFilter: false,
          };
        }
      }
    }

    case LOG_OUT: {
      return { ...getInitialState() };
    }
  }

  return state;
};

export default forReviewMutations;
