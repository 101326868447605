import React from "react";
import { parseDate } from "../../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
} from "icr-shared-components";
import styles from "./AcceptedMutationExpadableRowTop.module.scss";
import { createMutationContent } from "../../../../utils/mutation";
import classNames from "classnames";
import { ReactComponent as IconContact } from "../../../../assets/icons/personalien.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import Tooltip from "rc-tooltip";

interface Props {
  intl: any;
  mutation: any;
  loading: boolean;
  expanded: boolean;
  onPdfClick: (link) => void;
  toggleExpand: () => void;
  onDetailsClick?: () => void;
}

const AcceptedMutationExpandableRowTop = ({
  mutation,
  onDetailsClick,
  onPdfClick,
  toggleExpand,
  expanded,
  loading,
}: Props) => {
  const isEditable = !!(
    mutation.attributes.mutationType.name !== "Adresslöschung" &&
    mutation.attributes.mutationType.name !== "Lohnlöschung" &&
    mutation.attributes.mutationType.name !== "UnbezahlterUrlaublöschung"
  );

  const hasMoreContent = !!createMutationContent(mutation).more.length;

  return (
    <div className={styles.acceptedMutationExpandableRowTop}>
      <div className={styles.column}>
        <span className={styles.mobileHeader}>Mutationstyp / Name</span>
        <p className="text-small">
          {mutation.attributes?.isRevert && (
            <span className="mr-6">
              <FormattedMessage
                id="mutation.Revert.TypePrefix"
                defaultMessage="Storno"
              />
            </span>
          )}
          {mutation.attributes.mutationType?.translation}
        </p>
        <p>
          <b>{`${mutation.attributes.name} ${mutation.attributes.firstname}`}</b>
        </p>
      </div>

      <div className={styles.column}>
        <span className={styles.mobileHeader}>Gültig ab</span>
        <p>{parseDate(mutation.attributes.keyDate)}</p>
      </div>

      <div className={classNames(styles.column)}>
        {createMutationContent(mutation).content.map(
          ({ prevValue, value, title, translation }) => (
            <>
              <div className={styles.mutationContentRow}>
                <div className={styles.mutationContentRowLeft}>
                  <p className="text-small">
                    <FormattedMessage id={translation} defaultMessage={title} />
                  </p>
                  <span className={styles.mobileHeader}>
                    <FormattedMessage id={translation} defaultMessage={title} />{" "}
                    alt
                  </span>
                  <p>
                    <b>{mutation.attributes?.isRevert ? "-" : prevValue}</b>
                  </p>
                </div>
                <div className={styles.mutationContentRowRight}>
                  <span className={styles.mobileHeader}>
                    <FormattedMessage id={translation} defaultMessage={title} />{" "}
                    neu
                  </span>
                  <p>
                    <b>
                      {mutation.attributes?.isRevert ? (
                        <FormattedMessage
                          id="mutation.Revert.Text"
                          defaultMessage="Storniert"
                        />
                      ) : (
                        value
                      )}
                    </b>
                  </p>
                </div>
              </div>
              <div
                className={classNames(styles.expandable, {
                  [styles.expanded]: expanded,
                })}
              >
                {createMutationContent(mutation).more.map(
                  ({ prevValue, value, title, translation }) => (
                    <div
                      className={classNames(
                        styles.mutationContentRow,
                        styles.more
                      )}
                    >
                      <div className={styles.mutationContentRowLeft}>
                        <p className="text-small">
                          <FormattedMessage
                            id={translation}
                            defaultMessage={title}
                          />
                        </p>
                        <p>
                          <b>{prevValue}</b>
                        </p>
                      </div>
                      <div className={styles.mutationContentRowRight}>
                        <p>
                          <b>
                            {mutation.attributes?.isRevert ? (
                              <FormattedMessage
                                id="mutation.Revert.Text"
                                defaultMessage="Storniert"
                              />
                            ) : (
                              value
                            )}
                          </b>
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>

              {hasMoreContent && (
                <Button
                  className="mt-16"
                  type={ButtonTypes.Text}
                  color={ButtonColors.Action}
                  size={ButtonSizes.Small}
                  onClick={toggleExpand}
                >
                  {expanded ? "Weniger" : "Mehr"}
                </Button>
              )}
            </>
          )
        )}
      </div>

      <div className={styles.column}>
        <span className={styles.mobileHeader}>Mutationsdatum / Bearbeiter</span>
        {/*<p className="text-small">CSV-Import</p>*/}
        <p>{mutation.attributes.username}</p>
        <p className="text-small">{parseDate(mutation.attributes.timeStamp)}</p>
      </div>

      <div className={styles.column}>
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onDetailsClick()}
        >
          <Tooltip
            trigger={["hover"]}
            placement="top"
            overlay={"Versichertendossier"}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
          >
            <IconContact />
          </Tooltip>
        </Button>
      </div>

      {!!mutation.links?.exportPdfsRaw && (
        <div className={styles.row}>
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={(e) => {
              e.stopPropagation();
              onPdfClick(mutation.links.exportPdfsRaw);
            }}
          >
            <FormattedMessage
              id="app.label_tech_doc"
              defaultMessage="Protokoll"
            />
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  loading: state.app.loading,
});
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(AcceptedMutationExpandableRowTop));
