import React from "react";
import { formatNumber, parseDate } from "../../../../utils/react";
import styles from "./InsurantDetailsRowTop.module.scss";
import { Status, StatusColors } from "icr-shared-components";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

interface Props {
  intl: any;
  entities: any;
  insurant: any;
}

const InsurantDetailsRowTop = ({ insurant, entities }: Props) => {
  const isActive = insurant?.employee.isActive;
  const processingState =
    entities?.ProcessingStates?.values[insurant?.employee.processingState];
  return (
    <div className={styles.insurantDetailsRowTop}>
      <div className={styles.column}>
        <span className={styles.headerText}>Name</span>
        <p>
          <b>{insurant.person.name + " " + insurant.person.firstname}</b>
        </p>
      </div>
      <div className={styles.column}>
        <span className={styles.headerText}>Geburtsdatum</span>
        <p>{parseDate(insurant.person.birthDate, "yyyy/dd")}</p>
      </div>
      <div className={styles.column}>
        <span className={styles.headerText}>
          <FormattedMessage
            id="app.insurantDetails.card.top.socialSecurityNumber"
            defaultMessage="AHV-Nummer"
          />
        </span>
        <p>{insurant.person.socialSecurityNumber}</p>
      </div>
      <div className={styles.column}>
        <span className={styles.headerText}>
          <FormattedMessage
            id="app.insurantDetails.card.top.grossSalary"
            defaultMessage="Lohn"
          />
        </span>
        <p>
          {!!(insurant.payroll && insurant.payroll.grossSalary) &&
            "CHF " + formatNumber(insurant.payroll.grossSalary)}
        </p>
      </div>
      <div className={styles.column}>
        <span className={styles.headerText}>Status</span>
        <Status
          text={!isActive ? "inaktiv" : "aktiv"}
          color={!isActive ? StatusColors.DISABLED : StatusColors.HIGHLIGHT}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(InsurantDetailsRowTop));
