import * as constants from '../constants';
import de from '../constants/locales/agp/de.json';
import en from '../constants/locales/agp/en.json';
import fr from '../constants/locales/agp/fr.json';
import it from '../constants/locales/agp/it.json';

import dePkc from '../constants/locales/pkc/de.json';
import enPkc from '../constants/locales/pkc/en.json';
import frPkc from '../constants/locales/pkc/fr.json';
import itPkc from '../constants/locales/pkc/it.json';

export interface LocalesState {
    en: any;
    it: any;
    de: any;
    fr: any;
    fetchedTranslations: boolean;
}

const getInitialState = () => {
    const isPensionskasse = window.location.pathname.includes("/pensionskasse/");
    return isPensionskasse ? {
        en: enPkc,
        it: itPkc,
        de: dePkc,
        fr: frPkc,
        fetchedTranslations: false
    }: {
        en: en,
        it: it,
        de: de,
        fr: fr,
        fetchedTranslations: false
    }
};

const localesReducer = (state: any = getInitialState(), action: any) => {
    switch (action.type) {
        case constants.FETCHED_TRANSLATIONS: {
            console.log(action.payload);
            if(!action.payload) {
                return { ...state };
            }
            return {
                ...state,
                de: { ...state.de, ...action.payload },
                fetchedTranslations: true,
            };
        }
    }
    return state;
};

export default localesReducer;
