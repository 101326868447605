import {
    Button,
    ButtonColors,
    ButtonSizes,
    ButtonTypes,
    Input,
    ValidationMessageType,
} from "icr-shared-components";
import {IInputConfig} from "icr-shared-components/dist/components/Input";
import React, {useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {RootState} from "../../../../reducers";
import {isMonth, isYear} from "../../../../utils/validationTests";

interface SideBarProps extends RootState {
    type: "Inbox" | "Outbox";
    filter: (name, value, type?, employers?) => void;
    firmaOptions: any[];
    documentTypes: string[];
    documentTypeOptions: IInputConfig[];

    filterNameOutbox: any;
    filterFirmaOutbox: any;
    filterTypeOutbox: any;
    filterYearOutbox: any;
    filterMonthOutbox: any;
    filterOnlyNewOutbox: any;
    employers: any[];
    filterNameInbox: any;
    filterFirmaInbox: any;
    filterTypeInbox: any;
    filterYearInbox: any;
    filterMonthInbox: any;
    filterOnlyNewInbox: any;
}
function SideBar(props: SideBarProps) {
    const [openFrimaDropDown, setOpenFirmaDropDown] = useState(false);
    const [openTypeDropDown, setOpenTypeDropDown] = useState(false);

    const validateDateInputs = (type, value) => {
        const error = {
            config: {type: ValidationMessageType.Error, message: ""},
            visible: true
        };
        if (type === "month") {
            if (!isMonth(value)) {
                return error;
            }
        }
        if (type === "year") {
            if (!isYear(value)) {
                return error;
            }
        }
    };

    return (
        <div>
            <div className="paddingContainer">
                <p className="colorGrey mb-4">Datei suchen</p>
                <Input
                    id={`filterName${props.type}`}
                    name={`filterName${props.type}`}
                    key={`filterName${props.type}`}
                    inputWrapperClass="text-input filter-input"
                    placeholder={"Dokumente, Dateien, etc."}
                    value={props[`filterName${props.type}`]}
                    onChange={(name, value) => props.filter(name, value)}
                    validationMessageConfig={null}
                    type="text"
                    inputWrapperStyle={{}}
                    filtering
                />
                <div>
                    <Input
                        id={`filterFirma${props.type}`}
                        name={`filterFirma${props.type}`}
                        key={`filterFirma${props.type}`}
                        inputWrapperClass="dropdown-input filter-input"
                        placeholder={"Firma wählen"}
                        value={props.employers && props.employers[props[`filterFirma${props.type}`]] ? props.employers[props[`filterFirma${props.type}`]]["attributes"]["name"] : props[`filterFirma${props.type}`]}
                        onSelected={(name, value) =>
                            props[`filterFirma${props.type}`] != value &&
                            props.filter(name, value)
                        }
                        onChange={(name, value) => props.filter(name, value, null, true)}
                        onDropdownOpen={(isOpen) =>
                            setOpenFirmaDropDown(isOpen)
                        }
                        onFocus={() => setOpenFirmaDropDown(true)}
                        onBlur={() => setOpenFirmaDropDown(false)}
                        isOpen={openFrimaDropDown}
                        selectOptions={props.firmaOptions}
                        validationMessageConfig={null}
                        type="text"
                        inputWrapperStyle={{}}
                        sort
                    />
                </div>
                <div>
                    <Input
                        id={`filterType${props.type}`}
                        name={`filterType${props.type}`}
                        key={`filterType${props.type}`}
                        inputWrapperClass="dropdown-input filter-input"
                        placeholder={"Dokumententyp wählen"}
                        value={props.documentTypes && props.documentTypes[props[`filterType${props.type}`]] ? props.documentTypes[props[`filterType${props.type}`]]["attributes"]["filterValue"] : props[`filterType${props.type}`]}
                        onSelected={(name, value) =>
                            props[`filterType${props.type}`] != value &&
                            props.filter(name, value)
                        }
                        onChange={(name, value) => props.filter(name, value)}
                        onDropdownOpen={(isOpen) => setOpenTypeDropDown(isOpen)}
                        onFocus={() => setOpenTypeDropDown(true)}
                        onBlur={() => setOpenTypeDropDown(false)}
                        isOpen={openTypeDropDown}
                        selectOptions={props.documentTypeOptions}
                        validationMessageConfig={null}
                        type="text"
                        inputWrapperStyle={{}}
                        filtering
                        sort/>
                </div>
                <p className="colorGrey mb-3">
                    Nach Datum suchen
                </p>
                <div className="row">
                    <div className="col-6">
                        <Input
                            id={`filterYear${props.type}`}
                            name={`filterYear${props.type}`}
                            key={`filterYear${props.type}`}
                            inputWrapperClass="filter-input"
                            placeholder={"Jahr"}
                            value={props[`filterYear${props.type}`]}
                            onChange={(name, value) =>
                                !!(props[`filterYear${props.type}`] != value) &&
                                props.filter(name, value, "year")
                            }
                            validationMessageConfig={validateDateInputs(
                                "year",
                                props[`filterYear${props.type}`]
                            )}
                            type="year"
                            inputWrapperStyle={{}}
                            filtering/>
                    </div>
                    <div className="col-6">
                        <Input
                            disabled={!isYear(props[`filterYear${props.type}`]) || !props[`filterYear${props.type}`]}
                            id={`filterMonth${props.type}`}
                            name={`filterMonth${props.type}`}
                            key={`filterMonth${props.type}`}
                            inputWrapperClass="filter-input"
                            placeholder={"Monat"}
                            value={props[`filterMonth${props.type}`]}
                            onChange={(name, value) => {
                                (props[`filterMonth${props.type}`] != value) && props.filter(name, value, "month")
                            }}
                            validationMessageConfig={validateDateInputs(
                                "month",
                                props[`filterMonth${props.type}`]
                            )}
                            type="month"
                            inputWrapperStyle={{}}
                            filtering/>
                    </div>
                </div>
                {props.type=="Inbox" && <div>
                    <Input
                        filtering
                        name={`filterOnlyNew${props.type}`}
                        checked={props[`filterOnlyNew${props.type}`]}
                        checkboxLabel={"Nur neue zeigen"}
                        onChange={(name, value) => props.filter(name, value)}
                        type="checkbox"
                        inputWrapperClass="filter-input checkbox-input"/>
                </div>}
                <div className="sidebar-clear-button-wrapper">
                    <Button
                        type={ButtonTypes.Text}
                        onClick={() => props.filter("clear", null)}
                        color={ButtonColors.Action}
                        size={ButtonSizes.Small}>
                        <FormattedMessage id="app.resettodefault" defaultMessage="Zurücksetzen"/>
                    </Button>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,

        filterNameOutbox: state.upload.filterNameOutbox,
        filterFirmaOutbox: state.upload.filterFirmaOutbox,
        filterTypeOutbox: state.upload.filterTypeOutbox,
        filterYearOutbox: state.upload.filterYearOutbox,
        filterMonthOutbox: state.upload.filterMonthOutbox,
        filterOnlyNewOutbox: state.upload.filterOnlyNewOutbox,

        filterNameInbox: state.upload.filterNameInbox,
        filterFirmaInbox: state.upload.filterFirmaInbox,
        filterTypeInbox: state.upload.filterTypeInbox,
        filterYearInbox: state.upload.filterYearInbox,
        filterMonthInbox: state.upload.filterMonthInbox,
        filterOnlyNewInbox: state.upload.filterOnlyNewInbox,
    };
}

export default connect(mapStateToProps)(injectIntl(SideBar));
