import React from "react";
import { formatNumber, parseDate } from "../../../../utils/react";
import styles from "./InsurantDetailsRowBotton.module.scss";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import { mutationFormTypes } from "../../../../constants";
import { ReactComponent as IconPen } from "../../../../assets/icons/pen.svg";
import Tooltip from "rc-tooltip";

interface Props {
  intl: any;
  entities: any;
  employers: any;
  insurant: any;
  onEditClick: any;
}

const InsurantDetailsRowBottom = ({
  insurant,
  entities,
  employers,
  onEditClick,
}: Props) => {
  const lohn =
    !!insurant.payroll?.grossSalary &&
    "CHF " + formatNumber(insurant.payroll.grossSalary);
  const bonus =
    !!insurant.payroll?.bonus && "CHF " + formatNumber(insurant.payroll.bonus);
  const employerName =
    employers?.[insurant.employee.employerId]?.attributes.name;
  const kassennummer =
    employers?.[insurant.employee.employerId]?.attributes.extParentId;
  return (
    <div className={styles.insurantDetailsRowBottom}>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          <FormattedMessage
            id="app.insurantDetails.card.bottom.grossSalary"
            defaultMessage="Lohn"
          />
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{lohn}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Bonus
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{bonus}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Zivilstand
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Zivilstand)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{entities?.CivilStatus?.values[insurant.person.civilStatus]}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Versicherungsnummer
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{insurant.employee.insEmployeeId}</p>
      </div>

      <div className={styles.column} />

      <div className={styles.break} />

      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          <FormattedMessage
            id="app.insurantDetails.card.bottom.grossSalary.validFrom"
            defaultMessage="Lohn gültig ab"
          />
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{parseDate(insurant.payroll?.validFrom)}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Eintrittsdatum
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{parseDate(insurant.employee.entryDate, "yyyy/dd")}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Arbeitgeber
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{employerName}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Kassennummer
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{kassennummer}</p>
      </div>

      <div className={styles.column} />

      <div className={styles.break} />

      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          <FormattedMessage
            id="app.insurantDetails.card.bottom.activityRate"
            defaultMessage="Arbeitspensum"
          />
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewPayroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>
          {insurant.payroll?.activityRate}
          {insurant.payroll?.activityRate ? "%" : null}
        </p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Adresse
          <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.NewAddress)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>
          <span>
            {insurant.address?.address1 + " "}
            <br />
            {insurant.address?.zipCode + " "}
            {insurant.address?.city}
          </span>
        </p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          <FormattedMessage
            id="app.mutationcard.values.person.costCenter"
            defaultMessage="Kostenstelle"
          />
          <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div>
        </span>
        <p>{insurant.employee.costCenter}</p>
      </div>

      <div className={styles.column} />

      <div className={styles.column} />
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(InsurantDetailsRowBottom));
