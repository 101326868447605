import * as constants from '../../constants';
import {LOG_OUT} from "../../constants";

export interface UnpaidvacationsStatePK {
    unpaidVacations: any;

}

const getInitialState = () => {
    return {
        unpaidVacations: {},
    }
};

// console.log("app.ts", getInitialState());

const unpaidvacations = (state: UnpaidvacationsStatePK = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.FETCHED_UNPAIDVACATIONS: {
            const unpaidVacationsObj = {};


            action.payload.map((entity) => {
                const contributionCoverage = entity.attributes.contributionCoverage;
                if(contributionCoverage == 1) {
                    unpaidVacationsObj[entity.id] = {
                        ...entity.attributes,
                        contributionCoverage1: "Versicherter zahlt Arbeitgeber- und Arbeitnehmerbeiträge",
                        contributionCoverage2: "-"
                    };
                } else if(contributionCoverage == 2) {
                    unpaidVacationsObj[entity.id] = {
                        ...entity.attributes,
                        contributionCoverage1: "Versicherter und Arbeitgeber zahlen jeweilige Beiträge",
                        contributionCoverage2: "-"
                    };
                } else if(contributionCoverage == 3) {
                    unpaidVacationsObj[entity.id] = {
                        ...entity.attributes,
                        contributionCoverage1: "-",
                        contributionCoverage2: "Versicherter zahlt Arbeitgeber- und Arbeitnehmerbeiträge"
                    };
                } else if(contributionCoverage == 4) {
                    unpaidVacationsObj[entity.id] = {
                        ...entity.attributes,
                        contributionCoverage1: "-",
                        contributionCoverage2: "Versicherter und Arbeitgeber zahlen jeweilige Beiträge"
                    };
                } else {
                    unpaidVacationsObj[entity.id] = {
                        ...entity.attributes,
                        contributionCoverage1: "-",
                        contributionCoverage2: "-"
                    };
                }
            })
            console.log("UPOBJ", unpaidVacationsObj)
            return { ...state, unpaidVacations: unpaidVacationsObj };
        }

        case LOG_OUT: {
            return { ...getInitialState() };
        }

    }

    return state;
};

export default unpaidvacations;
