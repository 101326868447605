import React, {FC, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {Input} from "icr-shared-components";

import {getMutationTypes} from "../../selectors";
import {prepareMutationTypesDropdownOptions} from "../../utils/react";
import styles from './MutationTypeDropdown.module.scss';

interface MutationTypeDropdownProps {
    data: readonly object[];
    setFilteredData: (value: any) => void;
}

const MutationTypeDropdown: FC<MutationTypeDropdownProps> = ({data, setFilteredData}) => {
    const mutationTypes = useSelector(getMutationTypes);
    const mutationTypesArray = Object.values(mutationTypes);
    const [value, setValue] = useState('');
    const [filterOptions, setFilterOptions] = useState([]);
    const [mutationTypesOptions, setMutationTypesOptions] = useState(prepareMutationTypesDropdownOptions(mutationTypes));

    useEffect(() => {
        if (!isEmpty(mutationTypes)) {
            const options = prepareMutationTypesDropdownOptions(mutationTypes);

            setMutationTypesOptions(options);
            setFilterOptions(options);
        }
    }, [mutationTypes])

    const filteredData = (item: any) => {
        if (!item) {
            setFilteredData(data);
        }

        if (!isEmpty(data) && item.attributes && item.attributes.name) {
            const filtered = data.filter((dataItem: any) => dataItem.attributes.mutationType.name === item.attributes.name)

            setFilteredData(filtered);
        }
    }

    const handleFilterOnChange = (name, value) => {
        if (!value) {
            setValue('');
            filteredData('');
        }

        setValue(value);

        const mutationType: any = mutationTypesOptions.filter(type =>
            type.label.toLowerCase().includes(value.trim().toLowerCase()));

        if (!isEmpty(mutationType)) {
            setFilterOptions(mutationType);
        }
    }

    const handleFilterSelected = (name, value) => {
        if (!value) {
            setValue('');
            filteredData('');
        }

        const mutationType: any = mutationTypesArray.find(type => type["attributes"]["translation"] === value);

        if (mutationType && mutationType.attributes && mutationType.attributes.name) {
            setValue(mutationType.attributes.translation);
            filteredData(mutationType);
        }
    }

    if (!isEmpty(filterOptions)) {
        return (
            <Input
                type='text'
                name="mutationsFilter"
                placeholder={"Alle Mutationen"}
                filtering
                inputWrapperClass={styles.dropdownInputTypes}
                selectOptions={filterOptions}
                value={value}
                onSelected={handleFilterSelected}
                onChange={handleFilterOnChange}
            />
        );
    }

    return null;
};

export default MutationTypeDropdown;
