import { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view.svg";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login } from "../../../../actions/auth";
import { Controller, useForm } from "react-hook-form";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../utils/validationTools";

interface Props {
  intl: any;
  login: any;
  history: any;
}

function Login(props: Props) {
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const isPensionskasse = window.location.pathname.includes("/pensionskasse/");

  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      username: "",
      password: "",
    },
  });

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      formCustomization: {
        components: {
          username: {
            required: true,
          },
          password: {
            required: true,
          },
        },
        messages: {},
      },
      requiredFields: {
        username: !!getValues("username"),
        password: !!getValues("password"),
      },
    });
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const togglePasswordVisible = () => {
    setPasswordInputType(passwordInputType == "password" ? "text" : "password");
  };

  const onClickPasswordReset = () => {
    props.history.push(`?password-reset=1&user=${getValues("username")}`);
  };

  const onSubmit = () => {
    setIsLoading(true);

    props.login(getValues()).finally(() => setIsLoading(false));
  };

  return (
    <div>
      <div className="login-title">
        {isPensionskasse ? "PK Cockpit" : "Arbeitgeber-Portal"}
      </div>
      <form
        onSubmit={(e) => {
          console.log("onsubmit", e);
          e.preventDefault();
        }}
      >
        <Controller
          control={control}
          name="username"
          render={({ field: { value } }) => (
            <Input
              type="text"
              value={value}
              name="username"
              placeholder="Benutzername eingeben"
              onChange={(name, valueInput) => {
                setValue("username", valueInput);
                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "username",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );
                setError("username", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "username",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );
                setError("username", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              validationMessageConfig={{
                visible: false,
                config: {
                  message: errors?.username?.message,
                  type: errors?.username?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id="app.username"
                defaultMessage="Benutzername"
              />
            </Input>
          )}
        />
        <div className="mt-40">
          <Controller
            control={control}
            name="password"
            render={({ field: { value } }) => (
              <Input
                inputWrapperClass="mt-24"
                type={passwordInputType}
                value={value}
                name="password"
                placeholder="Passwort eingeben"
                onChange={(name, inputValue) => {
                  setValue("password", inputValue);

                  const validation: ValidationMessage = validateInputMessage(
                    formConfig.formCustomization,
                    getValues(),
                    "password",
                    () => [EValidationTestType.hasValue],
                    formConfig.requiredFields
                  );
                  setError("password", validation);
                  validation?.valid &&
                    setFormConfig({
                      ...formConfig,
                      requiredFields: validation?.valid,
                    });
                }}
                onBlur={() => {
                  const validation: ValidationMessage = validateInputMessage(
                    formConfig.formCustomization,
                    getValues(),
                    "password",
                    () => [EValidationTestType.hasValue],
                    formConfig.requiredFields
                  );
                  setError("password", validation);
                  validation?.valid &&
                    setFormConfig({
                      ...formConfig,
                      requiredFields: validation?.valid,
                    });
                }}
                validationMessageConfig={{
                  visible: false,
                  config: {
                    message: errors?.password?.message,
                    type: errors?.password?.type as ValidationMessageType,
                  },
                }}
              >
                <FormattedMessage id="app.password" defaultMessage="Passwort" />
                <ViewIcon
                  onClick={togglePasswordVisible}
                  className={classNames("icon-show-password", {
                    gray: passwordInputType == "password",
                  })}
                />
              </Input>
            )}
          />
        </div>
        <Button
          className="ml-auto mt-32"
          onClick={onClickPasswordReset}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          <FormattedMessage
            id="app.forgotpassword"
            defaultMessage="PASSWORT VERGESSEN"
          />
        </Button>
        <Button
          submit
          className="mt-24"
          onClick={() => onSubmit()}
          type={ButtonTypes.Standard}
          color={ButtonColors.Action}
          style={ButtonStyles.Primary}
          isDisabled={isDisabledButton || isLoading}
        >
          <FormattedMessage id="app.signin" defaultMessage="ANMELDEN" />
        </Button>
      </form>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  login: bindActionCreators(login, dispatch),
});
const connector = connect(null, mapDispatchToProps);
export default withRouter(connector(injectIntl(Login)));
