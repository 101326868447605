import * as React from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import { ReactComponent as WarningOutlinedIcon } from "../../../../assets/icons/Warning_Icon_Outlined.svg";
import "./style.scss";

interface IProps {
  intl: any;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const TakeOverPopup = (props: IProps) => {
  return (
    <div>
      <h1 className="mb-16">Mutationen übernehmen</h1>

      <div className="row">
        <WarningOutlinedIcon className="waring-icon ml-4" />
        <p className="mb-16 ml-10">Sie haben keine Firma ausgewählt</p>
      </div>

      <p className="mb-32">Wollen Sie die Mutation wirklich übernehmen?</p>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={props.onSubmit}
        >
          JETZT ÜBERNEHMEN
        </Button>
        <Button
          type={ButtonTypes.Standard}
          color={ButtonColors.Action}
          style={ButtonStyles.Primary}
          onClick={props.onCancel}
          isDisabled={props.loading}
        >
          <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: RootState, ownProps: any) => ({
  loading: state.app.loading,
});
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(TakeOverPopup));
