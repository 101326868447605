import * as React from "react";
import { Component } from "react";
import { Blank, ExpandableRow, GroupPositions } from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import DeletedMutationExpandableRowTop from "../DeletedMutationExpandableRowTop";
import styles from "./DeletedTab.module.scss";
import { modalPageTypes } from "../../../../constants";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DeletedMutationExpandableRowBottom from "../DeletedMutationExpandableRowBottom";
import classNames from "classnames";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../../actions";
import {
  fetchDeletedMutations,
  setDeletedMutationsFilter,
  setDeletedMutationsPageNo,
} from "../../../../actions/pensionskasse/mutations";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import AcceptedMutationExpandableRowTop from "../AcceptedMutationExpandableRowTop";

interface State {
  expandedRow: any;
  expandedMore: any;
  filtersChanged: number;
}

interface Props extends RouteComponentProps {
  intl: any;
  refreshData: any;
  mutations: any;
  expandedRow: any;
  filtersChanged: any;
  setLoading: any;
  onPdfClick: any;

  deletedMutationsNameFilter: any;
  deletedMutationsEventFilter: any;
  deletedMutationsFirmaFilter: any;
  deletedMutationsTakeoverDateFrom: any;
  deletedMutationsTakeoverDateUntil: any;
  deletedMutationsMutationDateFrom: any;
  deletedMutationsMutationDateUntil: any;

  deletedMutationsFetched: boolean;
  fetchDeletedMutations: any;
  deletedMutationsPageNo: any;
  setDeletedMutationsPageNo: any;
  setDeletedMutationsFilter: any;
}

const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || "20";

class DeletedTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expandedRow: null,
      expandedMore: null,
      filtersChanged: 0,
    };
  }

  componentDidMount() {
    this.props.fetchDeletedMutations(
      this.props.deletedMutationsPageNo * parseInt(paginationSize),
      0
    );
  }

  onDetailsClick = (insurantId) => {
    this.props.history.push(
      `/pensionskasse/versicherte/personen?edit=${insurantId}&content=${modalPageTypes.INSURANT_DETAILS_PK}&origin=${this.props.location.pathname}`
    );
  };

  rowChanged = (expanded, row) => {
    this.setState({ expandedRow: row });
  };

  determineGroupPosition = (mutations, index) => {
    if (!mutations || !mutations.length || mutations.length == 1) return null;
    const length = mutations.length;
    if (index == 0) {
      return GroupPositions.Top;
    } else if (index == length - 1) {
      return GroupPositions.Bottom;
    } else {
      return GroupPositions.Middle;
    }
  };

  clearFilters = () => {
    this.props.setDeletedMutationsPageNo(0);
    this.props.setDeletedMutationsFilter({ name: "clear" });
    this.props.setLoading(true);
    this.props.fetchDeletedMutations().then(() => {
      this.props.setLoading(false);
      this.props.setDeletedMutationsPageNo(1);
    });
  };

  filtersAreApplied = () => {
    return !!(
      this.props.deletedMutationsNameFilter ||
      this.props.deletedMutationsEventFilter ||
      this.props.deletedMutationsFirmaFilter ||
      this.props.deletedMutationsTakeoverDateFrom ||
      this.props.deletedMutationsTakeoverDateUntil ||
      this.props.deletedMutationsMutationDateFrom ||
      this.props.deletedMutationsMutationDateUntil
    );
  };

  render() {
    return (
      <>
        <h1 className={styles.pageTitle}>Gelöschte Mutationen</h1>
        {!!(
          this.props.deletedMutationsFetched || this.props.mutations.length
        ) && (
          <div className={styles.listView}>
            <div className={styles.listHeader}>
              <div className={styles.column}>
                <FormattedMessage
                  id="app.type"
                  defaultMessage="Mutationstyp / Name"
                />
              </div>
              <div className={styles.column}>
                <FormattedMessage
                  id="app.validFrom"
                  defaultMessage="Gültig ab"
                />
              </div>
              <div className={styles.column}>
                <span>
                  <FormattedMessage
                    id="app.pervValue"
                    defaultMessage="bisher"
                  />
                </span>
                <span>
                  <FormattedMessage id="app.new" defaultMessage="neu" />
                </span>
              </div>
              <div className={styles.column}>
                <FormattedMessage
                  id="app.source"
                  defaultMessage="Mutationsdatum / Bearbeiter"
                />
              </div>
              <div className={styles.column}>
                <div style={{ width: "5rem" }} />
              </div>
            </div>
            {this.props.mutations.length ? (
              this.props.mutations.map(
                (company, companyIndex) =>
                  !!(company.employees && company.employees.length) && (
                    <React.Fragment
                      key={`deleted_mutation_row_${companyIndex}_${company.id}`}
                    >
                      <div className={styles.groupHeader}>{company.name}</div>

                      {company.employees.map((employee, employeeIndex) =>
                        employee.mutations.map((mutation, mutationIndex) => {
                          const groupPosition = this.determineGroupPosition(
                            employee.mutations,
                            mutationIndex
                          );
                          const index =
                            ++companyIndex + ++employeeIndex + ++mutationIndex;
                          console.log(index);
                          return (
                            <ExpandableRow
                              expandable
                              index={index}
                              groupPosition={groupPosition}
                              key={
                                this.state.expandedMore == index
                                  ? `deleted_mutation_row_${index}_${this.props.filtersChanged}`
                                  : `deleted_mutation_row_${index}`
                              }
                              expanded={this.state.expandedRow}
                              length={this.props.filtersChanged}
                              className={classNames({
                                "mb-24":
                                  groupPosition == GroupPositions.Bottom ||
                                  !groupPosition,
                              })}
                              rowChanged={(expanded) =>
                                this.rowChanged(expanded, index)
                              }
                              more={
                                <DeletedMutationExpandableRowBottom
                                  mutation={mutation}
                                />
                              }
                              content={
                                <DeletedMutationExpandableRowTop
                                  mutation={mutation}
                                  expanded={this.state.expandedMore == index}
                                  onPdfClick={(link) =>
                                    this.props.onPdfClick(link)
                                  }
                                  onDetailsClick={() =>
                                    this.onDetailsClick(
                                      mutation.attributes.employeeId
                                    )
                                  }
                                  toggleExpand={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      expandedMore:
                                        this.state.expandedMore == index
                                          ? null
                                          : index,
                                      filtersChanged:
                                        this.state.filtersChanged + 1,
                                    });
                                  }}
                                />
                              }
                            />
                          );
                        })
                      )}
                    </React.Fragment>
                  )
              )
            ) : (
              <Blank
                onButtonClick={this.clearFilters}
                // buttonText="Filter zurücksetzen"
                showButton={this.filtersAreApplied()}
                text={this.props.intl.formatMessage({
                  id: "app.nomutations",
                  defaultMessage: "Keine Mutationen vorhanden",
                })}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
  fetchDeletedMutations: bindActionCreators(fetchDeletedMutations, dispatch),
  setDeletedMutationsPageNo: (val) => dispatch(setDeletedMutationsPageNo(val)),
  setDeletedMutationsFilter: (val) => dispatch(setDeletedMutationsFilter(val)),
});
const mapStateToProps = (state: RootState, ownProps: any) => ({
  ...ownProps,
  deletedMutationsFetched: state.deletedMutations.deletedMutationsFetched,
  deletedMutationsPageNo: state.deletedMutations.deletedMutationsPageNo,
  deletedMutationsNameFilter: state.deletedMutations.deletedMutationsNameFilter,
  deletedMutationsEventFilter:
    state.deletedMutations.deletedMutationsEventFilter,
  deletedMutationsFirmaFilter:
    state.deletedMutations.deletedMutationsFirmaFilter,
  deletedMutationsTakeoverDateFrom:
    state.deletedMutations.deletedMutationsTakeoverDateFrom,
  deletedMutationsTakeoverDateUntil:
    state.deletedMutations.deletedMutationsTakeoverDateUntil,
  deletedMutationsMutationDateFrom:
    state.deletedMutations.deletedMutationsMutationDateFrom,
  deletedMutationsMutationDateUntil:
    state.deletedMutations.deletedMutationsMutationDateUntil,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(DeletedTab)));
