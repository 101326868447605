import * as constants from '../../constants/pensionskasse';
import {LOG_OUT} from "../../constants";

export interface InsurantsStatePK {
    insurants: any;
    insurantsFetched: boolean;
    insurantsPageNo: number;
    moreInsurants: boolean;
    insurantsNameFilter: string;
    insurantsFirmaFilter: string;
    insurantsStatusFilter: string;
}

const getInitialState = () => {
    return {
        insurants: [],
        insurantsFetched: false,
        insurantsPageNo: 0,
        moreInsurants: true,
        insurantsNameFilter: "",
        insurantsFirmaFilter: "",
        insurantsStatusFilter: ""
    }
};

const insurants = (state: InsurantsStatePK = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.FETCHED_INSURANTS: {
            const insurants = state.insurantsPageNo == 0 ? [ ...action.payload ] : [ ...state.insurants, ...action.payload ];
            return {
                ...state,
                insurants: insurants,
                insurantsFetched: true
            }
        }

        case constants.SET_INSURANTS_PAGE_NO: {
            let moreInsurants = !(state.insurants.length < ((state.insurantsPageNo + 1) * constants.PAGINAION_SIZE));
            console.log("MORE INSURANTS", moreInsurants);
            console.log("MORE INSURANTS", state.insurants.length, (state.insurantsPageNo + 1) * constants.PAGINAION_SIZE);
            return { ...state, insurantsPageNo: action.payload, moreInsurants: moreInsurants}
        }

        case constants.SET_INSURANTS_FILTERING: {
            switch(action.payload.name) {
                case "insurantsNameFilter":
                    return { ...state, insurantsNameFilter: action.payload.value };
                case "insurantsFirmaFilter":
                    return { ...state, insurantsFirmaFilter: action.payload.value };
                case "insurantsStatusFilter":
                    return { ...state, insurantsStatusFilter: action.payload.value };
                default: {
                    return {
                        ...state,
                        insurantsNameFilter: "",
                        insurantsFirmaFilter: "",
                        insurantsStatusFilter: ""
                    }
                }
            }
        }

        case LOG_OUT: {
            return { ...getInitialState() };
        }

    }

    return state;
};

export default insurants;
