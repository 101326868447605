import * as constants from '../../constants';

export interface InsurantsState {
    insurants: any;
    insurantsAll: any;
    insurantsObj: any;
    insurantsPageNo: any;
    moreInsurants: any;
    filterByNamePerson: any;
    filterByFirmaPerson: any;
    filterByOnlyChangedPerson: any;
    filterByAlsoInactivePerson: any;
    insurantsFetched: boolean;
}

const getInitialState = () => {
    return {
        insurants: [],
        insurantsAll: [],
        insurantsObj: {},
        insurantsPageNo: 0,
        moreInsurants: true,
        filterByNamePerson: "",
        filterByFirmaPerson: "",
        filterByOnlyChangedPerson: false,
        filterByAlsoInactivePerson: false,
        insurantsFetched: false
    }
};

// console.log("app.ts", getInitialState());

const insurants = (state: InsurantsState = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.FETCHED_INSURANTS: {
            // Deep clone
            var insurantsJson = {};
            var insurants = [];
            var insurantsObj = {};
            JSON.parse(JSON.stringify(action.payload)).forEach((insurant) => {
                insurantsJson[insurant.id] = insurant;
            })

            if(state.insurantsPageNo == 0 || action.page == 0) {
                insurants = [ ...action.payload ];
                insurantsObj = { ...insurantsJson };
            } else {
                insurants = [ ...state.insurants, ...action.payload ];
                insurantsObj = { ...state.insurantsObj, ...insurantsJson };
            }

            return { ...state, insurantsObj: insurantsObj, insurants: insurants, insurantsFetched: true };
        }

        case constants.FETCH_UPDATE_INSURANTS: {
            return {
                ...state, 
                insurants: state.insurants.map(item=> item.id === action.contentId ? {...item, attributes: !!action.payloadType ? {...item.attributes, [action.payloadType]:action.payload} : action.payload} : item), 
                insurantsFetched: true
            };
        }

        case constants.SET_INSURANTS_PAGE_NO: {
            // console.log("reducer loading", action);
            let moreInsurants = !(state.insurants.length < ((state.insurantsPageNo + 1) * constants.PAGINAION_SIZE)) || state.insurantsPageNo == 0;
            return { ...state, insurantsPageNo: action.payload, moreInsurants: moreInsurants}
        }

        case constants.SET_INSURANTS_FETCHED: {
            return { ...state, insurantsFetched: action.payload };
        }

        case constants.SET_FILTERING_CRITERIA_PERSONS: {
            if(action.payload.name === "clear") {
                return {
                    ...state,
                    filterByNamePerson: "",
                    filterByFirmaPerson: "",
                    filterByAlsoInactivePerson: false,
                    filterByOnlyChangedPerson: false
                }
            } else if (action.payload.name === "personSearch") {
                return { ...state, filterByNamePerson: action.payload.value };
            } else if (action.payload.name === "firmaSelect") {
                return { ...state, filterByFirmaPerson: action.payload.value };
            } else if (action.payload.name === "alsoInactiveCheckbox") {
                return { ...state, filterByAlsoInactivePerson: action.payload.value };
            } else if (action.payload.name === "onlyChangedChekbox") {
                return { ...state, filterByOnlyChangedPerson: action.payload.value};
            } else {
                return { ...state };
            }
        }

        case constants.LOG_OUT: {
            console.log("log out insurants");
            return { ...getInitialState() };
        }

    }

    return state;
};

export default insurants;
