import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import { parseDate } from "../../../utils/react";
import { ProcessingStates } from "../../../models";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import formName from "../../../constants/forms/formName.json";
// import { formConfig } from './formConfig';
import "./FormIncapacity.scss";
import { FormattedMessage } from "react-intl";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import { Controller, useForm } from "react-hook-form";
import { fetchFormCustomization } from "../../../utils/form";
import { EValidationTestType } from "../../../utils/validationConfigs";
import {
  buildValidationBasedOnEmployerDates,
  formHasChanged,
  validateInputMessage,
} from "../../../utils/validationTools";
import Spinner from "../../Spinner";

interface Props {
  checkFormValidity: any;
  closeModal: any;
  entities?: any;
  exitReasonOptions?: any;
  initialData: any;
  isLoading: boolean;
  sexOptionsEditFormInputs?: any;
  submitAction: any;
  employers: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
}

function FormIncapacity(props: Props) {
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });

  const [initialFormToCompare, setInitialFormToCompare] = useState<any>();
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const tenantCode = globalSettings?.attributes?.tenantCode;

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig]);

  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      employee: {
        entryDate: parseDate(props.initialData.employee.entryDate),
        unemployabilityDate:
          parseDate(props.initialData.employee.unemployabilityDate) || "",
        degreeUnemployability:
          props.initialData.employee.degreeUnemployability || "",
        unemployabilityNote:
          props.initialData.employee.unemployabilityNote || "",
      },
    },
  });

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      tenantCode,
      formName["formName.formIncapacity"]
    ); // cadastrar no json
    if (response.data) {
      const employerConfiguration =
        props.employers[props.initialData.employee.employerId];

      let formComponentsCustomization = {};
      response["data"].attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );
      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("employee")[key])
      );

      // Build custom frontend validation
      formComponentsCustomization = buildValidationBasedOnEmployerDates(
        employerConfiguration,
        formComponentsCustomization,
        "unemployabilityDate"
      );

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: response["data"].attributes?.translations,
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            unemployabilityDate: {
              required: true,
              interval: {
                fieldToCompare: "entryDate",
              },
              date: {
                dayFilter: 0,
              },
            },
            degreeUnemployability: {
              required: true,
            },
            unemployabilityNote: {
              required: true,
            },
          },
          messages: {},
        },
        requiredFields: {
          unemployabilityDate: !!getValues("employee.unemployabilityDate"),
          degreeUnemployability: !!getValues("employee.degreeUnemployability"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("employee"),
        entryDate: parseDate(props.initialData.employee.entryDate),
        unemployabilityDate:
          parseDate(props.initialData.employee.unemployabilityDate) || "",
        degreeUnemployability:
          props.initialData.employee.degreeUnemployability || "",
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  //ask to Yvonne if its necessary
  const haveCustomValidationsValidFrom = (): EValidationTestType[] => {
    let arr = [];
    !!props.employers[props.initialData.employee.employerId]?.attributes
      .changesAllowedFrom && arr.push(EValidationTestType.dateBetween);
    return [...arr, EValidationTestType.dateIsNotBeforeEntrydate];
  };

  return (
    <>
      {props.isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            console.log("onsubmit", e);
            e.preventDefault();
          }}
        >
          <fieldset
            disabled={
              props.initialData.employee.processingState ===
                ProcessingStates.Committed ||
              props.initialData.employee.processingState ===
                ProcessingStates.Exported
            }
          >
            <div className="row">
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name={"employee.unemployabilityDate"}
                  render={({ field: { value } }) => (
                    <Input
                      isFocused
                      type="date"
                      maxLength={10}
                      hidden={
                        formConfig.formCustomization.components[
                          "unemployabilityDate"
                        ]?.hidden
                      }
                      inputWrapperClass="text-input"
                      id="unemployabilityDateInput"
                      name="unemployabilityDate"
                      placeholder=""
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue("employee.unemployabilityDate", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "unemployabilityDate",
                            haveCustomValidationsValidFrom,
                            formConfig.requiredFields
                          );
                        setError("employee.unemployabilityDate", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "unemployabilityDate",
                            haveCustomValidationsValidFrom,
                            formConfig.requiredFields
                          );
                        setError("employee.unemployabilityDate", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message:
                            errors?.employee?.unemployabilityDate?.message,
                          type: errors?.employee?.unemployabilityDate
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.dateofdisability"
                        defaultMessage="Datum der Erwerbsunfähigkeit"
                      />
                      {formConfig.formCustomization.components[
                        "unemployabilityDate"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name={"employee.degreeUnemployability"}
                  render={({ field: { value } }) => (
                    <Input
                      type="number"
                      inputWrapperClass="text-input filter-input"
                      id="degreeUnemployability"
                      hidden={
                        formConfig.formCustomization.components[
                          "degreeUnemployability"
                        ]?.hidden
                      }
                      name="degreeUnemployability"
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("employee.degreeUnemployability", valueInput);

                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "degreeUnemployability",
                            () => [
                              EValidationTestType.isDegree,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("employee.degreeUnemployability", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "degreeUnemployability",
                            () => [
                              EValidationTestType.isDegree,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("employee.degreeUnemployability", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message:
                            errors?.employee?.degreeUnemployability?.message,
                          type: errors?.employee?.degreeUnemployability
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.degreeofdisability"
                        defaultMessage="Erwerbsunfähigkeitsgrad"
                      />
                      {formConfig.formCustomization.components[
                        "degreeUnemployability"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>
              <div className="col-12 col-sm-12">
                <Controller
                  control={control}
                  name={"employee.unemployabilityNote"}
                  render={({ field: { value } }) => (
                    <Input
                      type="content"
                      inputWrapperClass="text-input"
                      id="unemployabilityNote"
                      hidden={
                        formConfig.formCustomization.components[
                          "unemployabilityNote"
                        ]?.hidden
                      }
                      name="unemployabilityNote"
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("employee.unemployabilityNote", valueInput);

                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "unemployabilityNote",
                            null,
                            formConfig.requiredFields
                          );
                        setError("employee.unemployabilityNote", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "unemployabilityNote",
                            null,
                            formConfig.requiredFields
                          );
                        setError("employee.unemployabilityNote", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message:
                            errors?.employee?.unemployabilityNote?.message,
                          type: errors?.employee?.unemployabilityNote
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      {tenantCode === "PKSH" ? (
                        <FormattedMessage
                          id="app.unemployabilityNotePKSH"
                          defaultMessage="Bemerkung (Unfall/Krankheit, Selbst-/Fremdverschuldet, Versicherung/Dossier Krankentaggeld, Datum Ende Lohnfortzahlung)"
                        />
                      ) : (
                        <FormattedMessage
                          id="app.unemployabilityNote"
                          defaultMessage="Bemerkung"
                        />
                      )}
                      {formConfig.formCustomization.components[
                        "unemployabilityNote"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>
              <div className="col-12 col-sm-6"></div>
              <div className="col-12 col-md-6">
                <div className="modal-buttons">
                  <Button
                    onClick={props.closeModal}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    submit={true}
                    isLoading={props.isLoading}
                    type={ButtonTypes.Standard}
                    style={ButtonStyles.Primary}
                    color={ButtonColors.Action}
                    isDisabled={
                      props.isLoading ||
                      isDisabledButton ||
                      !formHasChanged(
                        initialFormToCompare,
                        getValues("employee")
                      )
                    }
                    onClick={(e) => {
                      props.submitAction(e, {
                        ...props.initialData,
                        employee: {
                          ...props.initialData.employee,
                          ...getValues("employee"),
                        },
                      });
                    }}
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    isLoading: state.app.loading,
    employers: state.app.employers,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FormIncapacity);
