import {Button, ButtonColors, ButtonSizes, ButtonTypes, Input} from "icr-shared-components";
import React, {useRef, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {RootState} from "../../../../reducers";
import Drawer from "../../../Drawer";
import * as actions from "../../../../actions";
import memoize from "memoize-one";
import {prepareEmployersDropdownOptions} from "../../../../utils/react";
import {bindActionCreators, Dispatch} from "redux";
import {fetchInvoices} from "../../../../actions/arbeitgeber/invoices";

interface InvoicesSidebarProps extends RootState {
    open: boolean;
    collapseRowsAndShowSpinner;

    intl: any;
    entities;
    employers;
    filterByStatusInvoices;
    filterByFirmaInvoices;
    setInvoicesPageNo;
    filterInvoices;
    setLoading;
    fetchInvoices;
}
function InvoicesSidebar(props: InvoicesSidebarProps) {
    const [openStatusDropDown, setOpenStatusDropDown] = useState(false);
    const [openFirmaDropDown, setOpenFirmaDropDown] = useState(false);
    const firmaOptions = prepareEmployersDropdownOptions(props.employers);

    const debounce = useRef(null);

    const filterInvoices = (name, value, employers?) => {

        console.log(name, value, "FILTER");

        props.filterInvoices({name, value});

        if(employers === true && value !== "") {
            return;
        }

        props.setInvoicesPageNo(0);
        props.setLoading(true);
        clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
            props.fetchInvoices().then(() => {
                props.setLoading(false);
                props.setInvoicesPageNo(props.setInvoicesPageNo + 1);
            })
        }, 500);
    };

    const prepareStatusOptions = memoize((entities) => {
        const options = [];
        if (entities && entities.InvoiceStates && Object.keys(entities.InvoiceStates.values).length) {
            const invoiceStates = entities.InvoiceStates.values;
            Object.keys(invoiceStates).forEach((invoiceState) => {
                options.push({
                    value: invoiceState,
                    label: invoiceStates[invoiceState],
                });
            });
        }
        console.log("will return options: ", options, props.entities.InvoiceStates);
        return options;
    });

    return (
        <div className="paddingContainer">
            <Input
                id="invoicesStatusFilterInput"
                name="statusFilter"
                inputWrapperClass="text-input filter-input"
                placeholder={props.intl.formatMessage({id: "app.invoicestatus", defaultMessage: "Rechnungsstatus"})}
                value={props.entities.InvoiceStates && props.entities.InvoiceStates[props.filterByStatusInvoices] ? props.entities.InvoiceStates[props.filterByStatusInvoices]["attributes"]["name"] : props.filterByStatusInvoices}
                onChange={(name, value) => filterInvoices(name, value)}
                onSelected={(name, value) => props.filterByStatusInvoices != value && filterInvoices(name, value)}
                onDropdownOpen={(isOpen) => setOpenStatusDropDown(isOpen)}
                onFocus={(isOpen) => setOpenStatusDropDown(true)}
                onBlur={() => setOpenStatusDropDown(false)}
                isOpen={openStatusDropDown}
                selectOptions={prepareStatusOptions(props.entities)}
                validationMessageConfig={null /* this.validationMessageConfig() */}
                type="text"
                inputWrapperStyle={{}}
                filtering
                sort
            />
            <Input
                id="invoicesFirmaFilterInput"
                name="firmaFilter"
                inputWrapperClass="text-input filter-input"
                placeholder={props.intl.formatMessage({id: "app.choosecompany", defaultMessage: "Firma wählen"})}
                value={props.employers[props.filterByFirmaInvoices] ? props.employers[props.filterByFirmaInvoices]["attributes"]["name"] : props.filterByFirmaInvoices}
                onChange={(name, value) => filterInvoices(name, value, true)}
                onSelected={(name, value) => props.filterByFirmaInvoices != value && filterInvoices(name, value)}
                onDropdownOpen={(isOpen) => setOpenFirmaDropDown(isOpen)}
                onFocus={(isOpen) => setOpenFirmaDropDown(true)}
                onBlur={() => setOpenFirmaDropDown(false)}
                isOpen={openFirmaDropDown}
                selectOptions={firmaOptions}
                validationMessageConfig={null /* this.validationMessageConfig() */}
                type="text"
                inputWrapperStyle={{}}
                sort
            />
            <Button
                className="ml-auto"
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                size={ButtonSizes.Small}
                onClick={() => filterInvoices('clear', 'clear')}>
                <FormattedMessage id="app.resettodefault" defaultMessage="Zurücksetzen"/>
            </Button>
        </div>
    );
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        entities: state.app.entities,
        filterByStatusInvoices: state.invoices.filterByStatusInvoices,
        filterByFirmaInvoices: state.invoices.filterByFirmaInvoices,
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
        filterInvoices: (val) => dispatch(actions.filterInvoices(val)),
        setInvoicesPageNo: (val) => dispatch(actions.setInvoicesPageNo(val)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(InvoicesSidebar));
