import { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { passwordForgotInit } from "../../../../actions/arbeitgeber/user";
import { Controller, useForm } from "react-hook-form";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../utils/validationTools";

interface Props {
  intl: any;
  history: any;
  passwordForgotInit: any;
}

function ResetPassword(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      username: (props as any).location.search.split("=")[2],
    },
  });

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      formCustomization: {
        components: {
          username: {
            required: true,
          },
        },
        messages: {},
      },
      requiredFields: {
        username: !!getValues("username"),
      },
    });
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const onSubmit = () => {
    setIsLoading(true);
    props
      .passwordForgotInit(getValues("username"))
      .then((res) => {
        setIsLoading(false);
        props.history.push(`?password-reset=4`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="login-title">
          <FormattedMessage
            id="app.resetpassword"
            defaultMessage="Reset Password"
          />
        </div>
        <Controller
          control={control}
          name="username"
          render={({ field: { value } }) => (
            <Input
              type="text"
              name="benutzername"
              value={value}
              onChange={(name, inputValue) => {
                setValue("username", inputValue);

                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "username",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );
                setError("username", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "username",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );
                setError("username", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              validationMessageConfig={{
                visible: false,
                config: {
                  message: errors?.username?.message,
                  type: errors?.username?.type as ValidationMessageType,
                },
              }}
              placeholder={props.intl.formatMessage({
                id: "app.username",
                defaultMessage: "Benutzername",
              })}
            >
              <FormattedMessage
                id="app.username"
                defaultMessage="Benutzername"
              />
            </Input>
          )}
        />
        <Button
          submit
          className="mt-40"
          onClick={() => onSubmit()}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          isDisabled={isDisabledButton || isLoading}
        >
          <FormattedMessage id="app.submit" defaultMessage="Bestätigen" />
        </Button>
      </form>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  passwordForgotInit: bindActionCreators(passwordForgotInit, dispatch),
});
const connector = connect(null, mapDispatchToProps);
export default withRouter(connector(injectIntl(ResetPassword)));
