import * as React from "react";
import classNames from "classnames";

import styles from "./Dropdown.module.scss";
import { IInputConfig } from "../index";
import Option, { Props as OptionInterface } from "./Option";
import { defined } from "../../../utils/variableEvaluation";
import { ReactComponent as IconArrow } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";

const backendPagination = process.env.REACT_APP_BACKEND_PAGINATION;

interface IProps {
  dropdownOptions?: IInputConfig[];
  name?: string;
  value?: string[] | string | number;
  isDisabled: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  onOpen?: (isOpen: boolean) => void;
  onSelected?: (name: string, value: string) => void;
  onBlur?: any;
  filtering?: boolean;
  isOpen?: any;
  onFocus?: any;
  sort?: boolean;
  isCountriesDropdown?: boolean;
  hideClearDropdownIcon?: boolean;
  hideArrowDropdownIcon?: boolean;
}

interface IState {
  isOpen: boolean;
  currentlyActiveIndex: number;
  optionSelected: string | JSX.Element;
  shouldFocus: boolean;
}

export class DropdownInput extends React.Component<IProps, IState> {
  public constructor(props?: any, context?: any) {
    super(props, context);
    this.state = {
      isOpen: false,
      optionSelected: "",
      currentlyActiveIndex: -1,
      shouldFocus: true,
    };
  }

  private listRef = null;

  public componentDidMount() {
    var options = this.props.dropdownOptions;
    if (!this.props.filtering && options.length == 1) {
      this.setState({
        isOpen: false,
        currentlyActiveIndex: parseInt(options[0].value),
        optionSelected: options[0].label,
      });
      this.props.onSelected(this.props.name, options[0].value);
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ) {
    if (!prevProps.isDisabled && this.props.isDisabled) {
      this.setState({ isOpen: false });
    }
  }

  selectOption = (idx) => {
    this.setState({ currentlyActiveIndex: idx });
    if (this.listRef.children && this.listRef.children[idx]) {
      this.listRef.children[idx].scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  };

  private handleKeyPress = (event) => {
    console.log("handleKeypress in Dropdown, currentValue: ", this.props.value);
    event.stopPropagation();
    const code = event.keyCode || event.charCode;
    if (!this.props.readOnly) {
      if (code === 40) {
        // arrow down
        if (
          this.state.currentlyActiveIndex <
          this.props.dropdownOptions.length - 1
        ) {
          if (this.state.currentlyActiveIndex < 0) {
            this.selectOption(0);
          } else {
            this.selectOption(this.state.currentlyActiveIndex + 1);
          }
        }
        if (!this.state.isOpen) {
          this.setState({ isOpen: true });
        }
      } else if (code === 38 && this.state.currentlyActiveIndex > 0) {
        // arrow up
        this.selectOption(this.state.currentlyActiveIndex - 1);
      } else if (code === 27) {
        // escape
        this.setState({ isOpen: false });
      } else if (code === 13) {
        event.preventDefault();
        // enter
        // console.log("currentlyActiveIndex", this.state.currentlyActiveIndex, this.props.dropdownOptions[this.state.currentlyActiveIndex]['dropdownLabel']);
        if (this.state.currentlyActiveIndex >= 0) {
          this.handleOptionClick(
            this.props.dropdownOptions[this.state.currentlyActiveIndex][
              "label"
            ],
            this.state.currentlyActiveIndex
          );
        }
        this.setState({ isOpen: !this.state.isOpen });
      } else if (code === 9) {
        this.setState({ isOpen: false });
      }
    }
  };

  private handleWrapperClick = (e) => {
    console.log("dropdownclicked", e.target);

    if (
      e.target.classList.contains("inputSelectOption") ||
      e.target.classList.contains("selectOptionsWrapper")
    ) {
      return;
    }
    const { isOpen } = this.state;
    const isDisabled = defined(this.props.isDisabled) && this.props.isDisabled;

    if (!this.props.readOnly) {
      if (!isDisabled) {
        this.setState({ isOpen: !isOpen, shouldFocus: true });
        this.props.onOpen && this.props.onOpen(!this.state.isOpen);
      }
    }
  };

  private handleOptionClick = (value: any, idx: number, e?) => {
    console.log("value: ", value);
    e && e.stopPropagation();
    if (value === "close") {
      this.setState({ isOpen: false });
      return;
    }
    if (!this.props.readOnly) {
      let idVal = "";
      if (backendPagination == "true" && this.props.filtering) {
        idVal = idx >= 0 ? this.props.dropdownOptions[idx]["label"] : "";
      } else {
        idVal = idx >= 0 ? this.props.dropdownOptions[idx]["value"] : "";
      }
      // this.props.onChange(this.props.name, value, idVal);
      console.log("IDVAL", idVal);
      this.setState({
        optionSelected: value,
        currentlyActiveIndex: idx,
        isOpen: false,
      });
      this.props.onSelected(this.props.name, idVal);
    }
  };

  private handleFocus = (e) => {
    // console.log("handleFocus");
    if (!this.props.readOnly && !this.props.isDisabled) {
      this.state.shouldFocus && this.setState({ isOpen: true });
    }
    this.props.onFocus && this.props.onFocus();
  };

  private handleBlur = (e) => {
    if (this.props.value != "" || this.props.clearable === false) {
      var value = this.props.dropdownOptions.filter(
        (option) => option.label === this.props.value
      );
      console.log("vallllll", value);
      if (value.length > 0) {
        this.setState({
          isOpen: false,
          currentlyActiveIndex: parseInt(value[0].value),
          optionSelected: value[0].label,
        });
        if (backendPagination == "true" && this.props.filtering) {
          this.props.onSelected(this.props.name, value[0].label);
        } else {
          this.props.onSelected(this.props.name, value[0].value);
        }
      } else {
        this.setState({
          isOpen: false,
          currentlyActiveIndex: -1,
          optionSelected: "",
        });
        this.props.onSelected(this.props.name, "");
      }
    } else if (this.props.value != "") {
      this.setState({
        isOpen: false,
        currentlyActiveIndex: -1,
        optionSelected: "",
      });
      this.props.onSelected(this.props.name, "");
    } else if (this.props.value == "") {
      this.setState({
        isOpen: false,
        currentlyActiveIndex: -1,
        optionSelected: "",
      });
    }
  };

  private renderChildren(child, i): JSX.Element {
    return React.cloneElement(child, {
      key: `InputText ${i}`,
      value: this.props.value,
    });
  }

  handleArrowClick(e) {
    e.stopPropagation();
    let isOpen = this.state.isOpen;
    if (!this.props.readOnly) {
      this.setState({ isOpen: !isOpen });
      this.props.onOpen && this.props.onOpen(!isOpen);
    }
  }

  handleClearClick(e) {
    e.stopPropagation();
    this.handleOptionClick("", -1);
  }

  options = (options, selected, value, selectedIndex) => {
    // if (this.props.sort) {
    //    options.sort((a, b) => {
    //       return Comparator(a.dropdownLabel, b.dropdownLabel, 1, true)
    //    })
    // }

    if (this.props.isCountriesDropdown) {
      const swissIndex = options.findIndex((option) => option?.value == "ch");
      if (swissIndex != -1) {
        const swiss = options[swissIndex];
        options.splice(swissIndex, 1);
        options.unshift(swiss);
      }
    }

    return options.map((option: OptionInterface, index) => {
      // let regexMatch
      // let selected = dropdownOptions.find(option => option.dropdownLabel === value)
      // if (this.state.optionSelected !== value && value !== '' && !selected) {
      //    regexMatch = RegExp(this.props.value ? this.props.value.toString() : '', 'i').test(input.dropdownLabel)
      // } else {
      //    regexMatch = true
      // }
      const isSelected = options.find((option) => option.label == value);
      const matches = RegExp(
        this.props.value ? this.props.value.toString() : "",
        "i"
      ).test(option.label);
      const visible =
        value && selected !== value && !isSelected ? matches : true;
      return (
        visible && (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            selected={index === selectedIndex}
            onOptionClick={(label, e) =>
              this.handleOptionClick(label, index, e)
            }
          />
        )
      );
    });
  };

  public render() {
    const { children, dropdownOptions, value, clearable, isDisabled } =
      this.props;
    const { optionSelected, isOpen, currentlyActiveIndex } = this.state;
    const isOpenProp = this.props.isOpen;
    const clearIcon = !!(value && clearable !== false);

    const classes = classNames(
      styles.dropdown,
      {
        [styles.open]:
          (defined(isOpenProp) && isOpenProp && isOpen) ||
          (!defined(isOpenProp) && isOpen),
      },
      { [styles.disabled]: isDisabled }
    );

    return (
      <div
        className={classes}
        onBlur={this.handleBlur}
        onFocus={(e) => this.handleFocus(e)}
        onMouseDown={() => this.setState({ shouldFocus: false })}
        onClick={(e) => this.handleWrapperClick(e)}
        onKeyDown={(e) => this.handleKeyPress(e)}
      >
        {React.Children.map(children, (child, i) =>
          this.renderChildren(child, i)
        )}

        {!this.props.hideClearDropdownIcon && (
          <div
            className={styles.clear}
            onClick={(e) => this.handleClearClick(e)}
          >
            <IconClose />
          </div>
        )}

        {!this.props.hideArrowDropdownIcon && (
          <div
            className={styles.arrow}
            onClick={(e) => this.handleArrowClick(e)}
          >
            <IconArrow />
          </div>
        )}

        <div
          ref={(div) => (this.listRef = div)}
          className={classNames(styles.options, "selectOptionsWrapper")}
        >
          {this.options(
            dropdownOptions,
            optionSelected,
            value,
            currentlyActiveIndex
          )}
        </div>
      </div>
    );
  }
}
