import {
  FormCustomizationType,
  ValidationMessage,
} from "../@types/forms/agp/formValidation";
import { testsFailed as validationTestFailed } from "./validationCore";
import { InputValidationReturn } from "../@types/forms/formUtils";

export enum EValidationTestType {
  hasValue = "hasValue",
  hasAnyValue = "hasAnyValue",
  acceptAGBS = "acceptAGBS",
  isPasswordMatch = "isPasswordMatch",
  isEmail = "isEmail",
  isPhoneNumber = "isPhoneNumber",
  isPassword = "isPassword",
  isPin = "isPin",
  isSsn = "isSsn",
  isDate = "isDate",
  isFirstDayOfTheMonth = "isFirstDayOfTheMonth",
  isLastDayOfTheMonth = "isLastDayOfTheMonth",
  isBeschaftingungsgrad = "isBeschaftingungsgrad",
  isNumber = "isNumber",
  isPositive = "isPositive",
  isText = "isText",
  isDegree = "isDegree",
  dateBetween = "dateBetween",
  backendValid = "backendValid",
  isActivityRate = "isActivityRate",
  isValidUnpaidVacation = "isValidUnpaidVacation",
  dateIsNotBeforeBirthdate = "dateIsNotBeforeBirthdate",
  dateIsNotBeforeEntrydate = "dateIsNotBeforeEntrydate",
  civilStatusDateNotBeforeBirthDate = "civilStatusDateNotBeforeBirthDate",
  dateIsOneMonthAfterEntryDate = "dateIsOneMonthAfterEntryDate",
  dateIsBeforeDate = "dateIsBeforeDate",
  ageRangeEntryDate = "ageRangeEntryDate",
  ageRangeBirthdate = "ageRangeBirthdate",
  isValidGender = "isValidGender",
  validationMessage = "validationMessage",
  dateIsNotBeforeEntrydateNew = "dateIsNotBeforeEntrydateNew",
  isFifteenthDayOfTheMonth = "isFifteenthDayOfTheMonth",
  dateBetweenNew = "dateBetweenNew",
  dateIsUntilToday = "dateIsUntilToday",
  isValidExcelFile = "isValidExcelFile",
  isValidExcelFileSize = "isValidExcelFileSize",
  isUntilHigherThenFrom = "isUntilHigherThenFrom",
}

export const validateInputMessage = (
  formCustomization: FormCustomizationType,
  form: object,
  formId: string,
  haveCustomValidations,
  isValidForm: object,
  prepareInputName?
): ValidationMessage => {
  let testsFailedArr: InputValidationReturn[] = [];

  if (formCustomization.components[formId]) {
    const component = formCustomization.components[formId];
    testsFailedArr = validationTestFailed(
      buildValidationTests(
        component,
        haveCustomValidations && haveCustomValidations(formId)
      ),
      form[prepareInputName ? prepareInputName(formId) : formId],
      component?.interval?.fieldToCompare
        ? form[component?.interval?.fieldToCompare]
        : form,
      component?.interval,
      component?.frontEndCustomValidation
    );

    console.log("qwe", testsFailedArr);

    if (testsFailedArr.length > 0 && (component.required || !!form[formId])) {
      return {
        message: testsFailedArr[0]?.message,
        type: testsFailedArr[0]?.type,
        valid: { ...isValidForm, [formId]: false },
      };
    } else {
      !component.required && delete isValidForm[formId];
      return {
        message: "",
        type: null,
        valid: component.required
          ? { ...isValidForm, [formId]: true }
          : { ...isValidForm },
      };
    }
  }
};

const buildValidationTests = (
  formConfig,
  customValidations?: EValidationTestType[]
) => {
  let validationTests: EValidationTestType[] = [];

  formConfig &&
    Object.keys(formConfig).forEach((key) => {
      // Required case
      if (key === "required" && formConfig[key]) {
        validationTests.push(EValidationTestType.hasValue);
      }

      // Date Type
      if (key === "date" && formConfig[key]) {
        validationTests.push(EValidationTestType.isDate);
      }
    });

  return validationTests.concat(customValidations || []);
};

export const buildValidationBasedOnBirthDate = (
  birthDate,
  formComponentsCustomization,
  componentName: string
) => {
  if (birthDate) {
    formComponentsCustomization[componentName].frontEndCustomValidation = {
      dateIsNotBeforeBirthdate: birthDate,
    };
  }

  return formComponentsCustomization;
};

export const buildValidationBasedOnEmployerDates = (
  employerItem,
  formComponentsCustomization,
  componentName: string
) => {
  if (
    employerItem &&
    (employerItem.attributes?.changesAllowedFrom ||
      employerItem.attributes?.changesAllowedUntil)
  ) {
    formComponentsCustomization[componentName].frontEndCustomValidation = {
      dateBetween: {
        from: employerItem.attributes?.changesAllowedFrom,
        until: employerItem.attributes?.changesAllowedUntil,
      },
    };
  }

  return formComponentsCustomization;
};

export const formHasChanged = (initialData, actualForm) => {
  if (initialData === null) {
    return true;
  } else {
    return JSON.stringify(initialData) !== JSON.stringify(actualForm);
  }
};
