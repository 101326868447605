import React from "react";
import { formatNumber, parseDate } from "../../../../utils/react";
import styles from "./InsurantDetailsRowTop.module.scss";
import { ReactComponent as MaleIcon } from "../../../../assets/icons/male.svg";
import { ReactComponent as FemaleIcon } from "../../../../assets/icons/female.svg";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Status, StatusColors } from "icr-shared-components";

interface Props {
  intl: any;
  employers: any;
  insurant: any;
  entities: any;
}

const InsurantDetailsRowTop = ({ insurant, employers, entities }: Props) => {
  const isActive = insurant?.employee.isActive;
  const payroll =
    !!insurant.payroll?.grossSalary &&
    "CHF " + formatNumber(insurant.payroll.grossSalary);
  const sex =
    insurant.person.sex == 1 ? (
      <MaleIcon />
    ) : insurant.person.sex == 2 ? (
      <FemaleIcon />
    ) : null;
  const employerName =
    employers?.[insurant.employee.employerId]?.attributes.name;
  const processingState =
    entities?.ProcessingStates?.values[insurant?.employee.processingState];
  return (
    <div className={styles.insurantDetailsRowTop}>
      <div className={styles.column}>
        <span className={styles.headerText}>Name / Geburtsdatum</span>
        <p>
          <b>
            {insurant.person.name + " " + insurant.person.firstname} {sex}
          </b>
        </p>
        <p className="text-small">
          <b>{parseDate(insurant.person.birthDate)}</b>
        </p>
      </div>
      <div className={styles.column}>
        <span className={styles.headerText}>AHV-Nr. / Vers.-Nr.</span>
        <p>{insurant.person.socialSecurityNumber}</p>
        <p>{insurant.employee.insEmployeeId}</p>
      </div>
      <div className={styles.column}>
        <span className={styles.headerText}>Arbeitgeber</span>
        <p>{employerName}</p>
      </div>
      <div className={styles.column}>
        <span className={styles.headerText}>Status</span>
        <Status
          text={!isActive ? "inaktiv" : "aktiv"}
          color={!isActive ? StatusColors.DISABLED : StatusColors.HIGHLIGHT}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(InsurantDetailsRowTop));
