import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import PkApp from "./PkApp";
import "./styles/index.scss";
import * as serviceWorker from "./serviceWorker";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, Store, compose } from "redux";
import { Route } from "react-router-dom";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import createRootReducer, { RootState } from "./reducers/index";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { IntlProvider } from "react-intl-redux";
import de from "./constants/locales/agp/de.json";
import en from "./constants/locales/agp/en.json";
import { createBrowserHistory, createHashHistory } from "history";
import "icr-shared-components/dist/index.css";
import { ThemeProvider } from "@emotion/react";
import { GlobalStyles, theme } from "icr-shared-components";

import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

// process.env.NODE_ENV !== "development" &&
//   Sentry.init({
//     dsn: "https://454bb6a57f1a46a3b9b265b55cda80c2@o429644.ingest.sentry.io/5376669",
//   });
// console.log("indexm env mode: ", process.env, 'Sentry', Sentry);

const historyS = createBrowserHistory();

let middleware = applyMiddleware(
  routerMiddleware(historyS), // for dispatching history actions
  thunk
  // logger,
);

const i18nInitialState = {
  locale: "de",
  messages: {
    ...de,
  },
};
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  createRootReducer(historyS) as any,
  { intl: i18nInitialState } as any,
  composeEnhancers(middleware as any)
) as Store<RootState>;
/* eslint-enable */

ReactDOM.render(
  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <IntlProvider locale="de" defaultLocale="de">
          <ConnectedRouter history={historyS}>
            <GlobalStyles />
            <ReduxToastr
              timeOut={6000}
              newestOnTop={false}
              preventDuplicates
              position="top-center"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              closeOnToastrClick
            />
            <Route path="/" component={App} />
          </ConnectedRouter>
        </IntlProvider>
      </ThemeProvider>
    </Provider>
  </Worker>,
  document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
