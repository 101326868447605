import * as constants from '../../constants';

export interface InvoicesState {
    invoices: any;
    invoicesObj: any;
    invoicesPageNo: any;
    moreInvoices: any;
    filterByStatusInvoices: any;
    filterByFirmaInvoices: any;
    invoicesFetched: boolean;
    openInvoicesFetched: boolean;
    openInvoices: any;
}

const getInitialState = () => {
    return {
        invoices: [],
        openInvoices: [],
        openInvoicesFetched: false,
        invoicesFetched: false,
        invoicesObj: {},
        invoicesPageNo: 0,
        moreInvoices: true,
        filterByStatusInvoices: "",
        filterByFirmaInvoices: ""
    }
};

// console.log("app.ts", getInitialState());

const invoices = (state: InvoicesState = getInitialState(), action: any) => {
    switch (action.type) {


        case constants.SET_INVOICES_FETCHED: {
            return { ...state, invoicesFetched: action.payload };
        }


        case constants.SET_INVOICES_PAGE_NO: {
            // console.log("reducer loading", action);
            let moreInvoices = !(state.invoices.length < ((state.invoicesPageNo + 1) * constants.PAGINAION_SIZE));
            return { ...state, invoicesPageNo: action.payload, moreInvoices: moreInvoices}
        }

        case constants.FETCHED_OPEN_INVOICES: {
            return { ...state, openInvoices: action.payload, openInvoicesFetched: true};
        }

        case constants.FETCHED_INVOICES: {
            console.log("reducer FETCHED_INVOICES", action);
            let invoices = [];
            let invoicesObj = {}
            JSON.parse(JSON.stringify(action.payload)).forEach((insurant) => {
                invoicesObj[insurant.id] = insurant;
            })
            if(state.invoicesPageNo == 0 || action.page == 0) {
                invoices = [ ...action.payload ];
            } else {
                invoices = [ ...state.invoices, ...action.payload ];
            }
            invoicesObj["success"] = true;
            return { ...state, invoices: invoices, invoicesObj: invoicesObj, invoicesFetched: true };
        }

        case constants.SET_FILTERING_CRITERIA_INVOICES: {
            if(action.payload.name === "clear") {
                return {
                    ...state,
                    filterByStatusInvoices: "",
                    filterByFirmaInvoices: ""
                }
            } else if (action.payload.name === "statusFilter") {
                return { ...state, filterByStatusInvoices: action.payload.value };
            } else if (action.payload.name === "firmaFilter") {
                return { ...state, filterByFirmaInvoices: action.payload.value };
            } else {
                return { ...state };
            }
        }

        case constants.LOG_OUT: {
            console.log("log out invoices");
            return { ...getInitialState() };
        }

    }

    return state;
};

export default invoices;
