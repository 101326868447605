import * as React from 'react';
import styles from "./CockpitMutationCard.module.scss";
import {Button, ButtonColors, ButtonTypes, Status, StatusColors} from "icr-shared-components";
import {RootState} from "../../../../reducers";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as EditIcon} from "../../../../assets/icons/edit.svg";
import {ReactComponent as DownloadIcon} from "../../../../assets/icons/pdfdownload.svg";

interface Props {
    intl: any;
    entities: any;
    mutation: any;
    onPdfClick: any;
    onEditClick: any;
}

const CockpitMutationCard = ({mutation, entities, onEditClick, onPdfClick}: Props) => {
    const isActive = mutation?.attributes?.values?.employee?.isActive;
    return (
        <div tabIndex={0} className={styles.cockpitMutationCard}>
            <p className="mb-16">
                <b>{mutation.attributes?.isRevert && <span className="mr-6"><FormattedMessage id="mutation.Revert.TypePrefix" defaultMessage="Storno" /></span>}</b>
                <b>{mutation.attributes.mutationType?.translation}</b>
            </p>
            <div className="d-flex justify-content-between  mb-24">
                <p>{`${mutation.attributes.name} ${mutation.attributes.firstname}`}</p>
                <p>{mutation.attributes.socialSecurityNumber}</p>
            </div>
            <div className="d-flex justify-content-between">
                <Status text={!isActive ? "inaktiv" : "aktiv"} color={!isActive ? StatusColors.DISABLED : StatusColors.HIGHLIGHT} />
                <div className={styles.cockpitMutationCardButtons}>
                    {!!(onEditClick && mutation.attributes.values) && <Button
                        className={styles.editIcon}
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        onClick={onEditClick}>
                        <EditIcon/>
                    </Button>}

                    {onPdfClick && <Button
                        className={styles.pdfIcon}
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        onClick={onPdfClick}>
                        <DownloadIcon/>
                    </Button>}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    entities: state.app.entities
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(CockpitMutationCard));
