import { EValidationMessageType } from "../components/ValidationMessage/index";
import { calculateAgeFromDates, parseDate } from "./react";
import moment from "moment";
import {
  isValidUnpaidVacation as isValidUnpaidVacationCore,
  dateBetween as dateBetweenCore,
  dateIsUntilToday as dateIsUntilTodayCore,
  insurantAgeBefore as insurantAgeBeforeCore,
  dateIsOneMonthAfterEntryDate as dateIsOneMonthAfterEntryDateCore,
} from "./validationCore";
import { InputValidationReturn } from "../@types/forms/formUtils";

// Tests //

export enum EValidationTestType {
  hasValue = "hasValue",
  hasAnyValue = "hasAnyValue",
  acceptAGBS = "acceptAGBS",
  isPasswordMatch = "isPasswordMatch",
  isEmail = "isEmail",
  isPhoneNumber = "isPhoneNumber",
  isPassword = "isPassword",
  isPin = "isPin",
  isSsn = "isSsn",
  isDate = "isDate",
  isFirstDayOfTheMonth = "isFirstDayOfTheMonth",
  isLastDayOfTheMonth = "isLastDayOfTheMonth",
  isBeschaftingungsgrad = "isBeschaftingungsgrad",
  isNumber = "isNumber",
  isPositive = "isPositive",
  isText = "isText",
  isDegree = "isDegree",
  dateBetween = "dateBetween",
  backendValid = "backendValid",
  isActivityRate = "isActivityRate",
  isValidUnpaidVacation = "isValidUnpaidVacation",
  dateIsNotBeforeBirthdate = "dateIsNotBeforeBirthdate",
  dateIsNotBeforeEntrydate = "dateIsNotBeforeEntrydate",
  civilStatusDateNotBeforeBirthDate = "civilStatusDateNotBeforeBirthDate",
  dateIsBeforeDate = "dateIsBeforeDate",
  ageRangeEntryDate = "ageRangeEntryDate",
  ageRangeBirthdate = "ageRangeBirthdate",
  isValidGender = "isValidGender",
  validationMessage = "validationMessage",
  dateIsOneMonthAfterEntryDate = "dateIsOneMonthAfterEntryDate",
  dateIsNotBeforeEntrydateNew = "dateIsNotBeforeEntrydateNew",
  isFifteenthDayOfTheMonth = "isFifteenthDayOfTheMonth",
  dateBetweenNew = "dateBetweenNew",
  dateIsUntilToday = "dateIsUntilToday",
  dateIsNotInTheFuture = "dateIsNotInTheFuture",
  isValidExcelFile = "isValidExcelFile",
  isValidExcelFileSize = "isValidExcelFileSize",
  isUntilHigherThenFrom = "isUntilHigherThenFrom",
  insurantAgeBefore = "insurantAgeBefore",
}

// Test Messages //
export const VALIDATION_MESSAGE = {
  hasValue(messages?) {
    return {
      message:
        typeof messages === "string"
          ? messages
          : "Pflichtfeld darf nicht leer sein",
      type: EValidationMessageType.Error,
    };
  },
  hasAnyValue(messages?) {
    return {
      message: "Pflichtfeld darf nicht leer sein",
      type: EValidationMessageType.Error,
    };
  },
  isPasswordMatch(messages?) {
    return {
      message: "Passwörter stimmen nicht überein", // tslint:disable-line
      type: EValidationMessageType.Error,
    };
  },
  isEmail(messages?) {
    return {
      message: "E-Mail ist ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isPhoneNumber(messages?) {
    return {
      message: "Telefonnummer ist ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isPin(messages?) {
    return {
      message: "PIN ist ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isDegree(messages?) {
    return {
      message: "Wert muss zwischen 0 und 100 sein.",
      type: EValidationMessageType.Error,
    };
  },
  isPassword(messages?) {
    return {
      message:
        "Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens 1 Sonderzeichen enthalten",
      type: EValidationMessageType.Error,
    };
  },
  isSsn(messages?) {
    return {
      message: "AHV-Nummer ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isDate(messages?) {
    return {
      message:
        typeof messages === "string"
          ? messages
          : "Bitte korrektes Datumsformat “DD.MM.YYYY” verwenden",
      type: EValidationMessageType.Error,
    };
  },
  isFirstDayOfTheMonth(messages?) {
    return {
      message: "Nur am 1. eines Monats möglich",
      type: EValidationMessageType.Error,
    };
  },
  isFifteenthDayOfTheMonth(messages?) {
    return {
      message: "Bitte wählen Sie den fünfzehnten Tag des Monats aus",
      type: EValidationMessageType.Error,
    };
  },
  isLastDayOfTheMonth(messages?) {
    return {
      message: "Bitte wählen Sie den letzten Tag des Monats aus",
      type: EValidationMessageType.Error,
    };
  },
  isBeschaftingungsgrad(messages?) {
    return {
      message: "Beschäftigungsgrad in Prozent eingeben. (0 - 140)",
      type: EValidationMessageType.Error,
    };
  },
  isNumber(messages?) {
    return {
      message: "Bitte eine Nummer eingeben",
      type: EValidationMessageType.Error,
    };
  },
  isPositive(messages?) {
    return {
      message: "Number between 0 and 100",
      type: EValidationMessageType.Error,
    };
  },
  dateBetween(messages?) {
    var message = "";
    console.log("MESSAGES", messages);
    if (!messages.from && !messages.until) {
      message = "Bitte andere Felder ausfüllen";
    } else if (messages.from && !messages.until) {
      message = `Das Datum muss sollte nach oder gleich dem ${parseDate(
        messages.from
      )} liegen`;
    } else {
      message = `Das Datum muss zwischen dem ${parseDate(
        messages.from
      )} und dem ${parseDate(messages.until)} liegen`;
    }
    return {
      message: message,
      type: EValidationMessageType.Error,
    };
  },

  dateBetweenNew(
    messages?,
    validateAgainstValues?,
    value?,
    intervalSettings?,
    frontEndCustomValidations?
  ): InputValidationReturn {
    return dateBetweenCore(
      value,
      validateAgainstValues,
      messages,
      frontEndCustomValidations
    );
  },

  dateIsUntilToday(
    messages?,
    validateAgainstValues?,
    value?,
    intervalSettings?,
    frontEndCustomValidations?
  ): InputValidationReturn {
    return dateIsUntilTodayCore(value);
  },

  backendValid(messages) {
    return {
      message: messages,
      type: EValidationMessageType.Error,
    };
  },

  isActivityRate(messages?) {
    return {
      message: "Pensum muss zwischen 0 und 100 sein",
      type: EValidationMessageType.Error,
    };
  },

  // isValidUnpaidVacation(messages?, validateAgainstValues?, value?, validation?) {
  //     let message = "";
  //     const from = new Date(validateAgainstValues);
  //     const until = new Date(value);
  //     console.log("diff In day", moment(until).diff(from, "days"))
  //     if(moment(until).diff(from, "days") > validation.maxIntervalValue) {
  //       message = formatErrorMessage({message:messages[0], minValue:parseDate(validateAgainstValues)})
  //     } else {
  //       message = formatErrorMessage({message:messages[1], minValue:parseDate(validateAgainstValues), maxValue: validation.maxIntervalValue})
  //     }
  //   return {
  //     message: message,
  //     type: EValidationMessageType.Error
  //   }
  // },

  isValidUnpaidVacation(
    messages?,
    validateAgainstValues?,
    value?,
    intervalSettings?
  ): InputValidationReturn {
    return isValidUnpaidVacationCore(
      value,
      validateAgainstValues,
      intervalSettings,
      messages
    );
  },

  dateIsOneMonthAfterEntryDate(
    messages?,
    validateAgainstValues?,
    value?,
    intervalSettings?
  ): InputValidationReturn {
    return dateIsOneMonthAfterEntryDateCore(value, validateAgainstValues);
  },

  dateIsBeforeDate(
    messages?,
    validateAgainstValues?,
    value?,
    intervalSettings?
  ): InputValidationReturn {
    return {
      message: "Gültig ab Datum muss vor dem Gültig bis Datum liegen.",
      type: EValidationMessageType.Error,
    };
  },

  dateIsNotBeforeEntrydateNew(
    messages?,
    validateAgainstValues?,
    value?,
    intervalSettings?
  ): InputValidationReturn {
    return {
      message: `Datum darf nicht vor dem Eintrittsdatum (${validateAgainstValues}) liegen.`,
      type: EValidationMessageType.Error,
    };
  },

  dateIsNotBeforeEntrydate(messages?) {
    const date = moment(messages.isNotBefore).format("DD.MM.YYYY");
    return {
      message: `Datum darf nicht vor dem Eintrittsdatum (${date}) liegen.`,
      type: EValidationMessageType.Error,
    };
  },

  dateIsNotBeforeBirthdate(messages?) {
    return {
      message: `Datum darf nicht vor dem Geburtsdatum liegen`,
      type: EValidationMessageType.Error,
    };
  },

  civilStatusDateNotBeforeBirthDate(messages?) {
    return {
      message: `Heiratsdatum/Scheidungsdatum liegt vor Geburtsdatum.`,
      type: EValidationMessageType.Error,
    };
  },

  insurantAgeBefore(
    messages?,
    validateAgainstValues?,
    value?,
    intervalSettings?
  ) {
    return insurantAgeBeforeCore(
      messages,
      validateAgainstValues,
      value,
      intervalSettings
    );
  },

  ageRangeEntryDate(messages?, validateAgainstValues?, value?) {
    const age = calculateAgeFromDates(value, validateAgainstValues.birthDate);
    let message = "";
    if (age < 18) {
      message = `Eintrittsalter ist kleiner 18 (Geburtsdatum ${parseDate(
        validateAgainstValues.birthDate
      )}) Überprüfen Sie Eintritts- und Geburtsdatum`;
    } else if (age > 70) {
      message = `Eintrittsalter ist grösser 70 (Geburtsdatum ${parseDate(
        validateAgainstValues.birthDate
      )}) Überprüfen Sie Eintritts- und Geburtsdatum`;
    }
    return {
      message: message,
      type: EValidationMessageType.Error,
    };
  },

  ageRangeBirthdate(messages?, validateAgainstValues?, value?) {
    const age = calculateAgeFromDates(validateAgainstValues.entryDate, value);
    let message = "";
    if (age < 18) {
      message = `Eintrittsalter ist kleiner 18 (Eintrittsdatum ${parseDate(
        validateAgainstValues.entryDate
      )}) Überprüfen Sie Eintritts- und Geburtsdatum`;
    } else if (age > 70) {
      message = `Eintrittsalter ist grösser 70 (Eintrittsdatum ${parseDate(
        validateAgainstValues.entryDate
      )}) Überprüfen Sie Eintritts- und Geburtsdatum`;
    }
    return {
      message: message,
      type: EValidationMessageType.Error,
    };
  },
  isValidGender(messages?) {
    return {
      message: "Pflichtfeld darf nicht leer sein",
      type: EValidationMessageType.Error,
    };
  },
  isValidExcelFile(messages?) {
    return {
      message: "Bitte wählen Sie eine Excel Datei aus",
      type: EValidationMessageType.Error,
    };
  },
  isValidExcelFileSize(messages?) {
    return {
      message: "Datei zu gross. Maximale grösse ist 50MB",
      type: EValidationMessageType.Error,
    };
  },
};
