export const LOG_OUT = "FETCHED_COMPLETE_MUTATIONS";

export const FETCHED_COMPLETE_MUTATIONS = "FETCHED_COMPLETE_MUTATIONS";
export const FETCHED_OPEN_MUTATIONS = "FETCHED_OPEN_MUTATIONS";
export const SET_SORT_PARAMS = "SET_SORT_PARAMS";

export const FETCHED_INSURANTS = "FETCHED_INSURANTS";
export const SET_INSURANTS_PAGE_NO = "SET_INSURANTS_PAGE_NO";
export const SET_INSURANTS_FILTERING = "SET_INSURANTS_FILTERING";

export const FETCHED_FOR_REVIEW_MUTATIONS = "FETCHED_FOR_REVIEW_MUTATIONS";
export const FETCHED_FOR_REVIEW_MUTATIONS_COCKPIT =
  "FETCHED_FOR_REVIEW_MUTATIONS_COCKPIT";
export const FETCHED_CAN_SUBMIT_ALL = "FETCHED_CAN_SUBMIT_ALL";
export const SET_FOR_REVIEW_MUTATIONS_PAGE_NO =
  "SET_FOR_REVIEW_MUTATIONS_PAGE_NO";
export const SET_FOR_REVIEW_MUTATIONS_FILTERING =
  "SET_FOR_REVIEW_MUTATIONS_FILTERING";
export const FETCHED_FOR_REVIEW_MUTATIONS_COUNT =
  "FETCHED_FOR_REVIEW_MUTATIONS_COUNT";
export const FETCHED_FOR_REVIEW_MUTATIONS_COUNT_COCKPIT =
  "FETCHED_FOR_REVIEW_MUTATIONS_COUNT_COCKPIT";
export const SET_FOR_REVIEW_MUTATIONS_FETCHED =
  "SET_FOR_REVIEW_MUTATIONS_FETCHED";
export const FETCHED_FOR_REVIEW_MUTATIONS_BY_PROGRESS =
  "FETCHED_FOR_REVIEW_MUTATIONS_BY_PROGRESS";

export const FETCHED_ACCEPTED_MUTATIONS = "FETCHED_ACCEPTED_MUTATIONS";
export const FETCHED_ACCEPTED_MUTATIONS_COCKPIT =
  "FETCHED_ACCEPTED_MUTATIONS_COCKPIT";
export const SET_ACCEPTED_MUTATIONS_PAGE_NO = "SET_ACCEPTED_MUTATIONS_PAGE_NO";
export const SET_ACCEPTED_MUTATIONS_FILTERING =
  "SET_ACCEPTED_MUTATIONS_FILTERING";
export const FETCHED_ACCEPTED_MUTATIONS_COUNT =
  "FETCHED_ACCEPTED_MUTATIONS_COUNT";
export const FETCHED_ACCEPTED_MUTATIONS_COUNT_COCKPIT =
  "FETCHED_ACCEPTED_MUTATIONS_COUNT_COCKPIT";
export const SET_ACCEPTED_MUTATIONS_FETCHED =
  "SET_FOR_ACCEPTED_MUTATIONS_FETCHED";
export const FETCHED_ACCEPTED_MUTATIONS_BY_PROGRESS =
  "FETCHED_ACCEPTED_MUTATIONS_BY_PROGRESS";

export const FETCHED_DELETED_MUTATIONS = "FETCHED_DELETED_MUTATIONS";
export const SET_DELETED_MUTATIONS_PAGE_NO = "SET_DELETED_MUTATIONS_PAGE_NO";
export const SET_DELETED_MUTATIONS_FILTERING =
  "SET_DELETED_MUTATIONS_FILTERING";
export const FETCHED_DELETED_MUTATIONS_COUNT =
  "FETCHED_DELETED_MUTATIONS_COUNT";
export const SET_DELETED_MUTATIONS_FETCHED =
  "SET_FOR_DELETED_MUTATIONS_FETCHED";

export const FETCHED_FOR_REVIEW_MUTATIONS_PROGRESS =
  "FETCHED_FOR_REVIEW_MUTATIONS_PROGRESS";
export const FETCHED_ACCEPTED_MUTATIONS_PROGRESS =
  "FETCHED_ACCEPTED_MUTATIONS_PROGRESS";

export const FETCHED_COMPANY_INVOICES = "FETCHED_COMPANY_INVOICES";
export const FETCHED_COMPANIES = "FETCHED_COMPANIES";
export const SET_COMPANIES_PAGE_NO = "SET_COMPANIES_PAGE_NO";
export const SET_COMPANIES_FILTERING = "SET_COMPANIES_FILTERING";

export const FETCHED_INVOICES = "FETCHED_INVOICES";
export const SET_INVOICES_PAGE_NO = "SET_INVOICES_PAGE_NO";
export const SET_INVOICES_FILTERING = "SET_INVOICES_FILTERING";

export const FETCHED_MUTATIONTYPES = "FETCHED_MUTATIONTYPES";
export const FETCHED_PROFILES = "FETCHED_PROFILES";
export const FETCHED_MUTATIONS = "FETCHED_MUTATIONS";
export const FETCHED_EMPLOYERS = "FETCHED_EMPLOYERS";
export const FETCHED_PAYROLLS = "FETCHED_PAYROLLS";
export const FETCHED_ADDRESSES = "FETCHED_ADDRESSES";
export const FETCHED_INSURANT_MUTATIONS = "FETCHED_INSURANT_MUTATIONS";
export const FETCHED_UNPAIDVACATIONS = "FETCHED_UNPAIDVACATIONS";
export const REMOVE_MUTATION_MANUAL_COMMIT = "REMOVE_MUTATION_MANUAL_COMMIT"

export const LOADING = "LOADING";

export const SET_COCKPIT_FILTERING = "SET_COCKPIT_FILTERING";

export const PAGINAION_SIZE = 20;

export enum INSURANCES_TABS {
  PersonenTab = "personen",
  MutationenTab = "mutationen",
}

export enum MUTATIONS_TABS {
  ForReviewTab = "uberprufung-erforderlich",
  AcceptedTab = "ubernommen",
  DeletedTab = "geloscht",
}

export enum COMPANIES_MODAL_TABS {
  InvoicesTab = "invoices",
  EventsTab = "events",
}
