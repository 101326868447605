import * as constants from '../constants';

export interface GlobalSettingsState {
    attributes:{
        tenantName?: string;
        tenantCode?: string;
        runningYear?: null | number;
        hasCsvImportEnabled: boolean;
    }
    relationships?:any
}

const getInitialState = () => {
    return {
        attributes:{
            tenantName: "",
            tenantCode: "",
            runningYear: null,
            hasCsvImportEnabled: false
        }
    }
};


const globalSettings = (state: GlobalSettingsState = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.GLOBAL_SETTINGS: {
            
            return {
                attributes: action.payload.attributes,
            }
        }

    }

    return state;
};

export default globalSettings;
