import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers/index";
import { bindActionCreators, Dispatch } from "redux";
import Modal from "react-modal";
import * as actions from "../../actions/";
import { FormattedMessage, injectIntl } from "react-intl";
import "./Upload.scss";
import Header from "../../components/Header";
import Tab from "@material/react-tab";
import classNames from "classnames";
import { ReactComponent as IconFilter } from "../../assets/icons/filter.svg";
import Drawer from "../../components/Drawer";
import { arrayBufferToBase64 } from "../../utils/react";
import Spinner from "../../components/Spinner";
import { getParameterByName } from "../../utils/toSearchParams";
import {
  fetchInbox,
  fetchOutbox,
  initializeDownload,
  initializeUpload,
  uploadFile,
  uploadPart,
} from "../../actions/arbeitgeber/upload";

import {
  fetchInboxPkc,
  fetchOutboxPkc,
  uploadFilePkc,
} from "../../actions/pensionskasse/upload";
import { isInputValid, isMonth, isYear } from "../../utils/validationCore";
import { testsFailed } from "../../utils/validationTests"; // remove this after refactor
import {
  EValidationTestType,
  VALIDATION_MESSAGE,
} from "../../utils/validationConfigs";
import {
  EMPTY_VALIDATION_MESSAGE_CONFIG,
  EValidationMessageType,
} from "../../components/ValidationMessage";
import { defined } from "../../utils/variableEvaluation";
import ReceivedTab from "../../components/Arbeitgeber/Upload/ReceivedTab";
import SentTab from "../../components/Arbeitgeber/Upload/SentTab";
import SideBar from "../../components/Arbeitgeber/Upload/SideBar";
import UploadModal from "../../components/Arbeitgeber/Upload/UploadModal";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  PlusButton,
} from "icr-shared-components";
import { ReactComponent as ImportIcon } from "../../assets/icons/import.svg";
import { INSURANCES_TABS } from "../../constants/pensionskasse";

interface State {
  currentTab: string;
  filtersOpened: boolean;
  modalIsOpen: boolean;
  expandedRow: number;
  showValidations: boolean;
  loadingRowIndex: any;
  progressMax: any;
  progressValue: any;
  loading: boolean;
  modalFadeOut: boolean;
  showMoreSpinner: boolean;
  filtersChanged: number;
  downloadingFiles: any;
}

interface Props {
  history: any;
  intl: any;
  location: any;
  setPopup: any;
  popupIsOpen: any;
  sidebarOpened: any;
  setSidebar: any;
  employers: any;
  fetchInbox: any;
  fetchOutbox: any;
  initializeUpload: any;
  initializeDownload: any;
  uploadPart: any;
  uploadFile: any;
  addNewFile: any;
  inboxPageNo: any;
  outboxPageNo: any;
  setInboxPageNo: any;
  setOutboxPageNo: any;
  moreInbox: any;
  moreOutbox: any;
  filterInbox: any;
  filterOutbox: any;
  filterNameInbox: any;
  filterFirmaInbox: any;
  filterTypeInbox: any;
  filterYearInbox: any;
  filterMonthInbox: any;
  filterOnlyNewInbox: any;
  filterNameOutbox: any;
  filterFirmaOutbox: any;
  filterTypeOutbox: any;
  filterYearOutbox: any;
  filterMonthOutbox: any;
  filterOnlyNewOutbox: any;
  updateProgress: any;
  updateFileStatus: any;
  showValidations: any;
  inboxSuccess: boolean;
  outboxSuccess: boolean;
  documentTypes: any;

  fetchInboxPkc: any;
  fetchOutboxPkc: any;
  uploadFilePkc: any;
}

Modal.setAppElement("#root");

const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || "20";
const backendPagination = process.env.REACT_APP_BACKEND_PAGINATION;

class Upload extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentTab: props.match.params.tab,
      filtersOpened: window.innerWidth >= 1400,
      modalIsOpen: false,
      expandedRow: null,
      showValidations: false,
      loadingRowIndex: null,
      progressMax: 100,
      progressValue: 0,
      loading: false,
      modalFadeOut: false,
      showMoreSpinner: false,
      filtersChanged: 0,
      downloadingFiles: [],
    };
  }

  private drawer = null;
  private scrollableContainer = null;
  private scrollableContainerParent = null;
  private isPensionskasse = null;

  componentDidMount() {
    this.isPensionskasse = window.location.pathname.includes("/pensionskasse");
    this.fetchData();
    document.body.classList.add("uploadPage");
    document.body.classList.remove("backdrop");
    window.addEventListener("resize", this.viewPortChanged);
    window.addEventListener("scroll", this.handleScroll, true);
    if (getParameterByName("content", this.props.location.search) == "upload") {
      this.setState({ modalIsOpen: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let content = getParameterByName("content", this.props.location.search);
    let prevContent = getParameterByName("content", prevProps.location.search);
    if (content != prevContent && content != "upload") {
      this.setState({ modalIsOpen: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.viewPortChanged);
    window.removeEventListener("scroll", this.handleScroll, true);
    document.body.classList.remove("uploadPage");
  }

  fetchData = () => {
    if (!this.props.inboxSuccess) {
      this.isPensionskasse
        ? this.props.fetchInboxPkc().then((res) => this.props.setInboxPageNo(1))
        : this.props.fetchInbox().then((res) => this.props.setInboxPageNo(1));
    }

    if (!this.props.outboxSuccess) {
      this.isPensionskasse
        ? this.props
            .fetchOutboxPkc()
            .then((res) => this.props.setOutboxPageNo(1))
        : this.props.fetchOutbox().then((res) => this.props.setOutboxPageNo(1));
    }
  };

  viewPortChanged = (e) => {
    setTimeout(() => {
      if (e.target.innerWidth >= 1400 && this.state.filtersOpened === false) {
        this.setState({ filtersOpened: true });
      }
    }, 500);
  };

  handleContentContainerClick = (e) => {
    if (this.drawer.contains(e.target)) {
      return;
    } else {
      window.innerWidth < 1400 && this.setState({ filtersOpened: false });
    }
    if (!document.querySelector(".hamburgerContainer")?.contains(e.target)) {
      this.props.sidebarOpened && this.props.setSidebar(false);
      this.props.popupIsOpen && this.props.setPopup(false);
    }
  };

  loadMore = () => {
    const { currentTab, loading } = this.state;
    const { moreInbox, moreOutbox } = this.props;
    if (
      currentTab === "empfangen" &&
      moreInbox 
    ) {
      this.setState({ loading: true });
      this.isPensionskasse && window.location.pathname === "/pensionskasse/filetransfer/empfangen"
        ? this.props.fetchInboxPkc().then((res) => {
            this.setState({ loading: false });
            this.props.setInboxPageNo(this.props.inboxPageNo + 1);
          })
        : this.props.fetchInbox().then((res) => {
            this.setState({ loading: false });
            this.props.setInboxPageNo(this.props.inboxPageNo + 1);
          });
    }
    if (
      currentTab === "gesendet" &&
      moreOutbox
    ) {
      this.setState({ loading: true });
      this.isPensionskasse && window.location.pathname === "/pensionskasse/filetransfer/gesendet"
        ? this.props.fetchOutboxPkc().then((res) => {
            this.setState({ loading: false });
            this.props.setOutboxPageNo(this.props.outboxPageNo + 1);
          })
        : this.props.fetchOutbox().then((res) => {
            this.setState({ loading: false });
            this.props.setOutboxPageNo(this.props.outboxPageNo + 1);
          });
    }
  };

  handleScroll = (e) => {
    const { loading } = this.state;
    const element = e.target;
    const bottom =
    Math.floor(element.scrollHeight - element.scrollTop) <=
    element.clientHeight + 200 &&
    Math.floor(element.scrollHeight - element.scrollTop) >=
    element.clientHeight;
    if (bottom && !loading) {
      this.loadMore();
    }
  };

  checkFormValidity = (
    formName,
    modelName,
    formObj,
    formConfig,
    checkValidationAgainstValues?
  ) => {
    let isValid = null;
    isValid =
      formConfig[modelName] &&
      Object.keys(formConfig[modelName]).every((inputObjKey) => {
        if (
          checkValidationAgainstValues &&
          checkValidationAgainstValues[formConfig[modelName][inputObjKey].name]
        ) {
          return isInputValid(
            formObj[modelName],
            formConfig[modelName][inputObjKey].validationTests,
            formConfig[modelName][inputObjKey].name,
            checkValidationAgainstValues[
              formConfig[modelName][inputObjKey].name
            ]
          );
        } else {
          return isInputValid(
            formObj[modelName],
            formConfig[modelName][inputObjKey].validationTests,
            formConfig[modelName][inputObjKey].name
          );
        }
      });
    this.props.showValidations(!isValid);
    this.setState({ showValidations: !isValid });
    return isValid;
  };

  validationMessage = (
    formName,
    modelName,
    inputName,
    validationTests,
    formObj,
    checkAgainstValidationValues?
  ) => {
    let testsFailedArr: EValidationTestType[] = [];
    if (modelName.length > 0) {
      if (checkAgainstValidationValues) {
        testsFailedArr = testsFailed(
          validationTests,
          formObj[modelName][inputName],
          checkAgainstValidationValues
        );
      } else {
        testsFailedArr = testsFailed(
          validationTests,
          formObj[modelName][inputName]
        );
      }
    } else {
      if (checkAgainstValidationValues) {
        testsFailedArr = testsFailed(
          validationTests,
          formObj[inputName],
          checkAgainstValidationValues
        );
      } else {
        testsFailedArr = testsFailed(validationTests, formObj[inputName]);
      }
    }

    if (testsFailedArr.length > 0) {
      if (checkAgainstValidationValues) {
        return VALIDATION_MESSAGE[testsFailedArr[0]](
          checkAgainstValidationValues
        );
      } else {
        return VALIDATION_MESSAGE[testsFailedArr[0]]();
      }
    } else {
      return EMPTY_VALIDATION_MESSAGE_CONFIG;
    }
  };

  validationMessageConfig = (
    formName,
    modelName,
    inputName,
    defaultValidationMessageConfig,
    validationTests,
    formObj,
    checkAgainstValidationValues?
  ) => {
    if (defined(validationTests)) {
      if (checkAgainstValidationValues) {
        return this.validationMessage(
          formName,
          modelName,
          inputName,
          validationTests,
          formObj,
          checkAgainstValidationValues
        );
      } else {
        return this.validationMessage(
          formName,
          modelName,
          inputName,
          validationTests,
          formObj
        );
      }
    } else {
      return EMPTY_VALIDATION_MESSAGE_CONFIG;
    }
  };

  fetchInbox = async (pageNo?, pageSize?) => {
    return (await this.isPensionskasse)
      ? this.props.fetchInboxPkc(pageSize, pageNo)
      : this.props.fetchInbox(pageSize, pageNo);
  };

  fetchOutbox = async (pageNo?, pageSize?) => {
    return (await this.isPensionskasse)
      ? this.props.fetchOutboxPkc(pageSize, pageNo)
      : this.props.fetchOutbox(pageSize, pageNo);
  };

  private debounce = null;

  filterInbox = (name, value, type?) => {
    this.props.setInboxPageNo(0);
    this.props.filterInbox({ name, value });
    if (type == "year") {
      if (value && value.length < 4) return;
    }
    if (type == "month") {
      if (value == "0") return;
    }
    this.setState(
      {
        expandedRow: null,
        showMoreSpinner: true,
      },
      () => {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(
          () =>
            this.fetchInbox().then(() => {
              this.setState({
                filtersChanged: this.state.filtersChanged + 1,
                showMoreSpinner: false,
              });
              this.props.setInboxPageNo(this.props.inboxPageNo + 1);
            }),
          500
        );
      }
    );
  };

  validateDateInputs = (type, value) => {
    const error = { type: EValidationMessageType.Error, message: "" };
    if (type === "month") {
      if (!isMonth(value)) {
        return error;
      }
    }
    if (type === "year") {
      if (!isYear(value)) {
        return error;
      }
    }
  };

  filterOutbox = (name, value, type?, employers?) => {
    this.props.setOutboxPageNo(0);
    this.props.filterOutbox({ name, value });

    if (employers === true && value !== "") {
      return;
    }

    if (type == "year") {
      if (value && value.length < 4) return;
    }
    if (type == "month") {
      if (value == "0") return;
    }
    this.setState(
      {
        expandedRow: null,
        showMoreSpinner: true,
      },
      () => {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(
          () =>
            this.fetchOutbox().then(() => {
              this.setState({
                filtersChanged: this.state.filtersChanged + 1,
                showMoreSpinner: false,
              });
              this.props.setOutboxPageNo(this.props.outboxPageNo + 1);
            }),
          500
        );
      }
    );
  };

  submitAction = async (e, data) => {
    this.setState({ loading: true });
    let chunks = [];
    const file = data.file;
    const title = data.title;
    const description = data.description;
    const employer = data.employerId;
    const type = data.documentType;
    const size = file.size;
    const partSize = 262000;

    var fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = function (e) {
      const content = e.target.result;
      let i = 0;
      let partNumber = 0;

      while (i < size) {
        chunks.push({
          number: partNumber,
          content: arrayBufferToBase64(content.slice(i, i + partSize)),
        });
        i += partSize;
        partNumber++;
      }
    };

    this.props
      .initializeUpload({
        data: {
          type: "file",
          attributes: {
            name: file.name,
            mimeType: file.type,
            size: size,
          },
        },
      })
      .then(async (res) => {
        const fileId = res.data.id;
        const requests = [];
        this.props.addNewFile({
          id: fileId,
          type: "fileTransfer",
          attributes: {
            employerId: employer,
            documentType: type,
            title: title,
            description: description,
            file: { ...res.data.attributes },
            hasBeenDownloaded: false,
          },
        });
        this.setState({ modalFadeOut: true });
        setTimeout(() => {
          this.setState({
            modalIsOpen: false,
            loading: false,
            currentTab: "gesendet",
            modalFadeOut: false,
          });
          this.props.history.push({
            pathname: this.isPensionskasse
              ? "/pensionskasse/filetransfer/gesendet"
              : "/filetransfer/gesendet",
          });
        }, 1500);

        for (let j = 0; j < chunks.length; j++) {
          await this.props
            .uploadPart(fileId, {
              data: {
                type: "filepart",
                attributes: {
                  content: chunks[j].content,
                  number: chunks[j].number,
                },
              },
            })
            .then(function () {
              // @ts-ignore
              requests.push(this);
            })
            .then(() => {
              this.props.updateProgress({
                fileId: fileId,
                progressMax: chunks.length,
                progressValue: j,
                finished: false,
              });
            });
        }

        Promise.all(requests).then(() => {
          this.isPensionskasse
            ? this.props
                .uploadFilePkc({
                  data: {
                    type: "fileTransfer",
                    attributes: {
                      employerId: employer,
                      documentType: type,
                      title: title,
                      description: description,
                      file: {
                        fileId: fileId,
                      },
                    },
                  },
                })
                .then((res) => {
                  this.props.updateProgress({
                    fileId: fileId,
                    progressMax: chunks.length,
                    progressValue: chunks.length,
                  });
                  setTimeout(() => {
                    this.props.updateProgress({
                      fileId: fileId,
                      progressMax: chunks.length,
                      progressValue: chunks.length,
                      finished: true,
                    });
                  }, 1500);
                })
            : this.props
                .uploadFile({
                  data: {
                    type: "fileTransfer",
                    attributes: {
                      employerId: employer,
                      documentType: type,
                      title: title,
                      description: description,
                      file: {
                        fileId: fileId,
                      },
                    },
                  },
                })
                .then((res) => {
                  this.props.updateProgress({
                    fileId: fileId,
                    progressMax: chunks.length,
                    progressValue: chunks.length,
                  });
                  setTimeout(() => {
                    this.props.updateProgress({
                      fileId: fileId,
                      progressMax: chunks.length,
                      progressValue: chunks.length,
                      finished: true,
                    });
                  }, 1500);
                });
        });
      });
  };

  downloadFile = (fileId) => {
    let downloadingFiles = [...this.state.downloadingFiles];
    if (!downloadingFiles.includes(fileId)) {
      downloadingFiles.push(fileId);
      this.setState({ downloadingFiles });
    }
    // this.props.setPopup(true);

    this.props.initializeDownload(fileId).then((res) => {
      const url = process.env.REACT_APP_REST_API_URL + "files?" + res;
      window.location.assign(url);

      let downloadingFiles = [...this.state.downloadingFiles];
      if (downloadingFiles.includes(fileId)) {
        const indexOfFile = downloadingFiles.indexOf(fileId);
        if (indexOfFile != -1) {
          downloadingFiles.splice(indexOfFile, 1);
          this.setState({ downloadingFiles });
        }
      }
      // this.props.setPopup(false);

      this.props.updateFileStatus(fileId);
    });
  };

  documentTypes = [
    "Beiträge",
    "Lohndaten",
    "Versicherungsausweise",
    "Sonstige",
  ];

  prepareDocumentTypeOptions = (types) => {
    const options = [];
    if (types && Object.keys(types).length) {
      Object.keys(types).forEach((type) => {
        options.push({
          label: types[type]["attributes"]["translation"],
          value: types[type]["attributes"]["filterValue"],
        });
      });
    }
    return options;
  };

  prepareFirmaOptions = (employers) => {
    let options = [];
    if (employers && Object.keys(employers).length) {
      Object.keys(employers).forEach((employerKey) => {
        options.push({
          label: employers[employerKey]["attributes"]["name"],
          value: employers[employerKey].id,
        });
      });
    }
    return options;
  };

  openModal = () => {
    this.props.showValidations(false);
    this.setState({ modalIsOpen: true }, () => {
      this.props.history.push({
        pathname: this.isPensionskasse
          ? `/pensionskasse/filetransfer/${this.state.currentTab}`
          : `/filetransfer/${this.state.currentTab}`,
        search: "?content=upload",
      });
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false }, () => {
      this.props.history.push({
        pathname: this.isPensionskasse
          ? `/pensionskasse/filetransfer/${this.state.currentTab}`
          : `/filetransfer/${this.state.currentTab}`,
      });
    });
  };

  afterOpenModal = () => {
    const modal = document.querySelector(".large-modal");
    if (modal) {
      modal.removeAttribute("tabIndex");
    }
  };

  renderPopupContent() {
    if (this.state.modalIsOpen) {
      //
    } else {
      return (
        <div
          className="popup_content download"
          onClick={() => this.props.setPopup(false)}
        >
          <div className="popup_title">File Download</div>
          <div className="popup_text">Der Download startet in Kurze</div>
          <div className="popup_spinner download-spinner">
            <Spinner relative />
          </div>
        </div>
      );
    }
  }

  activateRecievedTab = () => {
    this.setState({ currentTab: "empfangen" });
    this.props.history.push({
      pathname: this.isPensionskasse
        ? "/pensionskasse/filetransfer/empfangen"
        : "/filetransfer/empfangen",
    });
  };

  activateSentTab = () => {
    this.setState({ currentTab: "gesendet" });
    this.props.history.push({
      pathname: this.isPensionskasse
        ? "/pensionskasse/filetransfer/gesendet"
        : "/filetransfer/gesendet",
    });
  };

  handleSetLoading = (value) => {
    this.setState({
      showMoreSpinner: value,
      filtersChanged: this.state.filtersChanged + 1,
    });
  };

  render() {
    const { currentTab, showMoreSpinner, loading } = this.state;
    const { inboxSuccess, outboxSuccess } = this.props;
    const isInboxTab = currentTab == "empfangen";
    const isOutboxTab = currentTab == "gesendet";
    const isLoading =
      (!inboxSuccess && isInboxTab) ||
      (!outboxSuccess && isOutboxTab) ||
      showMoreSpinner ||
      loading;
    return (
      <div className="main-container">
        <Header
          hasMoreContent
          title="File-Transfer"
          locales={null}
          onFiltersButtonClick={() =>
            this.setState({ filtersOpened: !this.state.filtersOpened })
          }
        >
          <Tab
            active={this.state.currentTab === "empfangen"}
            minWidth
            className="mr-5"
            onClick={this.activateRecievedTab}
          >
            <span className="mdc-tab__text-label">
              <FormattedMessage id="app.received" defaultMessage="Empfangen" />
            </span>
          </Tab>
          <Tab
            active={this.state.currentTab === "gesendet"}
            minWidth
            onClick={this.activateSentTab}
          >
            <span className="mdc-tab__text-label">
              <FormattedMessage id="app.sent" defaultMessage="Gesendet" />
            </span>
          </Tab>
        </Header>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Large Modal"
          className={classNames("large-modal", {
            "modal-fade-out": this.state.modalFadeOut,
          })}
          overlayClassName={"large-modal-overlay"}
        >
          <UploadModal
            open={this.state.modalIsOpen}
            close={this.closeModal}
            loading={this.state.loading}
            firmaOptions={this.prepareFirmaOptions(this.props.employers)}
            documentTypeOptions={this.prepareDocumentTypeOptions(
              this.props.documentTypes
            )}
            checkFormValidity={this.checkFormValidity}
            validationMessageConfig={this.validationMessageConfig}
            submitAction={this.submitAction}
          />
        </Modal>

        <Modal
          isOpen={this.props.popupIsOpen}
          contentLabel="Small Modal"
          className={"small-modal"}
          overlayClassName={"small-modal-overlay"}
        >
          {this.renderPopupContent()}
        </Modal>

        <div className="main-content-container">
          <div className="relative-container">
            {isLoading && <Spinner relative />}
            <div
              className="main-content-container-left"
              onScroll={this.handleScroll}
            >
              <div className="content-container">
                {!!(this.props.inboxSuccess && this.props.outboxSuccess) && (
                  <div className="top-action-buttons mt-40">
                    <Button
                      type={ButtonTypes.Text}
                      color={ButtonColors.Action}
                      onClick={() => this.openModal()}
                    >
                      <ImportIcon className="mr-12" />
                      <FormattedMessage
                        id="app.sendfile"
                        defaultMessage="Datei senden"
                      />
                    </Button>
                  </div>
                )}

                {this.state.currentTab === "empfangen" &&
                  this.props.inboxSuccess && (
                    <ReceivedTab
                      downloadingFiles={this.state.downloadingFiles}
                      setLoading={this.handleSetLoading}
                      filtersChanged={this.state.filtersChanged}
                      downloadFile={this.downloadFile}
                    />
                  )}

                {this.state.currentTab === "gesendet" &&
                  this.props.outboxSuccess && (
                    <SentTab
                      downloadingFiles={this.state.downloadingFiles}
                      setLoading={this.handleSetLoading}
                      filtersChanged={this.state.filtersChanged}
                      downloadFile={this.downloadFile}
                    />
                  )}
              </div>
            </div>
          </div>

          <div className="drawer" ref={(node) => (this.drawer = node)}>
            <Drawer opened={this.state.filtersOpened}>
              {this.state.currentTab === "empfangen" ? (
                <SideBar
                  type="Inbox"
                  filter={this.filterInbox.bind(this)}
                  firmaOptions={this.prepareFirmaOptions(this.props.employers)}
                  documentTypeOptions={this.prepareDocumentTypeOptions(
                    this.props.documentTypes
                  )}
                />
              ) : (
                <SideBar
                  type="Outbox"
                  filter={this.filterOutbox}
                  firmaOptions={this.prepareFirmaOptions(this.props.employers)}
                  documentTypeOptions={this.prepareDocumentTypeOptions(
                    this.props.documentTypes
                  )}
                />
              )}
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    inboxSuccess: state.upload.inboxSuccess,
    outboxSuccess: state.upload.outboxSuccess,

    popupIsOpen: state.app.popupIsOpen,
    sidebarOpened: state.app.sidebarOpened,
    employers: state.app.employers,
    documentTypes: state.app.documentTypes,

    inboxPageNo: state.upload.inboxPageNo,
    outboxPageNo: state.upload.outboxPageNo,

    moreInbox: state.upload.moreInbox,
    moreOutbox: state.upload.moreOutbox,

    filterNameOutbox: state.upload.filterNameOutbox,
    filterFirmaOutbox: state.upload.filterFirmaOutbox,
    filterTypeOutbox: state.upload.filterTypeOutbox,
    filterYearOutbox: state.upload.filterYearOutbox,
    filterMonthOutbox: state.upload.filterMonthOutbox,
    filterOnlyNewOutbox: state.upload.filterOnlyNewOutbox,

    filterNameInbox: state.upload.filterNameInbox,
    filterFirmaInbox: state.upload.filterFirmaInbox,
    filterTypeInbox: state.upload.filterTypeInbox,
    filterYearInbox: state.upload.filterYearInbox,
    filterMonthInbox: state.upload.filterMonthInbox,
    filterOnlyNewInbox: state.upload.filterOnlyNewInbox,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setPopup: (val) => dispatch(actions.setPopup(val)),
    setSidebar: (val) => dispatch(actions.setSidebar(val)),
    filterInbox: (val) => dispatch(actions.filterInbox(val)),
    filterOutbox: (val) => dispatch(actions.filterOutbox(val)),
    setInboxPageNo: (val) => dispatch(actions.setInboxPageNo(val)),
    setOutboxPageNo: (val) => dispatch(actions.setOutboxPageNo(val)),
    addNewFile: (val) => dispatch(actions.addNewFile(val)),
    updateProgress: (val) => dispatch(actions.updateProgress(val)),
    updateFileStatus: (val) => dispatch(actions.updateFileStatus(val)),
    fetchInbox: bindActionCreators(fetchInbox, dispatch),
    fetchOutbox: bindActionCreators(fetchOutbox, dispatch),
    initializeUpload: bindActionCreators(initializeUpload, dispatch),
    initializeDownload: bindActionCreators(initializeDownload, dispatch),
    uploadPart: bindActionCreators(uploadPart, dispatch),
    uploadFile: bindActionCreators(uploadFile, dispatch),
    showValidations: (val) => dispatch(actions.showValidations(val)),

    fetchInboxPkc: bindActionCreators(fetchInboxPkc, dispatch),
    fetchOutboxPkc: bindActionCreators(fetchOutboxPkc, dispatch),
    uploadFilePkc: bindActionCreators(uploadFilePkc, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Upload));
