import * as React from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { RootState } from "../../../../reducers";
import { Dispatch } from "redux";
import * as actions from "../../../../actions";
import { connect } from "react-redux";

interface Props {
  intl: any;
  onSubmit: any;
  onCancel: any;
  loading: boolean;
  affectedMutations: any;
  affectedCompany: any;
  affectedCsvImportDate: any;
  isImportMutation?: boolean;
}

interface IState {}

class DeleteMutationsPopup extends React.Component<Props, IState> {
  constructor(props?: any, context?: any) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      affectedMutations,
      affectedCompany,
      affectedCsvImportDate,
      isImportMutation,
    } = this.props;
    return (
      <div>
        <h1 className="mb-24">
          {isImportMutation ? (
            <FormattedMessage
              id="app.deleteimportmutationconfirmation"
              defaultMessage="Wollen Sie diesen Import wirklich rückgängig machen?"
            />
          ) : (
            <FormattedMessage
              id="app.deletemutationconfirmation"
              defaultMessage="Wollen Sie diese Mutation wirklich löschen?"
            />
          )}
        </h1>

        {/* Show only when have more then one mutation afected */}
        {(affectedMutations >= 1 || affectedCompany) && (
          <div>
            {affectedMutations !== "" && (
              <p>Betroffene Mutationen: {affectedMutations}</p>
            )}
            {!!affectedCompany && <p>Betroffene Firma: {affectedCompany}</p>}
            {isImportMutation && (
              <p>zu löschender Import: {affectedCsvImportDate}</p>
            )}
          </div>
        )}

        {isImportMutation ? (
          <p className="mt-24">
            <b>ACHTUNG:</b> nachträgliche manuelle Mutationen gehen ebenfalls
            verloren!
          </p>
        ) : (
          <p className="mt-24">
            <b>ACHTUNG:</b> nachträgliche Mutationen werden ebenfalls gelöscht!
          </p>
        )}

        <div className="modal-buttons">
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={() => this.props.onCancel()}
          >
            <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
          </Button>
          <Button
            isDisabled={this.props.loading}
            type={ButtonTypes.Standard}
            style={ButtonStyles.Primary}
            color={ButtonColors.Error}
            onClick={() => this.props.onSubmit()}
          >
            jetzt löschen
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  ...ownProps,
  insurants: state.insurants.insurants,
  employers: state.app.employers,
  entities: state.app.entities,
  loading: state.app.loading,
});
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(DeleteMutationsPopup));
