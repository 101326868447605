import * as React from "react";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { FormattedMessage } from "react-intl";
import { mutationFormTypes } from "../../../../constants";
import "./MutationsMenu.scss";
import { useHistory } from "react-router";
import { ValidationFormProps } from "../../../../@types/forms/agp/formValidation";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isObjEmpty } from "../../../../utils/react";

interface Props {
  onMenuItemClick: any;
  formConfig: ValidationFormProps;
}

export default function MutationsMenu({ onMenuItemClick, formConfig }: Props) {
  const history = useHistory();
  const isPensionskasse = history.location.pathname.includes("/pensionskasse/");

  const renderSkeleton = () => {
    return (
      <Skeleton
        style={{
          borderRadius: 10,
          marginTop: 25,
        }}
        height={20}
        width={150}
      />
    );
  };

  return (
    <div className="row">
      <div className="col-12 col-sm-6 col-xl-4 mb-5">
        <p className="mutations-menu-title text-grey">
          <FormattedMessage
            id="app.changepersonaldetails"
            defaultMessage="Personalien ändern"
          />
        </p>

        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formInsurantInfo"]
              ?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.Versicherteninfos)
                }
              >
                <FormattedMessage
                  id="app.insurantinfo"
                  defaultMessage="Versicherteninfos"
                />
              </Button>
            )}

        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formAddress"]?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.NewAddress)
                }
              >
                <FormattedMessage id="app.address" defaultMessage="Adresse" />
              </Button>
            )}
      </div>

      <div className="col-12 col-sm-6 col-xl-4 mb-5">
        <p className="mutations-menu-title colorGrey">Beschäftigung ändern </p>
        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formSalary"]?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.NewPayroll)
                }
              >
                <FormattedMessage
                  id="app.mutationsMenu.payroll"
                  defaultMessage="Beschäftigungsgrad/Lohn"
                />
              </Button>
            )}
        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formUnpaidVacation"]
              ?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.NewUnpaidVacation)
                }
              >
                <FormattedMessage
                  id="app.unpaidvacation"
                  defaultMessage="Unbezahlter Urlaub"
                />
              </Button>
            )}
        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formLeaving"]?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.Austritt)
                }
              >
                <FormattedMessage id="app.leaving" defaultMessage="Austritt" />
              </Button>
            )}
        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !isPensionskasse &&
            !formConfig.formCustomization.components["formIncapacity"]
              ?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.Unemployability)
                }
              >
                <FormattedMessage
                  id="app.unemployability"
                  defaultMessage="Erwerbsunfähigkeit"
                />
              </Button>
            )}
      </div>

      <div className="col-12 col-sm-6 col-xl-4 mb-5">
        <p className="mutations-menu-title colorGrey">Ereignisse ändern</p>
        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formCivilStatus"]
              ?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.Zivilstand)
                }
              >
                <FormattedMessage
                  id="app.civilstatus"
                  defaultMessage="Zivilstand"
                />
              </Button>
            )}
        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formRetirement"]
              ?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.Pensionierung)
                }
              >
                <FormattedMessage
                  id="app.retirement"
                  defaultMessage="Pensionierung"
                />
              </Button>
            )}
        {isObjEmpty(formConfig.formCustomization.components)
          ? renderSkeleton()
          : !formConfig.formCustomization.components["formDeath"]?.hidden && (
              <Button
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
                className="colorMain w-auto buttonLink d-block text-uppercase font-14 text-left"
                onClick={() =>
                  onMenuItemClick(null, mutationFormTypes.Todesfall)
                }
              >
                <FormattedMessage id="app.death" defaultMessage="Todesfall" />
              </Button>
            )}
      </div>
    </div>
  );
}
