import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import {RootState} from './reducers/index';
import * as actions from './actions/';
import {logOutLocally} from './actions/';
import './App.scss';
import {setBodyClassNames} from "./utils/react";

import AuthRoute from "./components/Routes/AuthRoute";
import MainRoute from "./components/Routes/MainRoute";
import LoginRoute from "./components/Routes/LoginRoute";

import Cockpit from './containers/Cockpit';
import Invoices from './containers/Invoices';
import Insurances from './containers/Insurances';
import Login from './containers/Login';
import Upload from './containers/Upload';
import {getParameterByName} from "./utils/toSearchParams";

import PensionskasseCockpit from './containers/Pensionskasse/Cockpit';
import PensionskasseInvoices from './containers/Pensionskasse/Invoices';
import PensionskasseMutations from './containers/Pensionskasse/Mutations';
import PensionskasseInsurances from './containers/Pensionskasse/Insurances';
import PensionskasseFirmen from './containers/Pensionskasse/Companies';
import Sidebars from "./components/Sidebar";
import Spinner from "./components/Spinner";
import {authenticate, logOut} from "./actions/auth";

export interface IconProps {
    color: string;
}

export interface AppProps {
    sidebarOpened: boolean;
    authentication: any;
    setSidebar: any;
    loading: boolean;
    logOut: any;
    invoices: any;
    location: any;
    authenticate: any;
    history: any;
}

const hasInvoices = process.env.REACT_APP_INVOICES == "true";


const App: React.FC<AppProps> = ({ sidebarOpened, setSidebar, authentication, loading, invoices, location, authenticate, history, logOut }) => {

    const [isLoading, setIsLoading] = useState(false);

    setBodyClassNames(sidebarOpened);

    useEffect(() => {
        const token = getParameterByName("token", null);
        const redirect = getParameterByName("redirect", null);

        if(!token || !redirect) {
            return;
        }

        setIsLoading(true);
        logOut();

        authenticate(token)
            .then(res => {
                setIsLoading(false);
                if(!res) {
                    history.push("/login");
                } else {
                    history.push(redirect);
                }
            });

    }, []);

    useEffect(() => {
        let tenant = getParameterByName("tenantName", null);
        let currentTenant = window.localStorage.getItem("arbeitgeberportal_tenant");
        if(tenant && (currentTenant != tenant)) {
            window.localStorage.setItem("arbeitgeberportal_tenant", tenant);
            window.location.reload();
        }
    }, []);

    return (
        <div>

            {!isLoading ? <>
            <Sidebars
                authentication={authentication}
                setSidebar={setSidebar}
                sidebarOpened={sidebarOpened}
                hasInvoices={hasInvoices}/>

            {/* Main routes */}
            <MainRoute path={"/"} location={location} authentication={authentication} />

            {/* Login routes */}
            <LoginRoute path={"/login"} location={location} authentication={authentication}>
                <Login />
            </LoginRoute>

            <LoginRoute path={"/pensionskasse/login"} location={location} authentication={authentication}>
                <Login />
            </LoginRoute>

            {/* Arbeitgeber routes */}
            <AuthRoute path={"/cockpit"} location={location} authentication={authentication}>
                <Cockpit />
            </AuthRoute>
            <AuthRoute path={"/versicherte/:tab?"} location={location} authentication={authentication}>
                <Insurances />
            </AuthRoute>
            <AuthRoute path={"/rechnungen"} location={location} authentication={authentication}>
                <Invoices /> {/* hasInvoices */}
            </AuthRoute>
            <AuthRoute path={"/filetransfer/:tab?"} location={location} authentication={authentication}>
                <Upload />
            </AuthRoute>

            {/* Pensionskasse routes */}
            <AuthRoute path={"/pensionskasse/cockpit"} location={location} authentication={authentication}>
                <PensionskasseCockpit />
            </AuthRoute>
            <AuthRoute path={"/pensionskasse/mutationen/:tab?"} location={location} authentication={authentication}>
                <PensionskasseMutations />
            </AuthRoute>
            <AuthRoute path={"/pensionskasse/versicherte"} location={location} authentication={authentication}>
                <PensionskasseInsurances />
            </AuthRoute>
            <AuthRoute path={"/pensionskasse/firmen"} location={location} authentication={authentication}>
                <PensionskasseFirmen />
            </AuthRoute>
            <AuthRoute path={"/pensionskasse/rechnungen"} location={location} authentication={authentication}>
                <PensionskasseInvoices /> {/* hasInvoices */}
            </AuthRoute>
            <AuthRoute exact path={"/pensionskasse"} location={location} authentication={authentication}>
                <Redirect to="/pensionskasse/cockpit"/>
            </AuthRoute>
            <AuthRoute path={"/pensionskasse/filetransfer/:tab?"} location={location} authentication={authentication}>
                <Upload />
            </AuthRoute>
            </> : <Spinner relative />}

        </div>
    );
}


function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        ...state.mutations,
        ...state.insurants,
        ...state.invoices
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setSidebar: (val) => dispatch(actions.setSidebar(val)),
        logOut: bindActionCreators(logOut, dispatch),
        authenticate: bindActionCreators(authenticate, dispatch),
        logOutLocally: bindActionCreators(logOutLocally, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
