import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-action.svg";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { RootState } from "../../../../reducers/index";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import { parseDate } from "../../../../utils/react";
import Tooltip from "rc-tooltip";
import { useForm, Controller } from "react-hook-form";
import { validateInputMessage } from "../../../../utils/validationTools";
import { ValidationMessage } from "../../../../@types/forms/agp/formValidation";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import { ReactComponent as IconHelp } from "../../../../assets/icons/help.svg";
import { EintrittFormValues } from "../../../../reducers/arbeitgeber/types";
import { bindActionCreators } from "redux";
import { updateFieldValues } from "../../../../actions/arbeitgeber/user";

interface Props {
  checkFormValidity: any;
  civilStatusOptions: any;
  closeModal: any;
  eintrittBestehenderPerson?: boolean;
  employers: any;
  entities?: any;
  firmaOptions: any;
  getPersonBySsn: any;
  goToNextStep: any;
  goToPreviousStep: any;
  initialData: any;
  isLoading: boolean;
  landOptions: any;
  languageOptions: any;
  sexOptions: any;
  step: number;
  submitAction: any;
  wiedereintritt?: boolean;
  intl: any;
  insurantsObj: any;
  location: any;
  reset?: any;
  backendValidation?: any;
  history: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
  isMutationsTab?: boolean;

  checkValidationAgainstValues: any;
  isPayrollTooltipVisible: any;
  setFormState: any;
  formState: any;
  storedValues: EintrittFormValues;
  updateFieldValues: any;

  formConfig: any;
  setFormConfig: any;

  getValues: any;
  setValue: any;
  confirmEvent: any;
}

const Step3 = (props: Props) => {
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      payroll: {
        entryDate:
          props?.storedValues?.payroll?.entryDate ||
          props.getValues("eintrittErfassenForm.employee.entryDate") ||
          "",
        grossSalary:
          props?.storedValues?.payroll?.grossSalary ||
          props.getValues("eintrittErfassenForm.payroll.grossSalary") ||
          "",
        activityRate:
          props?.storedValues?.payroll?.activityRate ||
          props.getValues("eintrittErfassenForm.payroll.activityRate") ||
          "",
        payrollNote:
          props?.storedValues?.payroll?.payrollNote ||
          props.getValues("eintrittErfassenForm.payroll.payrollNote") ||
          "",
        validFrom:
          props?.storedValues?.employee?.entryDate ||
          props.getValues("eintrittErfassenForm.employee.validFrom") ||
          "",
      },
      requiredFields: {},
    },
  });

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(getValues("requiredFields")).every(
        (key) => getValues("requiredFields")[key]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("requiredFields")]);

  const requiredFieldsStep3 = useCallback(() => {
    const requiredForStep3 = {};
    Object.keys({
      grossSalary: "",
      activityRate: "",
      payrollNote: "",
      validFrom: "",
    }).map(
      (key) =>
        props.formConfig.formCustomization.components[key]?.required &&
        (requiredForStep3[key] = !!getValues("payroll")[key])
    );
    setValue("requiredFields", requiredForStep3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("payroll"), setValue]);

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const currentTenant = globalSettings?.attributes?.tenantCode;

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      requiredFieldsStep3();
    }
  }, [props.formConfig.formCustomization, requiredFieldsStep3]);

  const prepareToNextStep = () => {
    if (!isDisabledButton) {
      const payroll = {
        entryDate: props.getValues("eintrittErfassenForm.employee.entryDate"),
        grossSalary: getValues("payroll.grossSalary"),
        activityRate: getValues("payroll.activityRate"),
        payrollNote: getValues("payroll.payrollNote"),
        validFrom: getValues("payroll.validFrom"),
      };
      props.setValue("eintrittErfassenForm.payroll", payroll);
    }
    props.goToNextStep();
  };

  return (
    <div className="row">
      {/* Lohn */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="payroll.grossSalary"
          render={({ field: { value } }) => (
            <Input
              isFocused
              type="money"
              hidden={
                props.formConfig.formCustomization.components["grossSalary"]
                  ?.hidden
              }
              inputWrapperClass="text-input lohn-input"
              id="grossSalary"
              name="grossSalary"
              placeholder={props.intl.formatMessage({
                id:
                  currentTenant === "PKSH"
                    ? "app.forms.eintritt.grossSalaryPKSH.placeholder"
                    : "app.forms.eintritt.grossSalary.placeholder",
                defaultMessage:
                  currentTenant === "PKSH"
                    ? "Jahreslohn in CHF"
                    : "Lohn eingeben",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("payroll.grossSalary", valueInput);
                props.updateFieldValues({
                  payroll: {
                    ...props?.storedValues?.payroll,
                    grossSalary: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "grossSalary",
                  () => [EValidationTestType.isNumber],
                  getValues("requiredFields")
                );
                setError("payroll.grossSalary", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "grossSalary",
                  () => [EValidationTestType.isNumber],
                  getValues("requiredFields")
                );
                setError("payroll.grossSalary", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.payroll?.grossSalary?.message,
                  type: errors?.payroll?.grossSalary
                    ?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id={
                  currentTenant === "KUSP"
                    ? "app.forms.eintritt.grossSalaryKusnacht"
                    : currentTenant === "PKSH"
                    ? "app.forms.eintritt.grossSalaryPKSH"
                    : "app.forms.eintritt.grossSalary"
                }
                defaultMessage="Lohn"
              />
              {props.formConfig.formCustomization.components["grossSalary"]
                ?.required
                ? " *"
                : ""}
              {props.isPayrollTooltipVisible && (
                <Tooltip
                  trigger={["click"]}
                  placement="top"
                  overlay={
                    <FormattedMessage
                      id="app.forms.eintritt.grossSalary.tooltip"
                      defaultMessage="Tooltip"
                    />
                  }
                  arrowContent={<div className="rc-tooltip-arrow-inner" />}
                >
                  <div className="help-icon pointer">
                    <IconHelp />
                  </div>
                </Tooltip>
              )}
            </Input>
          )}
        />
      </div>

      {/* Beschäftingungsgrad */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="payroll.activityRate"
          render={({ field: { value } }) => (
            <Input
              type="percentage"
              key="activityRateInput"
              hidden={
                props.formConfig.formCustomization.components["activityRate"]
                  ?.hidden
              }
              inputWrapperClass="text-input"
              id="activityRate"
              name="activityRate"
              placeholder={props.intl.formatMessage({
                id: "app.enterlevelofemployment",
                defaultMessage: "Beschäftigungsgrad in %",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("payroll.activityRate", valueInput);
                props.updateFieldValues({
                  payroll: {
                    ...props?.storedValues?.payroll,
                    activityRate: valueInput,
                  },
                });

                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "activityRate",
                  () => [EValidationTestType.isBeschaftingungsgrad],
                  getValues("requiredFields")
                );
                setError("payroll.activityRate", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "activityRate",
                  () => [EValidationTestType.isBeschaftingungsgrad],
                  getValues("requiredFields")
                );
                setError("payroll.activityRate", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.payroll?.activityRate?.message,
                  type: errors?.payroll?.activityRate
                    ?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id="app.forms.eintritt.activityRate"
                defaultMessage="Beschäftingungsgrad"
              />
              {props.formConfig.formCustomization.components["activityRate"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* Gutlig ab */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="payroll.validFrom"
          render={({ field: { value } }) => (
            <Input
              type="date"
              key="validFromInput"
              hidden={
                props.formConfig.formCustomization.components["validFrom"]
                  ?.hidden
              }
              maxLength={10}
              inputWrapperClass="text-input"
              id="validFrom"
              name="validFrom"
              placeholder={props.intl.formatMessage({
                id: "app.validfrom",
                defaultMessage: "Gültig ab",
              })}
              value={value?.length >= 10 && parseDate(value)}
              onChange={(name, valueInput) => {
                setValue("payroll.validFrom", valueInput);
                props.updateFieldValues({
                  payroll: {
                    ...props?.storedValues?.payroll,
                    validFrom: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "validFrom",
                  () => [EValidationTestType.dateIsNotBeforeEntrydate],
                  getValues("requiredFields")
                );
                setError("payroll.validFrom", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "validFrom",
                  () => [EValidationTestType.dateIsNotBeforeEntrydate],
                  getValues("requiredFields")
                );
                setError("payroll.validFrom", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.payroll?.validFrom?.message,
                  type: errors?.payroll?.validFrom
                    ?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.validfrom" defaultMessage="Gültig ab" />
              {props.formConfig.formCustomization.components["validFrom"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6" />

      {/* Bemerkungen */}
      <div className="col-12">
        <Controller
          control={control}
          name="payroll.payrollNote"
          render={({ field: { value } }) => (
            <Input
              type="content"
              key="payrollNoteInput"
              hidden={
                props.formConfig.formCustomization.components["payrollNote"]
                  ?.hidden
              }
              inputWrapperClass="text-input"
              id="payrollNote"
              name="payrollNote"
              placeholder="Bemerkungen eingeben"
              value={value}
              onChange={(name, valueInput) => {
                setValue("payroll.payrollNote", valueInput);
                props.updateFieldValues({
                  payroll: {
                    ...props?.storedValues?.payroll,
                    payrollNote: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "payrollNote",
                  null,
                  getValues("requiredFields")
                );
                setError("payroll.payrollNote", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("payroll"),
                  "payrollNote",
                  null,
                  getValues("requiredFields")
                );
                setError("payroll.payrollNote", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.payroll?.payrollNote?.message,
                  type: errors?.payroll?.payrollNote
                    ?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id="app.payrollnote"
                defaultMessage="Bemerkungen"
              />
              {props.formConfig.formCustomization.components["payrollNote"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6" />

      <div className="col-12 col-sm-6">
        <div className="modal-buttons">
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={props.goToPreviousStep}
          >
            <ArrowRight className="mr-16 rotate-180" />
            <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
          </Button>
          <Button
            submit={true}
            isDisabled={isDisabledButton}
            onClick={() => prepareToNextStep()}
            type={ButtonTypes.Standard}
            color={ButtonColors.Action}
            style={ButtonStyles.Primary}
          >
            <FormattedMessage id="app.continue" defaultMessage="WEITER" />
          </Button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
    storedValues: state.app.storedValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFieldValues: bindActionCreators(updateFieldValues, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Step3))
);
