import React from "react";
import {connect} from "react-redux";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {
    Blank,
    Button,
    ButtonColors,
    ButtonTypes,
    ExpandableRow,
    GroupPositions,
    StatusColors, StatusShape,
} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as ImportIcon} from "../../../../assets/icons/import.svg";
import {ReactComponent as TrashIcon} from "../../../../assets/icons/trash.svg";
import styles from "./MutationenTab.module.scss"
import MutationExpandableRowTop from "../MutationExpandableRowTop";
import {getModalName} from "../../../../utils/react";
import {ProcessingStates} from "../../../../models";
import {fetchMutations} from "../../../../actions/arbeitgeber/mutations";
import {filterMutations, setMutationsPageNo} from "../../../../actions";
import ProcessingMutations from "../../../ProcessingMutations";
import classNames from "classnames";

const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || "20";

interface State {
    expandedRow: any;
    filtersChanged: any;
    showMoreSpinner: any;
}

interface Props {
    intl: any;
    openModal: any;
    mutations: any;
    moreMutations: boolean;
    fetchMutations: any;
    mutationsPageNo: number;
    setMutationsPageNo: any;
    onEditClick: any;
    onDownloadClick: any;
    onDeleteClick: any;
    onDeleteCsv: any;
    onCsvImportClick: any;
    onExcelImportClick: () => void;
    isLoading: boolean;
    isImporting: boolean;
    excelProcessedMutations: number
    csvImports: any;
    filtersChanged: any;
    filterMutations: any;
    setLoading: any;

    morePadding: boolean;

    importJobs: any;
    importJobErrorMutations: any;
    onDeleteImportJob: any;
    mutationsFetched: boolean;
    filterByNameMutations: string;
    filterByFirmaMutations: string;
    filterByTypeMutations: string;
    filterByAlsoClosedMutations: boolean;
    globalSettings: any;
    actionsButton: any;
}

class MutationTab extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            expandedRow: null,
            filtersChanged: 0,
            showMoreSpinner: false,
        };
    }

    rowChanged = (expanded, row) => {
        this.setState({expandedRow: row});
        // if (expanded) {
        //     document.querySelector('.main-content-container.insurances').classList.add("expanded");
        // } else {
        //     document.querySelector('.main-content-container.insurances').classList.remove("expanded");
        // }
    };

    committedMutations = (mutations) => {
        return mutations.filter(mutation => mutation.attributes.processingState != ProcessingStates.Changed);
    }

    uncommittedMutations = (mutations) => {
        return mutations.filter(mutation => mutation.attributes.processingState == ProcessingStates.Changed && !mutation.attributes.isWithheld);
    }

    groupByWithheld = (mutations) => {
        return mutations.filter(mutation => mutation.attributes.isWithheld);
    }

    clearFilters = () => {
        this.props.filterMutations({name: "clear"});
        this.props.setMutationsPageNo(0);
        this.props.setLoading(true);
        this.props.fetchMutations().then(() => {
            this.props.setLoading(false);
            this.props.setMutationsPageNo(1);
        })
    }

    filtersApplied = () => {
        return !!(this.props.filterByNameMutations || this.props.filterByTypeMutations || this.props.filterByFirmaMutations || this.props.filterByAlsoClosedMutations);
    }

    render() {
        const {mutations, importJobErrorMutations} = this.props;
        const committedMutations = this.committedMutations(mutations);
        const uncommittedMutations = this.uncommittedMutations(mutations);
        const groupByIsWithheld = this.groupByWithheld(mutations);
        const importsToDelete = this.props.importJobs?.find(job => !job.isCommitted);
        const globalSettings = JSON.parse(localStorage.getItem('global-settings')) || this.props.globalSettings;

        return (
            <div className="content-container">
               {!!(this.props.mutationsFetched || this.props.mutations.length) && <>
                    <div className={styles.importActions}>
                        <div className="d-flex top-action-buttons">
                        {this.props.actionsButton()?.map(item=> item.visible && <Button
                            className={classNames({"disabled-delete-import-button": this.props.isImporting},"mr-40") }
                            type={ButtonTypes.Text}
                            color={ButtonColors.Action}
                            onClick={() => item.action()}>
                            {item.content}
                        </Button>)}
                        </div>
                        {this.props.isImporting ? <ProcessingMutations count={this.props.excelProcessedMutations}/>: null }
                    </div>
                   <div className={classNames(styles.listView, {[styles.morePadding]: this.props.morePadding})}>
                        <div className={styles.listHeader}>
                            <div className={styles.column}>
                                <FormattedMessage id="app.type" defaultMessage="Typ / Name"/>
                            </div>
                            <div className={styles.column}>
                                <FormattedMessage id="app.validFrom" defaultMessage="Gültig ab"/>
                            </div>
                            <div className={styles.column}>
                                <span>
                                    <FormattedMessage id="app.pervValue" defaultMessage="bisher"/>
                                </span>
                                <span>
                                    <FormattedMessage id="app.new" defaultMessage="neu"/>
                                </span>
                            </div>
                            <div className={styles.column}>
                                <FormattedMessage id="app.source" defaultMessage="Quelle / Datum / Bearbeiter"/>
                            </div>
                            <div className={styles.column}>
                                <div style={{width: "7rem"}} />
                            </div>
                        </div>

                        {this.props.mutations.length > 0 || importJobErrorMutations.length > 0 ? (
                            <>
                                {importJobErrorMutations.map((mutation, index) => {
                                    return (
                                        <ExpandableRow
                                            className="mb-24"
                                            statusBar={mutation.attributes.hasError ? {
                                                text: mutation.attributes.errorText,
                                                color: StatusColors.ERROR,
                                                shape: StatusShape.BOTTOM_ROUNDED
                                            } : null}
                                            content={
                                                <MutationExpandableRowTop
                                                    mutation={mutation}
                                                    onDeleteClick={() => this.props.onDeleteClick(mutation.id)}
                                                    onEditClick={() => this.props.onEditClick(mutation.id, getModalName(mutation.attributes.mutationType.name))}
                                                    onDownloadClick={() => this.props.onDownloadClick(mutation.attributes.fileId)}
                                                    expanded={`${mutation.id}-${index}` == this.state.expandedRow}
                                                    toggleExpand={() => this.setState({ expandedRow: this.state.expandedRow == `${mutation.id}-${index}` ? null : `${mutation.id}-${index}` })} />}/>
                                    );
                                })}
                                {uncommittedMutations.map((mutation, index) => {
                                    return (
                                        <ExpandableRow
                                            className="mb-24"
                                            statusBar={mutation.attributes.processingState != 2 && mutation.attributes.processingState != 3 && mutation.attributes.hasError ? {
                                                text: mutation.attributes.errorText,
                                                color: StatusColors.ERROR,
                                                shape: StatusShape.BOTTOM_ROUNDED
                                            } : null}
                                            content={
                                                <MutationExpandableRowTop
                                                    mutation={mutation}
                                                    onDeleteClick={() => this.props.onDeleteClick(mutation.id)}
                                                    onEditClick={() => this.props.onEditClick(mutation.id, getModalName(mutation.attributes.mutationType.name))}
                                                    onDownloadClick={() => this.props.onDownloadClick(mutation.attributes.fileId)}
                                                    expanded={`${mutation.id}-${index}` == this.state.expandedRow}
                                                    toggleExpand={() =>{
                                                        this.setState({ expandedRow: this.state.expandedRow == `${mutation.id}-${index}` ? null : `${mutation.id}-${index}` })}}/>}/>
                                    );
                                })}
                                {!!groupByIsWithheld.length && <>
                                    <p className={styles.labelHeaderText}>
                                        Zurückgehalten
                                    </p>
                                    <p className={styles.labelContentText}>
                                        {`Die folgenden Mutationen liegen in einem durch die Pensionskasse noch nicht freigegebenen Zeitraum (nach dem 01.01.${globalSettings.attributes?.runningYear+1}). Sie brauchen nichts weiter zu tun. Bei der nächsten Abgabe werden diese Mutationen automatisch mit gesendet, sofern der Zeitraum freigegeben wurde.`}
                                    </p>
                                </>}
                                {groupByIsWithheld.map((mutation,index)=>{
                                    return (
                                        <ExpandableRow
                                            className="mb-24"
                                            statusBar={mutation.attributes.processingState != 2 && mutation.attributes.processingState != 3 && mutation.attributes.hasError ? {
                                                text: mutation.attributes.errorText,
                                                color: StatusColors.ERROR,
                                                shape: StatusShape.BOTTOM_ROUNDED
                                            } : null}
                                            content={
                                                <MutationExpandableRowTop
                                                    mutation={mutation}
                                                    onDeleteClick={() => this.props.onDeleteClick(mutation.id)}
                                                    onEditClick={() => this.props.onEditClick(mutation.id, getModalName(mutation.attributes.mutationType.name))}
                                                    onDownloadClick={() => this.props.onDownloadClick(mutation.attributes.fileId)}
                                                    expanded={`${mutation.id}-${index}` == this.state.expandedRow}
                                                    toggleExpand={() => this.setState({ expandedRow: this.state.expandedRow == `${mutation.id}-${index}` ? null : `${mutation.id}-${index}` })} />}/>
                                    )
                                })}

                                {!!committedMutations.length &&
                                <p className={styles.listHeaderText}>
                                    Abgegebene Mutationen
                                </p>}
                                {committedMutations.map((mutation, index) => {
                                    return (
                                        <ExpandableRow
                                            className="mb-24"
                                            statusBar={(mutation.attributes.processingState != 2 && mutation.attributes.processingState != 3 && mutation.attributes.hasError) || mutation.attributes.isDeleted ? {
                                                text: mutation.attributes.isDeleted ? mutation.attributes.reasonForDeletion : mutation.attributes.errorText,
                                                color: StatusColors.ERROR,
                                                shape: StatusShape.BOTTOM_ROUNDED
                                            } : null}
                                            content={
                                                <MutationExpandableRowTop
                                                    mutation={mutation}
                                                    onDeleteClick={() => this.props.onDeleteClick(mutation.id)}
                                                    onEditClick={() => this.props.onEditClick(mutation.id, getModalName(mutation.attributes.mutationType.name))}
                                                    onDownloadClick={() => this.props.onDownloadClick(mutation.attributes.fileId)}
                                                    expanded={`${mutation.id}-${index}` == this.state.expandedRow}
                                                    toggleExpand={() => this.setState({ expandedRow: this.state.expandedRow == `${mutation.id}-${index}` ? null : `${mutation.id}-${index}` })} />}/>
                                    );
                                })}
                            </>
                        ) : (
                            <Blank
                                onButtonClick={this.clearFilters}
                                // buttonText="Filter zurücksetzen"
                                showButton={this.filtersApplied()}
                                text={this.props.intl.formatMessage({
                                    id: "app.nomutations",
                                    defaultMessage: "Keine Mutationen vorhanden",
                                })}
                            />
                        )}
                    </div>
               </>}
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        mutations: state.mutations.mutations,
        csvImports: state.mutations.csv_imports,
        importJobs: state.mutations.import_jobs,
        importJobErrorMutations: state.mutations.import_job_error_mutations,

        mutationsFetched: state.mutations.mutationsFetched,
        filterByNameMutations: state.mutations.filterByNameMutations,
        filterByFirmaMutations: state.mutations.filterByFirmaMutations,
        filterByTypeMutations: state.mutations.filterByTypeMutations,
        filterByAlsoClosedMutations: state.mutations.filterByAlsoClosedMutations,

        globalSettings: state.globalSettings
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        filterMutations: val => dispatch(filterMutations(val)),
        setMutationsPageNo: val => dispatch(setMutationsPageNo(val)),
        fetchMutations: bindActionCreators(fetchMutations, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MutationTab));
