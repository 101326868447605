import * as React from 'react';
import {Input} from "icr-shared-components";
import {Button, ButtonColors, ButtonSizes, ButtonTypes} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as IconPdfDownload} from "../../../../assets/icons/pdfdownload.svg";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {connect} from "react-redux";
import {fetchEmployees, setInsurantsFilter, setInsurantsPageNo} from "../../../../actions/pensionskasse/employee";
import {ReactComponent as PdfIcon} from "../../../../assets/icons/download.svg";
import {prepareEmployersDropdownOptions} from "../../../../utils/react";

interface State {

}

interface Props {
    intl: any;
    employers: any;
    mutationTypes: any;
    setLoading: any;
    fetchEmployees: any;
    insurantsPageNo: any;
    setInsurantsFilter: any;
    setInsurantsPageNo: any;
    insurantsNameFilter: any;
    insurantsFirmaFilter: any;
    insurantsStatusFilter: any;

}

class Sidebar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    private debounce = null;

    filterInsurants = (name, value, employers?) => {
        this.props.setInsurantsFilter({ name, value });

        if(employers === true && value !== "") {
            return;
        }

        if(this.props[name] == value) {
            return;
        }
        this.props.setInsurantsPageNo(0);
        this.props.setLoading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.props.fetchEmployees().then(() => {
                this.props.setLoading(false);
                this.props.setInsurantsPageNo(this.props.insurantsPageNo + 1);
            })
        }, 500);
    }

    render() {
        const {employers} = this.props;
        const employerOptions = prepareEmployersDropdownOptions(employers);
        return (
            <>
                <div className="sidebar-container">
                    <p className="sidebar-header mb-16">
                        <FormattedMessage id="app.searchpeople" defaultMessage="Personen suchen" />
                    </p>
                    <Input
                        id="personSearch"
                        name="insurantsNameFilter"
                        key="personSearch"
                        inputWrapperClass="text-input filter-input"
                        placeholder={this.props.intl.formatMessage({ id: "app.namebirthdayssn", defaultMessage: "Name, Geburtstag, AHV-Nr." })}
                        value={this.props.insurantsNameFilter}
                        onChange={(name, value) => this.filterInsurants(name, value)}
                        validationMessageConfig={null}
                        type='text'
                        inputWrapperStyle={{}}>
                    </Input>
                    <Input
                        id="firmaSelect"
                        name="insurantsFirmaFilter"
                        key="firmaSelect"
                        inputWrapperClass="dropdown-input filter-input"
                        placeholder={this.props.intl.formatMessage({ id: "app.choosecompany", defaultMessage: "Firma wählen" })}
                        value={this.props.employers[this.props.insurantsFirmaFilter] ? this.props.employers[this.props.insurantsFirmaFilter]['attributes']['name'] : this.props.insurantsFirmaFilter}
                        onSelected={(name, value) => this.filterInsurants(name, value)}
                        onChange={(name, value) => this.filterInsurants(name, value, true)}
                        selectOptions={employerOptions}
                        validationMessageConfig={null}
                        type='text'>
                    </Input>
                    {/* TODO MAKE STATUS FILTER WORK --' */}
                    {/* <p className="sidebar-header mb-16">
                        <FormattedMessage id="app.status" defaultMessage="Status" />
                    </p>
                    <Input
                        id="firmaSelect"
                        name="firmaSelect"
                        key="firmaSelect"
                        inputWrapperClass="dropdown-input filter-input"
                        placeholder={this.props.intl.formatMessage({ id: "app.showall", defaultMessage: "Alle anzeigen" })}
                        value={""}
                        onSelected={(name, value) => this.filterInsurants(name, value)}
                        onChange={(name, value) => this.filterInsurants(name, value)}
                        selectOptions={[{value: "", dropdownLabel: ""}]}
                        validationMessageConfig={null}
                        type='text'
                        filtering>
                    </Input> */}
                    <Button
                        className="ml-auto"
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        size={ButtonSizes.Small}
                        onClick={() => this.filterInsurants("clear", "")}>
                        <FormattedMessage id="app.resettodefault" defaultMessage="Zurücksetzen" />
                    </Button>
                </div>

            </>
        );
    }


}


function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        entities: state.app.entities,
        mutationTypes: state.app.mutationTypes,

        insurantsPageNo: state.insurantsPK.insurantsPageNo,

        insurantsNameFilter: state.insurantsPK.insurantsNameFilter,
        insurantsFirmaFilter: state.insurantsPK.insurantsFirmaFilter,
        insurantsStatusFilter: state.insurantsPK.insurantsStatusFilter,

    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
        setInsurantsPageNo: (val) => dispatch(setInsurantsPageNo(val)),
        setInsurantsFilter: (val) => dispatch(setInsurantsFilter(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar));
