import {Button, ButtonTypes} from "icr-shared-components";
import React from "react";
import {FormattedMessage} from "react-intl";
import {ReactComponent as IconArrowRight} from "../../../../assets/icons/arrow-right-action.svg";
import UploadModalContent from "../UploadModalContent";

interface UploadModalProps {
    open: boolean;
    close: () => void;
    loading: boolean;
    firmaOptions: any[];
    documentTypeOptions: any[];
    validationMessageConfig: any;
    checkFormValidity: any;
    submitAction: (e, formObj) => void;
}
export default function UploadModal({
    open,
    close,
    loading,
    firmaOptions,
    documentTypeOptions,
    validationMessageConfig,
    checkFormValidity,
    submitAction,
}: UploadModalProps) {
    return (
        open && (
            <div className="modal">
                <div className="modal-header">
                    <div className="modal-header-left">
                        <Button
                            className="primary upload-modal-back-icon-wrapper"
                            onClick={() => close()}
                            to={null}
                            type={ButtonTypes.Text}>
                            <IconArrowRight className="rotate-180 mr-16" />
                            <FormattedMessage
                                id="app.backtooverview"
                                defaultMessage="Zurück zur Übersicht" 
                            />
                        </Button>
                    </div>
                </div>
                <div className="modal-sub-header">
                    <div className="modal-sub-header-left">Datei senden</div>
                </div>
                <UploadModalContent
                    loading={loading}
                    firmaOptions={firmaOptions}
                    documentTypeOptions={documentTypeOptions}
                    validationMessageConfig={validationMessageConfig}
                    checkFormValidity={checkFormValidity}
                    submitAction={submitAction}
                    close={close}
                />
            </div>
        )
    );
}
