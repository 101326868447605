import { ACTION } from "../index";
import {
  acceptedMutationsQueryParams,
  deletedMutationsQueryParams,
  forReviewMutationsCommitCompanyQueryParams,
  forReviewMutationsQueryParams,
} from "../../utils/toSearchParams";
import * as constants from "../../constants/pensionskasse";
import api from "../../utils/api";

export const setForReviewMutationsPageNo = (payload: boolean): ACTION => ({
  type: constants.SET_FOR_REVIEW_MUTATIONS_PAGE_NO,
  payload,
});

export const setAcceptedMutationsPageNo = (payload: boolean): ACTION => ({
  type: constants.SET_ACCEPTED_MUTATIONS_PAGE_NO,
  payload,
});

export const setDeletedMutationsPageNo = (payload: boolean): ACTION => ({
  type: constants.SET_DELETED_MUTATIONS_PAGE_NO,
  payload,
});

export const setForReviewMutationsFilter = (payload: boolean): ACTION => ({
  type: constants.SET_FOR_REVIEW_MUTATIONS_FILTERING,
  payload,
});

export const setAcceptedMutationsFilter = (payload: boolean): ACTION => ({
  type: constants.SET_ACCEPTED_MUTATIONS_FILTERING,
  payload,
});

export const setDeletedMutationsFilter = (payload: boolean): ACTION => ({
  type: constants.SET_DELETED_MUTATIONS_FILTERING,
  payload,
});

export const setForReviewMutationsFetched = (payload: boolean): ACTION => ({
  type: constants.SET_FOR_REVIEW_MUTATIONS_FETCHED,
  payload,
});

export const setAcceptedMutationsFetched = (payload: boolean): ACTION => ({
  type: constants.SET_ACCEPTED_MUTATIONS_FETCHED,
  payload,
});

export const setDeletedMutationsFetched = (payload: boolean): ACTION => ({
  type: constants.SET_DELETED_MUTATIONS_FETCHED,
  payload,
});

export const fetchForReviewMutations = (length, page, isProgress?) => {
  const params = forReviewMutationsQueryParams(length, page);
  return async (dispatch) => {
    return api
      .get(`mutations/validation${params}`)
      .then((response: any) => {
        const { data, links, meta } = response;
        if (!isProgress) {
          dispatch({
            type: constants.FETCHED_FOR_REVIEW_MUTATIONS,
            payload: { data, links },
            page: page,
          });
          dispatch({
            type: constants.FETCHED_FOR_REVIEW_MUTATIONS_COUNT,
            payload: meta,
          });
          dispatch({
            type: constants.FETCHED_CAN_SUBMIT_ALL,
            payload: meta,
          });
        } else {
          dispatch({
            type: constants.FETCHED_FOR_REVIEW_MUTATIONS_BY_PROGRESS,
            payload: data,
          });
        }
      })
      .catch((err) => err);
  };
};

export const fetchForReviewMutationsCockpit = (length, page) => {
  const params =
    "?page=size:undefined,page:undefined&filter=text[contains]:,mutationType.name[contains]:";
  return async (dispatch) => {
    return api
      .get(`mutations/validation${params}`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_FOR_REVIEW_MUTATIONS_COCKPIT,
          payload: response["data"],
          page: page,
        });
        dispatch({
          type: constants.FETCHED_FOR_REVIEW_MUTATIONS_COUNT_COCKPIT,
          payload: response["meta"],
        });
      })
      .catch((err) => err);
  };
};

export const fetchAcceptedMutations = (length, page, isProgress?) => {
  const params = acceptedMutationsQueryParams(length, page);
  return async (dispatch) => {
    return api
      .get(`mutations/archived${params}`)
      .then((response: any) => {
        const { data, links, meta } = response;
        if (!isProgress) {
          dispatch({
            type: constants.FETCHED_ACCEPTED_MUTATIONS,
            payload: { data, links },
            page: page,
          });
          dispatch({
            type: constants.FETCHED_ACCEPTED_MUTATIONS_COUNT,
            payload: meta,
          });
        } else {
          dispatch({
            type: constants.FETCHED_ACCEPTED_MUTATIONS_BY_PROGRESS,
            payload: data,
          });
        }
      })
      .catch((err) => err);
  };
};

export const fetchAcceptedMutationsCockpit = (length, page) => {
  return async (dispatch) => {
    return api
      .get(
        `mutations/archived?page=size:undefined,page:undefined&filter=text[contains]:,mutationType.name[contains]:`
      )
      .then((response) => {
        dispatch({
          type: constants.FETCHED_ACCEPTED_MUTATIONS_COCKPIT,
          payload: response["data"],
          page: page,
        });
        dispatch({
          type: constants.FETCHED_ACCEPTED_MUTATIONS_COUNT_COCKPIT,
          payload: response["meta"],
        });
      })
      .catch((err) => err);
  };
};

export const fetchDeletedMutations = (length, page) => {
  const params = deletedMutationsQueryParams(length, page);
  return async (dispatch) => {
    return api
      .get(`mutations/deleted${params}`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_DELETED_MUTATIONS,
          payload: response["data"],
          page: page,
        });
        dispatch({
          type: constants.FETCHED_DELETED_MUTATIONS_COUNT,
          payload: response["meta"],
        });
      })
      .catch((err) => err);
  };
};

export const fetchInsurantMutations = (insurantId) => {
  return async (dispatch) => {
    return api
      .get(`insurants/${insurantId}/mutations/`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_INSURANT_MUTATIONS,
          payload: response["data"],
        });
      })
      .catch((err) => err);
  };
};

export const postDeleteMutation = (data) => {
  return async (dispatch) => {
    return api
      .post(`mutations/deleted/`, data)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const processMutation = (data, skipProcessing?) => {
  return async (dispatch) => {
    return api
      .post(
        `mutations/archived/${skipProcessing ? "?skipProcessing=true" : ""}`,
        data
      )
      .then((response) => response)
      .catch((err) => err);
  };
};

export const processMutations = (excludeWarnings) => {
  const params = forReviewMutationsCommitCompanyQueryParams(excludeWarnings);
  return async (dispatch) => {
    return api
      .put(`insurants/archived/${params}`, {})
      .then((response) => response)
      .catch((err) => err);
  };
};

export const fetchForReviewMutationsProgress = () => {
  return async (dispatch) => {
    return api
      .get(`mutations/validation/progress`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_FOR_REVIEW_MUTATIONS_PROGRESS,
          payload: response["data"],
        });
      })
      .catch((err) => err);
  };
};

export const fetchAcceptedMutationsProgress = () => {
  return async (dispatch) => {
    return api
      .get(`mutations/archived/progress`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_ACCEPTED_MUTATIONS_PROGRESS,
          payload: response["data"],
        });
      })
      .catch((err) => err);
  };
};

export const removeMutationManualCommit = (mutationId: string) => {
  return async (dispatch) => {
    dispatch({
      type: constants.REMOVE_MUTATION_MANUAL_COMMIT,
      payload: mutationId,
    });
  };
};
