import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-white.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import "./Alert.scss";

export interface AlertProps {
  label: string;
  textButton: string;
  onButtonClick: (params?: any) => void;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

export class Alert extends React.Component<AlertProps> {
  public render() {
    return (
      <div
        className="alert-wrapper"
        style={{ display: this.props.isOpen ? "flex" : "none" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoIcon style={{ marginLeft: 5 }} />
          <div className="alert-label">{this.props.label}</div>
        </div>
        <div className="alert-button-box">
          <button className="alert-button" onClick={this.props.onButtonClick}>
            {this.props.textButton}
          </button>
          <div
            className="alert-icon-wrapper"
            onClick={() => this.props.setOpen(false)}
          >
            <CloseIcon width={11} />
          </div>
        </div>
      </div>
    );
  }
}
