import localesReducer, { LocalesState } from "./i18n";
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { connectRouter } from "connected-react-router";
import { intlReducer } from "react-intl-redux";
import AppReducer, { AppState } from "./arbeitgeber/app";
import InsurantsReducer, { InsurantsState } from "./arbeitgeber/insurants";
import InsurantsReducerPK, {
  InsurantsStatePK,
} from "./pensionskasse/insurants";
import MutationsReducer, { MutationsState } from "./arbeitgeber/mutations";
import CompaniesReducer, { CompaniesStatePK } from "./pensionskasse/companies";
import ForReviewMutationsReducer, {
  ForReviewMutationsStatePK,
} from "./pensionskasse/forReviewMutations";
import ForReviewMutationsCockpitReducer, {
  ForReviewMutationsStatePKCockpit,
} from "./pensionskasse/forReviewMutationsCockpit";

import AcceptedMutationsReducer, {
  AcceptedMutationsStatePK,
} from "./pensionskasse/acceptedMutations";
import AcceptedMutationsCockpitReducer, {
  AcceptedMutationsStatePKCockpit,
} from "./pensionskasse/acceptedMutationsCockpit";
import DeletedMutationsReducer, {
  DeletedMutationsStatePK,
} from "./pensionskasse/deletedMutations";
import InvoicesReducer, { InvoicesState } from "./arbeitgeber/invoices";
import InvoicesReducerPK, { InvoicesStatePK } from "./pensionskasse/invoices";
import UploadReducer, { UploadState } from "./arbeitgeber/upload";
import UnpaidvacationsReducer, {
  UnpaidvacationsState,
} from "./arbeitgeber/unpaidvacations";
import AddressesReducer, { AddressesState } from "./arbeitgeber/addresses";
import PayrollsReducer, { PayrollsState } from "./arbeitgeber/payrolls";
import PayrollsReducerPK, { PayrollsStatePK } from "./pensionskasse/payrolls";
import AddressesReducerPK, {
  AddressesStatePK,
} from "./pensionskasse/addresses";
import UnpaidvacationsReducerPK, {
  UnpaidvacationsStatePK,
} from "./pensionskasse/unpaidvacations";
import GlobalSettings, { GlobalSettingsState } from "./settings";

// type insurantsState = InsurantsState & InsurantsStatePK;
// type invoicesState = InvoicesState & InvoicesStatePK;

export interface RootState {
  app: AppState;

  insurants: InsurantsState;
  mutations: MutationsState;
  invoices: InvoicesState;
  upload: UploadState;
  unpaidvacations: UnpaidvacationsState;
  addresses: AddressesState;
  payrolls: PayrollsState;

  intl: any;
  locales: LocalesState;

  insurantsPK: InsurantsStatePK;
  invoicesPK: InvoicesStatePK;
  companies: CompaniesStatePK;
  forReviewMutations: ForReviewMutationsStatePK;
  forReviewMutationsCockpit: ForReviewMutationsStatePKCockpit;
  acceptedMutations: AcceptedMutationsStatePK;
  acceptedMutationsCockpit: AcceptedMutationsStatePKCockpit;
  deletedMutations: DeletedMutationsStatePK;

  unpaidvacationsPK: UnpaidvacationsStatePK;
  addressesPK: AddressesStatePK;
  payrollsPK: PayrollsStatePK;

  globalSettings: GlobalSettingsState;
}

export default (history) => {
  return combineReducers({
    app: AppReducer,

    insurants: InsurantsReducer,
    mutations: MutationsReducer,
    invoices: InvoicesReducer,

    upload: UploadReducer,
    unpaidvacations: UnpaidvacationsReducer,
    addresses: AddressesReducer,
    payrolls: PayrollsReducer,

    insurantsPK: InsurantsReducerPK,
    invoicesPK: InvoicesReducerPK,

    unpaidvacationsPK: UnpaidvacationsReducerPK,
    addressesPK: AddressesReducerPK,
    payrollsPK: PayrollsReducerPK,

    companies: CompaniesReducer,

    forReviewMutations: ForReviewMutationsReducer,
    forReviewMutationsCockpit: ForReviewMutationsCockpitReducer,
    acceptedMutations: AcceptedMutationsReducer,
    acceptedMutationsCockpit: AcceptedMutationsCockpitReducer,
    deletedMutations: DeletedMutationsReducer,

    intl: intlReducer,
    locales: localesReducer,

    router: connectRouter(history),
    toastr: toastrReducer,

    globalSettings: GlobalSettings,
  });
};
