import axios from "axios";
import { setLoading } from "../actions";
import { store } from "../index";

const apiUrl = process.env.REACT_APP_FORM_API_URL;

const instance = axios.create({
  baseURL: apiUrl,
});

export const filterOnlyLetters = (value: string): string => {
  return value.replace(/[\d]/g, "");
};

export const fetchFormCustomization = (
  tenantName: string,
  formName: string
) => {
  store.dispatch(setLoading(true));
  return instance
    .get(`form/formconfiguration?tenantCode=${tenantName}&formName=${formName}`)
    .then((response) => response.data)
    .catch(() => {
      return { data: null };
    })
    .finally(() => {
      store.dispatch(setLoading(false));
    });
};
