import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
// import { inputIsRequired, prepareEmployersDropdownOptions } from '../../../utils/react';
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import formName from "../../../constants/forms/formName.json";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import { Controller, useForm } from "react-hook-form";
import { validateInputMessage } from "../../../utils/validationTools";
import { fetchFormCustomization } from "../../../utils/form";
import { EValidationTestType } from "../../../utils/validationConfigs";
import { ReactComponent as BlueSpinnerIcon } from "../../../assets/icons/blue-spinner.svg";
import "./FormExcelImport.scss";

interface Props {
  closeModal: () => void;
  employers: any;
  firmaOptions: any;
  intl: IntlShape;
  loading: boolean;
  submitAction: (event, formObj) => void;
  showValidations: boolean;
  goBack: () => void;
}

function FormExcelImport(props: Props) {
  // const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      upload: {
        // employerId: "",
        fileName: "",
        file: null,
      },
    },
  });

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //     setIsDisabledButton(!Object.keys(formConfig.requiredFields).every(key => formConfig.requiredFields[key]))
  // }, [formConfig]);

  // const firmaOptions = prepareEmployersDropdownOptions(props.employers)

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.formExcelImport"]
    );
    if (response.data) {
      let formComponentsCustomization = {};
      response["data"].attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );
      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("upload")[key])
      );

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: response["data"].attributes?.translations,
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            // employerId: {
            //     required: false,
            // },
            file: {
              required: true,
            },
          },
          messages: {},
        },
        requiredFields: {
          // employerId: !!getValues("upload.employerId"),
          file: !!getValues("upload.file"),
        },
      });
    }
  };

  return (
    <>
      {props.loading && !!getValues("upload.fileName") ? (
        <div className="loading-container">
          <BlueSpinnerIcon className="spinner-animation" />
          <FormattedMessage
            id="app.excel.message.import"
            defaultMessage="Ihre Mutationen werden erstellt. Dies kann einige Sekunden dauern."
          />
        </div>
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            console.log("onsubmit", e);
            e.preventDefault();
          }}
        >
          <fieldset disabled={false}>
            <div className="row">
              <div className="col-12 col-sm-12">
                <Controller
                  control={control}
                  name={"upload.file"}
                  render={({ field: { value } }) => (
                    <Input
                      isFocused
                      type="file"
                      maxLength={10}
                      inputWrapperClass="file-input"
                      hidden={
                        formConfig.formCustomization.components["file"]?.hidden
                      }
                      id="fileInput"
                      name="file"
                      placeholder=""
                    //   value={getValues("upload.fileName")}
                      onChange={(name, value, file) => {
                        setValue("upload.file", file);
                        setValue("upload.fileName", value);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("upload"),
                            "file",
                            () => [EValidationTestType.isValidExcelFile],
                            formConfig.requiredFields
                          );
                        setError("upload.file", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("upload"),
                            "file",
                            () => [EValidationTestType.isValidExcelFile],
                            formConfig.requiredFields
                          );
                        setError("upload.file", validation);

                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.upload?.file?.message,
                          type: errors?.upload?.file
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.excel.label.uploadFile"
                        defaultMessage="Excel-Datei"
                      />
                      {formConfig.formCustomization.components["file"]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6"></div>
              <div className="col-12 col-md-6">
                <div className="modal-buttons">
                  <Button
                    onClick={() => props.goBack()}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage id="app.back" defaultMessage="zurück" />
                  </Button>
                  <Button
                    submit={true}
                    // isLoading={props.loading}
                    isDisabled={getValues("upload.file") === null}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    onClick={(e) => {
                      console.log("send", getValues("upload"));
                      props.submitAction(e, getValues("upload"));
                    }}
                  >
                    <FormattedMessage
                      id="app.excel.importExcel.button"
                      defaultMessage="Mutationen erstellen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    loading: state.app.loading,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FormExcelImport));
