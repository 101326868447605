import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers/index';
import { Dispatch } from 'redux';
import * as actions from '../../../actions';
import { Button, ButtonColors, ButtonStyles, ButtonTypes, Input, ValidationMessageType } from "icr-shared-components";
import './FormFile.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ValidationFormProps, ValidationMessage } from '../../../@types/forms/agp/formValidation';
import { Controller, useForm } from 'react-hook-form';
import formName from "../../../constants/forms/formName.json";
import { fetchFormCustomization } from '../../../utils/form';
import { validateInputMessage } from '../../../utils/validationTools';

interface Props {
    checkFormValidity: any;
    closeModal: any;
    documentTypeOptions: any;
    employers: any;
    firmaOptions: any;
    loading: any;
    intl: any;
    submitAction: any;
    showValidations: boolean;
}

function FormFile(props: Props) {

    const [isDisabledButton, setIsDisabledButton] = useState(true);
    const [formConfig, setFormConfig] = useState<ValidationFormProps>({
        formCustomization: {
            components: {},
            messages: {}
        },
        requiredFields: {}
    });

    const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

    const {
        setValue,
        getValues,
        setError,
        control,
        formState: { errors },
    } = useForm({
        criteriaMode: "all",
        defaultValues: {
            upload: {
                employerId: "",
                documentType: "",
                title: "",
                description: "",
                fileName: "",
                file: null
            }
        },
    });

    useEffect(() => {
        getFetchFormCustomization();
    }, [])

    useEffect(() => {
        setIsDisabledButton(!Object.keys(formConfig.requiredFields).every(key => formConfig.requiredFields[key]))
    }, [formConfig]);

    const getFetchFormCustomization = async () => {
        const response = await fetchFormCustomization(globalSettings.attributes.tenantCode, formName["formName.formFile"])
        if (response.data) {
            let formComponentsCustomization = {}
            response["data"].attributes?.components.map(item => formComponentsCustomization[item.id] = item.componentAttributes)
            let requiredFields = {}
            Object.keys(formComponentsCustomization).map(key => formComponentsCustomization[key].required && (requiredFields[key] = !!getValues("upload")[key]));


            setFormConfig({
                ...formConfig,
                formCustomization: {
                    components: formComponentsCustomization,
                    messages: response["data"].attributes?.translations
                },
                requiredFields
            })
        } else {
            setFormConfig({
                ...formConfig,
                formCustomization: {
                    components: {
                        employerId: {
                            required: true,
                        },
                        documentType: {
                            required: true,
                        },
                        title: {
                            required: true,
                        },
                        file: {
                            required: true,
                        },
                        description: {
                            required: false,
                        },
                    },
                    messages: {}
                },
                requiredFields: {
                    employerId: !!getValues("upload.employerId"),
                    documentType: !!getValues("upload.documentType"),
                    title: !!getValues("upload.title"),
                    file: !!getValues("upload.file"),
                }
            })
        }
    }

    return (
        <form className="form"
            onSubmit={(e) => {
                console.log("onsubmit", e);
                e.preventDefault();
            }}>
            <fieldset disabled={false}>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Controller
                            control={control}
                            name={"upload.file"}
                            render={({ field: { value } }) => (
                                <Input
                                    isFocused
                                    type="file"
                                    fileInputLabel="Datei auswählen"
                                    hidden={formConfig.formCustomization.components["file"]?.hidden}
                                    maxLength={10}
                                    inputWrapperClass="file-input"
                                    id="fileInput"
                                    name="file"
                                    placeholder="Keine Datei ausgewählt"
                                    value={getValues("upload.fileName")}
                                    onChange={(name, value, file) => {
                                        setValue("upload.file", file);
                                        setValue("upload.fileName", value);
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "file",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.file", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    onBlur={() => {
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "file",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.file", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    validationMessageConfig={{
                                        visible: props.showValidations,
                                        config: {
                                            message: errors?.upload?.file?.message,
                                            type: errors?.upload?.file?.type as ValidationMessageType
                                        }
                                    }}
                                >
                                    <FormattedMessage id="app.uploadfile" defaultMessage="Datei hochladen" />
                                    {formConfig.formCustomization.components["file"]?.required ? ' *' : ''}
                                </Input>)} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <Controller
                            control={control}
                            name={"upload.documentType"}
                            render={({ field: { value } }) => (
                                <Input
                                    type="text"
                                    label="label"
                                    inputWrapperClass="text-input"
                                    hidden={formConfig.formCustomization.components["documentType"]?.hidden}
                                    id="documentTypeInput"
                                    name="documentType"
                                    placeholder="Dokumenten-Typ wählen"
                                    value={value}
                                    selectOptions={props.documentTypeOptions}
                                    onSelected={(name, value) => {
                                        setValue("upload.documentType", value); //trocar pra input value
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "documentType",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.documentType", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    onBlur={() => {
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "documentType",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.documentType", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    validationMessageConfig={{
                                        visible: props.showValidations,
                                        config: {
                                            message: errors?.upload?.documentType?.message,
                                            type: errors?.upload?.documentType?.type as ValidationMessageType
                                        }
                                    }}
                                    sort>
                                    <FormattedMessage id="app.documenttype" defaultMessage="Dokumenten-Typ" />
                                    {formConfig.formCustomization.components["documentType"]?.required ? ' *' : ''}
                                </Input>)} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <Controller
                            control={control}
                            name={"upload.title"}
                            render={({ field: { value } }) => (
                                <Input
                                    type="text"
                                    label="label"
                                    inputWrapperClass="text-input"
                                    hidden={formConfig.formCustomization.components["title"]?.hidden}
                                    id="documentTitleInput"
                                    name="documentTitle"
                                    placeholder="Erstellen Sie einen Datei-Titel"
                                    value={value}
                                    onChange={(name, value) => {
                                        setValue("upload.title", value);

                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "title",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.title", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    onBlur={() => {
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "title",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.title", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    validationMessageConfig={{
                                        visible: props.showValidations,
                                        config: {
                                            message: errors?.upload?.title?.message,
                                            type: errors?.upload?.title?.type as ValidationMessageType
                                        }
                                    }}>
                                    <FormattedMessage id="app.filetitle" defaultMessage="Datei-Titel" />
                                    {formConfig.formCustomization.components["title"]?.required ? ' *' : ''}
                                </Input>)} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <Controller
                            control={control}
                            name={"upload.employerId"}
                            render={({ field: { value } }) => (
                                <Input
                                    type="text"
                                    label="label"
                                    inputWrapperClass="text-input"
                                    hidden={formConfig.formCustomization.components["employerId"]?.hidden}
                                    id="employerInput"
                                    name="employerId"
                                    placeholder="Firma wählen"
                                    value={props.employers && props.employers[value] ? props.employers[value]["attributes"]["name"] : value}
                                    selectOptions={props.firmaOptions}
                                    onSelected={(name, value) => {
                                        setValue("upload.employerId", value);

                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "employerId",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.employerId", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    onBlur={() => {
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "employerId",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.employerId", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    validationMessageConfig={{
                                        visible: props.showValidations,
                                        config: {
                                            message: errors?.upload?.employerId?.message,
                                            type: errors?.upload?.employerId?.type as ValidationMessageType
                                        }
                                    }}
                                    sort>
                                    <FormattedMessage id="app.company" defaultMessage="Firma" />
                                    {formConfig.formCustomization.components["employerId"]?.required ? ' *' : ''}
                                </Input>)} />
                    </div>
                    <div className="col-12">
                        <Controller
                            control={control}
                            name={"upload.description"}
                            render={({ field: { value } }) => (
                                <Input
                                    type="textarea"
                                    label="label"
                                    inputWrapperClass="textarea-input"
                                    hidden={formConfig.formCustomization.components["description"]?.hidden}
                                    id="descriptionInput"
                                    name="description"
                                    placeholder=""
                                    value={value}
                                    onChange={(name, value) => {
                                        setValue("upload.description", value);

                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "description",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.description", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    onBlur={() => {
                                        const validation: ValidationMessage = validateInputMessage(
                                            formConfig.formCustomization,
                                            getValues("upload"),
                                            "description",
                                            null,
                                            formConfig.requiredFields
                                        )
                                        setError("upload.description", validation);

                                        validation?.valid && setFormConfig({ ...formConfig, requiredFields: validation?.valid })
                                    }}
                                    validationMessageConfig={{
                                        visible: props.showValidations,
                                        config: {
                                            message: errors?.upload?.description?.message,
                                            type: errors?.upload?.description?.type as ValidationMessageType
                                        }
                                    }}>
                                    <FormattedMessage id="app.comment" defaultMessage="Bemerkung" />
                                    {formConfig.formCustomization.components["description"]?.required ? ' *' : ''}
                                </Input>)} />
                    </div>
                    <div className="col-12 col-sm-6">
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="modal-buttons">
                            <Button
                                onClick={props.closeModal}
                                type={ButtonTypes.Text}
                                color={ButtonColors.Action}>
                                <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
                            </Button>
                            <Button
                                submit={true}
                                isLoading={props.loading}
                                isDisabled={props.loading || isDisabledButton}
                                type={ButtonTypes.Standard}
                                style={ButtonStyles.Primary}
                                color={ButtonColors.Action}
                                onClick={(e) => props.submitAction(e, getValues("upload"))}>
                                <FormattedMessage id="app.sendnow" defaultMessage="JETZT SENDEN" />
                            </Button>
                        </div>

                    </div>
                </div>
            </fieldset>
        </form>

    );
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        showValidations: state.app.showValidations
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FormFile));
