import apiCaller from "../../utils/api";
import {ACTION, wrap} from "../index";
import * as constants from '../../constants/pensionskasse';
import {insurantsQueryParamsPK} from "../../utils/toSearchParams";
import api from "../../utils/api";

const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || '20';
const backendPagination = process.env.REACT_APP_BACKEND_PAGINATION;

export const setInsurantsPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_INSURANTS_PAGE_NO,
    payload
});

export const setInsurantsFilter = (payload: boolean): ACTION => ({
    type: constants.SET_INSURANTS_FILTERING,
    payload
});

export const fetchEmployees = (length, page) => {
    const params = insurantsQueryParamsPK(length, page);
    return async (dispatch) => {
        return api.get(`insurants${params}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_INSURANTS, "payload": response['data'], "page": page });
            }).catch(err => err);
    }
};

export const createInsurant = (payload) => {
    return async (dispatch) => {
        return api.post(`insurants/`, payload)
            .then(response => response)
            .catch(err => err);
    }
};

export const editInsurant = (payload, insurantId) => {
    return async (dispatch) => {
        return api.patch(`insurants/${insurantId}/`, payload)
            .then(response => response)
            .catch(err => err);
    }
};
