import React from "react";
import { ReactComponent as IconContact } from "../../../../assets/icons/personalien.svg";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Status,
  StatusColors,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import styles from "./PersonenExpandableRowTop.module.scss";
import { parseDate } from "../../../../utils/react";
import { ReactComponent as MaleIcon } from "../../../../assets/icons/male.svg";
import { ReactComponent as FemaleIcon } from "../../../../assets/icons/female.svg";
import Tooltip from "rc-tooltip";

interface Props {
  intl: any;
  entities: any;
  employers: any;
  insurant: any;
  onEditClick: any;
  onDetailsClick: any;
}

const PersonenExpandableRowTop = ({
  insurant,
  entities,
  employers,
  onDetailsClick,
}: Props) => {
  const isActive = insurant?.attributes?.employee.isActive;
  const sex =
    insurant.attributes.person.sex === 1 ? (
      <MaleIcon className="ml-1" />
    ) : insurant.attributes.person.sex === 2 ? (
      <FemaleIcon className="ml-1" />
    ) : null;
  const employerName =
    employers?.[insurant.attributes.employee.employerId]?.attributes.name;
  return (
    <div className={styles.personenExpandableRowTop}>
      <div className={styles.column}>
        <span className={styles.mobileHeader}>Name / Geburtsdatum</span>
        <p>
          <b>
            {insurant.attributes.person.name +
              " " +
              insurant.attributes.person.firstname}{" "}
            {sex}
          </b>
        </p>
        <p className="text-small">
          <b>{parseDate(insurant.attributes.person.birthDate)}</b>
        </p>
      </div>
      <div className={styles.column}>
        <span className={styles.mobileHeader}>
          <FormattedMessage
            id="app.personen.list.card.top.socialSecurityNumber"
            defaultMessage="AHV-Nr. / Vers.-Nr."
          />
        </span>
        <p>{insurant.attributes.person.socialSecurityNumber}</p>
        <p>{insurant.attributes.employee.insEmployeeId}</p>
      </div>
      <div className={styles.column}>
        <span className={styles.mobileHeader}>Arbeitgeber</span>
        <p>{employerName}</p>
      </div>
      <div className={styles.column}>
        <span className={styles.mobileHeader}>Status</span>
        <Status
          text={!isActive ? "inaktiv" : "aktiv"}
          color={!isActive ? StatusColors.DISABLED : StatusColors.HIGHLIGHT}
        />
      </div>
      <div className={styles.column}>
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onDetailsClick(insurant.id, "")}
        >
          <Tooltip
            trigger={["hover"]}
            placement="top"
            overlay={"Versichertendossier"}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
          >
            <IconContact />
          </Tooltip>
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(PersonenExpandableRowTop));
