import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../actions";
import { fetchEmployers, setLoading, setPopup } from "../../../actions";
import Header from "../../../components/Header";
import { injectIntl } from "react-intl";
import "./Mutations.scss";
import Modal from "react-modal";
import { Tab } from "@material/react-tab";
import Drawer from "../../../components/Drawer";
import classNames from "classnames";
import { MUTATIONS_TABS } from "../../../constants/pensionskasse";
import ForReviewSidebar from "../../../components/Pensionskasse/Mutations/ForReviewSidebar";
import AcceptedSidebar from "../../../components/Pensionskasse/Mutations/AcceptedSidebar";
import DeletedSidebar from "../../../components/Pensionskasse/Mutations/DeletedSidebar";
import ForReviewTab from "../../../components/Pensionskasse/Mutations/ForReviewTab";
import AcceptedTab from "../../../components/Pensionskasse/Mutations/AcceptedTab";
import DeletedTab from "../../../components/Pensionskasse/Mutations/DeletedTab";
import mutations from "../../../constants/DummyData/mutations.json";
import { groupMutationsByEmployer } from "../../../utils/react";
import {
  fetchAcceptedMutations,
  fetchDeletedMutations,
  fetchForReviewMutations,
  fetchForReviewMutationsProgress,
  processMutations,
  setAcceptedMutationsFetched,
  setAcceptedMutationsFilter,
  setAcceptedMutationsPageNo,
  setDeletedMutationsFetched,
  setDeletedMutationsFilter,
  setDeletedMutationsPageNo,
  setForReviewMutationsFetched,
  setForReviewMutationsFilter,
  setForReviewMutationsPageNo,
} from "../../../actions/pensionskasse/mutations";
import {
  fetchEmployees,
  setInsurantsPageNo,
} from "../../../actions/pensionskasse/employee";
import Spinner from "../../../components/Spinner";
import memoize from "memoize-one";
import { StickyButton } from "../../../components/StickyButton";
import WarningsPopup from "../../../components/Pensionskasse/Mutations/WarningsPopup";
import { MUTATIONS_MODAL_PAGES } from "../../../constants";
import PdfModal from "../../../components/Arbeitgeber/Cockpit/PdfModal";
import TakeOverPopup from "../../../components/Pensionskasse/Mutations/TakeOverPopup";
import { Alert } from "../../../components/Alert";

interface State {
  modalIsOpen: boolean;
  modalFadeOut: boolean;
  currentTab: string;
  expandedRow: any;
  filtersOpened: boolean;
  modalContentId: string;
  filtersChanged: number;
  modalMutations: any;
  modalContent: any;
  loading: boolean;
  commitLoading: boolean;
  pdfLink: string;
  filterPopup: boolean;
  newForReviewMutationsAlert: boolean;
}

interface Props {
  intl: any;
  match: any;
  history: any;
  insurants: any;
  employers: any;
  entities: any;
  setPopup: any;
  setLoading: any;
  loading: boolean;
  popupIsOpen: boolean;
  mutationTypes: any;
  processMutations: any;
  forReviewMutations: any;
  acceptedMutations: any;
  deletedMutations: any;
  fetchEmployers: any;
  fetchEmployees: any;
  setInsurantsPageNo: any;
  fetchForReviewMutations: any;
  fetchAcceptedMutations: any;
  fetchDeletedMutations: any;
  fetchForReviewMutationsProgress: any;
  forReviewMutationsProgress: any;
  canSubmitAll: boolean;
  forReviewMutationsFetched: boolean;
  acceptedMutationsFetched: boolean;
  deletedMutationsFetched: boolean;
  setForReviewMutationsPageNo: any;
  setAcceptedMutationsPageNo: any;
  setDeletedMutationsPageNo: any;
  forReviewMutationsPageNo: any;
  acceptedMutationsPageNo: any;
  deletedMutationsPageNo: any;
  setForReviewMutationsFilter: any;
  setAcceptedMutationsFilter: any;
  setDeletedMutationsFilter: any;
  moreForReviewMutations: boolean;
  moreAcceptedMutations: boolean;
  moreDeletedMutations: boolean;
  forReviewMutationsTotal: number;
  forReviewMutationsWarnings: number;
  forReviewMutationsErrors: number;
  acceptedMutationsTotal: number;
  acceptedMutationsWarnings: number;
  acceptedMutationsErrors: number;
  deletedMutationsTotal: boolean;
  deletedMutationsWarnings: boolean;
  deletedMutationsErrors: boolean;
  setForReviewMutationsFetched: any;
  setDeletedMutationsFetched: any;
  setAcceptedMutationsFetched: any;
  fetchedEntities: boolean;
  forReviewMutationsCountFetched: boolean;
  acceptedMutationsCountFetched: boolean;
  deletedMutationsCountFetched: boolean;

  forReviewMutationsNameFilter: string;
  forReviewMutationsEventFilter: string;
  forReviewMutationsFirmaFilter: string;
  forReviewMutationsOnlyErrorsFilter: boolean;
  forReviewMutationsOnlyWarningsFilter: boolean;
  forReviewMutationsNoWarningsErrorsFilter: boolean;
}

const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || "20";

class Mutations extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      filtersChanged: 0,
      expandedRow: null,
      modalIsOpen: false,
      modalFadeOut: false,
      filtersOpened: window.innerWidth >= 1400,
      modalContentId: "",
      modalMutations: [],
      modalContent: {},
      currentTab: MUTATIONS_TABS.ForReviewTab,
      loading: false,
      commitLoading: false,
      pdfLink: "",
      filterPopup: false,
      newForReviewMutationsAlert: false,
    };
  }

  private drawer = null;
  private interval = null;

  componentDidMount() {
    this.fetchData();
    const { match } = this.props;
    const tab = match.params.tab;
    this.setState({ currentTab: tab });

    window.addEventListener("resize", this.viewPortChanged);

    const interval = isNaN(parseInt("10000")) ? 10000 : parseInt("10000");
    this.interval = setInterval(() => {
      this.props.fetchForReviewMutationsProgress().then((res) => {
        if (
          this.props.forReviewMutationsProgress.length > 0 &&
          !this.state.newForReviewMutationsAlert
        ) {
          this.setState({ newForReviewMutationsAlert: true });
        }
      });
    }, interval);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.viewPortChanged);
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const tab = this.props.match.params.tab;
    const previousTab = prevProps.match.params.tab;

    if (tab != previousTab) {
      this.setState({ currentTab: tab });
    }
  }

  fetchData = () => {
    if (!this.props.forReviewMutationsFetched) {
      this.props
        .fetchForReviewMutations(
          this.props.forReviewMutationsPageNo === 0
            ? parseInt(paginationSize)
            : this.props.forReviewMutationsPageNo * parseInt(paginationSize),
          0
        )
        .then((res) => this.props.setForReviewMutationsPageNo(1));
    }
    if (!this.props.acceptedMutationsFetched) {
      this.props
        .fetchAcceptedMutations(
          this.props.acceptedMutationsPageNo === 0
            ? parseInt(paginationSize)
            : this.props.acceptedMutationsPageNo * parseInt(paginationSize),
          0
        )
        .then((res) => this.props.setAcceptedMutationsPageNo(1));
    }
    if (!this.props.deletedMutationsFetched) {
      this.props
        .fetchDeletedMutations(
          this.props.deletedMutationsPageNo === 0
            ? parseInt(paginationSize)
            : this.props.deletedMutationsPageNo * parseInt(paginationSize),
          0
        )
        .then((res) => this.props.setDeletedMutationsPageNo(1));
    }
  };

  viewPortChanged = (e) => {
    setTimeout(() => {
      if (e.target.innerWidth >= 1400 && this.state.filtersOpened === false) {
        this.setState({ filtersOpened: true });
      }
    }, 500);
  };

  loadMore = () => {
    const { currentTab, loading } = this.state;
    const {
      moreForReviewMutations,
      moreAcceptedMutations,
      moreDeletedMutations,
    } = this.props;
    if (currentTab === MUTATIONS_TABS.ForReviewTab && moreForReviewMutations) {
      this.setState({ loading: true });
      this.props.fetchForReviewMutations().then((res) => {
        this.setState({ loading: false });
        this.props.setForReviewMutationsPageNo(
          this.props.forReviewMutationsPageNo + 1
        );
      });
    }
    if (currentTab === MUTATIONS_TABS.AcceptedTab && moreAcceptedMutations) {
      this.setState({ loading: true });
      this.props.fetchAcceptedMutations().then((res) => {
        this.setState({ loading: false });
        this.props.setAcceptedMutationsPageNo(
          this.props.acceptedMutationsPageNo + 1
        );
      });
    }
    if (currentTab === MUTATIONS_TABS.DeletedTab && moreDeletedMutations) {
      this.setState({ loading: true });
      this.props.fetchDeletedMutations().then((res) => {
        this.setState({ loading: false });
        this.props.setDeletedMutationsPageNo(
          this.props.deletedMutationsPageNo + 1
        );
      });
    }
  };

  handleScroll = (e) => {
    const { currentTab, loading } = this.state;
    const element = e.target;
    const bottom =
      Math.floor(element.scrollHeight - element.scrollTop) <=
        element.clientHeight + 200 &&
      Math.floor(element.scrollHeight - element.scrollTop) >=
        element.clientHeight;
    if (bottom && !loading) {
      console.log("SCROLLED TO BOTTOM");
      this.loadMore();
    }
  };

  activateTab = (tab) => {
    this.props.history.push(`/pensionskasse/mutationen/${tab}`);
  };

  openModal = (id) => {
    console.log("openModal", id);

    const updateUrl = () => {
      this.setState({ modalIsOpen: true, modalContentId: id }, () => {
        // this.props.history.push({
        //     pathname: `/pensionskasse/mutationen/${this.state.currentTab}`,
        //     search: "?" + new URLSearchParams({content: id}).toString()
        // })
      });
    };

    updateUrl();
  };

  openPdfModal = (type, link) => {
    this.setState({
      modalIsOpen: true,
      modalContent: MUTATIONS_MODAL_PAGES.PDF,
      pdfLink: link,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      modalContent: "",
      // pdfLink: link
    });
  };

  handleRowChanged = (index) => {
    this.setState({ expandedRow: index });
  };

  handleOpenMutation = (mutation) => {
    this.setState({
      modalIsOpen: true,
      modalContent: mutation,
      modalMutations: mutations.data,
    });
  };

  groupedMutations = (mutations, employers) => {
    if (!mutations || !employers) return [];
    if (!Object.keys(employers).length) return [];
    const grouped = groupMutationsByEmployer(mutations, employers);
    if (!grouped) {
      return [];
    }
    return grouped;
  };

  handleSetLoading = (value) => {
    this.setState({
      loading: value,
      filtersChanged: this.state.filtersChanged + 1,
    });
  };

  processMutations = (excludeWarnings) => {
    this.props.setLoading(true);
    if (this.props.popupIsOpen) {
      this.setState({ commitLoading: true });
    } else {
      this.setState({ loading: true });
    }
    this.props.processMutations(excludeWarnings).then((res) => {
      this.refreshData();
      this.setState({ loading: false, commitLoading: false });
      this.props.setLoading(false);
      this.props.setPopup(false);
    });
  };

  prepareFirmaOptions = memoize((employers) => {
    let options = [];
    if (employers && Object.keys(employers).length) {
      Object.keys(employers).forEach((employerKey) => {
        options.push({
          dropdownLabel: employers[employerKey]["attributes"]["name"],
          id: employers[employerKey].id,
        });
      });
    }
    return options;
  });

  prepareMutationTypeOptions = memoize((mutationTypes) => {
    const options = [];
    if (mutationTypes && Object.keys(mutationTypes).length) {
      Object.keys(mutationTypes).forEach((mutationTypeKey) => {
        options.push({
          dropdownLabel:
            mutationTypes[mutationTypeKey]["attributes"]["translation"],
          id: mutationTypes[mutationTypeKey].id,
        });
      });
    }
    return options;
  });

  refreshData = () => {
    this.props.setForReviewMutationsFetched(false);
    this.props.setAcceptedMutationsFetched(false);
    this.props.setDeletedMutationsFetched(false);
    Promise.all([
      this.filtersAreApplied()
        ? this.props.fetchForReviewMutations(
            this.props.forReviewMutationsPageNo * parseInt(paginationSize),
            0
          )
        : this.props.fetchForReviewMutations(parseInt(paginationSize), 0),
      this.props.fetchAcceptedMutations(parseInt(paginationSize), 0),
      this.props.fetchDeletedMutations(parseInt(paginationSize), 0),
      this.props.fetchEmployees(parseInt(paginationSize), 0),
    ]).then(() => {
      this.filtersAreApplied()
        ? this.props.setForReviewMutationsPageNo(
            this.props.forReviewMutationsPageNo * parseInt(paginationSize)
          )
        : this.props.setForReviewMutationsPageNo(1);
      this.props.setAcceptedMutationsPageNo(1);
      this.props.setDeletedMutationsPageNo(1);
      this.props.setInsurantsPageNo(1);
    });
    this.props.fetchEmployers();
  };

  filtersAreApplied = () => {
    return !!(
      this.props.forReviewMutationsNameFilter ||
      this.props.forReviewMutationsEventFilter ||
      this.props.forReviewMutationsFirmaFilter ||
      this.props.forReviewMutationsOnlyErrorsFilter ||
      this.props.forReviewMutationsOnlyWarningsFilter ||
      this.props.forReviewMutationsNoWarningsErrorsFilter
    );
  };

  checkIfFilterIsEnable = () => {
    this.setState({ filterPopup: true });
  };

  render() {
    const firmaOptions = this.prepareFirmaOptions(this.props.employers);
    const mutationTypeOptions = this.prepareMutationTypeOptions(
      this.props.mutationTypes
    );
    const { loading, filtersChanged, modalContent, commitLoading, currentTab } =
      this.state;
    const {
      forReviewMutationsFetched,
      acceptedMutationsFetched,
      deletedMutationsFetched,
      fetchedEntities,
    } = this.props;
    const isForReviewTab = currentTab === MUTATIONS_TABS.ForReviewTab;
    const isAcceptedTab = currentTab === MUTATIONS_TABS.AcceptedTab;
    const isDeletedTab = currentTab === MUTATIONS_TABS.DeletedTab;
    const groupedForReviewMutations = this.groupedMutations(
      this.props.forReviewMutations,
      this.props.employers
    );
    const groupedAcceptedMutations = this.groupedMutations(
      this.props.acceptedMutations,
      this.props.employers
    );
    const groupedDeletedMutations = this.groupedMutations(
      this.props.deletedMutations,
      this.props.employers
    );
    const isLoading =
      (!forReviewMutationsFetched && isForReviewTab) ||
      (!acceptedMutationsFetched && isAcceptedTab) ||
      (!deletedMutationsFetched && isDeletedTab) ||
      loading ||
      !fetchedEntities;

    const showStickyActionButton =
      isForReviewTab && this.props.forReviewMutationsTotal;
    const isPdfPage =
      modalContent === MUTATIONS_MODAL_PAGES.PDF && this.state.pdfLink;

    return (
      <div className="main-container pensionskasse">
        {commitLoading && <Spinner relative />}

        <Header
          hasMoreContent
          title="Mutationen"
          locales={null}
          mutationsPageHeader
          onFiltersButtonClick={() =>
            this.setState({ filtersOpened: !this.state.filtersOpened })
          }
        >
          <Tab
            active={this.state.currentTab === MUTATIONS_TABS.ForReviewTab}
            minWidth
            className="mr-5"
            onClick={() => this.activateTab(MUTATIONS_TABS.ForReviewTab)}
            tabIndex={0}
          >
            <span className="mdc-tab__text-label">
              Überprüfung erforderlich
              <span className="count">
                {this.props.forReviewMutationsTotal}
              </span>
            </span>
          </Tab>
          <Tab
            active={this.state.currentTab === MUTATIONS_TABS.AcceptedTab}
            minWidth
            className="mr-5"
            onClick={() => this.activateTab(MUTATIONS_TABS.AcceptedTab)}
            tabIndex={0}
          >
            <span className="mdc-tab__text-label">
              Übernommen
              <span className="count">{this.props.acceptedMutationsTotal}</span>
            </span>
          </Tab>
          <Tab
            active={this.state.currentTab === MUTATIONS_TABS.DeletedTab}
            minWidth
            onClick={() => this.activateTab(MUTATIONS_TABS.DeletedTab)}
            tabIndex={0}
          >
            <span className="mdc-tab__text-label">
              Gelöscht
              <span className="count">{this.props.deletedMutationsTotal}</span>
            </span>
          </Tab>
        </Header>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Large Modal"
          className="large-modal"
          overlayClassName="large-modal-overlay"
        >
          {isPdfPage && (
            <PdfModal
              provider={null}
              pdfFilter={null}
              pdfFileId={null}
              pdfLink={this.state.pdfLink}
              onClose={this.closeModal}
            />
          )}
        </Modal>

        <Modal
          isOpen={this.props.popupIsOpen}
          contentLabel="Small Modal"
          className={classNames("small-modal warnings-popup")}
          overlayClassName={"small-modal-overlay"}
        >
          <WarningsPopup
            onSubmitAll={() => this.processMutations(false)}
            onSubmitWithoutWarnings={() => this.processMutations(true)}
            onCancel={() => this.props.setPopup(false)}
          />
        </Modal>

        <Modal
          isOpen={this.state.filterPopup}
          contentLabel="Small Modal"
          className={classNames("small-modal")}
          overlayClassName={"small-modal-overlay"}
        >
          <TakeOverPopup
            onSubmit={() => {
              this.setState({ filterPopup: false });
              this.processMutations(false);
            }}
            onCancel={() => this.setState({ filterPopup: false })}
          />
        </Modal>

        <div className="main-content-container pensionskasse-mutations">
          <div className="relative-container">
            {isLoading && (
              <Spinner
                lowZIndex={
                  (!forReviewMutationsFetched ||
                    !acceptedMutationsFetched ||
                    !deletedMutationsFetched) &&
                  fetchedEntities
                }
                relative
              />
            )}
            <Alert
              isOpen={this.state.newForReviewMutationsAlert}
              label="Neue Mutationen eingetroffen, laden Sie die Seite neu"
              onButtonClick={() => {
                this.refreshData();
                this.setState({ newForReviewMutationsAlert: false });
              }}
              textButton="LADEN"
              setOpen={(value) => {
                this.setState({ newForReviewMutationsAlert: value });
              }}
            />

            <div
              className="main-content-container-left pensionskasse"
              onScroll={(e) => this.handleScroll(e)}
            >
              {this.state.currentTab == MUTATIONS_TABS.ForReviewTab && (
                <ForReviewTab
                  morePadding={showStickyActionButton}
                  refreshData={this.refreshData}
                  mutations={groupedForReviewMutations}
                  expandedRow={this.state.expandedRow}
                  filtersChanged={this.state.filtersChanged}
                  fetched={this.props.forReviewMutationsFetched}
                  onPdfClick={(link) => this.openPdfModal("pdf", link)}
                  setLoading={(value) => this.handleSetLoading(value)}
                />
              )}

              {this.state.currentTab == MUTATIONS_TABS.AcceptedTab && (
                <AcceptedTab
                  refreshData={this.refreshData}
                  mutations={groupedAcceptedMutations}
                  expandedRow={this.state.expandedRow}
                  filtersChanged={this.state.filtersChanged}
                  onPdfClick={(link) => this.openPdfModal("pdf", link)}
                  setLoading={(value) => this.handleSetLoading(value)}
                />
              )}

              {this.state.currentTab == MUTATIONS_TABS.DeletedTab && (
                <DeletedTab
                  refreshData={this.refreshData}
                  mutations={groupedDeletedMutations}
                  expandedRow={this.state.expandedRow}
                  filtersChanged={this.state.filtersChanged}
                  onPdfClick={(link) => this.openPdfModal("pdf", link)}
                  setLoading={(value) => this.handleSetLoading(value)}
                />
              )}
            </div>

            {showStickyActionButton && (
              <StickyButton
                canSubmitAll={this.props.canSubmitAll}
                tabs={[
                  { label: "Alle", count: this.props.forReviewMutationsTotal },
                  {
                    label: "OK",
                    count:
                      this.props.forReviewMutationsTotal -
                      this.props.forReviewMutationsWarnings -
                      this.props.forReviewMutationsErrors,
                  },
                  {
                    label: "Warnung",
                    count: this.props.forReviewMutationsWarnings,
                  },
                  {
                    label: "Fehler",
                    count: this.props.forReviewMutationsErrors,
                  },
                ]}
                onButtonClick={() => {
                  if (this.props.forReviewMutationsWarnings) {
                    this.props.setPopup(true);
                  } else {
                    !this.filtersAreApplied()
                      ? this.checkIfFilterIsEnable()
                      : this.processMutations(false);
                  }
                }}
              />
            )}
          </div>

          <div className="drawer" ref={(node) => (this.drawer = node)}>
            <Drawer opened={this.state.filtersOpened}>
              {this.state.currentTab == MUTATIONS_TABS.ForReviewTab && (
                <ForReviewSidebar
                  firmaOptions={firmaOptions}
                  mutationTypeOptions={mutationTypeOptions}
                  setLoading={(value) => this.handleSetLoading(value)}
                />
              )}
              {this.state.currentTab == MUTATIONS_TABS.AcceptedTab && (
                <AcceptedSidebar
                  firmaOptions={firmaOptions}
                  mutationTypeOptions={mutationTypeOptions}
                  setLoading={(value) => this.handleSetLoading(value)}
                />
              )}
              {this.state.currentTab == MUTATIONS_TABS.DeletedTab && (
                <DeletedSidebar
                  firmaOptions={firmaOptions}
                  mutationTypeOptions={mutationTypeOptions}
                  setLoading={(value) => this.handleSetLoading(value)}
                />
              )}
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,

    loading: state.app.loading,
    popupIsOpen: state.app.popupIsOpen,

    employers: state.app.employers,
    entities: state.app.entities,
    mutationTypes: state.app.mutationTypes,

    forReviewMutations: state.forReviewMutations.forReviewMutations,
    acceptedMutations: state.acceptedMutations.acceptedMutations,
    deletedMutations: state.deletedMutations.deletedMutations,

    forReviewMutationsNameFilter:
      state.forReviewMutations.forReviewMutationsNameFilter,
    forReviewMutationsEventFilter:
      state.forReviewMutations.forReviewMutationsEventFilter,
    forReviewMutationsFirmaFilter:
      state.forReviewMutations.forReviewMutationsFirmaFilter,
    forReviewMutationsOnlyErrorsFilter:
      state.forReviewMutations.forReviewMutationsOnlyErrorsFilter,
    forReviewMutationsOnlyWarningsFilter:
      state.forReviewMutations.forReviewMutationsOnlyWarningsFilter,
    forReviewMutationsNoWarningsErrorsFilter:
      state.forReviewMutations.forReviewMutationsNoWarningsErrorsFilter,

    forReviewMutationsProgress:
      state.forReviewMutations.forReviewMutationsProgress,
    forReviewMutationsFetched:
      state.forReviewMutations.forReviewMutationsFetched,
    acceptedMutationsFetched: state.acceptedMutations.acceptedMutationsFetched,
    deletedMutationsFetched: state.deletedMutations.deletedMutationsFetched,

    canSubmitAll: state.forReviewMutations.canSubmitAll,

    forReviewMutationsPageNo: state.forReviewMutations.forReviewMutationsPageNo,
    acceptedMutationsPageNo: state.acceptedMutations.acceptedMutationsPageNo,
    deletedMutationsPageNo: state.deletedMutations.deletedMutationsPageNo,

    moreForReviewMutations: state.forReviewMutations.moreForReviewMutations,
    moreAcceptedMutations: state.acceptedMutations.moreAcceptedMutations,
    moreDeletedMutations: state.deletedMutations.moreDeletedMutations,

    forReviewMutationsTotal: state.forReviewMutations.total,
    forReviewMutationsWarnings: state.forReviewMutations.warnings,
    forReviewMutationsErrors: state.forReviewMutations.errors,

    acceptedMutationsTotal: state.acceptedMutations.total,
    acceptedMutationsWarnings: state.acceptedMutations.warnings,
    acceptedMutationsErrors: state.acceptedMutations.errors,

    deletedMutationsTotal: state.deletedMutations.total,
    deletedMutationsWarnings: state.deletedMutations.warnings,
    deletedMutationsErrors: state.deletedMutations.errors,

    fetchedEntities: state.app.fetchedEntities,

    forReviewMutationsCountFetched:
      state.forReviewMutations.forReviewMutationsCountFetched,
    acceptedMutationsCountFetched:
      state.acceptedMutations.acceptedMutationsCountFetched,
    deletedMutationsCountFetched:
      state.deletedMutations.deletedMutationsCountFetched,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    fetchForReviewMutations: bindActionCreators(
      fetchForReviewMutations,
      dispatch
    ),
    fetchAcceptedMutations: bindActionCreators(
      fetchAcceptedMutations,
      dispatch
    ),
    fetchDeletedMutations: bindActionCreators(fetchDeletedMutations, dispatch),
    processMutations: bindActionCreators(processMutations, dispatch),
    fetchEmployers: bindActionCreators(fetchEmployers, dispatch),
    fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
    fetchForReviewMutationsProgress: bindActionCreators(
      fetchForReviewMutationsProgress,
      dispatch
    ),
    setInsurantsPageNo: (val) => dispatch(setInsurantsPageNo(val)),
    setForReviewMutationsPageNo: (val) =>
      dispatch(setForReviewMutationsPageNo(val)),
    setAcceptedMutationsPageNo: (val) =>
      dispatch(setAcceptedMutationsPageNo(val)),
    setDeletedMutationsPageNo: (val) =>
      dispatch(setDeletedMutationsPageNo(val)),
    setForReviewMutationsFilter: (val) =>
      dispatch(setForReviewMutationsFilter(val)),
    setAcceptedMutationsFilter: (val) =>
      dispatch(setAcceptedMutationsFilter(val)),
    setDeletedMutationsFilter: (val) =>
      dispatch(setDeletedMutationsFilter(val)),
    setForReviewMutationsFetched: (val) =>
      dispatch(setForReviewMutationsFetched(val)),
    setDeletedMutationsFetched: (val) =>
      dispatch(setDeletedMutationsFetched(val)),
    setAcceptedMutationsFetched: (val) =>
      dispatch(setAcceptedMutationsFetched(val)),
    setPopup: (val) => dispatch(setPopup(val)),
    setLoading: (val) => dispatch(setLoading(val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Mutations));
