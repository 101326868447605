import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { withRouter } from "react-router-dom";
import { RootState } from "../../../../reducers/index";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-action.svg";
import { useForm, Controller } from "react-hook-form";
import { validateInputMessage } from "../../../../utils/validationTools";
import { ValidationMessage } from "../../../../@types/forms/agp/formValidation";

import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { parseDate, parseToApiDate } from "../../../../utils/react";

import { EValidationTestType } from "../../../../utils/validationConfigs";
import { Input } from "../../../Input";
import { EValidationMessageType } from "../../../ValidationMessage";
import { filterOnlyLetters } from "../../../../utils/form";
import { EintrittFormValues } from "../../../../reducers/arbeitgeber/types";
import { bindActionCreators } from "redux";
import { updateFieldValues } from "../../../../actions/arbeitgeber/user";

interface Props {
  checkFormValidity: any;
  employers: any;
  entities?: any;
  firmaOptions: any;
  goToNextStep: any;
  goToPreviousStep: any;
  initialData: any;
  isLoading: boolean;
  step: number;
  intl: IntlShape;
  insurantsObj: any;
  backendValidation?: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
  isDirty: boolean;

  checkValidationAgainstValues: any;

  payrollValidFromChanged: boolean;
  isFormDisabled;
  formState: any;
  setFormState: any;
  landOptions: any;
  submitAction: any;

  formConfig: any;
  getValues: any;
  submitForm: any;
  storedValues: EintrittFormValues;
  updateFieldValues: any;

  setValue: any;
}

const Step4 = (props: Props) => {
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const isDirty = props.isDirty;
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      address: {
        address1:
          props?.storedValues?.address?.address1 ||
          props.getValues("eintrittErfassenForm.address.address1") ||
          "",
        address2:
          props?.storedValues?.address?.address2 ||
          props.getValues("eintrittErfassenForm.address.address2") ||
          "",
        zipCode:
          props?.storedValues?.address?.zipCode ||
          props.getValues("eintrittErfassenForm.address.zipCode") ||
          "",
        city:
          props?.storedValues?.address?.city ||
          props.getValues("eintrittErfassenForm.address.city") ||
          "",
        country:
          props?.storedValues?.address?.country ||
          props.getValues("eintrittErfassenForm.address.country") ||
          "",
        addressValidFrom:
          props?.storedValues?.address?.addressValidFrom ||
          props.getValues("eintrittErfassenForm.address.validFrom") ||
          "",
        email:
          props?.storedValues?.address?.email ||
          props.getValues("eintrittErfassenForm.address.email") ||
          "",
        mobileNumber:
          props?.storedValues?.address?.mobileNumber ||
          props.getValues("eintrittErfassenForm.address.mobileNumber") ||
          "",
        phoneNumber:
          props?.storedValues?.address?.phoneNumber ||
          props.getValues("eintrittErfassenForm.address.phoneNumber") ||
          "",
      },
      requiredFields: {},
    },
  });

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(getValues("requiredFields")).every(
        (key) => getValues("requiredFields")[key]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("requiredFields")]);

  const requiredFieldsStep4 = useCallback(() => {
    const requiredForStep4 = {};
    Object.keys(getValues("address")).map(
      (key) =>
        props.formConfig.formCustomization.components[key]?.required &&
        (requiredForStep4[key] = !!getValues("address")[key])
    );
    setValue("requiredFields", requiredForStep4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formConfig.formCustomization, setValue, getValues("address")]);

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      requiredFieldsStep4();
    }
  }, [props.formConfig.formCustomization, requiredFieldsStep4]);

  const validateIfChangedAddress = () => {
    return (
      Object.keys(getValues("address")).some((key) => {
        return (
          props.getValues("eintrittErfassenForm.address") &&
          props.getValues("eintrittErfassenForm.address")[key] &&
          props.getValues("eintrittErfassenForm.address")[key] !==
            getValues("address")[key]
        );
      }) ||
      props
        .getValues("eintrittErfassenForm.address.validFrom")
        .includes("1900-")
    );
  };

  const prepareToNextStep = (e) => {
    if (!isDisabledButton) {
      let formattedAddress = {
        ...getValues("address"),
        validFrom: validateIfChangedAddress()
          ? parseToApiDate(
              props.getValues("eintrittErfassenForm.employee.entryDate")
            )
          : getValues("address.addressValidFrom"),
      };
      delete formattedAddress.addressValidFrom;

      props.setValue("eintrittErfassenForm.address", formattedAddress);
      props.submitForm(e);
    }
  };

  const countryDropdownValue = props.landOptions
    ? props.landOptions.find(
        (option) => option.value === getValues("address.country")
      )
    : null;

  return (
    <div className="row">
      {/* Adress 1 */}
      <div className="col-12">
        <Controller
          control={control}
          name="address.address1"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              isFocused
              type="text"
              hidden={
                props.formConfig.formCustomization.components["address1"]
                  ?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              inputWrapperClass="text-input"
              id="address1"
              key="address1"
              name="address1"
              placeholder={props.intl.formatMessage({
                id: "app.address1",
                defaultMessage: "Adresszeile 1 eingeben",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("address.address1", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    address1: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "address1",
                  null,
                  getValues("requiredFields")
                );
                setError("address.address1", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "address1",
                  null,
                  getValues("requiredFields")
                );
                setError("address.address1", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.address1?.message,
                  type: errors?.address?.address1
                    ?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.address1" defaultMessage="Adresse 1" />
              {props.formConfig.formCustomization.components["address1"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* Adress 2 */}
      <div className="col-12">
        <Controller
          control={control}
          name="address.address2"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              hidden={
                props.formConfig.formCustomization.components["address2"]
                  ?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              inputWrapperClass="text-input"
              id="address2"
              name="address2"
              placeholder={props.intl.formatMessage({
                id: "app.address2",
                defaultMessage: "Adresszeile 2 eingeben",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("address.address2", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    address2: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "address2",
                  null,
                  getValues("requiredFields")
                );
                setError("address.address2", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.address2.model, formConfig.address.address2.name);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "address2",
                  null,
                  getValues("requiredFields")
                );
                setError("address.address2", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.address2?.message,
                  type: errors?.address?.address2
                    ?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.address2" defaultMessage="Adresse 2" />
              {props.formConfig.formCustomization.components["address2"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* PLZ */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="address.zipCode"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              key="zipCodeInput"
              inputWrapperClass="text-input"
              hidden={
                props.formConfig.formCustomization.components["zipCode"]?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              id="zipCode"
              name="zipCode"
              placeholder={props.intl.formatMessage({
                id: "app.enterpostacode",
                defaultMessage: "Postleitzahl eingeben",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("address.zipCode", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    zipCode: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "zipCode",
                  null,
                  getValues("requiredFields")
                );
                setError("address.zipCode", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.zipCode.model, formConfig.address.zipCode.name);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "zipCode",
                  null,
                  getValues("requiredFields")
                );
                setError("address.zipCode", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.zipCode?.message,
                  type: errors?.address?.zipCode
                    ?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id="app.postalcode"
                defaultMessage="Postleitzahl"
              />
              {props.formConfig.formCustomization.components["zipCode"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* Ort */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="address.city"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              inputWrapperClass="text-input"
              hidden={
                props.formConfig.formCustomization.components["city"]?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              id="city"
              name="city"
              placeholder={props.intl.formatMessage({
                id: "app.entercity",
                defaultMessage: "Ort eingeben",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("address.city", filterOnlyLetters(valueInput));
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    city: filterOnlyLetters(valueInput),
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "city",
                  null,
                  getValues("requiredFields")
                );
                setError("address.city", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.city.model, formConfig.address.city.name);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "city",
                  null,
                  getValues("requiredFields")
                );
                setError("address.city", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.city?.message,
                  type: errors?.address?.city?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.city" defaultMessage="Ort" />
              {props.formConfig.formCustomization.components["city"]?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* Land */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="address.country"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              isCountriesDropdown
              inputWrapperClass="dropdown-input"
              hidden={
                props.formConfig.formCustomization.components["country"]?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              id="country"
              name="country"
              placeholder={props.intl.formatMessage({
                id: "app.enterland",
                defaultMessage: "Land eingeben",
              })}
              label="Land"
              value={
                countryDropdownValue
                  ? countryDropdownValue["label"]
                  : value || ""
              }
              selectOptions={props.landOptions}
              onSelected={(name, valueInput) => {
                setValue("address.country", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    country: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "country",
                  null,
                  getValues("requiredFields")
                );
                setError("address.country", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.country.model, formConfig.address.country.name);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "country",
                  null,
                  getValues("requiredFields")
                );
                setError("address.country", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.country?.message,
                  type: errors?.address?.country
                    ?.type as EValidationMessageType,
                },
              }}
              sort
            >
              <FormattedMessage id="app.land" defaultMessage="Land" />
              {props.formConfig.formCustomization.components["country"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6"></div>

      {/* Gutlig ab */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="address.addressValidFrom"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="date"
              maxLength={10}
              inputWrapperClass="text-input"
              hidden={
                props.formConfig.formCustomization.components[
                  "addressValidFrom"
                ]?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              id="addressValidFrom"
              name="addressValidFrom"
              placeholder={"z.B. 22.12.2016"}
              value={value && value.length >= 10 ? parseDate(value) : value}
              onChange={(name, valueInput) => {
                setValue("address.addressValidFrom", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    addressValidFrom: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "addressValidFrom",
                  null,
                  getValues("requiredFields")
                );
                setError("address.addressValidFrom", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.validFrom.model, formConfig.address.validFrom.name);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "addressValidFrom",
                  null,
                  getValues("requiredFields")
                );

                setError("address.addressValidFrom", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.addressValidFrom?.message,
                  type: errors?.address?.addressValidFrom
                    ?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.validfrom" defaultMessage="Gültig ab" />
              {props.formConfig.formCustomization.components["addressValidFrom"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6" />

      {/* Email */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="address.email"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              hidden={
                props.formConfig.formCustomization.components["email"]?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              inputWrapperClass="text-input"
              id="email"
              name="email"
              placeholder=""
              value={value}
              onChange={(name, valueInput) => {
                setValue("address.email", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    email: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "email",
                  () => [EValidationTestType.isEmail],
                  getValues("requiredFields")
                );
                setError("address.email", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.email.model, formConfig.address.email.name);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "email",
                  () => [EValidationTestType.isEmail],
                  getValues("requiredFields")
                );
                setError("address.email", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.email?.message,
                  type: errors?.address?.email?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.email" defaultMessage="Email" />
              {props.formConfig.formCustomization.components["email"]?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* Mobile Number */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="address.mobileNumber"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              inputWrapperClass="text-input"
              hidden={
                props.formConfig.formCustomization.components["mobileNumber"]
                  ?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              id="mobileNumber"
              name="mobileNumber"
              placeholder=""
              value={value}
              onChange={(name, valueInput) => {
                setValue("address.mobileNumber", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    mobileNumber: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "mobileNumber",
                  () => [
                    EValidationTestType.isPhoneNumber,
                    EValidationTestType.isNumber,
                  ],
                  getValues("requiredFields")
                );
                setError("address.mobileNumber", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.mobileNumber.model, formConfig.address.mobileNumber.name);
              }}
              onBlur={(name, valueInput) => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "mobileNumber",
                  () => [
                    EValidationTestType.isPhoneNumber,
                    EValidationTestType.isNumber,
                  ],
                  getValues("requiredFields")
                );
                setError("address.mobileNumber", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.mobileNumber?.message,
                  type: errors?.address?.mobileNumber
                    ?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id="app.mobilenumber"
                defaultMessage="Mobiltelefon"
              />
              {props.formConfig.formCustomization.components["mobileNumber"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* Phone number */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="address.phoneNumber"
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              inputWrapperClass="text-input"
              hidden={
                props.formConfig.formCustomization.components["phoneNumber"]
                  ?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              id="phoneNumber"
              name="phoneNumber"
              placeholder=""
              value={value}
              onChange={(name, valueInput) => {
                setValue("address.phoneNumber", valueInput);
                props.updateFieldValues({
                  address: {
                    ...props?.storedValues?.address,
                    phoneNumber: valueInput,
                  },
                });
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "phoneNumber",
                  () => [
                    EValidationTestType.isPhoneNumber,
                    EValidationTestType.isNumber,
                  ],
                  getValues("requiredFields")
                );
                setError("address.phoneNumber", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
                // props.removeBackendValidationMessage("eintrittErfassenForm", formConfig.address.phoneNumber.model, formConfig.address.phoneNumber.name);
              }}
              onBlur={(name, valueInput) => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("address"),
                  "phoneNumber",
                  () => [
                    EValidationTestType.isPhoneNumber,
                    EValidationTestType.isNumber,
                  ],
                  getValues("requiredFields")
                );
                setError("address.phoneNumber", validation);
                validation?.valid &&
                  setValue("requiredFields", validation?.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.address?.phoneNumber?.message,
                  type: errors?.address?.phoneNumber
                    ?.type as EValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.phonenumber" defaultMessage="Telefon" />
              {props.formConfig.formCustomization.components["phoneNumber"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6" />
      <div className="col-12 col-sm-6" />

      <div className="col-12 col-sm-6">
        <div className="modal-buttons">
          <Button
            className="modal-sub-button primary"
            onClick={() => {
              props.goToPreviousStep();
            }}
            to={null}
            type={ButtonTypes.Text}
          >
            <ArrowRight className="mr-16 rotate-180" />
            <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
          </Button>
          <Button
            submit={true}
            type={ButtonTypes.Standard}
            color={ButtonColors.Action}
            style={ButtonStyles.Primary}
            isDisabled={props.isLoading || isDisabledButton}
            onClick={(e) => prepareToNextStep(e)}
          >
            <FormattedMessage id="app.submit" defaultMessage="Bestätigen" />
          </Button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
    storedValues: state.app.storedValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFieldValues: bindActionCreators(updateFieldValues, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Step4))
);
