import { useState, useEffect } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getParameterByName,
  getParameterByNameWithoutSpaces,
} from "../../../../utils/toSearchParams";
import { login } from "../../../../actions/auth";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../../@types/forms/agp/formValidation";
import { Controller, useForm } from "react-hook-form";
import { validateInputMessage } from "../../../../utils/validationTools";

interface Props {
  intl: any;
  history: any;
  login: any;
}

function Mfa(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      code: "",
    },
  });

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      formCustomization: {
        components: {
          code: {
            required: true,
          },
        },
        messages: {},
      },
      requiredFields: {
        code: !!getValues("code"),
      },
    });
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  useEffect(() => {
    const token = getParameterByName("mfa-token", null);
    if (!token) {
      props.history.push("/login");
    }
  },[]);

  const onSubmit = () => {
    const code = getValues("code");
    const token = getParameterByNameWithoutSpaces("mfa-token", null);
    if (token) {
      setIsLoading(true);
      props
        .login({ pin: code, bearerToken: token })
        .then((res) => setIsLoading(false));
    }
  };

  return (
    <div>
      <div className="login-title">
        <FormattedMessage
          id="app.twofactorauthentication"
          defaultMessage="Zwei-Faktor Code"
        />
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          control={control}
          name="code"
          render={({ field: { value } }) => (
            <Input
              type="text"
              name="code"
              value={value}
              onChange={(name, inputValue) => {
                setValue("code", inputValue);

                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "code",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );
                setError("code", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  formConfig.formCustomization,
                  getValues(),
                  "code",
                  () => [EValidationTestType.hasValue],
                  formConfig.requiredFields
                );
                setError("code", validation);
                validation?.valid &&
                  setFormConfig({
                    ...formConfig,
                    requiredFields: validation?.valid,
                  });
              }}
              validationMessageConfig={{
                visible: false,
                config: {
                  message: errors?.code?.message,
                  type: errors?.code?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage id="app.pin" defaultMessage="Code" />
            </Input>
          )}
        />
        <Button
          submit
          className="mt-48"
          isDisabled={isDisabledButton || isLoading}
          onClick={() => onSubmit()}
          type={ButtonTypes.Standard}
          color={ButtonColors.Action}
          style={ButtonStyles.Primary}
        >
          <FormattedMessage id="app.signin" defaultMessage="ANMELDEN" />
        </Button>
      </form>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  login: bindActionCreators(login, dispatch),
});
const connector = connect(null, mapDispatchToProps);
export default withRouter(connector(injectIntl(Mfa)));
