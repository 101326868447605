import * as React from "react";
import { EInputTextType, IInputConfig } from "../index";
import { defined } from "../../../utils/variableEvaluation";
import { InputText } from "../Text";
import { InputSelect } from "../Select";
import { InputCheckbox } from "../Checkbox";
// import { InputTextarea } from "../Textarea";
import { renderSwitch } from "../../../utils/react";
import { InputFile } from "../File";
import { InputRadio } from "../Radio";
import { InputCircularRadio } from "../CircularRadio";
// import { Calendar } from "../../Calendar";

export enum EInputType {
  Textarea = "Textarea",
  File = "File",
  Text = "Text",
  Checkbox = "Checkbox",
  Radio = "Radio",
  Select = "Select",
  CircularRadio = "CircularRadio",
  Custom = "Custom",
}

interface IProps {
  inputConfig: IInputConfig;
}

export class InputContent extends React.Component<IProps, {}> {
  private inputType(): EInputType {
    const { type, selectOptions, radioOptions, customComponent } =
      this.props.inputConfig;
    if (type === "textarea") {
      return EInputType.Textarea;
    } else if (type === "file") {
      return EInputType.File;
    } else if (type === "checkbox") {
      return EInputType.Checkbox;
    } else if (defined(selectOptions)) {
      return EInputType.Select;
    } else if (defined(radioOptions)) {
      return EInputType.Radio;
    } else if (type === "radio") {
      return EInputType.CircularRadio;
    } else if (defined(customComponent)) {
      return EInputType.Custom;
    } else {
      return EInputType.Text;
    }
  }

  public render(): JSX.Element {
    const {
      checkboxLabel,
      radioLabel,
      checked,
      clearable,
      customComponent,
      disabled,
      forcedValue,
      inputTextType,
      isFocused,
      isLoading,
      max,
      maxLength,
      min,
      name,
      onBlur,
      onChange,
      onDropdownOpen,
      onSelected,
      placeholder,
      radioOptions,
      readOnly,
      selectOptions,
      step,
      type,
      value,
      filtering,
      id,
      cols,
      rows,
      isOpen,
      isDirty,
      onFocus,
      sort,
      validationMessageConfig,
      fileInputLabel,
      numeric,
      isCountriesDropdown,
      hideClearDropdownIcon,
      hideArrowDropdownIcon,
      isDatepicker,
      validationVisible,
      prefix,
    } = this.props.inputConfig;
    console.log("inputtype", this.inputType());
    return renderSwitch(this.inputType(), {
      [EInputType.Custom]: () => customComponent!,
      [EInputType.Checkbox]: () => (
        <InputCheckbox
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          value={value}
          checked={checked}
          readOnly={readOnly}
          label={checkboxLabel}
          type={type!}
          onBlur={onBlur}
          isDisabled={disabled}
          onChange={onChange!}
        />
      ),
      [EInputType.CircularRadio]: () => (
        <InputCircularRadio
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          value={value}
          checked={checked}
          readOnly={readOnly}
          label={radioLabel}
          type={type!}
          onBlur={onBlur}
          isDisabled={disabled}
          onChange={onChange!}
        />
      ),
      [EInputType.Radio]: () => (
        <InputRadio
          isDirty={isDirty}
          radioOptions={radioOptions}
          readOnly={readOnly}
          forcedValue={forcedValue}
          onBlur={onBlur}
          isDisabled={disabled}
        />
      ),
      [EInputType.Select]: () => (
        <InputSelect
          id={id}
          clearable={clearable}
          inputTextType={inputTextType || EInputTextType.Material}
          isDisabled={disabled}
          isFocused={isFocused}
          isLoading={isLoading}
          name={name!}
          onBlur={onBlur}
          onChange={onChange}
          isDirty={isDirty}
          onDropdownOpen={onDropdownOpen!}
          onSelected={onSelected}
          placeholder={placeholder}
          readOnly={readOnly}
          selectOptions={selectOptions}
          type={type!}
          value={value}
          filtering={filtering}
          isOpen={isOpen}
          onFocus={onFocus}
          sort={sort}
          isCountriesDropdown={isCountriesDropdown}
          hideClearDropdownIcon={hideClearDropdownIcon}
          hideArrowDropdownIcon={hideArrowDropdownIcon}
        />
      ),
      [EInputType.Text]: () => (
        // isDatepicker ? (
        //    <Calendar
        //       value={value}
        //       name={name}
        //       readOnly={readOnly}
        //       isDisabled={disabled}
        //       onChange={onChange}
        //       onBlur={onBlur}
        //       validationVisible={validationVisible}
        //       validationMessageConfig={validationMessageConfig}
        //    />
        // ) : (
        <InputText
          id={id}
          numeric={numeric}
          prefix={prefix}
          isFocused={isFocused}
          isDirty={isDirty}
          isLoading={isLoading}
          name={name}
          min={min}
          max={max}
          step={step}
          maxLength={maxLength}
          value={value}
          placeholder={placeholder}
          type={type}
          readOnly={readOnly}
          isDisabled={disabled}
          inputTextType={inputTextType || EInputTextType.Material}
          onChange={onChange}
          onBlur={onBlur}
          filtering={filtering}
          validationMessageConfig={validationMessageConfig}
        />
      ),
      // [EInputType.File]: () => (
      //   <InputFile
      //     id={id}
      //     isFocused={isFocused}
      //     isLoading={isLoading}
      //     name={name}
      //     value={value}
      //     placeholder={placeholder}
      //     type={type}
      //     readOnly={readOnly}
      //     isDisabled={disabled}
      //     onChange={onChange}
      //     label={fileInputLabel}
      //     onBlur={onBlur}
      //   />
      // ),
      // [EInputType.Textarea]: () => (
      //   <InputTextarea
      //     isFocused={isFocused}
      //     isLoading={isLoading}
      //     name={name}
      //     maxLength={maxLength}
      //     value={value}
      //     placeholder={placeholder}
      //     type={type}
      //     readOnly={readOnly}
      //     isDisabled={disabled}
      //     onChange={onChange}
      //     onBlur={onBlur}
      //     rows={rows}
      //     cols={cols}
      //   />
      // ),
    });
  }
}
