import * as React from 'react';
import Multiselect from "../../../Multiselect";
import {Button, ButtonColors, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {FormattedMessage} from "react-intl";
import {ReactComponent as Arrow} from "../../../../assets/icons/arrow-right-action.svg";

interface IProps {
    onCancel: any;
    onSelect: any;
    selected: any;
    massSelect: any;
    employerOptions: any;
    commitMutations: any;
}

interface IState {
    selected: any;
}

class CommitMutationsModal extends React.Component<IProps, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            selected: []
        };
    }

    public componentDidMount() {
    }

    public componentWillUnmount() {
    }

    public componentDidUpdate(prevProps, prevState, snapshot) {
    }

    commitMutations = () => {
        const {selected} = this.state;
        this.props.commitMutations(selected);
    }

    cancel = () => {
        this.props.onCancel();
    }

    render() {
        const {employerOptions} = this.props;
        return (
            <div className="modal">
                <div className="d-flex align-items-center justify-content-between mb-94">
                    <Button
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        onClick={this.cancel}>
                        <Arrow className="rotate-180 mr-16"/>
                        <FormattedMessage id="app.backtooverview" defaultMessage="Zurück zur Übersicht"/>
                    </Button>
                    <h2>Änderungen abgeben</h2>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-40">
                    <h1>Arbeitgeber auswählen</h1>
                    <Button
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        onClick={this.props.massSelect}>
                        {this.props.selected.length ?
                            <FormattedMessage id="app.selectall" defaultMessage="Alle abwählen"/>
                            : <FormattedMessage id="app.deselectall" defaultMessage="Alle auswählen"/>}
                    </Button>
                </div>
                <div className="modal-content">
                    <p className="mb-24 text-grey">Arbeitgeber</p>
                    <Multiselect
                        options={employerOptions}
                        selected={this.props.selected}
                        onChange={selected => this.props.onSelect(selected)}/>

                    <div className="modal-buttons">
                        <Button
                            type={ButtonTypes.Text}
                            color={ButtonColors.Action}
                            onClick={this.cancel}>
                            <FormattedMessage id="app.cancel" defaultMessage="Abbrechen"/>
                        </Button>

                        <Button
                            style={ButtonStyles.Primary}
                            type={ButtonTypes.Standard}
                            color={ButtonColors.Action}
                            onClick={this.commitMutations}>
                            <FormattedMessage id="app.submit" defaultMessage="Weiter"/>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommitMutationsModal;
