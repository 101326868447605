import * as constants from '../../constants';

export interface AddressesState {
    addresses: any;
}

const getInitialState = () => {
    return {
        addresses: {},
    }
};

// console.log("app.ts", getInitialState());

const addresses = (state: AddressesState = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.FETCHED_ADDRESSES: {
            const addressObj = {};
            action.payload.forEach((entity) => {
                addressObj[entity.id] = entity.attributes;
            })
            return { ...state, addresses: addressObj };
        }

        case constants.LOG_OUT: {
            return { ...getInitialState() };
        }

    }

    return state;
};

export default addresses;
