import React from "react";
import styles from "./AddressExpandableRowTop.module.scss";
import { ReactComponent as IconEdit } from "../../../../../assets/icons/edit.svg";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { RootState } from "../../../../../reducers";
import { connect } from "react-redux";
import { parseDate } from "../../../../../utils/react";
import { ReactComponent as IconDelete } from "../../../../../assets/icons/trash.svg";

interface Props {
  intl: IntlShape;
  address: any;
  onEditClick: (value: string) => void;
  onDeleteClick: (value: string) => void;
}

const AddressExpandableRowTop = ({
  address,
  onEditClick,
  onDeleteClick,
}: Props) => {
  return (
    <div className={styles["address-expandable-row-top"]}>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.validFrom" defaultMessage="Gültig ab" />
        </span>
        <p>{parseDate(address.validFrom)}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.validUntil" defaultMessage="Gültig bis" />
        </span>
        <p>{parseDate(address.validUntil)}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.row.address1" defaultMessage="Adresse 1" />
        </span>
        <p>{address.address1}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.row.address2" defaultMessage="Adresse 2" />
        </span>
        <p>{address.address2}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.city" defaultMessage="Ort" />
        </span>
        <p>{address.city}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.land" defaultMessage="Land" />
        </span>
        <p>{address.countryName}</p>
      </div>
      <div className={styles.column}>
        <Button
          className="edit-icon mr-24"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onEditClick(address.id)}
        >
          <IconEdit />
        </Button>
        <Button
          className="trash-icon"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onDeleteClick(address.id)}
        >
          <IconDelete />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  //
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(AddressExpandableRowTop));
