import * as constants from "../../constants/pensionskasse";
import { LOG_OUT } from "../../constants";

export interface ForReviewMutationsStatePKCockpit {
  forReviewMutationsCockpit: any;
  total: number;
  warnings: number;
  errors: number;
}

const getInitialState = () => {
  return {
    forReviewMutationsCockpit: [],
    total: 0,
    warnings: 0,
    errors: 0,
  };
};

const forReviewMutationsCockpit = (
  state: ForReviewMutationsStatePKCockpit = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.FETCHED_FOR_REVIEW_MUTATIONS_COCKPIT: {
      console.log(action);
      const forReviewMutations = [
        ...state.forReviewMutationsCockpit,
        ...action.payload,
      ];
      return {
        ...state,
        forReviewMutationsCockpit: forReviewMutations,
        forReviewMutationsFetched: false,
      };
    }
    case constants.FETCHED_FOR_REVIEW_MUTATIONS_COUNT_COCKPIT: {
      return {
        ...state,
        total: action.payload.total,
        warnings: action.payload.warnings,
        errors: action.payload.errors,
        forReviewMutationsCountFetched: false,
      };
    }
    case LOG_OUT: {
      return { ...getInitialState() };
    }
  }

  return state;
};

export default forReviewMutationsCockpit;
