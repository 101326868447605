import {Blank, ExpandableRow} from "icr-shared-components";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {RootState} from "../../../../reducers";
import InvoiceExpandableRowContent from "../InvoiceExpandableRowContent";
import InvoiceExpandableRowMore from "../InvoiceExpandableRowMore";
import memoize from "memoize-one";
import * as actions from "../../../../actions";
import {bindActionCreators, Dispatch} from "redux";
import {fetchInvoices} from "../../../../actions/arbeitgeber/invoices";
import styles from "./InvoiceList.module.scss";


interface InvoiceListProps {
    intl: any;
    invoices: any;
    entities: any;
    rowChanged: any;
    expanded: any;
    filteredInvoices: any;
    openModal: any;
    employers: any;
    filterByFirmaInvoices: any;
    filterByStatusInvoices: any;
    filterInvoices: any;
    setInvoicesPageNo: any;
    fetchInvoices: any;
    setLoading: any;
    invoicesFetched: boolean;
}

interface State {
    expandedRow: any;
}

class InvoiceList extends Component<InvoiceListProps, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            expandedRow: null,
        }
    }

    prepareStatusOptions = memoize((entities) => {
        const options = [];
        if (
            entities &&
            entities.InvoiceStates &&
            Object.keys(entities.InvoiceStates.values).length
        ) {
            const invoiceStates = entities.InvoiceStates.values;
            Object.keys(invoiceStates).forEach((invoiceState) => {
                options.push({
                    value: invoiceState,
                    label: invoiceStates[invoiceState],
                });
            });
        }
        return options;
    });

    clearFilters = () => {
        this.props.filterInvoices({ name: "clear" });
        this.props.setInvoicesPageNo(0);
        this.props.setLoading(true);
        this.props.fetchInvoices().then(() => {
            this.props.setLoading(false);
        })
    }

    filtersApplied = () => {
        return this.props.filterByFirmaInvoices || this.props.filterByStatusInvoices;
    }

    rowChanged = (expanded, row) => {
        this.setState({expandedRow: row});
    }

    render() {
        return (
            this.props.invoicesFetched || this.props.invoices.length ? <div className={styles.listView}>
                <div className={styles.listHeader}>
                    <div className={styles.column}>
                        <FormattedMessage id="app.invoicenumber" defaultMessage="Rechnungsnummer"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.invoicedate" defaultMessage="Rechnungsdatum"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.duedate" defaultMessage="Fälligkeitsdatum"/>
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.amount" defaultMessage="Betrag" />
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.status" defaultMessage="Status" />
                    </div>
                    <div className={styles.column} style={{marginRight: "3rem"}} />
            </div>

                {this.props.invoices.length > 0 ? (
                    this.props.invoices.map((invoice, idx) => {
                        return this.props.entities.InvoiceStates ? (
                            <ExpandableRow
                                // expandable
                                // index={idx}
                                className={`mb-24`}
                                key={"invoiceRow" + idx}
                                // rowChanged={(expanded) => this.rowChanged(expanded, idx)}
                                // expanded={this.state.expandedRow}
                                // length={this.props.filteredInvoices || this.props.invoices}
                                content={
                                    <InvoiceExpandableRowContent
                                        invoice={invoice}
                                        statusOptions={this.prepareStatusOptions(this.props.entities)}
                                        openModal={() => this.props.openModal(invoice.attributes.file)}/>}
                                // more={
                                //     <InvoiceExpandableRowMore
                                //         entry={invoice}
                                //         employers={this.props.employers}/>}
                            />
                        ) : null;
                    })
                ) : (
                    <Blank
                        onButtonClick={this.clearFilters}
                        showButton={this.filtersApplied()}
                        text={this.props.intl.formatMessage({
                            id: "app.noinvoices",
                            defaultMessage: "Keine Rechnungen vorhanden",
                        })}
                    />
                )}
        </div>: null
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        invoices: state.invoices.invoices,
        entities: state.app.entities,
        employers: state.app.employers,

        invoicesFetched: state.invoices.invoicesFetched,
        filterByFirmaInvoices: state.invoices.filterByFirmaInvoices,
        filterByStatusInvoices: state.invoices.filterByStatusInvoices,
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
        filterInvoices: (val) => dispatch(actions.filterInvoices(val)),
        setInvoicesPageNo: (val) => dispatch(actions.setInvoicesPageNo(val)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InvoiceList));
