// import {objectToSearchParams} from './toSearchParams';
import { store } from "../index";
import { logOutLocally } from "../actions";
import axios from "axios";
import { toastr } from "react-redux-toastr";

const apiUrl = process.env.REACT_APP_REST_API_URL;
// const apiUrl = "https://api.arbeitgeber.pensiondynamics.ch/jsonapi/v1/";

const instance = axios.create({
  baseURL: apiUrl,
});

instance.defaults.headers.get["Content-Type"] = "application/json";
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.put["Content-Type"] = "application/json";
instance.defaults.headers.patch["Content-Type"] = "application/json";
instance.defaults.headers.delete["Content-Type"] = "application/json";

instance.interceptors.request.use(
  function (config: any) {
    const apiToken = localStorage.getItem("bearerToken");
    if (apiToken) {
      config.headers.common.Authorization = `Bearer ${apiToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response: any) {
    const { data } = response;

    return data;
  },
  function (error: any) {
    const { response } = error;
    const { status, data } = response;

    if (data) {
      const isSsn = response.request.responseURL.includes("people");
      const isLogout = response.request.responseURL.includes("logout");
      const isDeleteMutations =
        response.request.responseURL.includes("?cascade=false");
      if (!isDeleteMutations && !isSsn && !isLogout) {
        if (data.errors?.length) {
          data.errors.forEach((error) => {
            toastr.error(error.title, error.detail || "");
          });
        }
      }
    }

    if (status === 401) {
      store.dispatch(logOutLocally());
    }

    return Promise.reject(data);
  }
);

export default instance;

export function authApiCaller<T>(
  method: string,
  path: string,
  data?: any,
  headers?: object
): Promise<T[] | any> {
  console.log("authApiCaller headers: ", headers, "data: ", data);
  return fetch(apiUrl + path, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : null,
  })
    .then((res) => {
      console.log("res: ", res);
      if (res.ok) {
        return res.text();
      }

      return res.json().then((errorObj) => {
        console.log("tryapicallerro2", errorObj);
        throw JSON.stringify(errorObj);
      });
    })
    .then((text) => {
      console.log("got text: ", text);
      try {
        const data = JSON.parse(text);
        // Do your JSON handling here
        return data;
      } catch (err) {
        return text;
        // It is text, do you text handling here
      }
    });
}
