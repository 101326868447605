import * as React from 'react';
import {Blank, ExpandableRow} from 'icr-shared-components';
import {FormattedMessage, injectIntl} from "react-intl";
import ExpandedRowMore from "../InvoicesExpandableRowMore";
import InvoicesExpandableRowTop from "../InvoicesExpandableRowTop";
import styles from "./InvoicesList.module.scss";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {fetchInvoices} from "../../../../actions/arbeitgeber/invoices";
import {connect} from "react-redux";
import {setInvoicesFilter, setInvoicesPageNo} from "../../../../actions/pensionskasse/invoices";

interface IProps {
    intl: any;
    employers: any;
    invoices: any;
    expandedRow: any;
    filtersChanged: any;
    handleRowChanged: any;
    handleOpenPdf: any;
    fetchInvoices: any;
    setLoading: any;
    filterInvoices: any;
    setInvoicesPageNo: any;
    invoicesFetched: boolean;
    invoicesFirmaFilter: any;
    invoicesOnlyOpenFilter: boolean;
    invoicesOnlyOverdueFilter: boolean;
    invoicesOnlyPaidFilter: boolean;
}

const InvoicesList = (props: IProps) => {

    const clearFilters = () => {
        props.filterInvoices({ name: "clear" });
        props.setInvoicesPageNo(0);
        props.setLoading(true);
        props.fetchInvoices().then(() => {
            props.setLoading(false);
        })
    }

    const filtersApplied = props.invoicesFirmaFilter || props.invoicesOnlyOpenFilter || props.invoicesOnlyOverdueFilter || props.invoicesOnlyPaidFilter;

    return (
        props.invoicesFetched || props.invoices.length ? <div className={styles.listView}>
            <div className={styles.listHeader}>
                <div className={styles.column}>
                    <FormattedMessage id="app.invoicenumber" defaultMessage="Rechnungsnummer" />
                </div>
                <div className={styles.column}>
                    <FormattedMessage id="app.invoicedate" defaultMessage="Rechnungsdatum" />
                </div>
                <div className={styles.column}>
                    <FormattedMessage id="app.duedate" defaultMessage="Fälligkeitsdatum" />
                </div>
                <div className={styles.column}>
                    <FormattedMessage id="app.amount" defaultMessage="Betrag" />
                </div>
                <div className={styles.column}>
                    <FormattedMessage id="app.status" defaultMessage="Status" />
                </div>
                <div className={styles.column}>
                    <div style={{width: "3rem"}} />
                </div>
            </div>
            {props.invoices.length ? props.invoices.map((invoice, index) => (
                <ExpandableRow
                    // index={index}
                    // expandable={true}
                    translucent={false}
                    className="mb-24"
                    key={`invoices_row_${invoice.id}`}
                    // expanded={props.expandedRow}
                    // length={props.filtersChanged}
                    // rowChanged={() => props.handleRowChanged(index)}
                    content={<InvoicesExpandableRowTop invoice={invoice} onPdfClick={() => props.handleOpenPdf(invoice.attributes.file)} />}
                    // more={<ExpandedRowMore invoice={invoice} employers={props.employers} /> }
                />
            )) : (
                <Blank
                    onButtonClick={clearFilters}
                    showButton={filtersApplied}
                    text={props.intl.formatMessage({
                    id: "app.noinvoices",
                    defaultMessage: "Keine Rechnungen vorhanden",
                })}/>
            )}
        </div>: null
    );
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        invoices: state.invoices.invoices,
        entities: state.app.entities,
        employers: state.app.employers,

        invoicesFetched: state.invoicesPK.invoicesFetched,
        invoicesFirmaFilter: state.invoicesPK.invoicesFirmaFilter,
        invoicesOnlyOpenFilter: state.invoicesPK.invoicesOnlyOpenFilter,
        invoicesOnlyOverdueFilter: state.invoicesPK.invoicesOnlyOverdueFilter,
        invoicesOnlyPaidFilter: state.invoicesPK.invoicesOnlyPaidFilter
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
        filterInvoices: (val) => dispatch(setInvoicesFilter(val)),
        setInvoicesPageNo: (val) => dispatch(setInvoicesPageNo(val)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InvoicesList));
