import { store } from "../index";
import api from "../utils/api";
import { push } from "connected-react-router";
import { loginSuccess, setLoading } from "./index";
import * as constants from "../constants";
import { toastr } from 'react-redux-toastr';
import axios from "axios";
import { globalSettings } from "./settings";

const apiUrl = process.env.REACT_APP_REST_API_URL;
// const apiUrl = "https://api.arbeitgeber.pensiondynamics.ch/jsonapi/v1/";


export const login = (payload) => {
    const isPensionskasse = window.location.pathname.includes("/pensionskasse/");
    const headers: any = { 'Content-Type': 'application/x-www-form-urlencoded' };
    return async (dispatch) => {
        return axios.post(`${apiUrl}Authentication/`, payload, headers)
            .then((response: any) => {
                const status = response.data?.authenticationStatus;
                const bearerToken = response.data?.bearerToken;
                const resetToken = response.data?.resetToken;
                if (status == "Successfull") {
                    localStorage.setItem("bearerToken", bearerToken);
                    dispatch(globalSettings());
                    dispatch(loginSuccess(response.data));
                    setTimeout(() => dispatch(push(`${isPensionskasse ? "/pensionskasse" : ""}/cockpit`)), 200);
                } else if (status == "CheckMfa") {
                    setTimeout(() => dispatch(push(`${isPensionskasse ? "/pensionskasse" : ""}/login?password-reset=3&mfa-token=${bearerToken}`)), 200);
                } else if (status == "PasswordExpired") {
                    setTimeout(() => dispatch(push(`${isPensionskasse ? "/pensionskasse" : ""}/login?password-reset=2&token=${resetToken}`)), 200);
                    toastr.error(store.getState().intl.messages["app.accountexpired"]);
                } else if (status == "UserLockedOut") {
                    setTimeout(() => dispatch(push(`${isPensionskasse ? "/pensionskasse" : ""}/login?password-reset=1`)), 200);
                    toastr.error(store.getState().intl.messages["app.accountlocked"]);
                } else if (status == "MfaFailed") {
                    toastr.error("Zwei-Faktor Code inkorrekt");
                } else {
                    toastr.error('Benutzername oder Passwort inkorrekt.');
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
                localStorage.removeItem('bearerToken');
            });
    }
};

export const authenticate = (payload) => {
    return async (dispatch) => {
        return api.post(`${apiUrl}authenticate`, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const bearerToken = response.hasOwnProperty('bearerToken') ? response['bearerToken'] : null;
                localStorage.setItem('bearerToken', bearerToken);
                dispatch(globalSettings());
                dispatch(loginSuccess(response));
                return response;
            }).catch(err => {
                toastr.error("Invalid token!");
                localStorage.removeItem('bearerToken');
                return null
            });
    }
};

export const getUser = (username) => {
    return async (dispatch) => {
        return api.get(`users/${username}/`)
            .then(response => response)
            .catch(err => err);
    }
};

export const logOut = () => {
    const isPensionskasse = window.location.pathname.includes("/pensionskasse/");
    return async (dispatch) => {
        return api.post(`logout/`)
            .then(response => {
                dispatch(push(`${isPensionskasse ? "/pensionskasse" : ""}/login`));
                dispatch({ "type": constants.LOG_OUT, payload: null });
            }).catch(err => err);
    }
};
