import * as constants from '../../constants';
import {inboxQueryParams, outboxQueryParams} from "../../utils/toSearchParams";
import api from "../../utils/api";

export const fetchInboxPkc = (length, page) => {
    const params = inboxQueryParams(length, page);
    return async (dispatch) => {
        return api.get(`inbox/pension-fund/${params}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_INBOX, "payload": response['data'] });
            }).catch(err => {
                dispatch({ "type": constants.FETCHED_INBOX, "payload": null });
            });
    }
};

export const fetchOutboxPkc = (length, page) => {
    const params = outboxQueryParams(length, page);
    return async (dispatch) => {
        return api.get(`outbox/pension-fund/${params}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_OUTBOX, "payload": response['data'] });
                return response;
            }).catch(err => {
                dispatch({ "type": constants.FETCHED_OUTBOX, "payload": null });
                return err;
            });
    }
};

export const uploadFilePkc = (data) => {
    return async (dispatch) => {
        return api.post(`outbox/pension-fund`, data)
            .then(response => {
                dispatch({"type": constants.FILE_UPLOADED, "payload": response["data"]});
                return response;
            }).catch(err => err);
    }
};


