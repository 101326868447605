import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-action.svg";
import { useForm, Controller, UseFormSetValue } from "react-hook-form";
import { IntlShape, FormattedMessage, injectIntl } from "react-intl";
import {
  parseDate,
  prepareInsurancePlansDropdownOptions,
  prepareInsurancePlanObject,
  dateFilter,
  parseToApiDate,
  buildDateFromString,
  calculateAgeFromDates,
} from "../../../../utils/react";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import Tooltip from "rc-tooltip";
import { ReactComponent as IconHelp } from "../../../../assets/icons/help.svg";
import "rc-tooltip/assets/bootstrap_white.css";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";

import { Input } from "./../../../Input";

import { EValidationMessageType as ValidationMessageType } from "../../../../components/ValidationMessage";

import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../utils/validationTools";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import { EintrittFormValues } from "../../../../reducers/arbeitgeber/types";
import { bindActionCreators } from "redux";
import { updateFieldValues } from "../../../../actions/arbeitgeber/user";

type FormData = {
  employee: {
    validFrom: string;
    entryDate: string;
    employerId: string;
    insurancePlan: string;
    extEmployeeId: string;
    costCenter: string;
  };
  requiredFields?: {};
};

type Props = {
  intl: IntlShape;
  insurants: any;
  formConfig: ValidationFormProps;
  setFormValue: UseFormSetValue<{ eintrittErfassenForm: any }>;
  setFormConfig: (state: ValidationFormProps) => void;
  showValidations: boolean;
  employers: any;
  firmaOptions: any;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  getValues: any;
  setValue: any;
  storedValues: EintrittFormValues;
  updateFieldValues: any;
  fetchEmployersInsurancePlans: (employerId: string, isSelect?: boolean) => any;
};

function Step2(props: Props) {
  const {
    control,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useForm<FormData>({
    criteriaMode: "all",
    defaultValues: {
      employee: {
        validFrom:
          props?.storedValues?.employee?.entryDate ||
          props.getValues("eintrittErfassenForm.employee.validFrom") ||
          "",
        entryDate:
          props?.storedValues?.employee?.entryDate ||
          props.getValues("eintrittErfassenForm.employee.entryDate") ||
          "",
        employerId:
          props?.storedValues?.employee?.employerId ||
          props.getValues("eintrittErfassenForm.employee.employerId") ||
          (props.firmaOptions.length === 1
            ? props.firmaOptions[0]["value"]
            : ""),
        insurancePlan:
          props?.storedValues?.employee?.insurancePlan ||
          props.getValues("eintrittErfassenForm.employee.insurancePlan") ||
          (props.firmaOptions.length === 1
            ? props.employers[props.firmaOptions[0]["value"]]["attributes"][
                "insurancePlans"
              ][0]["code"]
            : ""),
        extEmployeeId:
          props?.storedValues?.employee?.extEmployeeId ||
          props.getValues("eintrittErfassenForm.employee.extEmployeeId") ||
          "",
        costCenter:
          props?.storedValues?.employee?.costCenter ||
          props.getValues("eintrittErfassenForm.employee.costCenter") ||
          "",
      },
      requiredFields: {},
    },
  });

  const [insurancePlanObject, setInsurancePlanObject] = useState<any>();
  const [insurancePlanOptions, setInsurancePlanOptions] = useState<any>();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [isLoadingInsurancePlan, setIsLoadingInsurancePlan] = useState(false);

  const [initialInsurancePlan, setInitialInsurancePlan] = useState<any>(
    props.storedValues?.employee?.insurancePlan ||
      props.getValues("eintrittErfassenForm.employee.insurancePlan")
  );

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const currentTenant = globalSettings?.attributes?.tenantCode;

  useEffect(() => {
    const employerId =
      props?.storedValues?.employee?.employerId ||
      props.getValues("eintrittErfassenForm.employee.employerId");

    if (employerId) {
      adjustInsurancePlan(employerId);
    }
  }, []);

  const mustDisableInsurancePlanInput = () => {
    const birthDate = props?.storedValues?.person?.birthDate.includes("-")
      ? new Date(props?.storedValues?.person?.birthDate)
      : buildDateFromString(props?.storedValues?.person?.birthDate);

    const age = calculateAgeFromDates(new Date(), birthDate);

    return currentTenant?.toLowerCase().includes("propublic") && age <= 44;
  };

  const adjustInsurancePlan = (employerId) => {
    let defaultInsurancePlanCode = props.getValues(
      "eintrittErfassenForm.employee.insurancePlan"
    );
    setIsLoadingInsurancePlan(true);

    const insurancePlansByProps = () => {
      if (
        !defaultInsurancePlanCode &&
        props.employers[employerId] &&
        props.employers[employerId]["attributes"]["insurancePlans"] &&
        props.employers[employerId]["attributes"]["defaultInsurancePlanId"]
      ) {
        let defaultInsurancePlanId =
          props.employers[employerId]["attributes"]["defaultInsurancePlanId"];
        defaultInsurancePlanCode = props.employers[employerId]["attributes"][
          "insurancePlans"
        ].find((plan) => plan["id"] == defaultInsurancePlanId)["code"];
      }

      setValue("employee.insurancePlan", defaultInsurancePlanCode);
      props.updateFieldValues({
        employee: {
          ...props?.storedValues?.employee,
          insurancePlan: defaultInsurancePlanCode,
          employerId: employerId,
        },
      });

      const internalInsurancePlanObject = prepareInsurancePlanObject(
        props.employers?.[employerId]?.attributes?.insurancePlans
      );

      setInsurancePlanObject(internalInsurancePlanObject);
      setInsurancePlanOptions(
        prepareInsurancePlansDropdownOptions(internalInsurancePlanObject)
      );
    };

    if (employerId) {
      props
        .fetchEmployersInsurancePlans(employerId)
        .then((response) => {
          if (!!response && response?.length) {
            let listOfInsuranceElements = {};
            let firstElementCode = "";
            response.forEach((item, index) => {
              index === 0 && (firstElementCode = item.attributes.code);
              listOfInsuranceElements[item.attributes.code] = {
                id: item.id,
                code: item.attributes.code,
                name: item.attributes.name,
                description: item.attributes.description,
              };
            });

            const defaultInsurancePlan = Object.keys(
              listOfInsuranceElements
            ).find(
              (key) =>
                listOfInsuranceElements[key].id ===
                props.employers[employerId].attributes.defaultInsurancePlanId
            );

            setValue(
              "employee.insurancePlan",
              listOfInsuranceElements[
                initialInsurancePlan || defaultInsurancePlan || firstElementCode
              ].code
            );
            props.updateFieldValues({
              employee: {
                ...props?.storedValues?.employee,
                insurancePlan:
                  listOfInsuranceElements[
                    initialInsurancePlan ||
                      defaultInsurancePlan ||
                      firstElementCode
                  ].code,
                employerId: employerId,
              },
            });

            setInitialInsurancePlan(null);
            setInsurancePlanObject(listOfInsuranceElements);
            setInsurancePlanOptions(
              prepareInsurancePlansDropdownOptions(listOfInsuranceElements)
            );
            setIsLoadingInsurancePlan(false);
          } else {
            insurancePlansByProps();
            setIsLoadingInsurancePlan(false);
          }
        })
        .catch(() => {
          insurancePlansByProps();
          setIsLoadingInsurancePlan(false);
        });
    }
  };

  const requiredFieldsStep2 = useCallback(() => {
    const requiredForStep2 = {};
    Object.keys({
      entryDate:
        props.getValues("eintrittErfassenForm.employee.entryDate") || "",
      employerId:
        props.getValues("eintrittErfassenForm.employee.employerId") || "",
      insurancePlan:
        props.getValues("eintrittErfassenForm.employee.insurancePlan") || "",
      extEmployeeId:
        props.getValues("eintrittErfassenForm.employee.extEmployeeId") || "",
      costCenter:
        props.getValues("eintrittErfassenForm.employee.costCenter") || "",
      validFrom:
        props.getValues("eintrittErfassenForm.employee.validFrom") || "",
    }).map(
      (key) =>
        props.formConfig.formCustomization.components[key]?.required &&
        (requiredForStep2[key] = !!getValues("employee")[key])
    );
    setValue("requiredFields", requiredForStep2);
  }, [props.formConfig.formCustomization, getValues, setValue]);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(getValues("requiredFields")).every(
        (key) => getValues("requiredFields")[key]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("requiredFields")]);

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      requiredFieldsStep2();
    }
  }, [props.formConfig.formCustomization, requiredFieldsStep2]);

  const prepareToNextStep = () => {
    if (!isDisabledButton) {
      const employee = {
        entryDate: getValues("employee.entryDate"),
        employerId: getValues("employee.employerId"),
        insurancePlan: getValues("employee.insurancePlan"),
        extEmployeeId: getValues("employee.extEmployeeId"),
        costCenter: getValues("employee.costCenter"),
      };

      props.setValue("eintrittErfassenForm.employee", employee);
    }
    props.goToNextStep();
  };

  const haveCustomValidationsEntryDate = (
    formId: string
  ): EValidationTestType[] => {
    const validations = [];
    !!props.formConfig.formCustomization.components[formId]?.date?.dayFilter &&
      validations.push(
        dateFilter(
          props.formConfig.formCustomization.components[formId]?.date?.dayFilter
        )
      );
    return validations;
  };

  const checkIfEntryDateIsBeforeQuittingDate = (quittingDate) => {
    const quittingDateObject = new Date(quittingDate);
    const entryDateObject = new Date(
      parseToApiDate(getValues("employee.entryDate"))
    );

    return (
      quittingDate && quittingDateObject.getTime() > entryDateObject.getTime()
    );
  };

  const checkIfEntryDateIsBeforeRetirementDate = (retirementDate) => {
    const retirementDateObject = new Date(retirementDate);
    const entryDateObject = new Date(
      parseToApiDate(getValues("employee.entryDate"))
    );

    return (
      retirementDate &&
      retirementDateObject.getTime() > entryDateObject.getTime()
    );
  };

  const isInsurantAlreadyInThisFirma = (valueInput) => {
    props.getValues("eintrittErfassenForm.person.socialSecurityNumber");
    const insurantObjectsWithSameSsn =
      props.insurants &&
      props.insurants.filter(
        (insurant) =>
          insurant.attributes.person.socialSecurityNumber ===
          props.getValues("eintrittErfassenForm.person.socialSecurityNumber")
      );

    return insurantObjectsWithSameSsn.some(
      (item) =>
        item.attributes.employee.employerId === valueInput &&
        (checkIfEntryDateIsBeforeQuittingDate(
          item.attributes?.employee?.quittingDate
        ) ||
          checkIfEntryDateIsBeforeRetirementDate(
            item.attributes?.employee?.retirementDate
          ))
    );
  };

  const buildValidationMessageToEmployerId = (): ValidationMessage => {
    return validateInputMessage(
      props.formConfig.formCustomization,
      getValues("employee"),
      "employerId",
      null,
      getValues("requiredFields")
    );
  };

  const setEmployerIdAsInvalid = (validation: ValidationMessage) => {
    validation.type = "error";
    validation.message = "Die Person arbeitet bereits für diesen Arbeitgeber.";
    validation.valid["employerId"] = false;
  };

  return (
    <div className="row">
      {/* Versicherung Eintrittsdatum */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="employee.entryDate"
          render={({ field: { value } }) => (
            <Input
              isFocused
              key="enterinsuranceentrydate"
              type="date"
              hidden={
                props.formConfig.formCustomization.components["entryDate"]
                  ?.hidden
              }
              inputWrapperClass="text-input"
              id="entryDate"
              name="entryDate"
              placeholder={props.intl.formatMessage({
                id: "app.enterinsuranceentrydate",
                defaultMessage: "Eintrittsdatum Versicherung eingeben",
              })}
              value={value && value.length >= 10 ? parseDate(value) : value}
              onChange={(name, valueInput) => {
                setValue("employee.entryDate", valueInput);
                setValue("employee.validFrom", valueInput);

                props.updateFieldValues({
                  employee: {
                    ...props?.storedValues?.employee,
                    entryDate: valueInput,
                  },
                });

                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("employee"),
                  "entryDate",
                  haveCustomValidationsEntryDate,
                  getValues("requiredFields")
                );
                const employerValidation = buildValidationMessageToEmployerId();

                if (
                  getValues("employee.employerId") &&
                  isInsurantAlreadyInThisFirma(getValues("employee.employerId"))
                ) {
                  setEmployerIdAsInvalid(employerValidation);
                }

                setError("employee.employerId", employerValidation);
                employerValidation?.valid &&
                  setValue("requiredFields", employerValidation.valid);
                setError("employee.entryDate", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("employee"),
                  "entryDate",
                  haveCustomValidationsEntryDate,
                  getValues("requiredFields")
                );
                const employerValidation = buildValidationMessageToEmployerId();

                if (
                  getValues("employee.employerId") &&
                  isInsurantAlreadyInThisFirma(getValues("employee.employerId"))
                ) {
                  setEmployerIdAsInvalid(employerValidation);
                }

                setError("employee.employerId", employerValidation);
                employerValidation?.valid &&
                  setValue("requiredFields", employerValidation.valid);
                setError("employee.entryDate", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.employee?.entryDate?.message,
                  type: errors?.employee?.entryDate
                    ?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id="app.insuranceentrydate"
                defaultMessage="Versicherung Eintrittsdatum"
              />
              {props.formConfig.formCustomization.components["entryDate"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6" />

      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="employee.employerId"
          render={({ field: { value } }) => (
            <Input
              type="text"
              label="Arbeitgeber"
              inputWrapperClass="dropdown-input"
              hidden={
                props.formConfig.formCustomization.components["employerId"]
                  ?.hidden
              }
              id="employerId"
              key="employerId"
              name="employerId"
              placeholder=""
              value={
                props.employers[value] &&
                props.employers[value]["attributes"]["name"]
              }
              selectOptions={props.firmaOptions}
              onSelected={(name, valueInput) => {
                setValue("employee.employerId", valueInput);

                adjustInsurancePlan(valueInput);

                const validation: ValidationMessage =
                  buildValidationMessageToEmployerId();

                if (isInsurantAlreadyInThisFirma(valueInput)) {
                  setEmployerIdAsInvalid(validation);
                }

                setError("employee.employerId", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              onBlur={(name, valueInput) => {
                setValue("employee.employerId", valueInput);

                adjustInsurancePlan(valueInput);

                const validation: ValidationMessage =
                  buildValidationMessageToEmployerId();

                if (
                  isInsurantAlreadyInThisFirma(getValues("employee.employerId"))
                ) {
                  setEmployerIdAsInvalid(validation);
                }

                setError("employee.employerId", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.employee?.employerId?.message,
                  type: errors?.employee?.employerId
                    ?.type as ValidationMessageType,
                },
              }}
              sort
            >
              <FormattedMessage
                id="app.employer"
                defaultMessage="Versicherung Eintrittsdatum"
              />
              {props.formConfig.formCustomization.components["employerId"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6">
        {props.employers[getValues().employee.employerId] &&
          insurancePlanObject &&
          !isLoadingInsurancePlan && (
            <Controller
              control={control}
              name="employee.insurancePlan"
              render={({ field: { value } }) => (
                <Input
                  type="text"
                  inputWrapperClass="text-input"
                  disabled={mustDisableInsurancePlanInput()}
                  hidden={
                    props.formConfig.formCustomization.components[
                      "insurancePlan"
                    ]?.hidden
                  }
                  id="insurancePlan"
                  name="insurancePlan"
                  placeholder={props.intl.formatMessage({
                    id: "app.forms.eintritt.insurancePlan.placeholder",
                    defaultMessage: "Versicherungsplan",
                  })}
                  label="Anrede"
                  value={
                    insurancePlanObject[value]
                      ? insurancePlanObject[value]["name"]
                      : value
                  }
                  selectOptions={insurancePlanOptions}
                  onSelected={(name, valueInput) => {
                    setValue("employee.insurancePlan", valueInput);
                    props.updateFieldValues({
                      employee: {
                        ...props?.storedValues?.employee,
                        insurancePlan: valueInput,
                      },
                    });

                    const validation: ValidationMessage = validateInputMessage(
                      props.formConfig.formCustomization,
                      getValues("employee"),
                      "insurancePlan",
                      null,
                      getValues("requiredFields")
                    );

                    setError("employee.insurancePlan", validation);
                    validation?.valid &&
                      setValue("requiredFields", validation.valid);
                  }}
                  onBlur={() => {
                    const validation: ValidationMessage = validateInputMessage(
                      props.formConfig.formCustomization,
                      getValues("employee"),
                      "insurancePlan",
                      null,
                      getValues("requiredFields")
                    );

                    setError("employee.insurancePlan", validation);
                    validation?.valid &&
                      setValue("requiredFields", validation.valid);
                  }}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: errors?.employee?.insurancePlan?.message,
                      type: errors?.employee?.insurancePlan
                        ?.type as ValidationMessageType,
                    },
                  }}
                  sort
                >
                  <FormattedMessage
                    id={
                      currentTenant?.toLowerCase().includes("propublic")
                        ? "app.forms.eintritt.propublic.insurancePlan"
                        : "app.forms.eintritt.insurancePlan"
                    }
                    defaultMessage="Versicherungsplan"
                  />
                  {props.formConfig.formCustomization.components[
                    "insurancePlan"
                  ]?.required
                    ? " *"
                    : ""}
                  {currentTenant?.toLowerCase().includes("propublic") && (
                    <span className="z-index-high">
                      <Tooltip
                        trigger={["click"]}
                        placement="top"
                        overlay={
                          <span>
                            Ab dem 45. Lebensjahr besteht die Option zur Wahl
                            der Überbrückungsrente. Für Versicherte unter 45
                            Jahren ist die Überbrückungsrente standardmässig
                            ohne zusätzliche Beitragskosten aktiviert.
                          </span>
                        }
                        arrowContent={
                          <div className="rc-tooltip-arrow-inner"></div>
                        }
                      >
                        <div className="help-icon pointer">
                          <IconHelp />
                        </div>
                      </Tooltip>
                    </span>
                  )}
                </Input>
              )}
            />
          )}
      </div>

      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="employee.extEmployeeId"
          render={({ field: { value } }) => (
            <Input
              type="text"
              inputWrapperClass="text-input"
              hidden={
                props.formConfig.formCustomization.components["extEmployeeId"]
                  ?.hidden
              }
              id="extEmployeeId"
              name="extEmployeeId"
              placeholder={props.intl.formatMessage({
                id: "app.personalnumber",
                defaultMessage: "Personal-Nummer",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("employee.extEmployeeId", valueInput);
                props.updateFieldValues({
                  employee: {
                    ...props?.storedValues?.employee,
                    extEmployeeId: valueInput,
                  },
                });

                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("employee"),
                  "extEmployeeId",
                  () => [EValidationTestType.isNumber],
                  getValues("requiredFields")
                );

                setError("employee.extEmployeeId", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("employee"),
                  "extEmployeeId",
                  () => [EValidationTestType.isNumber],
                  getValues("requiredFields")
                );

                setError("employee.extEmployeeId", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.employee?.extEmployeeId?.message,
                  type: errors?.employee?.extEmployeeId
                    ?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id="app.personalnumber"
                defaultMessage="Personal-Nummer"
              />
              {props.formConfig.formCustomization.components["extEmployeeId"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name="employee.costCenter"
          render={({ field: { value } }) => (
            <Input
              type="text"
              inputWrapperClass="text-input"
              hidden={
                props.formConfig.formCustomization.components["costCenter"]
                  ?.hidden
              }
              id="costCenter"
              name="costCenter"
              placeholder={props.intl.formatMessage({
                id: "app.entercostcenter",
                defaultMessage: "Kostenstelle eingeben",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("employee.costCenter", valueInput);
                props.updateFieldValues({
                  employee: {
                    ...props?.storedValues?.employee,
                    costCenter: valueInput,
                  },
                });

                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("employee"),
                  "costCenter",
                  null,
                  getValues("requiredFields")
                );

                setError("employee.costCenter", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("employee"),
                  "costCenter",
                  null,
                  getValues("requiredFields")
                );

                setError("employee.costCenter", validation);
                validation?.valid &&
                  setValue("requiredFields", validation.valid);
              }}
              validationMessageConfig={{
                visible: props.showValidations,
                config: {
                  message: errors?.employee?.costCenter?.message,
                  type: errors?.employee?.costCenter
                    ?.type as ValidationMessageType,
                },
              }}
            >
              <FormattedMessage
                id={"app.costcenter"}
                defaultMessage="Kostenstelle"
              />
              {props.formConfig.formCustomization.components["costCenter"]
                ?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      <div className="col-12 col-sm-6" />

      <div className="col-12 col-sm-6">
        <div className="modal-buttons">
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={props.goToPreviousStep}
          >
            <ArrowRight className="mr-16 rotate-180" />
            <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
          </Button>
          <Button
            submit={true}
            type={ButtonTypes.Standard}
            color={ButtonColors.Action}
            style={ButtonStyles.Primary}
            isDisabled={isDisabledButton}
            onClick={() => prepareToNextStep()}
          >
            <FormattedMessage id="app.continue" defaultMessage="WEITER" />
          </Button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
    isLoading: state.app.loading,
    insurants: state.insurants.insurants,
    showValidations: state.app.showValidations,
    storedValues: state.app.storedValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFieldValues: bindActionCreators(updateFieldValues, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Step2))
);
