import React from "react";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import {expiredAlert, isTokenExpired} from "../../utils/react";
import Login from "../../containers/Login";
import {getParameterByName} from "../../utils/toSearchParams";

interface Props {
    path: any
    authentication: any;
    location: any;
}

const MainRoute: React.FC<Props> = ({ path, authentication, location }) => {
    const isPensionskasse = window.location.pathname.includes("/pensionskasse/");
    return <Route exact
               path={path}
               render={() => {
                   let params = "";
                   const token = getParameterByName("token", location.href);
                   const redirect = getParameterByName("redirect", location.href);

                   if (token && redirect) {
                       console.log(location.search)
                       params = location.search;
                   }
                   return isTokenExpired(authentication.token, authentication.expirationDate) || (token && redirect) ?
                       <Redirect to={`${isPensionskasse ? "/pensionskasse" : ""}/login` + params}/>
                       : <Redirect to={`${isPensionskasse ? "/pensionskasse" : ""}/cockpit` + params}/>
               }}/>
}

export default MainRoute;
