import * as constants from "../../constants";
import { EintrittFormValues } from "./types";
export interface AppState {
  sidebarOpened: boolean;
  employers: any;
  documentTypes: any;
  loading: boolean;
  authentication: any;
  entities: any;
  cockpitFirmaFilter: string;
  popupIsOpen: boolean;
  mutationTypes: any;
  showValidations: boolean;
  fetchedEntities: boolean;
  profiles: any;
  employersFetched: boolean;
  mutationTypesFetched: boolean;
  documentTypesFetched: boolean;
  storedValues: EintrittFormValues;
}

const getInitialState = () => {
  return {
    authentication: {
      login: null,
      token: localStorage.getItem("bearerToken"),
      expirationDate: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).expirationDate
        : null,
      fetchData: !!localStorage.getItem("bearerToken"),
    },
    userData: localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : {},
    // userData: null,
    sidebarOpened: false,
    entities: {},
    loading: false,
    employers: {},
    documentTypes: {},
    errors: [],
    cockpitFirmaFilter: "",
    popupIsOpen: false,
    mutationTypes: {},
    profiles: {},
    showValidations: false,
    fetchedEntities: false,

    employersFetched: false,
    mutationTypesFetched: false,
    documentTypesFetched: false,
    storedValues: undefined,
  };
};

// console.log("app.ts", getInitialState());

const app = (state: AppState = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.UPDATE_EXPIRATION: {
      let userData = window.localStorage.getItem("userData");
      let expiration = new Date();
      if (userData) {
        userData = JSON.parse(userData);
        expiration.setMinutes(
          expiration.getMinutes() + userData["sessionLifeTime"]
        );
        userData["expirationDate"] = expiration;
        window.localStorage.setItem("userData", JSON.stringify(userData));
      }
      return {
        ...state,
        authentication: {
          ...state.authentication,
          expirationDate: expiration,
        },
      };
    }

    case constants.SHOW_VALIDATIONS: {
      return {
        ...state,
        showValidations: action.payload,
      };
    }

    case constants.SET_FETCH_DATA: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          fetchData: action.payload,
        },
      };
    }

    case constants.FETCHED_EMPLOYERS: {
      // console.log("reducer FETCHED_EMPLOYERS", action);
      const employersObj = {};
      action.payload.forEach((employer) => {
        employersObj[employer.id] = employer;
      });
      return { ...state, employers: employersObj, employersFetched: true };
    }

    case constants.FETCHED_DOCUMENT_TYPES: {
      console.log("reducer FETCHED_DOCUMENT_TYPES", action);
      const documentTypesObj = {};
      action.payload.forEach((documentType) => {
        documentTypesObj[documentType["attributes"]["filterValue"]] =
          documentType;
      });
      return {
        ...state,
        documentTypes: documentTypesObj,
        documentTypesFetched: true,
      };
    }

    case constants.CREATED_INSURANT: {
      return state;
    }

    case constants.FETCHED_MUTATIONTYPES: {
      // console.log("reducer FETCHED_INSURANTS", action.payload);
      const mutationTypesObj = {};
      action.payload.forEach((mutationType) => {
        mutationTypesObj[mutationType.id] = mutationType;
      });
      return {
        ...state,
        mutationTypes: mutationTypesObj,
        mutationTypesFetched: true,
      };
    }

    case constants.FETCHED_PROFILES: {
      // console.log("reducer FETCHED_INSURANTS", action.payload);
      const profilesObj = {};
      action.payload.forEach((profile) => {
        profilesObj[profile.id] = profile;
      });
      return { ...state, profiles: profilesObj };
    }

    case constants.FETCHED_ENTITIES: {
      // console.log("reducer FETCHED_ENTITIES", action.payload);
      const entitiesObj = {};
      action.payload.forEach((entity) => {
        entitiesObj[entity.id] = entity.attributes;
      });
      return { ...state, entities: entitiesObj, fetchedEntities: true };
    }

    case constants.SIDEBAR_SET: {
      // console.log("reducer sidebarset", action);
      return { ...state, sidebarOpened: action.payload };
    }

    case constants.CLEAR_ENTRY_FORM_VALUE: {
      return {
        ...state,
        storedValues: action.payload,
      };
    }

    case constants.UPDATE_ENTRY_FORM_VALUES: {
      return {
        ...state,
        storedValues: { ...state.storedValues, ...action.payload },
      };
    }

    case constants.POPUP: {
      // console.log("reducer sidebarset", action);
      return { ...state, popupIsOpen: action.payload };
    }

    case constants.LOADING: {
      // console.log("reducer loading", action);
      return { ...state, loading: action.payload };
    }

    case constants.LOGIN: {
      // console.log("reducer login start", action.payload);
      return {
        ...state,
        authentication: {
          name: action.payload.login,
          rememberMe: action.payload.rememberMe,
          token: action.payload.token,
          step: action.payload.step,
        },
      };
    }

    case constants.LOGIN_SUCCESS: {
      const userData = { ...action.payload };
      let expiration = new Date();
      expiration.setMinutes(expiration.getMinutes() + userData.sessionLifeTime);
      userData.expirationDate = expiration;
      action.payload.expirationDate = expiration;

      const newAuthentication = {
        ...state.authentication,
        token: action.payload.bearerToken,
        step: action.payload.step,
        expirationDate: action.payload.expirationDate,
        fetchData: true,
      };
      delete userData["bearerToken"];
      delete userData["authenticationStatus"];
      window.localStorage.setItem("userData", JSON.stringify(userData));
      return {
        ...getInitialState(),
        authentication: newAuthentication,
        userData: userData,
      };
    }

    case constants.LOG_OUT: {
      console.log("reducer logout", action);
      localStorage.removeItem("bearerToken");
      localStorage.removeItem("userData");
      // return { ...state, authentication: {login: null, token: null, expirationDate: null}, userData: null };
      return { ...getInitialState() };
    }

    case constants.LOGIN_ERROR: {
      // @TODO better handle state change when error
      console.log("a login error occurred: ", action);
      return {
        ...state,
        authentication: getInitialState().authentication,
        errors: [action.error],
        userData: null,
      };
    }

    case constants.FIRMAFILTER_SET: {
      return { ...state, cockpitFirmaFilter: action.payload };
    }
  }

  return state;
};

export default app;
