
// enum ContributionCoverage {
//     0 = keine,
//     1 = spar risiko employee,
//     2 = spar risiko employee und employer,
//     3 = risiko employee,
//     4 = risiko employee und employer
// }

export enum ContributionCoverage {
    Imported = 0,
    Changed = 1,
    Commited = 2,
    Exported = 3,
    Locked = 4,
}

export enum ProcessingStates {
    Imported = 0,
    Changed = 1,
    Committed = 2,
    Exported = 3,
    Locked = 4,
}

export enum InvoiceStates {
    "Offen"=  0,
    "Überfällig" = 1,
    "Erledigt" = 2,
    "Bezahlt" = 2,
}

export enum CivilStatusCodes {
    None = 0,
    Single = 1,
    Married = 2,
    Divorced = 3,
    Widowed = 4,
    Separated = 5,
    RegisteredPartnership = 6,
    Concubinage = 7,
    SolvedPartnership = 8,
    Unknown = 9,
}

// values: {0: "None", 1: "Male", 2: "Female", 3: "Undefined"}
// 0: "None"
// 1: "Male"
// 2: "Female"
// 3: "Undefined"
// id: "SexCodes"
//
// values: {0: "Termination", 1: "Illness"}
// 0: "Termination"
// 1: "Illness"
// id: "ReasonsForLeaving"
//
// values: {0: "None", 1: "German", 2: "French", 3: "Italian", 4: "English"}
// 0: "None"
// 1: "German"
// 2: "French"
// 3: "Italian"
// 4: "English"
// id: "LanguageCodes"
//
// values: {0: "OffenEnum", 1: "UberfalligEnum", 2: "ErledigtEnum"}
// 0: "OffenEnum"
// 1: "UberfalligEnum"
// 2: "ErledigtEnum"
// id: "InvoiceStates"
//
// values: {0: "None", 1: "Employee", 2: "Employer"}
// 0: "None"
// 1: "Employee"
// 2: "Employer"
// id: "ContributionCoverage"
//
