import * as React from "react";
import classNames from "classnames";

import { defined } from "../../utils/variableEvaluation";
import styles from "./InputWrapper.module.scss";
import { ValidationMessageProps } from "../ValidationMessage";

interface IProps {
  inputWrapperStyle: React.CSSProperties;
  inputWrapperClass: string;
  validationMessage: ValidationMessageProps;
  validationVisible: boolean;
  hidden?: boolean;
}

export class InputWrapper extends React.Component<IProps, {}> {
  public render() {
    const {
      inputWrapperStyle,
      validationMessage,
      validationVisible,
      children,
    } = this.props;
    const validationClass =
      validationMessage && validationMessage.config?.type && validationVisible
        ? validationMessage.config.type
        : null;

    return (
      <div
        className={classNames(
          styles.inputWrapper,
          this.props.inputWrapperClass,
          styles[validationClass]
        )}
        style={defined(inputWrapperStyle) ? inputWrapperStyle : {}}
        hidden={this.props.hidden}
      >
        {children}
      </div>
    );
  }
}
