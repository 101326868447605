import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import FormAddress from "../../../Forms/FormAddress";
import styles from "./NewAddressModalContent.module.scss";
import { Blank, ExpandableRow } from "icr-shared-components";
import AddressExpandableRowTop from "./AddressExpandableRowTop";

interface Props {
  payrolls: any;
  intl: IntlShape;
  initialData: any;
  disabled: boolean;
  submitAction: (event, value) => void;
  history: any;
  insurantMutations: any;

  addresses: any;
  onDeleteClick: (value) => void;
  onEditClick: (value) => void;
}

function NewAddressModalContent({
  addresses,
  onDeleteClick,
  onEditClick,
  intl,
  ...props
}: Props) {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles["address-title"]}>
        <FormattedMessage id="app.newaddress" defaultMessage="Neue Adresse" />
      </h1>

      <div className={styles["form-wrapper"]}>
        <FormAddress {...props} />
      </div>

      <h1 className={styles["course-title"]}>
        <FormattedMessage
          id="app.modals.title.newPayroll.course"
          defaultMessage="Verlauf"
        />
      </h1>

      <div>
        <div className={styles["list-header"]}>
          <div className={styles.column}>
            <FormattedMessage id="app.validFrom" defaultMessage="Gültig ab" />
          </div>
          <div className={styles.column}>
            <FormattedMessage id="app.validUntil" defaultMessage="Gültig bis" />
          </div>
          <div className={styles.column}>
            <FormattedMessage
              id="app.row.address1"
              defaultMessage="Adresse 1"
            />
          </div>
          <div className={styles.column}>
            <FormattedMessage
              id="app.row.address2"
              defaultMessage="Adresse 2"
            />
          </div>
          <div className={styles.column}>
            <FormattedMessage id="app.city" defaultMessage="Ort" />
          </div>
          <div className={styles.column}>
            <FormattedMessage id="app.land" defaultMessage="Land" />
          </div>
          <div className={styles.column}>
            <div style={{ width: "7rem" }} />
          </div>
        </div>

        {addresses.length ? (
          addresses.map((address) => (
            <ExpandableRow
              className="mb-24"
              content={
                <AddressExpandableRowTop
                  address={address}
                  onEditClick={() => onEditClick(address)}
                  onDeleteClick={() => onDeleteClick(address)}
                />
              }
            />
          ))
        ) : (
          <Blank
            text={intl.formatMessage({
              id: "app.noaddresses",
              defaultMessage: "Keine Adressen vorhanden",
            })}
          />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {};
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(NewAddressModalContent));
