import * as constants from '../../constants/pensionskasse';
import {LOG_OUT} from "../../constants";

export interface CompaniesStatePK {
    companies: any;
    companiesFetched: boolean;
    companiesPageNo: number;
    moreCompanies: boolean;
    companiesNameFilter: string;
    companyInvoices: Array<any>;
}

const getInitialState = () => {
    return {
        companies: [],
        companiesFetched: false,
        companiesPageNo: 0,
        moreCompanies: true,
        companiesNameFilter: "",
        companyInvoices: []
    }
};

// console.log("app.ts", getInitialState());

const companies = (state: CompaniesStatePK = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.FETCHED_COMPANIES: {
            const companies = state.companiesPageNo == 0 ? [ ...action.payload ] : [ ...state.companies, ...action.payload ];

            return {
                ...state,
                companies: companies,
                companiesFetched: true
            }
        }

        case constants.FETCHED_COMPANY_INVOICES: {
            const companyInvoices = action.payload;

            return {...state, companyInvoices: companyInvoices}
        }

        case constants.SET_COMPANIES_PAGE_NO: {
            let moreCompanies = !(state.companies.length < ((state.companiesPageNo + 1) * constants.PAGINAION_SIZE));
            return { ...state, companiesPageNo: action.payload, moreCompanies: moreCompanies}
        }

        case constants.SET_COMPANIES_FILTERING: {
            switch(action.payload.name) {
                case "companiesNameFilter":
                    return { ...state, companiesNameFilter: action.payload.value };
                default: {
                    return {
                        ...state,
                        companiesNameFilter: "",
                    }
                }
            }
        }

        case LOG_OUT: {
            return { ...getInitialState() };
        }

    }

    return state;
};

export default companies;
