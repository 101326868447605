import * as React from "react";
import { injectIntl } from "react-intl";
import { RootState } from "../../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../../actions";
import {
  fetchAcceptedMutations,
  fetchDeletedMutations,
  fetchForReviewMutations,
  setAcceptedMutationsFilter,
  setAcceptedMutationsPageNo,
  setDeletedMutationsFilter,
  setDeletedMutationsPageNo,
  setForReviewMutationsFilter,
  setForReviewMutationsPageNo,
} from "../../../../actions/pensionskasse/mutations";
import { connect } from "react-redux";
import { isDate } from "../../../../utils/validationTests";
import SidebarFilterForm, {
  AcceptedSideBarFilterNames,
  DeletedSideBarFilterNames,
  ForReviewSideBarFilterNames,
} from "../SidebarFilterForm";

interface State {}

interface Props {
  intl: any;
  match: any;
  history: any;
  insurants: any;
  employers: any;
  mutationTypes: any;
  setLoading: any;
  firmaOptions: any;
  mutationTypeOptions: any;
  acceptedMutations: any;
  fetchForReviewMutations: any;
  fetchAcceptedMutations: any;
  fetchDeletedMutations: any;
  deletedMutationsFetched: boolean;
  setDeletedMutationsPageNo: any;
  deletedMutationsPageNo: any;
  acceptedMutationsFetched: boolean;
  setAcceptedMutationsPageNo: any;
  acceptedMutationsPageNo: any;
  forReviewMutationsPageNo: any;
  setForReviewMutationsPageNo: any;
  setAcceptedMutationsFilter: any;
  setDeletedMutationsFilter: any;
  setForReviewMutationsFilter: any;
  acceptedMutationsNameFilter: any;
  acceptedMutationsEventFilter: any;
  acceptedMutationsFirmaFilter: any;
  acceptedMutationsTakeoverDateFrom: any;
  acceptedMutationsTakeoverDateUntil: any;
  acceptedMutationsMutationDateFrom: any;
  acceptedMutationsMutationDateUntil: any;
}

class AcceptedSidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  private debounce = null;

  filterAcceptedMutations = (name, value, date?, employers?) => {
    this.props.setAcceptedMutationsFilter({ name, value });
    if (name === "clear") {
      this.props.setForReviewMutationsFilter({ name, value });
      this.props.setDeletedMutationsFilter({ name, value });
    }
    if (name === AcceptedSideBarFilterNames.acceptedMutationsNameFilter) {
      this.props.setForReviewMutationsFilter({
        name: ForReviewSideBarFilterNames.forReviewMutationsNameFilter,
        value,
      });
      this.props.setDeletedMutationsFilter({
        name: DeletedSideBarFilterNames.deletedMutationsNameFilter,
        value,
      });
    } else if (
      name === AcceptedSideBarFilterNames.acceptedMutationsFirmaFilter
    ) {
      this.props.setForReviewMutationsFilter({
        name: ForReviewSideBarFilterNames.forReviewMutationsFirmaFilter,
        value,
      });
      this.props.setDeletedMutationsFilter({
        name: DeletedSideBarFilterNames.deletedMutationsFirmaFilter,
        value,
      });
    } else if (
      name === AcceptedSideBarFilterNames.acceptedMutationsEventFilter
    ) {
      this.props.setForReviewMutationsFilter({
        name: ForReviewSideBarFilterNames.forReviewMutationsEventFilter,
        value,
      });
      this.props.setDeletedMutationsFilter({
        name: DeletedSideBarFilterNames.deletedMutationsEventFilter,
        value,
      });
    }

    if (employers === true && value !== "") {
      return;
    }

    if (date) {
      if (!isDate(value) || this.props[name] == value) {
        return;
      }
    }

    this.props.setAcceptedMutationsPageNo(0);
    if (
      name === AcceptedSideBarFilterNames.acceptedMutationsNameFilter ||
      name === AcceptedSideBarFilterNames.acceptedMutationsFirmaFilter ||
      name === AcceptedSideBarFilterNames.acceptedMutationsEventFilter ||
      name === "clear"
    ) {
      this.props.setDeletedMutationsPageNo(0);
      this.props.setForReviewMutationsPageNo(0);
    }

    this.props.setLoading(true);
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.props.fetchAcceptedMutations().then(() => {
        this.props.setLoading(false);
        this.props.setAcceptedMutationsPageNo(
          this.props.acceptedMutationsPageNo + 1
        );
      });
      if (
        name === AcceptedSideBarFilterNames.acceptedMutationsNameFilter ||
        name === AcceptedSideBarFilterNames.acceptedMutationsFirmaFilter ||
        name === AcceptedSideBarFilterNames.acceptedMutationsEventFilter ||
        name === "clear"
      ) {
        this.props.fetchForReviewMutations().then(() => {
          this.props.setLoading(false);
          this.props.setForReviewMutationsPageNo(
            this.props.forReviewMutationsPageNo + 1
          );
        });
        this.props.fetchDeletedMutations().then(() => {
          this.props.setLoading(false);
          this.props.setDeletedMutationsPageNo(
            this.props.deletedMutationsPageNo + 1
          );
        });
      }
    }, 500);
  };

  render() {
    const { employers, mutationTypes } = this.props;
    return (
      <div className="sidebar-container">
        <SidebarFilterForm
          employers={employers}
          mutationTypes={mutationTypes}
          mutationsEventFilter={this.props.acceptedMutationsEventFilter}
          isForReviewSideBar={false}
          nameInputFilter="acceptedMutationsNameFilter"
          filterInputValue={this.props.acceptedMutationsNameFilter}
          filterFunction={this.filterAcceptedMutations}
          mutationTypeInputName="acceptedMutationsEventFilter"
          firmInputId="filterTypeInbox"
          firmInputName="acceptedMutationsFirmaFilter"
          firmInputKey="filterTypeInbox"
          firmInputValue={
            this.props.employers[this.props.acceptedMutationsFirmaFilter]
              ? this.props.employers[this.props.acceptedMutationsFirmaFilter][
                  "attributes"
                ]["name"]
              : this.props.acceptedMutationsFirmaFilter
          }
          inputNameMutationsTakeoverDateFrom="acceptedMutationsTakeoverDateFrom"
          inputNameMutationsTakeoverDateUntil="acceptedMutationsTakeoverDateUntil"
          inputNameMutationsDateFrom="acceptedMutationsMutationDateFrom"
          inputNameMutationsDateUtil="acceptedMutationsMutationDateUntil"
          mutationsTakeOverDateFrom={
            this.props.acceptedMutationsTakeoverDateFrom
          }
          mutationsTakeOverDateUntil={
            this.props.acceptedMutationsTakeoverDateUntil
          }
          mutationDateFrom={this.props.acceptedMutationsMutationDateFrom}
          mutationDateUntil={this.props.acceptedMutationsMutationDateUntil}
        />
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    mutationTypes: state.app.mutationTypes,

    acceptedMutations: state.acceptedMutations.acceptedMutations,
    acceptedMutationsFetched: state.acceptedMutations.acceptedMutationsFetched,
    acceptedMutationsPageNo: state.acceptedMutations.acceptedMutationsPageNo,

    acceptedMutationsNameFilter:
      state.acceptedMutations.acceptedMutationsNameFilter,
    acceptedMutationsEventFilter:
      state.acceptedMutations.acceptedMutationsEventFilter,
    acceptedMutationsFirmaFilter:
      state.acceptedMutations.acceptedMutationsFirmaFilter,
    acceptedMutationsTakeoverDateFrom:
      state.acceptedMutations.acceptedMutationsTakeoverDateFrom,
    acceptedMutationsTakeoverDateUntil:
      state.acceptedMutations.acceptedMutationsTakeoverDateUntil,
    acceptedMutationsMutationDateFrom:
      state.acceptedMutations.acceptedMutationsMutationDateFrom,
    acceptedMutationsMutationDateUntil:
      state.acceptedMutations.acceptedMutationsMutationDateUntil,
    forReviewMutationsPageNo: state.forReviewMutations.forReviewMutationsPageNo,
    deletedMutationsPageNo: state.deletedMutations.deletedMutationsPageNo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    fetchAcceptedMutations: bindActionCreators(
      fetchAcceptedMutations,
      dispatch
    ),
    setDeletedMutationsPageNo: (val) =>
      dispatch(setDeletedMutationsPageNo(val)),
    setForReviewMutationsPageNo: (val) =>
      dispatch(setForReviewMutationsPageNo(val)),
    setAcceptedMutationsPageNo: (val) =>
      dispatch(setAcceptedMutationsPageNo(val)),
    setAcceptedMutationsFilter: (val) =>
      dispatch(setAcceptedMutationsFilter(val)),
    setDeletedMutationsFilter: (val) =>
      dispatch(setDeletedMutationsFilter(val)),
    setForReviewMutationsFilter: (val) =>
      dispatch(setForReviewMutationsFilter(val)),
    fetchForReviewMutations: bindActionCreators(
      fetchForReviewMutations,
      dispatch
    ),
    fetchDeletedMutations: bindActionCreators(fetchDeletedMutations, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AcceptedSidebar));
