import React, {useEffect} from "react";
import {isTokenExpired, setBodyClassNames} from "../../utils/react";
import {matchPath} from "react-router";
import Sidebar from "react-sidebar";
import {ReactComponent as IconClose} from "../../assets/icons/close.svg";
import {ReactComponent as IconHamburger} from "../../assets/icons/hamburger.svg";
import {Link, withRouter, RouteComponentProps} from "react-router-dom";
import {ReactComponent as IconCockpit} from "../../assets/icons/cockpit.svg";
import {ReactComponent as IconProfile} from "../../assets/icons/profile-icon.svg";
import {ReactComponent as IconFileTransfer} from "../../assets/icons/file-transfer.svg";
import {ReactComponent as IconMutationen} from "../../assets/icons/mutationen_icon.svg";
import {ReactComponent as IconVersicherte} from "../../assets/icons/users-dark.svg";
import {ReactComponent as IconCompanies} from "../../assets/icons/firmen.svg";
import {ReactComponent as IconInvoices} from "../../assets/icons/card.svg";
import styles from "./Sidebar.module.scss";
import "./Icons.scss";
import classNames from "classnames";


interface Props extends RouteComponentProps {
    location: any;
    authentication: any;
    sidebarOpened: any;
    hasInvoices: any;
    setSidebar: any;
}

const Sidebars: React.FC<Props> = ({ authentication, sidebarOpened, hasInvoices, location, setSidebar }) => {

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        }
    }, []);

    const handleDocumentClick = (e) => {
        const sidebarContainer = document.querySelector(".sidebar-container");

        console.log(e.target.classList)
        const sidebar = e.target.classList.contains("sidebar-container");
        const hamburger = e.target.classList.contains("hamburger-button");
        const link = e.target.classList.contains("sidebar-link");

        if(!sidebarContainer?.contains(e.target) && !sidebar && !hamburger && !link) {
            console.log("close");
            console.log(sidebarOpened);
                setSidebar(false);
        }
    }

    const isPKCockpitPathActive = !!matchPath(
        location.pathname,
        '/pensionskasse/'
    );

    const handleClick = (e) => {
        console.log(e.target);
        setBodyClassNames(false);
        setSidebar(false);
        e.target && e.target.blur();
    }

    const onClickHamburgerButton = (e) => {
        e.stopPropagation();
        setBodyClassNames(!sidebarOpened);
        setSidebar(!sidebarOpened);
    }

    const employerLinks = [
        {text: "Cockpit", icon: <IconCockpit />, to: "/cockpit", classname: "cockpit-link", match: "/cockpit"},
        {text: "Versicherte", icon: <IconVersicherte />, to: "/versicherte/personen", classname: "personen-link", match: "/versicherte"},
        {text: "Rechnungen", icon: <IconInvoices />, to: "/rechnungen", classname: "invoices-link", match: "/rechnungen", visible: hasInvoices},
        {text: "File-Transfer", icon: <IconFileTransfer />, to: "/filetransfer/empfangen", classname: "filetransfer-link", match: "/filetransfer"},
    ];

    const pkCockpitLinks = [
        {text: "Cockpit", icon: <IconCockpit />, to: "/pensionskasse/cockpit", classname: "cockpit-link", match: "/pensionskasse/cockpit"},
        {text: "Mutationen", icon: <IconMutationen />, to: "/pensionskasse/mutationen/uberprufung-erforderlich", classname: "mutations-link", match: "/pensionskasse/mutationen"},
        {text: "Versicherte", icon: <IconVersicherte />, to: "/pensionskasse/versicherte/personen", classname: "personen-link", match: "/pensionskasse/versicherte"},
        {text: "Firmen", icon: <IconCompanies />, to: "/pensionskasse/firmen", classname: "companies-link", match: "/pensionskasse/firmen"},
        {text: "Rechnungen", icon: <IconInvoices />, to: "/pensionskasse/rechnungen", classname: "invoices-link", match: "/pensionskasse/rechnungen", visible: hasInvoices},
        {text: "File-Transfer", icon: <IconFileTransfer />, to: "/pensionskasse/filetransfer/empfangen", classname: "filetransfer-link", match: "/pensionskasse/filetransfer"},
    ];

    const links = isPKCockpitPathActive ? pkCockpitLinks : employerLinks;

    const isActive = (match) => {
        return location.pathname.startsWith(match);
    }

    return (
        !isTokenExpired(authentication.token, authentication.expirationDate, authentication.step) &&
        <Sidebar
            rootClassName={styles.sidebarRoot}
            sidebarClassName={styles.sidebar}
            contentClassName="sidebar-content"
            overlayClassName={styles.sidebarOverlay}
            open={sidebarOpened}
            sidebar={<div />}
            docked={false}
            onSetOpen={(opened) => {setBodyClassNames(opened);setSidebar(opened)}}>

            <div className={classNames(styles.sidebarContainer, "sidebar-container")}>

                <button onClick={onClickHamburgerButton} className={classNames(styles.hamburger, "hamburger-button")}>
                    {sidebarOpened ? <IconClose/> : <IconHamburger/>}
                </button>

                <div className={styles.sidebarLinks}>

                    {links.map((link, index) => (
                        (link.visible !== false) &&
                        <Link className={classNames(styles.sidebarLink, `sidebar-link ${link.classname}`, {active: isActive(link.match)})}
                              to={link.to}
                              onClick={handleClick}
                              key={`route-link-${index}`}>
                            {link.icon}
                            <p>{link.text}</p>
                        </Link>
                    ))}

                    <button className={classNames(styles.sidebarLink, styles.mobileLink,  "sidebar-link profile-link profileTrigger")}
                            onClick={(e) => handleClick(e)}>
                            <IconProfile />
                            <p>Profile</p>
                    </button>

                </div>
                <br/>
            </div>
        </Sidebar>
    )
}

export default withRouter(Sidebars);
