import * as React from "react";
import { Component } from "react";
import {
  Blank,
  ExpandableRow,
  GroupPositions,
  StatusColors,
  StatusShape,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import MutationExpandableRowTop from "../MutationExpandableRowTop";
import styles from "./ForReviewTab.module.scss";
import MutationExpandableRowBottom from "../MutationExpandableRowBottom";
import { modalPageTypes } from "../../../../constants";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../../actions";
import { setLoading } from "../../../../actions";
import {
  fetchForReviewMutations,
  fetchForReviewMutationsProgress,
  postDeleteMutation,
  processMutation,
  setForReviewMutationsFilter,
  setForReviewMutationsPageNo,
  removeMutationManualCommit,
} from "../../../../actions/pensionskasse/mutations";
import { connect } from "react-redux";
import classNames from "classnames";
import { RootState } from "../../../../reducers";
import ProcessingMutations from "../../../ProcessingMutations";
import MutationExpandableRowErrorBottom from "../MutationExpandableRowErrorBottom";
import { processInsurant } from "../../../../actions/arbeitgeber/insurants";

interface State {
  expandedRow: any;
  expandedRowShowMore: any;
  filtersChanged: number;
}

interface Props extends RouteComponentProps {
  intl: any;
  history: any;
  location: any;
  setLoading: any;
  setAppLoading: any;
  fetched: boolean;
  mutations: any;
  expandedRow: any;
  refreshData: any;
  filtersChanged: any;
  postDeleteMutation: any;
  processMutation: any;
  processInsurant: any;
  morePadding: boolean;
  employers: any;

  onPdfClick: any;

  forReviewMutationsPageNo: any;
  forReviewMutationsFetched: boolean;
  fetchForReviewMutations: any;
  fetchForReviewMutationsProgress: any;
  setForReviewMutationsPageNo: any;
  setForReviewMutationsFilter: any;

  forReviewMutationsProgress: any;

  forReviewMutationsNameFilter: any;
  forReviewMutationsEventFilter: any;
  forReviewMutationsFirmaFilter: any;
  forReviewMutationsOnlyErrorsFilter: any;
  forReviewMutationsOnlyWarningsFilter: any;
  forReviewMutationsNoWarningsErrorsFilter: any;
  removeMutationManualCommit: any;
}

class ForReviewTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expandedRow: null,
      expandedRowShowMore: null,
      filtersChanged: 0,
    };
  }

  onDeleteClick = (mutation, text) => {
    const payload = {
      data: {
        ...mutation,
        attributes: {
          ...mutation.attributes,
          reasonForDeletion: text,
        },
      },
    };
    this.props.setAppLoading(true);
    this.props.postDeleteMutation(payload).then((res) => {
      this.props.setAppLoading(false);
      this.props.refreshData();
      this.setState({ expandedRow: null });
    });
  };

  onDetailsClick = (insurantId) => {
    this.props.history.push(
      `/pensionskasse/versicherte/personen?edit=${insurantId}&content=${modalPageTypes.INSURANT_DETAILS_PK}&origin=${this.props.location.pathname}`
    );
  };

  onCommitMutationClick = (mutationId, skipProcessing?) => {
    const payload = {
      data: { type: "Mutation", id: mutationId },
    };
    this.props.setAppLoading(true);
    this.setState({
      expandedRow: null,
      expandedRowShowMore: null,
      filtersChanged: this.state.filtersChanged + 1,
    });
    this.props.processMutation(payload, skipProcessing).then((res) => {
      this.props.setAppLoading(false);
      this.props.refreshData();
    });
  };

  onCommitClick = (insurantId) => {
    const payload = {
      data: { type: "insurant", id: insurantId },
    };
    this.props.setAppLoading(true);
    this.setState({
      expandedRow: null,
      expandedRowShowMore: null,
      filtersChanged: this.state.filtersChanged + 1,
    });
    this.props.processInsurant(payload).then((res) => {
      this.props.setAppLoading(false);
      this.props.refreshData();
    });
  };

  determineGroupPosition = (mutations, index) => {
    if (!mutations || !mutations.length || mutations.length == 1) return null;
    const length = mutations.length;
    if (index == 0) {
      return GroupPositions.Top;
    } else if (index == length - 1) {
      return GroupPositions.Bottom;
    } else {
      return GroupPositions.Middle;
    }
  };

  clearFilters = () => {
    this.props.setForReviewMutationsPageNo(0);
    this.props.setForReviewMutationsFilter({ name: "clear" });
    this.props.setLoading(true);
    this.props.fetchForReviewMutations().then(() => {
      this.props.setLoading(false);
      this.props.setForReviewMutationsPageNo(1);
    });
  };

  filtersAreApplied = () => {
    return !!(
      this.props.forReviewMutationsNameFilter ||
      this.props.forReviewMutationsEventFilter ||
      this.props.forReviewMutationsFirmaFilter ||
      this.props.forReviewMutationsOnlyErrorsFilter ||
      this.props.forReviewMutationsOnlyWarningsFilter ||
      this.props.forReviewMutationsNoWarningsErrorsFilter
    );
  };

  remainingMutations = (employer) => {
    const { forReviewMutationsProgress } = this.props;
    if (!forReviewMutationsProgress.length) {
      return 0;
    }
    const progressOfEmployer = forReviewMutationsProgress.find(
      (progress) => progress.attributes.employerId == employer.id
    );
    if (progressOfEmployer) {
      return progressOfEmployer.attributes.remaining;
    }
    return 0;
  };

  groupByRemainingMutations = (employers) => {
    const { forReviewMutationsProgress } = this.props;
    const employersWithRemainingMutations = employers.filter((employer) => {
      return !!forReviewMutationsProgress.find(
        (progress) => progress.attributes.employerId == employer.id
      );
    });
    const employersWithNoRemainingMutations = employers.filter((employer) => {
      return (
        !!!forReviewMutationsProgress.find(
          (progress) => progress.attributes.employerId == employer.id
        ) && employer.employees.length
      );
    });
    return [
      ...employersWithNoRemainingMutations,
      ...employersWithRemainingMutations,
    ];
  };

  validateAustrittWith58Years = (mutation) => {
    let warning = null;
    if (mutation.attributes?.mutationType?.name === "Austritt") {
      const continuedInsuranceAge =
        this.props.employers[mutation.attributes?.employerId]?.attributes
          ?.continuedInsuranceAge || 58;
      const date = new Date(mutation.attributes?.keyDate);
      const birthDate = new Date(mutation.attributes?.birthDate);

      const age = date.getFullYear() - birthDate.getFullYear();
      if (
        age &&
        mutation.attributes?.values?.employee?.reasonForLeaving === 0 &&
        age >= continuedInsuranceAge
      ) {
        warning = `Der Versicherte ist über ${continuedInsuranceAge} alt.`;
      }
    }

    return warning;
  };

  statusBarProps = (mutation, groupPosition) => {
    const isAustrittWith58Years = this.validateAustrittWith58Years(mutation);

    if (mutation.attributes.warning || isAustrittWith58Years) {
      return {
        text:
          (mutation.attributes.warning
            ? `${mutation.attributes.warning} ${isAustrittWith58Years ?? ""}`
            : isAustrittWith58Years) || "Warning",
        color: StatusColors.WARNING,
        shape:
          groupPosition == GroupPositions.Bottom || !groupPosition
            ? StatusShape.BOTTOM_ROUNDED
            : StatusShape.SQUARE,
      };
    } else if (mutation.attributes.hasError && mutation.attributes.errorText) {
      return {
        text: mutation.attributes.errorText,
        color: StatusColors.ERROR,
        shape:
          groupPosition == GroupPositions.Bottom || !groupPosition
            ? StatusShape.BOTTOM_ROUNDED
            : StatusShape.SQUARE,
      };
    } else {
      return null;
    }
  };

  render() {
    const groupByRemainingMutations = this.groupByRemainingMutations(
      this.props.mutations
    );
    return (
      <>
        <h1 className={styles.pageTitle}>Zu überprüfende Mutationen</h1>
        {!!(
          this.props.forReviewMutationsFetched ||
          groupByRemainingMutations.length
        ) && (
          <div
            className={classNames(styles.listView, {
              [styles.morePadding]: this.props.morePadding,
            })}
          >
            {this.props.forReviewMutationsFetched && (
              <div className={styles.listHeader}>
                <div className={styles.column}>
                  <FormattedMessage
                    id="app.type"
                    defaultMessage="Mutationstyp / Name"
                  />
                </div>
                <div className={styles.column}>
                  <FormattedMessage
                    id="app.validFrom"
                    defaultMessage="Gültig ab"
                  />
                </div>
                <div className={styles.column}>
                  <span>
                    <FormattedMessage
                      id="app.pervValue"
                      defaultMessage="bisher"
                    />
                  </span>
                  <span>
                    <FormattedMessage id="app.new" defaultMessage="neu" />
                  </span>
                </div>
                <div className={styles.column}>
                  <FormattedMessage
                    id="app.source"
                    defaultMessage="Mutationsdatum / Bearbeiter"
                  />
                </div>
                <div className={styles.column}>
                  <div style={{ width: "3rem" }} />
                </div>
              </div>
            )}
            {groupByRemainingMutations.length ? (
              groupByRemainingMutations.map((company) => {
                const remainingMutations = this.remainingMutations(company);
                return (
                  !!(company.employees.length || remainingMutations) && (
                    <>
                      <div className={styles.groupHeader}>
                        {company.name}

                        {!!remainingMutations && (
                          <ProcessingMutations count={remainingMutations} />
                        )}
                      </div>
                      {company.employees.map((employee) =>
                        employee.mutations.map((mutation, index) => {
                          const groupPosition = this.determineGroupPosition(
                            employee.mutations,
                            index
                          );
                          return (
                            <ExpandableRow
                              key={`expandable-row-mutation-${mutation.id}-${index}`}
                              ref={`expandableRowRef-${mutation.id}`}
                              expandable
                              hasNoArrow
                              rowChanged={(isErrorMoreContentOpen) => {
                                this.setState({
                                  expandedRow: isErrorMoreContentOpen
                                    ? null
                                    : !this.state.expandedRow
                                    ? null
                                    : mutation.id,
                                });
                              }}
                              groupPosition={groupPosition}
                              isExpanded={this.state.expandedRow == mutation.id}
                              length={this.props.filtersChanged}
                              className={classNames({
                                "mb-24":
                                  groupPosition == GroupPositions.Bottom ||
                                  !groupPosition,
                              })}
                              statusBar={this.statusBarProps(
                                mutation,
                                groupPosition
                              )}
                              errorMoreContent={
                                employee.mutations.length === index + 1 ? (
                                  <MutationExpandableRowErrorBottom
                                    canArchiveAutomatically={
                                      mutation.attributes
                                        .canArchiveAutomatically
                                    }
                                    canArchiveManually={
                                      mutation.attributes.canArchiveManually
                                    }
                                    hasWarning={mutation.attributes.warning}
                                    onSubmit={(skipProcessing) =>
                                      this.onCommitMutationClick(
                                        mutation.id,
                                        skipProcessing
                                      )
                                    }
                                  />
                                ) : null
                              }
                              more={
                                <MutationExpandableRowBottom
                                  onSubmit={(text) =>
                                    this.onDeleteClick(mutation, text)
                                  }
                                  onCancel={() =>
                                    this.setState({
                                      expandedRow: null,
                                      filtersChanged:
                                        this.state.filtersChanged + 1,
                                    })
                                  }
                                />
                              }
                              content={
                                <MutationExpandableRowTop
                                  employer={company.name}
                                  mutation={mutation}
                                  groupPosition={groupPosition}
                                  deleteButtonDisabled={
                                    groupPosition != GroupPositions.Top &&
                                    groupPosition != null
                                  }
                                  expanded={
                                    mutation.id ==
                                    this.state.expandedRowShowMore
                                  } //the row is expanded if the expandedRow == index
                                  onCommitClick={() =>
                                    this.onCommitClick(
                                      mutation.attributes.employeeId
                                    )
                                  }
                                  onDetailsClick={() =>
                                    this.onDetailsClick(
                                      mutation.attributes.employeeId
                                    )
                                  }
                                  onPdfClick={(link) =>
                                    this.props.onPdfClick(link)
                                  }
                                  onDeleteClick={() => {
                                    const expandableRowRef = this.refs[
                                      `expandableRowRef-${mutation.id}`
                                    ] as any;
                                    if (expandableRowRef.state.expandedError) {
                                      expandableRowRef.handleErrorClick();
                                      expandableRowRef.handleClick();
                                    }
                                    this.setState({
                                      expandedRow:
                                        this.state.expandedRow == mutation.id
                                          ? null
                                          : mutation.id,
                                      filtersChanged:
                                        this.state.filtersChanged + 1,
                                    });
                                  }}
                                  toggleExpand={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      expandedRowShowMore:
                                        this.state.expandedRowShowMore ==
                                        mutation.id
                                          ? null
                                          : mutation.id,
                                    });
                                  }}
                                />
                              }
                            />
                          );
                        })
                      )}
                    </>
                  )
                );
              })
            ) : (
              <Blank
                onButtonClick={this.clearFilters}
                // buttonText="Filter zurücksetzen"
                showButton={this.filtersAreApplied()}
                text={this.props.intl.formatMessage({
                  id: "app.nomutations",
                  defaultMessage: "Keine Mutationen vorhanden",
                })}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
  postDeleteMutation: bindActionCreators(postDeleteMutation, dispatch),
  processMutation: bindActionCreators(processMutation, dispatch),
  processInsurant: bindActionCreators(processInsurant, dispatch),
  fetchForReviewMutations: bindActionCreators(
    fetchForReviewMutations,
    dispatch
  ),
  fetchForReviewMutationsProgress: bindActionCreators(
    fetchForReviewMutationsProgress,
    dispatch
  ),
  setAppLoading: (val) => dispatch(setLoading(val)),
  setForReviewMutationsPageNo: (val) =>
    dispatch(setForReviewMutationsPageNo(val)),
  setForReviewMutationsFilter: (val) =>
    dispatch(setForReviewMutationsFilter(val)),
  removeMutationManualCommit: bindActionCreators(
    removeMutationManualCommit,
    dispatch
  ),
});
const mapStateToProps = (state: RootState, ownProps: any) => ({
  ...ownProps,
  employers: state.app.employers,
  forReviewMutationsPageNo: state.forReviewMutations.forReviewMutationsPageNo,
  forReviewMutationsProgress:
    state.forReviewMutations.forReviewMutationsProgress,
  forReviewMutationsFetched: state.forReviewMutations.forReviewMutationsFetched,
  forReviewMutationsNameFilter:
    state.forReviewMutations.forReviewMutationsNameFilter,
  forReviewMutationsEventFilter:
    state.forReviewMutations.forReviewMutationsEventFilter,
  forReviewMutationsFirmaFilter:
    state.forReviewMutations.forReviewMutationsFirmaFilter,
  forReviewMutationsOnlyErrorsFilter:
    state.forReviewMutations.forReviewMutationsOnlyErrorsFilter,
  forReviewMutationsOnlyWarningsFilter:
    state.forReviewMutations.forReviewMutationsOnlyWarningsFilter,
  forReviewMutationsNoWarningsErrorsFilter:
    state.forReviewMutations.forReviewMutationsNoWarningsErrorsFilter,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(ForReviewTab)));
