import * as React from 'react';
import {Blank, Button, ButtonColors, ButtonTypes, ExpandableRow, StatusColors, StatusShape} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";

import InsurantDetailsRowTop from "../InsurantDetailsRowTop";
import InsurantDetailsRowBottom from "../InsurantDetailsRowBottom";
import styles from "./InsurantDetailsModalContent.module.scss";
import InsurantDetailsMutationExpandableRowTop from "../InsurantDetailsMutationExpandableRowTop";
import MutationTypeDropdown from "../../../MutationTypeDropdown/MutationTypeDropdown";

interface Props {
    intl: any;
    onClose: any;
    insurantData: any;
    entities: any;
    onEditClick: any;
    insurantMutations: any;
    onPdfClick: any;
}

interface IState {
    expandedRow: boolean;
    filtersChanged: number;
    showMoreSpinner: boolean;
    insurantMutations: any;
}

class InsurantDetailsModalContent extends React.Component<Props, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            expandedRow: null,
            filtersChanged: 0,
            showMoreSpinner: false,
            insurantMutations: this.props.insurantMutations,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>, snapshot?: any) {
        const {insurantMutations} = this.props;

        if (prevProps.insurantMutations !== insurantMutations && this.state.insurantMutations !== insurantMutations) {
            this.setState({insurantMutations})
        }
    }

    onClickMutationButton = () => {
        // const id = this.props.insurantData.
        // this.props.history.push("/pensionskasse/")
    }

    render() {
        const {expandedRow, insurantMutations} = this.state;
        const {insurantData, intl, onPdfClick, onEditClick} = this.props;

        return (
            <div>
                <div className="row mb-48 px-20">
                    <div className="col-12 col-md-6">
                        <h1 className="mb-32 mb-md-0">Aktuelle Daten</h1>
                    </div>

                    {/* <div className="col-12 col-md-6">
                        <Button
                            className="ml-auto"
                            type={ButtonTypes.Text}
                            color={ButtonColors.Action}
                            onClick={() => onEditClick("")}>
                            <FormattedMessage id="app.makemutation" defaultMessage="Mutation vornehmen"/>
                        </Button>
                    </div> */}
                </div>

                <ExpandableRow
                    expandable
                    isExpanded
                    content={<InsurantDetailsRowTop insurant={insurantData}/>}
                    more={
                        <InsurantDetailsRowBottom
                            insurant={insurantData}
                            onEditClick={(type) => onEditClick(type)}
                        />
                    }
                />

                <div className="d-flex align-items-center justify-content-between mt-58">
                <h1><FormattedMessage id="app.history" defaultMessage="Verlauf" /></h1>

                    <MutationTypeDropdown
                        data={this.props.insurantMutations}
                        setFilteredData={value => this.setState({insurantMutations: value})}
                    />
                </div>

                <div className={styles.listView}>
                    {insurantMutations.length > 0 && (
                        <div className={styles.listHeader}>
                            <div className={styles.column}>Mutationstyp / Gültig ab</div>
                            <div className={styles.column}>
                                <span>
                                    <FormattedMessage id="app.pervValue" defaultMessage="bisher"/>
                                </span>
                                <span>
                                    <FormattedMessage id="app.new" defaultMessage="neu"/>
                                </span>
                            </div>

                            <div className={styles.column}>Mutationsdatum / Bearbeiter</div>

                            <div className={styles.column}>
                                <div style={{width: "2.4rem"}}/>
                            </div>

                            {/* <div className="column">
                                    Bearbeitungsdatum/Bearbeiter
                                </div> */}
                            {/* <div className="column">
                                    <div style={{width: "2.3rem"}} />
                                </div> */}
                        </div>
                    )}

                    {insurantMutations.length > 0 ?
                        insurantMutations.map((mutation, index) => (
                                <ExpandableRow
                                    className="mb-24"
                                    statusBar={mutation.attributes.isDeleted ? {
                                        text: mutation.attributes.reasonForDeletion,
                                        color: StatusColors.ERROR,
                                        shape: StatusShape.BOTTOM_ROUNDED
                                    } : null}
                                    content={
                                        <InsurantDetailsMutationExpandableRowTop
                                            mutation={mutation}
                                            onPdfClick={() => onPdfClick(mutation.attributes.fileId)}
                                            expanded={index == expandedRow} //the row is expanded if the expandedRow == index
                                            toggleExpand={() => this.setState({expandedRow: expandedRow == index ? null : index})}
                                        />
                                    }
                                />
                            )
                        ) : (
                            <Blank
                                onButtonClick={() => null}
                                text={intl.formatMessage({
                                    id: "app.noinsurants",
                                    defaultMessage: "Keine Personen vorhanden"
                                })}
                            />
                        )}
                </div>
            </div>
        );
    }
}

export default injectIntl(InsurantDetailsModalContent);
