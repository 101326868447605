import React, { useEffect, useState } from "react";
import { ValidationMessage } from "../../../../@types/forms/agp/formValidation";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-action.svg";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { Controller, useForm } from "react-hook-form";
import { validateInputMessage } from "../../../../utils/validationTools";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  formatSSN,
  parseDate,
  prepareEntitiesDropdownOptions,
  isObjEmpty,
} from "../../../../utils/react";
import { isSsn } from "../../../../utils/validationTests";
import Tooltip from "rc-tooltip";
import { ReactComponent as IconHelp } from "../../../../assets/icons/help.svg";
import { CivilStatusCodes } from "../../../../models";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import { Input } from "../../../Input";
import { EValidationMessageType } from "../../../ValidationMessage";
import { filterOnlyLetters } from "../../../../utils/form";
import { bindActionCreators } from "redux";
import { updateFieldValues } from "../../../../actions/arbeitgeber/user";
import { EintrittFormValues } from "../../../../reducers/arbeitgeber/types";

interface Props {
  getValues: any;
  setValue: any;
  goToNextStep: any;
  showValidations: boolean;
  formConfig: any;
  isFormDisabled: boolean;
  isDirty: boolean;
  fetchPersonData: any;
  closeModal: any;
  setFormConfig: any;
  isMarriageDateRequired: boolean;
  isDivorceDateRequired: boolean;

  entities: any;
  civilStatusOptions: any;
  languageOptions: any;
  sexOptions: any;
  updateFieldValues: any;
  storedValues: EintrittFormValues;

  intl: any;
}

const Step1 = (props: Props) => {
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [requiredFields, setRequiredFields] = useState({});

  const isDirty = props.isDirty;

  const showValidation = true;

  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      person: {
        name:
          props?.storedValues?.person?.name ||
          props.getValues("eintrittErfassenForm.person.name") ||
          "",
        firstname:
          props?.storedValues?.person?.firstname ||
          props.getValues("eintrittErfassenForm.person.firstname") ||
          "",
        birthDate:
          props?.storedValues?.person?.birthDate ||
          props.getValues("eintrittErfassenForm.person.birthDate") ||
          "",
        sex:
          props?.storedValues?.person?.sex ||
          props.getValues("eintrittErfassenForm.person.sex") ||
          "",
        socialSecurityNumber:
          props?.storedValues?.person?.socialSecurityNumber ||
          props.getValues("eintrittErfassenForm.person.socialSecurityNumber") ||
          "",
        civilStatus:
          props?.storedValues?.person?.civilStatus ||
          props.getValues("eintrittErfassenForm.person.civilStatus") ||
          "",
        marriageDate:
          props?.storedValues?.person?.marriageDate ||
          props.getValues("eintrittErfassenForm.person.marriageDate") ||
          "",
        divorceDate:
          props?.storedValues?.person?.divorceDate ||
          props.getValues("eintrittErfassenForm.person.divorceDate") ||
          "",
        language:
          props?.storedValues?.person?.language ||
          props.getValues("eintrittErfassenForm.person.language") ||
          "1",
      },
    },
  });

  const civilStatusOptions = useSelector((state: RootState) => {
    return prepareEntitiesDropdownOptions(
      !isObjEmpty(props.entities.CivilStatus)
        ? props.entities.CivilStatus.values
        : {}
    );
  });

  useEffect(() => {
    if (JSON.stringify(requiredFields) !== JSON.stringify({})) {
      setIsDisabledButton(
        Object.keys(requiredFields).some(
          (key) => requiredFields[key] === "" || requiredFields[key] === false
        )
      );
    } else {
      setIsDisabledButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFields]);

  const handleRadioChange = (name: "sex" | "language", value) => {
    setValue(`person.${name}`, value);
    const validation: ValidationMessage = validateInputMessage(
      props.formConfig.formCustomization,
      getValues("person"),
      name,
      null,
      requiredFields
    );
    setError(`person.${name}`, validation);
    validation?.valid && setRequiredFields(validation?.valid);
  };

  const sexOptionsFormInputs = () => {
    return props.sexOptions.map((option) => ({
      ...option,
      name: "sex",
      type: "radio",
      label: option.label,
      value: option.value,
      validationMessageConfig: null,
      validationTests: [EValidationTestType.hasValue],
      onChange: handleRadioChange,
    }));
  };

  const languageOptionsFormInputs = () => {
    return props.languageOptions.map((option) => ({
      ...option,
      type: "radio",
      name: "language",
      label: option.label,
      value: option.value,
      validationMessageConfig: null,
      validationTests: [EValidationTestType.hasValue],
      onChange: handleRadioChange,
    }));
  };

  const requiredFieldStep1 = () => {
    const requiredForStep1 = {};
    Object.keys(getValues("person")).map((key) => {
      if (props.formConfig.formCustomization.components[key]?.required) {
        if (
          key === "marriageDate" &&
          (+getValues("person")["civilStatus"] === CivilStatusCodes.Married ||
            +getValues("person")["civilStatus"] ===
              CivilStatusCodes.RegisteredPartnership)
        ) {
          requiredForStep1[key] = getValues("person")[key];
        } else if (
          key === "divorceDate" &&
          +getValues("person")["civilStatus"] === CivilStatusCodes.Divorced
        ) {
          requiredForStep1[key] = getValues("person")[key];
        } else if (key !== "marriageDate" && key !== "divorceDate") {
          requiredForStep1[key] =
            key === "socialSecurityNumber"
              ? isSsn(getValues("person")[key])
              : getValues("person")[key];
        }
      }
    });

    setRequiredFields(requiredForStep1);
    setTimeout(
      () =>
        +getValues("person")["civilStatus"] === CivilStatusCodes.Married ||
        +getValues("person")["civilStatus"] ===
          CivilStatusCodes.RegisteredPartnership
          ? buildMarriageDateValidation(requiredForStep1)
          : +getValues("person")["civilStatus"] === CivilStatusCodes.Divorced
          ? buildDivorceDateValidation(requiredForStep1)
          : null,
      200
    );
  };

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      requiredFieldStep1();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formConfig.formCustomization]);

  const prepareToNextStep = () => {
    if (!isDisabledButton) {
      const payload = {
        person: {
          name: getValues("person.name"),
          firstname: getValues("person.firstname"),
          birthDate: getValues("person.birthDate"),
          sex: getValues("person.sex"),
          socialSecurityNumber: getValues("person.socialSecurityNumber"),
          civilStatus: getValues("person.civilStatus"),
          marriageDate: getValues("person.marriageDate"),
          divorceDate: getValues("person.divorceDate"),
          language: getValues("person.language"),
        },
      };
      props.setValue("eintrittErfassenForm.person", payload.person);
      props.updateFieldValues(payload);
    }
    props.goToNextStep();
  };

  const changeRequiredFieldFromFormCustomization = (
    field,
    value: boolean,
    formConfig
  ) => {
    return {
      ...formConfig,
      formCustomization: {
        ...formConfig.formCustomization,
        components: {
          ...formConfig.formCustomization.components,
          [field]: {
            ...formConfig.formCustomization.components[field],
            required: value,
          },
        },
      },
    };
  };

  const prepareCivilStatus = (value) => {
    let localFormCustomization = props.formConfig;
    // CivilStatusValidation
    if (
      value == CivilStatusCodes.Married ||
      value === CivilStatusCodes.RegisteredPartnership
    ) {
      if (props.isMarriageDateRequired) {
        setRequiredFields({ ...requiredFields, marriageDate: false });
      }
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "marriageDate",
        props.isMarriageDateRequired,
        localFormCustomization
      );
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "divorceDate",
        false,
        localFormCustomization
      );
      props.setFormConfig(localFormCustomization);
    } else if (value == CivilStatusCodes.Divorced) {
      if (props.isDivorceDateRequired) {
        setRequiredFields({ ...requiredFields, divorceDate: false });
      }
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "divorceDate",
        props.isDivorceDateRequired,
        localFormCustomization
      );
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "marriageDate",
        false,
        localFormCustomization
      );
      props.setFormConfig(localFormCustomization);
    } else {
      delete (requiredFields as any)?.marriageDate;
      delete (requiredFields as any)?.divorceDate;
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "marriageDate",
        false,
        localFormCustomization
      );
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "divorceDate",
        false,
        localFormCustomization
      );
      props.setFormConfig(localFormCustomization);
    }
    // setValue("person.marriageDate", "");
    // setValue("person.divorceDate", "");

    setValue("person.civilStatus", value && value);
    const validation: ValidationMessage = validateInputMessage(
      localFormCustomization.formCustomization,
      getValues("person"),
      "civilStatus",
      null,
      requiredFields
    );

    setError("person.civilStatus", validation);
    validation?.valid && setRequiredFields(validation?.valid);
  };

  const buildMarriageDateValidation = (customRequired?) => {
    const validation: ValidationMessage = validateInputMessage(
      props.formConfig.formCustomization,
      getValues("person"),
      "marriageDate",
      () => [
        EValidationTestType.dateIsNotBeforeEntrydate,
        EValidationTestType.dateIsNotBeforeBirthdate,
      ],
      customRequired ?? requiredFields
    );
    setError("person.marriageDate", validation);
    validation?.valid && setRequiredFields(validation?.valid);
  };

  const buildDivorceDateValidation = (customRequired?) => {
    const validation: ValidationMessage = validateInputMessage(
      props.formConfig.formCustomization,
      getValues("person"),
      "divorceDate",
      () => [
        EValidationTestType.dateIsNotBeforeEntrydate,
        EValidationTestType.dateIsNotBeforeBirthdate,
      ],
      customRequired ?? requiredFields
    );
    setError("person.divorceDate", validation);
    validation?.valid && setRequiredFields(validation?.valid);
  };

  const isSsnTooltipVisible =
    props.intl.formatMessage({
      id: "app.forms.eintritt.socialSecurityNumber.tooltip",
    }) != "app.forms.eintritt.socialSecurityNumber.tooltip";

  return (
    <div className="row">
      {/* AHV-Nummer */}
      {!props.formConfig.formCustomization.components["socialSecurityNumber"]
        ?.hidden && (
        <Controller
          control={control}
          name="person.socialSecurityNumber"
          render={({ field: { value } }) => (
            <div className="col-12 col-sm-6">
              <Input
                isFocused
                type="text"
                inputWrapperClass="text-input"
                id={"socialSecurityNumber"}
                name={"socialSecurityNumber"}
                placeholder={props.intl.formatMessage({
                  id: "app.forms.eintritt.socialSecurityNumber.placeholder",
                  defaultMessage: "AHV-Nummer eingeben",
                })}
                value={formatSSN(value)}
                onBlur={async (e) => {
                  if (isSsn(getValues("person.socialSecurityNumber"))) {
                    const updated = await props.fetchPersonData(
                      getValues("person.socialSecurityNumber")
                    );
                    if (updated?.socialSecurityNumber || updated?.birthDate) {
                      setValue("person", {
                        ...updated,
                        socialSecurityNumber: getValues(
                          "person.socialSecurityNumber"
                        ),
                      });
                      requiredFieldStep1();
                      setIsDisabledButton(false);
                    }
                  } else {
                    const validation: ValidationMessage = validateInputMessage(
                      props.formConfig.formCustomization,
                      getValues("person"),
                      "socialSecurityNumber",
                      () => [EValidationTestType.isSsn],
                      requiredFields
                    );
                    setError("person.socialSecurityNumber", validation);
                    validation?.valid && setRequiredFields(validation?.valid);
                  }
                }}
                onChange={(name, valueInput) => {
                  setValue(
                    "person.socialSecurityNumber",
                    formatSSN(valueInput)
                  );
                  const validation: ValidationMessage = validateInputMessage(
                    props.formConfig.formCustomization,
                    getValues("person"),
                    "socialSecurityNumber",
                    () => [EValidationTestType.isSsn],
                    requiredFields
                  );
                  setError("person.socialSecurityNumber", validation);
                  validation?.valid && setRequiredFields(validation?.valid);
                }}
                validationMessageConfig={{
                  visible: showValidation,
                  config: {
                    message: errors?.person?.socialSecurityNumber?.message,
                    type: errors?.person?.socialSecurityNumber
                      ?.type as EValidationMessageType,
                  },
                }}
              >
                <FormattedMessage
                  id="app.forms.eintritt.socialSecurityNumber"
                  defaultMessage="AHV-Nummer"
                />
                {props.formConfig.formCustomization.components[
                  "socialSecurityNumber"
                ]?.required
                  ? " *"
                  : ""}
                {isSsnTooltipVisible && (
                  <Tooltip
                    trigger={["click"]}
                    placement="top"
                    overlay={
                      <FormattedMessage
                        id="app.forms.eintritt.socialSecurityNumber.tooltip"
                        defaultMessage="AHV-Nummer"
                      />
                    }
                    arrowContent={<div className="rc-tooltip-arrow-inner" />}
                  >
                    <div className="help-icon pointer">
                      <IconHelp />
                    </div>
                  </Tooltip>
                )}
              </Input>
            </div>
          )}
        />
      )}

      <div className="col-12 col-sm-6" />

      {/* Name */}
      <div className="col-12 col-sm-6">
        <Controller
          control={control}
          name={"person.name"}
          render={({ field: { value } }) => (
            <Input
              isDirty={isDirty}
              type="text"
              hidden={
                props.formConfig.formCustomization.components["name"]?.hidden
              }
              disabled={props.isFormDisabled}
              readOnly={props.isFormDisabled}
              inputWrapperClass="text-input"
              id={"name"}
              name="name"
              placeholder={props.intl.formatMessage({
                id: "app.entername",
                defaultMessage: "Name eingeben",
              })}
              value={value}
              onChange={(name, valueInput) => {
                setValue("person.name", filterOnlyLetters(valueInput));
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("person"),
                  "name",
                  null,
                  requiredFields
                );
                setError("person.name", validation);
                validation?.valid && setRequiredFields(validation?.valid);
              }}
              onBlur={() => {
                const validation: ValidationMessage = validateInputMessage(
                  props.formConfig.formCustomization,
                  getValues("person"),
                  "name",
                  null,
                  requiredFields
                );
                setError("person.name", validation);
                validation?.valid && setRequiredFields(validation?.valid);
              }}
              validationMessageConfig={
                props.isFormDisabled && !!value
                  ? { visible: false, config: { message: "", type: null } }
                  : {
                      visible: showValidation,
                      config: {
                        message: errors?.person?.name?.message,
                        type: errors?.person?.name
                          ?.type as EValidationMessageType,
                      },
                    }
              }
            >
              <FormattedMessage id="app.name" defaultMessage="Name" />
              {props.formConfig.formCustomization.components["name"]?.required
                ? " *"
                : ""}
            </Input>
          )}
        />
      </div>

      {/* Vorname */}
      {!props.formConfig.formCustomization.components["firstname"]?.hidden && (
        <Controller
          control={control}
          name="person.firstname"
          render={({ field: { value } }) => (
            <div className="col-12 col-sm-6">
              <Input
                isDirty={isDirty}
                type="text"
                inputWrapperClass="text-input"
                disabled={props.isFormDisabled}
                readOnly={props.isFormDisabled}
                id={"firstname"}
                name={"firstname"}
                placeholder={props.intl.formatMessage({
                  id: "app.enterfirstname",
                  defaultMessage: "Vorname eingeben",
                })}
                value={value}
                onChange={(name, valueInput) => {
                  setValue("person.firstname", filterOnlyLetters(valueInput));
                  const validation: ValidationMessage = validateInputMessage(
                    props.formConfig.formCustomization,
                    getValues("person"),
                    "firstname",
                    null,
                    requiredFields
                  );
                  setError("person.firstname", validation);
                  validation?.valid && setRequiredFields(validation?.valid);
                }}
                onBlur={() => {
                  const validation: ValidationMessage = validateInputMessage(
                    props.formConfig.formCustomization,
                    getValues("person"),
                    "firstname",
                    null,
                    requiredFields
                  );
                  setError("person.firstname", validation);
                  validation?.valid && setRequiredFields(validation?.valid);
                }}
                validationMessageConfig={
                  props.isFormDisabled && !!value
                    ? { visible: false, config: { message: "", type: null } }
                    : {
                        visible: showValidation,
                        config: {
                          message: errors?.person?.firstname?.message,
                          type: errors?.person?.firstname
                            ?.type as EValidationMessageType,
                        },
                      }
                }
              >
                <FormattedMessage id="app.firstname" defaultMessage="Vorname" />
                {props.formConfig.formCustomization.components["firstname"]
                  ?.required
                  ? " *"
                  : ""}
              </Input>
            </div>
          )}
        />
      )}

      {/* Geburtsdatum */}
      {!props.formConfig.formCustomization.components["birthDate"]?.hidden && (
        <Controller
          control={control}
          name="person.birthDate"
          render={({ field: { value } }) => (
            <div className="col-12 col-sm-6">
              <Input
                isDirty={isDirty}
                type="date"
                maxLength={10}
                inputWrapperClass="text-input"
                disabled={props.isFormDisabled}
                readOnly={props.isFormDisabled}
                id={"birthDate"}
                name={"birthDate"}
                value={value && value.length >= 10 ? parseDate(value) : value}
                onChange={(name, valueInput) => {
                  setValue("person.birthDate", valueInput);
                  const validation: ValidationMessage = validateInputMessage(
                    props.formConfig.formCustomization,
                    getValues("person"),
                    "birthDate",
                    () => [
                      EValidationTestType.ageRangeBirthdate,
                      EValidationTestType.dateIsNotInTheFuture,
                    ],
                    requiredFields
                  );
                  setError("person.birthDate", validation);
                  validation?.valid && setRequiredFields(validation?.valid);

                  if (
                    +getValues("person")["civilStatus"] ===
                      CivilStatusCodes.Married ||
                    +getValues("person")["civilStatus"] ===
                      CivilStatusCodes.RegisteredPartnership
                  ) {
                    buildMarriageDateValidation(validation?.valid);
                  }

                  if (
                    +getValues("person.civilStatus") ===
                    CivilStatusCodes.Divorced
                  ) {
                    buildDivorceDateValidation(validation?.valid);
                  }
                }}
                onBlur={() => {
                  const validation: ValidationMessage = validateInputMessage(
                    props.formConfig.formCustomization,
                    getValues("person"),
                    "birthDate",
                    () => [
                      EValidationTestType.ageRangeBirthdate,
                      EValidationTestType.dateIsNotInTheFuture,
                    ],
                    requiredFields
                  );
                  setError("person.birthDate", validation);
                  validation?.valid && setRequiredFields(validation?.valid);

                  if (
                    +getValues("person")["civilStatus"] ===
                      CivilStatusCodes.Married ||
                    +getValues("person")["civilStatus"] ===
                      CivilStatusCodes.RegisteredPartnership
                  ) {
                    buildMarriageDateValidation(validation?.valid);
                  }

                  if (
                    +getValues("person.civilStatus") ===
                    CivilStatusCodes.Divorced
                  ) {
                    buildDivorceDateValidation(validation?.valid);
                  }
                }}
                validationMessageConfig={
                  props.isFormDisabled && !!value
                    ? { visible: false, config: { message: "", type: null } }
                    : {
                        visible: showValidation,
                        config: {
                          message: errors?.person?.birthDate?.message,
                          type: errors?.person?.birthDate
                            ?.type as EValidationMessageType,
                        },
                      }
                }
              >
                <FormattedMessage
                  id="app.birthdate"
                  defaultMessage="Geburtsdatum"
                />
                {props.formConfig.formCustomization.components["birthDate"]
                  ?.required
                  ? " *"
                  : ""}
              </Input>
            </div>
          )}
        />
      )}

      {/* Geschlecht */}
      {!props.formConfig.formCustomization.components["sex"]?.hidden && (
        <Controller
          control={control}
          name="person.sex"
          render={({ field: { value } }) => (
            <div className="col-12 col-sm-6">
              <Input
                isDirty={isDirty}
                disabled={props.isFormDisabled}
                readOnly={props.isFormDisabled}
                inputWrapperClass="radio-input"
                id={"sex"}
                name={"sex"}
                forcedValue={value.toString()}
                radioOptions={sexOptionsFormInputs()}
                onBlur={() => handleRadioChange("sex", value.toString())}
                validationMessageConfig={{
                  visible: showValidation,
                  config: {
                    message: errors?.person?.sex?.message,
                    type: errors?.person?.sex?.type as EValidationMessageType,
                  },
                }}
              >
                <FormattedMessage id="app.sex" defaultMessage="Geschlecht" />
                {props.formConfig.formCustomization.components["sex"]?.required
                  ? " *"
                  : ""}
              </Input>
            </div>
          )}
        />
      )}

      {/* Zivilstand */}
      {!props.formConfig.formCustomization.components["civilStatus"]
        ?.hidden && (
        <Controller
          control={control}
          name="person.civilStatus"
          render={({ field: { value } }) => (
            <div className="col-12 col-sm-6">
              <Input
                isDirty={isDirty}
                type="text"
                label="Anrede"
                disabled={props.isFormDisabled}
                readOnly={props.isFormDisabled}
                id={"civilStatus"}
                name={"civilStatus"}
                placeholder={
                  props.entities.CivilStatus
                    ? props.entities.CivilStatus.values[0]
                    : ""
                }
                value={
                  (props.entities.CivilStatus &&
                    props.entities.CivilStatus.values[value]) ||
                  value
                }
                selectOptions={civilStatusOptions}
                onSelected={(name, valueInput) => {
                  prepareCivilStatus(valueInput);
                }}
                onBlur={(name, valueInput) => prepareCivilStatus(valueInput)}
                // onChange={(name, valueInput) => {
                //   prepareCivilStatus(valueInput);
                // }}
                validationMessageConfig={{
                  visible: showValidation,
                  config: {
                    message: errors?.person?.civilStatus?.message,
                    type: errors?.person?.civilStatus
                      ?.type as EValidationMessageType,
                  },
                }}
                sort
              >
                <FormattedMessage
                  id="app.civistatus"
                  defaultMessage="Zivilstand"
                />
                {props.formConfig.formCustomization.components["civilStatus"]
                  ?.required
                  ? " *"
                  : ""}
              </Input>
            </div>
          )}
        />
      )}

      {/* Heiratsdatum */}
      {(+getValues("person.civilStatus") === CivilStatusCodes.Married ||
        +getValues("person.civilStatus") ===
          CivilStatusCodes.RegisteredPartnership) &&
        !props.formConfig.formCustomization.components["marriageDate"]
          ?.hidden && (
          <Controller
            control={control}
            name="person.marriageDate"
            render={({ field: { value } }) => (
              <div className="col-12 col-sm-6">
                <Input
                  isDirty={isDirty}
                  type="date"
                  label="label"
                  maxLength={10}
                  inputWrapperClass="text-input"
                  id="marriageDate"
                  name="marriageDate"
                  placeholder=""
                  disabled={props.isFormDisabled}
                  readOnly={props.isFormDisabled}
                  value={value && value.length >= 10 ? parseDate(value) : value}
                  onChange={(name, valueInput) => {
                    setValue("person.marriageDate", valueInput);
                    buildMarriageDateValidation();
                  }}
                  onBlur={() => {
                    buildMarriageDateValidation();
                  }}
                  validationMessageConfig={
                    props.isFormDisabled && !!value
                      ? { visible: false, config: { message: "", type: null } }
                      : {
                          visible: showValidation,
                          config: {
                            message: errors?.person?.marriageDate?.message,
                            type: errors?.person?.marriageDate
                              ?.type as EValidationMessageType,
                          },
                        }
                  }
                >
                  <FormattedMessage
                    id={
                      +getValues("person.civilStatus") ===
                      CivilStatusCodes.Married
                        ? "app.marriagedate"
                        : "app.partnerschaftdate"
                    }
                    defaultMessage={
                      +getValues("person.civilStatus") ===
                      CivilStatusCodes.Married
                        ? "Heiratsdatum"
                        : "Datum eingetragene Partnerschaft"
                    }
                  />
                  {props.formConfig.formCustomization.components["marriageDate"]
                    ?.required &&
                  +getValues("person.civilStatus") == CivilStatusCodes.Married
                    ? " *"
                    : ""}
                </Input>
              </div>
            )}
          />
        )}

      {/* Scheidungsdatum */}
      {+getValues("person.civilStatus") == CivilStatusCodes.Divorced &&
        !props.formConfig.formCustomization.components["divorceDate"]
          ?.hidden && (
          <Controller
            control={control}
            name="person.divorceDate"
            render={({ field: { value } }) => (
              <div className="col-12 col-sm-6">
                <Input
                  isDirty={isDirty}
                  type="date"
                  label="label"
                  maxLength={10}
                  inputWrapperClass="text-input"
                  id="divorceDate"
                  name="divorceDate"
                  placeholder=""
                  disabled={props.isFormDisabled}
                  readOnly={props.isFormDisabled}
                  value={value && value.length >= 10 ? parseDate(value) : value}
                  onChange={(name, valueInput) => {
                    setValue("person.divorceDate", valueInput);
                    buildDivorceDateValidation();
                  }}
                  onBlur={() => {
                    buildDivorceDateValidation();
                  }}
                  validationMessageConfig={
                    props.isFormDisabled && !!value
                      ? { visible: false, config: { message: "", type: null } }
                      : {
                          visible: showValidation,
                          config: {
                            message: errors?.person?.divorceDate?.message,
                            type: errors?.person?.divorceDate
                              ?.type as EValidationMessageType,
                          },
                        }
                  }
                >
                  <FormattedMessage
                    id="app.divorcedate"
                    defaultMessage="Scheidungsdatum"
                  />
                  {props.formConfig.formCustomization.components["divorceDate"]
                    ?.required &&
                  +getValues("person.civilStatus") == CivilStatusCodes.Divorced
                    ? " *"
                    : ""}
                </Input>
              </div>
            )}
          />
        )}

      {/* Space */}
      {(+getValues("person")["civilStatus"] === CivilStatusCodes.Married ||
        +getValues("person")["civilStatus"] ===
          CivilStatusCodes.RegisteredPartnership ||
        +getValues("person.civilStatus") == CivilStatusCodes.Divorced) && (
        <div className="col-12 col-sm-6" />
      )}

      {/* Korrespondenzsprache */}
      {!props.formConfig.formCustomization.components["language"]?.hidden && (
        <Controller
          control={control}
          name="person.language"
          render={({ field: { value } }) => (
            <div className="col-12 col-sm-6">
              <Input
                isDirty={isDirty}
                inputWrapperClass="radio-input"
                id={"language"}
                name={"language"}
                forcedValue={value.toString()}
                radioOptions={languageOptionsFormInputs()}
                disabled={props.isFormDisabled}
                readOnly={props.isFormDisabled}
                onBlur={() => handleRadioChange("language", value.toString())}
                validationMessageConfig={{
                  visible: showValidation,
                  config: {
                    message: errors?.person?.language?.message,
                    type: errors?.person?.language
                      ?.type as EValidationMessageType,
                  },
                }}
              >
                <FormattedMessage
                  id="app.language"
                  defaultMessage="Korrespondenzsprache"
                />
                {props.formConfig.formCustomization.components["language"]
                  ?.required
                  ? " *"
                  : ""}
              </Input>
            </div>
          )}
        />
      )}

      <div className="col-12 col-sm-6" />
      <div className="col-12 col-sm-6" />
      {props.formConfig.formCustomization.components["language"]?.hidden && (
        <div className="col-12 col-sm-6" />
      )}

      <div className="col-12 col-sm-6" />
      <div className="col-12 col-md-6">
        <div className="modal-buttons">
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={props.closeModal}
          >
            <ArrowRight className="mr-16 rotate-180" />
            <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
          </Button>
          <Button
            submit={true}
            isDisabled={isDisabledButton}
            type={ButtonTypes.Standard}
            color={ButtonColors.Action}
            style={ButtonStyles.Primary}
            onClick={() => prepareToNextStep()}
          >
            <FormattedMessage id="app.continue" defaultMessage="WEITER" />
          </Button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    storedValues: state.app.storedValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFieldValues: bindActionCreators(updateFieldValues, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Step1))
);
