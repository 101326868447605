import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import { Controller, useForm } from "react-hook-form";

interface IProps {
  intl: any;
  loading: boolean;
  onSubmit: (reason?: string) => void;
  onCancel: any;
}

function InsurantDetailPopup(props: IProps) {
  const { setValue, getValues, control } = useForm({
    criteriaMode: "all",
    defaultValues: {
      reasonForDeletion: "",
    },
  });

  return (
    <div>
      <div className="col-12">
        <h1 className="mb-16">Stornieren</h1>

        <p className="mb-32">Wollen Sie diese Mutation wirklich stornieren?</p>

        <Controller
          control={control}
          name={"reasonForDeletion"}
          render={({ field: { value } }) => (
            <Input
              isFocused
              type="text"
              inputWrapperClass="text-input"
              id="reasonForDeletionInput"
              name="reasonForDeletionInput"
              placeholder={props.intl.formatMessage({
                id: "app.comment.placeholder",
                defaultMessage: "Bemerkung eingeben",
              })}
              value={value}
              onChange={(name, inputValue) => {
                setValue("reasonForDeletion", inputValue);
              }}
            >
              <FormattedMessage id="app.comment" defaultMessage="Bemerkung" />
            </Input>
          )}
        />
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={props.onCancel}
        >
          <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
        </Button>
        <Button
          type={ButtonTypes.Standard}
          color={ButtonColors.Action}
          style={ButtonStyles.Primary}
          onClick={() => props.onSubmit(getValues("reasonForDeletion"))}
          isDisabled={props.loading}
        >
          Stornieren
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  loading: state.app.loading,
});
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(InsurantDetailPopup));
