import * as React from 'react';
import styles from "./CockpitInvoiceCard.module.scss";
import {Button, ButtonColors, ButtonTypes, Status, StatusColors} from "icr-shared-components";
import {RootState} from "../../../../reducers";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {ReactComponent as DownloadIcon} from "../../../../assets/icons/pdfdownload.svg";
import {formatNumber, parseDate} from "../../../../utils/react";

interface Props {
    intl: any;
    entities: any;
    invoice: any;
    onPdfClick: any;
    onEditClick: any;
}

const InvoiceStatusColors = {
    0: StatusColors.HIGHLIGHT,
    1: StatusColors.ERROR,
    2: StatusColors.VERIFICATION,
};

const CockpitInvoiceCard = ({invoice, entities, onEditClick, onPdfClick}: Props) => {
    const status = entities?.InvoiceStates?.values?.[invoice.attributes.status];
    return (
        <div tabIndex={0} className={styles.cockpitInvoiceCard}>
            <p className="mb-16"><b>{invoice.attributes.invoiceName}</b></p>
            <div className="d-flex justify-content-between  mb-24">
                <p><span className="mr-24">{invoice.attributes.amount && "CHF " + formatNumber(invoice.attributes.amount)}</span> {parseDate(invoice.attributes.invoiceDate)}</p>
            </div>
            <div className="d-flex justify-content-between">
                <Status text={status} color={InvoiceStatusColors[invoice.attributes.status]}/>
                <div className={styles.cockpitInvoiceCardButtons}>
                    {onPdfClick && <Button
                        className={styles.pdfIcon}
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        onClick={onPdfClick}>
                        <DownloadIcon/>
                    </Button>}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    entities: state.app.entities
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(CockpitInvoiceCard));
