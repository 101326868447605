import * as React from 'react';
import {Button, ButtonColors, ButtonSizes, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {FormattedMessage} from "react-intl";
import {ReactComponent as IconCheckmarkSlim} from "../../../../assets/icons/checkmark-slim.svg";
import "./SuccessModal.scss"

interface Props {
    onClose: any;
    onPdfClick: any;
}

interface IState {
}

class SuccessModal extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className="modal">
                <div className="modal-content success">
                    <div className="">
                        <div className="modal-success-icon">
                            <IconCheckmarkSlim className="strokeWhite"/>
                        </div>
                        <h1 className="mb-32 text-center">Änderungen wurden abgegeben!</h1>
                        <div className="success-message-wrap">
                            <p className="text-center success-message-space">
                                Die Zusammenfassung der abgegebenen Mutationen finden Sie in wenigen Augenblicken im File-Transfer.
                            </p>
                        </div>
                        <Button
                            className="mb-24 mx-auto"
                            // size={ButtonSizes.Full}
                            type={ButtonTypes.Standard}
                            color={ButtonColors.Action}
                            style={ButtonStyles.Primary}
                            onClick={this.props.onClose}>
                            <FormattedMessage id="app.tooverview" defaultMessage="Zur Übersicht"/>
                        </Button>
                        {/* <Button
                            className="mx-auto"
                            type={ButtonTypes.Text}
                            color={ButtonColors.Action}
                            onClick={this.props.onPdfClick}>
                            pdf viewer öffnen
                        </Button> */}
                    </div>

                </div>
            </div>
        );
    }
}

export default SuccessModal;
