import React from "react";

interface IProps {
    text?: any;
    color?:string
}

export default function DownloadIcon(props: IProps) {
    return (
        <svg width="25px" height="26px" viewBox="0 0 25 26" version="1.1">
            <title>F8269955-56F7-40BC-AE77-3C04C5EA473C</title>
            <desc>Created with sketchtool.</desc>
            <g id="✅-Feather-(Neutral)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="File-Transfer_Empfangen" transform="translate(-962.000000, -291.000000)">
                    <g id="Person" transform="translate(112.000000, 249.000000)">
                        <g id="download-on-light-grey-icon-copy" transform="translate(850.000000, 42.000000)">
                            <path d="M4,2.53846154 C4,1.13650794 5.13650794,0 6.53846154,0 L13.4700906,0 C13.9427079,0 14.3956789,0.189094392 14.7280183,0.525126478 L20.4886969,6.75589571 C20.8162727,7.08711127 21,7.53415703 21,8 L21,20 C21,22 21,22 19,22 L6,22 C4.5980464,22 4,21.4019536 4,20 L4,14.3846154 L6,14.3846154 L6,6.38461538 L4,6.38461538 L4,2.53846154 Z M13.3737552,2 L6.53846154,2 C6.24107744,2 6,2.24107744 6,2.53846154 L6,20 L19,20 L19,19 L19,8 L13.3737552,2 Z" id="Path"  fill={props?.color ||"#263238"} fillRule="nonzero"></path>
                            <text id="CSV" fontFamily="Inter-ExtraBold, Inter" fontSize="7" fontWeight="600" fill={props?.color ||"#263238"}>
                                <tspan x="0" y="13">{props.text || 'CSV'}</tspan>
                            </text>
                            <circle id="Oval" stroke="#FFFFFF" strokeWidth="2"  fill={props?.color ||"#263238"} cx="18" cy="19" r="6"></circle>
                            <polyline id="Path" stroke="#FFFFFF" points="16 19 18 21 20 19"></polyline>
                            <line x1="18" y1="21" x2="18" y2="16.5" id="Path" stroke="#FFFFFF"></line>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
