import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers/index";
import { bindActionCreators, Dispatch } from "redux";
import Modal from "react-modal";
import Tab from "@material/react-tab";
import * as actions from "../../actions/";
import * as employeeActions from "../../actions/arbeitgeber/insurants";
import {
  fetchEmployees,
  setInsurantsFetched,
  fetchLocalEmployees,
} from "../../actions/arbeitgeber/insurants";
import {
  createAddress,
  deleteAddress,
  fetchAddresses,
  patchAddress,
} from "../../actions/arbeitgeber/address";
import {
  createPayroll,
  deletePayroll,
  fetchPayrolls,
  patchPayroll,
} from "../../actions/arbeitgeber/payroll";
import {
  commitMutations,
  deleteCsv,
  deleteImportJob,
  deleteMutation,
  fetchImportJobState,
  fetchCompleteMutations,
  fetchCsv,
  fetchImportJobs,
  fetchInsurantMutations,
  fetchMutationById,
  fetchMutations,
  fetchMutationsCount,
  fetchOpenMutations,
  patchMutation,
  setCompleteMutationsFetched,
  setMutationsFetched,
  setOpenMutationsFetched,
  fetchLocalMutations,
  uploadCsv,
  revertMutations,
} from "../../actions/arbeitgeber/mutations";
import { fetchEmployers } from "../../actions";
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import { toastr } from "react-redux-toastr";
import Drawer from "../../components/Drawer";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import { objectFlip } from "../../utils/array";
import { EValidationMessageType } from "../../components/ValidationMessage/index";
import {
  arrayBufferToBase64,
  emptyStringsToNull,
  formatCsvImportDate,
  formatNumber,
  getLatestCsvImport,
  isObjEmpty,
  parseDate,
  parseToApiDate,
  prepareEmployersForCommit,
} from "../../utils/react";
import { getParameterByName } from "../../utils/toSearchParams";
import { ProcessingStates } from "../../models";
import { commitMutationsPayload } from "../../constants/payloads";
import {
  COCKPIT_MODAL_PAGES,
  INSURANCES_MODAL_PAGES,
  insurantMutationTypesToFormsMap,
  modalPageTypes,
  mutationFormTypes,
  mutationMutationTypesToFormMap,
  mutationToFormMap,
  mutationTypesToMessageMap,
  mutationTypeToFormObj,
  VERSICHERTE_POPUP_CONTENT,
} from "../../constants";

import "./Insurances.scss";
import {
  createUnpaidVacation,
  deleteUnpaidVacation,
  fetchUnpaidVacations,
  patchUnpaidVacation,
} from "../../actions/arbeitgeber/unpaidvacation";
import {
  initializeUpload,
  uploadFile,
  uploadPart,
  uploadExcel,
} from "../../actions/arbeitgeber/upload";
import Comparator from "../../utils/sorting";
import {
  prepareBackendValidationObject,
  prepareCreateAddressPayload,
  prepareCreateNewInsurantPayload,
  prepareCreatePayrollPayload,
  prepareCreateUnpaidvacationPayload,
  preparePatchInsurantPayload,
  preparePatchMutationPayload,
  prepareUpdateAddressPayload,
  prepareUpdatePayrollPayload,
  prepareUpdateUnpaidvacationPayload,
} from "./utils";
import PersonenTab from "../../components/Arbeitgeber/Versicherte/PersonenTab";
import MutationenTab from "../../components/Arbeitgeber/Versicherte/MutationTab";
import PersonenSidebar from "../../components/Arbeitgeber/Versicherte/PersonenSidebar";
import MutationenSidebar from "../../components/Arbeitgeber/Versicherte/MutationenSidebar";
import { INSURANCES_TABS } from "../../constants/pensionskasse";
import ModalContent from "../../components/Arbeitgeber/Versicherte/ModalContent";
import DeleteMutationsPopup from "../../components/Arbeitgeber/Versicherte/DeleteMutationPopup";
import CsvImportPopup from "../../components/Arbeitgeber/Versicherte/DeleteCsvPopup";
import CommitMutationsPopup from "../../components/Arbeitgeber/Cockpit/CommitMutationsPopup";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-round.svg";
import { ReactComponent as ImportIcon } from "../../assets/icons/import.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { StickyActions } from "../../components/StickyActions";
import { StickyButton } from "../../components/StickyButton";
import { PdfProviders } from "../../components/PdfViewer";
import {
  fetchExcelImports,
  fetchExcelFiles,
} from "../../actions/arbeitgeber/insurants";
import { clearFieldValues } from "../../actions/arbeitgeber/user";

interface State {
  alsoClosedMutationsFilter: boolean;
  currentTab: string;
  eintrittErfassenForm: any;
  // expandedRowPresent: number;
  filtersChanged: number;
  filtersOpened: boolean;
  insurantData: any;
  insurantDataLoaded: boolean;
  insuredPersonInfo: any;
  modalContent: string;
  modalContentId: string;
  modalIsOpen: boolean;
  modalMessage: string;
  newAddress: any;
  newEntryStep: number;
  newPayroll: any;
  newUnpaidVacation: any;
  pdfFileId: string;
  refreshedState: boolean;
  selectedAddress: any;
  selectedPayroll: any;
  payrollMode: string;
  showMoreSpinner: boolean;
  showValidations: boolean;
  useInsurantDataPatch: boolean;
  versicherteInfosForm: any;
  expandedRow: number;
  popupContent: any;
  popupId: any;
  provider: any;
  modalFadeOut: boolean;
  openedDropdown: any;
  selectedUnpaidVacation: any;
  progressMax: any;
  progressValue: any;
  affectedMutations: any;
  backendValidation: any;
  affectedCsvImportDate: any;
  affectedCompany: any;
  loading: boolean;
  stickyActionButtonsOpened: boolean;
  showSpinner: boolean;
  commitLoading: boolean;
  isImporting: boolean;
  excelProcessedMutations: number;
}

interface Props {
  actions: any;
  addresses: any;
  createAddress: any;
  createPayroll: any;
  deleteAddress: any;
  deleteMutation: any;
  deletePayroll: any;
  employeeActions: any;
  employers: any;
  entities: any;
  fetchAddresses: any;
  fetchMutations: any;
  fetchPayrolls: any;
  fetchMutationById: any;
  // Filtering logic start
  filterByAlsoClosedMutations: boolean;
  filterByAlsoInactivePerson: boolean;
  filterByFirmaMutations: string;
  filterByFirmaPerson: string;
  filterByNameMutations: string;
  filterByNamePerson: string;
  filterByOnlyChangedPerson: boolean;
  filterByTypeMutations: string;
  filterMutations: any;
  filterPersons: any;
  // Filtering logic end
  history: any;
  intl: any;
  insurants: any;
  insurantsObj: any;
  invoices: Array<any>;
  locales: any;
  location: any;
  match: any;
  mutations: Array<any>;
  mutationsObj: any;
  mutationTypes: any;
  patchAddress: any;
  patchMutation: any;
  patchPayroll: any;
  payrolls: any;
  setSidebar: any;
  sidebarOpened: boolean;
  setLoading: any;
  isLoading: boolean;
  insurantsPageNo: number;
  mutationsPageNo: number;
  setInsurantsPageNo: any;
  setMutationsPageNo: any;
  moreInsurants: boolean;
  moreMutations: boolean;
  fetchEmployers: any;
  setPopup: any;
  popupIsOpen: boolean;
  commitMutations: any;
  fetchOpenMutations: any;
  fetchCompleteMutations: any;
  unpaidVacations: any;
  fetchUnpaidVacations: any;
  createUnpaidVacation: any;
  patchUnpaidVacation: any;
  deleteUnpaidVacation: any;
  initializeUpload: any;
  initializeDownload: any;
  uploadExcel: any;
  uploadPart: any;
  uploadFile: any;
  addNewFile: any;
  uploadCsv: any;
  fetchCsv: any;
  csv_imports: any;
  job_imports: any;
  deleteCsv: any;
  fetchEmployees: any;
  insurantsFetched: boolean;
  mutationsFetched: boolean;
  showValidations: any;
  fetchInsurantMutations: any;
  totalMutations: number;
  warningMutations: number;
  errorMutations: number;
  deleteImportJob: any;
  fetchImportJobs: any;
  fetchImportJobState: any;
  fetchMutationsCount: any;
  setInsurantsFetched: any;
  setMutationsFetched: any;
  setOpenMutationsFetched: any;
  setCompleteMutationsFetched: any;
  fetchedEntities: boolean;
  fetchLocalEmployees: any;
  fetchLocalMutations: any;
  revertMutations: any;
  clearFieldValues: any;
}

Modal.setAppElement("#root");

const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || "20";

class Insurances extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    document.body.classList.add("insurancesPage");
    let modalContentId = "";
    let modalContent = "";
    let fileId = "";

    if (this.props.location.search && this.props.location.search.length > 0) {
      // const url = new URL(this.props.location.search);
      // const url = new URL(window.location.href);
      modalContentId = getParameterByName("edit", this.props.location.search);
      modalContent = getParameterByName("content", this.props.location.search);
      fileId = getParameterByName("fileId", this.props.location.search);
      // tabId = Number(getParameterByName('tab', this.props.location.search));
      // console.log("modalContentId", modalContentId, 'modalContent', modalContent);
    }

    this.state = {
      alsoClosedMutationsFilter: false,
      currentTab: props.match.params.tab,
      // eintrittErfassenForm: null,
      expandedRow: null,
      loading: false,
      // expandedRowPresent: 0,
      filtersOpened: window.innerWidth >= 1400,
      filtersChanged: 0,
      insurantData: null,
      insurantDataLoaded: false,
      insuredPersonInfo: {},
      modalContent: modalContent,
      modalContentId: modalContentId,
      modalIsOpen: modalContent ? true : false,
      modalMessage: "",
      newEntryStep: 1,
      eintrittErfassenForm: {
        person: {},
        address: {},
        payroll: {},
        employee: {},
        contact: {},
      },
      pdfFileId: fileId,
      refreshedState: false,
      selectedAddress: {},
      selectedPayroll: {},
      selectedUnpaidVacation: {},
      showMoreSpinner: false,
      showValidations: false,
      useInsurantDataPatch: false,
      versicherteInfosForm: {},
      newPayroll: {},
      newAddress: {},
      payrollMode: "",
      newUnpaidVacation: {},
      popupContent: "",
      popupId: "",
      provider: "",
      modalFadeOut: false,
      openedDropdown: null,
      progressMax: null,
      progressValue: null,
      affectedMutations: "",
      backendValidation: {},
      affectedCompany: "",
      affectedCsvImportDate: "",
      stickyActionButtonsOpened: false,
      showSpinner: false,
      commitLoading: false,
      isImporting: false,
      excelProcessedMutations: null,
    };
  }

  private drawer = null;

  componentDidMount() {
    this.fetchData();
    document.body.classList.add("insurancesPage");
    document.body.classList.remove("backdrop");
    window.addEventListener("resize", this.viewPortChanged);
    window.addEventListener("scroll", this.handleScroll, true);

    let id = getParameterByName("edit", this.props.location.search);
    let addressId = getParameterByName("addressId", this.props.location.search);
    let payrollId = getParameterByName("payrollId", this.props.location.search);
    let unpaidVacationId = getParameterByName(
      "unpaidVacationId",
      this.props.location.search
    );
    if (id) {
      if (this.state.currentTab == "mutationen") {
        this.props.fetchMutationById(id).then((res) => {
          this.setState({
            insurantData: res["data"]["attributes"]["values"],
            selectedAddress: res["data"]["attributes"]["values"]["address"],
            selectedPayroll: res["data"]["attributes"]["values"]["payroll"],
            selectedUnpaidVacation:
              res["data"]["attributes"]["values"]["unpaidVacation"],
            eintrittErfassenForm: res["data"]["attributes"]["values"],
            insurantDataLoaded: true,
          });
        });
      } else {
        this.props.employeeActions.fetchInsurantById(id).then((res) => {
          this.setState({
            insurantData: res["data"]["attributes"],
            // eintrittErfassenForm: { ...minimumInsurantPayload.data.attributes },
            insurantDataLoaded: true,
          });
          this.props.fetchInsurantMutations(id);
          this.props.fetchAddresses(id).then(() => {
            if (addressId) {
              this.setState({
                selectedAddress: this.props.addresses[addressId],
              });
            } else {
              this.setState({
                selectedAddress: this.state.insurantData.address,
              });
            }
          });
          this.props.fetchPayrolls(id).then(() => {
            if (payrollId) {
              this.setState({
                selectedPayroll: this.props.payrolls[payrollId],
              });
            } else {
              this.setState({
                selectedPayroll: this.state.insurantData.payroll,
              });
            }
          });
          this.props.fetchUnpaidVacations(id).then(() => {
            if (unpaidVacationId) {
              this.setState({
                selectedUnpaidVacation:
                  this.props.unpaidVacations[unpaidVacationId],
              });
            } else {
              this.setState({
                selectedUnpaidVacation: this.state.insurantData.unpaidVacation,
              });
            }
          });
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.viewPortChanged);
    window.removeEventListener("scroll", this.handleScroll);
    document.body.classList.remove("insurancesPage");
  }

  fetchData = () => {
    if (!this.props.insurantsFetched) {
      this.props.fetchEmployees().then(() => this.props.setInsurantsPageNo(1));
    }

    if (!this.props.mutationsFetched) {
      this.props.fetchMutations().then(() => this.props.setMutationsPageNo(1));
    }
  };

  static getDerivedStateFromProps(props, state) {
    // Update state so the next render will show the fallback UI.
    // console.log("getDerivedStateFromProps", props.location.search, state.eintrittErfassenForm, props.match.params.tab);
    if (state.modalIsOpen && props.location.search === "") {
      // Will hide modal as search is empty
      return {
        modalIsOpen: false,
        currentTab: props.match.params.tab,
      };
    } else {
      let modalContentId = getParameterByName("edit", props.location.search);
      let modalContent = getParameterByName("content", props.location.search);
      let pdfFileId = getParameterByName("fileId", props.location.search);
      let modalIsOpen = !!(modalContentId || modalContent);
      let filtersChanged = state.filtersChanged + 1;
      let currentTab = props.match.params.tab;
      let provider = getParameterByName("provider", props.location.search);

      return {
        currentTab: currentTab,
        modalContentId: modalContentId,
        modalContent: modalContent,
        modalIsOpen: modalIsOpen,
        pdfFileId: pdfFileId,
        filtersChanged: filtersChanged,
        provider: provider,
      };
    }
  }

  handleContentContainerClick = (e) => {
    // console.log("handleContentContainerClick", e, e.target, e.currentTarget);
    if (this.drawer.contains(e.target)) {
      return;
    } else {
      window.innerWidth < 1400 && this.setState({ filtersOpened: false });
    }
    // console.log("document.querySelector('.hamburgerContainer').contains(e.target)", document.querySelector('.hamburgerContainer').contains(e.target));
    if (!document.querySelector(".sidebar-container").contains(e.target)) {
      this.props.sidebarOpened && this.props.setSidebar(false);
    }
  };

  viewPortChanged = (e) => {
    // console.log("viewPortChanged", e);
    setTimeout(() => {
      if (e.target.innerWidth >= 1400 && this.state.filtersOpened === false) {
        this.setState({ filtersOpened: true });
      }
    }, 500);
  };

  fetchEmployees = async (pageNo?, pageSize?) => {
    return await this.props.employeeActions.fetchEmployees(pageSize, pageNo);
  };

  fetchMutations = async (pageNo?, pageSize?) => {
    return await this.props.fetchMutations(pageSize, pageNo);
  };

  openModal = (id, content, message?) => {
    const { currentTab, insurantData } = this.state;
    const { mutations, insurants } = this.props;
    const isMutationenTab = currentTab === INSURANCES_TABS.MutationenTab;
    const isPersonenTab = currentTab === INSURANCES_TABS.PersonenTab;

    let entity: any = {};
    let insurant: any = {};
    let address: any = {};
    let payroll: any = {};
    let unpaidVacation: any = {};
    let eintritt: any = {};

    eintritt = {
      person: {},
      address: {},
      payroll: {},
      employee: {},
      contact: {},
    };
    if (isMutationenTab && id) {
      entity = mutations.find((mutation) => mutation.id == id);
      insurant = entity.attributes.values;
      address = entity.attributes.values.address;
      payroll = entity.attributes.values.payroll;
      unpaidVacation = entity.attributes.values.unpaidVacation;
      eintritt = entity.attributes.values;
      console.log("inside mutations");
    }
    if (isPersonenTab && id) {
      entity = insurants.find((insurant) => insurant.id == id);
      if (!entity) {
        entity = { attributes: insurantData };
      }
      console.log("ENTITY", entity);
      insurant = entity.attributes;
      address = entity.attributes.address;
      payroll = entity.attributes.payroll;
      unpaidVacation = entity?.attributes.unpaidVacation;
      console.log("inside mutations");
    }
    if (isPersonenTab && id) {
      this.props.fetchAddresses(id);
      this.props.fetchPayrolls(id);
      this.props.fetchUnpaidVacations(id);
      if (content == modalPageTypes.INSURANT_DETAILS) {
        this.props.fetchInsurantMutations(id);
      }
    }
    console.log("EINTRITTTTTT", eintritt);
    if (
      (id && !isObjEmpty(entity)) ||
      !id ||
      content == modalPageTypes.SUCCESS
    ) {
      this.setState(
        {
          modalMessage: message,
          insurantData: insurant,
          selectedAddress: address,
          selectedPayroll: payroll,
          selectedUnpaidVacation: unpaidVacation,
          eintrittErfassenForm: eintritt,
          insurantDataLoaded: !!id,
        },
        () => {
          this.props.history.push({
            pathname: `/versicherte/${this.state.currentTab}`,
            search:
              "?" +
              new URLSearchParams({
                edit: id || "",
                content: content || "",
              }).toString(),
          });
        }
      );
    }
  };

  openPdfModal = (content, fileId, provider) => {
    console.log("openPdfModal", fileId, content);
    this.setState({
      modalIsOpen: true,
      modalContent: content,
      pdfFileId: fileId,
      provider: provider,
    });
    this.props.history.push({
      pathname: `/versicherte/${this.state.currentTab}`,
      search:
        "?" + new URLSearchParams({ fileId, content, provider }).toString(),
    });
  };

  openAllInsurantsPdfModal = () => {
    this.props.history.push({
      pathname: `/versicherte/${this.state.currentTab}`,
      search:
        "?" +
        new URLSearchParams({
          content: INSURANCES_MODAL_PAGES.PDF,
          provider: PdfProviders.INSURANTS,
        }).toString(),
    });
  };

  openAllInsurantsExcelModal = () => {
    this.props.history.push({
      pathname: `/versicherte/${this.state.currentTab}`,
      search:
        "?" +
        new URLSearchParams({
          content: INSURANCES_MODAL_PAGES.EXPORT_EXCEL,
          provider: PdfProviders.INSURANTS,
        }).toString(),
    });
  };

  openAllMutationsModal = () => {
    this.props.history.push({
      pathname: `/versicherte/${this.state.currentTab}`,
      search:
        "?" +
        new URLSearchParams({
          content: INSURANCES_MODAL_PAGES.PDF,
          provider: PdfProviders.MUTATIONS,
        }).toString(),
    });
  };

  openSingleFilePdfModal = (fileId) => {
    this.props.history.push({
      search:
        "?" +
        new URLSearchParams({
          content: COCKPIT_MODAL_PAGES.PDF,
          fileId: fileId || "",
        }).toString(),
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      showValidations: false,
      insurantDataLoaded: false,
      backendValidation: {},
      newEntryStep: 1,
    });
    this.props.clearFieldValues();
    if (
      getParameterByName("previous", this.props.location.search) == "cockpit"
    ) {
      this.props.history.push({ pathname: "/cockpit", search: "" });
    } else {
      this.props.history.push({
        pathname: `/versicherte/${this.state.currentTab}`,
        search: "",
      });
    }
  };

  afterOpenModal = () => {
    const modal = document.querySelector(".large-modal");
    if (modal) {
      modal.removeAttribute("tabIndex");
    }
  };

  rowChanged(expanded) {
    const container = document.querySelector(
      ".main-content-container.insurances"
    );
    if (expanded) {
      container.classList.add("expanded");
    } else {
      container.classList.remove("expanded");
    }
  }

  getAddressButtonsContent = (address) => {
    return this.state.insurantData.employee.processingState ===
      ProcessingStates.Committed ||
      this.state.insurantData.employee.processingState ===
        ProcessingStates.Exported
      ? []
      : [
          {
            name: "Edit",
            action: () => this.handleAddressEdit(address),
            showIcon: true,
            iconType: "edit",
          },
          {
            name: "Delete",
            action: () => this.handleAddressDelete(address),
            showIcon: true,
            iconType: "delete",
          },
        ];
  };

  getPayrollButtonsContent = (payroll) => {
    return this.state.insurantData.employee.processingState ===
      ProcessingStates.Committed ||
      this.state.insurantData.employee.processingState ===
        ProcessingStates.Exported
      ? []
      : [
          {
            name: "Edit",
            action: () => this.handlePayrollEdit(payroll),
            showIcon: true,
            iconType: "edit",
          },
          {
            name: "Delete",
            action: () => this.handlePayrollDelete(payroll),
            showIcon: true,
            iconType: "delete",
          },
        ];
  };

  getUnpaidVacationButtonsContent = (unpaidVacation) => {
    return this.state.insurantData.employee.processingState ===
      ProcessingStates.Committed ||
      this.state.insurantData.employee.processingState ===
        ProcessingStates.Exported
      ? []
      : [
          {
            name: "Edit",
            action: () => this.handleUnpaidVacationEdit(unpaidVacation),
            showIcon: true,
            iconType: "edit",
          },
          {
            name: "Delete",
            action: () => this.handleUnpaidVacationDelete(unpaidVacation),
            showIcon: true,
            iconType: "delete",
          },
        ];
  };

  preparePayrollData = (payrollData) => {
    const preparedPayrollData = [];
    Object.keys(payrollData).forEach((payrollId) => {
      payrollData[payrollId]["actions"] = this.getPayrollButtonsContent(
        payrollData[payrollId]
      );
      payrollData[payrollId]["id"] = payrollId;
      preparedPayrollData.push(payrollData[payrollId]);
    });
    preparedPayrollData.forEach((payrollData) => {
      payrollData.validFrom = payrollData.validFrom
        ? parseDate(payrollData.validFrom.slice(0, 10))
        : "";
      payrollData.validUntil = payrollData.validUntil
        ? parseDate(payrollData.validUntil.slice(0, 10))
        : "";
      payrollData.grossSalary = payrollData.grossSalary
        ? formatNumber(payrollData.grossSalary)
        : "";
    });
    const sorted = preparedPayrollData.sort((a, b) => {
      return Comparator(
        new Date(parseToApiDate(a.validFrom)),
        new Date(parseToApiDate(b.validFrom)),
        -1,
        false
      );
    });
    return sorted;
  };

  prepareAddressData = (addressData) => {
    const preparedAddressData = [];
    Object.keys(addressData).forEach((addressId) => {
      addressData[addressId]["actions"] = this.getAddressButtonsContent(
        addressData[addressId]
      );
      addressData[addressId]["id"] = addressId;
      preparedAddressData.push(addressData[addressId]);
    });
    preparedAddressData.forEach((addressData) => {
      addressData.validFrom = addressData.validFrom
        ? parseDate(addressData.validFrom.slice(0, 10))
        : "";
      addressData.validUntil = addressData.validUntil
        ? parseDate(addressData.validUntil.slice(0, 10))
        : "";
    });
    const sorted = preparedAddressData.sort((a, b) => {
      return Comparator(
        new Date(parseToApiDate(a.validFrom)),
        new Date(parseToApiDate(b.validFrom)),
        -1,
        false
      );
    });
    return sorted;
  };

  prepareUnpaidVacationData = (unpaidVacationData) => {
    const preparedUnpaidVacationData = [];
    Object.keys(unpaidVacationData).forEach((unpaidVacationId) => {
      unpaidVacationData[unpaidVacationId]["actions"] =
        this.getUnpaidVacationButtonsContent(
          unpaidVacationData[unpaidVacationId]
        );
      unpaidVacationData[unpaidVacationId]["id"] = unpaidVacationId;
      preparedUnpaidVacationData.push(unpaidVacationData[unpaidVacationId]);
    });
    preparedUnpaidVacationData.forEach((unpaidVacationData) => {
      unpaidVacationData.validFrom = unpaidVacationData.validFrom
        ? parseDate(unpaidVacationData.validFrom.slice(0, 10))
        : "";
      unpaidVacationData.validUntil = unpaidVacationData.validUntil
        ? parseDate(unpaidVacationData.validUntil.slice(0, 10))
        : "";
    });
    return preparedUnpaidVacationData;
  };

  handleAddressEdit = (address) => {
    this.setState(
      {
        selectedAddress: { ...address },
        modalIsOpen: true,
        modalContent: "Adresse bearbeiten",
        modalContentId: this.state.modalContentId,
        modalMessage: "",
      },
      () => {
        this.props.history.push({
          pathname: `/versicherte/${this.state.currentTab}`,
          search:
            "?" +
            new URLSearchParams({
              edit: this.state.modalContentId,
              content: "Adresse bearbeiten",
            }).toString() +
            "&addressId=" +
            address.id,
        });
      }
    );
  };

  handleAddressCreate = (e, formObj) => {
    e.preventDefault();
    this.props.setLoading(true);
    const insurantId = this.state.modalContentId;
    const addressData = prepareCreateAddressPayload(
      insurantId,
      formObj.address
    );
    this.props
      .createAddress(insurantId, emptyStringsToNull(addressData))
      .then((res) => {
        this.setState({ newAddress: {} });
        if (res.errors) {
          this.applyBackendValidation("newAddress", res.errors);
        } else {
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap["Adressaenderung"]
          );
        }
        this.props.setLoading(false);
      })
      .then(() => {
        this.refreshData();
      });
  };

  openDeleteCsvPopup = () => {
    const csv_imports = [...this.props.csv_imports];
    const csv_import = getLatestCsvImport(csv_imports);
    const import_id = csv_import["id"];
    const affectedMutations = csv_import["attributes"]["mutation-count"];
    const affectedCompany =
      this.props.employers[csv_import["attributes"]["employerId"]][
        "attributes"
      ]["name"];
    const affectedCsvImportDate = formatCsvImportDate(
      csv_import["attributes"]["imported-at"]
    );
    this.props.setPopup(true);
    this.setState({
      popupId: import_id,
      popupContent: VERSICHERTE_POPUP_CONTENT.DELETE_CSV,
      affectedMutations,
      affectedCompany,
      affectedCsvImportDate,
    });
  };

  openDeleteJobPopup = () => {
    const job_imports = [...this.props.job_imports];
    const job_import = job_imports?.find((job) => !job.isCommitted);
    if (!job_import) return;
    console.log(job_import);
    // return;
    const import_id = job_import["id"];
    const affectedMutations = job_import["attributes"]["numberOfMutations"];
    const affectedCompany = job_import["attributes"]["employers"]
      .map(
        (employerId) => this.props.employers[employerId]?.["attributes"]["name"]
      )
      ?.join(", ");
    const affectedCsvImportDate = formatCsvImportDate(
      job_import["attributes"]["startedAt"]
    );
    this.props.setPopup(true);
    this.setState({
      popupId: import_id,
      popupContent: VERSICHERTE_POPUP_CONTENT.DELETE_CSV,
      affectedMutations,
      affectedCompany,
      affectedCsvImportDate,
    });
  };

  openDeleteMutationPopup = (id) => {
    this.props.setLoading(true);
    this.props.deleteMutation(id, false).then((res) => {
      let affectedMutations = "";
      if (res?.errors?.[0]?.meta?.mutations) {
        affectedMutations = res["errors"][0]["meta"]["mutations"].length;
      }
      this.props.setPopup(true);
      this.props.setLoading(false);
      this.setState({
        popupId: id,
        popupContent: VERSICHERTE_POPUP_CONTENT.DELETE_MUTATION,
        affectedMutations: affectedMutations,
      });
    });
  };

  deleteMutation(id) {
    this.props.setLoading(true);
    this.props.deleteMutation(id, true).then((res) => {
      if (!res.errors) {
        toastr.success("Mutation", "Mutation wurde gelöscht.");
        this.refreshData();
      }
      this.props.setLoading(false);
      this.props.setPopup(false);
    });
  }

  deleteCsv(id) {
    this.props.setLoading(true);
    this.props.deleteCsv(id).then((res) => {
      if (!res.errors) {
        toastr.success("CSV", "CSV wurde gelöscht.");
        this.refreshData();
        this.props.setLoading(false);
        this.props.setPopup(false);
      }
    });
  }

  deleteImportJob(id) {
    this.props.setLoading(true);
    this.props.deleteImportJob(id).then((res) => {
      if (!res.errors) {
        toastr.success(
          "Import gelöscht",
          "Importierte Daten wurden zurückgesetzt"
        );
        this.refreshData();
        this.props.setLoading(false);
        this.props.setPopup(false);
      }
    });
  }

  handleAddressUpdate = (e, formObj) => {
    this.props.setLoading(true);
    const payload = prepareUpdateAddressPayload(formObj.address);
    this.props
      .patchAddress(this.state.modalContentId, payload.data.id, payload)
      .then((res) => {
        if (res.errors) {
          this.applyBackendValidation("newAddress", res.errors);
          this.props.setLoading(false);
        } else {
          // this needs a translation
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap["Adresskorrektur"]
          );
          this.props.setLoading(false);
          this.props.setInsurantsFetched(false);
          this.props.fetchLocalEmployees({
            contentId: this.state.modalContentId,
            payloadType: "address",
            payload: res.data.attributes,
          });
          this.refreshMutations();
        }
      });
  };

  handleAddressDelete = (address) => {
    const insurantId = this.state.modalContentId;
    this.props.deleteAddress(insurantId, address.id).then((res) => {
      if (!res.errors) {
        toastr.success("Adresse wurde gelöscht");
        this.props.fetchAddresses(insurantId);
        this.refreshData();
      }
    });
  };

  handlePayrollCreate = (e, formObj) => {
    this.props.setLoading(true);
    const payload = prepareCreatePayrollPayload(formObj);
    this.props.createPayroll(this.state.modalContentId, payload).then((res) => {
      this.setState({ newPayroll: {} });
      console.log("RESSSSS", res);
      if (res.errors) {
        this.applyBackendValidation("newPayroll", res.errors);
      } else {
        // this needs a translation
        this.openModal(
          this.state.modalContentId,
          "success",
          mutationTypesToMessageMap["Lohnaenderung"]
        );
        this.refreshData();
      }
      this.props.setLoading(false);
      this.setState({ payrollMode: "" });
    });
  };

  handlePayrollUpdate = (e, formObj) => {
    this.props.setLoading(true);
    const payload = prepareUpdatePayrollPayload(formObj.payroll);
    this.props
      .patchPayroll(this.state.modalContentId, payload.data.id, payload)
      .then((res) => {
        this.props.setLoading(false);
        if (res.errors) {
          this.applyBackendValidation("newPayroll", res.errors);
        } else {
          // this needs a translation
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap["Lohnkorrektur"]
          );
          this.props.setInsurantsFetched(false);
          this.props.fetchLocalEmployees({
            contentId: this.state.modalContentId,
            payloadType: "payroll",
            payload: res.data.attributes,
          });
          this.refreshMutations();
          this.setState({ payrollMode: "" });
        }
      });
  };

  handlePayrollAddEditOrRemoveModal = (payroll, mutationFormTypes) => {
    this.setState(
      {
        selectedPayroll: payroll,
        modalIsOpen: true,
        modalContent: mutationFormTypes,
        modalContentId: this.state.modalContentId,
        modalMessage: "",
      },
      () => {
        this.props.history.push({
          pathname: `/versicherte/${this.state.currentTab}`,
          search:
            "?" +
            new URLSearchParams({
              edit: this.state.modalContentId,
              content: mutationFormTypes,
            }).toString(),
        });
      }
    );
  };

  handleNewPayroll = () => {
    this.setState(
      {
        selectedPayroll: this.state.selectedPayroll,
        payrollMode: "new",
        modalIsOpen: true,
        modalContent: "Neuer Lohn",
        modalContentId: this.state.modalContentId,
        modalMessage: "",
      },
      () => {
        this.props.history.push({
          pathname: `/versicherte/${this.state.currentTab}`,
          search:
            "?" +
            new URLSearchParams({
              edit: this.state.modalContentId,
              content: "Neuer Lohn",
            }).toString() +
            "&payrollId=" +
            this.state.selectedPayroll.id,
        });
      }
    );
  };

  handlePayrollEdit = (payroll) => {
    this.setState(
      {
        selectedPayroll: payroll,
        payrollMode: "edit",
        modalIsOpen: true,
        modalContent: "Neuer Lohn",
        modalContentId: this.state.modalContentId,
        modalMessage: "",
      },
      () => {
        this.props.history.push({
          pathname: `/versicherte/${this.state.currentTab}`,
          search:
            "?" +
            new URLSearchParams({
              edit: this.state.modalContentId,
              content: "Neuer Lohn",
            }).toString() +
            "&payrollId=" +
            this.state.selectedPayroll.id,
        });
      }
    );
  };

  handlePayrollDelete = (payroll) => {
    this.props
      .deletePayroll(
        this.state.modalContentId,
        payroll?.id ? payroll.id : this.state.selectedPayroll.id
      )
      .then((res) => {
        if (!res.errors) {
          // this needs a translation
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap["Lohnlöschung"]
          );
          this.props.fetchPayrolls(this.state.modalContentId);
          this.refreshData();
        }
      });
  };

  handleUnpaidVacationEdit = (unpaidVacation) => {
    this.setState(
      {
        selectedUnpaidVacation: unpaidVacation,
        modalIsOpen: true,
        modalContent: "Unbezahlter Urlaub bearbeiten",
        modalContentId: this.state.modalContentId,
        modalMessage: "",
      },
      () => {
        // this.openModal(this.state.modalContentId, 'Lohn bearbeiten');
        this.props.history.push({
          pathname: `/versicherte/${this.state.currentTab}`,
          search:
            "?" +
            new URLSearchParams({
              edit: this.state.modalContentId,
              content: "Unbezahlter Urlaub bearbeiten",
            }).toString() +
            "&unpaidVacationId=" +
            unpaidVacation.id,
        });
      }
    );
  };

  handleUnpaidVacationCreate = (e, formObj) => {
    this.props.setLoading(true);
    const payload = prepareCreateUnpaidvacationPayload({
      ...formObj.unpaidVacation,
      id: formObj.unpaidVacation.employerId,
    });
    this.props
      .createUnpaidVacation(this.state.modalContentId, payload)
      .then((res) => {
        if (res.errors) {
          this.applyBackendValidation("newUnpaidVacation", res.errors);
        } else {
          this.setState({ newUnpaidVacation: {} });
          // this needs a translation
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap["UnbezahlterUrlaub"]
          );
          this.refreshData();
        }
        this.props.setLoading(false);
      });
  };

  handleUnpaidVacationUpdate = (e, formObj) => {
    this.props.setLoading(true);
    const payload = prepareUpdateUnpaidvacationPayload(formObj.unpaidVacation);
    this.props
      .patchUnpaidVacation(this.state.modalContentId, payload.data.id, payload)
      .then((res) => {
        if (res.errors) {
          this.applyBackendValidation("newUnpaidVacation", res.errors);
        } else {
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap["UnbezahlterUrlaubKorrektur"]
          );
          this.props.setInsurantsFetched(false);
          this.props.fetchLocalEmployees({
            contentId: this.state.modalContentId,
            payloadType: "unpaidVacation",
            payload: res.data.attributes,
          });
          this.refreshMutations();
        }
        this.props.setLoading(false);
      });
  };

  handleUnpaidVacationDelete = (unpaidVacation) => {
    this.props
      .deleteUnpaidVacation(this.state.modalContentId, unpaidVacation.id)
      .then((res) => {
        if (!res.errors) {
          toastr.success("Unpaid Vacation", "Unpaid vacation has been deleted");
          this.props.fetchUnpaidVacations(this.state.modalContentId);
          this.refreshData();
        }
      });
  };

  createNewInsurant = (e, formObj) => {
    const payload = prepareCreateNewInsurantPayload(formObj);
    this.props.employeeActions
      .createInsurant(payload)
      .then((res) => {
        if (res.errors) {
          this.applyBackendValidation("eintrittErfassenForm", res.errors);
          toastr.error(res.errors[0].title, res.errors[0].detail || ""); //temporary
        } else {
          this.setState({
            eintrittErfassenForm: {
              person: {},
              address: {},
              payroll: {},
              employee: {},
            },
            newEntryStep: 1,
          });
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap["Eintritt"]
          );
          this.refreshData();
        }
      })
      .finally(() => {
        this.props.setLoading(false);
      });
  };

  patchInsurant = (e, formObj, forcedMutationType?) => {
    const formToMutationMap = objectFlip(insurantMutationTypesToFormsMap);
    const mutationType =
      forcedMutationType || formToMutationMap[this.state.modalContent];
    const payload = preparePatchInsurantPayload(formObj);
    payload.meta = { mutationType: mutationType };
    // payload.data.attributes = JSON.parse(JSON.stringify(formObj));
    payload.data.id = this.state.modalContentId;

    this.props.employeeActions
      .editInsurant(payload, this.state.modalContentId)
      .then((res) => {
        if (res.errors) {
          this.applyBackendValidation("insurantDataPatch", res.errors);
          this.props.setLoading(false);
        } else {
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap[mutationType]
          );
          this.props.setInsurantsFetched(false);
          this.props.fetchLocalEmployees({
            contentId: this.state.modalContentId,
            payloadType:
              mutationType === "Pensionierung" || mutationType === "Austritt"
                ? "employee"
                : undefined,
            payload:
              mutationType === "Pensionierung" || mutationType === "Austritt"
                ? res.data.attributes.employee
                : res.data.attributes,
          });
          this.refreshMutations();
          this.props.setLoading(false);
        }
      });
  };

  patchMutation = (e, formObj, forcedMutationType?) => {
    const mutation = this.props.mutationsObj[this.state.modalContentId];
    const formToMutationMap = objectFlip(mutationMutationTypesToFormMap);
    const mutationType =
      forcedMutationType || formToMutationMap[this.state.modalContent];
    const formObjName = mutationTypeToFormObj[this.state.modalContent]
      ? mutationTypeToFormObj[this.state.modalContent]
      : "insurantDataPatch";
    const payload = preparePatchMutationPayload(mutation, formObj);

    const ignoreWarnings =
      this.state.modalContent == mutationFormTypes.NewEntry ||
      this.state.modalContent == mutationFormTypes.EditNewEntry;
    console.log("update mutation payload", payload);

    this.props
      .patchMutation(payload, this.state.modalContentId, ignoreWarnings)
      .then((res) => {
        if (res.errors) {
          this.applyBackendValidation(formObjName, res.errors);
          this.props.setLoading(false);
        } else {
          // this needs a translation
          this.props.setMutationsFetched(false);
          this.openModal(
            this.state.modalContentId,
            "success",
            mutationTypesToMessageMap[mutationType]
          );
          this.props.fetchLocalEmployees({
            contentId: res.data.attributes.employeeId,
            payload: res.data.attributes.values,
          });
          this.props.fetchLocalMutations(
            this.state.modalContentId,
            res.data.attributes
          );
          this.props.setLoading(false);
          this.setState({
            newEntryStep: 1,
          });
        }
      });
  };

  uploadCsv = async (e, data) => {
    let chunks = [];
    const employer = data.employerId;
    const file = data.file;
    const size = file.size;
    const partSize = 262000;

    var fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = function (e) {
      const content = e.target.result;
      let i = 0;
      let partNumber = 0;

      while (i < size) {
        chunks.push({
          number: partNumber,
          content: arrayBufferToBase64(content.slice(i, i + partSize)),
        });
        i += partSize;
        partNumber++;
      }
    };

    this.props
      .initializeUpload({
        data: {
          type: "file",
          attributes: {
            name: file.name,
            mimeType: file.type,
            size: size,
          },
        },
        jsonapi: {},
      })
      .then(async (res) => {
        const fileId = res.data.id;
        const requests = [];

        this.setState({ popupContent: "csvImport" });
        this.props.setPopup(true);
        this.props.setLoading(true);

        for (let j = 0; j < chunks.length; j++) {
          await this.props
            .uploadPart(fileId, {
              data: {
                type: "filepart",
                attributes: {
                  content: chunks[j].content,
                  number: chunks[j].number,
                },
              },
              jsonapi: {},
            })
            .then(function () {
              // @ts-ignore
              requests.push(this);
            })
            .then(() => {
              this.setState({
                progressMax: chunks.length,
                progressValue: j,
              });
            });
        }

        Promise.all(requests).then(() => {
          this.props
            .uploadCsv({
              data: {
                type: "csv-import",
                attributes: {
                  fileId: fileId,
                  employerId: employer,
                  // profileId: "9142e247-82da-4a3c-895b-abe3008f3aa4"
                },
              },
              jsonapi: {},
            })
            .then(() => {
              this.refreshData();
              this.props.setLoading(false);
              this.props.setPopup(false);
              this.setState({ progressValue: chunks.length });
              this.closeModal();
            });
        });
      });
  };

  uploadExcel = async (e, data) => {
    // const employer = data.employerId;
    const file = data.file;

    const formData = new FormData();
    formData.append("file", file);
    this.props.setLoading(true);

    await this.props.uploadExcel(formData).then((response) => {
      if (!!response.errors || response === "") {
        this.props.setLoading(false);
        return;
      }

      const stateCheck = setInterval(() => {
        this.props
          .fetchImportJobState(response.data.id)
          .then(({ data: { attributes } }) => {
            const isImporting =
              attributes.numberOfSubmittedEmployments !=
              attributes.numberOfProcessedEmployments;

            // this.props.setLoading(true);
            this.setState({
              isImporting,
              excelProcessedMutations:
                attributes.numberOfSubmittedEmployments -
                attributes.numberOfProcessedEmployments,
            });

            // if (this.props.location.search.includes("Excel+Importieren")) {
            //   this.closeModal();
            // }

            if (attributes.isFinished) {
              this.setState({ isImporting });
              this.props.setLoading(false);
              this.openModal(
                null,
                "ImportExcelSuccess",
                "Ihre Mutationen wurden komplett erstellt."
              );
              clearInterval(stateCheck);
            }
          });
      }, 3000);
    });
  };

  submitExportExcel = (form: ExcelExport) => {
    this.props.setLoading(true);
    fetchExcelImports({
      ...form.formExcel,
      validFrom: parseToApiDate(form.formExcel.validFrom).split("T")[0],
    }).then((response) => {
      if (response.errors) {
        this.props.setLoading(false);
      } else {
        fetchExcelFiles(response.split("=")[1])
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${form?.formExcel.employerId}-${Date.now()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            this.props.setLoading(false);
          })
          .catch(() => {
            this.props.setLoading(false);
          });
      }
    });
  };

  removeBackendValidationMessage = (formName, modelName, inputName) => {
    console.log(formName, modelName, inputName);
    const backendValidation = { ...this.state.backendValidation };
    if (
      backendValidation[formName] &&
      backendValidation[formName][modelName] &&
      backendValidation[formName][modelName][inputName]
    ) {
      delete backendValidation[formName][modelName][inputName];
      this.setState({ backendValidation: backendValidation });
    }
  };

  newEntryFormSteps = [
    {
      number: 1,
      text: this.props.intl.formatMessage({
        id: "app.personalinfo",
        defaultMessage: "Personalien",
      }),
    },
    {
      number: 2,
      text: this.props.intl.formatMessage({
        id: "app.insurance",
        defaultMessage: "Versicherung",
      }),
    },
    {
      number: 3,
      text: this.props.intl.formatMessage({
        id: "app.salary",
        defaultMessage: "Lohn",
      }),
    },
    {
      number: 4,
      text: this.props.intl.formatMessage({
        id: "app.address",
        defaultMessage: "Adresse",
      }),
    },
  ];

  activatePersonenTab = () => {
    this.setState({ expandedRow: null });

    this.props.history.push({
      pathname: "/versicherte/personen",
    });
  };

  activateMutationTab = () => {
    this.setState({ expandedRow: null });
    this.props.history.push({
      pathname: "/versicherte/mutationen",
    });
  };

  commitMutations = (type?) => {
    let employers = prepareEmployersForCommit(
      this.props.employers,
      this.props.mutations
    );
    if (employers.length == 0) {
      return;
    }

    if (
      (this.props.filterByFirmaMutations && !type) ||
      (employers.length == 1 && !type)
    ) {
      this.setState({
        popupContent: VERSICHERTE_POPUP_CONTENT.COMMIT_MUTATIONS,
      });
      this.props.setPopup(true);
    } else if (employers.length > 1 && !type) {
      this.props.history.push({
        pathname: "/cockpit",
        search: "?" + new URLSearchParams({ content: "commit" }).toString(),
        state: {
          previousPage: this.props.location.pathname,
        },
      });
    } else if (type && type == "post") {
      this.props.setLoading(true);
      this.setState({ commitLoading: true });
      const payload = { ...commitMutationsPayload };
      if (this.props.filterByFirmaMutations) {
        payload.data.attributes.employers = [this.props.filterByFirmaMutations];
      } else {
        payload.data.attributes.employers = [employers[0]["value"]];
      }

      this.props.commitMutations(payload).then((res) => {
        this.setState({ commitLoading: false });
        let fileId =
          res && res.data
            ? res.data.attributes.summary.split("FileId=")[1]
            : null;

        if (fileId) {
          this.refreshData();
          this.props.setPopup(false);
          this.props.history.push({
            pathname: "/cockpit",
            search:
              "?" +
              new URLSearchParams({ content: "success", fileId }).toString(),
            state: {
              fromInsurancesPage: true,
            },
          });
        } else {
          console.error("FileId is not defined", fileId);
        }
        this.props.setLoading(false);
      });
    }
  };

  handleCreateInsurant = () => {
    this.setState(
      {
        newEntryStep: 1,
        eintrittErfassenForm: {
          person: {},
          address: {},
          payroll: {},
          employee: {},
          contact: {},
        },
      },
      () => {
        this.openModal("", mutationFormTypes.NewEntry);
      }
    );
  };

  loadMore = () => {
    const { currentTab } = this.state;
    const { moreInsurants, moreMutations } = this.props;
    if (currentTab === INSURANCES_TABS.PersonenTab && moreInsurants) {
      this.setState({ loading: true });
      this.props.fetchEmployees().then(() => {
        this.setState({ loading: false });
        this.props.setInsurantsPageNo(this.props.insurantsPageNo + 1);
      });
    }
    if (currentTab === INSURANCES_TABS.MutationenTab && moreMutations) {
      this.setState({ loading: true });
      this.props.fetchMutations().then(() => {
        this.setState({ loading: false });
        this.props.setMutationsPageNo(this.props.mutationsPageNo + 1);
      });
    }
  };

  handleScroll = (e) => {
    const { loading } = this.state;
    const element = e.target;
    const bottom =
      Math.floor(element.scrollHeight - element.scrollTop) <=
        element.clientHeight + 200 &&
      Math.floor(element.scrollHeight - element.scrollTop) >=
        element.clientHeight;
    if (bottom && !loading) {
      this.loadMore();
    }
  };

  handleSetLoading = (value) => {
    this.setState({
      loading: value,
      filtersChanged: this.state.filtersChanged + 1,
    });
  };

  refreshMutations = () => {
    this.props.setOpenMutationsFetched(false);
    this.props.setCompleteMutationsFetched(false);
    this.props.setMutationsFetched(false);
    this.props.fetchMutations(parseInt(paginationSize), 0);
    this.props.fetchOpenMutations();
    this.props.fetchCompleteMutations();
    this.props.fetchImportJobs();
    this.props.fetchMutationsCount();
    this.props.fetchEmployers();
  };

  refreshData = () => {
    this.props.setInsurantsFetched(false);
    this.props.setInsurantsPageNo(0);
    this.props
      .fetchEmployees(parseInt(paginationSize), 0)
      .then(() => this.props.setInsurantsPageNo(1));
    this.refreshMutations();
    // this.props.fetchCsv();
  };

  onFormSubmit = (e, formObj) => {
    e.preventDefault();
    const { currentTab, modalContent } = this.state;
    if (currentTab == "mutationen") {
      if (modalContent == mutationFormTypes.CsvImport) {
        this.uploadCsv(e, formObj);
      }
      // else if (modalContent == mutationFormTypes.ExcelImport) {
      //   this.uploadExcel(e, formObj);
      // }
      else {
        this.patchMutation(e, formObj);
      }
    } else {
      if (modalContent == mutationFormTypes.NewAddress) {
        this.handleAddressCreate(e, formObj);
      } else if (modalContent == mutationFormTypes.EditAddress) {
        this.handleAddressUpdate(e, formObj);
      } else if (
        modalContent == mutationFormTypes.NewPayroll &&
        (this.state.payrollMode === "new" || this.state.payrollMode === "")
      ) {
        this.handlePayrollCreate(e, formObj);
      } else if (
        modalContent == mutationFormTypes.NewPayroll &&
        this.state.payrollMode === "edit"
      ) {
        this.handlePayrollUpdate(e, formObj);
      } else if (modalContent == mutationFormTypes.NewUnpaidVacation) {
        this.handleUnpaidVacationCreate(e, formObj);
      } else if (modalContent == mutationFormTypes.EditUnpaidVacation) {
        this.handleUnpaidVacationUpdate(e, formObj);
      } else if (modalContent == mutationFormTypes.NewEntry) {
        this.createNewInsurant(e, formObj);
      } else if (modalContent == mutationFormTypes.ImportExcel) {
        this.uploadExcel(e, formObj);
      } else {
        this.patchInsurant(e, formObj);
      }
    }
  };

  applyBackendValidation = (formObjName, errors) => {
    let backendValidation = prepareBackendValidationObject(
      formObjName,
      this.state.backendValidation
    );
    errors.forEach((error) => {
      if (error?.source?.pointer) {
        const pointer = error.source.pointer;
        const properties = pointer.split("/");
        const model = properties[properties.length - 2];
        const input = properties[properties.length - 1];
        backendValidation[formObjName][model][input] = {
          message: error.detail || error.title,
          type: EValidationMessageType.Error,
        };
      }
    });
    this.setState({
      showValidations: true,
      backendValidation: backendValidation,
    });
  };

  onStickyActionsButtonClick = () => {
    const opened = this.state.stickyActionButtonsOpened;
    this.setState({ stickyActionButtonsOpened: !opened });
  };

  allEmployersCommitted = (employersObj) => {
    if (employersObj && Object.keys(employersObj).length > 0) {
      const employers = Object.values(employersObj);
      const uncommitedEmployers = employers.filter(
        (employer) => employer["attributes"]["processingState"] != 2
      );
      return !uncommitedEmployers.length;
    } else {
      return true;
    }
  };

  stickyActions = (csvImportEnabled, excelImportEnabled) => {
    return [
      {
        content: (
          <>
            <PlusIcon className="mr-12" />
            <FormattedMessage
              id="app.addnewentry"
              defaultMessage="Eintritt Erfassen"
            />
          </>
        ),
        action: () => this.openModal(null, mutationToFormMap.Eintritt),
        visible: !!(
          !this.allEmployersCommitted(this.props.employers) &&
          this.state.currentTab == INSURANCES_TABS.PersonenTab
        ),
      },
      {
        content: (
          <>
            <PlusIcon className="mr-12" />
            <FormattedMessage
              id="app.payround"
              defaultMessage="Lohnrunde Erfassen"
            />
          </>
        ),
        action: () => this.openModal(null, mutationToFormMap.Lohnrunde),
        visible: !!(
          excelImportEnabled &&
          this.state.currentTab == INSURANCES_TABS.PersonenTab
        ),
      },
      {
        content: (
          <>
            <ImportIcon className="mr-12" />
            <FormattedMessage
              id="app.csvimport"
              defaultMessage="CSV Importieren"
            />
          </>
        ),
        action: () => this.openModal(null, mutationFormTypes.CsvImport),
        visible: !!(
          csvImportEnabled &&
          this.state.currentTab == INSURANCES_TABS.MutationenTab
        ),
      },
      {
        content: (
          <>
            <TrashIcon className="mr-12" />
            Import löschen
          </>
        ),
        action: () => this.openDeleteJobPopup(),
        visible:
          !!this.props.job_imports?.find((job) => !job.isCommitted) &&
          this.state.currentTab == INSURANCES_TABS.MutationenTab,
      },
    ];
  };

  clearMutationFilters = () => {
    this.props.filterMutations({ name: "clear" });
    this.props.setMutationsPageNo(0);
    this.setState({
      loading: true,
      filtersChanged: this.state.filtersChanged + 1,
    });
    this.props.fetchMutations().then(() => {
      this.setState({ loading: false });
      this.props.setMutationsPageNo(1);
    });
  };

  onSubmitRevert = ({
    id,
    employeeId,
    reason,
  }: {
    id: string;
    employeeId: string;
    reason?: string;
  }) => {
    this.props.setLoading(true);
    const data = { data: { id, type: "mutation" } };
    this.props.revertMutations(reason, data).then((response) => {
      if (!response.errors) {
        this.props.setMutationsPageNo(0);
        this.props.fetchMutations().then(() => {
          this.props.fetchEmployers();
          this.props.fetchInsurantMutations(employeeId);
          this.setState({ loading: false });
          this.props.setMutationsPageNo(1);
          this.props.setLoading(false);
        });
      } else {
        this.props.setLoading(false);
      }
    });
  };

  render() {
    const {
      currentTab,
      loading,
      popupContent,
      insurantDataLoaded,
      commitLoading,
    } = this.state;
    const { insurantsFetched, mutationsFetched, fetchedEntities } = this.props;
    const isPersonenTab = currentTab === INSURANCES_TABS.PersonenTab;
    const isMutationenTab = currentTab === INSURANCES_TABS.MutationenTab;
    const isLoading =
      !insurantsFetched || !mutationsFetched || loading || !fetchedEntities;
    const csvImportEnabled = JSON.parse(localStorage.getItem("global-settings"))
      ?.attributes?.hasCsvImportEnabled;
    const excelImportEnabled = JSON.parse(
      localStorage.getItem("global-settings")
    )?.attributes?.hasExcelImportEnabled;
    const visibleStickyActions = this.stickyActions(
      csvImportEnabled,
      excelImportEnabled
    ).filter((action) => action.visible).length;
    const areMutationFiltersApplied = !!(
      this.props.filterByNameMutations ||
      this.props.filterByAlsoClosedMutations ||
      this.props.filterByTypeMutations ||
      (this.props.filterByFirmaMutations && !this.props.mutations.length)
    );
    const showStickyActionButton = !!this.props.mutations.filter(
      (mutation) =>
        mutation.attributes.processingState == ProcessingStates.Changed
    ).length;
    const isModalOpen = !!this.props.location.search.split("?edit=")[1];

    return (
      <div className="main-container">
        {commitLoading && <Spinner relative />}

        <Header
          hasMoreContent
          title="Versicherte"
          locales={this.props.locales}
          onFiltersButtonClick={() =>
            this.setState({ filtersOpened: !this.state.filtersOpened })
          }
        >
          <Tab
            active={isPersonenTab}
            minWidth
            className="mr-5"
            onClick={this.activatePersonenTab}
            tabIndex={0}
          >
            <span className="mdc-tab__text-label">
              <FormattedMessage id="app.persons" defaultMessage="Personen" />
            </span>
          </Tab>
          <Tab
            active={isMutationenTab}
            minWidth
            onClick={this.activateMutationTab}
            tabIndex={0}
          >
            <span className="mdc-tab__text-label">
              <FormattedMessage
                id="app.mutations"
                defaultMessage="Mutationen"
              />
            </span>
          </Tab>
        </Header>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          onAfterOpen={this.afterOpenModal}
          contentLabel="Large Modal"
          className={classNames("large-modal", {
            "modal-fade-out": this.state.modalFadeOut,
          })}
          overlayClassName={"large-modal-overlay"}
        >
          <ModalContent
            onClose={this.closeModal}
            onPdfClick={(id) => this.openPdfModal("pdf", id, "")}
            onEditClick={(id, content) =>
              this.openModal(this.state.modalContentId, content)
            }
            backendValidation={this.state.backendValidation}
            removeBackendValidation={this.removeBackendValidationMessage}
            onFormSubmit={(e, formObj) => this.onFormSubmit(e, formObj)}
            submitExportExcel={(form) => this.submitExportExcel(form)}
            onSubmitRevert={(e) => this.onSubmitRevert(e)}
            currentTab={this.state.currentTab}
            entryFormStep={this.state.newEntryStep}
            resetEntryForm={() => this.setState({ newEntryStep: 1 })}
            nextEntryFormStep={() =>
              this.setState({ newEntryStep: this.state.newEntryStep + 1 })
            }
            previousEntryFormStep={() =>
              this.setState({ newEntryStep: this.state.newEntryStep - 1 })
            }
            insurantData={this.state.insurantData}
            insurantDataLoaded={this.state.insurantDataLoaded}
            eintrittErfassenForm={this.state.eintrittErfassenForm}
            modalMessage={this.state.modalMessage}
            modalContent={this.state.modalContent}
            modalContentId={this.state.modalContentId}
            provider={this.state.provider}
            pdfFileId={this.state.pdfFileId}
            onAddressEditClick={this.handleAddressEdit}
            onAddressDeleteClick={this.handleAddressDelete}
            onPayrollEditClick={this.handlePayrollEdit}
            onPayrollDeleteClick={this.handlePayrollDelete}
            onNewPayrollClick={this.handleNewPayroll}
            payrollMode={this.state.payrollMode}
            onUnpaidVacationEditClick={this.handleUnpaidVacationEdit}
            onUnpaidVacationDeleteClick={this.handleUnpaidVacationDelete}
            addressesTableData={
              insurantDataLoaded &&
              this.prepareAddressData(this.props.addresses)
            }
            handlePayrollAddEditOrRemoveModal={
              this.handlePayrollAddEditOrRemoveModal
            }
            payrollsTableData={
              insurantDataLoaded && this.preparePayrollData(this.props.payrolls)
            }
            unpaidVacationsTableData={
              insurantDataLoaded &&
              this.prepareUnpaidVacationData(this.props.unpaidVacations)
            }
            newAddress={this.state.newAddress}
            selectedAddress={this.state.selectedAddress}
            selectedPayroll={this.state.selectedPayroll}
            newUnpaidVacation={this.state.newUnpaidVacation}
            selectedUnpaidVacation={this.state.selectedUnpaidVacation}
            openExcelOptions={(item) => {
              this.openModal(
                null,
                item === "import"
                  ? mutationFormTypes.ImportExcel
                  : mutationFormTypes.ExportExcel
              );
            }}
            excelGoBack={() => {
              this.openModal(null, mutationToFormMap.Lohnrunde);
            }}
            // excelImportSubmit={()=>this.openModal(null, "ImportExcelSuccess", "Ihre Mutationen wurden komplett erstellt.")}
            excelSuccessRedirect={() => {
              this.closeModal();
              this.activateMutationTab();
              this.refreshMutations();
              this.refreshData();
            }}
          />
        </Modal>

        <Modal
          isOpen={this.props.popupIsOpen}
          contentLabel="Small Modal"
          className={classNames("small-modal", {
            "csv-modal": this.state.modalContent == mutationFormTypes.CsvImport,
          })}
          overlayClassName={"small-modal-overlay"}
        >
          {popupContent == VERSICHERTE_POPUP_CONTENT.DELETE_MUTATION && (
            <DeleteMutationsPopup
              key="delete-mutation-popup"
              onSubmit={() => this.deleteMutation(this.state.popupId)}
              onCancel={() => this.props.setPopup(false)}
              affectedMutations={this.state.affectedMutations}
              affectedCompany={this.state.affectedCompany}
              affectedCsvImportDate={this.state.affectedCsvImportDate}
            />
          )}

          {popupContent == "csvImport" && (
            <CsvImportPopup
              progressMax={this.state.progressMax}
              progressValue={this.state.progressValue}
            />
          )}

          {popupContent == VERSICHERTE_POPUP_CONTENT.DELETE_CSV && (
            <DeleteMutationsPopup
              key="delete-csv-popup"
              onSubmit={() => this.deleteImportJob(this.state.popupId)}
              isImportMutation={true}
              onCancel={() => this.props.setPopup(false)}
              affectedMutations={this.state.affectedMutations}
              affectedCompany={this.state.affectedCompany}
              affectedCsvImportDate={this.state.affectedCsvImportDate}
            />
          )}

          {popupContent == VERSICHERTE_POPUP_CONTENT.COMMIT_MUTATIONS && (
            <CommitMutationsPopup
              onSubmit={() => this.commitMutations("post")}
              onCancel={() => this.props.setPopup(false)}
            />
          )}
        </Modal>

        <div className="main-content-container">
          <div className="relative-container">
            {!isModalOpen && isLoading && (
              <Spinner
                lowZIndex={
                  (!mutationsFetched || !insurantsFetched) && fetchedEntities
                }
                relative
              />
            )}
            <div
              className="main-content-container-left"
              onScroll={this.handleScroll}
            >
              {isPersonenTab && (
                <PersonenTab
                  morePadding={showStickyActionButton}
                  isLoading={isLoading}
                  actionsButton={() =>
                    this.stickyActions(csvImportEnabled, excelImportEnabled)
                  }
                  filtersChanged={this.state.filtersChanged}
                  setLoading={this.handleSetLoading}
                  onNewEntryClick={() => this.handleCreateInsurant()}
                  onDetailsClick={(id) =>
                    this.openModal(id, modalPageTypes.INSURANT_DETAILS)
                  }
                  onEditClick={(id, type) => this.openModal(id, type)}
                />
              )}
              {isMutationenTab && (
                <MutationenTab
                  morePadding={showStickyActionButton}
                  isLoading={isLoading}
                  actionsButton={() =>
                    this.stickyActions(csvImportEnabled, excelImportEnabled)
                  }
                  isImporting={this.state.isImporting}
                  excelProcessedMutations={this.state.excelProcessedMutations}
                  filtersChanged={this.state.filtersChanged}
                  setLoading={this.handleSetLoading}
                  onCsvImportClick={() =>
                    this.openModal(null, mutationFormTypes.CsvImport)
                  }
                  onExcelImportClick={() =>
                    this.openModal(null, mutationFormTypes.ExcelImport)
                  }
                  onDeleteImportJob={() => this.openDeleteJobPopup()}
                  onDownloadClick={(id) => this.openSingleFilePdfModal(id)}
                  onDeleteClick={(id) => this.openDeleteMutationPopup(id)}
                  onEditClick={(id, type) => this.openModal(id, type)}
                />
              )}
            </div>

            {showStickyActionButton && this.props.totalMutations > 0 && (
              <StickyButton
                canSubmitAll={true}
                tabs={[
                  {
                    label: "Alle",
                    count: this.props.totalMutations,
                    persist: true,
                  },
                  {
                    label: "OK",
                    count:
                      this.props.totalMutations -
                      this.props.warningMutations -
                      this.props.errorMutations,
                  },
                  { label: "Warnung", count: this.props.warningMutations },
                  { label: "Fehler", count: this.props.errorMutations },
                ]}
                onButtonClick={this.commitMutations}
                isDisabledByValidation={areMutationFiltersApplied}
                onClearValidation={() => this.clearMutationFilters()}
              />
            )}

            {visibleStickyActions ? (
              <StickyActions
                actions={this.stickyActions(
                  csvImportEnabled,
                  excelImportEnabled
                )}
              />
            ) : null}
          </div>

          <div className="drawer" ref={(node) => (this.drawer = node)}>
            <Drawer opened={this.state.filtersOpened}>
              {isPersonenTab && (
                <PersonenSidebar
                  excelImportEnabled={excelImportEnabled}
                  openPdfModal={() => this.openAllInsurantsPdfModal()}
                  openExcelModal={() => this.openAllInsurantsExcelModal()}
                  setLoading={this.handleSetLoading}
                />
              )}
              {isMutationenTab && (
                <MutationenSidebar
                  openPdfModal={() => this.openAllMutationsModal()}
                  setLoading={this.handleSetLoading}
                />
              )}
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,

    locales: state.locales,
    isLoading: state.app.loading,
    entities: state.app.entities,
    employers: state.app.employers,
    popupIsOpen: state.app.popupIsOpen,
    mutationTypes: state.app.mutationTypes,
    sidebarOpened: state.app.sidebarOpened,

    unpaidVacations: state.unpaidvacations.unpaidVacations,
    addresses: state.addresses.addresses,
    payrolls: state.payrolls.payrolls,

    invoices: state.invoices.invoices,

    insurants: state.insurants.insurants,
    insurantsObj: state.insurants.insurantsObj,
    moreInsurants: state.insurants.moreInsurants,
    insurantsFetched: state.insurants.insurantsFetched,
    insurantsPageNo: state.insurants.insurantsPageNo,
    filterByNamePerson: state.insurants.filterByNamePerson,
    filterByFirmaPerson: state.insurants.filterByFirmaPerson,
    filterByOnlyChangedPerson: state.insurants.filterByOnlyChangedPerson,
    filterByAlsoInactivePerson: state.insurants.filterByAlsoInactivePerson,

    mutations: state.mutations.mutations,
    mutationsObj: state.mutations.mutationsObj,
    moreMutations: state.mutations.moreMutations,
    mutationsFetched: state.mutations.mutationsFetched,
    mutationsPageNo: state.mutations.mutationsPageNo,

    totalMutations: state.mutations.total,
    warningMutations: state.mutations.warnings,
    errorMutations: state.mutations.errors,

    filterByNameMutations: state.mutations.filterByNameMutations,
    filterByFirmaMutations: state.mutations.filterByFirmaMutations,
    filterByTypeMutations: state.mutations.filterByTypeMutations,
    filterByAlsoClosedMutations: state.mutations.filterByAlsoClosedMutations,
    csv_imports: state.mutations.csv_imports,
    job_imports: state.mutations.import_jobs,

    fetchedEntities: state.app.fetchedEntities,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setInsurantsPageNo: (val) => dispatch(actions.setInsurantsPageNo(val)),
    setMutationsPageNo: (val) => dispatch(actions.setMutationsPageNo(val)),
    setSidebar: (val) => dispatch(actions.setSidebar(val)),
    setLoading: (val) => dispatch(actions.setLoading(val)),
    setPopup: (val) => dispatch(actions.setPopup(val)),
    filterPersons: (val) => dispatch(actions.filterPersons(val)),
    filterMutations: (val) => dispatch(actions.filterMutations(val)),
    addNewFile: (val) => dispatch(actions.addNewFile(val)),
    showValidations: (val) => dispatch(actions.showValidations(val)),
    setInsurantsFetched: (val) => dispatch(setInsurantsFetched(val)),
    setMutationsFetched: (val) => dispatch(setMutationsFetched(val)),
    setOpenMutationsFetched: (val) => dispatch(setOpenMutationsFetched(val)),
    setCompleteMutationsFetched: (val) =>
      dispatch(setCompleteMutationsFetched(val)),
    actions: bindActionCreators(actions as any, dispatch),
    employeeActions: bindActionCreators(employeeActions as any, dispatch),
    fetchEmployees: bindActionCreators(fetchEmployees as any, dispatch),
    fetchAddresses: bindActionCreators(fetchAddresses, dispatch),
    createAddress: bindActionCreators(createAddress, dispatch),
    patchAddress: bindActionCreators(patchAddress, dispatch),
    deleteAddress: bindActionCreators(deleteAddress, dispatch),
    fetchPayrolls: bindActionCreators(fetchPayrolls, dispatch),
    createPayroll: bindActionCreators(createPayroll, dispatch),
    patchPayroll: bindActionCreators(patchPayroll, dispatch),
    deletePayroll: bindActionCreators(deletePayroll, dispatch),
    fetchMutations: bindActionCreators(fetchMutations, dispatch),
    patchMutation: bindActionCreators(patchMutation, dispatch),
    deleteMutation: bindActionCreators(deleteMutation, dispatch),
    fetchMutationById: bindActionCreators(fetchMutationById, dispatch),
    fetchEmployers: bindActionCreators(fetchEmployers, dispatch),
    commitMutations: bindActionCreators(commitMutations, dispatch),
    fetchOpenMutations: bindActionCreators(fetchOpenMutations, dispatch),
    fetchCompleteMutations: bindActionCreators(
      fetchCompleteMutations,
      dispatch
    ),
    fetchUnpaidVacations: bindActionCreators(fetchUnpaidVacations, dispatch),
    createUnpaidVacation: bindActionCreators(createUnpaidVacation, dispatch),
    patchUnpaidVacation: bindActionCreators(patchUnpaidVacation, dispatch),
    deleteUnpaidVacation: bindActionCreators(deleteUnpaidVacation, dispatch),
    initializeUpload: bindActionCreators(initializeUpload, dispatch),
    uploadExcel: bindActionCreators(uploadExcel, dispatch),
    uploadPart: bindActionCreators(uploadPart, dispatch),
    uploadFile: bindActionCreators(uploadFile, dispatch),
    uploadCsv: bindActionCreators(uploadCsv, dispatch),
    fetchCsv: bindActionCreators(fetchCsv, dispatch),
    fetchImportJobs: bindActionCreators(fetchImportJobs, dispatch),
    fetchImportJobState: bindActionCreators(fetchImportJobState, dispatch),
    deleteCsv: bindActionCreators(deleteCsv, dispatch),
    deleteImportJob: bindActionCreators(deleteImportJob, dispatch),
    fetchInsurantMutations: bindActionCreators(
      fetchInsurantMutations,
      dispatch
    ),
    clearFieldValues: bindActionCreators(clearFieldValues, dispatch),
    fetchMutationsCount: bindActionCreators(fetchMutationsCount, dispatch),
    fetchLocalEmployees: bindActionCreators(fetchLocalEmployees, dispatch),
    fetchLocalMutations: bindActionCreators(fetchLocalMutations, dispatch),
    revertMutations: bindActionCreators(revertMutations, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Insurances));
