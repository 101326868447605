import * as React from "react";
import { InputRadioOption } from "./InputRadioOption";
import { IInputConfig } from "../index";
import styles from "./InputRadio.module.scss";
import classNames from "classnames"; // Import css modules stylesheet as styles

interface IProps {
  radioOptions?: IInputConfig[];
  forcedValue?: string;
  readOnly?: boolean;
  isDirty?: boolean;
  onBlur: any;
  isDisabled?: boolean;
}

export function InputRadio(props: IProps) {
  const [focused, setFocused] = React.useState(false);

  const options = props.radioOptions.map((input: IInputConfig, i: number) => (
    <InputRadioOption
      key={input.id}
      input={input}
      index={i}
      isDirty={props.isDirty}
      readOnly={props.readOnly}
      defaultChecked={input.defaultChecked}
      checked={props.forcedValue === input.value}
      forcedValue={input.value}
      onFocus={() => setFocused(true)}
      onBlur={() => {
        props.onBlur();
        setFocused(false);
      }}
    />
  ));

  return (
    <ul
      className={classNames(styles.radioWrapper, {
        [styles.focused]: focused,
        [styles.disabled]: props.isDisabled,
      })}
    >
      {options}
    </ul>
  );
}
