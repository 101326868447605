import React from "react";
import styles from "./UnpaidVacationExpandableRowTop.module.scss";
import { ReactComponent as IconEdit } from "../../../../../assets/icons/edit.svg";
import { ReactComponent as IconDelete } from "../../../../../assets/icons/trash.svg";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { RootState } from "../../../../../reducers";
import { connect } from "react-redux";
import { parseDate } from "../../../../../utils/react";

interface Props {
  intl: IntlShape;
  unpaidVacation: any;
  onEditClick: (value: string) => void;
  onDeleteClick: (value: string) => void;
}

const UnpaidVacationExpandableRowTop = ({
  unpaidVacation,
  onEditClick,
  onDeleteClick,
}: Props) => {
  const contributionOptions = {
    "1": (
      <span>
        <b>
          <FormattedMessage id="app.insurant" defaultMessage="Versicherter" />
        </b>{" "}
        <FormattedMessage
          id="app.forms.unpaidVacation.paysEmployerAndEmployeeContributions"
          defaultMessage="zahlt Arbeitgeber- und Arbeitnehmerbeiträge"
        />
      </span>
    ),
    "2": (
      <span>
        <b>
          <FormattedMessage id="app.insurant" defaultMessage="Versicherter" />
        </b>{" "}
        <FormattedMessage id="app.and" defaultMessage="und" />{" "}
        <b>
          <FormattedMessage id="app.employer" defaultMessage="Arbeitgeber" />
        </b>{" "}
        <FormattedMessage
          id="app.forms.unpaidVacation.payContributions"
          defaultMessage="zahlen jeweilige Beiträge"
        />
      </span>
    ),
    "3": (
      <span>
        <b>
          <FormattedMessage id="app.insurant" defaultMessage="Versicherter" />
        </b>{" "}
        <FormattedMessage
          id="app.forms.unpaidVacation.paysEmployerAndEmployeeContributions"
          defaultMessage="zahlt Arbeitgeber- und Arbeitnehmerbeiträge"
        />
      </span>
    ),
    "4": (
      <span>
        <b>
          <FormattedMessage id="app.insurant" defaultMessage="Versicherter" />
        </b>{" "}
        <FormattedMessage id="app.and" defaultMessage="und" />{" "}
        <b>
          <FormattedMessage id="app.employer" defaultMessage="Arbeitgeber" />
        </b>{" "}
        <FormattedMessage
          id="app.forms.unpaidVacation.payContributions"
          defaultMessage="zahlen jeweilige Beiträge"
        />
      </span>
    ),
  };

  return (
    <div className={styles["unpaid-vacation-expandable-row-top"]}>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.from" defaultMessage="Von" />
        </span>
        <p>{parseDate(unpaidVacation.validFrom)}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage id="app.to" defaultMessage="Bis" />
        </span>
        <p>{parseDate(unpaidVacation.validUntil)}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage
            id="app.forms.unpaidVacation.savingsAndRiskContributions"
            defaultMessage="Spar- und Risikobeiträge"
          />
        </span>
        <p>
          {unpaidVacation.contributionCoverage <= 2 &&
          unpaidVacation.contributionCoverage > 0
            ? contributionOptions[unpaidVacation.contributionCoverage]
            : "-"}
        </p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage
            id="app.forms.unpaidVacation.riskContributionsOnly"
            defaultMessage="Nur Risikobeiträge"
          />
        </span>
        <p>
          {unpaidVacation.contributionCoverage > 2
            ? contributionOptions[unpaidVacation.contributionCoverage]
            : "-"}
        </p>
      </div>
      <div className={styles.column}>
        <Button
          className="edit-icon mr-24"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onEditClick(unpaidVacation.id)}
        >
          <IconEdit />
        </Button>
        <Button
          className="trash-icon"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() => onDeleteClick(unpaidVacation.id)}
        >
          <IconDelete />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  //
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(UnpaidVacationExpandableRowTop));
