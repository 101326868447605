import React from "react";
import styles from "./PayrollExpandableRowTop.module.scss";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { RootState } from "../../../../../reducers";
import { connect } from "react-redux";
import { parseDate } from "../../../../../utils/react";
import { DropdownCollapse } from "../../../../DropdownCollapse";
import { mutationFormTypes } from "../../../../../constants";
import classNames from "classnames";

interface Props {
  intl: IntlShape;
  payroll: any;
  handlePayrollAddEditOrRemoveModal: (string) => void;
}

const PayrollExpandableRowTop = ({
  payroll,
  handlePayrollAddEditOrRemoveModal,
}: Props) => {
  return (
    <div className={styles["payroll-expandable-row-top"]}>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage
            id="app.payrolls.list.header.validFrom"
            defaultMessage="Gültig ab"
          />
        </span>
        <p>{parseDate(payroll.validFrom)}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage
            id="app.payrolls.list.header.validUntil"
            defaultMessage="Gültig bis"
          />
        </span>
        <p>{parseDate(payroll.validUntil)}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage
            id="app.payrolls.list.header.grossSalary"
            defaultMessage="AHV-Jahreslohn"
          />
        </span>
        <p>{payroll.grossSalary ? "CHF " + payroll.grossSalary : null}</p>
      </div>
      <div className={styles.column}>
        <span className={styles["mobile-header"]}>
          <FormattedMessage
            id="app.payrolls.list.header.activityRate"
            defaultMessage="Beschäftigungsgrad"
          />
        </span>
        <p>{payroll.activityRate ? payroll.activityRate + "%" : null}</p>
      </div>
      <div className={styles.column}>
        <DropdownCollapse label="     " isInlineRow>
          <p
            onClick={() =>
              handlePayrollAddEditOrRemoveModal(
                mutationFormTypes.EditOrAddSalary
              )
            }
            className={classNames(styles.dropdownLabel, styles["mb-10"])}
          >
            Fehleingabe korrigieren
          </p>
          <hr />
          <p
            onClick={() =>
              handlePayrollAddEditOrRemoveModal(mutationFormTypes.RemoveSalary)
            }
            className={classNames(
              styles.dropdownLabel,
              styles.isRed,
              styles["mt-10"]
            )}
          >
            Lohnänderung löschen
          </p>
        </DropdownCollapse>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  //
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(PayrollExpandableRowTop));
