import "./config";
import { StatusColors } from "icr-shared-components";

export const SIDEBAR_SET = "SIDEBAR_SET";
export const LOADING = "LOADING";

export const ADD_NEW_INSURANCE_ERROR = "ADD_NEW_INSURANCE_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_REFRESH_TOKEN = "LOGIN_REFRESH_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_LOCALES = "UPDATE_LOCALES";
export const UPDATE_EXPIRATION = "UPDATE_EXPIRATION";

export const FETCH_ERROR = "FETCH_ERROR";
export const POST_ERROR = "POST_ERROR";
export const PATCH_ERROR = "PATCH_ERROR";
export const DELETE_ERROR = "DELETE_ERROR";

export const POST_SUCCESS = "POST_SUCCESS";
export const PATCH_SUCCESS = "PATCH_SUCCESS";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const FETCHED_INVOICES = "FETCHED_INVOICES";
export const FETCHED_OPEN_INVOICES = "FETCHED_OPEN_INVOICES";
export const FETCHED_MUTATIONTYPES = "FETCHED_MUTATIONTYPES";
export const FETCHED_PROFILES = "FETCHED_PROFILES";
export const FETCHED_MUTATIONS = "FETCHED_MUTATIONS";
export const FETCHED_EMPLOYERS = "FETCHED_EMPLOYERS";
export const FETCHED_EMPLOYERS_INSURANCE_PLANS =
  "FETCHED_EMPLOYERS_INSURANCE_PLANS";
export const FETCHED_DOCUMENT_TYPES = "FETCHED_DOCUMENT_TYPES";
export const FETCHED_INSURANTS = "FETCHED_INSURANTS";
export const FETCHED_PAYROLLS = "FETCHED_PAYROLLS";
export const FETCHED_ADDRESSES = "FETCHED_ADDRESSES";
export const FETCHED_INSURANT_MUTATIONS = "FETCHED_INSURANT_MUTATIONS";
export const FETCHED_ENTITIES = "FETCHED_ENTITIES";
export const FETCHED_UNPAIDVACATIONS = "FETCHED_UNPAIDVACATIONS";
export const CSV_UPLOADED = "CSV_UPLOADED";
export const FETCHED_CSV = "FETCHED_CSV";
export const FETCHED_IMPORT_JOBS = "FETCHED_IMPORT_JOBS";
export const FETCHED_IMPORT_JOB_ERROR_MUTATIONS =
  "FETCHED_IMPORT_JOB_ERROR_MUTATIONS";
export const FETCHED_TRANSLATIONS = "FETCHED_TRANSLATIONS";
export const FETCH_UPDATE_INSURANTS = "FETCH_UPDATE_INSURANTS";

export const FETCHED_COMPLETE_MUTATIONS = "FETCHED_COMPLETE_MUTATIONS";
export const FETCHED_OPEN_MUTATIONS = "FETCHED_OPEN_MUTATIONS";
export const FETCHED_MUTATION_COUNT = "FETCHED_MUTATION_COUNT";
export const FETCH_UPDATE_MUTATIONS = "FETCH_UPDATE_MUTATIONS";

export const SET_MUTATIONS_FETCHED = "SET_MUTATIONS_FETCHED";
export const SET_COMPLETE_MUTATIONS_FETCHED = "SET_MUTATIONS_FETCHED";
export const SET_OPEN_MUTATIONS_FETCHED = "SET_MUTATIONS_FETCHED";

export const SET_INSURANTS_FETCHED = "SET_INSURANTS_FETCHED";

export const CREATED_INSURANT = "CREATED_INSURANT";

export const CREATED_PAYROLL = "CREATED_PAYROLL";
export const UPDATED_PAYROLL = "UPDATED_PAYROLL";

export const SET_FILTERING_CRITERIA_PERSONS = "SET_FILTERING_CRITERIA_PERSONS";
export const SET_FILTERING_CRITERIA_MUTATIONS =
  "SET_FILTERING_CRITERIA_MUTATIONS";
export const SET_FILTERING_CRITERIA_INVOICES =
  "SET_FILTERING_CRITERIA_INVOICES";
export const FIRMAFILTER_SET = "FIRMAFILTER_SET";

export const SET_INSURANTS_PAGE_NO = "SET_INSURANTS_PAGE_NO";
export const SET_MUTATIONS_PAGE_NO = "SET_MUTATIONS_PAGE_NO";
export const SET_INVOICES_PAGE_NO = "SET_INVOICES_PAGE_NO";
export const SET_INVOICES_FETCHED = "SET_INVOICES_FETCHED";

export const POPUP = "POPUP";

export const FETCHED_OUTBOX = "OUTBOX";
export const FETCHED_INBOX = "INBOX";
export const INITIALIZED_UPLOAD = "INITIALIZED_UPLOAD";
export const FILEPART_UPLOADED = "FILEPART_UPLOADED";
export const FILE_UPLOADED = "FILE_UPLOADED";
export const SET_INBOX_FILTER = "SET_INBOX_FILTER";
export const SET_OUTBOX_FILTER = "SET_OUTBOX_FILTER";
export const SET_INBOX_PAGE_NO = "SET_INBOX_PAGE_NO";
export const SET_OUTBOX_PAGE_NO = "SET_OUTBOX_PAGE_NO";
export const ADD_NEW_FILE = "ADD_NEW_FILE";
export const INITIALIZED_DOWNLOAD = "INITIALIZED_DOWNLOAD";
export const FILE_DOWNLOADED = "FILE_DOWNLOADED";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATE_FILE_STATUS = "UPDATE_FILE_STATUS";

export const SET_FETCH_DATA = "SET_FETCH_DATA";

export const UPDATE_ENTRY_FORM_VALUES = "UPDATE_ENTRY_FORM_VALUES";
export const CLEAR_ENTRY_FORM_VALUE = "CLEAR_ENTRY_FORM_VALUE";

export const SHOW_VALIDATIONS = "SHOW_VALIDATIONS";

export const PAGINAION_SIZE = 20;

export const GLOBAL_SETTINGS = "GLOBAL_SETTINGS";

export const languageOptions = [
  {
    dropdownLabel: "EN",
    id: "en",
    value: "en",
  },
  {
    dropdownLabel: "DE",
    id: "de",
    value: "de",
  },
];

export const STATUSES_MAP: { [statusId: number]: string } = {
  0: "OFFEN",
  1: "BEZAHLT",
  2: "ERROR",
};

export const InvoiceStatusColors = {
  0: StatusColors.GREEN,
  1: StatusColors.RED,
  2: StatusColors.GREEN,
};

export enum VERSICHERTE_POPUP_CONTENT {
  DELETE_CSV = "delete-csv",
  DELETE_MUTATION = "delete-mutation",
  COMMIT_MUTATIONS = "commit-mutations",
}

export enum COCKPIT_MODAL_PAGES {
  SUCCESS = "success",
  COMMIT = "commit",
  PDF = "pdf",
}

export enum INSURANCES_MODAL_PAGES {
  PDF = "pdf",
  EXPORT_EXCEL = "export-excel",
}

export enum MUTATIONS_MODAL_PAGES {
  PDF = "pdf",
}

export enum INVOICES_MODAL_PAGES {
  PDF = "pdf",
}

export enum LOGIN_PAGES {
  LOGIN = "login",
  MFA = "mfa",
  RESET_PASSWORD = "reset-password",
  RESET_PASSWORD_MESSAGE = "reset-password-message",
}

export const payrollTableHeaders = [
  {
    name: "Gültig ab",
    handle: "validFrom",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Gültig bis",
    handle: "validUntil",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "AHV-Jahreslohn",
    handle: "grossSalary",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Beschäftigungsgrad",
    handle: "activityRate",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "AHV-Jahreslohn bisher",
    handle: "payrollNote",
    withSorting: false,
  },
  {
    name: " ",
    handle: "actions",
  },
];

export const addressTableHeaders = [
  {
    name: "Gültig ab",
    handle: "validFrom",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Gültig bis",
    handle: "validUntil",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Adresse 1",
    handle: "address1",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Adresse 2",
    handle: "address2",
    withSorting: false,
    direction: "DESC",
  },
  {
    name: "Ort",
    handle: "city",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Land",
    handle: "countryName",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: " ",
    handle: "actions",
  },
];

export const unpaidVacationTableHeaders = [
  {
    name: "Von",
    handle: "validFrom",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Bis",
    handle: "validUntil",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Spar- und Risikobeiträge",
    handle: "contributionCoverage1",
    withSorting: true,
    direction: "DESC",
  },
  {
    name: "Nur Risikobeiträge",
    handle: "contributionCoverage2",
    withSorting: false,
    direction: "DESC",
  },
  {
    name: " ",
    handle: "actions",
  },
];

export const mutationToFormMap = {
  Adressaenderung: "Adresse bearbeiten", // adds address ?
  Adresskorrektur: "Adresse bearbeiten", // updates existing address
  Anstellungsänderung: "Anstellungsänderung",
  Austritt: "Austritt",
  Austrittskorrektur: "Austritt",
  Eintritt: "Eintritt erfassen",
  Erwerbsunfaehigkeit: "Erwerbsunfähigkeit",
  Informationsänderung: "Versicherteninfos bearbeiten",
  Invaliditaet: "Invalidität",
  Lohnaenderung: "Lohn bearbeiten",
  Lohnkorrektur: "Lohn bearbeiten",
  Lohnlöschung: "Lohn bearbeiten", //, we don't display edit for this type,
  Pensionierung: "Pensionierung",
  Personalienänderung: "Personalienänderung",
  Todesfall: "Todesfall",
  UnbezahlterUrlaub: "Unbezahlter Urlaub bearbeiten",
  UnbezahlterUrlaubKorrektur: "Unbezahlter Urlaub bearbeiten",
  Wiedereintritt: "Wiedereintritt",
  Zivilstandsaenderung: "Zivilstand",
  Lohnrunde: "Lohnrunde",
  // 'Adresslöschung': 'Adresse', we don't display edit for this type
  // 'EintrittBestehenderPerson': 'Eintritt erfassen',
};

export const insurantMutationTypesToFormsMap = {
  Adressaenderung: "Adresse bearbeiten", // adds address ?
  Austritt: "Austritt",
  Eintritt: "Eintritt erfassen",
  Erwerbsunfaehigkeit: "Erwerbsunfähigkeit",
  Informationsänderung: "Versicherteninfos bearbeiten",
  Invaliditaet: "Invalidität",
  Lohnaenderung: "Lohn bearbeiten",
  Lohnlöschung: "Lohn bearbeiten", //, we don't display edit for this type,
  Pensionierung: "Pensionierung",
  Personalienänderung: "Personalienänderung",
  Todesfall: "Todesfall",
  UnbezahlterUrlaub: "Unbezahlter Urlaub",
  UnbezahlterUrlaubKorrektur: "Unbezahlter Urlaub Korrektur",
  Zivilstandsaenderung: "Zivilstand",
};

export const mutationMutationTypesToFormMap = {
  Adresskorrektur: "Adresse bearbeiten", // updates existing address
  Anstellungsänderung: "Anstellungsänderung",
  Austrittskorrektur: "Austritt",
  Eintritt: "Eintritt erfassen",
  Erwerbsunfaehigkeit: "Erwerbsunfähigkeit",
  Informationsänderung: "Versicherteninfos bearbeiten",
  Invaliditaet: "Invalidität",
  Lohnkorrektur: "Lohn bearbeiten",
  Pensionierung: "Pensionierung",
  Personalienänderung: "Personalienänderung",
  Todesfall: "Todesfall",
  UnbezahlterUrlaub: "Unbezahlter Urlaub",
  UnbezahlterUrlaubKorrektur: "Unbezahlter Urlaub bearbeiten",
  Wiedereintritt: "Wiedereintritt",
  Zivilstandsaenderung: "Zivilstand",
};

// This requires translation somehow, on the insurances page
export const mutationTypesToMessageMap = {
  Adressaenderung: "Neue Adresse wurde erfasst.",
  Adresskorrektur: "Adresse wurde angepasst.",
  Adresslöschung: "Adresse wurde gelöscht.",
  Anstellungsänderung: "Anstellungsverhältnis wurde angepasst.",
  Austritt: "Austritt wurde erfasst.",
  Austrittskorrektur: "Austritt wurde angepasst.",
  Eintritt: "Eintritt wurde erfasst.",
  EintrittBestehenderPerson: "Eintritt wurde erfasst.",
  Erwerbsunfaehigkeit: "Erwerbsunfähigkeit wurde erfasst.",
  Informationsänderung: "Versicherteninformationen wurden angepasst.",
  Invaliditaet: "Invalidität wurde erfasst.",
  Lohnaenderung: "Neuer Lohn wurde erfasst.",
  Lohnkorrektur: "Lohn wurde angepasst.",
  Lohnlöschung: "Lohn wurde gelöscht.",
  Pensionierung: "Pensionierung wurde erfasst.",
  Personalienänderung: "Personalien wurden angepasst.",
  Todesfall: "Todesfall wurde erfasst.",
  UnbezahlterUrlaub: "Unbezahlter Urlaub wurde erfasst.",
  UnbezahlterUrlaubKorrektur: "Unbezahlter Urlaub wurde angepasst.",
  Wiedereintritt: "Eintritt wurde erfasst.",
  Zivilstandsaenderung: "Zivilstandsänderung wurde erfasst.",
};

export const mutationFormTypes = {
  Austritt: "Austritt",
  Invalidity: "Invalidität",
  Zivilstand: "Zivilstand",
  Pensionierung: "Pensionierung",
  Todesfall: "Todesfall",
  Employment: "Anstellungsänderung",
  Personnel: "Personalienänderung",
  Unemployability: "Erwerbsunfähigkeit",
  Versicherteninfos: "Versicherteninfos bearbeiten",

  NewAddress: "Neue Adresse",
  EditAddress: "Adresse bearbeiten",

  NewPayroll: "Neuer Lohn",
  EditOrAddSalary: "Fehleingabe korrigieren",
  RemoveSalary: "Lohnänderung löschen",
  EditPayroll: "Lohn bearbeiten",

  NewEntry: "Eintritt erfassen",
  EditNewEntry: "Wiedereintritt",

  NewUnpaidVacation: "Neuer Unbezahlter Urlaub",
  EditUnpaidVacation: "Unbezahlter Urlaub bearbeiten",

  CsvImport: "CSV Importieren",
  ExcelImport: "Excel Importieren",
  Lohnrunde: "Lohnrunde",
  ExportExcel: "ExportExcel",
  ImportExcel: "ImportExcel",
  ImportExcelSuccess: "ImportExcelSuccess",
};

export const formRequestTypes = {
  CreateNewInsurant: "CreateNewInsurant",
  PatchInsurant: "PatchInsurant",
  PatchMutation: "PatchMutation",
  UploadCsv: "UploadCsv",
  UpdateAddress: "UpdateAddress",
  CreateAddress: "CreateAddress",
  UpdatePayroll: "UpdatePayroll",
  CreatePayroll: "CreatePayroll",
  UpdateUnpaidVacation: "UpdateUnpaidVacation",
  CreateUnpaidVacation: "CreateUnpaidVacation",
};

export const mutationTypeToFormObj = {
  "Adresse bearbeiten": "newAddress", // updates existing address
  "Neue Adresse": "newAddress",
  "Neuer Lohn": "newPayroll",
  "Lohn bearbeiten": "newPayroll",
  "Neuer Unbezahlter Urlaub": "newUnpaidVacation",
  "Unbezahlter Urlaub bearbeiten": "newUnpaidVacation",
  "Eintritt erfassen": "eintrittErfassenForm",
  Wiedereintritt: "eintrittErfassenForm",
};

export const modalPageTypes = {
  SUCCESS: "success",
  PDF: "pdf",
  INSURANT_DETAILS: "insurant-details",
  COMPANY_DETAILS: "company-details",
  INSURANT_DETAILS_PK: "insurant-details-pk",
};
