import React from "react";
import { formatNumber, parseDate } from "../../../../utils/react";
import { injectIntl } from "react-intl";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import styles from "./PersonenExpandableRowBottom.module.scss";
import classNames from "classnames";
import { mutationFormTypes } from "../../../../constants";
import { ReactComponent as IconPen } from "../../../../assets/icons/pen.svg";
import Tooltip from "rc-tooltip";

interface Props {
  intl: any;
  insurant: any;
  employers: any;
  entities: any;
  onEditClick: any;
}

const PersonenExpandableRowBottom = ({
  insurant,
  employers,
  entities,
  onEditClick,
}: Props) => {
  const kassennummer =
    employers?.[insurant.attributes.employee.employerId]?.attributes
      .extParentId;
  const civilStatus =
    entities?.CivilStatus?.values[insurant.attributes.person?.civilStatus];
  const payroll =
    !!insurant.attributes.payroll?.grossSalary &&
    "CHF " + formatNumber(insurant.attributes.payroll.grossSalary);
  return (
    <div className={styles.personenExpandableRowBottom}>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Versicherter Lohn
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.Payroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{payroll}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Arbeitspensum
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.Payroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>
          {insurant.attributes.payroll?.activityRate}
          {insurant.attributes.payroll?.activityRate ? "%" : null}
        </p>
      </div>

      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Eintrittsdatum
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Versicherteninfos)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{parseDate(insurant.attributes.employee?.entryDate)}</p>
      </div>

      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Zivilstand
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() =>
              onEditClick(insurant.id, mutationFormTypes.Zivilstand)
            }
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{civilStatus}</p>
      </div>

      <div className={styles.column}>
        <div style={{ width: "7rem" }} />
      </div>

      <div className={styles.break} />

      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Lohn gültig ab
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.Payroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{parseDate(insurant.attributes.payroll?.validFrom)}</p>
      </div>

      {/* <div className={styles.column}>
                <span className={classNames(styles.headerText, "show-child-on-hover")}>
                    Bonus
                    <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.Payroll)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
                <IconPen />
            </Tooltip>
                    </div>
                </span>
                <p>{insurant.attributes.payroll?.bonus}</p>
            </div> */}

      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Adresse
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(insurant.id, mutationFormTypes.Address)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>
          {insurant.attributes.address && (
            <span>
              {insurant.attributes.address.address1 &&
                insurant.attributes.address.address1 + " "}
              <br />
              {insurant.attributes.address.zipCode &&
                insurant.attributes.address.zipCode + " "}
              {insurant.attributes.address.city &&
                insurant.attributes.address.city}
            </span>
          )}
        </p>
      </div>

      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Kassennummer
          {/* <div className="toggle-child show-on-mobile"
                         onClick={() => onEditClick(insurant.id,  mutationFormTypes.Versicherteninfos)}>
                        <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
                <IconPen />
            </Tooltip>
                    </div> */}
        </span>
        <p>{kassennummer}</p>
      </div>

      <div className={styles.column}>
        <div style={{ width: "7rem" }} />
      </div>

      <div className={styles.column}>
        <div style={{ width: "7rem" }} />
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(PersonenExpandableRowBottom));
