import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers/index";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../actions/";
import {
  fetchInvoices,
  fetchOpenInvoices,
} from "../../actions/arbeitgeber/invoices";
import {
  fetchCompleteMutations,
  fetchCsv,
  fetchImportJobs,
  fetchMutations,
  fetchMutationsCount,
  fetchMutationTypes,
  fetchOpenMutations,
  fetchProfiles,
} from "../../actions/arbeitgeber/mutations";
import { fetchEmployees } from "../../actions/arbeitgeber/insurants";
import { fetchEntities } from "../../actions";
import { FormattedMessage, injectIntl } from "react-intl";
import { updateIntl } from "react-intl-redux";
import { store } from "../../index";
import styles from "./Header.module.scss";
import { DropdownCollapse } from "../DropdownCollapse/index";
import { Input } from "../../components/Input/index";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { ReactComponent as IconLogout } from "../../assets/icons/logout.svg";
import {
  fetchDocumentTypes,
  fetchInbox,
  fetchOutbox,
} from "../../actions/arbeitgeber/upload";
import { fetchInvoices as fetchInvoicesPk } from "../../actions/pensionskasse/invoices";
import { fetchEmployees as fetchEmployeesPk } from "../../actions/pensionskasse/employee";
import { fetchCompanies } from "../../actions/pensionskasse/companies";
import {
  fetchAcceptedMutations,
  fetchDeletedMutations,
  fetchForReviewMutations,
  setAcceptedMutationsPageNo,
  setDeletedMutationsPageNo,
  setForReviewMutationsPageNo,
} from "../../actions/pensionskasse/mutations";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import { ReactComponent as IconFilter } from "../../assets/icons/filter.svg";
import classNames from "classnames";

import { setInvoicesPageNo as setInvoicesPkPageNo } from "../../actions/pensionskasse/invoices";
import { fetchEmployers, health, setFetchData } from "../../actions/";
import { setInsurantsPageNo as setInsurantsPkPageNo } from "../../actions/pensionskasse/employee";
import { fetchTranslations } from "../../actions/arbeitgeber/translations";
import { logOut } from "../../actions/auth";

interface State {
  requestsMade: number;
}

interface Props {
  location: any;
  title: string;
  avatarSrc: any;
  logOut: any;
  locales: any;
  intl: any;
  userData: any;
  authentication: any;
  hasMoreContent: boolean;
  cockpitPageHeader: boolean;
  mutationsPageHeader: boolean;

  onFiltersButtonClick: any;

  mutationTypes: any;
  fetchMutationTypes: any;

  entities: any;
  fetchEntities: any;

  employers: Array<any>;
  fetchEmployers: any;

  mutations: Array<any>;
  fetchMutations: any;
  fetchMutationsCount: any;
  filterMutations: any;
  mutationsFetched: boolean;
  setMutationsPageNo: any;
  mutationsPageNo: number;

  insurants: Array<any>;
  fetchEmployees: any;
  insurantsFetched: boolean;
  setInsurantsPageNo: any;
  insurantsPageNo: number;

  invoices: Array<any>;
  fetchInvoices: any;
  invoicesFetched: boolean;

  completeMutations: any;
  fetchCompleteMutations: any;
  completeMutationsFetched: any;

  openMutations: any;
  fetchOpenMutations: any;
  openMutationsFetched: any;

  profiles: any;
  fetchProfiles: any;
  setInvoicesPageNo: any;
  invoicesPageNo: number;
  csv_imports: any;
  import_jobs: any;
  fetchCsv: any;
  fetchImportJobs: any;

  forReviewMutations: any;
  fetchForReviewMutations: any;
  forReviewMutationsFetched: boolean;
  setForReviewMutationsPageNo: any;
  forReviewMutationsPageNo: any;

  acceptedMutations: any;
  fetchAcceptedMutations: any;
  acceptedMutationsFetched: boolean;
  setAcceptedMutationsPageNo: any;
  acceptedMutationsPageNo: any;

  deletedMutations: any;
  fetchDeletedMutations: any;
  deletedMutationsFetched: boolean;
  setDeletedMutationsPageNo: any;
  deletedMutationsPageNo: any;

  insurantsPk: any;
  fetchEmployeesPk: any;
  insurantsPkFetched: boolean;
  setInsurantsPkPageNo: any;
  insurantsPkPageNo: any;

  invoicesPk: any;
  fetchInvoicesPk: any;
  invoicesPkFetched: boolean;
  setInvoicesPkPageNo: any;
  invoicesPkPageNo: any;

  companies: any;
  companiesFetched: any;
  fetchCompanies: any;
  setCompaniesPageNo: any;
  companiesPageNo: any;

  fetchInbox: any;
  inbox: any;
  inboxFetched: boolean;
  setInboxPageNo: any;
  inboxPageNo: number;

  fetchOutbox: any;
  outbox: any;
  outboxFetched: boolean;
  setOutboxPageNo: any;
  outboxPageNo: number;

  health: any;
  setFetchData: any;

  documentTypes: any;
  fetchDocumentTypes: any;

  fetchedEntities: boolean;
  employersFetched: boolean;
  mutationTypesFetched: boolean;
  documentTypesFetched: boolean;
  csv_imports_fetched: boolean;
  import_jobs_fetched: boolean;

  fetchTranslations: any;
  fetchedTranslations: boolean;

  openInvoicesFetched: boolean;
  fetchOpenInvoices: any;
  openInvoices: any;
}

const showLanguageDropdown = !!(
  process.env.REACT_APP_LANGUAGE_DROPDOWN &&
  process.env.REACT_APP_LANGUAGE_DROPDOWN == "true"
);
let requestsFinished = false;

class Header extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      requestsMade: 0,
    };
    console.log("Header constructor called");
  }

  isPKCockpitPathActive = (pathname) =>
    !!matchPath(pathname, "/pensionskasse/");

  commonRequests = [
    { data: this.props.fetchedEntities, request: this.props.fetchEntities },
    { data: this.props.employersFetched, request: this.props.fetchEmployers },
    {
      data: this.props.mutationTypesFetched,
      request: this.props.fetchMutationTypes,
    },
    {
      data: this.props.documentTypesFetched,
      request: this.props.fetchDocumentTypes,
    },
    {
      data: this.props.import_jobs_fetched,
      request: this.props.fetchImportJobs,
    },
    // #{data: this.props.profiles, request: this.props.fetchProfiles},
    // {data: this.props.csv_imports, request: this.props.fetchCsv},
  ];

  arbeitgeberRequests = [
    // {data: this.props.insurantsFetched, request: this.props.fetchEmployees, then: this.props.setInsurantsPageNo, value: this.props.insurantsPageNo},
    // {data: this.props.mutationsFetched, request: this.props.fetchMutations, then: this.props.setMutationsPageNo, value: this.props.mutationsPageNo},
    // {data: this.props.openMutationsFetched, request: this.props.fetchOpenMutations},
    // {data: this.props.completeMutationsFetched, request: this.props.fetchCompleteMutations},
    // {data: this.props.openInvoicesFetched, request: this.props.fetchOpenInvoices},
    // {data: this.props.invoicesFetched, request: this.props.fetchInvoices, then: this.props.setInvoicesPageNo, value: this.props.invoicesPageNo},
    // {request: this.props.fetchMutationsCount},
    // {data: this.props.inboxFetched, request: this.props.fetchInbox, then: this.props.setInboxPageNo, value: this.props.inboxPageNo},
    // {data: this.props.outboxFetched, request: this.props.fetchOutbox, then: this.props.setOutboxPageNo, value: this.props.outboxPageNo, final: true},
  ];

  pkCockpitRequests = [
    // {data: this.props.forReviewMutationsFetched, request: this.props.fetchForReviewMutations, then: this.props.setForReviewMutationsPageNo, value: this.props.forReviewMutationsPageNo},
    // {data: this.props.acceptedMutationsFetched, request: this.props.fetchAcceptedMutations, then: this.props.setAcceptedMutationsPageNo, value: this.props.acceptedMutationsPageNo},
    // {data: this.props.deletedMutationsFetched, request: this.props.fetchDeletedMutations, then: this.props.setDeletedMutationsPageNo, value: this.props.deletedMutationsPageNo },
    // {data: this.props.insurantsPkFetched, request: this.props.fetchEmployeesPk, then: this.props.setInsurantsPkPageNo, value: this.props.insurantsPkPageNo},
    //  {data: this.props.companiesFetched, request: this.props.fetchCompanies, then: this.props.setCompaniesPageNo, value: this.props.companiesPageNo},
    //  {data: this.props.invoicesPkFetched, request: this.props.fetchInvoicesPk, then: this.props.setInvoicesPkPageNo, value: this.props.invoicesPageNo, final: true},
  ];

  fetchData = async () => {
    const fetchData = this.props.authentication.fetchData;
    if (this.isPKCockpitPathActive(this.props.location.pathname)) {
      for (const request of this.pkCockpitRequests) {
        if ((!request.data && !requestsFinished) || fetchData) {
          request.request();
          if (request.then) {
            request.then(1);
          }
        }
        if (request.final) {
          requestsFinished = true;
          this.props.setFetchData(false);
        }
      }
    } else {
      for (const request of this.arbeitgeberRequests) {
        if (!request.data && (!requestsFinished || fetchData)) {
          request.request();
          if (request.then) {
            request.then(1);
          }
        }
        if (request.final) {
          requestsFinished = true;
          this.props.setFetchData(false);
        }
      }
    }
  };

  async componentDidMount() {
    console.log("HEADER MOUNTED");
    console.log(this.props.authentication);

    if (!this.props.fetchedTranslations) {
      const locale = "de";
      this.props.fetchTranslations().then((res) => {
        if (this.props.locales && this.props.locales[locale]) {
          store.dispatch(
            updateIntl({
              locale: locale,
              messages: this.props.locales[locale],
            })
          );
        }
      });
    }

    for (const request of this.commonRequests) {
      if (!request.data) {
        request.request();
      }
    }

    // await this.fetchData()
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      this.isPKCockpitPathActive(this.props.location.pathname) !=
      this.isPKCockpitPathActive(prevProps.location.pathname)
    ) {
      await this.fetchData();
    }
  }

  componentWillUnmount() {
    // if(requestsFinished == true) {
    //     requestsFinished = false;
    // }
  }

  changeLanguage(lang) {
    store.dispatch(
      updateIntl({ locale: lang, messages: this.props.locales[lang] })
    );
  }

  render() {
    return (
      <header
        className={classNames(styles.headerContainer, {
          [styles.mutations]: this.props.mutationsPageHeader,
          [styles.hasMoreContent]: this.props.hasMoreContent,
          [styles.cockpit]: this.props.cockpitPageHeader,
        })}
      >
        <h1 className={styles.headerTitle}>{this.props.title}</h1>
        <div className={styles.headerContent}>{this.props.children}</div>
        {/*        <div className="headerAvatar"><img alt="avatar" src={this.props.avatarSrc}/></div>*/}
        <div className={styles.rightContent}>
          <DropdownCollapse
            label={
              this.props.userData &&
              this.props.userData["first-name"] +
                " " +
                this.props.userData["last-name"]
            }
          >
            <h2 className="dropdownContent">
              {this.props.userData &&
                this.props.userData["first-name"] +
                  " " +
                  this.props.userData["last-name"]}
            </h2>
            <p className="email dropdownContent">
              {this.props.userData && this.props.userData["email"]}
            </p>
            {showLanguageDropdown && (
              <Input
                id="languageInput"
                name="languageInput"
                inputWrapperClass="dropdownContent"
                inputWrapperStyle={{ width: "80px", marginLeft: "auto" }}
                label="Language"
                clearable={false}
                onSelected={(name, value) => this.changeLanguage(value)}
                value={this.props.intl.locale.toUpperCase()}
                selectOptions={[
                  { dropdownLabel: "EN", id: "en", value: "en" },
                  { dropdownLabel: "DE", id: "de", value: "de" },
                ]}
                type="text"
                sort
              />
            )}
            <Button
              className="mt-32"
              size={ButtonSizes.Full}
              type={ButtonTypes.Standard}
              style={ButtonStyles.Secondary}
              color={ButtonColors.Error}
              onClick={() => this.props.logOut()}
            >
              <FormattedMessage id="app.logout" defaultMessage="Abmelden" />
              <IconLogout className="ml-16" />
            </Button>
          </DropdownCollapse>
          {this.props.onFiltersButtonClick && (
            <Button
              className={classNames("py-1", styles.headerFilterButton)}
              type={ButtonTypes.Text}
              color={ButtonColors.Action}
              onClick={() => this.props.onFiltersButtonClick()}
            >
              <IconFilter />
            </Button>
          )}
        </div>
      </header>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,

    fetchedTranslations: state.locales.fetchedTranslations,
    locales: state.locales,

    authentication: state.app.authentication,

    insurants: state.insurants.insurants,
    insurantsFetched: state.insurants.insurantsFetched,
    insurantsPageNo: state.insurants.insurantsPageNo,

    mutations: state.mutations.mutations,
    mutationsFetched: state.mutations.mutationsFetched,
    mutationsPageNo: state.mutations.mutationsPageNo,
    completeMutations: state.mutations.completeMutations,
    completeMutationsFetched: state.mutations.completeMutationsFetched,
    openMutationsFetched: state.mutations.openMutationsFetched,

    entities: state.app.entities,
    employers: state.app.employers,
    mutationTypes: state.app.mutationTypes,
    documentTypes: state.app.documentTypes,
    profiles: state.app.profiles,
    import_jobs: state.mutations.import_jobs,

    fetchedEntities: state.app.fetchedEntities,
    employersFetched: state.app.employersFetched,
    mutationTypesFetched: state.app.mutationTypesFetched,
    documentTypesFetched: state.app.documentTypesFetched,
    import_jobs_fetched: state.mutations.import_jobs_fetched,

    inbox: state.upload.inbox,
    inboxFetched: state.upload.inboxSuccess,
    inboxPageNo: state.upload.inboxPageNo,

    outbox: state.upload.outbox,
    outboxFetched: state.upload.outboxSuccess,
    outboxPageNo: state.upload.outboxPageNo,

    invoices: state.invoices.invoices,
    invoicesFetched: state.invoices.invoicesFetched,
    invoicesPageNo: state.invoices.invoicesPageNo,

    companies: state.companies.companies,
    companiesFetched: state.companies.companiesFetched,

    invoicesPk: state.invoicesPK.invoices,
    invoicesPkFetched: state.invoicesPK.invoicesFetched,
    invoicesPkPageNo: state.invoicesPK.invoicesPageNo,

    openInvoicesFetched: state.invoices.openInvoicesFetched,
    openInvoices: state.invoices.openInvoices,

    insurantsPk: state.insurantsPK.insurants,
    insurantsPkFetched: state.insurantsPK.insurantsFetched,
    insurantsPkPageNo: state.insurantsPK.insurantsPageNo,

    forReviewMutations: state.forReviewMutations.forReviewMutations,
    forReviewMutationsFetched:
      state.forReviewMutations.forReviewMutationsFetched,
    forReviewMutationsPageNo: state.forReviewMutations.forReviewMutationsPageNo,

    acceptedMutations: state.acceptedMutations.acceptedMutations,
    acceptedMutationsFetched: state.acceptedMutations.acceptedMutationsFetched,
    acceptedMutationsPageNo: state.acceptedMutations.acceptedMutationsPageNo,

    deletedMutations: state.deletedMutations.deletedMutations,
    deletedMutationsFetched: state.deletedMutations.deletedMutationsFetched,
    deletedMutationsPageNo: state.deletedMutations.deletedMutationsPageNo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    logOut: bindActionCreators(logOut, dispatch),
    fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
    fetchMutations: bindActionCreators(fetchMutations, dispatch),
    fetchMutationsCount: bindActionCreators(fetchMutationsCount, dispatch),
    fetchOpenMutations: bindActionCreators(fetchOpenMutations, dispatch),
    fetchCompleteMutations: bindActionCreators(
      fetchCompleteMutations,
      dispatch
    ),
    fetchEmployers: bindActionCreators(fetchEmployers, dispatch),
    fetchDocumentTypes: bindActionCreators(fetchDocumentTypes, dispatch),
    fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
    fetchEntities: bindActionCreators(fetchEntities, dispatch),
    fetchMutationTypes: bindActionCreators(fetchMutationTypes, dispatch),
    fetchInbox: bindActionCreators(fetchInbox, dispatch),
    fetchOutbox: bindActionCreators(fetchOutbox, dispatch),
    fetchProfiles: bindActionCreators(fetchProfiles, dispatch),
    fetchCsv: bindActionCreators(fetchCsv, dispatch),
    fetchImportJobs: bindActionCreators(fetchImportJobs, dispatch),
    fetchTranslations: bindActionCreators(fetchTranslations, dispatch),

    filterPersons: (val) => dispatch(actions.filterPersons(val)),
    filterMutations: (val) => dispatch(actions.filterMutations(val)),
    setInsurantsPageNo: (val) => dispatch(actions.setInsurantsPageNo(val)),
    setMutationsPageNo: (val) => dispatch(actions.setMutationsPageNo(val)),
    setInvoicesPageNo: (val) => dispatch(actions.setInvoicesPageNo(val)),

    setInboxPageNo: (val) => dispatch(actions.setInboxPageNo(val)),
    setOutboxPageNo: (val) => dispatch(actions.setOutboxPageNo(val)),

    setForReviewMutationsPageNo: (data) =>
      dispatch(setForReviewMutationsPageNo(data)),
    setAcceptedMutationsPageNo: (data) =>
      dispatch(setAcceptedMutationsPageNo(data)),
    setDeletedMutationsPageNo: (data) =>
      dispatch(setDeletedMutationsPageNo(data)),

    setInsurantsPkPageNo: (val) => dispatch(setInsurantsPkPageNo(val)),
    setInvoicesPkPageNo: (val) => dispatch(setInvoicesPkPageNo(val)),

    setFetchData: (val) => dispatch(setFetchData(val)),

    fetchInvoicesPk: bindActionCreators(fetchInvoicesPk, dispatch),
    fetchEmployeesPk: bindActionCreators(fetchEmployeesPk, dispatch),
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),

    fetchOpenInvoices: bindActionCreators(fetchOpenInvoices, dispatch),

    fetchForReviewMutations: bindActionCreators(
      fetchForReviewMutations,
      dispatch
    ),
    fetchAcceptedMutations: bindActionCreators(
      fetchAcceptedMutations,
      dispatch
    ),
    fetchDeletedMutations: bindActionCreators(fetchDeletedMutations, dispatch),

    health: bindActionCreators(health, dispatch),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(Header)));
