import * as constants from '../../constants/pensionskasse';
import {LOG_OUT} from "../../constants";

export interface InvoicesStatePK {
    invoices: any;
    invoicesFetched: boolean;
    moreInvoices: boolean;
    invoicesPageNo: number;
    invoicesFirmaFilter: string;
    invoicesOnlyOverdueFilter: boolean;
    invoicesOnlyOpenFilter: boolean;
    invoicesOnlyPaidFilter: boolean;
    overdueInvoicesCount: number;
    paidInvoicesCount: number;
    openInvoicesCount: number;
}

const getInitialState = () => {
    return {
        invoices: [],
        invoicesFetched: false,
        moreInvoices: true,
        invoicesPageNo: 0,
        invoicesFirmaFilter: "",
        invoicesOnlyOverdueFilter: false,
        invoicesOnlyOpenFilter: false,
        invoicesOnlyPaidFilter: false,
        overdueInvoicesCount: 0,
        paidInvoicesCount: 0,
        openInvoicesCount: 0,
    }
};

// console.log("app.ts", getInitialState());

const invoices = (state: InvoicesStatePK = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.FETCHED_INVOICES: {
            const invoices = [ ...action.payload ];

            let { overdueInvoicesCount, paidInvoicesCount, openInvoicesCount } = state;
            if(!state.invoicesFetched) {
                overdueInvoicesCount = invoices.filter(invoice => invoice.attributes.status == 1).length;
                paidInvoicesCount = invoices.filter(invoice => invoice.attributes.status == 2).length;
                openInvoicesCount = invoices.filter(invoice => invoice.attributes.status == 0).length;
            }

            return {
                ...state,
                invoices: invoices,
                invoicesFetched: true,
                overdueInvoicesCount,
                paidInvoicesCount,
                openInvoicesCount
            }
        }

        case constants.SET_INVOICES_PAGE_NO: {
            let moreInvoices = !(state.invoices.length < ((state.invoicesPageNo + 1) * constants.PAGINAION_SIZE));
            return { ...state, invoicesPageNo: action.payload, moreInvoices: moreInvoices}
        }

        case constants.SET_INVOICES_FILTERING: {
            switch(action.payload.name) {
                case "invoicesFirmaFilter":
                    return { ...state, invoicesFirmaFilter: action.payload.value };
                case "invoicesOnlyOverdueFilter": {
                    const value = action.payload.value;
                    return { ...state,
                        invoicesOnlyOverdueFilter: value,
                        invoicesOnlyOpenFilter: value ? false : state.invoicesOnlyOpenFilter,
                        invoicesOnlyPaidFilter: value ? false : state.invoicesOnlyPaidFilter
                    };
                }

                case "invoicesOnlyOpenFilter": {
                    const value = action.payload.value;
                    return { ...state,
                        invoicesOnlyOpenFilter: value,
                        invoicesOnlyOverdueFilter: value ? false : state.invoicesOnlyOverdueFilter,
                        invoicesOnlyPaidFilter: value ? false : state.invoicesOnlyPaidFilter
                    };
                }
                case "invoicesOnlyPaidFilter": {
                    const value = action.payload.value;
                    return { ...state,
                        invoicesOnlyPaidFilter: value,
                        invoicesOnlyOpenFilter: value ? false : state.invoicesOnlyOpenFilter,
                        invoicesOnlyOverdueFilter: value ? false : state.invoicesOnlyOverdueFilter
                    };
                }
                default: {
                    return {
                        ...state,
                        invoicesFirmaFilter: "",
                        invoicesOnlyOverdueFilter: false,
                        invoicesOnlyOpenFilter: false,
                        invoicesOnlyPaidFilter: false,
                    }
                }
            }
        }

        case LOG_OUT: {
            return { ...getInitialState() };
        }
    }

    return state;
};

export default invoices;
