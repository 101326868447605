import api from "../../utils/api";
import * as constants from '../../constants';

export const fetchPayrolls = (insurantId) => {
    return async (dispatch) => {
        return api.get(`insurants/${insurantId}/payrolls/`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_PAYROLLS, "payload": response['data'] });
                return response;
            }).catch(err => err);
    }
};

export const createPayroll = (insurantId, data) => {
    return async (dispatch) => {
        return api.post(`insurants/${insurantId}/payrolls/`, data)
            .then(response => response)
            .catch(err => err);
    }
};

export const patchPayroll = (insurantId, payrollId, data) => {
    return async (dispatch) => {
        return api.patch(`insurants/${insurantId}/payrolls/${payrollId}/`, data)
            .then(response => response)
            .catch(err => err);
    }
};

export const deletePayroll = (insurantId, payrollId) => {
    return async (dispatch) => {
        return api.delete(`insurants/${insurantId}/payrolls/${payrollId}/`)
            .then(response => response)
            .catch(err => err);
    }
};
