import * as constants from '../../constants';

export interface PayrollsState {
    payrolls: any;

}

const getInitialState = () => {
    return {
        payrolls: {},
    }
};

// console.log("app.ts", getInitialState());

const payrolls = (state: PayrollsState = getInitialState(), action: any) => {
    switch (action.type) {

        case constants.FETCHED_PAYROLLS: {
            const payrollsObj = {};
            action.payload.forEach((entity) => {
                payrollsObj[entity.id] = entity.attributes;
            })
            return { ...state, payrolls: payrollsObj };
        }

        case constants.UPDATED_PAYROLL: {
            const updatedPayrolls = { ...state.payrolls };
            updatedPayrolls[action.payload.id] = action.payload.attributes;
            return { ...state, payrolls: { ...updatedPayrolls }}
        }

        case constants.LOG_OUT: {
            return { ...getInitialState() };
        }

    }

    return state;
};

export default payrolls;
