import * as React from "react";
import { injectIntl } from "react-intl";
import { RootState } from "../../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../../actions";
import {
  fetchAcceptedMutations,
  fetchDeletedMutations,
  fetchForReviewMutations,
  setAcceptedMutationsFilter,
  setAcceptedMutationsPageNo,
  setDeletedMutationsFilter,
  setDeletedMutationsPageNo,
  setForReviewMutationsFilter,
  setForReviewMutationsPageNo,
} from "../../../../actions/pensionskasse/mutations";
import { connect } from "react-redux";
import SidebarFilterForm, {
  AcceptedSideBarFilterNames,
  DeletedSideBarFilterNames,
  ForReviewSideBarFilterNames,
} from "../SidebarFilterForm";
interface State {}
interface Props {
  intl: any;
  match: any;
  history: any;
  insurants: any;
  employers: any;
  entities: any;
  mutationTypes: any;
  setLoading: any;
  fetchForReviewMutations: any;
  fetchAcceptedMutations: any;
  fetchDeletedMutations: any;
  acceptedMutationsFetched: boolean;
  deletedMutationsFetched: boolean;
  setForReviewMutationsPageNo: any;
  setDeletedMutationsPageNo: any;
  acceptedMutationsPageNo: any;
  forReviewMutationsPageNo: any;
  deletedMutationsPageNo: any;
  setForReviewMutationsFilter: any;
  setDeletedMutationsFilter: any;
  setAcceptedMutationsPageNo: any;
  setAcceptedMutationsFilter: any;
  forReviewMutationsNameFilter: any;
  forReviewMutationsEventFilter: any;
  forReviewMutationsFirmaFilter: any;
  forReviewMutationsOnlyErrorsFilter: any;
  forReviewMutationsOnlyWarningsFilter: any;
  forReviewMutationsNoWarningsErrorsFilter: any;
}
class ForReviewSidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  private debounce = null;

  filterForReviewMutations = (name, value, employers?) => {
    this.props.setForReviewMutationsFilter({ name, value });
    if (name === "clear") {
      this.props.setDeletedMutationsFilter({ name, value });
      this.props.setAcceptedMutationsFilter({ name, value });
    }
    if (name === ForReviewSideBarFilterNames.forReviewMutationsNameFilter) {
      this.props.setDeletedMutationsFilter({
        name: DeletedSideBarFilterNames.deletedMutationsNameFilter,
        value,
      });
      this.props.setAcceptedMutationsFilter({
        name: AcceptedSideBarFilterNames.acceptedMutationsNameFilter,
        value,
      });
    } else if (
      name === ForReviewSideBarFilterNames.forReviewMutationsFirmaFilter
    ) {
      this.props.setDeletedMutationsFilter({
        name: DeletedSideBarFilterNames.deletedMutationsFirmaFilter,
        value,
      });
      this.props.setAcceptedMutationsFilter({
        name: AcceptedSideBarFilterNames.acceptedMutationsFirmaFilter,
        value,
      });
    } else if (
      name === ForReviewSideBarFilterNames.forReviewMutationsEventFilter
    ) {
      this.props.setDeletedMutationsFilter({
        name: DeletedSideBarFilterNames.deletedMutationsEventFilter,
        value,
      });
      this.props.setAcceptedMutationsFilter({
        name: AcceptedSideBarFilterNames.acceptedMutationsEventFilter,
        value,
      });
    }

    if (employers === true && value !== "") {
      return;
    }

    this.props.setForReviewMutationsPageNo(0);
    if (
      name === ForReviewSideBarFilterNames.forReviewMutationsNameFilter ||
      name === ForReviewSideBarFilterNames.forReviewMutationsFirmaFilter ||
      name === ForReviewSideBarFilterNames.forReviewMutationsEventFilter ||
      name === "clear"
    ) {
      this.props.setAcceptedMutationsPageNo(0);
      this.props.setDeletedMutationsPageNo(0);
    }

    this.props.setLoading(true);
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.props.fetchForReviewMutations().then(() => {
        this.props.setLoading(false);
        this.props.setForReviewMutationsPageNo(
          this.props.forReviewMutationsPageNo + 1
        );
      });

      if (
        name === ForReviewSideBarFilterNames.forReviewMutationsNameFilter ||
        name === ForReviewSideBarFilterNames.forReviewMutationsFirmaFilter ||
        name === ForReviewSideBarFilterNames.forReviewMutationsEventFilter ||
        name === "clear"
      ) {
        this.props.fetchDeletedMutations().then(() => {
          this.props.setLoading(false);
          this.props.setDeletedMutationsPageNo(
            this.props.deletedMutationsPageNo + 1
          );
        });

        this.props.fetchAcceptedMutations().then(() => {
          this.props.setLoading(false);
          this.props.setAcceptedMutationsPageNo(
            this.props.acceptedMutationsPageNo + 1
          );
        });
      }
    }, 500);
  };

  render() {
    const { employers, mutationTypes } = this.props;
    return (
      <>
        <div className="sidebar-container">
          <SidebarFilterForm
            employers={employers}
            mutationTypes={mutationTypes}
            mutationsEventFilter={this.props.forReviewMutationsEventFilter}
            isForReviewSideBar={true}
            nameInputFilter="forReviewMutationsNameFilter"
            filterInputValue={this.props.forReviewMutationsNameFilter}
            filterFunction={this.filterForReviewMutations}
            mutationTypeInputName="forReviewMutationsEventFilter"
            firmInputId="filterTypeInbox"
            firmInputName="forReviewMutationsFirmaFilter"
            firmInputKey="filterTypeInbox"
            firmInputValue={
              this.props.employers[this.props.forReviewMutationsFirmaFilter]
                ? this.props.employers[
                    this.props.forReviewMutationsFirmaFilter
                  ]["attributes"]["name"]
                : this.props.forReviewMutationsFirmaFilter
            }
            forReviewMutationsOnlyWarningsFilter={
              this.props.forReviewMutationsOnlyWarningsFilter
            }
            forReviewMutationsOnlyErrorsFilter={
              this.props.forReviewMutationsOnlyErrorsFilter
            }
            forReviewMutationsNoWarningsErrorsFilter={
              this.props.forReviewMutationsNoWarningsErrorsFilter
            }
          />
        </div>

        {/*<div className="breaker" />*/}

        {/*<div className="paddingContainer">*/}
        {/*    <p className="sidebar-header mt-32 mb-32">*/}
        {/*        <FormattedMessage id="app.generatepdf" defaultMessage="PDF generieren"/>*/}
        {/*    </p>*/}
        {/*    <div className="d-flex justify-content-between mt-32" onClick={() => null}>*/}
        {/*        <p>Liste als PDF herunterladen</p>*/}
        {/*        <Button*/}
        {/*            type={ButtonTypes.Text}*/}
        {/*            color={ButtonColors.Action}*/}
        {/*            onClick={() => null}>*/}
        {/*            <PdfIcon/>*/}
        {/*        </Button>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    mutationTypes: state.app.mutationTypes,

    forReviewMutationsPageNo: state.forReviewMutations.forReviewMutationsPageNo,
    acceptedMutationsPageNo: state.acceptedMutations.acceptedMutationsPageNo,
    deletedMutationsPageNo: state.deletedMutations.deletedMutationsPageNo,

    forReviewMutationsNameFilter:
      state.forReviewMutations.forReviewMutationsNameFilter,
    forReviewMutationsEventFilter:
      state.forReviewMutations.forReviewMutationsEventFilter,
    forReviewMutationsFirmaFilter:
      state.forReviewMutations.forReviewMutationsFirmaFilter,
    forReviewMutationsOnlyErrorsFilter:
      state.forReviewMutations.forReviewMutationsOnlyErrorsFilter,
    forReviewMutationsOnlyWarningsFilter:
      state.forReviewMutations.forReviewMutationsOnlyWarningsFilter,
    forReviewMutationsNoWarningsErrorsFilter:
      state.forReviewMutations.forReviewMutationsNoWarningsErrorsFilter,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    fetchForReviewMutations: bindActionCreators(
      fetchForReviewMutations,
      dispatch
    ),
    fetchAcceptedMutations: bindActionCreators(
      fetchAcceptedMutations,
      dispatch
    ),
    fetchDeletedMutations: bindActionCreators(fetchDeletedMutations, dispatch),
    setForReviewMutationsPageNo: (val) =>
      dispatch(setForReviewMutationsPageNo(val)),
    setAcceptedMutationsPageNo: (val) =>
      dispatch(setAcceptedMutationsPageNo(val)),
    setDeletedMutationsPageNo: (val) =>
      dispatch(setDeletedMutationsPageNo(val)),
    setForReviewMutationsFilter: (val) =>
      dispatch(setForReviewMutationsFilter(val)),
    setDeletedMutationsFilter: (val) =>
      dispatch(setDeletedMutationsFilter(val)),
    setAcceptedMutationsFilter: (val) =>
      dispatch(setAcceptedMutationsFilter(val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ForReviewSidebar));
