import * as React from 'react';
import {Button, ButtonColors, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {postDeleteMutation, processMutation} from "../../../../actions/pensionskasse/mutations";
import {setLoading} from "../../../../actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {RootState} from "../../../../reducers";

interface IProps {
    intl: any;
    loading: boolean;
    onSubmit: any;
    onCancel: any;
}

interface IState {
}

class CommitMutationsPopup extends React.Component<IProps, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h1 className="mb-16">Änderungen abgeben</h1>

                <p className="mb-16">Wollen Sie die Mutation wirklich abgeben?</p>

                <p className="mb-32">
                    Wollen Sie diese Änderungen jetzt an die Pensionskasse übermitteln?
                </p>

                <div className="modal-buttons">
                    <Button
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        onClick={this.props.onCancel}>
                        <FormattedMessage id="app.cancel" defaultMessage="Abbrechen"/>
                    </Button>
                    <Button
                        type={ButtonTypes.Standard}
                        color={ButtonColors.Action}
                        style={ButtonStyles.Primary}
                        onClick={this.props.onSubmit}
                        isDisabled={this.props.loading}>
                        JETZT ABGEBEN
                    </Button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: RootState, ownProps: any) => ({
    loading: state.app.loading
});
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(CommitMutationsPopup));
