import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import "./Modals.scss";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { renderSwitch } from "../../../../utils/react";
import { mutationFormTypes } from "../../../../constants";

interface Props {
  history: any;
  selectedPayroll?: any;
  onNewPayrollClick: () => void;
  editButton: (payroll) => void;
  deleteButton: () => void;
  modalType: string;
}

const AddEditOrRemoveSalary = ({
  history,
  selectedPayroll,
  onNewPayrollClick,
  editButton,
  modalType,
  deleteButton,
}: Props) => {
  const [check, setCheck] = useState(false);

  return renderSwitch(modalType, {
    [mutationFormTypes.RemoveSalary]: () => (
      <div>
        <div className="body-wrapper">
          <p className="title">
            <FormattedMessage
              id="app.salary.removesalary.title"
              defaultMessage="Lohnänderung löschen"
            />
          </p>
          <br />

          <p className="label">
            <FormattedMessage
              id="app.salary.removesalary.body1"
              defaultMessage="Sie möchten diese Lohnänderung löschen."
            />
          </p>
          <br />

          <p className="label">
            <FormattedMessage
              id="app.salary.removesalary.body2"
              defaultMessage="Mit der Löschung wird der vorherige Lohn rückwirkend zum gültigen Lohn. Dies hat rückwirkende Auswirkungen auf die Beiträge."
            />
          </p>

          <Input
            type="checkbox"
            inputWrapperClass="checkbox-input unpaidvacation-checkbox"
            id={"contributionCoverage1"}
            name={"contributionCoverage1"}
            checkboxLabel={
              <span className="check-box-label">
                Ich bin mir der Auswirkung bewusst und möchte die Lohnänderung
                rückwirkend löschen
              </span>
            }
            value={check}
            checked={check}
            onChange={(name, valueInput) => {
              setCheck(valueInput);
            }}
          />
        </div>

        <div className="btn-line">
          <Button
            onClick={deleteButton}
            type={ButtonTypes.Standard}
            isDisabled={!check}
            style={ButtonStyles.Primary}
            color={ButtonColors.Error}
          >
            <FormattedMessage
              id="app.salary.removesalary.button.removeSalary"
              defaultMessage="LOHNÄNDERUNG RÜCKWIRKEND LÖSCHEN"
            />
          </Button>
          <Button
            className="mr-32"
            onClick={() => history.goBack()}
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
          >
            <FormattedMessage id="app.cancel" defaultMessage="ABBRECHEN" />
          </Button>
        </div>
      </div>
    ),
    [mutationFormTypes.EditOrAddSalary]: () => (
      <div>
        <div className="body-wrapper">
          <p className="title">
            <FormattedMessage
              id="app.salary.editoradd.title"
              defaultMessage="Fehleingabe korrigieren"
            />
          </p>
          <br />

          <p className="label">
            <FormattedMessage
              id="app.salary.editoradd.body1"
              defaultMessage="Sie möchten eine frühere Fehleingabe korrigieren, dann sind sie hier richtig."
            />
          </p>
          <br />

          <p className="label">
            <FormattedMessage
              id="app.salary.editoradd.body2"
              defaultMessage="Sie möchten eine neue Lohnänderung melden? Dann tun sie dies, indem sie im Lohnänderungsformular den neuen Lohn melden."
            />
          </p>
        </div>

        <div className="btn-line">
          <Button
            onClick={() => editButton(selectedPayroll)}
            type={ButtonTypes.Standard}
            style={ButtonStyles.Primary}
            color={ButtonColors.Action}
          >
            <FormattedMessage
              id="app.salary.editoradd.button.editSalary"
              defaultMessage="FEHLEINGABE KORRIGIEREN"
            />
          </Button>
          <Button
            className="mr-32"
            onClick={onNewPayrollClick}
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
          >
            <FormattedMessage
              id="app.salary.editoradd.button.newSalary"
              defaultMessage="NEUEN LOHN MELDEN"
            />
          </Button>
        </div>
      </div>
    ),
  });
};

export default AddEditOrRemoveSalary;
