import * as React from 'react';
import {Blank, ExpandableRow} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import PersonenExpandableRowTop from "../PersonenExpandableRowTop";
import {ProcessingStates} from "../../../../models";
import PersonenExpandableRowBottom from "../PersonenExpandableRowBottom";
import styles from "./PersonenList.module.scss";
import {isRowDisabled} from "../../../../utils/react";
import classNames from "classnames";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {fetchEmployees, setInsurantsFilter, setInsurantsPageNo} from "../../../../actions/pensionskasse/employee";
import {connect} from "react-redux";

interface Props {
    intl: any;
    employers: any;
    insurants: any;
    expandedRow: any;
    filtersChanged: any;
    onDetailsClick: any;
    onEditClick: any;

    setLoading: any;
    fetchEmployees: any;
    setInsurantsPageNo: any;
    setInsurantsFilter: any;

    insurantsNameFilter: string;
    insurantsFirmaFilter: string;
    insurantsStatusFilter: string;
}

interface State {
    expandedRow: any;
    showMoreSpinner: boolean;
}

class PersonenList extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            expandedRow: null,
            showMoreSpinner: false
        }
    }

    rowChanged = (expanded, row) => {
        this.setState({expandedRow: row});
        // if (expanded) {
        //     document.querySelector('.main-content-container.insurances').classList.add("expanded");
        // } else {
        //     document.querySelector('.main-content-container.insurances').classList.remove("expanded");
        // }
    }

    clearFilters = () => {
        this.props.setInsurantsFilter({ name: "clear" });
        this.props.setInsurantsPageNo(0);
        this.props.setLoading(true);
        this.props.fetchEmployees().then(() => {
            this.props.setLoading(false);
            this.props.setInsurantsPageNo(1);
        })
    }

    filtersAreApplied = () => {
        return !!(this.props.insurantsFirmaFilter || this.props.insurantsNameFilter || this.props.insurantsStatusFilter)
    }

    render() {
        return (
            <div className={styles.listView}>
                <div className={styles.listHeader}>
                    <div className={styles.column}>
                        <FormattedMessage id="app.name_geb_datum" defaultMessage="Name / Geburtsdatum" />
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.personen.list.header.socialSecurityNumber" defaultMessage="AHV-Nr. / Vers.-Nr." />
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.employer" defaultMessage=" Arbeitgeber" />
                    </div>
                    <div className={styles.column}>
                        <FormattedMessage id="app.status" defaultMessage="Status" />
                    </div>
                    <div className={styles.column}>
                        <div style={{ width: "7rem" }} />
                    </div>
                </div>
                {!!this.props.insurants.length ?
                    this.props.insurants.map((insurant, index) => (
                    <ExpandableRow
                        expandable
                        className={classNames("mb-24", styles.listItem)}
                        key={`insurants_row_${index}`}
                        index={index}
                        expanded={this.state.expandedRow}
                        length={this.props.filtersChanged}
                        rowChanged={(expanded) => this.rowChanged(expanded, index)}
                        content={<PersonenExpandableRowTop
                                        insurant={insurant}
                                        employers={this.props.employers}
                                        onDetailsClick={(id) => this.props.onDetailsClick(id)} />}
                        more={<PersonenExpandableRowBottom
                                    insurant={insurant}
                                    employer={this.props.employers[insurant.attributes.employee.employerId]}
                                    onEditClick={(id, type)  => this.props.onEditClick(id, type)} />}
                    />))
                    : <Blank
                        onButtonClick={this.clearFilters}
                        showButton={this.filtersAreApplied()}
                        text={this.props.intl.formatMessage({id: "app.noinsurants", defaultMessage: "Keine Personen vorhanden" })}/>}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    insurantsNameFilter: state.insurantsPK.insurantsNameFilter,
    insurantsFirmaFilter: state.insurantsPK.insurantsFirmaFilter,
    insurantsStatusFilter: state.insurantsPK.insurantsStatusFilter,
});
const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
    fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
    setInsurantsPageNo: (val) => dispatch(setInsurantsPageNo(val)),
    setInsurantsFilter: (val) => dispatch(setInsurantsFilter(val))
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(injectIntl(PersonenList));
