/**
 * Renders the appropriate content depending if the predicate is true or false
 */
import { EValidationTestType } from "./validationConfigs";
import { logOutLocally } from "../actions";
import { toastr } from "react-redux-toastr";
import { ProcessingStates } from "../models";
import { mutationToFormMap } from "../constants";
import { FormatErrorMessageType } from "../@types/forms/agp/formValidation";
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

// import { IDictionary } from '../models';

export const isObjEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export function renderIf(
  condition: boolean,
  content: {
    ifTrue: () => JSX.Element | JSX.Element[];
    ifFalse: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (condition) {
    return content.ifTrue();
  } else {
    return content.ifFalse();
  }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
  condition: boolean,
  content: () => JSX.Element
): JSX.Element | JSX.Element[] | null {
  if (condition) {
    return content();
  }
  return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
  condition: boolean,
  content: () => JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null {
  if (!condition) {
    return content();
  }
  return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefinedTrue<T>(
  data: T,
  content: (data: T) => JSX.Element
): JSX.Element | null {
  if (typeof data !== "undefined" && data !== null) {
    return content(data);
  } else {
    return null;
  }
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
  data: T,
  content: {
    isDefined: (data: T) => JSX.Element | JSX.Element[];
    isNotDefined: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (typeof data !== "undefined" && data !== null) {
    return content.isDefined && content.isDefined(data);
  } else {
    return content.isNotDefined && content.isNotDefined();
  }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
  key: string | number,
  callbacks: IDictionary<() => JSX.Element>,
  defaultCallback?: () => JSX.Element
): JSX.Element | null {
  if (callbacks[key]) {
    return callbacks[key]();
  } else {
    if (defaultCallback) {
      return defaultCallback();
    } else {
      return null;
    }
  }
}

export const isString = (string) => {
  return typeof string === "string";
};

export const parseDate = (str, type?) => {
  // console.log("parseDate utils: ", str);
  if (!str) {
    return "";
  }
  if (/\d{4}\-\d{2}\-\d{2}/gm.test(str)) {
    if (str != null && str != "") {
      var d = new Date(str),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = "" + d.getHours(),
        minutes = "" + d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;

      if (type && type == "hr-min") {
        return (
          [day, month, year].join(".") + " - " + [hours, minutes].join(":")
        );
      }
      // console.log("will return date matched: ", day, month, year);
      const date = [day, month, year].join(".");
      if (date.includes("NaN")) {
        return;
      }
      return date;
    } else {
      return "";
    }
  } else {
    return str;
  }
};

export const parseToApiDate = (str, type?) => {
  //check if its already parsed
  if (str.includes("T00:00:00")) {
    return str;
  }

  //check if do not exist two dots
  if (!/(\..*){2,}/.test(str)) {
    return "";
  }
  const parts = str.split(".");
  if (!/\d{2}\.\d{2}\.\d{4}/gm.test(str)) {
    parts.map((item, index) => {
      return item.length < 2 && (parts[index] = `0${item}`);
    });
  }
  return parts[2] + "-" + parts[1] + "-" + parts[0] + "T00:00:00";
};

export function removeEmptyObjects(o) {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue; // If null or not an object, skip to the next iteration
    }
    // The property is an object
    removeEmptyObjects(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
}

export function emptyStringsToNull(o) {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      if (o[k] === "") {
        console.log("matched empty string: ", k);
        o[k] = null;
      }
      if (typeof o[k] === "undefined") {
        delete o[k];
      }
    } else {
      emptyStringsToNull(o[k]);
    }
  }
  return o;
}

export function removeNullProperties(o) {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      if (typeof o[k] === "undefined" || o[k] === null) {
        delete o[k];
      }
    } else {
      emptyStringsToNull(o[k]);
    }
  }
  return o;
}

export function formatNumber(num) {
  // console.log("formatNumber: ", num);
  if (!num) {
    return num;
  }
  if (typeof num == "string" && /[a-zA-Z]/.test(num)) {
    return num;
  }
  if (typeof num == "string") {
    num = num.replace(/'/g, "").replace(/,/g, ".");
  }
  return parseFloat(num)
    .toFixed(2)
    .replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "'" + c : c;
    });
}

export function inputIsRequired(validationTests) {
  return (
    validationTests.indexOf(EValidationTestType.hasValue) >= 0 ||
    validationTests.indexOf(EValidationTestType.hasAnyValue) >= 0
  );
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function compareObjects(obj1, obj2) {
  // console.log("VERSICHERTE", obj1, obj2)
  return JSON.stringify(obj1) == JSON.stringify(obj2);
}

export function percentage(max, value) {
  console.log("PROGRESS", max, value);
  return max && value ? (value * 100) / max : 0;
}

export const calculateStickyActionsBottomPosition = (itemsCount) => {
  const itemHeight = 72;
  const stickyActionsContainerPadding = 40;
  return -(itemsCount * itemHeight) - stickyActionsContainerPadding;
};

export const isTokenExpired = (token, expirationDate, authenticationStep?) => {
  let now = new Date().getTime();
  let expiration = new Date(expirationDate).getTime();
  if (
    !token ||
    (expirationDate &&
      expiration <= now &&
      authenticationStep != null &&
      authenticationStep != "2fa")
  ) {
    logOutLocally();
    return true;
  } else {
    return false;
  }
};

export const setBodyClassNames = (opened) => {
  if (opened) {
    document.body.classList.add("opened");
    document.body.classList.remove("closed");
  } else {
    document.body.classList.remove("opened");
    document.body.classList.add("closed");
  }
};

export const expiredAlert = (path) => {
  if (path != "/login" && path != "/") {
    toastr.error("Session expired", "Please log in again");
  }
  return true;
};

export const groupMutationsByEmployer = (mutations, employers_obj) => {
  let employers: any =
    employers_obj && mutations.length ? Object.keys(employers_obj) : [];

  employers = employers.map((employer) => {
    const employer_obj = employers_obj[employer];
    const employer_mutations = mutations.filter(
      (mutation) => mutation["attributes"]["employerId"] === employer
    );
    let employees = [];

    for (let i = 0; i < employer_mutations.length; i++) {
      const employee = employer_mutations[i]["attributes"]["employeeId"];
      if (!employees.includes(employee)) {
        employees.push(employee);
      }
    }

    employees = employees.map((employee) => {
      const employee_mutations = employer_mutations.filter(
        (mutation) => mutation["attributes"]["employeeId"] === employee
      );

      return {
        id: employee,
        mutations: employee_mutations,
      };
    });

    return {
      id: employer,
      name: employer_obj ? employer_obj["attributes"]["name"] : "Employer",
      employees: employees,
    };
  });
  const employersWithEmployees = employers.filter(
    (x) => x.employees.length >= 1
  );
  return employersWithEmployees;
};

export function getLatestCsvImport(csv_imports) {
  let sorted = csv_imports.sort((a, b) => {
    return (
      new Date(b["attributes"]["imported-at"]).getTime() -
      new Date(a["attributes"]["imported-at"]).getTime()
    );
  });
  return sorted[0];
}

export function formatCsvImportDate(string) {
  // "2020-08-06T11:06:29.2206086"
  const date = string.split("T")[0];
  const time = string.split("T")[1];
  const splitDate = date.split("-");
  const splitTime = time.split(":");
  const formattedDate = [splitDate[2], splitDate[1], splitDate[0]].join(".");
  const formattedTime = [splitTime[0], splitTime[1]].join(":");
  return formattedDate + ", " + formattedTime;
}

export const hasParentWithMatchingSelector = (target, el) => {
  return el.contains(target);
  // return [...document.querySelectorAll(selector) as any].some(el =>
  //     el !== target && el.contains(target)
  // )
};

export const prepareInsurancePlanObject = (plans) => {
  let obj = {};
  if (!plans || !plans.length) return obj;
  plans.forEach((plan) => {
    obj[plan["code"]] = plan;
  });
  return obj;
};

export const prepareInsurancePlansDropdownOptions = (entities) => {
  if (!entities || !Object.keys(entities).length) return [];
  return Object.keys(entities).map((key) => {
    return {
      value: entities[key]["code"],
      label: entities[key]["name"],
    };
  });
};

export const prepareEmployersDropdownOptions = (entities) => {
  if (!entities || !Object.keys(entities).length) return [];
  return Object.keys(entities).map((key) => {
    return {
      value: entities[key]["id"],
      label: entities[key]["attributes"]["name"],
    };
  });
};

export const prepareCountriesEntitiesDropdownOptions = (entities) => {
  if (!entities || !Object.keys(entities).length) return [];
  const keyToRemove = "keinCode";
  if (entities[keyToRemove]) {
    delete entities[keyToRemove];
  }
  return Object.keys(entities).map((key) => {
    return {
      value: key,
      label: entities[key],
    };
  });
};

export const prepareGendersEntitiesDropdownOptions = (entities) => {
  if (!entities || !Object.keys(entities).length) return [];
  const keyToRemove = 0;
  const valueToRemove = "Unbekannt";
  if (entities[keyToRemove] && entities[keyToRemove] == valueToRemove) {
    delete entities[keyToRemove];
  }
  return Object.keys(entities).map((key) => {
    return {
      value: key,
      label: entities[key],
    };
  });
};

export const prepareEntitiesDropdownOptions = (entities) => {
  if (!entities || !Object.keys(entities).length) return [];
  return Object.keys(entities).map((key) => {
    return {
      value: key,
      label: entities[key],
    };
  });
};

export const prepareMutationTypesDropdownOptions = (mutationTypes) => {
  if (!mutationTypes || !Object.keys(mutationTypes).length) return [];
  return Object.keys(mutationTypes).map((key) => {
    return {
      value: mutationTypes[key]["id"],
      label: mutationTypes[key]["attributes"]["translation"],
    };
  });
};

export const prepareEmployersForCommit = (employers, mutations) => {
  if (!employers || !Object.keys(employers).length) return [];
  const parsed_employers = Object.keys(employers).map((key) => {
    return {
      value: employers[key]["id"],
      processingState: employers[key]["attributes"]["processingState"],
      label: `${employers[key]["attributes"]["extEmployerId"]}  ${employers[key]["attributes"]["name"]}`,
    };
  });
  return parsed_employers.filter((employer) => {
    return employer.processingState == ProcessingStates.Changed;
  });
};

export const allEmployersCommitted = (employers) => {
  if (employers && Object.keys(employers).length) {
    const uncommittedEmployers = Object.values(employers).filter(
      (employer) =>
        employer["attributes"]["processingState"] != ProcessingStates.Committed
    );
    return !uncommittedEmployers.length;
  } else {
    return true;
  }
};

export const isRowDisabled = (insurant) => {
  return (
    insurant.attributes.employee.processingState ===
      ProcessingStates.Committed ||
    insurant.attributes.employee.processingState === ProcessingStates.Exported
  );
};

export const getModalName = (eventName) => {
  if (eventName === "Other") {
    return "";
  } else if (mutationToFormMap[eventName]) {
    return mutationToFormMap[eventName];
  } else {
    return "Beschäftigungsgrad/Lohn";
  }
};

export function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function calculateAgeFromDates(a, b) {
  a = new Date(a);
  b = new Date(b);
  var age = a.getFullYear() - b.getFullYear();
  var m = a.getMonth() - b.getMonth();
  if (m < 0 || (m === 0 && a.getDate() < b.getDate())) {
    age--;
  }
  return age;
}

export const checkDiffInDays = (dateBegin, dateFinal) => {
  const from = buildDateFromString(dateBegin);
  const until = buildDateFromString(dateFinal);
  const difference = differenceInDays(from, until);
  return difference;
};

export function formatYear(val) {
  if (val == "" || !val) return val;
  val = val.replace(/[^\d]/g, "");
  if (val.length > 4) {
    val = val.slice(0, 4);
  }
  if (val.length == 4) {
    if (parseInt(val) < 1970) {
      return "1970";
    }
    if (parseInt(val) > 2100) {
      return "2100";
    }
  }

  return val;
}

export function formatMonth(val) {
  if (val == "" || !val) return val;
  val = val.replace(/[^\d]/g, "");
  if (val.length > 2) {
    val = val.slice(0, 2);
  }
  if (parseInt(val) > 12) {
    val = "12";
  }
  if (val.length > 1 && parseInt(val) < 1) {
    val = "01";
  }
  return val;
}

export function formatDate(val, isFocused) {
  if (val && Array.from(val).length > 0) {
    val = val.replace(/[^\d.-]/g, "");

    if (val && val.length && val.length > 10) {
      val = val.substr(0, 10);
    }

    if (val == "") return val;
    var value = val;
    if (!value) return val;
    if (isFocused) {
      var parts = val.split(".");
      if (parts.length == 1) {
        if (val.length > 2) {
          value = val[0] + val[1] + "." + val[2];
        }
      }
      if (parts.length == 2) {
        if (parts[0].length == 1 && parts[1].length == 0) {
          value = "0" + parts[0] + ".";
        }
        if (parts[1].length == 3) {
          value =
            parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[1][2];
        }
      }
      if (parts.length == 3) {
        if (parts[1].length == 1 && parts[2].length == 0) {
          value = parts[0] + ".0" + parts[1] + "." + parts[2];
        }
        if (parts[1].length == 2) {
          value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[2];
        }
      }
    } else {
      var parts = val.split(".");
      if (parts.length == 1) {
        if (val.length > 2) {
          return val[0] + val[1] + "." + val[2];
        }
      }
      if (parts.length == 2) {
        if (parts[0].length == 1) {
          return "0" + parts[0] + ".";
        }
        if (parts[1].length > 2) {
          return parts[0] + "." + parts[1][0] + parts[1][1] + ".";
        }
      }
      if (parts.length == 3) {
        if (parts[2].length == 2) {
          if (parseInt(parts[2]) < 45) {
            value = parts[0] + "." + parts[1] + "." + "20" + parts[2];
          } else {
            value = parts[0] + "." + parts[1] + "." + "19" + parts[2];
          }
        } else if (parts[2].length == 4) {
          value = parts[0] + "." + parts[1] + "." + parts[2];
        }
        if (parts[0].length == 1) {
          value = "0" + parts[0] + "." + parts[1] + "." + parts[2];
        }
        if (parts[1].length == 1) {
          value = parts[0] + ".0" + parts[1] + "." + parts[2];
        }
      }
    }
    return value;
  } else {
    return val;
  }
}

export const formatErrorMessage = ({
  message,
  minValue,
  maxValue,
  aux,
}: FormatErrorMessageType) => {
  if (message.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) {
    const mapObj = { "0": `${minValue}`, "1": `${maxValue}`, "2": `${aux}` };
    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    return message
      .replace(re, (match) => {
        return mapObj[match];
      })
      .replaceAll("{", "")
      .replaceAll("}", "");
  } else {
    return message;
  }
};

export const groupingMessages = (objMessages: object, name: string) => {
  let multipleMessages = [];
  let response = {};
  Object.keys(objMessages).map((key) => {
    return key.includes(name)
      ? multipleMessages.push(objMessages[key])
      : (response[key] = objMessages[key]);
  });
  return { ...response, [name]: multipleMessages };
};

export const dateFilter = (value: number) => {
  switch (value) {
    case 1:
      return EValidationTestType.isFirstDayOfTheMonth;
    case 2:
      return EValidationTestType.isFifteenthDayOfTheMonth;
    case 3:
      return EValidationTestType.isLastDayOfTheMonth;
    default:
      return EValidationTestType.hasValue;
  }
};

export const buildDateFromString = (date): Date => {
  let converted = new Date();
  if (date.includes("T")) date = date.split("T")[0];
  const isSplittedWithDot = date.includes("."); // if false, splitted by - (dash)

  if (date) {
    try {
      const rawFullDate = date
        .split("T")[0]
        .split(isSplittedWithDot ? "." : "-");

      const day = parseInt(rawFullDate[0]);
      const month = parseInt(rawFullDate[1]);
      const year = parseInt(rawFullDate[2]);

      converted = new Date(year, month - 1, day);

      return converted;
    } catch {
      return converted;
    }
  } else return converted;
};

export const filteredLanguages = (languages) => {
  Object.keys(languages).forEach((key) => {
    if (languages[key] === "Englisch") {
      delete languages[key];
    }
  });
  return languages;
};

export const formatSSN = (value: string) => {
  return value.replace(/(\w{3})(\w{4})(\w{4})(\w{2})/g, "$1.$2.$3.$4");
};

export const IntervalType = (value: number, from: string, until: string) => {
  const dateFrom = buildDateFromString(from);
  const dateUntil = buildDateFromString(until);

  switch (value) {
    case 5:
      return differenceInDays(dateUntil, dateFrom);
    case 7:
      return differenceInMonths(dateUntil, dateFrom);
    case 8:
      return differenceInYears(dateUntil, dateFrom);
    default:
      return differenceInYears(dateUntil, dateFrom);
  }
};
