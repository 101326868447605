import * as React from 'react';
import {injectIntl} from "react-intl";
import {RootState} from "../../../../reducers";
import {connect} from "react-redux";
import styles from "./DeletedMutationExpandableRowBottom.module.scss"

interface IProps {
    intl: any;
    mutation: any;
}

const DeletedMutationExpandableRowBottom = ({mutation}: IProps) => {

    return (
        <div className={styles.deletedMutationExpandableRowBottom}>
            <div className={styles.headerText}>
                Löschgrund
            </div>
            <p>{mutation.attributes.reasonForDeletion}</p>
        </div>
    );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    loading: state.app.loading
})

const connector = connect(mapStateToProps, null);
export default connector(injectIntl(DeletedMutationExpandableRowBottom));
