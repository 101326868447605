import React from "react";
import {Button, ButtonColors, ButtonTypes} from "icr-shared-components";
import {ReactComponent as IconContact} from "../../../../assets/icons/personalien.svg";
import styles from "./CompaniesExpandableRowTop.module.scss";
import {ReactComponent as EmployerIcon} from "../../../../assets/icons/arbeitgeber.svg";
import {ReactComponent as FirmenIcon} from "../../../../assets/icons/firmen.svg";

interface IProps {
    company: any;
    onDetailsClick: any;
}

const CompaniesExpandableRowTop = ({ company, onDetailsClick }: IProps) => {
    const icon = company.attributes.isEmployer ? <EmployerIcon className="mr-12" /> : <FirmenIcon className="mr-12" /> ;
    return (
        <div className={styles.companiesExpandableRowTop}>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Organisatorische Einheit / Arbeitgeber</span>
                <p>{icon}<b> {company.attributes.name} </b></p>
            </div>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Arbeitgeber Nr.</span>
                <p>{company.attributes.extEmployerId}</p>
            </div>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Kassen Nr.</span>
                <p>{company.attributes.extParentId}</p>
            </div>
            <div className={styles.column}>
                <p>{company.attributes.description}</p>
            </div>
            <div className={styles.column}>
                <Button
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={() => onDetailsClick()}>
                    <IconContact/>
                </Button>
            </div>
        </div>
        )
}

export default CompaniesExpandableRowTop;
