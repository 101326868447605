import React from "react";
import styles from "./CompanyDetailsRowBottom.module.scss";
import {injectIntl} from "react-intl";

interface Props {
    intl: any;
    company: any;
}

const CompanyDetailsRowBottom = ({company}: Props) => {
    return (
        <div className={styles.companyDetailsRowBottom}>
            <div className={styles.column}>
                <span className={styles.headerText}>
                    Adresse
                </span>
                <p></p>
            </div>
            <div className={styles.break}/>
            {/*<div className={styles.column}>*/}
            {/*    <span className={styles.headerText}>Name</span>*/}
            {/*    <p>{}</p>*/}
            {/*</div>*/}
            {/*<div className={styles.column}>*/}
            {/*    <span className={styles.headerText}>Position</span>*/}
            {/*    <p>{}</p>*/}
            {/*</div>*/}
            {/*<div className={styles.column}>*/}
            {/*    <span className={styles.headerText}>Telefonnummer</span>*/}
            {/*    <p>{}</p>*/}
            {/*</div>*/}
            {/*<div className={styles.column}>*/}
            {/*    <span className={styles.headerText}>E-Mail-Adresse</span>*/}
            {/*    <p>{}</p>*/}
            {/*</div>*/}
            {/*<div className={styles.break} >*/}
            {/*    <h2>Kontaktperson</h2>*/}
            {/*</div>*/}
        </div>
    )
}


export default injectIntl(CompanyDetailsRowBottom)
