import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import {
  parseDate,
  prepareCountriesEntitiesDropdownOptions,
} from "../../../utils/react";
import { ProcessingStates } from "../../../models";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import "./FormDeath.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import { Controller, useForm } from "react-hook-form";
import { fetchFormCustomization } from "../../../utils/form";
import {
  buildValidationBasedOnEmployerDates,
  formHasChanged,
  validateInputMessage,
} from "../../../utils/validationTools";
import { EValidationTestType } from "../../../utils/validationConfigs";
import Spinner from "../../Spinner";

interface Props {
  checkFormValidity: any;
  closeModal: any;
  entities?: any;
  initialData: any;
  isLoading: boolean;
  landOptions?: any;
  submitAction: any;
  intl: any;
  employers: any;
  insurantsObj: any;
  location: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
}

const FormDeath = ({
  intl,
  employers,
  showValidations,
  submitAction,
  closeModal,
  isLoading,
  initialData,
}: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      person: {
        ...initialData.person,
        dateOfDeath: initialData.person.dateOfDeath
          ? parseDate(initialData.person.dateOfDeath)
          : "",
        contactCountry: initialData.person.contactCountry
          ? initialData.person.contactCountry.toLowerCase()
          : "",
      },
    },
  });

  const [isContactVisible, setIsContactVisible] = useState(true);

  const landOptions = useSelector((state: RootState) => {
    return prepareCountriesEntitiesDropdownOptions(
      state.app.entities.CountryCodes.values
    );
  });

  const disabled =
    initialData.employee.processingState === ProcessingStates.Committed ||
    initialData.employee.processingState === ProcessingStates.Exported;
  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      "formDeath"
    );
    if (response.data) {
      const employerConfiguration = employers[initialData.employee.employerId];

      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("person")[key])
      );

      // Build custom frontend validation
      formComponentsCustomization = buildValidationBasedOnEmployerDates(
        employerConfiguration,
        formComponentsCustomization,
        "dateOfDeath"
      );

      if (
        Object.keys(formComponentsCustomization)
          .filter((key) => key !== "dateOfDeath")
          .every((key) => formComponentsCustomization[key].hidden)
      ) {
        setIsContactVisible(false);
      }

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            dateOfDeath: {
              required: true,
            },
            contactCountry: {
              required: false,
            },
            contactFirstname: {
              required: false,
            },
            contactName: {
              required: false,
            },
            contactTown: {
              required: false,
            },
            contactAddress: {
              required: false,
            },
            contactZipCode: {
              required: false,
            },
            contactPhoneNumber: {
              required: false,
            },
            contactEmail: {
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          dateOfDeath: !!getValues("person.dateOfDeath"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("person"),
        dateOfDeath: initialData.person.dateOfDeath
          ? parseDate(initialData.person.dateOfDeath)
          : "",
        contactCountry: initialData.person.contactCountry
          ? initialData.person.contactCountry.toLowerCase()
          : "",
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const countryDropdownValue = landOptions
    ? landOptions.find(
        (option) => option.value === getValues("person.contactCountry")
      )
    : null;

  const handleCustomValidation = () => {
    let arr = [
      EValidationTestType.dateIsNotBeforeEntrydate,
      EValidationTestType.dateIsUntilToday,
    ];
    return arr;
  };

  return (
    <>
      {isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            console.log("onsubmit", e);
            e.preventDefault();
          }}
        >
          <fieldset disabled={disabled}>
            <div className="row">
              {/* Todesdatum */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.dateOfDeath"
                  render={({ field: { value } }) => (
                    <Input
                      isFocused
                      type="date"
                      id="dateOfDeath"
                      name="dateOfDeath"
                      placeholder={intl.formatMessage({
                        id: "app.dateOfDeath",
                        defaultMessage: "Todesdatum",
                      })}
                      inputWrapperClass="text-input"
                      maxLength={10}
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue("person.dateOfDeath", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "dateOfDeath",
                            handleCustomValidation,
                            formConfig.requiredFields
                          );
                        setError("person.dateOfDeath", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "dateOfDeath",
                            handleCustomValidation,
                            formConfig.requiredFields
                          );
                        setError("person.dateOfDeath", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.dateOfDeath?.message,
                          type: errors?.person?.dateOfDeath
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.dateofdeath"
                        defaultMessage="Todesdatum"
                      />
                      {formConfig.formCustomization.components["dateOfDeath"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />

              {/* Title */}
              {isContactVisible && (
                <div className="col-12">
                  <div className="form-title">
                    <FormattedMessage
                      id="app.contactperson"
                      defaultMessage="Kontaktperson"
                    />
                  </div>
                </div>
              )}

              {/* Name */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactName"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      hidden={
                        formConfig.formCustomization.components["contactName"]
                          ?.hidden
                      }
                      id="contactName"
                      name="contactName"
                      placeholder=""
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.contactName", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactName",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactName", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactName",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactName", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactName?.message,
                          type: errors?.person?.contactName
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage id="app.name" defaultMessage="Name" />
                      {formConfig.formCustomization.components["contactName"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Vorname */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactFirstname"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id="contactFirstname"
                      hidden={
                        formConfig.formCustomization.components[
                          "contactFirstname"
                        ]?.hidden
                      }
                      name="contactFirstname"
                      placeholder=""
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.contactFirstname", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactFirstname",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactFirstname", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactFirstname",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactFirstname", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactFirstname?.message,
                          type: errors?.person?.contactFirstname
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.firstname"
                        defaultMessage="Vorname"
                      />
                      {formConfig.formCustomization.components[
                        "contactFirstname"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Adresse */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactAddress"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id="contactAddress"
                      name="contactAddress"
                      hidden={
                        formConfig.formCustomization.components[
                          "contactAddress"
                        ]?.hidden
                      }
                      placeholder=""
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.contactAddress", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactAddress",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactAddress", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactAddress",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactAddress", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactAddress?.message,
                          type: errors?.person?.contactAddress
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.address"
                        defaultMessage="Adresse"
                      />
                      {formConfig.formCustomization.components["contactAddress"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />

              {/* Postleitzahl */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactZipCode"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      hidden={
                        formConfig.formCustomization.components[
                          "contactZipCode"
                        ]?.hidden
                      }
                      id="contactZipCode"
                      name="contactZipCode"
                      placeholder=""
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.contactZipCode", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactZipCode",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactZipCode", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactZipCode",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactZipCode", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactZipCode?.message,
                          type: errors?.person?.contactZipCode
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.postalcode"
                        defaultMessage="Postleitzahl"
                      />
                      {formConfig.formCustomization.components["contactZipCode"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Ort */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactTown"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      hidden={
                        formConfig.formCustomization.components["contactTown"]
                          ?.hidden
                      }
                      id="contactTown"
                      name="contactTown"
                      placeholder=""
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.contactTown", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactTown",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactTown", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactTown",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactTown", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactTown?.message,
                          type: errors?.person?.contactTown
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage id="app.city" defaultMessage="Ort" />
                      {formConfig.formCustomization.components["contactTown"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Wohnland */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactCountry"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      isCountriesDropdown
                      hidden={
                        formConfig.formCustomization.components[
                          "contactCountry"
                        ]?.hidden
                      }
                      id="contactCountry"
                      name="contactCountry"
                      inputWrapperClass="text-input"
                      placeholder={intl.formatMessage({
                        id: "app.enterland",
                        defaultMessage: "Land eingeben",
                      })}
                      label="Land"
                      readOnly={disabled}
                      value={
                        countryDropdownValue
                          ? countryDropdownValue["label"]
                          : value || ""
                      }
                      selectOptions={landOptions}
                      onSelected={(name, valueInput) => {
                        setValue("person.contactCountry", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactCountry",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactCountry", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactCountry",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.contactCountry", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactCountry?.message,
                          type: errors?.person?.contactCountry
                            ?.type as ValidationMessageType,
                        },
                      }}
                      sort
                    >
                      <FormattedMessage id="app.land" defaultMessage="Land" />
                      {formConfig.formCustomization.components["contactCountry"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />

              {/* Telefonnummer */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactPhoneNumber"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id="contactPhoneNumber"
                      name="contactPhoneNumber"
                      hidden={
                        formConfig.formCustomization.components[
                          "contactPhoneNumber"
                        ]?.hidden
                      }
                      placeholder=""
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.contactPhoneNumber", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactPhoneNumber",
                            () => [
                              EValidationTestType.isPhoneNumber,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("person.contactPhoneNumber", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactPhoneNumber",
                            () => [
                              EValidationTestType.isPhoneNumber,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("person.contactPhoneNumber", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactPhoneNumber?.message,
                          type: errors?.person?.contactPhoneNumber
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.phonenumber"
                        defaultMessage="Telefonnummer"
                      />
                      {formConfig.formCustomization.components[
                        "contactPhoneNumber"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Email Adresse */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.contactEmail"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id="contactEmail"
                      name="contactEmail"
                      hidden={
                        formConfig.formCustomization.components["contactEmail"]
                          ?.hidden
                      }
                      placeholder=""
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.contactEmail", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactEmail",
                            () => [EValidationTestType.isEmail],
                            formConfig.requiredFields
                          );
                        setError("person.contactEmail", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "contactEmail",
                            () => [EValidationTestType.isEmail],
                            formConfig.requiredFields
                          );
                        setError("person.contactEmail", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.contactEmail?.message,
                          type: errors?.person?.contactEmail
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.email"
                        defaultMessage="Email Adresse"
                      />
                      {formConfig.formCustomization.components["contactEmail"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />

              <div className="col-12 col-sm-6">
                <div className="modal-buttons">
                  <Button
                    onClick={() => closeModal()}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    className="modal-main-button primary width-1"
                    submit={true}
                    isLoading={isLoading}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={
                      isLoading ||
                      isDisabledButton ||
                      !formHasChanged(initialFormToCompare, getValues("person"))
                    }
                    onClick={(e) => {
                      submitAction(e, getValues());
                    }}
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    isLoading: state.app.loading,
    employers: state.app.employers,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FormDeath));
