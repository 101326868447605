import * as React from 'react';
import {Button, ButtonColors, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {FormattedMessage} from "react-intl";
import {percentage} from "../../../../utils/react";

interface Props {
    progressMax: any;
    progressValue: any;
}

interface IState {
}

class CsvImportPopup extends React.Component<Props, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h1>File-Upload</h1>

                <p className="mt-24 mb-48">
                    Bitte warten Sie, bis die Datei vollständig
                    hochgeladen worden ist.
                </p>

                <div className="progressBar">
                    <div className="fill"
                         style={{width: percentage(this.props.progressMax, this.props.progressValue)}} />
                </div>
            </div>
        );
    }
}

export default CsvImportPopup;
