import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPersonBySsn } from "../../../actions/arbeitgeber/insurants";
import { RootState } from "../../../reducers/index";
import * as actions from "../../../actions/";
import { fetchFormCustomization } from "../../../utils/form";
import {
  filteredLanguages,
  isObjEmpty,
  parseToApiDate,
  prepareCountriesEntitiesDropdownOptions,
  prepareEmployersDropdownOptions,
  prepareEntitiesDropdownOptions,
  prepareGendersEntitiesDropdownOptions,
  renderSwitch,
} from "../../../utils/react";
import "./FormNewEntry.scss";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { ValidationFormProps } from "../../../@types/forms/agp/formValidation";
import { useForm } from "react-hook-form";
import formName from "../../../constants/forms/formName.json";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Spinner from "../../Spinner";

interface Props {
  insurantsObj: any;

  // use because we need to change all steps logic
  goToNextStep: any;
  goToPreviousStep: any;
  step: number;
  wiedereintritt?: boolean;
  entities?: any;
  getPersonBySsn: any;
  closeModal: any;
  employers: any;
  initialData: any;
  isLoading: boolean;
  submitAction: any;
  intl: any;
  history: any;
  location: any;
  showValidations: boolean;
  fetchEmployersInsurancePlans: (employerId: string, isSelect?: boolean) => any;
}

let isMarriageDateRequired = null;
let isDivorceDateRequired = null;

const FormNewEntry = (props: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });

  const [isDirty, setIsDirty] = useState(false);

  const { setValue, getValues } = useForm({
    criteriaMode: "all",
    defaultValues: {
      eintrittErfassenForm: {
        ...props.initialData,
        person: {
          ...props.initialData.person,
          birthDate: props.initialData.person?.birthDate
            ? parseToApiDate(props.initialData.person.birthDate)
            : "",
        },
        employee: {
          ...props.initialData.employee,
          entryDate: props.initialData.employee.entryDate
            ? parseToApiDate(props.initialData.employee.entryDate)
            : "",
          employerId: props.initialData.employee.employerId
            ? props.initialData.employee.employerId
            : "",
        },
        address: {
          ...props.initialData.address,
          validFrom:
            props.initialData.address && props.initialData.address.validFrom
              ? parseToApiDate(props.initialData.address.validFrom)
              : "",
          country:
            props.initialData.address && props.initialData.address.country
              ? props.initialData.address.country.toLowerCase()
              : "",
        },
        payroll: {
          ...props.initialData.payroll,
          validFrom: props.initialData.payroll.validFrom
            ? parseToApiDate(props.initialData.payroll.validFrom)
            : "",
        },
      },
    },
  });

  const firmaOptions = useSelector((state: RootState) => {
    return prepareEmployersDropdownOptions(state.app.employers);
  });

  const landOptions = useSelector((state: RootState) => {
    return prepareCountriesEntitiesDropdownOptions(
      !isObjEmpty(state.app.entities.CountryCodes)
        ? state.app.entities.CountryCodes.values
        : {}
    );
  });

  const sexOptions = useSelector((state: RootState) => {
    return prepareGendersEntitiesDropdownOptions(
      !isObjEmpty(state.app.entities.SexCodes)
        ? state.app.entities.SexCodes.values
        : {}
    );
  });

  const civilStatusOptions = useSelector((state: RootState) => {
    return prepareEntitiesDropdownOptions(
      !isObjEmpty(state.app.entities.CivilStatus)
        ? state.app.entities.CivilStatus.values
        : {}
    );
  });

  const languageOptions = useSelector((state: RootState) => {
    return prepareEntitiesDropdownOptions(
      !isObjEmpty(state.app.entities.LanguageCodes)
        ? filteredLanguages(state.app.entities.LanguageCodes.values)
        : {}
    );
  });

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const submitForm = (e) => {
    props.submitAction(e, getValues("eintrittErfassenForm"));
  };

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.formNewEntry"]
    );
    if (response.data) {
      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("eintrittErfassenForm")[key])
      );

      isMarriageDateRequired =
        formComponentsCustomization["marriageDate"]?.required;
      isDivorceDateRequired =
        formComponentsCustomization["divorceDate"]?.required;

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
        requiredFields,
      });
    } else {
      isMarriageDateRequired = false;
      isDivorceDateRequired = false;
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            // create standard validations to form TODO
            name: {
              required: true,
            },
            firstname: {
              required: true,
            },
            sex: {
              required: true,
            },
            socialSecurityNumber: {
              required: true,
            },
            civilStatus: {
              required: true,
            },
            language: {
              required: false,
            },
            extEmployeeId: {
              required: false,
            },
            insurancePlan: {
              required: false,
            },
            costCenter: {
              required: false,
            },
            grossSalary: {
              required: true,
            },
            activityRate: {
              required: true,
            },
            payrollNote: {
              required: false,
            },
            address1: {
              required: true,
            },
            address2: {
              required: false,
            },
            zipCode: {
              required: true,
            },
            city: {
              required: true,
            },
            country: {
              required: true,
            },
            addressValidFrom: {
              required: false,
              hidden: true,
              date: {
                dayFilter: 0,
              },
            },
            email: {
              required: false,
            },
            mobileNumber: {
              required: false,
            },
            phoneNumber: {
              required: false,
            },
            employerId: {
              required: true,
            },
            validFrom: {
              required: true,
              date: {
                dayFilter: 0,
              },
            },
            marriageDate: {
              required: false,
              date: {
                dayFilter: 0,
              },
            },
            divorceDate: {
              required: false,
              date: {
                dayFilter: 0,
              },
              interval: {
                fieldToCompare: "entryDate",
                intervalType: 5,
                minIntervalValue: 0,
                maxIntervalValue: 0,
                minBegin: 1,
                maxEnd: 0,
              },
            },
            entryDate: {
              required: true,
              date: {
                dayFilter: 0,
              },
            },
            birthDate: {
              required: true,
              date: {
                dayFilter: 0,
              },
            },
          },
          messages: {},
        },
        requiredFields: {},
      });
    }
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editingEintrittMutationOrWiedereintritt =
    props.wiedereintritt || !props.location.search.includes("edit=&");

  const fetchPersonData = async (ssn) => {
    if (!editingEintrittMutationOrWiedereintritt) {
      const response = await getPersonBySsn(ssn);

      if (response && (response as any).data) {
        const personData = (response as any).data.attributes;
        const newPersonData = {};
        const keys = Object.keys(personData).map((key) => {
          return key.charAt(0).toLowerCase() + key.slice(1);
        });
        keys.forEach((key) => {
          newPersonData[key] =
            personData[key.charAt(0).toUpperCase() + key.slice(1)];
        });
        // Adjust form
        setValue("eintrittErfassenForm", {
          person: {
            ...getValues("eintrittErfassenForm.person"),
            name: personData.name,
            firstname: personData.firstname,
            birthDate: personData.birthDate,
            civilStatus: personData.civilStatus,
            marriageDate: personData.marriageDate,
            divorceDate: personData.divorceDate,
            sex: personData.sex,
            language: personData.language,
          },
          address: {
            ...personData.address,
            country:
              personData.address && personData.address.country
                ? personData.address.country.toLowerCase()
                : "",
          },
        });
        setIsDirty(true);
        // Adjust child form
        return getValues("eintrittErfassenForm.person");
      }
    }
  };

  const step1Props = {
    formConfig,
    goToNextStep: props.goToNextStep,
    getValues,
    isDirty,
    civilStatusOptions: civilStatusOptions.filter((cs) => cs.value !== "0"),
    languageOptions,
    sexOptions,
    closeModal: props.closeModal,
    setValue: setValue.bind(this),
    fetchPersonData: fetchPersonData.bind(this),
    setFormConfig: setFormConfig.bind(this),
    isMarriageDateRequired,
    isDivorceDateRequired,
  };

  const step2Props = {
    intl: props.intl,
    employers: props.employers,
    insurantsObj: props.insurantsObj,
    fetchEmployersInsurancePlans: props.fetchEmployersInsurancePlans,
    formConfig,
    firmaOptions,
    setFormConfig,
    showValidations: props.showValidations,
    getValues,
    goToPreviousStep: props.goToPreviousStep,
    goToNextStep: props.goToNextStep,
    setValue,
  };

  const step3Props = {
    formConfig,
    setFormConfig,
    getValues,
    goToNextStep: props.goToNextStep,
    goToPreviousStep: props.goToPreviousStep,
    setValue: setValue.bind(this),
  };

  const step4Props = {
    formConfig,
    isDirty,
    setFormConfig,
    getValues,
    landOptions,
    goToNextStep: props.goToNextStep,
    goToPreviousStep: props.goToPreviousStep,
    setValue: setValue.bind(this),
    submitForm: submitForm.bind(this),
  };

  return (
    <>
      {props.isLoading ? (
        <Spinner relative />
      ) : (
        renderSwitch(props.step, {
          1: () => <Step1 {...step1Props} />,
          2: () => <Step2 {...step2Props} />,
          3: () => <Step3 {...step3Props} />,
          4: () => <Step4 {...step4Props} />,
        })
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    getPersonBySsn: bindActionCreators(getPersonBySsn as any, dispatch),
    fetchEmployersInsurancePlans: bindActionCreators(
      actions.fetchEmployersInsurancePlans,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(FormNewEntry))
);
