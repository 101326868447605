import {wrap} from "../index";
import apiCaller from "../../utils/api";
import * as constants from "../../constants";
import api from "../../utils/api";

export const fetchTranslations = () => {
    return async (dispatch) => {
        return api.get(`translations?language=German`)
            .then((response: any) => {
                dispatch({ "type": constants.FETCHED_TRANSLATIONS, "payload": response.data?.attributes?.translations });
            }).catch(err => err);
    }
};
