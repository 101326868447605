import React from "react";
import FormFile from "../../../Forms/FormFile";

interface UploadModalContentProps {
    loading;
    firmaOptions;
    documentTypeOptions;
    validationMessageConfig;
    checkFormValidity;
    submitAction;
    close;
}
export default function UploadModalContent({
    loading,
    firmaOptions,
    documentTypeOptions,
    validationMessageConfig,
    checkFormValidity,
    submitAction,
    close,
}: UploadModalContentProps) {
    return (
        <div className="modal-content form">
            <FormFile
                loading={loading}
                firmaOptions={firmaOptions}
                documentTypeOptions={documentTypeOptions}
                validationMessageConfig={validationMessageConfig}
                checkFormValidity={checkFormValidity}
                closeModal={() => close()}
                submitAction={(e, formObj, formInputs) =>
                    submitAction(e, formObj)
                }
            />
        </div>
    );
}
