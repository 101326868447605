import React from 'react';
import {connect} from 'react-redux';
import memoize from "memoize-one";
import {injectIntl} from 'react-intl';
import {bindActionCreators, Dispatch} from 'redux';
import {RootState} from '../../../reducers/index';
import Modal from 'react-modal';
import * as actions from '../../../actions/';
import Header from "../../../components/Header";
import classNames from "classnames";
import Drawer from "../../../components/Drawer";
import {getParameterByName} from "../../../utils/toSearchParams";
import './Insurances.scss';
import Sidebar from "../../../components/Pensionskasse/Versicherte/Sidebar";
import InsurantModal from "../../../components/Pensionskasse/Modal/InsurantModal";
import PersonenList from "../../../components/Pensionskasse/Versicherte/PersonenList";
import mutations from "../../../constants/DummyData/mutations.json";
import Spinner from "../../../components/Spinner";
import {
    createInsurant,
    editInsurant,
    fetchEmployees,
    setInsurantsPageNo
} from "../../../actions/pensionskasse/employee";
import {fetchInsurantById} from "../../../actions/arbeitgeber/insurants";
import {minimumInsurantPayload} from "../../../constants/payloads";
import ModalContent from "../../../components/Arbeitgeber/Versicherte/ModalContent";
import {setLoading, showValidations} from "../../../actions/";
import {defined} from "../../../utils/variableEvaluation";
import {EMPTY_VALIDATION_MESSAGE_CONFIG, EValidationMessageType} from "../../../components/ValidationMessage";
import {EValidationTestType, VALIDATION_MESSAGE} from "../../../utils/validationConfigs";
import {
    insurantMutationTypesToFormsMap,
    mutationFormTypes,
    mutationToFormMap,
    mutationTypesToMessageMap,
    modalPageTypes
} from "../../../constants";
import {emptyStringsToNull, formatNumber, isObjEmpty, parseDate, parseToApiDate} from "../../../utils/react";
import Comparator from "../../../utils/sorting";
import {ProcessingStates} from "../../../models";
import {
    prepareBackendValidationObject,
    prepareCreateAddressPayload,
    prepareCreateNewInsurantPayload,
    prepareCreatePayrollPayload,
    prepareCreateUnpaidvacationPayload, preparePatchInsurantPayload,
    prepareUpdateAddressPayload,
    prepareUpdatePayrollPayload,
    prepareUpdateUnpaidvacationPayload
} from "../../Insurances/utils";
import {INSURANCES_TABS} from "../../../constants/pensionskasse";
import {objectFlip} from "../../../utils/array";
import {createAddress, deleteAddress, fetchAddresses, patchAddress} from "../../../actions/pensionskasse/address";
import {createPayroll, deletePayroll, fetchPayrolls, patchPayroll} from "../../../actions/pensionskasse/payroll";
import {
    createUnpaidVacation,
    deleteUnpaidVacation, fetchUnpaidVacations,
    patchUnpaidVacation
} from "../../../actions/pensionskasse/unpaidvacation";
import {toastr} from 'react-redux-toastr';
import {fetchInsurantMutations} from "../../../actions/pensionskasse/mutations";

interface State {
    filtersChanged: number;
    filtersOpened: boolean;
    modalIsOpen: boolean;
    insurantData: any;
    modalMutations: any;
    modalFadeOut: boolean;
    expandedRow: any;
    modalContentId: string;
    loading: boolean;
    insurantDataLoaded: boolean;
    backendValidation: any;
    newEntryStep: number;
    eintrittErfassenForm: any;
    modalMessage: string;
    modalContent: string;
    provider: string;
    pdfFileId: string;
    newPayroll: any;
    newAddress: any;
    newUnpaidVacation: any;
    selectedAddress: any;
    selectedPayroll: any;
    selectedUnpaidVacation: any;
}

interface Props {
    match: any;
    intl: any;
    location: any;
    history: any;
    employers: any;
    insurants: any;
    moreInsurants: any;
    mutationTypes: any;
    fetchEmployees: any;
    insurantsPageNo: any;
    insurantsFetched: boolean
    setInsurantsPageNo: any;
    fetchInsurantById: any;
    showValidations: any;
    addresses: any;
    payrolls: any;
    unpaidVacations: any;
    fetchAddresses: any;
    fetchPayrolls: any;
    fetchUnpaidVacations: any;
    fetchInsurantMutations: any;
    createAddress: any;
    patchAddress: any;
    deleteAddress: any;
    createPayroll: any;
    patchPayroll: any;
    deletePayroll: any;
    createUnpaidVacation: any;
    patchUnpaidVacation: any;
    deleteUnpaidVacation: any;
    createInsurant: any;
    editInsurant: any;
    setLoading: any;

}
Modal.setAppElement('#root');
const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || '20';

class Insurances extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        let modalContent = '';

        if (this.props.location.search && this.props.location.search.length > 0) {
            // modalContentId = getParameterByName('edit', this.props.location.search);
            modalContent = getParameterByName('content', this.props.location.search);
            // fileId = getParameterByName('fileId', this.props.location.search);
        }

        this.state = {
            filtersChanged: 0,
            expandedRow: null,
            modalIsOpen: false,
            filtersOpened: window.innerWidth >= 1400,
            modalMutations: [],
            insurantData: {},
            eintrittErfassenForm: {
                person: {},
                address: {},
                payroll: {},
                employee: {},
                contact: {}
            },
            insurantDataLoaded: false,
            modalFadeOut: false,
            modalContentId: "",
            loading: false,
            backendValidation: {},
            newEntryStep: 1,
            modalMessage: "",
            modalContent: modalContent,
            provider: "",
            pdfFileId: "",
            newPayroll: {},
            newAddress: {},
            newUnpaidVacation: {},
            selectedAddress: {},
            selectedPayroll: {},
            selectedUnpaidVacation: {},
        }
    }

    private drawer = null;
    private scrollableContainer = null;

    componentDidMount() {
        this.fetchData();
        let id = getParameterByName('edit', this.props.location.search);
        let addressId = getParameterByName('addressId', this.props.location.search);
        let payrollId = getParameterByName('payrollId', this.props.location.search);
        let unpaidVacationId = getParameterByName('unpaidVacationId', this.props.location.search);
        if (id) {
            this.props.fetchInsurantById(id).then((res) => {
                this.setState({
                    insurantData: res['data']['attributes'],
                    // eintrittErfassenForm: {...minimumInsurantPayload.data.attributes},
                    insurantDataLoaded: true
                });
                this.props.fetchInsurantMutations(id);
                this.props.fetchAddresses(id).then(res => {
                    if (addressId) {
                        this.setState({selectedAddress: this.props.addresses[addressId]});
                    } else {
                        this.setState({selectedAddress: this.state.insurantData.address});
                    }
                });
                this.props.fetchPayrolls(id).then(res => {
                    if (payrollId) {
                        this.setState({selectedPayroll: this.props.payrolls[payrollId]});
                    } else {
                        this.setState({selectedPayroll: this.state.insurantData.payroll});
                    }
                });
                this.props.fetchUnpaidVacations(id).then(res => {
                    if (unpaidVacationId) {
                        this.setState({selectedUnpaidVacation: this.props.unpaidVacations[unpaidVacationId]});
                    } else {
                        this.setState({selectedUnpaidVacation: this.state.insurantData.unpaidVacation});
                    }
                });
            });
        }
        window.addEventListener("resize", this.viewPortChanged);
        // window.addEventListener("scroll", this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.viewPortChanged);
        // window.removeEventListener("scroll", this.handleScroll);
    }

    static getDerivedStateFromProps(props, state) {
        // Update state so the next render will show the fallback UI.
        // console.log("getDerivedStateFromProps", props.location.search, state.eintrittErfassenForm, props.match.params.tab);
        if (state.modalIsOpen && props.location.search === '') {
            // Will hide modal as search is empty
            return {
                modalIsOpen: false,
            };

        } else {

            let modalContentId = getParameterByName('edit', props.location.search);
            let modalContent = getParameterByName('content', props.location.search);
            let pdfFileId = getParameterByName('fileId', props.location.search);
            let modalIsOpen = !!(modalContentId || modalContent);
            let filtersChanged = state.filtersChanged + 1;
            let provider = getParameterByName('provider', props.location.search);

            return {
                modalContentId: modalContentId,
                modalContent: modalContent,
                modalIsOpen: modalIsOpen,
                pdfFileId: pdfFileId,
                filtersChanged: filtersChanged,
                provider: provider
            }
        }
    };

    fetchData = () => {
        if(!this.props.insurantsFetched) {
            this.props.fetchEmployees()
                .then(res => this.props.setInsurantsPageNo(1));
        }
    }

    viewPortChanged = (e) => {
        setTimeout(() => {
            if (e.target.innerWidth >= 1400 && (this.state.filtersOpened === false)) {
                this.setState({filtersOpened: true});
            }
        }, 500);
    }

    applyBackendValidation = (formObjName, errors) => {
        let backendValidation = prepareBackendValidationObject(formObjName, this.state.backendValidation);
        errors.forEach(error => {
            if (error?.source?.pointer) {
                const pointer = error.source.pointer;
                const properties = pointer.split("/");
                const model = properties[properties.length - 2];
                const input = properties[properties.length - 1];
                backendValidation[formObjName][model][input] = {
                    message: error.detail || error.title,
                    type: EValidationMessageType.Error
                }
            }
        })
        this.setState({backendValidation: backendValidation});
    }

    removeBackendValidationMessage = (formName, modelName, inputName) => {
        console.log(formName, modelName, inputName);
        const backendValidation = {...this.state.backendValidation};
        if(backendValidation[formName] && backendValidation[formName][modelName] && backendValidation[formName][modelName][inputName]) {
            delete backendValidation[formName][modelName][inputName];
            this.setState({backendValidation: backendValidation});
        }
    }

    getAddressButtonsContent = (address) => {
        return this.state.insurantData.employee.processingState === ProcessingStates.Committed || this.state.insurantData.employee.processingState === ProcessingStates.Exported ? [] : [{
            'name': 'Edit',
            'action': () => this.handleAddressEdit(address),
            'showIcon': true,
            'iconType': 'edit'
        }, {
            'name': 'Delete',
            'action': () => this.handleAddressDelete(address),
            'showIcon': true,
            'iconType': 'delete'
        }];
    }

    getPayrollButtonsContent = (payroll) => {
        return this.state.insurantData.employee.processingState === ProcessingStates.Committed || this.state.insurantData.employee.processingState === ProcessingStates.Exported ? [] : [{
            'name': 'Edit',
            'action': () => this.handlePayrollEdit(payroll),
            'showIcon': true,
            'iconType': 'edit'
        }, {
            'name': 'Delete',
            'action': () => this.handlePayrollDelete(payroll),
            'showIcon': true,
            'iconType': 'delete'
        }];
    }

    getUnpaidVacationButtonsContent = (unpaidVacation) => {
        return this.state.insurantData.employee.processingState === ProcessingStates.Committed || this.state.insurantData.employee.processingState === ProcessingStates.Exported ? [] : [{
            'name': 'Edit',
            'action': () => this.handleUnpaidVacationEdit(unpaidVacation),
            'showIcon': true,
            'iconType': 'edit'
        }, {
            'name': 'Delete',
            'action': () => this.handleUnpaidVacationDelete(unpaidVacation),
            'showIcon': true,
            'iconType': 'delete'
        }];
    }

    preparePayrollData = (payrollData) => {
        const preparedPayrollData = [];
        Object.keys(payrollData).forEach((payrollId) => {
            payrollData[payrollId]['actions'] = this.getPayrollButtonsContent(payrollData[payrollId]);
            payrollData[payrollId]['id'] = payrollId;
            preparedPayrollData.push(payrollData[payrollId]);
        });
        preparedPayrollData.forEach((payrollData) => {
            payrollData.validFrom = payrollData.validFrom ? parseDate(payrollData.validFrom.slice(0, 10)) : '';
            payrollData.validUntil = payrollData.validUntil ? parseDate(payrollData.validUntil.slice(0, 10)) : '';
            payrollData.grossSalary = payrollData.grossSalary ? formatNumber(payrollData.grossSalary) : '';

        });
        const sorted = preparedPayrollData.sort((a, b) => {
            return Comparator(new Date(parseToApiDate(a.validFrom)), new Date(parseToApiDate(b.validFrom)), -1, false)
        })
        return sorted;
    }

    prepareAddressData = (addressData) => {
        const preparedAddressData = [];
        Object.keys(addressData).forEach((addressId) => {
            addressData[addressId]['actions'] = this.getAddressButtonsContent(addressData[addressId]);
            addressData[addressId]['id'] = addressId;
            preparedAddressData.push(addressData[addressId]);
        });
        preparedAddressData.forEach((addressData) => {
            addressData.validFrom = (addressData.validFrom) ? parseDate(addressData.validFrom.slice(0, 10)) : '';
            addressData.validUntil = (addressData.validUntil) ? parseDate(addressData.validUntil.slice(0, 10)) : '';
        })
        const sorted = preparedAddressData.sort((a, b) => {
            return Comparator(new Date(parseToApiDate(a.validFrom)), new Date(parseToApiDate(b.validFrom)), -1, false)
        })
        return sorted;
    }

    prepareUnpaidVacationData = (unpaidVacationData) => {
        const preparedUnpaidVacationData = [];
        Object.keys(unpaidVacationData).forEach((unpaidVacationId) => {
            unpaidVacationData[unpaidVacationId]['actions'] = this.getUnpaidVacationButtonsContent(unpaidVacationData[unpaidVacationId]);
            unpaidVacationData[unpaidVacationId]['id'] = unpaidVacationId;
            preparedUnpaidVacationData.push(unpaidVacationData[unpaidVacationId]);
        });
        preparedUnpaidVacationData.forEach((unpaidVacationData) => {
            unpaidVacationData.validFrom = (unpaidVacationData.validFrom) ? parseDate(unpaidVacationData.validFrom.slice(0, 10)) : '';
            unpaidVacationData.validUntil = (unpaidVacationData.validUntil) ? parseDate(unpaidVacationData.validUntil.slice(0, 10)) : '';
        })
        return preparedUnpaidVacationData;
    }

    loadMore = () => {
            this.setState({loading: true});
            this.props.fetchEmployees().then(res => {
                this.setState({loading: false});
                this.props.setInsurantsPageNo(this.props.insurantsPageNo + 1);
            });
    }

    handleScroll = e => {
        const element = e.target;
        const { loading } = this.state;
        const bottom = Math.floor(element.scrollHeight - element.scrollTop) <= (element.clientHeight + 200) && Math.floor(element.scrollHeight - element.scrollTop) >= (element.clientHeight);
        if (bottom && !loading) {
            this.loadMore();
        }
    }

    openModal = async(id, content, message?) => {
        console.log("openModal", id, content);
        const {insurants} = this.props;

        let entity: any = {};
        let insurant: any = {};
        let address: any = {};
        let payroll: any = {};
        let unpaidVacation: any = {};
        let eintritt: any = {};

        eintritt = minimumInsurantPayload.data.attributes;
        if(id) {
            entity = insurants.find(insurant => insurant.id == id);
            if(!entity) {
                await this.props.fetchInsurantById(id).then(res => {
                    entity = res['data'];
                })
            }
            insurant = entity.attributes;
            address = entity.attributes.address;
            payroll = entity.attributes.payroll;
            unpaidVacation = entity.attributes.unpaidVacation;

            this.props.fetchAddresses(id);
            this.props.fetchPayrolls(id);
            this.props.fetchUnpaidVacations(id);
            if(content == modalPageTypes.INSURANT_DETAILS_PK) {
                this.props.fetchInsurantMutations(id);
            }
        }
        if((id && !isObjEmpty(entity)) || !id) {
            this.setState({
                modalMessage: message,
                insurantData: insurant,
                selectedAddress: address,
                selectedPayroll: payroll,
                selectedUnpaidVacation: unpaidVacation,
                eintrittErfassenForm: eintritt,
                insurantDataLoaded: !!id
            }, () => {
                this.props.history.push({
                    pathname: `/pensionskasse/versicherte/personen`,
                    search: "?" + new URLSearchParams({edit: id || "", content: content || ""}).toString()
                })
            });
        }
    }

    openPdfModal = (content, fileId, provider) => {
        console.log("openPdfModal", fileId, content);
        this.setState({modalIsOpen: true, modalContent: content, pdfFileId: fileId, provider: provider});
        this.props.history.push({
            pathname: `/pensionskasse/versicherte/personen`,
            search: "?" + new URLSearchParams({fileId, content, provider}).toString()
        })
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
            insurantDataLoaded: false,
            backendValidation: {},
            newEntryStep: 1,
        });
        const origin = getParameterByName('origin', this.props.location.search);
        this.props.history.push({pathname: origin ? origin : `/pensionskasse/versicherte/personen`, search: ''});
    }

    handleRowChanged = (index) => {
        this.setState({expandedRow: index});
    }

    handleAddressCreate = (e, formObj, formInputs) => {
        e.preventDefault();
        const insurantId = this.state.modalContentId;
        const addressData = prepareCreateAddressPayload(insurantId, formObj.address);
        this.props.createAddress(insurantId, emptyStringsToNull(addressData)).then(res => {
            this.setState({newAddress: {}});
            if (res.errors) {
                this.applyBackendValidation("newAddress", res.errors);
            } else {
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap["Adressaenderung"]);
            }
            this.props.setLoading(false)

        }).then(res => {
            this.refreshData();
        });
    }

    handleAddressEdit(address) {
        this.setState({
            selectedAddress: {...address},
            modalIsOpen: true,
            modalContent: 'Adresse bearbeiten',
            modalContentId: this.state.modalContentId,
            modalMessage: ''
        }, () => {
            this.props.history.push({
                pathname: `/pensionskasse/versicherte/personen`,
                search: "?" + new URLSearchParams({
                    edit: this.state.modalContentId,
                    content: 'Adresse bearbeiten'
                }).toString() + '&addressId=' + address.id
            })
        });
    }

    handleAddressUpdate = (e, formObj, formInputs) => {
        const payload = prepareUpdateAddressPayload(formObj.address);
        this.props.patchAddress(this.state.modalContentId, payload.data.id, payload).then(res => {
            if (res.errors) {
                this.applyBackendValidation("newAddress", res.errors);
            } else {
                // this needs a translation
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap["Adresskorrektur"]);
                this.props.setLoading(false)
                this.refreshData();
            }
        });
    }

    handleAddressDelete(address) {
        const insurantId = this.state.modalContentId;
        this.props.deleteAddress(insurantId, address.id).then(res => {
            if (!res.errors) {
                toastr.success("Address", "Address has been deleted");
                this.props.fetchAddresses(insurantId);
                this.refreshData();
            }
        })
    }

    handlePayrollCreate = (e, formObj, formInputs) => {
        const payload = prepareCreatePayrollPayload(formObj.payroll);
        this.props.createPayroll(this.state.modalContentId, payload).then(res => {
            this.setState({newPayroll: {}});
            console.log("RESSSSS", res);
            if (res.errors) {
                this.applyBackendValidation("newPayroll", res.errors);
            } else {
                // this needs a translation
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap['Lohnaenderung']);
                this.refreshData();
            }
            this.props.setLoading(false)
        })
    }

    handlePayrollUpdate = (e, formObj, formInputs) => {
        const payload = prepareUpdatePayrollPayload(formObj.payroll);
        this.props.patchPayroll(this.state.modalContentId, payload.data.id, payload).then((res) => {
            this.props.setLoading(false)
            if (res.errors) {
                this.applyBackendValidation("newPayroll", res.errors);
            } else {
                // this needs a translation
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap["Lohnkorrektur"]);
                this.refreshData();
            }
        });
    }

    handlePayrollEdit(payroll) {
        this.setState({
            selectedPayroll: payroll,
            modalIsOpen: true,
            modalContent: 'Lohn bearbeiten',
            modalContentId: this.state.modalContentId,
            modalMessage: ''
        }, () => {
            // this.openModal(this.state.modalContentId, 'Lohn bearbeiten');
            this.props.history.push({
                pathname: `/pensionskasse/versicherte/personen`,
                search: "?" + new URLSearchParams({
                    edit: this.state.modalContentId,
                    content: 'Lohn bearbeiten'
                }).toString() + '&payrollId=' + payroll.id
            })
        });
    }

    handlePayrollDelete(payroll) {
        this.props.deletePayroll(this.state.modalContentId, payroll.id).then(res => {
            if (!res.errors) {
                toastr.success("Payroll", "Payroll has been deleted");
                this.props.fetchPayrolls(this.state.modalContentId);
                this.refreshData();
            }
        });
    }

    handleUnpaidVacationEdit(unpaidVacation) {
        this.setState({
            selectedUnpaidVacation: unpaidVacation,
            modalIsOpen: true,
            modalContent: 'Unbezahlter Urlaub bearbeiten',
            modalContentId: this.state.modalContentId,
            modalMessage: ''
        }, () => {
            // this.openModal(this.state.modalContentId, 'Lohn bearbeiten');
            this.props.history.push({
                pathname: `/pensionskasse/versicherte/personen`,
                search: "?" + new URLSearchParams({
                    edit: this.state.modalContentId,
                    content: 'Unbezahlter Urlaub bearbeiten'
                }).toString() + '&unpaidVacationId=' + unpaidVacation.id
            })
        });
    }

    handleUnpaidVacationCreate = (e, formObj, formInputs) => {
        const payload = prepareCreateUnpaidvacationPayload(formObj.unpaidVacation);
        this.props.createUnpaidVacation(this.state.modalContentId, payload).then(res => {
            if (res.errors) {
                this.applyBackendValidation("newUnpaidVacation", res.errors);
            } else {
                this.setState({newUnpaidVacation: {}});
                // this needs a translation
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap['UnbezahlterUrlaub']);
                this.refreshData();
            }
            this.props.setLoading(false)

        });
    }

    handleUnpaidVacationUpdate = (e, formObj, formInputs) => {
        const payload = prepareUpdateUnpaidvacationPayload(formObj.unpaidVacation);
        this.props.patchUnpaidVacation(this.state.modalContentId, payload.data.id, payload).then((res) => {
            if (res.errors) {
                this.applyBackendValidation("newUnpaidVacation", res.errors);
            } else {
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap["UnbezahlterUrlaubKorrektur"]);
                this.refreshData();
            }
            this.props.setLoading(false)

        });
    }

    handleUnpaidVacationDelete(unpaidVacation) {
        this.props.deleteUnpaidVacation(this.state.modalContentId, unpaidVacation.id).then(res => {
            if (!res.errors) {
                toastr.success("Unpaid Vacation", "Unpaid vacation has been deleted");
                this.props.fetchUnpaidVacations(this.state.modalContentId);
                this.refreshData();
            }
        });
    }

    createNewInsurant = (e, formObj, formInputs) => {
        const payload = prepareCreateNewInsurantPayload(formObj);
        this.props.createInsurant(payload).then(res => {
            if (res.errors) {
                this.applyBackendValidation("eintrittErfassenForm", res.errors);
            } else {
                this.setState({
                    eintrittErfassenForm: {
                        person: {},
                        address: {},
                        payroll: {},
                        employee: {},
                    },
                    newEntryStep: 1
                });
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap["Eintritt"]);
                this.refreshData();
                this.props.setLoading(false);
            }
        })
    };

    patchInsurant = (e, formObj, formInputs, forcedMutationType?) => {
        const formToMutationMap = objectFlip(insurantMutationTypesToFormsMap);
        const mutationType = forcedMutationType || formToMutationMap[this.state.modalContent];
        const payload = preparePatchInsurantPayload(formObj);
        payload.meta.mutationType = mutationType;
        payload.data.attributes = JSON.parse(JSON.stringify(formObj));
        payload.data.id = this.state.modalContentId;

        this.props.editInsurant(payload, this.state.modalContentId).then((res) => {
            if (res.errors) {
                this.applyBackendValidation("insurantDataPatch", res.errors);
            } else {
                // this needs a translation
                this.openModal(this.state.modalContentId, 'success', mutationTypesToMessageMap[mutationType]);
                this.refreshData();
                this.props.setLoading(false);
            }
        });

    }

    refreshData = () => {
        this.props.fetchEmployees(0, this.props.insurantsPageNo * parseInt(paginationSize));
    }

    prepareFirmaOptions = memoize((employers) => {
        let options = [];
        if (employers && Object.keys(employers).length) {
            Object.keys(employers).forEach((employerKey) => {
                options.push({
                    'dropdownLabel': employers[employerKey]['attributes']['name'],
                    'id': employers[employerKey].id
                })
            });
        }
        return options;
    });

    prepareMutationTypeOptions = memoize((mutationTypes) => {
        const options = [];
        if (mutationTypes && Object.keys(mutationTypes).length) {
            Object.keys(mutationTypes).forEach((mutationTypeKey) => {
                options.push({
                    'dropdownLabel': mutationTypes[mutationTypeKey]['attributes']['translation'],
                    'id': (mutationTypes[mutationTypeKey].id)
                })
            });
        }
        return options;
    });

    handleSetLoading = (value) => {
        this.setState({ loading: value, filtersChanged: this.state.filtersChanged + 1 });
    }

    onFormSubmit = (e, formObj, formInputs, formType) => {
        console.log(formObj, formInputs, formType);
        e.preventDefault();
        const {modalContent} = this.state;
        if(modalContent == mutationFormTypes.NewAddress) {
            this.handleAddressCreate(e, formObj, formInputs);
        } else if(modalContent == mutationFormTypes.EditAddress) {
            this.handleAddressUpdate(e, formObj, formInputs);
        } else if(modalContent == mutationFormTypes.NewPayroll) {
            this.handlePayrollCreate(e, formObj, formInputs);
        } else if(modalContent == mutationFormTypes.NewPayroll) {
            this.handlePayrollUpdate(e, formObj, formInputs);
        } else if(modalContent == mutationFormTypes.NewUnpaidVacation) {
            this.handleUnpaidVacationCreate(e, formObj, formInputs);
        } else if(modalContent == mutationFormTypes.EditUnpaidVacation) {
            this.handleUnpaidVacationUpdate(e, formObj, formInputs);
        } else if(modalContent == mutationFormTypes.NewEntry) {
            this.createNewInsurant(e, formObj, formInputs);
        } else {
            this.patchInsurant(e, formObj, formInputs);
        }
    }

    render() {
        const firmaOptions = this.prepareFirmaOptions(this.props.employers);
        const mutationTypeOptions = this.prepareMutationTypeOptions(this.props.mutationTypes);
        const isLoading = !this.props.insurantsFetched || this.state.loading;
        const {insurantDataLoaded} = this.state;
        return (
            <div className="main-container pensionskasse">

                <Header title="Versicherte"
                        locales={null}
                        onFiltersButtonClick={() => this.setState({filtersOpened: !this.state.filtersOpened})}/>

                <Modal isOpen={this.state.modalIsOpen}
                       onRequestClose={this.closeModal}
                       contentLabel="Large Modal"
                       className={classNames("large-modal", {"modal-fade-out": this.state.modalFadeOut})}
                       overlayClassName={"large-modal-overlay"}>

                    <ModalContent
                        onClose={this.closeModal}
                        backendValidation={this.state.backendValidation}
                        removeBackendValidation={this.removeBackendValidationMessage}
                        onPdfClick={(id) => this.openPdfModal("pdf", id, "")}
                        onEditClick={(type) => this.openModal(this.state.modalContentId, type)}
                        onFormSubmit={(e, formObj, formInputs, formType) => this.onFormSubmit(e, formObj, formInputs, formType)}

                        entryFormStep={this.state.newEntryStep}
                        resetEntryForm={() => this.setState({ newEntryStep: 1 })}
                        nextEntryFormStep={() => this.setState({ newEntryStep: this.state.newEntryStep + 1 })}
                        previousEntryFormStep={() => this.setState({ newEntryStep: this.state.newEntryStep - 1 })}

                        insurantData={this.state.insurantData}
                        insurantDataLoaded={this.state.insurantDataLoaded}
                        eintrittErfassenForm={this.state.eintrittErfassenForm}

                        modalMessage={this.state.modalMessage}
                        modalContent={this.state.modalContent}
                        modalContentId={this.state.modalContentId}

                        provider={this.state.provider}
                        pdfFileId={this.state.pdfFileId}

                        addressesTableData={insurantDataLoaded && this.prepareAddressData(this.props.addresses)}
                        payrollsTableData={insurantDataLoaded && this.preparePayrollData(this.props.payrolls)}
                        unpaidVacationsTableData={insurantDataLoaded && this.prepareUnpaidVacationData(this.props.unpaidVacations)}

                        newAddress={this.state.newAddress}
                        newPayroll={this.state.newPayroll}
                        selectedAddress={this.state.selectedAddress}
                        selectedPayroll={this.state.selectedPayroll}
                        newUnpaidVacation={this.state.newUnpaidVacation}
                        selectedUnpaidVacation={this.state.selectedUnpaidVacation} />
                </Modal>

                <div className="main-content-container pensionskasse-insurants">
                    <div className="relative-container">

                        {isLoading && <Spinner relative/>}

                        <div className="main-content-container-left pensionskasse" onScroll={this.handleScroll}>

                            {this.props.insurantsFetched &&
                            <PersonenList
                                employers={this.props.employers}
                                insurants={this.props.insurants}
                                expandedRow={this.state.expandedRow}
                                filtersChanged={this.state.filtersChanged}
                                setLoading={(value) => this.handleSetLoading(value)}
                                onEditClick={(id, type) =>  this.openModal(id, type)}
                                onDetailsClick={(id) => this.openModal(id, modalPageTypes.INSURANT_DETAILS_PK)}/>}
                        </div>
                    </div>

                    <div className="drawer" ref={node => this.drawer = node}>
                        <Drawer opened={this.state.filtersOpened}>
                            <Sidebar
                                firmaOptions={firmaOptions}
                                setLoading={(value) => this.handleSetLoading(value)}/>
                        </Drawer>
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        entities: state.app.entities,
        mutationTypes: state.app.mutationTypes,


        insurants: state.insurantsPK.insurants,
        moreInsurants: state.insurantsPK.moreInsurants,
        insurantsPageNo: state.insurantsPK.insurantsPageNo,
        insurantsFetched: state.insurantsPK.insurantsFetched,
        insurantMutations: state.mutations.insurantMutations,

        addresses: state.addressesPK.addresses,
        payrolls: state.payrollsPK.payrolls,
        unpaidVacations: state.unpaidvacationsPK.unpaidVacations,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
        fetchInsurantMutations: bindActionCreators(fetchInsurantMutations, dispatch),
        fetchInsurantById: bindActionCreators(fetchInsurantById, dispatch),
        fetchAddresses: bindActionCreators(fetchAddresses, dispatch),
        fetchPayrolls: bindActionCreators(fetchPayrolls, dispatch),
        fetchUnpaidVacations: bindActionCreators(fetchUnpaidVacations, dispatch),
        createAddress: bindActionCreators(createAddress, dispatch),
        patchAddress: bindActionCreators(patchAddress, dispatch),
        deleteAddress: bindActionCreators(deleteAddress, dispatch),
        createPayroll: bindActionCreators(createPayroll, dispatch),
        patchPayroll: bindActionCreators(patchPayroll, dispatch),
        deletePayroll: bindActionCreators(deletePayroll, dispatch),
        createUnpaidVacation: bindActionCreators(createUnpaidVacation, dispatch),
        patchUnpaidVacation: bindActionCreators(patchUnpaidVacation, dispatch),
        deleteUnpaidVacation: bindActionCreators(deleteUnpaidVacation, dispatch),
        createInsurant: bindActionCreators(createInsurant, dispatch),
        editInsurant: bindActionCreators(editInsurant, dispatch),
        setInsurantsPageNo: (val) => dispatch(setInsurantsPageNo(val)),
        showValidations: (val) => dispatch(showValidations(val)),
        setLoading: (val) => dispatch(setLoading(val)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Insurances));
