import * as React from "react";
import { Component } from "react";
import { Blank, ExpandableRow, GroupPositions } from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import AcceptedMutationExpandableRowTop from "../AcceptedMutationExpandableRowTop";
import styles from "./AcceptedTab.module.scss";
import { modalPageTypes } from "../../../../constants";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classNames from "classnames";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../../actions";
import {
  fetchAcceptedMutations,
  fetchAcceptedMutationsProgress,
  setAcceptedMutationsFilter,
  setAcceptedMutationsPageNo,
} from "../../../../actions/pensionskasse/mutations";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import ProcessingMutations from "../../../ProcessingMutations";

interface State {
  expandedRow: any;
  filtersChanged: number;
}

interface Props extends RouteComponentProps {
  intl: any;
  refreshData: any;
  mutations: any;
  expandedRow: any;
  setLoading: any;
  filtersChanged: any;

  onPdfClick: any;

  acceptedMutationsFetched: boolean;
  fetchAcceptedMutations: any;
  fetchAcceptedMutationsProgress: any;
  setAcceptedMutationsPageNo: any;
  setAcceptedMutationsFilter: any;
  acceptedMutationsPageNo: number;

  acceptedMutationsProgress: any;

  acceptedMutationsNameFilter: any;
  acceptedMutationsEventFilter: any;
  acceptedMutationsFirmaFilter: any;
  acceptedMutationsTakeoverDateFrom: any;
  acceptedMutationsTakeoverDateUntil: any;
  acceptedMutationsMutationDateFrom: any;
  acceptedMutationsMutationDateUntil: any;
}

// const envInterval = process.env.REACT_APP_MUTATIONS_PROGRESS_INTERVAL || '5000';
const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || "20";

class AcceptedTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expandedRow: null,
      filtersChanged: 0,
    };
  }

  private interval = null;

  fetch = () => {
    this.props.fetchAcceptedMutations(
      this.props.acceptedMutationsPageNo * parseInt(paginationSize),
      0
    );
  };

  componentDidMount() {
    const interval = isNaN(parseInt("10000")) ? 10000 : parseInt("10000");
    this.interval = setInterval(() => {
      this.props.fetchAcceptedMutationsProgress().then((res) => {
        if (this.props.acceptedMutationsFetched) {
          this.props.fetchAcceptedMutations(parseInt(paginationSize), 0, true);
        }
      });
    }, interval);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  onDetailsClick = (insurantId) => {
    this.props.history.push(
      `/pensionskasse/versicherte/personen?edit=${insurantId}&content=${modalPageTypes.INSURANT_DETAILS_PK}&origin=${this.props.location.pathname}`
    );
  };

  determineGroupPosition = (mutations, index) => {
    if (!mutations || !mutations.length || mutations.length == 1) return null;
    const length = mutations.length;
    if (index == 0) {
      return GroupPositions.Top;
    } else if (index == length - 1) {
      return GroupPositions.Bottom;
    } else {
      return GroupPositions.Middle;
    }
  };

  clearFilters = () => {
    this.props.setAcceptedMutationsFilter({ name: "clear" });
    this.props.setAcceptedMutationsPageNo(0);
    this.props.setLoading(true);
    this.props.fetchAcceptedMutations().then(() => {
      this.props.setLoading(false);
      this.props.setAcceptedMutationsPageNo(1);
    });
  };

  filtersAreApplied = () => {
    return !!(
      this.props.acceptedMutationsNameFilter ||
      this.props.acceptedMutationsEventFilter ||
      this.props.acceptedMutationsFirmaFilter ||
      this.props.acceptedMutationsTakeoverDateFrom ||
      this.props.acceptedMutationsTakeoverDateUntil ||
      this.props.acceptedMutationsMutationDateFrom ||
      this.props.acceptedMutationsMutationDateUntil
    );
  };

  remainingMutations = (employer) => {
    const { acceptedMutationsProgress } = this.props;
    if (!acceptedMutationsProgress.length) {
      return 0;
    }
    const progressOfEmployer = acceptedMutationsProgress.find(
      (progress) => progress.attributes.employerId == employer.id
    );
    if (progressOfEmployer) {
      return progressOfEmployer.attributes.remaining;
    }
    return 0;
  };

  render() {
    return (
      <>
        <h1 className={styles.pageTitle}>Übernommene Mutationen</h1>
        {!!(
          this.props.acceptedMutationsFetched || this.props.mutations.length
        ) && (
          <div className={styles.listView}>
            <div className={styles.listHeader}>
              <div className={styles.column}>
                <FormattedMessage
                  id="app.type"
                  defaultMessage="Mutationstyp / Name"
                />
              </div>
              <div className={styles.column}>
                <FormattedMessage
                  id="app.validFrom"
                  defaultMessage="Gültig ab"
                />
              </div>
              <div className={styles.column}>
                <span>
                  <FormattedMessage
                    id="app.pervValue"
                    defaultMessage="bisher"
                  />
                </span>
                <span>
                  <FormattedMessage id="app.new" defaultMessage="neu" />
                </span>
              </div>
              <div className={styles.column}>
                <FormattedMessage
                  id="app.source"
                  defaultMessage="Mutationsdatum / Bearbeiter"
                />
              </div>
              <div className={styles.column}>
                <div style={{ width: "3rem" }} />
              </div>
            </div>
            {this.props.mutations.length ? (
              this.props.mutations.map((company) => {
                const remainingMutations = this.remainingMutations(company);
                return (
                  !!(company.employees && company.employees.length) && (
                    <>
                      <div className={styles.groupHeader}>
                        {company.name}
                        {!!remainingMutations && (
                          <ProcessingMutations count={remainingMutations} />
                        )}
                      </div>

                      {company.employees.map((employee) =>
                        employee.mutations.map((mutation, index) => {
                          const groupPosition = this.determineGroupPosition(
                            employee.mutations,
                            index
                          );
                          return (
                            <ExpandableRow
                              groupPosition={groupPosition}
                              className={classNames({
                                "mb-24":
                                  groupPosition == GroupPositions.Bottom ||
                                  !groupPosition,
                              })}
                              content={
                                <AcceptedMutationExpandableRowTop
                                  mutation={mutation}
                                  onPdfClick={(link) =>
                                    this.props.onPdfClick(link)
                                  }
                                  onDetailsClick={() =>
                                    this.onDetailsClick(
                                      mutation.attributes.employeeId
                                    )
                                  }
                                  expanded={
                                    employee.id + company.id + index ==
                                    this.state.expandedRow
                                  } //the row is expanded if the expandedRow == index
                                  toggleExpand={() =>
                                    this.setState({
                                      expandedRow:
                                        this.state.expandedRow ==
                                        employee.id + company.id + index
                                          ? null
                                          : employee.id + company.id + index,
                                    })
                                  }
                                />
                              }
                            />
                          );
                        })
                      )}
                    </>
                  )
                );
              })
            ) : (
              <Blank
                onButtonClick={this.clearFilters}
                // buttonText="Filter zurücksetzen"
                showButton={this.filtersAreApplied()}
                text={this.props.intl.formatMessage({
                  id: "app.nomutations",
                  defaultMessage: "Keine Mutationen vorhanden",
                })}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
  fetchAcceptedMutations: bindActionCreators(fetchAcceptedMutations, dispatch),
  fetchAcceptedMutationsProgress: bindActionCreators(
    fetchAcceptedMutationsProgress,
    dispatch
  ),
  setAcceptedMutationsPageNo: (val) =>
    dispatch(setAcceptedMutationsPageNo(val)),
  setAcceptedMutationsFilter: (val) =>
    dispatch(setAcceptedMutationsFilter(val)),
});
const mapStateToProps = (state: RootState, ownProps: any) => ({
  ...ownProps,
  acceptedMutationsPageNo: state.acceptedMutations.acceptedMutationsPageNo,
  acceptedMutationsProgress: state.acceptedMutations.acceptedMutationsProgress,
  acceptedMutationsFetched: state.acceptedMutations.acceptedMutationsFetched,
  acceptedMutationsNameFilter:
    state.acceptedMutations.acceptedMutationsNameFilter,
  acceptedMutationsEventFilter:
    state.acceptedMutations.acceptedMutationsEventFilter,
  acceptedMutationsFirmaFilter:
    state.acceptedMutations.acceptedMutationsFirmaFilter,
  acceptedMutationsTakeoverDateFrom:
    state.acceptedMutations.acceptedMutationsTakeoverDateFrom,
  acceptedMutationsTakeoverDateUntil:
    state.acceptedMutations.acceptedMutationsTakeoverDateUntil,
  acceptedMutationsMutationDateFrom:
    state.acceptedMutations.acceptedMutationsMutationDateFrom,
  acceptedMutationsMutationDateUntil:
    state.acceptedMutations.acceptedMutationsMutationDateUntil,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(AcceptedTab)));
