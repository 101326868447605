import * as constants from '../constants';
import {toastr} from 'react-redux-toastr';
import apiCaller from "../utils/api";
import api from "../utils/api";

export interface ACTION {
    type: string;
    payload?: any;
}

export const setSidebar = (payload: boolean): ACTION => ({
  type: constants.SIDEBAR_SET,
  payload
});

export const setPopup = (payload: boolean): ACTION => ({
    type: constants.POPUP,
    payload
});


export const addNewFile  = (payload: any): ACTION => ({
   type: constants.ADD_NEW_FILE,
   payload
});

export const showValidations  = (payload: any): ACTION => ({
    type: constants.SHOW_VALIDATIONS,
    payload
});

export const updateProgress  = (payload: any): ACTION => ({
    type: constants.UPDATE_PROGRESS,
    payload
});

export const loginAct = (payload: any): ACTION => ({
  type: constants.LOGIN,
  payload
});

export const loginSuccess = (payload: any): ACTION => ({
  type: constants.LOGIN_SUCCESS,
  payload
});

export const updateExpirationDate = (payload: any): ACTION => ({
    type: constants.UPDATE_EXPIRATION,
    payload
});

export const logOutLocally = (): ACTION => ({
  type: constants.LOG_OUT,
});

export const setLoading = (payload: boolean): ACTION => ({
  type: constants.LOADING,
  payload
});

export const filterPersons = (payload: boolean): ACTION => ({
    type: constants.SET_FILTERING_CRITERIA_PERSONS,
    payload
});

export const filterMutations = (payload: boolean): ACTION => ({
    type: constants.SET_FILTERING_CRITERIA_MUTATIONS,
    payload
});

export const filterInvoices = (payload: boolean): ACTION => ({
    type: constants.SET_FILTERING_CRITERIA_INVOICES,
    payload
});

export const filterInbox = (payload: boolean): ACTION => ({
    type: constants.SET_INBOX_FILTER,
    payload
});

export const filterOutbox = (payload: boolean): ACTION => ({
    type: constants.SET_OUTBOX_FILTER,
    payload
});

export const setCockpitFirmaFilter = (payload: boolean): ACTION => ({
    type: constants.FIRMAFILTER_SET,
    payload
});

export const setInsurantsPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_INSURANTS_PAGE_NO,
    payload
});

export const setMutationsPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_MUTATIONS_PAGE_NO,
    payload
});

export const setInboxPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_INBOX_PAGE_NO,
    payload
});

export const setOutboxPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_OUTBOX_PAGE_NO,
    payload
});

export const setInvoicesPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_INVOICES_PAGE_NO,
    payload
});

export const updateFileStatus = (payload: any): ACTION => ({
    type: constants.UPDATE_FILE_STATUS,
    payload
});

export const setFetchData = (payload: any): ACTION => ({
    type: constants.SET_FETCH_DATA,
    payload
});

export const setGlobalSettings = (payload:any): ACTION => ({
    type: constants.GLOBAL_SETTINGS,
    payload
})

export const fetchEntities = () => {
    return async (dispatch) => {
        return api.get(`entities/`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_ENTITIES, "payload": response['data'] });
            }).catch(err => err);
    }
};

export const fetchEmployers = () => {
    return async (dispatch) => {
        return api.get(`employers/`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_EMPLOYERS, "payload": response['data'] });
            }).catch(err => err);
    }
};

export const fetchEmployersInsurancePlans = (employerId, isSelectableOnEntry=true) => {
    return async (dispatch) => {
        return api.get(`insurancePlans?employerId=${employerId}&isSelectableOnEntry=${isSelectableOnEntry}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_EMPLOYERS_INSURANCE_PLANS, "payload": response['data'] });
                return response['data']
            }).catch(err => err);
    }
};

export const health = () => {
    return fetch(`${process.env.REACT_APP_REST_API_URL}health/`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    })
    .then(response => response)
    .catch(err => err);
};

// handling errors from redux-thunk async action creators
// source: https://thecodebarbarian.com/async-await-with-react-and-redux-thunk
export const wrap = (fn, type = '') => {
    return function(dispatch) {
        return fn(dispatch)
            .then((res) => {
                dispatch({ "type": constants.LOADING, "payload": false });
                dispatch({ "type": constants.UPDATE_EXPIRATION, "payload": null });
                return res;
            }).catch((err) => {

                console.log("errrr", err);

                let jsonResponse;
                try {
                    jsonResponse = JSON.parse(err);
                } catch(e) {
                    jsonResponse = {
                        errors: [{ title: "Error",  status: "" }]
                    }
                }

                const validation_error = jsonResponse["errors"] &&
                    jsonResponse["errors"][0] &&
                    jsonResponse["errors"][0]["source"] &&
                    jsonResponse["errors"][0]["source"]["pointer"] &&
                    jsonResponse["errors"][0]["code"] &&
                    jsonResponse["errors"][0]["code"].includes("422");


                if(type != "LOG_OUT" && type != "LOGIN_ERROR" && !validation_error) {
                    if(jsonResponse["errors"] && jsonResponse["errors"].length) {
                        const errors = jsonResponse["errors"];
                        errors.forEach(err => {
                            dispatch({ type , error: err["title"] });
                            dispatch({ "type": constants.LOADING, "payload": false });
                            toastr.error(err['title'], err['detail'] || "");
                        })

                    }
                }

                if(type == "LOGIN_ERROR") {
                    toastr.error(err);
                    dispatch({ "type": constants.LOADING, "payload": false });
                }

                if (jsonResponse["errors"] && jsonResponse["errors"][0] && jsonResponse["errors"][0]["status"] === "401") {
                    dispatch(logOutLocally());
                }

                return jsonResponse;
            });
    };
}
