import * as React from 'react';
import style from './Checkmark.module.scss';
import {ReactComponent as IconCheckmarkSlim} from "../../assets/icons/checkmark-slim.svg";

export interface Props {
}

const Checkmark = (props: Props) => (
    <div className={style.checkmark}>
        <IconCheckmarkSlim className="strokeWhite"/>
    </div>
);

export default Checkmark;
