// import {EValidationTestType} from '../utils/validationConfigs';


export const emptyMutationPayload = {
    data: {
        attributes: {
            name: "",
            fileId: "",
            userId: "",
            username: "",
            birthDate: "",
            timeStamp: "",
            firstname: "",
            eventDate: "",
            employerId: "",
            mutationType: "",
            processingState: "",
            processingStateName: "",
            socialSecurityNumber: "",
            values: {
                person: null,
                payroll: null,
                address: null,
                employee: null,
                unpaidVacation: null
            }
        }
    }
}

export const commitMutationsPayload = {
    "data": {
        "id": '00000000-0000-0000-0000-000000000000',
        "type": "commit",
        "attributes": {
            "employers": [],
            // "summary": "string",
            // "report": "string",
            "approved": true
        }
    },
    "jsonapi": {}
};

export const minimumInsurantPayload = {
    "data": {
        "type": "insurant",
        "attributes": {
            "person": {
                "name": null,
                "firstname": null,
                "birthDate": null,
                "socialSecurityNumber": null,
                "sex": null,
                "civilStatus": null,
                "contactName": null,
                "contactFirstname": null,
                "contactaddress": null,
                "contactPhoneNumber": null,
                "contactEmail": null,
                "dateOfDeath": null,
                "divorceDate": null,
                "marriageDate": null
            },
            "address": {
                "validFrom": null,
                "validUntil": null,
                "address1": null,
                "zipCode": null,
                "city": null,
                "country": null
            },
            "payroll": {
                "validFrom": null,
                "grossSalary": null,
                "activityRate": null,
            },
            "employee": {
                "employerId": null,
                "entryDate": null,
                "unemployabilityDate": null,
                "quittingDate": null,
                "retirementDate": null
            },
            "Payoutaddress": null,
            "Type": "insurant"
        },
        "relationships": {},
        "links": {}
    }
}

export const minimumInsurantPatchPayload = {
    "data": {
        "id": null,
        "type": "insurant",
        "attributes": {
            "person": {
                "birthDate": "",
                "customizable2": "",
                "divorceDate": "",
                "marriageDate": "",
                "dateOfDeath": ""
            },
            "employee": {
                "insuranceEntryDate": "",
                "retirementDate": "",
                "quittingDate": "",
                "unemployabilityDate": "",
                "disabilityDate": "",
            },
            "unpaidVacation": {
                "validFrom": "",
                "validUntil": ""
            },
            "payoutAddress": {
                "address": "",
                "zip": "",
                "city": "",
                "iban": ""
              },
            // "payoutAddress": null
        },
        "relationships": {},
        "links": {}
    },
    // "jsonapi": {
    //     "version": "1.0"
    // },
    "meta": {
        "mutationType": "Informationsänderung"
    }
}

export const minimumMutationPatchPayload = {
    "data": {
        "id": "",
        "type": "",
        "attributes": {
          "fileId": "",
          "eventDate": "",
          "timeStamp": "",
          "mutationType": {
            "name": ""
          },
          "processingStateName": "",
          "processingState": 0,
          "username": "",
          "firstname": "",
          "name": "",
          "socialSecurityNumber": "",
          "values": {
          }
        }
      },
      "meta": {
          "mutationType": ""
      },
      "jsonapi": {}
}

// MAPPINGS
// Person
// m_dPersonCustomizable1	DateTime?	Scheidungsdatum
// m_dPersonCustomizable2	DateTime?	Geburtsdatum Ehepartner/in
// m_dPersonCustomizable3	DateTime?
// m_dPersonCustomizable4	DateTime?
// m_dPersonCustomizable5	DateTime?
// m_sPersonCustomizable6	string	Name Ehepartner/in
// m_sPersonCustomizable7	string	Vorname Ehepartner/in
// m_sPersonCustomizable8	string	Name des Versicherten nach Heirat
// m_sPersonCustomizable9	string	Name nach Scheidung
// m_sPersonCustomizable10	string
//
// Payroll
// m_bSpecialCase	bool	Bisheriger versicherten Lohn beibehalten Art 5 Abs 6
// m_nPayrollCustomizable1	decimal	Überbrückungsrente
// m_nPayrollCustomizable2	decimal
// m_nPayrollCustomizable3	decimal
// m_nPayrollCustomizable4	decimal
// m_nPayrollCustomizable5	decimal
// m_bPayrollCustomizable6	bool
// m_bPayrollCustomizable7	bool
// m_bPayrollCustomizable8	bool
// m_bPayrollCustomizable9	bool
// m_bPayrollCustomizable10	bool
//
// Employee
// m_bEmployeeCustomizable1	bool	Über 58 Jahre alt
// m_bEmployeeCustomizable2	bool	Kein neuer Arbeitgeber
// m_sEmployeeCustomizable3	string	Neuer Arbeitgeber
// m_sEmployeeCustomizable4	string	Neue Pensionskasse
// m_sEmployeeCustomizable5	string	Bisheriger Arbeitgeber
// m_sEmployeeCustomizable6	string	Vorsorgeeinrichtung
// m_sEmployeeCustomizable7	string
// m_bEmployeeCustomizable8	bool
// m_bEmployeeCustomizable9	bool
// m_bEmployeeCustomizable10	bool
