// import { IDictionary } from '../models';
import { store } from './../index';
import {defined} from "./variableEvaluation";
import *  as constants from "./../constants/index";
import { InvoiceStates } from "../models";
import { isDate } from './validationTests';

const paginationSize = process.env.REACT_APP_PAGINATION_SIZE || '20';

export function objectToSearchParams(object: IDictionary<any>) {
  const searchParams = new URLSearchParams();
  Object.keys(object).forEach(key => searchParams.set(key, object[key]));
  return searchParams;
}

export function objectToFormData(object: IDictionary<any>) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.set(key, object[key]));
  return formData;
}

export function getParameterByName(name, url?) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getParameterByNameWithoutSpaces(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
}

export function insurantsQueryParams(length, page) {
    const state = store.getState().insurants;
    const text = state.filterByNamePerson ? state.filterByNamePerson.replace(/,/g, ' ') : "";
    const company = state.filterByFirmaPerson ? state.filterByFirmaPerson.replace(/,/g, ' ') : "";
    const unchanged = state.filterByOnlyChangedPerson ? "hideUnchanged:true," : "";
    const inactive = !state.filterByAlsoInactivePerson ? "hideInactive:true," : "";

    const paginationLength = defined(length) ? length : constants.PAGINAION_SIZE;
    const paginationPage = defined(page) ? page : state.insurantsPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${text}${company ? ",company[eq]:"+company : ""},${unchanged}${inactive}`;

    const query = [pagination, filtering].join("");

    return query;
}

export function mutationsQueryParams(length, page) {
    const state = store.getState().mutations;
    const text = state.filterByNameMutations.replace(/,/g, ' ');
    const company = state.filterByFirmaMutations.replace(/,/g, ' ');
    const type = state.filterByTypeMutations.replace(/,/g, ' ');
    const complete = !state.filterByAlsoClosedMutations ? "hideComplete" : "";

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.mutationsPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${text}${company ? ",company[eq]:"+company : ""},mutationType.name[contains]:${type},${complete}`;
    // const sorting = `&sort=timeStamp:desc`;

    const query = [pagination, /*sorting,*/ filtering].join("");

    return query;
}

export function invoicesQueryParams(length, page) {
    const state = store.getState().invoices
    const status = state.filterByStatusInvoices.replace(/,/g, ' ');
    const company = state.filterByFirmaInvoices.replace(/,/g, ' ');

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.invoicesPageNo;

    // const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const pagination = `?page=size:50,page:0`;
    const filtering = `&filter=${company ? "company[eq]:"+company : ""}${ status ? ",status[eq]:" +  InvoiceStates[status] : ""}`;

    const query = [pagination, filtering].join("");

    return query;
}

export function inboxQueryParams(length, page) {
    const state = store.getState().upload
    const name = state.filterNameInbox.replace(/,/g, ' ');
    const company = state.filterFirmaInbox.replace(/,/g, ' ');
    const type = state.filterTypeInbox.replace(/,/g, ' ');
    const year = state.filterYearInbox.replace(/,/g, ' ');
    const month = state.filterMonthInbox.replace(/,/g, ' ');
    const isNew = state.filterOnlyNewInbox;

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.inboxPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${name}${company ? ",company[eq]:"+company : ""},documentType[contains]:${type}${year ? ",year[equals]:" + year : ""}${month ? ",month[equals]:" + month : ""}${isNew ? ",isNew" : ""}`;

    const query = [pagination, filtering].join("");

    return query;
}

export function outboxQueryParams(length, page) {
    const state = store.getState().upload
    const name = state.filterNameOutbox.replace(/,/g, ' ');
    const company = state.filterFirmaOutbox.replace(/,/g, ' ');
    const type = state.filterTypeOutbox.replace(/,/g, ' ');
    const year = state.filterYearOutbox.replace(/,/g, ' ');
    const month = state.filterMonthOutbox.replace(/,/g, ' ');

    // const isNew = state.filterOnlyNewOutbox;
    // ${isNew ? ",isNew" : ""} : for is new

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.outboxPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${name}${company ? ",company[eq]:"+company : ""},documentType[contains]:${type}${year ? ",year[equals]:" + year : ""}${month ? ",month[equals]:" + month : ""}`;

    const query = [pagination, filtering].join("");

    return query;
}

export function forReviewMutationsCommitCompanyQueryParams(excludeWarnings) {
    const state = store.getState().forReviewMutations;
    const company = state.forReviewMutationsFirmaFilter.replace(/,/g, ' ');
    
    const excludeQuery = excludeWarnings ? "?excludeWarnings" : "";
    const companyQuery = company ? `${excludeQuery ? "&" : "?"}filter=company[eq]:${company}` : "";

    return [excludeQuery, companyQuery].join("");
}

export function forReviewMutationsQueryParams(length, page) {
    const state = store.getState().forReviewMutations;
    const text = state.forReviewMutationsNameFilter.replace(/,/g, ' ');
    const company = state.forReviewMutationsFirmaFilter.replace(/,/g, ' ');
    const type = state.forReviewMutationsEventFilter.replace(/,/g, ' ');

    let warningsAndErrors = "";
    if(state.forReviewMutationsNoWarningsErrorsFilter) {
        warningsAndErrors = ",warning[eq]:false,error[eq]:false"
    } else if (state.forReviewMutationsOnlyWarningsFilter) {
        warningsAndErrors = ",warning[eq]:true"
    } else if (state.forReviewMutationsOnlyErrorsFilter) {
        warningsAndErrors = ",error[eq]:true"
    }

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.forReviewMutationsPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${text}${company ? ",company[eq]:"+company : ""},mutationType.name[contains]:${type}${warningsAndErrors}`;
    // const sorting = `&sort=m_sEmployerName:asc,timeStamp:desc`;

    const query = [pagination, /*sorting,*/ filtering].join("");

    return query;
}

export function acceptedMutationsQueryParams(length, page) {
    const state = store.getState().acceptedMutations;
    const text = state.acceptedMutationsNameFilter.replace(/,/g, ' ');
    const company = state.acceptedMutationsFirmaFilter.replace(/,/g, ' ');
    const type = state.acceptedMutationsEventFilter.replace(/,/g, ' ');
    const fromTakeover = state.acceptedMutationsTakeoverDateFrom && isDate(state.acceptedMutationsTakeoverDateFrom) ? `archiveDateFrom[eq]:${state.acceptedMutationsTakeoverDateFrom}` : "";
    const untilTakeover = state.acceptedMutationsTakeoverDateUntil && isDate(state.acceptedMutationsTakeoverDateUntil) ? `archiveDateUntil[eq]:${state.acceptedMutationsTakeoverDateUntil}` : "";
    const fromMutation = state.acceptedMutationsMutationDateFrom && isDate(state.acceptedMutationsMutationDateFrom) ? `mutationDateFrom[eq]:${state.acceptedMutationsMutationDateFrom}` : "";
    const untilMutation = state.acceptedMutationsMutationDateUntil && isDate(state.acceptedMutationsMutationDateUntil) ? `mutationDateUntil[eq]:${state.acceptedMutationsMutationDateUntil}` : "";

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.acceptedMutationsPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${text}${company ? ",company[eq]:"+company : ""},mutationType.name[contains]:${type},${fromTakeover},${untilTakeover},${fromMutation},${untilMutation}`;
    // const sorting = `&sort=timeStamp:desc`;

    const query = [pagination, /*sorting,*/ filtering].join("");

    return query;
}

export function deletedMutationsQueryParams(length, page) {
    const state = store.getState().deletedMutations;
    const text = state.deletedMutationsNameFilter.replace(/,/g, ' ');
    const company = state.deletedMutationsFirmaFilter.replace(/,/g, ' ');
    const type = state.deletedMutationsEventFilter.replace(/,/g, ' ');
    const fromTakeover = state.deletedMutationsTakeoverDateFrom && isDate(state.deletedMutationsTakeoverDateFrom) ? `archiveDateFrom[eq]:${state.deletedMutationsTakeoverDateFrom}` : "";
    const untilTakeover = state.deletedMutationsTakeoverDateUntil && isDate(state.deletedMutationsTakeoverDateUntil) ? `archiveDateUntil[eq]:${state.deletedMutationsTakeoverDateUntil}` : "";
    const fromMutation = state.deletedMutationsMutationDateFrom && isDate(state.deletedMutationsMutationDateFrom) ? `mutationDateFrom[eq]:${state.deletedMutationsMutationDateFrom}` : "";
    const untilMutation = state.deletedMutationsMutationDateUntil && isDate(state.deletedMutationsMutationDateUntil) ? `mutationDateUntil[eq]:${state.deletedMutationsMutationDateUntil}` : "";

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.deletedMutationsPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${text}${company ? ",company[eq]:"+company : ""},mutationType.name[contains]:${type},${fromTakeover},${untilTakeover},${fromMutation},${untilMutation}`;
    // const sorting = `&sort=timeStamp:desc`;

    const query = [pagination, /*sorting,*/ filtering].join("");

    return query;
}

export function insurantsQueryParamsPK(length, page) {
    const state = store.getState().insurantsPK;
    const text = state.insurantsNameFilter.replace(/,/g, ' ');
    const company = state.insurantsFirmaFilter.replace(/,/g, ' ');

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.insurantsPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${text}${company ? ",company[eq]:"+company : ""}`;

    const query = [pagination, filtering].join("");

    return query;
}

export function invoicesQueryParamsPK(length, page) {
    const state = store.getState().invoicesPK
    const company = state.invoicesFirmaFilter.replace(/,/g, ' ');
    const invoicesOnlyOverdueFilter = state.invoicesOnlyOverdueFilter;
    const invoicesOnlyPaidFilter = state.invoicesOnlyPaidFilter;
    const invoicesOnlyOpenFilter = state.invoicesOnlyOpenFilter;

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.invoicesPageNo;

    let status = null;
    if(invoicesOnlyOverdueFilter) {
        status = "1"
    } else if (invoicesOnlyPaidFilter) {
        status = "2"
    } else if (invoicesOnlyOpenFilter) {
        status = "0"
    }
    // const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const pagination = `?page=size:50,page:0`;
    const filtering = `&filter=${company ? "company[eq]:"+company : ""}${ status ? ",status[eq]:" +  status : ""}`;

    const query = [pagination, filtering].join("");

    return query;
}

export function companiesQueryParams(length, page) {
    const state = store.getState().companies;
    const text = state.companiesNameFilter.replace(/,/g, ' ');

    const paginationLength = defined(length) ? length : parseInt(paginationSize);
    const paginationPage = defined(page) ? page : state.companiesPageNo;

    const pagination = `?page=size:${paginationLength},page:${paginationPage}`;
    const filtering = `&filter=text[contains]:${text}`;

    const query = [pagination, filtering].join("");

    return query;
}
