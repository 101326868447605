import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import {
  parseDate,
  prepareCountriesEntitiesDropdownOptions,
} from "../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { FormattedMessage } from "react-intl";
import "./FormAddress.scss";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import formName from "../../../constants/forms/formName.json";
import { fetchFormCustomization, filterOnlyLetters } from "../../../utils/form";
import {
  formHasChanged,
  validateInputMessage,
  EValidationTestType,
} from "../../../utils/validationTools";
import Spinner from "../../Spinner";

interface Props {
  checkFormValidity: any;
  closeModal: any;
  entities?: any;
  initialData: any;
  isLoading: boolean;
  disabled: boolean;
  submitAction: any;
  location: any;
  employers: any;
  history: any;
  showValidations: boolean;
}

const FormAddress = (props: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      address: {
        ...props.initialData,
        validFrom: parseDate(props.initialData.validFrom),
        validUntil: parseDate(props.initialData.validUntil)
          ? parseDate(props.initialData.validUntil)
          : "",
        country: props.initialData.country
          ? props.initialData.country.toLowerCase()
          : "",
      },
    },
  });

  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const landOptions = useSelector((state: RootState) => {
    return prepareCountriesEntitiesDropdownOptions(
      state.app.entities.CountryCodes.values
    );
  });

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.formAddress"]
    );
    if (response.data) {
      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("address")[key])
      );
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: response["data"].attributes?.translations,
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            validFrom: {
              required: true,
              date: {
                dayFilter: 0,
              },
              interval: {
                fieldToCompare: "birthDate",
              },
            },
            validUntil: {
              required: false,
              date: {
                dayFilter: 0,
              },
              interval: {
                fieldToCompare: "validFrom",
              },
            },
            address1: {
              required: true,
            },
            address2: {
              required: false,
            },
            zipCode: {
              required: true,
            },
            city: {
              required: true,
            },
            country: {
              required: true,
            },
            email: {
              required: false,
            },
            mobileNumber: {
              required: false,
            },
            phoneNumber: {
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          validFrom: !!getValues("address.validFrom"),
          address1: !!getValues("address.address1"),
          zipCode: !!getValues("address.zipCode"),
          city: !!getValues("address.city"),
          country: !!getValues("address.country"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("address"),
        validFrom: parseDate(props.initialData.validFrom),
        validUntil: parseDate(props.initialData.validUntil)
          ? parseDate(props.initialData.validUntil)
          : "",
        country: props.initialData.country
          ? props.initialData.country.toLowerCase()
          : "",
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const countryDropdownValue = landOptions
    ? landOptions.find(
        (option) => option.value === getValues("address.country")
      )
    : null;

  return (
    <>
      {props.isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            console.log("onsubmit", e);
            e.preventDefault();
          }}
        >
          <fieldset disabled={props.disabled}>
            <div className="row">
              {/* Gutlig ab */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.validFrom"
                  render={({ field: { value } }) => (
                    <Input
                      isFocused
                      type="date"
                      hidden={
                        formConfig.formCustomization.components["validFrom"]
                          ?.hidden
                      }
                      maxLength={10}
                      inputWrapperClass="text-input"
                      id={"validFromInput"}
                      name={"validFromInput"}
                      placeholder=""
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue("address.validFrom", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "validFrom",
                            !!props.initialData.birthDate
                              ? () => [
                                  EValidationTestType.dateIsNotBeforeBirthdate,
                                ]
                              : null,
                            formConfig.requiredFields
                          );
                        setError("address.validFrom", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "validFrom",
                            !!props.initialData.birthDate
                              ? () => [
                                  EValidationTestType.dateIsNotBeforeBirthdate,
                                ]
                              : null,
                            formConfig.requiredFields
                          );
                        setError("address.validFrom", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.validFrom?.message,
                          type: errors?.address?.validFrom?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.addressvalidfrom"
                        defaultMessage="Adresse gültig ab"
                      />
                      {formConfig.formCustomization.components["validFrom"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Gutlig bis */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.validUntil"
                  render={({ field: { value } }) => (
                    <Input
                      type="date"
                      maxLength={10}
                      hidden={
                        formConfig.formCustomization.components["validUntil"]
                          ?.hidden
                      }
                      inputWrapperClass="text-input"
                      id={"validUntilInput"}
                      name={"validUntilInput"}
                      placeholder=""
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue("address.validUntil", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "validUntil",
                            () => [EValidationTestType.isUntilHigherThenFrom],
                            formConfig.requiredFields
                          );

                        setError("address.validUntil", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "validUntil",
                            () => [EValidationTestType.isUntilHigherThenFrom],
                            formConfig.requiredFields
                          );
                        setError("address.validUntil", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.validUntil?.message,
                          type: errors?.address?.validUntil?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.addressvaliduntil"
                        defaultMessage="Adresse gültig bis"
                      />
                      {formConfig.formCustomization.components["validUntil"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Adresse 1 */}
              <div className="col-12">
                <Controller
                  control={control}
                  name="address.address1"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id={"address1Input"}
                      name={"address1Input"}
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("address.address1", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "address1",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.address1", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "address1",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.address1", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.address1?.message,
                          type: errors?.address?.address1?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.addresse1"
                        defaultMessage="Adresszeile 1"
                      />
                      {formConfig.formCustomization.components["address1"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Adresse 2 */}
              <div className="col-12">
                <Controller
                  control={control}
                  name="address.address2"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id={"address2Input"}
                      name={"address2Input"}
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("address.address2", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "address2",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.address2", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "address2",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.address2", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.address2?.message,
                          type: errors?.address?.address2?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.addresse2"
                        defaultMessage="Adresszeile 2"
                      />
                      {formConfig.formCustomization.components["address2"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* PLZ */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.zipCode"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id={"zipCodeInput"}
                      name={"zipCodeInput"}
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("address.zipCode", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "zipCode",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.zipCode", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "zipCode",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.zipCode", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.zipCode?.message,
                          type: errors?.address?.zipCode?.type,
                        },
                      }}
                    >
                      <FormattedMessage id="app.plz" defaultMessage="PLZ" />
                      {formConfig.formCustomization.components["zipCode"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* ORT */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.city"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id={"cityInput"}
                      name={"cityInput"}
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("address.city", filterOnlyLetters(valueInput));
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "city",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.city", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "city",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.city", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.city?.message,
                          type: errors?.address?.city?.type,
                        },
                      }}
                    >
                      <FormattedMessage id="app.city" defaultMessage="Ort" />
                      {formConfig.formCustomization.components["city"]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Land */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.country"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      label="Land"
                      isCountriesDropdown
                      hidden={
                        formConfig.formCustomization.components["country"]
                          ?.hidden
                      }
                      inputWrapperClass="dropdown-input"
                      id={"countryInput"}
                      name={"countryInput"}
                      placeholder="Schweiz"
                      value={
                        countryDropdownValue
                          ? countryDropdownValue["label"]
                          : getValues("address.country") || ""
                      }
                      selectOptions={landOptions}
                      onSelected={(name, valueInput) => {
                        setValue("address.country", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "country",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.country", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "country",
                            null,
                            formConfig.requiredFields
                          );
                        setError("address.country", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.country?.message,
                          type: errors?.address?.country?.type,
                        },
                      }}
                      sort
                    >
                      <FormattedMessage id="app.land" defaultMessage="Land" />
                      {formConfig.formCustomization.components["country"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />

              {/* Email */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.email"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id={"emailInput"}
                      name={"emailInput"}
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("address.email", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "email",
                            () => [EValidationTestType.isEmail],
                            formConfig.requiredFields
                          );
                        setError("address.email", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "email",
                            () => [EValidationTestType.isEmail],
                            formConfig.requiredFields
                          );
                        setError("address.email", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.email?.message,
                          type: errors?.address?.email?.type,
                        },
                      }}
                    >
                      <FormattedMessage id="app.email" defaultMessage="Email" />
                      {formConfig.formCustomization.components["email"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Mobile Number */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.mobileNumber"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id={"mobileNumberInput"}
                      name={"mobileNumberInput"}
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("address.mobileNumber", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "mobileNumber",
                            () => [
                              EValidationTestType.isPhoneNumber,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("address.mobileNumber", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "mobileNumber",
                            () => [
                              EValidationTestType.isPhoneNumber,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("address.mobileNumber", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.mobileNumber?.message,
                          type: errors?.address?.mobileNumber?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.phoneNumber"
                        defaultMessage="Mobiltelefon"
                      />
                      {formConfig.formCustomization.components["mobileNumber"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Phone number */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="address.phoneNumber"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      id={"phoneNumberInput"}
                      name={"phoneNumberInput"}
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("address.phoneNumber", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "phoneNumber",
                            () => [
                              EValidationTestType.isPhoneNumber,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("address.phoneNumber", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("address"),
                            "phoneNumber",
                            () => [
                              EValidationTestType.isPhoneNumber,
                              EValidationTestType.isNumber,
                            ],
                            formConfig.requiredFields
                          );
                        setError("address.phoneNumber", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: props.showValidations,
                        config: {
                          message: errors?.address?.phoneNumber?.message,
                          type: errors?.address?.phoneNumber?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.phonenumber"
                        defaultMessage="Telefonnummer"
                      />
                      {formConfig.formCustomization.components["phoneNumber"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />
              <div className="col-12 col-sm-6" />
              <div className="col-12 col-sm-6" />
              <div className="col-12 col-sm-6" />
              <div className="col-12 col-sm-6">
                <div className="modal-buttons">
                  <Button
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={() => props.history.goBack()}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    submit={true}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={
                      props.isLoading ||
                      isDisabledButton ||
                      !formHasChanged(
                        initialFormToCompare,
                        getValues("address")
                      )
                    }
                    onClick={(e) => {
                      const data = {
                        address: {
                          validFrom: getValues("address.validFrom"),
                          validUntil: getValues("address.validUntil"),
                          address1: getValues("address.address1"),
                          address2: getValues("address.address2"),
                          zipCode: getValues("address.zipCode"),
                          city: getValues("address.city"),
                          country: getValues("address.country"),
                          email: getValues("address.email"),
                          mobileNumber: getValues("address.mobileNumber"),
                          phoneNumber: getValues("address.phoneNumber"),
                        },
                      };
                      props.submitAction(
                        e,
                        !!props.initialData.id
                          ? {
                              address: {
                                ...data.address,
                                id: props.initialData.id,
                              },
                            }
                          : data
                      );
                    }}
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    isLoading: state.app.loading,
    employers: state.app.employers,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormAddress)
);
