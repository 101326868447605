import apiCaller from "../../utils/api";
import {ACTION, wrap} from "../index";
import * as constants from "../../constants/pensionskasse";
import {invoicesQueryParamsPK} from "../../utils/toSearchParams";
import api from "../../utils/api";

export const setInvoicesFilter = (payload: boolean): ACTION => ({
    type: constants.SET_INVOICES_FILTERING,
    payload
});


export const setInvoicesPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_INVOICES_PAGE_NO,
    payload
});

export const fetchInvoices = (length, page) => {
    const params = invoicesQueryParamsPK(length, page);
    return async (dispatch) => {
        return api.get(`invoices${params}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_INVOICES, "payload": response['data'] });
            }).catch(err => err);
    }
};
