import * as React from "react";
import classNames from "classnames";
import { ReactComponent as ThreeDots } from "../../assets/icons/three_dots.svg";

import styles from "./DropdownCollapse.module.scss"; // Import css modules stylesheet as styles

interface State {
  isOpened: boolean;
  isFocused: boolean;
}

interface Props {
  label?: string;
  fullWidth?: boolean;
  isInlineRow?: boolean;
}

export class DropdownCollapse extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      isFocused: false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.modifyArrowOnCollapse = this.modifyArrowOnCollapse.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("click", this.onClickListener);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.onClickListener);
  }

  onClickListener = (event) => {
    // console.log("CLASSES", (event.target as any).classList)
    if (
      !(event.target as any).classList.contains("dropdownContent") &&
      !(event.target as any).classList.contains("selectOptionsWrapper") &&
      !(event.target as any).classList.contains("inputSelectOption") &&
      !(event.target as any).classList.contains("inputSelectArrow") &&
      (event.target as any).tagName.toLowerCase() != "input" &&
      (event.target as any).tagName.toLowerCase() != "svg" &&
      (event.target as any).tagName.toLowerCase() != "path"
    ) {
      document.body.classList.remove("backdrop");
      this.setState({ isOpened: false });
    }
    if (
      (event.target as any).classList &&
      ((event.target as any).classList.contains("profileTrigger") ||
        (event.target as any).parentElement.classList.contains(
          "profileTrigger"
        ))
    ) {
      document.body.classList.add("backdrop");
      this.setState({ isOpened: true });
    }
  };

  toggleCollapse(event?): void {
    this.setState(
      {
        isOpened: !this.state.isOpened,
      },
      () => {
        if (this.state.isOpened) {
          document.body.classList.add("backdrop");
        } else {
          document.body.classList.remove("backdrop");
        }
      }
    );
  }

  modifyArrowOnCollapse() {
    return this.state.isOpened ? styles.activeArrow : "";
  }

  render() {
    return (
      <div
        className={classNames("dropdown", styles.dropdownCollapse, {
          fullWidth: this.props.fullWidth,
        })}
      >
        <div className={styles.labelContainer}>
          <button
            tabIndex={-1}
            className={classNames(
              "dropdownContent",
              "headerDropdownButton",
              styles.collapseButton
            )}
            onClick={(e) => this.toggleCollapse(e)}
            onBlur={(e) => this.setState({ isOpened: false })}
          >
            <span className={classNames("dropdownContent", styles.label)}>
              {this.props.label}
            </span>
            {!this.props.isInlineRow && (
              <div
                className={`${
                  styles.arrow
                } ${this.modifyArrowOnCollapse()} dropdownContent`}
              />
            )}
            {this.props.isInlineRow && <ThreeDots />}
          </button>
        </div>

        <div
          className={classNames("dropdownContent", styles.contentContainer)}
          style={{ width: this.props.isInlineRow ? 245 : 356 }}
        >
          <div
            className={classNames("dropdownContent", styles.content, {
              [`${styles.isOpened}`]: this.state.isOpened,
            })}
          >
            <div
              className={classNames(
                "dropdownContent",
                this.props.isInlineRow
                  ? styles.smallPaddingContainer
                  : styles.paddingContainer
              )}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
