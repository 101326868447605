import api from "../../utils/api";
import { ACTION } from "../index";
import * as constants from "../../constants";
import { mutationsQueryParams } from "../../utils/toSearchParams";

export const setMutationsFetched = (payload: any): ACTION => ({
  type: constants.SET_MUTATIONS_FETCHED,
  payload,
});

export const setOpenMutationsFetched = (payload: any): ACTION => ({
  type: constants.SET_OPEN_MUTATIONS_FETCHED,
  payload,
});

export const setCompleteMutationsFetched = (payload: any): ACTION => ({
  type: constants.SET_COMPLETE_MUTATIONS_FETCHED,
  payload,
});

export const fetchMutationTypes = () => {
  return async (dispatch) => {
    return api
      .get(`mutationtypes/`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_MUTATIONTYPES,
          payload: response["data"],
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchProfiles = () => {
  return async (dispatch) => {
    return api
      .get(`profiles/`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_PROFILES,
          payload: response["data"],
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchMutationById = (id) => {
  return async (dispatch) => {
    return api
      .get(`mutations/${id}/`)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const fetchOpenMutations = (params) => {
  return async (dispatch) => {
    return api
      .get(`mutations?page=size:10,page:0&filter=hideComplete`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_OPEN_MUTATIONS,
          payload: response["data"],
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchCompleteMutations = (params) => {
  return async (dispatch) => {
    return api
      .get(`mutations?page=size:10,page:0&filter=hideOpen`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_COMPLETE_MUTATIONS,
          payload: response["data"],
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchMutations = (length, page) => {
  const params = mutationsQueryParams(length, page);
  return async (dispatch) => {
    return api
      .get(`mutations${params}`)
      .then((response) => {
        // dispatch(fetchSettings());
        dispatch({
          type: constants.FETCHED_MUTATIONS,
          payload: response["data"],
          page: page,
        });
        dispatch({
          type: constants.FETCHED_MUTATION_COUNT,
          payload: response["meta"],
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const revertMutations = (reason, data) => {
  const params = reason && `?reason=${reason}`;
  return async () => {
    return api
      .post(`mutations${params}`, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchLocalMutations = (contentId, payload) => {
  return async (dispatch) => {
    dispatch({ type: constants.FETCH_UPDATE_MUTATIONS, contentId, payload });
  };
};

export const fetchCsv = () => {
  return async (dispatch) => {
    return api
      .get(`csv-imports/`)
      .then((response) => {
        dispatch({ type: constants.FETCHED_CSV, payload: response["data"] });
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchImportJobs = () => {
  return async (dispatch) => {
    return api
      .get(`import-jobs/`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_IMPORT_JOB_ERROR_MUTATIONS,
          payload: [],
        });
        dispatch({
          type: constants.FETCHED_IMPORT_JOBS,
          payload: response["data"],
        });
        const responseArray = response?.data as any;
        if (responseArray) {
          responseArray.forEach((element) => {
            const { hasError, isArchived, isCommitted } = element?.attributes;
            if (hasError && !isArchived && !isCommitted) {
              dispatch(fetchImportJobErrorMutations(element.id));
            }
          });
        }
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchImportJobErrorMutations = (id) => {
  return async (dispatch) => {
    return api
      .get(`import-jobs/${id}/errors`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_IMPORT_JOB_ERROR_MUTATIONS,
          payload: response["data"],
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const fetchImportJobState = (id) => {
  return async (dispatch) => {
    return api
      .get(`import-jobs/${id}`)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  };
};

export const uploadCsv = (data) => {
  return async (dispatch) => {
    return api
      .post(`csv-imports/`, data)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const deleteCsv = (id) => {
  return async (dispatch) => {
    return api
      .delete(`csv-imports/${id}/`)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const deleteImportJob = (id) => {
  return async (dispatch) => {
    return api
      .delete(`import-jobs/${id}/`)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const patchMutation = (data, mutationId, ignoreWarnings) => {
  return async (dispatch) => {
    dispatch({ type: constants.LOADING, payload: true });
    return api
      .patch(
        `mutations/${mutationId}/${
          ignoreWarnings ? "?ignoreWarnings=true" : ""
        }`,
        data
      )
      .then((response) => response)
      .catch((err) => err);
  };
};

export const deleteMutation = (mutationId, cascade) => {
  return async (dispatch) => {
    return api
      .delete(`mutations/${mutationId}/?cascade=${cascade}`)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const fetchInsurantMutations = (insurantId) => {
  return async (dispatch) => {
    return api
      .get(`insurants/${insurantId}/mutations/`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_INSURANT_MUTATIONS,
          payload: response["data"],
        });
        return response;
      })
      .catch((err) => err);
  };
};

export const commitMutations = (payload) => {
  return async (dispatch) => {
    return api
      .post(`commits/`, payload)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const patchCommitMutations = (commitId, payload) => {
  return async (dispatch) => {
    return api
      .patch(`commits/${commitId}`, payload)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const getCommits = (ssn) => {
  return async (dispatch) => {
    return api
      .get(`commits/`)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const deleteCommit = (commitId) => {
  return async (dispatch) => {
    return api
      .delete(`commits/${commitId}`)
      .then((response) => response)
      .catch((err) => err);
  };
};

export const fetchMutationsCount = () => {
  return async (dispatch) => {
    return api
      .get(`mutations?page=size:0,page:0&filter=hideComplete`)
      .then((response) => {
        dispatch({
          type: constants.FETCHED_MUTATION_COUNT,
          payload: response["meta"],
        });
        return response;
      })
      .catch((err) => err);
  };
};
