import * as React from 'react';
import {Button, ButtonColors, ButtonTypes} from "icr-shared-components";
import styles from "./DetailsPopup.module.scss";
import {ReactComponent as ArrowDownIcon} from "../../assets/icons/arrow-down.svg";
import classNames from "classnames";
import * as actions from "../../actions";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {ReactComponent as MailIcon} from "../../assets/icons/Icon_Mail.svg";
import {ReactComponent as PhoneIcon} from "../../assets/icons/Icon_Phone.svg";
import {getUser} from "../../actions/auth";

interface IProps {
    intl: any;
    getUser: any;
    username: string;
    employer?: string;
    className?: string;
}

interface IState {
    user: any;
    isOpen: boolean;
    dataLoaded: boolean;
}

class DetailsPopup extends React.Component<IProps, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            isOpen: false,
            dataLoaded: false,
            user: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
            }
        };
    }

    componentDidMount() {

    }

    onButtonClick = () => {
        const isOpen = this.state.isOpen;
        this.setState({isOpen: !isOpen});
        if(!isOpen && !this.state.dataLoaded) {
            this.props.getUser(this.props.username).then(res => {
                this.setState({dataLoaded: true, user: res.data.attributes});
            })
        }
    }

    render() {
        const {employer, className} = this.props;
        const {user, isOpen, dataLoaded} = this.state;
        return (
            <div className={classNames(styles.detailsPopupContainer, className, {[styles.isOpen]: isOpen})}>
                <Button
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={() => this.onButtonClick()}>
                    ABKLÄREN <ArrowDownIcon />
                </Button>
                <div className={styles.detailsPopupContent}>
                    {dataLoaded && <div>
                        <div className="mb-24">
                            <p><b>{employer}</b></p>
                            <p className="d-flex">
                                {(!user.firstName && !user.lastName) ?
                                    <span>Keine Kontaktinformationen verfügbar</span>
                                    : <span>{user.firstName} {user.lastName}</span>}
                            </p>
                        </div>
                        <p className="mb-12 d-flex">
                            <PhoneIcon className="mr-12" /> {user.phoneNumber || <span>&ndash;</span>}
                        </p>
                        <p className="d-flex">
                            <MailIcon className="mr-12" /> {user.email || <span>&ndash;</span>}
                        </p>
                    </div>}
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        getUser: bindActionCreators(getUser, dispatch)
    }
}


export default connect(null, mapDispatchToProps)(injectIntl(DetailsPopup));
