import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import FormUnpaidVacation from "../../../Forms/FormUnpaidVacation";
import styles from "./NewUnpaidVacationModalContent.module.scss";
import { Blank, ExpandableRow } from "icr-shared-components";
import UnpaidVacationExpandableRowTop from "./UnpaidVacationExpandableRowTop";

interface Props {
  payrolls: any;
  intl: IntlShape;
  initialData: any;
  disabled: boolean;
  submitAction: (event, value) => void;
  history: any;
  insurantMutations: any;

  unpaidVacations: any;
  onDeleteClick: (value: string) => void;
  onEditClick: (value: string) => void;
}

function NewUnpaidVacationModalContent({
  intl,
  unpaidVacations,
  onDeleteClick,
  onEditClick,
  ...props
}: Props) {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles["address-title"]}>
        <FormattedMessage
          id="app.newUnpaidVacation"
          defaultMessage="Neuer Unbezahlter Urlaub"
        />
      </h1>

      <div className={styles["form-wrapper"]}>
        <FormUnpaidVacation {...props} />
      </div>

      <h1 className={styles["course-title"]}>
        <FormattedMessage
          id="app.modals.title.newPayroll.course"
          defaultMessage="Verlauf"
        />
      </h1>

      <div className={styles["list-header"]}>
        <div className={styles.column}>
          <FormattedMessage id="app.from" defaultMessage="Von" />
        </div>
        <div className={styles.column}>
          <FormattedMessage id="app.to" defaultMessage="Bis" />
        </div>
        <div className={styles.column}>
          <FormattedMessage
            id="app.forms.unpaidVacation.savingsAndRiskContributions"
            defaultMessage="Spar- und Risikobeiträge"
          />
        </div>
        <div className={styles.column}>
          <FormattedMessage
            id="app.forms.unpaidVacation.riskContributionsOnly"
            defaultMessage="Nur Risikobeiträge"
          />
        </div>
        <div className={styles.column}>
          <div style={{ width: "7rem" }} />
        </div>
      </div>

      {unpaidVacations.length ? (
        unpaidVacations.map((unpaidVacation) => (
          <ExpandableRow
            className="mb-24"
            content={
              <UnpaidVacationExpandableRowTop
                unpaidVacation={unpaidVacation}
                onEditClick={() => onEditClick(unpaidVacation)}
                onDeleteClick={() => onDeleteClick(unpaidVacation)}
              />
            }
          />
        ))
      ) : (
        <Blank
          text={intl.formatMessage({
            id: "app.nounpaidvacations",
            defaultMessage: "Kein unbezahlter Urlaub vorhanden",
          })}
        />
      )}
    </div>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {};
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(NewUnpaidVacationModalContent));
