import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers/index';
import { bindActionCreators, Dispatch } from 'redux';
import * as actions from '../../actions/';
import {
    commitMutations,
    deleteCommit,
    fetchCompleteMutations,
    fetchMutations,
    fetchOpenMutations,
    patchCommitMutations, setMutationsFetched
} from '../../actions/arbeitgeber/mutations';
import {
    Blank,
    Button,
    ButtonColors,
    ButtonSizes,
    ButtonStyles,
    ButtonTypes,
    Card,
    Input,
    Status, StatusColors,
    StatusSize
} from 'icr-shared-components';
import Header from '../../components/Header';
import Modal from 'react-modal';
import { ProcessingStates } from '../../models';
import { getParameterByName } from '../../utils/toSearchParams';
import { ReactComponent as ArrowWhite } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowAction } from '../../assets/icons/arrow-right-action.svg';
import { ReactComponent as DocumentIcon } from '../../assets/icons/pdfdownload.svg';
import './Cockpit.scss';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { commitMutationsPayload } from "../../constants/payloads";
import { fetchEmployees } from "../../actions/arbeitgeber/insurants";
import classNames from "classnames";
import Drawer from "../../components/Drawer";
import { ReactComponent as IconFilter } from "../../assets/icons/filter.svg";
import { COCKPIT_MODAL_PAGES, mutationToFormMap } from "../../constants";
import { parseDate, prepareEmployersDropdownOptions, prepareEmployersForCommit } from "../../utils/react";
import CommitMutationsModal from "../../components/Arbeitgeber/Cockpit/CommitMutationsModal";
import CommitMutationsPopup from "../../components/Arbeitgeber/Cockpit/CommitMutationsPopup";
import SuccessModal from "../../components/Arbeitgeber/Cockpit/SuccessModal";
import PdfModal from "../../components/Arbeitgeber/Cockpit/PdfModal";
import CockpitMutationCard from "../../components/Arbeitgeber/Cockpit/CockpitMutationCard";
import CockpitInvoiceCard from "../../components/Arbeitgeber/Cockpit/CockpitInvoiceCard";
import SmallSpinner from "../../components/SmallSpinner";
import Spinner from "../../components/Spinner";
import {PdfProviders} from "../../components/PdfViewer";
import {fetchEmployers, filterInvoices, filterMutations, setInvoicesPageNo, setMutationsPageNo} from "../../actions/";
import {fetchInvoices, fetchOpenInvoices, setInvoicesFetched} from "../../actions/arbeitgeber/invoices";

interface State {
    commitId: string;
    loading: boolean;
    modalContent: string;
    modalIsOpen: boolean;
    pdfFileId: string;
    selected: any;
    provider: any;
    filtersOpened: boolean;
    pdfFilter: string;
    maxNumberOfCards: number;
}

interface Props {
    commitMutations: any;
    deleteCommit: any;
    employers: any;
    entities: any;
    history: any;
    invoices: Array<any>;
    locales: any;
    location: any;
    mutations: Array<any>;
    patchCommitMutations: any;
    setSidebar: any;
    sidebarOpened: boolean;
    intl: any;
    cockpitFirmaFilter: string;
    setFirmaFilter: any;
    popupIsOpen: any;
    setPopup: any;
    isLoading: any;
    setLoading: any;
    insurantsPageNo: any;
    filterByNamePerson: any;
    filterByFirmaPerson: any;
    filterByAlsoInactivePerson: any;
    filterByOnlyChangedPerson: any;
    mutationsPageNo: any;
    filterByNameMutations: any;
    filterByFirmaMutations: any;
    filterByTypeMutations: any;
    filterByAlsoClosedMutations: any;
    fetchEmployees: any;
    fetchEmployers: any;
    fetchMutations: any;
    openMutations: any;
    completeMutations: any;
    fetchOpenMutations: any;
    fetchCompleteMutations: any;
    openMutationsFetched: boolean;
    completeMutationsFetched: boolean;
    invoicesFetched: boolean;
    setMutationsPageNo: any;
    filterMutations: any;
    setMutationsFetched: any;
    filterInvoices: any;
    setInvoicesPageNo: any;
    fetchInvoices: any;
    fetchOpenInvoices: any;
    filterByStatusInvoices: any;
    setInvoicesFetched: any;
    openInvoicesFetched: any;
    openInvoices: any;
}

Modal.setAppElement('#root');
const hasInvoices = process.env.REACT_APP_INVOICES == "true";

class Cockpit extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            commitId: '00000000-0000-0000-0000-000000000000',
            modalContent: "",
            modalIsOpen: false,
            pdfFileId: "",
            loading: false,
            selected: [],
            provider: "",
            filtersOpened: false,
            pdfFilter: "hideOpen",
            maxNumberOfCards: window.innerWidth < 768 ? 2 : 3,
        }
    }

    static getDerivedStateFromProps(props, state) {
        // Update state so the next render will show the fallback UI.
        // console.log("getDerivedStateFromProps", props.location.search, state.eintrittErfassenForm, props.match.params.tab);
        if (state.modalIsOpen && props.location.search === '') {
            // Will hide modal as search is empty
            return {
                modalIsOpen: false
            };

        } else {

            let modalContentId = getParameterByName('edit', props.location.search);
            let modalContent = getParameterByName('content', props.location.search);
            let pdfFileId = getParameterByName('fileId', props.location.search);
            let modalIsOpen = !!(modalContentId || modalContent);
            let provider = getParameterByName('provider', props.location.search);
            let pdfFilter = getParameterByName('filter', props.location.search);

            return {
                modalContentId: modalContentId,
                modalContent: modalContent,
                pdfFileId: pdfFileId,
                modalIsOpen: modalIsOpen,
                provider: provider,
                pdfFilter: pdfFilter
            }
        }
    };

    private drawer = null;

    async componentDidMount() {
        this.fetchData();
        (document.activeElement as HTMLElement).blur();
        console.log('cockpit page did mount', this.props);
        document.body.classList.remove('backdrop');
        document.body.classList.add("cockpitPage");
        const modalContent = getParameterByName('content', this.props.location.search);
        const pdfFileId = getParameterByName('fileId', this.props.location.search)
        // var w = window.innerWidth;
        // var h = window.innerHeight;
        // var bottomHeight = h - 550;
        //
        // var maxNumberOfCards = Math.floor(bottomHeight / 106)
        // if (w <= 575) {
        //     this.setState({maxNumberOfCards: 3})
        // } else {
        //     if (maxNumberOfCards >= 2) {
        //         this.setState({maxNumberOfCards})
        //     }
        // }
        // window.addEventListener('resize', (event) => {
        //     var w = window.innerWidth;
        //     var h = window.innerHeight;
        //     var bottomHeight = h - 550;
        //
        //     var maxNumberOfCards = Math.floor(bottomHeight / 106)
        //     if (maxNumberOfCards !== this.state.maxNumberOfCards) {
        //
        //         if (maxNumberOfCards >= 2) {
        //             console.log("changed");
        //             if (w <= 575) {
        //                 this.setState({maxNumberOfCards: 3})
        //             } else {
        //                 if (maxNumberOfCards >= 2) {
        //                     this.setState({maxNumberOfCards})
        //                 }
        //             }
        //         }
        //     }
        // });

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (Object.keys(this.props.employers).length) {
            if (Object.keys(this.props.employers).length == 1 && !this.props.cockpitFirmaFilter) {
                this.props.setFirmaFilter((Object.values(this.props.employers)[0] as any).attributes.name);
            }
        }

        console.log("EMPLOYERS", this.props.employers);
    }

    componentWillUnmount() {
        document.body.classList.remove("cockpitPage");
    }

    fetchData = () => {
        if (!this.props.openMutationsFetched) {
            this.props.fetchOpenMutations();
        }
        if (!this.props.completeMutationsFetched) {
            this.props.fetchCompleteMutations();
        }
        if (!this.props.openInvoicesFetched) {
            this.props.fetchOpenInvoices();
        }
    }

    handleContentContainerClick = e => {

        if (!this.drawer.contains(e.target) && window.innerWidth < 1400) {
            this.setState({ filtersOpened: false });
        }

        if (!document.querySelector('.sidebar-container').contains(e.target)) {
            this.props.sidebarOpened && this.props.setSidebar(false);
        }
    }

    openModal = (content) => {
        this.setState({ modalIsOpen: true, modalContent: content });
        this.props.history.push({
            search: "?" + new URLSearchParams({ content }).toString()
        });
    }

    openCompletedMutationsPdfModal = () => {
        const filter = "hideOpen";
        this.setState({ provider: PdfProviders.MUTATIONS, pdfFilter: filter });
        this.props.history.push({
            search: "?" + new URLSearchParams({
                content: COCKPIT_MODAL_PAGES.PDF,
                provider: PdfProviders.MUTATIONS,
                filter: filter
            }).toString()
        });
    }

    openAllInvoicesPdfModal = () => {
        this.setState({ provider: PdfProviders.INVOICES });
        this.props.history.push({
            search: "?" + new URLSearchParams({
                content: COCKPIT_MODAL_PAGES.PDF,
                provider: PdfProviders.INVOICES,
            }).toString()
        });
    }

    openSingleFilePdfModal = (fileId) => {
        this.setState({ pdfFileId: fileId });
        this.props.history.push({
            search: "?" + new URLSearchParams({
                content: COCKPIT_MODAL_PAGES.PDF,
                fileId: fileId || ""
            }).toString()
        });
    }


    closeModal = () => {
        this.setState({ modalIsOpen: false });
        this.props.history.push({ search: '' });
    }

    commitMutations = (popup) => {
        const { employers, mutations } = this.props;
        const employersForCommit = prepareEmployersForCommit(employers, mutations);

        if (employersForCommit.length == 0) {
            return;
        }

        if (employersForCommit.length == 1) {
            this.setState({ selected: [employersForCommit[0]['value']] }, () => {
                this.props.setPopup(true);
            })
        } else if (employersForCommit.length > 1) {
            this.openModal(COCKPIT_MODAL_PAGES.COMMIT);
            this.setState({ loading: false })
        }
    }

    openConfirmationPopup = () => {
        this.props.setPopup(true);
    }

    commitMutationsPost = () => {
        if (this.state.selected.length <= 0) {
            return;
        }
        this.setState({ loading: true });
        this.props.setLoading(true);
        const payload = { ...commitMutationsPayload };
        payload.data.attributes.employers = this.state.selected;
        this.props.commitMutations(payload).then((res) => {
            let fileId = res && res.data ? res.data.attributes.summary.split('FileId=')[1] : null;
            if (fileId) {
                this.props.setPopup(false);
                this.props.setLoading(false);
                this.setState({
                    selected: [],
                    modalIsOpen: true,
                    modalContent: COCKPIT_MODAL_PAGES.SUCCESS,
                    pdfFileId: fileId,
                    loading: false,
                });
                this.props.history.push({
                    search: "?" + new URLSearchParams({ content: COCKPIT_MODAL_PAGES.SUCCESS, fileId }).toString()
                });
                this.props.fetchMutations(null, 0);
                this.props.fetchEmployees(null, 0);
                this.props.fetchOpenMutations();
                this.props.fetchCompleteMutations();
                this.props.fetchEmployers();
            } else {
                console.error('FileId is not defined', fileId);
            }
        })

    }

    massSelect = (options) => {
        console.log(options);
        if (this.state.selected.length == 0) {
            let selected = [];
            options.forEach(option => {
                selected.push(option.value);
            })
            this.setState({ selected: selected });
        } else {
            this.setState({ selected: [] });
        }
    }

    cancelCommit = () => {
        const { location } = this.props;
        this.setState({ selected: [] });
        this.props.history.push(location.state && location.state.previousPage ? location.state.previousPage : "/cockpit");
    }

    editMutation(no, type) {
        this.props.history.push(`/versicherte/mutationen?edit=${no}&content=${mutationToFormMap[type]}&previous=cockpit`);
    }

    openMutations = (mutations) => {
        return mutations.filter(mutation => {
            return this.props.cockpitFirmaFilter == "" || RegExp(this.props.employers?.[mutation.attributes['employerId']]?.['attributes']['name'], 'i')
                .test(this.props.cockpitFirmaFilter);
        });
    }

    completeMutations = (mutations) => {
        return mutations.filter(mutation => {
            return this.props.cockpitFirmaFilter == "" || RegExp(this.props.employers?.[mutation.attributes['employerId']]?.['attributes']['name'], 'i')
                .test(this.props.cockpitFirmaFilter);
        });
    }

    invoices = (invoices) => {
        return invoices.filter(invoice => {
            return (this.props.cockpitFirmaFilter == "" || RegExp(this.props.employers[invoice['attributes']['employerId']]['attributes']['name'], 'i')
                .test(this.props.cockpitFirmaFilter));
        });
    }

    limitNumberOfCards = (cards, number) => {
        return (cards.length && cards.length >= (number - 1)) ? cards.slice(0, number) : cards
    }

    private InvoiceStatusColors = {
        0: StatusColors.VERIFICATION,
        1: StatusColors.ERROR,
        2: StatusColors.VERIFICATION,
    };

    goToOpenMutations = () => {
        if (this.props.filterByAlsoClosedMutations) {
            this.props.filterMutations({ name: "alsoClosedCheckboxMutations", value: false });
            this.props.setMutationsPageNo(0);
            this.props.setMutationsFetched(false);
            // this.props.fetchMutations();
        }
        this.props.history.push("/versicherte/mutationen")
    }

    goToCompletedMutations = () => {
        if (!this.props.filterByAlsoClosedMutations) {
            this.props.filterMutations({ name: "alsoClosedCheckboxMutations", value: true });
            this.props.setMutationsPageNo(0);
            this.props.setMutationsFetched(false);
            // this.props.fetchMutations();
        }
        this.props.history.push("/versicherte/mutationen")
    }

    goToOpenInvoices = () => {
        if (this.props.filterByStatusInvoices != "Offen") {
            this.props.filterInvoices({ name: "statusFilter", value: "Offen" });
            this.props.setInvoicesPageNo(0);
            this.props.setInvoicesFetched(false);
            this.props.fetchInvoices()
        }
        this.props.history.push("/rechnungen")
    }

    render() {
        const { employers, mutations, openMutationsFetched, completeMutationsFetched, openInvoicesFetched } = this.props;
        const { maxNumberOfCards, modalContent } = this.state;
        const employerOptions = prepareEmployersDropdownOptions(employers);
        const employersForCommit = prepareEmployersForCommit(employers, mutations);
        const openMutations = this.limitNumberOfCards(this.openMutations(this.props.openMutations), maxNumberOfCards);
        const completeMutations = this.limitNumberOfCards(this.completeMutations(this.props.completeMutations), maxNumberOfCards);
        const openInvoices = this.limitNumberOfCards(this.invoices(this.props.openInvoices), maxNumberOfCards);
        const isCommitButtonDisabled = !!(!this.props.openMutations.length);

        const isCommitMutationsPage = modalContent === COCKPIT_MODAL_PAGES.COMMIT;
        const isSuccessPage = modalContent === COCKPIT_MODAL_PAGES.SUCCESS;
        const isPdfPage = modalContent === COCKPIT_MODAL_PAGES.PDF;

        return (
            <div className="main-container">
                <Header
                    cockpitPageHeader
                    title="Cockpit"
                    locales={this.props.locales}
                    onFiltersButtonClick={() => this.setState({ filtersOpened: !this.state.filtersOpened })} />

                <Modal isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Large Modal"
                    className="large-modal"
                    overlayClassName="large-modal-overlay">

                    {isCommitMutationsPage &&
                        <CommitMutationsModal
                            selected={this.state.selected}
                            employerOptions={employersForCommit}
                            onCancel={this.closeModal}
                            commitMutations={this.openConfirmationPopup}
                            massSelect={() => this.massSelect(employersForCommit)}
                            onSelect={(selected) => this.setState({ selected })} />}

                    {isSuccessPage &&
                        <SuccessModal
                            onClose={() => this.goToCompletedMutations()}
                            onPdfClick={() => this.state.pdfFileId && this.openSingleFilePdfModal(this.state.pdfFileId)} />}

                    {isPdfPage &&
                        <PdfModal
                            pdfFileId={this.state.pdfFileId}
                            provider={this.state.provider}
                            pdfFilter={this.state.pdfFilter}
                            onClose={this.closeModal} />}

                </Modal>

                <Modal isOpen={this.props.popupIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Small Modal"
                    className="small-modal"
                    overlayClassName="small-modal-overlay">

                    <CommitMutationsPopup
                        onSubmit={this.commitMutationsPost}
                        onCancel={() => this.props.setPopup(false)} />
                </Modal>

                <div className="main-content-container cockpit" onClick={(e) => this.handleContentContainerClick(e)}>
                    {this.state.loading && <Spinner relative />}
                    <div className="content-container">

                        <div className="cockpit-employer-filter-input">
                            <Input
                                type="text"
                                id="employer_filter_input"
                                name="employer_filter_input"
                                placeholder={this.props.intl.formatMessage({ id: "app.choosecompany", defaultMessage: "Firma wählen" })}
                                value={this.props.employers[this.props.cockpitFirmaFilter] ? this.props.employers[this.props.cockpitFirmaFilter]['attributes']['name'] : this.props.cockpitFirmaFilter}
                                onSelected={(name, value) => this.props.setFirmaFilter(value)}
                                onChange={(name, value) => this.props.setFirmaFilter(value)}
                                selectOptions={employerOptions}
                                filtering
                                disabled={employerOptions.length <= 2}
                                hideClearDropdownIcon={employerOptions.length <= 2}
                                hideArrowDropdownIcon={employerOptions.length <= 2}
                                sort>
                            </Input>
                        </div>


                        <div className="cockpit-columns">
                            <div className="cockpit-column">
                                <div className="cockpit-column-header">
                                    <h1 className="mb-16">
                                        <FormattedMessage
                                            id="app.openmutations"
                                            defaultMessage="Offene Mutationen" /></h1>
                                    <span className="mb-24">
                                        <FormattedMessage
                                            id="app.youhaveopenmutations"
                                            defaultMessage="Sie haben offene, noch nicht an die PK abgegebene Mutationen.
                                            Geben Sie die Änderungen ab, um Sie verarbeiten zu lassen."/></span>
                                    <Button
                                        icon={<ArrowWhite />}
                                        type={ButtonTypes.Standard}
                                        style={ButtonStyles.Primary}
                                        color={ButtonColors.Action}
                                        size={ButtonSizes.Full}
                                        isDisabled={isCommitButtonDisabled}
                                        className={classNames({ "disabled-cockpit-button": isCommitButtonDisabled })}
                                        onClick={() => this.commitMutations([])}>
                                        <span className="cockpit-button-text">
                                            <FormattedMessage id="app.submitchanges" defaultMessage="Änderung abgeben" />
                                        </span>
                                    </Button>
                                </div>
                                <div className="cockpit-column-content">
                                    {openMutationsFetched || openMutations.length ? <>
                                        {openMutations.length ?
                                            openMutations.map((mutation, index) =>
                                                <CockpitMutationCard
                                                    key={`cockpit-open-mutations-card-${index}`}
                                                    mutation={mutation}
                                                    onEditClick={() => this.editMutation(mutation['id'], mutation['attributes']['mutationType']['name'])}
                                                    onPdfClick={() => this.openSingleFilePdfModal(mutation.attributes.fileId)} />
                                            ) : <Blank
                                                onButtonClick={() => null}
                                                buttonText="Filter zurücksetzen"
                                                className="mb-32" text={this.props.intl.formatMessage({
                                                    id: "app.noopenmutations", defaultMessage: "Keine offenen Mutationen"
                                                })} />}
                                        <div className="cockpit-column-footer">
                                            <Button
                                                className={classNames("cockpit-column-mobile-button", { "disabled-cockpit-button": isCommitButtonDisabled })}
                                                icon={<ArrowWhite />}
                                                type={ButtonTypes.Standard}
                                                style={ButtonStyles.Primary}
                                                color={ButtonColors.Action}
                                                size={ButtonSizes.Full}
                                                isDisabled={isCommitButtonDisabled}
                                                onClick={() => this.commitMutations([])}>
                                                <FormattedMessage id="app.submitchanges" defaultMessage="Änderung abgeben" />
                                            </Button>
                                            {!!(openMutations.length) &&
                                                <Button
                                                    type={ButtonTypes.Text}
                                                    color={ButtonColors.Action}
                                                    onClick={this.goToOpenMutations}>
                                                    alle Offene Mutationen
                                                    <ArrowAction className="ml-16" />
                                                </Button>}
                                        </div>
                                    </> : <SmallSpinner cockpitSpinner />}
                                </div>
                            </div>

                            <div className="cockpit-column">
                                <div className="cockpit-column-header">
                                    <h1 className="mb-16">
                                        <FormattedMessage
                                            id="app.completedmutations"
                                            defaultMessage="Abgeschlossene Mutationen" />
                                    </h1>
                                    <span className="mb-24">
                                        <FormattedMessage
                                            id="app.yoursubmittedmutations"
                                            defaultMessage="Ihre zuletzt an die PK abgegebenen und bereits verarbeiteten Mutationen." />
                                    </span>
                                    <Button
                                        icon={<DocumentIcon />}
                                        type={ButtonTypes.Standard}
                                        style={ButtonStyles.Secondary}
                                        color={ButtonColors.Action}
                                        size={ButtonSizes.Full}
                                        onClick={() => this.openCompletedMutationsPdfModal()}>
                                        <span className="cockpit-button-text">
                                            Übersichts-PDF herunterladen
                                        </span>
                                    </Button>
                                </div>
                                <div className="cockpit-column-content">
                                    {completeMutationsFetched || completeMutations.length ? <>
                                        {completeMutations.length ?
                                            completeMutations.map((mutation, index) =>
                                                <CockpitMutationCard
                                                    key={`cockpit-complete-mutations-card-${index}`}
                                                    mutation={mutation}
                                                    onPdfClick={() => this.openSingleFilePdfModal(mutation.attributes.fileId)} />
                                            ) : <Blank
                                                onButtonClick={() => null}
                                                buttonText="Filter zurücksetzen"
                                                className="mb-32"
                                                text={this.props.intl.formatMessage({ id: "app.noprocessedmutations", defaultMessage: "Keine verarbeitete Mutationen" })} />}

                                        <div className="cockpit-column-footer">
                                            <Button
                                                className="cockpit-column-mobile-button"
                                                icon={<DocumentIcon />}
                                                type={ButtonTypes.Standard}
                                                style={ButtonStyles.Secondary}
                                                color={ButtonColors.Action}
                                                size={ButtonSizes.Full}
                                                onClick={() => this.openCompletedMutationsPdfModal()}>
                                                <span className="cockpit-button-text">
                                                    Übersichts-PDF herunterladen
                                                </span>
                                            </Button>
                                            {!!(completeMutations.length) &&
                                                <Button
                                                    type={ButtonTypes.Text}
                                                    color={ButtonColors.Action}
                                                    onClick={this.goToCompletedMutations}>
                                                    alle Abgeschlossene Mutationen
                                                    <ArrowAction className="ml-16" />
                                                </Button>}
                                        </div>
                                    </> : <SmallSpinner cockpitSpinner />}
                                </div>
                            </div>

                            {hasInvoices && <div className="cockpit-column">
                                <div className="cockpit-column-header">
                                    <h1 className="mb-16">
                                        <FormattedMessage id="app.openinvoices" defaultMessage="Offene Rechnungen" /></h1>
                                    <span className="mb-24">
                                        <FormattedMessage id="app.youropeninvoices" defaultMessage="Ihre offenen Rechnungen" />
                                    </span>
                                    <Button
                                        icon={<DocumentIcon />}
                                        type={ButtonTypes.Standard}
                                        style={ButtonStyles.Secondary}
                                        color={ButtonColors.Action}
                                        size={ButtonSizes.Full}
                                        onClick={() => this.openAllInvoicesPdfModal()}>
                                        <span className="cockpit-button-text">
                                            Übersichts-PDF herunterladen
                                        </span>
                                    </Button>
                                </div>
                                <div className="cockpit-column-content">
                                    {openInvoicesFetched || openInvoices.length ? <>
                                        {openInvoices.length ?
                                            openInvoices.map((invoice, index) =>
                                                <CockpitInvoiceCard
                                                    key={`cockpit-invoice-mutations-card-${index}`}
                                                    invoice={invoice}
                                                    onPdfClick={() => this.openSingleFilePdfModal(invoice.attributes.file)} />
                                            ) : <Blank
                                                onButtonClick={() => null}
                                                buttonText="Filter zurücksetzen"
                                                className="mb-32"
                                                text={this.props.intl.formatMessage({
                                                    id: "app.noopeninvoices", defaultMessage: "Keine offene Rechnungen"
                                                })} />}
                                        <div className="cockpit-column-footer">
                                            <Button
                                                className="cockpit-column-mobile-button"
                                                icon={<DocumentIcon />}
                                                type={ButtonTypes.Standard}
                                                style={ButtonStyles.Secondary}
                                                color={ButtonColors.Action}
                                                size={ButtonSizes.Full}
                                                onClick={() => this.openAllInvoicesPdfModal()}>
                                                <span className="cockpit-button-text">
                                                    Übersichts-PDF herunterladen
                                                </span>
                                            </Button>
                                            {!!(openInvoices.length) &&
                                                <Button
                                                    type={ButtonTypes.Text}
                                                    color={ButtonColors.Action}
                                                    onClick={() => this.goToOpenInvoices()}>
                                                    alle Offene Rechnungen
                                                    <ArrowAction className="ml-16" />
                                                </Button>}
                                        </div>
                                    </> : <SmallSpinner cockpitSpinner />}
                                </div>
                            </div>}
                        </div>


                    </div>

                    <div className="drawer cockpit-drawer" ref={node => this.drawer = node}>
                        <Drawer opened={this.state.filtersOpened}>
                            <div className="paddingContainer">
                                <Input
                                    type='text'
                                    id="firmaSelectCockpit"
                                    name="firmaSelectCockpit"
                                    placeholder={this.props.intl.formatMessage({
                                        id: "app.choosecompany",
                                        defaultMessage: "Firma wählen"
                                    })}
                                    onSelected={(name, value) => this.props.setFirmaFilter(value)}
                                    onChange={(name, value) => this.props.setFirmaFilter(value)}
                                    value={this.props.employers[this.props.cockpitFirmaFilter] ? this.props.employers[this.props.cockpitFirmaFilter]['attributes']['name'] : this.props.cockpitFirmaFilter}
                                    selectOptions={employerOptions}
                                    filtering
                                    sort>
                                </Input>
                            </div>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        entities: state.app.entities,
        invoices: state.invoices.invoices,
        employers: state.app.employers,
        locales: state.locales,
        isLoading: state.app.loading,
        popupIsOpen: state.app.popupIsOpen,
        sidebarOpened: state.app.sidebarOpened,
        cockpitFirmaFilter: state.app.cockpitFirmaFilter,

        insurantsPageNo: state.insurants.insurantsPageNo,
        filterByNamePerson: state.insurants.filterByNamePerson,
        filterByFirmaPerson: state.insurants.filterByFirmaPerson,
        filterByAlsoInactivePerson: state.insurants.filterByAlsoInactivePerson,
        filterByOnlyChangedPerson: state.insurants.filterByOnlyChangedPerson,

        mutations: state.mutations.mutations,
        openMutations: state.mutations.openMutations,
        completeMutations: state.mutations.completeMutations,
        mutationsPageNo: state.mutations.mutationsPageNo,
        filterByNameMutations: state.mutations.filterByNameMutations,
        filterByFirmaMutations: state.mutations.filterByFirmaMutations,
        filterByTypeMutations: state.mutations.filterByTypeMutations,
        filterByAlsoClosedMutations: state.mutations.filterByAlsoClosedMutations,

        completeMutationsFetched: state.mutations.completeMutationsFetched,
        openMutationsFetched: state.mutations.openMutationsFetched,
        invoicesFetched: state.invoices.invoicesFetched,
        openInvoices: state.invoices.openInvoices,
        openInvoicesFetched: state.invoices.openInvoicesFetched,
        filterByStatusInvoices: state.invoices.filterByStatusInvoices,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setMutationsFetched: (val) => dispatch(setMutationsFetched(val)),
        setFirmaFilter: (val) => dispatch(actions.setCockpitFirmaFilter(val)),
        setSidebar: (val) => dispatch(actions.setSidebar(val)),
        setPopup: (val) => dispatch(actions.setPopup(val)),
        setLoading: (val) => dispatch(actions.setLoading(val)),
        commitMutations: bindActionCreators(commitMutations, dispatch),
        patchCommitMutations: bindActionCreators(patchCommitMutations, dispatch),
        deleteCommit: bindActionCreators(deleteCommit, dispatch),
        fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
        fetchMutations: bindActionCreators(fetchMutations, dispatch),
        fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
        fetchEmployers: bindActionCreators(fetchEmployers, dispatch),
        fetchOpenMutations: bindActionCreators(fetchOpenMutations, dispatch),
        fetchCompleteMutations: bindActionCreators(fetchCompleteMutations, dispatch),
        fetchOpenInvoices: bindActionCreators(fetchOpenInvoices, dispatch),
        filterMutations: val => dispatch(filterMutations(val)),
        setMutationsPageNo: val => dispatch(setMutationsPageNo(val)),
        filterInvoices: val => dispatch(filterInvoices(val)),
        setInvoicesPageNo: val => dispatch(setInvoicesPageNo(val)),
        setInvoicesFetched: val => dispatch(setInvoicesFetched(val)),
        // fetchMutations: bindActionCreators(fetchMutations, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cockpit));
