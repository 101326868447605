import * as React from 'react';
import {Blank, ExpandableRow} from "icr-shared-components";
import {injectIntl} from "react-intl";
import classNames from "classnames";
import InsurantDetailsMutationRowContent from "../../Versicherte/InsurantDetailsMutationRowContent";
import styles from "./CompanyDetailsModalContent.module.scss";
import CompanyDetailsRowTop from "../CompanyDetailsRowTop";
import CompanyDetailsRowBottom from "../CompanyDetailsRowBottom";
import InvoicesExpandableRowTop from "../../Invoices/InvoicesExpandableRowTop";
import ExpandedRowMore from "../../Invoices/InvoicesExpandableRowMore";
import CompanyDetailsInvoiceRowTop from "../CompanyDetailsInvoiceRowTop";
import CompanyDetailsInvoiceRowBottom from "../CompanyDetailsInvoiceRowBottom";

interface Props {
    intl: any;
    onClose: any;
    companyData: any;
    onEditClick: any;
    onPdfClick: any;
    companyInvoices: any;
    filtersChanged: number;
}

interface State {
    expandedRow: any;
}

class CompanyDetailsModalContent extends React.Component<Props, State> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            expandedRow: null
        };
    }

    onClickMutationButton = () => {
        // const id = this.props.insurantData.
        // this.props.history.push("/pensionskasse/")
    }

    rowChanged = (expanded, row) => {
        this.setState({expandedRow: row});
        // if (expanded) {
        //     document.querySelector('.main-content-container.insurances').classList.add("expanded");
        // } else {
        //     document.querySelector('.main-content-container.insurances').classList.remove("expanded");
        // }
    }

    render() {
        const {companyData} = this.props;
        return (
            <div>
                <div className="mb-48 px-20">
                    <h1>Aktuelle Daten</h1>
                </div>
                <ExpandableRow className="overflowHiddenOff"
                    expandable
                    isExpanded
                    content={<CompanyDetailsRowTop company={this.props.companyData} />}
                    more={<CompanyDetailsRowBottom company={this.props.companyData} />}/>


                {/*<div className="d-flex align-items-center justify-content-between mt-58">*/}
                {/*    <h1>Rechnungsübersicht</h1>*/}
                {/*</div>*/}
                {/*<div className={styles.listView}>*/}
                {/*    {this.props.companyInvoices.length > 0 &&*/}
                {/*    <div className={styles.listHeader}>*/}
                {/*        <div className={styles.column}>*/}
                {/*            Rechngsnummer*/}
                {/*        </div>*/}
                {/*        <div className={styles.column}>*/}
                {/*            Rechnungsdatum*/}
                {/*        </div>*/}
                {/*        <div className={styles.column}>*/}
                {/*            Fälligkeitsdatum*/}
                {/*        </div>*/}
                {/*        <div className={styles.column}>*/}
                {/*            Betrag*/}
                {/*        </div>*/}
                {/*        <div className={styles.column}>*/}
                {/*            Status*/}
                {/*        </div>*/}
                {/*        <div className={styles.column}>*/}
                {/*            <div style={{width: "2.3rem"}} />*/}
                {/*        </div>*/}
                {/*    </div>}*/}

                {/*    {this.props.companyInvoices.length > 0 ?*/}
                {/*        this.props.companyInvoices.map((invoice, index) => {*/}
                {/*            return <ExpandableRow*/}
                {/*                index={index}*/}
                {/*                expandable={true}*/}
                {/*                translucent={false}*/}
                {/*                className="mb-24"*/}
                {/*                key={`insurants_row_${index}`}*/}
                {/*                expanded={this.state.expandedRow}*/}
                {/*                length={this.props.filtersChanged}*/}
                {/*                rowChanged={(expanded) => this.rowChanged(expanded, index)}*/}
                {/*                content={<CompanyDetailsInvoiceRowTop invoice={invoice} onPdfClick={() => this.props.onPdfClick(invoice)} />}*/}
                {/*                more={<CompanyDetailsInvoiceRowBottom invoice={invoice} /> }*/}
                {/*            />*/}
                {/*        }) : <Blank text={this.props.intl.formatMessage({*/}
                {/*            id: "app.noinsurants",*/}
                {/*            defaultMessage: "Keine Personen vorhanden"*/}
                {/*        })}/>}*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default injectIntl(CompanyDetailsModalContent);
