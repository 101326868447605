import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../reducers/index';
import {bindActionCreators, Dispatch} from 'redux';
import * as actions from '../../actions/';
import Header from '../../components/Header';
import Modal from 'react-modal';
import {getParameterByName} from '../../utils/toSearchParams';
import classNames from 'classnames';
import {ReactComponent as IconFilter} from '../../assets/icons/filter.svg';
import './Invoices.scss';
import {injectIntl} from 'react-intl';
import {fetchInvoices} from "../../actions/arbeitgeber/invoices";
import Spinner from "../../components/Spinner";
import InvoicesHeader from '../../components/Arbeitgeber/Invoices/InvoicesHeader'
import InvoiceList from '../../components/Arbeitgeber/Invoices/InvoiceList'
import InvoicesSidebar from '../../components/Arbeitgeber/Invoices/InvoicesSidebar'
import InvoicesModal from '../../components/Arbeitgeber/Invoices/InvoicesModal'
import PdfModal from "../../components/Arbeitgeber/Cockpit/PdfModal";
import {INVOICES_MODAL_PAGES} from "../../constants";
import PersonenTab from "../../components/Arbeitgeber/Versicherte/PersonenTab";
import Drawer from "../../components/Drawer";

interface State {
    statusFilter: string;
    firmaFilter: string;
    filtersOpened: boolean;
    filteredInvoices: any;
    expandedRowPresent: boolean;
    modalIsOpen:boolean;
    expandedRow: number;
    modalContent: string;
    pdf: boolean;
    pdfFileId: string;
    loading: any;
    filtersChanged: any;
}

interface Props {
    mutations: Array<any>;
    invoicesObj: Object;
    setSidebar: any;
    sidebarOpened: boolean;
    locales: any;
    intl: any;
    location: any;
    history: any;
    invoicesPageNo: any;
    moreInvoices: any;
    setInvoicesPageNo: any;
    fetchInvoices: any;
    invoicesFetched: boolean;
    fetchedEntities: boolean;
}

class Invoices extends React.Component<Props, State> {
    constructor(props : any) {
        super(props);
        document.body.classList.add("invoicesPage");
        let modalContent = '';
        let fileId = '';

        if (this.props.location.search && this.props.location.search.length > 0) {
            modalContent = getParameterByName('content', this.props.location.search);
            fileId = getParameterByName('fileId', this.props.location.search);
        }

        this.state = {
            statusFilter: "",
            firmaFilter: "",
            expandedRowPresent: false,
            filtersOpened: window.innerWidth >= 1400,
            filteredInvoices: null,
            expandedRow: null,
            modalContent: modalContent,
            modalIsOpen: modalContent ? true : false,
            pdf: false,
            pdfFileId: fileId !== 'undefined' ? fileId : null,
            loading: false,
            filtersChanged: 0
        }
    }

    drawer: any = null;
    private scrollableContainer = null;
    private scrollableContainerParent = null;

    static getDerivedStateFromProps(props, state) {
        // Update state so the next render will show the fallback UI.
        console.log("getDerivedStateFromProps", props.location.search, state.modalIsOpen, props.match.params.tab);
        if (state.modalIsOpen && props.location.search === '') {
            // Will hide modal as search is empty
            return {
              modalIsOpen: false,
            };
        } else {
            return {
                modalIsOpen: Boolean(getParameterByName('content', props.location.search)),
                modalContent: getParameterByName('content', props.location.search),
                pdfFileId: getParameterByName('fileId', props.location.search) !== "null" ? getParameterByName('fileId', props.location.search) : state.pdfFileId,
                pdf: getParameterByName('step', props.location.search) != "1",
            }
        }
    }

    viewPortChanged = (e) => {
        console.log("viewPortChanged", e);
        setTimeout(() => {
            if (e.target.innerWidth >= 1400 && (this.state.filtersOpened === false)) {
                this.setState({filtersOpened: true});
            }
        }, 500);
    }

    componentDidMount() {
        this.fetchData();
        (document.activeElement as HTMLElement).blur();
        console.log('main app did mount', this.props);
        document.body.classList.add("invoicesPage");
        document.body.classList.remove('backdrop');
        window.addEventListener("resize", this.viewPortChanged);
    }

    componentWillUnmount() {
        document.body.classList.remove("invoicesPage");
        window.removeEventListener("resize", this.viewPortChanged);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("invoices componenentDidUpdate", prevProps, prevState, snapshot);
    }

    fetchData = () => {
        if(!this.props.invoicesFetched) {
            this.props.fetchInvoices()
                .then(res => this.props.setInvoicesPageNo(1));
        }
    }

    loadMore = () => {
        const { moreInvoices } = this.props;
        if(moreInvoices) {
            this.setState({loading: true});
            this.props.fetchInvoices().then(res => {
                this.setState({loading: false});
                this.props.setInvoicesPageNo(this.props.invoicesPageNo + 1);
            });
        }
    }

    handleScroll = e => {
        const element = e.target;
        const { loading } = this.state;
        const bottom = Math.floor(element.scrollHeight - element.scrollTop) <= (element.clientHeight + 200) && Math.floor(element.scrollHeight - element.scrollTop) >= (element.clientHeight);
        if (bottom && !loading) {
            this.loadMore();
        }
    }

    rowChanged(expanded) {
        console.log("rowChanged in insurances", expanded);
        if (expanded) {
            this.setState({expandedRowPresent: true});
        } else {
            this.setState({expandedRowPresent: false});
        }
    }

    openModal = (content, fileId?) => {
        console.log("open modal: ", fileId);
        this.setState({modalIsOpen: true, modalContent: content, pdfFileId: fileId});
        this.props.history.push({
            search: "?" + new URLSearchParams({content, fileId}).toString()
        });
    }

    openSingleInvoicePdfModal = (fileId) => {
        this.props.history.push({
            search: "?" + new URLSearchParams({
                content: INVOICES_MODAL_PAGES.PDF,
                fileId: fileId || ""
            }).toString()
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
        this.props.history.push({ search: '' });
    }

    handleSetLoading = (value) => {
        this.setState({ loading: value, filtersChanged: this.state.filtersChanged + 1 });
    }

    render() {
        const isPdfPage = this.state.modalContent == INVOICES_MODAL_PAGES.PDF;
        const { loading } = this.state;
        const { invoicesFetched, fetchedEntities } = this.props;
        const isLoading = loading || !invoicesFetched;
        return (
            <div className="main-container">
                <Header
                    title="Rechnungen"
                    locales={this.props.locales}
                    onFiltersButtonClick={() => this.setState({ filtersOpened: !this.state.filtersOpened })} />

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Large Modal"
                    className={"large-modal"}
                    overlayClassName={"large-modal-overlay"}>

                        {isPdfPage &&
                        <PdfModal
                            provider={null}
                            pdfFilter={null}
                            pdfFileId={this.state.pdfFileId}
                            pdfLink={null}
                            onClose={this.closeModal}/>}
                </Modal>

                <div className="main-content-container">
                    <div className="relative-container">

                        {isLoading && <Spinner lowZIndex={!invoicesFetched && fetchedEntities} relative/>}

                        <div className="main-content-container-left"
                             onScroll={this.handleScroll}>

                            <div className="content-container">
                                <InvoiceList
                                    isLoading={isLoading}
                                    filtersChanged={this.state.filtersChanged}
                                    setLoading={this.handleSetLoading}
                                    openModal={(fileId) => this.openSingleInvoicePdfModal(fileId)}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="drawer" ref={node => this.drawer = node}>
                        <Drawer opened={this.state.filtersOpened}>
                            <InvoicesSidebar setLoading={this.handleSetLoading}/>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state : RootState, ownProps : any) {
    return {
        ...ownProps,
        fetchedEntities: state.app.fetchedEntities,
        mutations: state.mutations.mutations,
        sidebarOpened: state.app.sidebarOpened,
        invoicesObj: state.invoices.invoicesObj,
        locales: state.locales,
        invoicesPageNo: state.invoices.invoicesPageNo,
        moreInvoices: state.invoices.moreInvoices,
        invoicesFetched: state.invoices.invoicesFetched
    }
}

function mapDispatchToProps(dispatch : Dispatch < actions.ACTION >) {
    return {
        setSidebar: (val) => dispatch(actions.setSidebar(val)),
        setInvoicesPageNo: (val) => dispatch(actions.setInvoicesPageNo(val)),
        fetchInvoices: bindActionCreators(fetchInvoices, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Invoices));
