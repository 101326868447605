import React from "react";
import { getModalName, parseDate } from "../../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
} from "icr-shared-components";
import { ReactComponent as IconPdfDownload } from "../../../../assets/icons/pdfdownload.svg";
import styles from "./InsurantDetailsMutationExpadableRowTop.module.scss";
import { createMutationContent } from "../../../../utils/mutation";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Status, StatusColors } from "icr-shared-components";

interface Props {
  mutation: any;
  onDownloadClick: any;
  expanded: boolean;
  toggleExpand: () => void;
  setPopup: (item: boolean, employeeId: string, id: string) => void;
}

const InsurantDetailsMutationExpandableRowTop = ({
  mutation,
  onDownloadClick,
  toggleExpand,
  expanded,
  setPopup,
}: Props) => {
  const isEditable = !!(
    mutation.attributes.mutationType.name !== "Adresslöschung" &&
    mutation.attributes.mutationType.name !== "Lohnlöschung" &&
    mutation.attributes.mutationType.name !== "UnbezahlterUrlaublöschung"
  );

  const onEditClick = () => {};

  const hasMoreContent = !!createMutationContent(mutation).more.length;

  return (
    <div className={styles.insurantColumn}>
      <div className={styles.insurantDetailsMutationExpandableRowTop}>
        <div
          className={`${styles.column} ${
            mutation.attributes?.isReverted && "text-disabled"
          }`}
        >
          <span className={styles.mobileHeader}>Mutationstyp / Gültig ab</span>
          <p className="text-small">
            {mutation.attributes?.isRevert && (
              <span className="mr-6">
                <FormattedMessage
                  id="mutation.Revert.TypePrefix"
                  defaultMessage="Storno"
                />
              </span>
            )}
            {mutation.attributes.mutationType.translation}
          </p>
          <p>
            <b>{parseDate(mutation.attributes.keyDate)}</b>
          </p>
        </div>

        <div
          className={classNames(
            `${styles.column} ${
              mutation.attributes?.isReverted && "text-disabled"
            }`
          )}
        >
          {createMutationContent(mutation).content.map(
            ({ prevValue, value, title, translation }) => (
              <>
                <div
                  className={`${styles.mutationContentRow} ${
                    mutation.attributes?.isReverted && "text-disabled"
                  }`}
                >
                  <div className={styles.mutationContentRowLeft}>
                    <p className="text-small">
                      <FormattedMessage
                        id={translation}
                        defaultMessage={title}
                      />
                    </p>
                    <span className={styles.mobileHeader}>
                      <FormattedMessage
                        id={translation}
                        defaultMessage={title}
                      />{" "}
                      alt
                    </span>
                    <p>
                      <b>{prevValue}</b>
                    </p>
                  </div>
                  <div className={styles.mutationContentRowRight}>
                    <span className={styles.mobileHeader}>
                      <FormattedMessage
                        id={translation}
                        defaultMessage={title}
                      />{" "}
                      neu
                    </span>
                    <p>
                      <b>
                        {mutation.attributes?.isRevert ? (
                          <FormattedMessage
                            id="mutation.Revert.Text"
                            defaultMessage="Storniert"
                          />
                        ) : (
                          value
                        )}
                      </b>
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(styles.expandable, {
                    [styles.expanded]: expanded,
                  })}
                >
                  {createMutationContent(mutation).more.map(
                    ({ prevValue, value, title, translation }) => (
                      <div
                        className={classNames(
                          styles.mutationContentRow,
                          styles.more
                        )}
                      >
                        <div className={styles.mutationContentRowLeft}>
                          <p className="text-small">
                            <FormattedMessage
                              id={translation}
                              defaultMessage={title}
                            />
                          </p>
                          <span className={styles.mobileHeader}>
                            <FormattedMessage
                              id={translation}
                              defaultMessage={title}
                            />{" "}
                            alt
                          </span>
                          <p>
                            <b>
                              {mutation.attributes?.isRevert ? "-" : prevValue}
                            </b>
                          </p>
                        </div>
                        <div className={styles.mutationContentRowRight}>
                          <span className={styles.mobileHeader}>
                            <FormattedMessage
                              id={translation}
                              defaultMessage={title}
                            />{" "}
                            neu
                          </span>
                          <p>
                            <b>
                              {mutation.attributes?.isRevert ? (
                                <FormattedMessage
                                  id="mutation.Revert.Text"
                                  defaultMessage="Storniert"
                                />
                              ) : (
                                value
                              )}
                            </b>
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>

                {hasMoreContent && (
                  <Button
                    className="mt-16"
                    size={ButtonSizes.Small}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                    onClick={toggleExpand}
                  >
                    {expanded ? "Weniger" : "Mehr"}
                  </Button>
                )}
              </>
            )
          )}
        </div>

        <div
          className={`${styles.column} ${
            mutation.attributes.isReverted && "text-disabled"
          }`}
        >
          <span className={styles.mobileHeader}>
            Mutationsdatum / Bearbeiter
          </span>
          {/*<p className="text-small">CSV-Import</p>*/}
          <p>{parseDate(mutation.attributes.timeStamp)}</p>
          <p className="text-small">{mutation.attributes.username}</p>
        </div>

        <div className={styles.column}>
          <Button
            className="download-icon"
            type={ButtonTypes.Text}
            onClick={() => onDownloadClick()}
          >
            <IconPdfDownload />
          </Button>
        </div>
      </div>
      <div className={styles.insurantDetailsMutationExpandableRowTop}>
        {mutation.attributes.isRevertible && !mutation.attributes.isReverted ? (
          <Button
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={() => {
              setPopup(true, mutation.attributes.employeeId, mutation.id);
            }}
          >
            <FormattedMessage id="app.revert" defaultMessage="Stornieren" />
          </Button>
        ) : (
          mutation.attributes.isReverted && (
            <Status
              text={
                <FormattedMessage
                  id="mutation.Revert.Text"
                  defaultMessage="Storniert"
                />
              }
              color={StatusColors.DISABLED}
            />
          )
        )}
      </div>
    </div>
  );
};
export default InsurantDetailsMutationExpandableRowTop;
