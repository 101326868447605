import * as React from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

interface Props {
  intl: any;
  history: any;
}

function ResetPasswordMessage(props: Props) {
  const isPensionskasse = window.location.pathname.includes("/pensionskasse/");

  return (
    <div>
      <div className="login-title">
        <FormattedMessage
          id="app.resetpassword"
          defaultMessage="Passwort Zurucksetzen"
        />
      </div>
      <p className="text-left mt-3 pr-4">
        <FormattedMessage
          id="app.wesentanemail"
          defaultMessage="We sent an email with reset link to the following user"
        />
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Button
          submit
          className="mt-5"
          onClick={() =>
            props.history.push(
              isPensionskasse ? "/pensionskasse/login" : "/login"
            )
          }
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
        >
          <FormattedMessage id="app.submit" defaultMessage="Bestätigen" />
        </Button>
      </form>
    </div>
  );
}

export default withRouter(injectIntl(ResetPasswordMessage));
