import React, { useEffect, useState } from "react";
import "./Login.scss";
import { getParameterByName } from "../../utils/toSearchParams";
import "../../utils/jwtToken";
import { injectIntl } from "react-intl";
import LoginPage from "../../components/Arbeitgeber/Login/Login";
import ResetPassword from "../../components/Arbeitgeber/Login/ResetPassword";
import NewPassword from "../../components/Arbeitgeber/Login/NewPassword";
import Mfa from "../../components/Arbeitgeber/Login/Mfa";
import ResetPasswordMessage from "../../components/Arbeitgeber/Login/ResetPasswordMessage";
import { RootState } from "../../reducers";
import { connect } from "react-redux";
import DefaultBackground from "../../assets/login/default-background.png";
import ShpBackground from "../../assets/login/shp-background.jpg";
import { ReactComponent as ProPublicLogo } from "../../assets/login/propublic-logo.svg";
import { ReactComponent as KusLogo } from "../../assets/login/kus-logo.svg";
import { ReactComponent as ShpLogo } from "../../assets/login/shp-logo.svg";
import { ReactComponent as PKSHLogo } from "../../assets/login/pksh-logo.svg";
import { ReactComponent as PDBadge } from "../../assets/login/pd-badge.svg";
import { ReactComponent as ICRBadge } from "../../assets/login/icr-badge.svg";
import { ReactComponent as DefaultLogo } from "../../assets/icons/logo.svg";
import { eachYearOfInterval } from "date-fns/esm";

interface Props {
  location: any;
  history: any;
  intl: any;
}

function Login(props: Props) {
  const [passwordResetStep, setPasswordResetStep] = useState("0");

  useEffect(() => {
    document.body.classList.add("login");
    let user = "";
    let passwordResetStepVar = "0";

    if (props.location.search && props.location.search.length > 0) {
      // const url = new URL(props.location.search);
      // const url = new URL(window.location.href);
      // modalContentId = getParameterByName('edit', props.location.search);
      user = getParameterByName("user", props.location.search);
      passwordResetStepVar = getParameterByName(
        "password-reset",
        props.location.search
      );
      // tabId = Number(getParameterByName('tab', props.location.search));
    }

    setPasswordResetStep(passwordResetStepVar ? passwordResetStepVar : "0");
  }, []);

  useEffect(() => {
    if (window.location.search === "") {
      setPasswordResetStep("0");
    } else {
      if (getParameterByName("2fa", props.location.search) == "1") {
        setPasswordResetStep("3");
      } else {
        setPasswordResetStep(
          getParameterByName("password-reset", props.location.search) || "0"
        );
      }
    }

    return () => {
      document.body.classList.remove("login");
    };
  });

  const defaultTenant = "demo";
  const currentTenant = (
    localStorage.getItem("arbeitgeberportal_tenant") || defaultTenant
  ).toLowerCase();

  const tenants = {
    shp: {
      background: ShpBackground,
      logo: <ShpLogo />,
      isGradientBackground: false,
    },
    propublic: {
      background: DefaultBackground,
      logo: <ProPublicLogo />,
      isGradientBackground: true,
    },
    kus: {
      background: DefaultBackground,
      logo: <KusLogo />,
      isGradientBackground: true,
    },
    demo: {
      background: DefaultBackground,
      logo: <DefaultLogo />,
      isGradientBackground: true,
    },
    pksh: {
      background: DefaultBackground,
      logo: <PKSHLogo />,
      isGradientBackground: true,
    },
  };

  const background = tenants[currentTenant]?.background || DefaultBackground;
  const logo = tenants[currentTenant]?.logo || <DefaultLogo />;
  const isGradientBackground = tenants[currentTenant]?.isGradientBackground;

  return (
    <div
      className="container-fluid login-container"
      style={{
        backgroundImage: "url(" + background + ")",
        backgroundSize: isGradientBackground ? "100% 100%" : "cover",
      }}
    >
      <PDBadge className="pd-badge" />

      <div className="row">
        <div className="card mx-auto text-right">
          <div className="login-logo">{logo}</div>
          {passwordResetStep === "0" && <LoginPage />}
          {passwordResetStep === "1" && <ResetPassword />}
          {passwordResetStep === "2" && <NewPassword />}
          {passwordResetStep === "3" && <Mfa />}
          {passwordResetStep === "4" && <ResetPasswordMessage />}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
  ...ownProps,
  ...state.app,
  isLoading: state.app.loading,
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(Login));
