import {
  insurantsQueryParams,
  mutationsQueryParams,
} from "../../utils/toSearchParams";

const apiUrl = process.env.REACT_APP_REST_API_URL;

export const fetchMutationsPdf = (filter) => {
  let filtering =
    "filter" + mutationsQueryParams(null, null).split("filter")[1];
  if (filter) {
    filtering = "filter=" + filter;
  }
  return `${apiUrl}pdfdocuments/mutations/raw?${filtering}`;
};

export const fetchInsurantsPdf = (filter) => {
  let filtering =
    "filter" + insurantsQueryParams(null, null).split("filter")[1];
  if (filter) {
    filtering = "filter=" + filter;
  }

  return `${apiUrl}pdfdocuments/insurants/raw?${filtering}`;
};

export const fetchInvoicesPdf = () => {
  return `${apiUrl}pdfdocuments/invoices/raw`;
};

export const fetchPdfByFileId = (file) => {
  return `${apiUrl}pdfdocuments/raw/${file}`;
};
