import React from "react";
import {Button, ButtonColors, ButtonTypes} from "icr-shared-components";
import {ReactComponent as IconContact} from "../../../../assets/icons/personalien.svg";
import styles from "./CompaniesExpandableRowBottom.module.scss";
import {ReactComponent as EmployerIcon} from "../../../../assets/icons/arbeitgeber.svg";
import {ReactComponent as FirmenIcon} from "../../../../assets/icons/firmen.svg";

interface IProps {
    level?: any;
    companies: any;
    onDetailsClick: any;
}

const RecursiveRow = ({companies, level, onDetailsClick}: IProps) => {

    const current_level = level || 0;

    return (
        <>
        {companies && companies.map((company, index) => {
            const icon = company.isEmployer ? <EmployerIcon className="mr-12" /> : <FirmenIcon className="mr-12" /> ;
            return (
                <>
                    <div className={styles.companiesExpandableRowBottom}>
                        <div className={styles.column}>
                            <p style={{marginLeft: (current_level + 1) * 40 + "px"}}>
                                <p>{icon}</p>
                                <p>{company.name}</p>
                            </p>
                        </div>
                        <div className={styles.column}>
                            <p>{level}</p>
                        </div>
                        <div className={styles.column}>
                            <span className={styles.mobileHeader}>Arbeitgeber Nr.</span>
                            <p>{company.extEmployerId}</p>
                        </div>
                        <div className={styles.column}>
                            <span className={styles.mobileHeader}>Kassen Nr.</span>
                            <p>{company.description}</p>
                        </div>
                        <div className={styles.column}>
                            <Button
                                type={ButtonTypes.Text}
                                color={ButtonColors.Action}
                                onClick={() => onDetailsClick()}>
                                <IconContact/>
                            </Button>
                        </div>
                    </div>

                    <RecursiveRow
                        level={current_level + 1}
                        companies={company.childCompanies}
                        onDetailsClick={onDetailsClick}/>

                </>
            )
        })}
        </>
    )

}

export default RecursiveRow;
