import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../reducers/index';
import {bindActionCreators, Dispatch} from 'redux';
import * as actions from '../../../actions/';
import './Invoices.scss';
import {FormattedMessage, injectIntl} from 'react-intl';
import Header from "../../../components/Header";
import {Button, EButtonType} from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import {ReactComponent as IconArrowRight} from '../../../assets/icons/arrow-right.svg';
import Drawer from "../../../components/Drawer";
import List from "../../../components/Pensionskasse/Invoices/InvoicesList";
import Sidebar from "../../../components/Pensionskasse/Invoices/Sidebar";
import {fetchInvoices} from "../../../actions/pensionskasse/invoices";
import memoize from "memoize-one";
import {getParameterByName} from "../../../utils/toSearchParams";
import {INVOICES_MODAL_PAGES} from "../../../constants";
import PdfModal from "../../../components/Arbeitgeber/Cockpit/PdfModal";
import Modal from "react-modal";

interface State {
    filtersOpened: boolean;
    modalIsOpen: boolean;
    modalContent: string;
    pdfFileId: string;
    expandedRow: any;
    filtersChanged: number;
    loading: any;
}

interface Props {
    employers: any;
    history: any;
    invoices: any;
    intl: any;
    fetchInvoices: any;
    invoicesPageNo: any;
    setInvoicesPageNo: any;
    invoicesFetched: boolean;
}

class Invoices extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            filtersOpened: window.innerWidth >= 1400,
            modalIsOpen: false,
            modalContent: "",
            pdfFileId: "",
            expandedRow: null,
            filtersChanged: 0,
            loading: false
        }
    }

    private drawer = null;
    private scrollableContainerParent = null;

    componentDidMount() {
        this.fetchData();
        window.addEventListener("resize", this.viewPortChanged);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.viewPortChanged);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    static getDerivedStateFromProps(props, state) {
        // Update state so the next render will show the fallback UI.
        console.log("getDerivedStateFromProps", props.location.search, state.modalIsOpen, props.match.params.tab);
        if (state.modalIsOpen && props.location.search === '') {
            // Will hide modal as search is empty
            return {
                modalIsOpen: false,
            };
        } else {
            return {
                modalIsOpen: Boolean(getParameterByName('content', props.location.search)),
                modalContent: getParameterByName('content', props.location.search),
                pdfFileId: getParameterByName('fileId', props.location.search) !== "null" ? getParameterByName('fileId', props.location.search) : state.pdfFileId,
                pdf: getParameterByName('step', props.location.search) != "1",
            }
        }
    }

    fetchData = () => {
        if(!this.props.invoicesFetched) {
            this.props.fetchInvoices()
                .then(res => this.props.setInvoicesPageNo(1));
        }
    }

    viewPortChanged = (e) => {
        setTimeout(() => {
            if (e.target.innerWidth >= 1400 && (this.state.filtersOpened === false)) {
                this.setState({filtersOpened: true});
            }
        }, 500);
    }

    openModal = (content, fileId?) => {
        console.log("open modal: ", fileId);
        this.setState({modalIsOpen: true, modalContent: content, pdfFileId: fileId});
        this.props.history.push({
            search: "?" + new URLSearchParams({content, fileId}).toString()
        });
    }

    handleScroll = e => {
        const element = e.target;
        const bottom = Math.floor(element.scrollHeight - element.scrollTop) <= (element.clientHeight + 200) && Math.floor(element.scrollHeight - element.scrollTop) >= (element.clientHeight);
        if (bottom) {
            this.setState({loading: true}, () => {
                this.props.setInvoicesPageNo(this.props.invoicesPageNo + 1);
                this.props.fetchInvoices().then(res => this.setState({loading: false}));
            });
        }
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
        this.props.history.push({
            search: ''
        })
    }

    openSingleInvoicePdfModal = (fileId) => {
        this.props.history.push({
            search: "?" + new URLSearchParams({
                content: INVOICES_MODAL_PAGES.PDF,
                fileId: fileId || ""
            }).toString()
        });
    }

    handleRowChanged = (index) => {
        this.setState({expandedRow: index});
    }

    handleSetLoading = (value) => {
        this.setState({loading: value, filtersChanged: this.state.filtersChanged + 1});
    }

    handleOpenPdf = () => {
        this.setState({
            modalIsOpen: true
        })
    }

    prepareFirmaOptions = memoize((employers) => {
        let options = [];
        if (employers && Object.keys(employers).length) {
            Object.keys(employers).forEach((employerKey) => {
                options.push({
                    'dropdownLabel': employers[employerKey]['attributes']['name'],
                    'id': employers[employerKey].id
                })
            });
        }
        return options;
    });

    renderModal() {
        return (
            <div className="modal">
                <div className="modal-header">
                    <div className="modal-header-left">
                        {<Button className="primary"
                                 onClick={this.closeModal}
                                 to={null}
                                 buttonType={EButtonType.ButtonText}>
                            <div className="iconWrapper arrow left d-inline-block mr-3 small">
                                <IconArrowRight/>
                            </div>
                            <FormattedMessage id="app.backtooverview" defaultMessage="Zurück zur Übersicht"/>
                        </Button>}
                    </div>
                </div>

                <div className="modal-content">
                    <div className="Overview">
                        {/*<PdfViewer title="overview" fileId={this.state.pdfFileId} provider="archive"/>*/}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const firmaOptions = this.prepareFirmaOptions(this.props.employers);
        const isLoading = !this.props.invoicesFetched || this.state.loading;
        const isPdfPage = this.state.modalContent == INVOICES_MODAL_PAGES.PDF;
        return (
            <div className="main-container pensionskasse">

                <Header title="Rechnungen"
                        locales={null}
                        onFiltersButtonClick={() => this.setState({filtersOpened: !this.state.filtersOpened})}/>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Large Modal"
                    className={"large-modal"}
                    overlayClassName={"large-modal-overlay"}>
                    {isPdfPage &&
                    <PdfModal
                        provider={null}
                        pdfFilter={null}
                        pdfFileId={this.state.pdfFileId}
                        pdfLink={null}
                        onClose={this.closeModal}/>}
                </Modal>

                <div className="main-content-container pensionskasse-invoices">
                    <div className="relative-container">
                        { isLoading && <Spinner relative /> }

                        <div className="main-content-container-left pensionskasse"
                             onScroll={this.handleScroll}>

                            {this.props.invoicesFetched &&
                            <List
                                setLoading={this.handleSetLoading}
                                employers={this.props.employers}
                                invoices={this.props.invoices}
                                expandedRow={this.state.expandedRow}
                                filtersChanged={this.state.filtersChanged}
                                handleOpenPdf={(fileId) => this.openSingleInvoicePdfModal(fileId)}
                                handleRowChanged={(index) => this.handleRowChanged(index)}/>}
                        </div>
                    </div>

                    <div className="drawer" ref={node => this.drawer = node}>
                        <Drawer opened={this.state.filtersOpened}>
                            <Sidebar
                                firmaOptions={firmaOptions}
                                setLoading={(value) => this.handleSetLoading(value)}/>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        entities: state.app.entities,
        invoices: state.invoicesPK.invoices,
        invoicesFetched: state.invoicesPK.invoicesFetched,
        invoicesPageNo: state.invoices.invoicesPageNo
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
        setInvoicesPageNo: (val) => dispatch(actions.setInvoicesPageNo(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Invoices));
