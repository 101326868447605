import * as React from 'react';
import {Input, Button, ButtonTypes, ButtonColors, ButtonSizes} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as IconPdfDownload} from "../../../../assets/icons/pdfdownload.svg";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {fetchEmployees, setInsurantsFilter, setInsurantsPageNo} from "../../../../actions/pensionskasse/employee";
import {connect} from "react-redux";
import {fetchInvoices, setInvoicesFilter, setInvoicesPageNo} from "../../../../actions/pensionskasse/invoices";
import {ReactComponent as PdfIcon} from "../../../../assets/icons/download.svg";
import {prepareEmployersDropdownOptions, prepareMutationTypesDropdownOptions} from "../../../../utils/react";

interface State {

}

interface Props {
    intl: any;
    employers: any;
    firmaOptions: any;
    setLoading: any;
    invoicesPageNo: number;
    setInvoicesPageNo: any;
    fetchInvoices: any;
    setInvoicesFilter:  any;
    invoicesFirmaFilter: string;
    invoicesOnlyOpenFilter: any;
    invoicesOnlyPaidFilter: any;
    invoicesOnlyOverdueFilter: any;
}

class Sidebar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    private debounce = null;

    filterInvoices = (name, value, employers?) => {
        this.props.setInvoicesFilter({ name, value });

        if(employers === true && value !== "") {
            return;
        }

        if(this.props[name] == value) {
            return;
        }
        this.props.setInvoicesPageNo(0);
        this.props.setLoading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.props.fetchInvoices().then(() => {
                this.props.setLoading(false);
                this.props.setInvoicesPageNo(this.props.invoicesPageNo + 1);
            })
        }, 500);
    }

    render() {
        const {employers} = this.props;
        const employerOptions = prepareEmployersDropdownOptions(employers);
        return (
            <>
                <div className="paddingContainer">
                    <Input
                        id="invoicesFirmaFilter"
                        name="invoicesFirmaFilter"
                        key="invoicesFirmaFilter"
                        inputWrapperClass="text-input filter-input"
                        placeholder={"Firma wahlen"}
                        value={this.props.employers[this.props.invoicesFirmaFilter] ? this.props.employers[this.props.invoicesFirmaFilter]['attributes']['name'] : this.props.invoicesFirmaFilter}
                        onChange={(name, value) => this.filterInvoices(name, value, true)}
                        onSelected={(name, value) => this.filterInvoices(name, value)}
                        selectOptions={employerOptions}
                        validationMessageConfig={null/* this.validationMessageConfig() */}
                        type='text'
                        inputWrapperStyle={{}}>
                    </Input>
                    <p className="sidebar-header mb-32">
                        Rechnungsstatus
                    </p>
                    <Input
                        type='checkbox'
                        name="invoicesOnlyOverdueFilter"
                        checkboxLabel={"Nur überfällige zeigen"}
                        checked={this.props.invoicesOnlyOverdueFilter}
                        onChange={(name, value) => this.filterInvoices(name, value)}
                        inputWrapperClass="radio-input filter-input">
                    </Input>
                    <Input
                        type='checkbox'
                        name="invoicesOnlyOpenFilter"
                        checkboxLabel={"Nur offene zeigen"}
                        checked={this.props.invoicesOnlyOpenFilter}
                        onChange={(name, value) => this.filterInvoices(name, value)}
                        inputWrapperClass="radio-input filter-input">
                    </Input>
                    <Input
                        type='checkbox'
                        name="invoicesOnlyPaidFilter"
                        checkboxLabel={"Nur bezahlte zeigen"}
                        checked={this.props.invoicesOnlyPaidFilter}
                        onChange={(name, value) => this.filterInvoices(name, value)}
                        inputWrapperClass="radio-input filter-input">
                    </Input>

                    <Button
                        className="ml-auto"
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        size={ButtonSizes.Small}
                        onClick={() => this.filterInvoices('clear', 'clear')}>
                        <FormattedMessage id="app.resettodefault" defaultMessage="Zurücksetzen"/>
                    </Button>
                </div>



                {/*<div className="breaker"></div>*/}

                {/*<div className="sidebar-container">*/}
                {/*    <p className="sidebar-header mb-16 mt-16">*/}
                {/*        Aktionen*/}
                {/*    </p>*/}
                {/*    <div className="d-flex justify-content-between mt-32" onClick={() => null}>*/}
                {/*        <p><FormattedMessage id="app.pdflist" defaultMessage="Liste als PDF herunterladen"/></p>*/}
                {/*        <Button*/}
                {/*            type={ButtonTypes.Text}*/}
                {/*            color={ButtonColors.Action}*/}
                {/*            onClick={() => null}>*/}
                {/*            <PdfIcon/>*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }
}


function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        invoicesPageNo: state.invoicesPK.invoicesPageNo,
        invoicesFirmaFilter: state.invoicesPK.invoicesFirmaFilter,
        invoicesOnlyOpenFilter: state.invoicesPK.invoicesOnlyOpenFilter,
        invoicesOnlyPaidFilter: state.invoicesPK.invoicesOnlyPaidFilter,
        invoicesOnlyOverdueFilter: state.invoicesPK.invoicesOnlyOverdueFilter,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
        setInvoicesPageNo: (val) => dispatch(setInvoicesPageNo(val)),
        setInvoicesFilter: (val) => dispatch(setInvoicesFilter(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar));
