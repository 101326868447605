import apiCaller from "../../utils/api";
import { wrap } from "../index";
import * as constants from '../../constants';
import {inboxQueryParams, outboxQueryParams} from "../../utils/toSearchParams";
import api from "../../utils/api";

export const fetchInbox = (length, page) => {
    const params = inboxQueryParams(length, page);
    return async (dispatch) => {
        return api.get(`inbox${params}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_INBOX, "payload": response['data'] });
            }).catch(err => {
                dispatch({ "type": constants.FETCHED_INBOX, "payload": null });
            });
    }
};

export const fetchOutbox = (length, page) => {
    const params = outboxQueryParams(length, page);
    return async (dispatch) => {
        return api.get(`outbox${params}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_OUTBOX, "payload": response['data'] });
                return response;
            }).catch(err => {
                dispatch({ "type": constants.FETCHED_OUTBOX, "payload": null });
                return err;
            });
    }
};

export const initializeUpload = (data) => {
    return async (dispatch) => {
        return api.post(`files/`, data)
            .then(response => {
                dispatch({ "type": constants.INITIALIZED_UPLOAD, "payload": response["data"] });
                return response;
            }).catch(err => err);
    }
};

export const uploadPart = (fileId, data) => {
    return async (dispatch) => {
        return api.post(`files/${fileId}/parts/`, data)
            .then(response => {
                dispatch({ "type": constants.FILEPART_UPLOADED, "payload": response["data"] });
                return response;
            }).catch(err => err);
    }
};

export const uploadFile = (data) => {
    return async (dispatch) => {
        return api.post(`outbox/`, data)
            .then(response => {
                dispatch({"type": constants.FILE_UPLOADED, "payload": response["data"]});
                return response;
            }).catch(err => err);
    }
};

export const uploadExcel = (data ) => {
    return async (dispatch) => {
        return api.put(`excel-imports/00000000-0000-0000-0000-000000000000`, data)
            .then(response => {
                return response;
            }).catch(err => err);
    }
};

export const initializeDownload = (fileId) => {
    return async (dispatch) => {
        return api.post(`files/${fileId}/`)
            .then(response => {
                console.log(response);
                dispatch({ "type": constants.INITIALIZED_DOWNLOAD, "payload": response });
                return response;
            }).catch(err => err);
    }
};

export const downloadFile = (token) => {
    return async (dispatch) => {
        return api.post(`files?${token}`)
            .then(response => {
                dispatch({ "type": constants.FILE_DOWNLOADED, "payload": response['data'] });
                return response;
            }).catch(err => err);
    }
};

export const fetchDocumentTypes = () => {
    return async (dispatch) => {
        return api.get(`document-types/`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_DOCUMENT_TYPES, "payload": response['data'] });
                return response;
            }).catch(err => err);
    }
};
