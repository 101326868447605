import * as React from 'react';
import {Button, ButtonColors, ButtonStyles, ButtonTypes} from "icr-shared-components";
import {FormattedMessage} from "react-intl";
import {ReactComponent as IconCheckmarkSlim} from "../../../../assets/icons/checkmark-slim.svg";
import {mutationTypesToMessageMap} from "../../../../constants";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface Props extends RouteComponentProps{
    onClose: any;
    modalMessage: any;
    history: any;
}

interface IState {
}

class SuccessModalContent extends React.Component<Props, IState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        const isZivilstandMutation = this.props.modalMessage == mutationTypesToMessageMap.Zivilstandsaenderung;
        return (
            <div>
                <div className="modal-success-icon">
                    <IconCheckmarkSlim className="strokeWhite"/>
                </div>
                <div className="modal-success-title">
                    {this.props.modalMessage == '' ? 'Eintritt wurde hinzugefügt!' : this.props.modalMessage}
                </div>
                {isZivilstandMutation && <p className="w-50 mx-auto my-5 text-center">Sofern vorhanden, schicken Sie uns bitte einen Zivilstandsnachweis. Diesen können Sie als PDF im File-Transfer hochladen.</p>}
                <div className="modal-buttons">
                    <Button
                        className="mx-auto"
                        onClick={this.props.onClose}
                        type={ButtonTypes.Standard}
                        color={ButtonColors.Action}
                        style={ButtonStyles.Primary}>
                        <FormattedMessage id="app.tooverview" defaultMessage="Zur Übersicht"/>
                    </Button>
                </div>
                {isZivilstandMutation && <Button
                    className="mx-auto mt-5"
                    onClick={() => this.props.history.push("/filetransfer/gesendet?content=upload")}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}>
                    zum File-Transfer
                </Button>}
            </div>
        );
    }
}

export default withRouter(SuccessModalContent);
