import * as constants from "../../constants";

export interface MutationsState {
  mutations: any;
  mutationsAll: any;
  mutationsObj: any;
  openMutations: any;
  completeMutations: any;
  openMutationsFetched: any;
  completeMutationsFetched: any;
  mutationsPageNo: any;
  moreMutations: any;
  csv_imports: any;
  import_jobs: any;
  import_job_error_mutations: any;
  filterByNameMutations: string;
  filterByTypeMutations: string;
  filterByFirmaMutations: string;
  filterByAlsoClosedMutations: boolean;
  mutationCount: any;
  mutationsFetched: boolean;
  insurantMutations: any;
  total: number;
  warnings: number;
  errors: number;

  csv_imports_fetched: boolean;
  import_jobs_fetched: boolean;
}

const getInitialState = () => {
  return {
    mutations: [],
    mutationsAll: [],
    mutationsObj: {},
    openMutations: [],
    completeMutations: [],
    insurantMutations: [],
    csv_imports: [],
    import_jobs: [],
    import_job_error_mutations: [],
    mutationsPageNo: 0,
    moreMutations: true,
    filterByNameMutations: "",
    filterByTypeMutations: "",
    filterByFirmaMutations: "",
    filterByAlsoClosedMutations: false,
    mutationCount: {},
    mutationsFetched: false,
    openMutationsFetched: false,
    completeMutationsFetched: false,
    total: 0,
    warnings: 0,
    errors: 0,
    csv_imports_fetched: false,
    import_jobs_fetched: false,
  };
};

// console.log("app.ts", getInitialState());

const mutations = (state: MutationsState = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.SET_MUTATIONS_PAGE_NO: {
      let moreMutations =
        !(
          state.mutations.length <
          (state.mutationsPageNo + 1) * constants.PAGINAION_SIZE
        ) || state.mutationsPageNo == 0;
      return {
        ...state,
        mutationsPageNo: action.payload,
        moreMutations: moreMutations,
      };
    }

    case constants.FETCHED_OPEN_MUTATIONS: {
      return {
        ...state,
        openMutations: action.payload,
        openMutationsFetched: true,
      };
    }

    case constants.FETCHED_INSURANT_MUTATIONS: {
      return { ...state, insurantMutations: action.payload };
    }

    case constants.FETCHED_COMPLETE_MUTATIONS: {
      return {
        ...state,
        completeMutations: action.payload,
        completeMutationsFetched: true,
      };
    }

    case constants.FETCHED_CSV: {
      return {
        ...state,
        csv_imports: action.payload,
        csv_imports_fetched: true,
      };
    }

    case constants.FETCHED_IMPORT_JOBS: {
      return {
        ...state,
        import_jobs: action.payload,
        import_job_error_mutations: [],
        import_jobs_fetched: true,
      };
    }

    case constants.FETCHED_IMPORT_JOB_ERROR_MUTATIONS: {
      return {
        ...state,
        import_job_error_mutations:
          action?.payload && action?.payload?.length > 0
            ? [...action.payload, ...state.import_job_error_mutations]
            : action?.payload,
      };
    }

    case constants.FETCHED_MUTATION_COUNT: {
      return {
        ...state,
        total: action.payload.total,
        warnings: action.payload.warnings,
        errors: action.payload.errors,
      };
    }

    case constants.SET_MUTATIONS_FETCHED: {
      return { ...state, mutationsFetched: action.payload };
    }

    case constants.FETCHED_MUTATIONS: {
      var mutationsJson = {};
      var mutations = [];
      var mutationsObj = {};
      // Deep clone
      JSON.parse(JSON.stringify(action.payload)).forEach((mutation) => {
        mutationsJson[mutation.id] = mutation;
      });

      console.log(state.mutationsPageNo, action.page);

      if (state.mutationsPageNo == 0 || action.page == 0) {
        mutations = [...action.payload];
        mutationsObj = { ...mutationsJson };
      } else {
        mutations = [...state.mutations, ...action.payload];
        mutationsObj = { ...state.mutationsObj, ...mutationsJson };
      }

      return {
        ...state,
        mutations: mutations,
        mutationsObj: mutationsObj,
        mutationsFetched: true,
      };
    }

    case constants.FETCH_UPDATE_MUTATIONS: {
      return {
        ...state,
        mutations: state.mutations.map((item) =>
          item.id === action.contentId
            ? { ...item, attributes: action.payload }
            : item
        ),
        mutationsFetched: true,
      };
    }

    case constants.SET_FILTERING_CRITERIA_MUTATIONS: {
      if (action.payload.name === "clear") {
        return {
          ...state,
          filterByNameMutations: "",
          filterByFirmaMutations: "",
          filterByTypeMutations: "",
          filterByAlsoClosedMutations: false,
        };
      } else if (action.payload.name === "personSearchMutation") {
        return { ...state, filterByNameMutations: action.payload.value };
      } else if (action.payload.name === "firmaSelectMutations") {
        return { ...state, filterByFirmaMutations: action.payload.value };
      } else if (action.payload.name === "ereignisSelectMutations") {
        return { ...state, filterByTypeMutations: action.payload.value };
      } else if (action.payload.name === "alsoClosedCheckboxMutations") {
        return { ...state, filterByAlsoClosedMutations: action.payload.value };
      } else {
        return { ...state };
      }
    }

    case constants.LOG_OUT: {
      console.log("log out mutations");
      return { ...getInitialState() };
    }
  }

  return state;
};

export default mutations;
