import {ACTION, wrap} from "../index";
import apiCaller from "../../utils/api";
import * as constants from "../../constants/pensionskasse";
import {companiesQueryParams} from "../../utils/toSearchParams";
import api from "../../utils/api";

export const setCompaniesPageNo = (payload: boolean): ACTION => ({
    type: constants.SET_COMPANIES_PAGE_NO,
    payload
});

export const setCompaniesFilter = (payload: boolean): ACTION => ({
    type: constants.SET_COMPANIES_FILTERING,
    payload
});

export const fetchCompanies = (length, page) => {
    const params = companiesQueryParams(length, page);
    return async (dispatch) => {
        return api.get(`companies${params}`)
            .then(response => {
                dispatch({ "type": constants.FETCHED_COMPANIES, "payload": response['data'], "page": page });
            }).catch(err => err);
    }
};

export const fetchCompanyById = (id) => {
    return async (dispatch) => {
        return api.get(`companies/${id}/`)
            .then(response => response)
            .catch(err => err);
    }
};
