import React from "react";
import { formatNumber, parseDate } from "../../../../utils/react";
import styles from "./InsurantDetailsRowBottom.module.scss";
import classNames from "classnames";
import { mutationFormTypes } from "../../../../constants";
import { ReactComponent as IconPen } from "../../../../assets/icons/pen.svg";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Tooltip from "rc-tooltip";

interface Props {
  intl: any;
  entities: any;
  insurant: any;
  employers: any;
  onEditClick: any;
}

const InsurantDetailsRowBottom = ({
  insurant,
  entities,
  employers,
  onEditClick,
}: Props) => {
  const kassennummer =
    employers?.[insurant.employee.employerId]?.attributes.extParentId;
  const civilStatus =
    entities?.CivilStatus?.values[insurant.person?.civilStatus];
  const payroll =
    !!insurant.payroll?.grossSalary &&
    "CHF " + formatNumber(insurant.payroll.grossSalary);
  return (
    <div className={styles.insurantDetailsRowBottom}>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Versicherter Lohn
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(mutationFormTypes.Payroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{payroll}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Arbeitspensum
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(mutationFormTypes.Payroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>
          {insurant.payroll?.activityRate}
          {insurant.payroll?.activityRate ? "%" : null}
        </p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Eintrittsdatum
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(mutationFormTypes.Versicherteninfos)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{parseDate(insurant.employee?.entryDate)}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Zivilstand
          {/* <div
            className="toggle-child"
            onClick={() => onEditClick(mutationFormTypes.Zivilstand)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{civilStatus}</p>
      </div>
      <div className={styles.break} />
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Lohn gültig ab
          {/* <div
            className="toggle-child"
            onClick={() => onEditClick(mutationFormTypes.Payroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{parseDate(insurant.payroll?.validFrom)}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Bonus
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(mutationFormTypes.Payroll)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{insurant.payroll?.bonus}</p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Adresse
          {/* <div
            className="toggle-child"
            onClick={() => onEditClick(mutationFormTypes.Address)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>
          {insurant.address && (
            <span>
              {insurant.address?.address1 + " "}
              <br />
              {insurant.address?.zipCode + " "}
              {insurant.address?.city}
            </span>
          )}
        </p>
      </div>
      <div className={styles.column}>
        <span className={classNames(styles.headerText, "show-child-on-hover")}>
          Kassennummer
          {/* <div
            className="toggle-child show-on-mobile"
            onClick={() => onEditClick(mutationFormTypes.Versicherteninfos)}
          >
            <Tooltip
              trigger={["hover"]}
              placement="top"
              overlay={"Bearbeiten"}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <IconPen />
            </Tooltip>
          </div> */}
        </span>
        <p>{kassennummer}</p>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers,
  };
}

export default connect(
  mapStateToProps,
  null
)(injectIntl(InsurantDetailsRowBottom));
