import * as React from 'react';
import {ButtonColors, ButtonSizes, Input} from "icr-shared-components";
import {Button, ButtonTypes} from "icr-shared-components";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as IconPdfDownload} from "../../../../assets/icons/pdfdownload.svg";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {fetchEmployees, setInsurantsFilter, setInsurantsPageNo} from "../../../../actions/pensionskasse/employee";
import {connect} from "react-redux";
import {fetchCompanies, setCompaniesFilter, setCompaniesPageNo} from "../../../../actions/pensionskasse/companies";

interface State {

}

interface Props {
    intl: any;
    setLoading: any;
    companiesPageNo: any;
    fetchCompanies: any;
    setCompaniesFilter: any;
    setCompaniesPageNo: any;
    companiesNameFilter: any;
}

class Sidebar extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    private debounce = null;

    filterCompanies = (name, value) => {
        this.props.setCompaniesFilter({ name, value });
        if(this.props[name] == value) {
            return;
        }
        this.props.setCompaniesPageNo(0);
        this.props.setLoading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.props.fetchCompanies().then(() => {
                this.props.setLoading(false);
                this.props.setCompaniesPageNo(this.props.companiesPageNo + 1);
            })
        }, 500);
    }

    render() {
        return (
            <div className="paddingContainer">
                <p className="colorGrey mb-4">
                    <FormattedMessage id="app.searchpeople" defaultMessage="Personen suchen" />
                </p>
                <Input
                    id="personSearch"
                    name="companiesNameFilter"
                    key="personSearch"
                    inputWrapperClass="text-input filter-input"
                    placeholder={"Name, Nr., Bemerkung"}
                    value={this.props.companiesNameFilter}
                    onChange={(name, value) => this.filterCompanies(name, value)}
                    validationMessageConfig={null/* this.validationMessageConfig() */}
                    type='text'
                    inputWrapperStyle={{}}
                    filtering>
                </Input>

                <Button
                        className="ml-auto"
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        size={ButtonSizes.Small}
                        onClick={() => this.filterCompanies('clear', 'clear')}>
                        <FormattedMessage id="app.resettodefault" defaultMessage="Zurücksetzen"/>
                    </Button>
            </div>
        );
    }

}


function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        employers: state.app.employers,
        entities: state.app.entities,
        mutationTypes: state.app.mutationTypes,

        companiesPageNo: state.companies.companiesPageNo,
        companiesNameFilter: state.companies.companiesNameFilter,

    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
        setCompaniesPageNo: (val) => dispatch(setCompaniesPageNo(val)),
        setCompaniesFilter: (val) => dispatch(setCompaniesFilter(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar));
