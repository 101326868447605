import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, ButtonColors, ButtonSizes, ButtonTypes, Input} from "icr-shared-components";
import {RootState} from "../../../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../../../actions";
import {filterMutations, setMutationsPageNo} from "../../../../actions";
import {connect} from "react-redux";
import {prepareEmployersDropdownOptions, prepareMutationTypesDropdownOptions} from "../../../../utils/react";
import {fetchMutations} from "../../../../actions/arbeitgeber/mutations";
import {ReactComponent as PdfIcon} from "../../../../assets/icons/download.svg";
import DownloadIcon from "../../../DownloadIcon";

interface Props {
    intl: any;
    employers: any;
    mutationTypes: any;
    mutationsPageNo: any;
    fetchMutations: any;
    filterMutations: any;
    filterByNameMutations: any;
    filterByTypeMutations: any;
    filterByFirmaMutations: any;
    filterByAlsoClosedMutations: any;
    setMutationsPageNo: any;
    onFiltersChanged: any;
    setLoading: any;
    openPdfModal: any;
}

interface State {
    openedDropdown: any;
    expandedRow: any;
}

class MutationSidebar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openedDropdown: null,
            expandedRow: null
        }

    }

    private debounce = null;

    filterMutations = (name, value, employers?) => {
        this.props.filterMutations({name, value});

        if(employers === true && value !== "") {
            return;
        }

        this.props.setMutationsPageNo(0);
        this.props.setLoading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.props.fetchMutations().then(() => {
                this.props.setLoading(false);
                this.props.setMutationsPageNo(this.props.mutationsPageNo + 1);
            })
        }, 500);
    }

    handleMutationTypeInputChange = (name, value) => {
        if (this.props.filterByTypeMutations != value) {
            const mutationType = Object.values(this.props.mutationTypes).find(mutationType => mutationType["attributes"]["translation"] === value);
            if (mutationType) {
                this.filterMutations(name, mutationType["attributes"]["name"])
            } else {
                this.filterMutations(name, value)
            }
        }
    }

    render() {
        const {employers, mutationTypes} = this.props;
        const employerOptions = prepareEmployersDropdownOptions(employers);
        const mutationTypeOptions = prepareMutationTypesDropdownOptions(mutationTypes)
        const selectedMutationType = this.props.mutationTypes ? Object.values(this.props.mutationTypes).find(mutationType => mutationType["attributes"]["name"] === this.props.filterByTypeMutations) : null;

        return (
            <div>
                <div className="paddingContainer">
                    <p className="colorGrey mb-4">
                        <FormattedMessage id="app.searchmutations" defaultMessage="Mutationen suchen"/>
                    </p>

                    <Input
                        filtering
                        type="text"
                        id="personSearchMutation"
                        name="personSearchMutation"
                        key="personSearchMutation"
                        inputWrapperClass="text-input filter-input"
                        value={this.props.filterByNameMutations}
                        onChange={(name, value) => this.filterMutations(name, value)}
                        placeholder={this.props.intl.formatMessage({
                            id: "app.namebirthdayssn",
                            defaultMessage: "Name, Geburtstag, AHV-Nr."
                        })}/>

                    <Input
                        id="ereignisSelectMutations"
                        name="ereignisSelectMutations"
                        key="ereignisSelectMutations"
                        inputWrapperClass="text-input filter-input"
                        placeholder="Ereignis"
                        value={selectedMutationType ? selectedMutationType["attributes"]["translation"] : this.props.filterByTypeMutations}
                        onChange={(name, value) => this.filterMutations(name, value)}
                        onSelected={(name, value) => this.handleMutationTypeInputChange(name, value)}
                        onDropdownOpen={(isOpen) => this.setState({openedDropdown: isOpen ? 1 : null})}
                        onFocus={() => this.setState({openedDropdown: 1})}
                        onBlur={() => this.setState({openedDropdown: null})}
                        selectOptions={mutationTypeOptions}
                        isOpen={this.state.openedDropdown == 1}
                        type='text'
                        filtering
                        sort>
                    </Input>
                    <Input
                        id="firmaSelectMutations"
                        name="firmaSelectMutations"
                        key="firmaSelectMutations"
                        inputWrapperClass="dropdown-input filter-input"
                        placeholder="Firma wählen"
                        value={this.props.employers[this.props.filterByFirmaMutations] ? this.props.employers[this.props.filterByFirmaMutations]['attributes']['name'] : this.props.filterByFirmaMutations}
                        onSelected={(name, value) => this.props.filterByFirmaMutations != value && this.filterMutations(name, value)}
                        onChange={(name, value) => this.filterMutations(name, value, true)}
                        onDropdownOpen={(isOpen) => this.setState({openedDropdown: isOpen ? 2 : null})}
                        onFocus={() => this.setState({openedDropdown: 2})}
                        onBlur={() => this.setState({openedDropdown: null})}
                        selectOptions={employerOptions}
                        isOpen={this.state.openedDropdown == 2}
                        type='text'
                        sort>
                    </Input>
                    <Input
                        id="alsoClosedCheckboxMutations"
                        name="alsoClosedCheckboxMutations"
                        checked={this.props.filterByAlsoClosedMutations}
                        checkboxLabel={this.props.intl.formatMessage({
                            id: "app.alsoshowalsoshowcompletedmutations",
                            defaultMessage: "Auch abgeschlossene Mutationen zeigen"
                        })}
                        onChange={(name, value) => this.filterMutations(name, value)}
                        type='checkbox'
                        inputWrapperClass="checkbox-input filter-input">
                    </Input>
                    <Button
                        className="ml-auto"
                        type={ButtonTypes.Text}
                        color={ButtonColors.Action}
                        size={ButtonSizes.Small}
                        onClick={() => this.filterMutations('clear', 'clear')}>
                        <FormattedMessage id="app.resettodefault" defaultMessage="Zurücksetzen"/>
                    </Button>
                </div>
                <div className="breaker"></div>
                <div className="paddingContainer">
                    <p className="colorGrey mt-40 mb-4">
                        <FormattedMessage id="app.generatepdf" defaultMessage="PDF generieren"/>
                    </p>
                    <div className="d-flex justify-content-between mt-32 cursor-pointer"
                         onClick={() => this.props.openPdfModal()}>
                        <p><FormattedMessage id="app.mutationen.sidebar.mutationenList.pdfButton" defaultMessage="Liste als PDF herunterladen"/></p>
                        <DownloadIcon text="PDF" color="#145685" />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state: RootState, props: any) => ({
    ...props,
    mutationTypes: state.app.mutationTypes,
    employers: state.app.employers,
    mutations: state.mutations.mutations,
    mutationsPageNo: state.mutations.mutationsPageNo,
    filterByTypeMutations: state.mutations.filterByTypeMutations,
    filterByNameMutations: state.mutations.filterByNameMutations,
    filterByFirmaMutations: state.mutations.filterByFirmaMutations,
    filterByAlsoClosedMutations: state.mutations.filterByAlsoClosedMutations
});

const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
    filterMutations: val => dispatch(filterMutations(val)),
    setMutationsPageNo: val => dispatch(setMutationsPageNo(val)),
    fetchMutations: bindActionCreators(fetchMutations, dispatch)
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(injectIntl(MutationSidebar));
