import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import { parseDate, renderSwitch } from "../../../utils/react";
import "./FormLeaving.scss";
import { useForm } from "react-hook-form";
import { ValidationFormProps } from "../../../@types/forms/agp/formValidation";
import { fetchFormCustomization } from "../../../utils/form";
import { buildValidationBasedOnEmployerDates } from "../../../utils/validationTools";
import Spinner from "../../Spinner";

import Start from "./journeys/start";
import Termination from "./journeys/termination";
import DiseaseStep1 from "./journeys/disease/DiseaseStep1";
import DiseaseStep2 from "./journeys/disease/DiseaseStep2";

interface Props {
  checkFormValidity: any;
  civilStatusOptions?: any;
  closeModal: any;
  entities?: any;
  exitReasonOptions?: any;
  initialData: any;
  isLoading: boolean;
  submitAction: any;
  employers: any;
  insurantsObj: any;
  location: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
}

const FormLeaving = (props: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const { setValue, getValues } = useForm({
    criteriaMode: "all",
    defaultValues: {
      insurantDataPatch: {
        ...props.initialData,
        employee: {
          ...props.initialData.employee,
          entryDate: parseDate(props.initialData.employee.entryDate),
          reasonForLeaving: props.initialData.employee.reasonForLeaving
            ? props.initialData.employee.reasonForLeaving
            : "0",
          quittingDate: props.initialData.employee.quittingDate
            ? parseDate(props.initialData.employee.quittingDate)
            : "",
          unemployabilityDate: "",
          noticeGivenBy: "",
          checkInsuranceContinuation: false,
          invalidationApplicationFiled:
            props.initialData.employee.invalidationApplicationFiled,
        },
      },
    },
  });

  const [step, setStep] = useState("start");
  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [, setIsDisabledButton] = useState(true);
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      "formLeaving"
    );
    if (response.data) {
      const employerConfiguration =
        props.employers[props.initialData.employee.employerId];

      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("insurantDataPatch.employee")[key])
      );

      // Build custom frontend validation
      formComponentsCustomization = buildValidationBasedOnEmployerDates(
        employerConfiguration,
        formComponentsCustomization,
        "quittingDate"
      );

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: response["data"].attributes?.translations,
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            quittingDate: {
              required: true,
              date: {
                dayFilter: 0,
              },
            },
            reasonForLeaving: {
              required: true,
            },
            employeeCustomizable1: {
              required: false,
            },
            employeeCustomizable2: {
              required: false,
            },
            employeeCustomizable3: {
              required: false,
            },
            employeeCustomizable4: {
              required: false,
            },
            terminatedByEmployer: {
              required: false,
            },
            employeeNote: {
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          quittingDate: !!getValues("insurantDataPatch.employee.quittingDate"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("insurantDataPatch"),
        employee: {
          ...props.initialData.employee,
          entryDate: parseDate(props.initialData.employee.entryDate),
          reasonForLeaving: props.initialData.employee.reasonForLeaving
            ? props.initialData.employee.reasonForLeaving
            : "0",
          quittingDate: props.initialData.employee.quittingDate
            ? parseDate(props.initialData.employee.quittingDate)
            : "",
          unemployabilityDate: parseDate(
            props.initialData.employee.unemployabilityDate
          ),
        },
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues.employee);
  };

  const submitForm = (e) => {
    const form = getValues("insurantDataPatch");
    if (
      +form.employee.noticeGivenBy !== 2 ||
      !form.employee.employeeCustomizable1
    ) {
      delete form.employee.checkInsuranceContinuation;
    }
    if (+form.employee.reasonForLeaving === 1) {
      delete form.employee.noticeGivenBy;
      delete form.employee.checkInsuranceContinuation;
    }
    if (globalSettings.attributes.tenantCode === "PKSH") {
      delete form.employee.checkInsuranceContinuation;
    }
    if (!form.employee.invalidationApplicationFiled) {
      delete form.employee.invalidationApplicationFiled;
    } else {
      form.employee.invalidationApplicationFiled =
        form.employee.invalidationApplicationFiled === "0" ? true : false;
    }

    props.submitAction(e, form);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  const startProps = {
    formConfig,
    getValues,
    setValue,
    initialData: props.initialData,
    setFormConfig,
    closeModal: props.closeModal,
    showValidations: props.showValidations,
    setStep,
  };

  const termination = {
    formConfig,
    initialFormToCompare,
    getValues,
    setValue,
    initialData: props.initialData,
    setFormConfig,
    submitAction: submitForm,
    showValidations: props.showValidations,
    setStep,
  };

  const diseaseStep1Props = {
    formConfig,
    initialFormToCompare,
    getValues,
    setValue,
    initialData: props.initialData,
    submitAction: submitForm,
    setFormConfig,
    showValidations: props.showValidations,
    setStep,
  };

  const diseaseStep2Props = {
    formConfig,
    initialFormToCompare,
    getValues,
    setValue,
    initialData: props.initialData,
    submitAction: submitForm,
    setFormConfig,
    setStep,
  };

  return (
    <>
      {props.isLoading ? (
        <Spinner relative />
      ) : (
        renderSwitch(step, {
          start: () => <Start {...startProps} />,
          termination: () => <Termination {...termination} />,
          disease: () => <DiseaseStep1 {...diseaseStep1Props} />,
          diseasePlus28: () => <DiseaseStep2 {...diseaseStep2Props} />,
        })
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FormLeaving);
