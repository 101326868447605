import React from "react";
import {Button, ButtonTypes, Status, StatusColors} from "icr-shared-components";
import {formatNumber, isObjEmpty, parseDate, prepareEntitiesDropdownOptions} from "../../../../utils/react";
import {ReactComponent as IconPdfDownload} from "../../../../assets/icons/pdfdownload.svg";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {RootState} from "../../../../reducers";
import styles from "./InvoicesExpandableRowTop.module.scss";

interface Props {
    intl: any;
    invoice: any;
    entities: any;
    onPdfClick: any;
}

const InvoiceStatusColors = {
    0: StatusColors.HIGHLIGHT,
    1: StatusColors.ERROR,
    2: StatusColors.VERIFICATION,
};

const InvoicesExpandableRowTop = ({invoice, entities, onPdfClick}: Props) => {
    const status = entities?.InvoiceStates?.values?.[invoice.attributes.status];
    return (
        <div className={styles.invoicesExpandableRowTop}>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Rechngsnummer</span>
                <p><b>{invoice.attributes.invoiceName}</b></p>
            </div>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Rechnungsdatum</span>
                <p>{parseDate(invoice.attributes.invoiceDate)}</p>
            </div>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Fälligkeitsdatum</span>
                <p> {parseDate(invoice.attributes.dueDate)}</p>
            </div>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Betrag</span>
                <p>{invoice.attributes.amount && "CHF " + formatNumber(invoice.attributes.amount)}</p>
            </div>
            <div className={styles.column}>
                <span className={styles.mobileHeader}>Status</span>
                <Status text={status} color={InvoiceStatusColors[invoice.attributes.status]}/>
            </div>
            <div className={styles.column}>
                <Button
                    onClick={() => onPdfClick()}
                    type={ButtonTypes.Text}>
                    <IconPdfDownload />
                </Button>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        entities: state.app.entities
    }
}

const connector = connect(mapStateToProps, null);
export default connector(injectIntl(InvoicesExpandableRowTop));


