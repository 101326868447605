import {Button, ButtonTypes, Status, StatusColors} from "icr-shared-components";
import {parseDate} from "../../../../utils/react";
import DownloadIcon from "../../../DownloadIcon";
import React, {useEffect, useRef, useState} from "react";
import styles from "./SentExpandableRowContent.module.scss";
import {RootState} from "../../../../reducers";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import SmallSpinner from "../../../SmallSpinner";
import Checkmark from "../../../Checkmark";

const UploadStatusColors = {
    true: StatusColors.GREEN,
    false: StatusColors.BLACK,
};
const UploadStatusText = {
    true: "Heruntergeladen",
    false: "Neues Dokument",
};
interface Props {
    intl: any;
    file: any;
    employers: any;
    isDownloading: boolean;
    downloadFile: (arg: any) => void;
}
const SentExpandableRowContent = ({file, downloadFile, employers, isDownloading}: Props) => {
    const fileMimeType = file.attributes?.file?.mimeType?.split("/")[1].toUpperCase();
    const employerName = employers?.[file.attributes.employerId]?.attributes.name

    const [buttonVisible, setButtonVisible] = useState(true);
    const [checkmarkVisible, setCheckmarkVisible] = useState(false);

    const firstUpdate = useRef(true);

    useEffect(() => {
        if(isDownloading) {
            setButtonVisible(false);
        } else {
            if(!firstUpdate.current) {
                console.log("SHOW checkmark");
                setCheckmarkVisible(true);
                setTimeout(() => {
                    setCheckmarkVisible(false);
                    setButtonVisible(true);
                }, 1200);
            }
            firstUpdate.current = false;
        }

    }, [isDownloading]);

    return (
        <div className={styles.sentExpandableRowContent}>
            <div className={styles.column}>
                <p className="text-small">{file.attributes.documentType}</p>
                <p><b className="mr-2">{file.attributes.title} <span>({fileMimeType}, {Math.floor(file.attributes.file.size * 0.001)}KB)</span></b> </p>
            </div>
            <div className={styles.column}>
                <p>{employerName}</p>
            </div>
            <div className={styles.column}>
                <p>{parseDate(file.attributes.file.createdAt)}</p>
            </div>
            <div className={styles.column}>
                <p>{(file.attributes.file.creator === 'created automatically from file in archive' ||
                     !file.attributes.file.creator ? 'PD' : file.attributes.file.creator )}</p>
            </div>
            <div className={styles.column}>
                <Status
                    text={UploadStatusText[file.attributes.hasBeenDownloaded]}
                    color={UploadStatusColors[file.attributes.hasBeenDownloaded]}/>
            </div>
            <div className={styles.column}>
                {isDownloading && <SmallSpinner />}

                {buttonVisible &&
                <Button
                    className={!firstUpdate.current && "fade-in-animation"}
                    onClick={() => downloadFile(file.attributes.file.fileId)}
                    type={ButtonTypes.Text}>
                    <DownloadIcon
                        text={fileMimeType} color="#145685"/>
                </Button>}

                {checkmarkVisible && <Checkmark />}
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    ...ownProps,
    entities: state.app.entities,
    employers: state.app.employers
})
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(SentExpandableRowContent));
