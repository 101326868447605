import * as React from "react";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right-action.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import { parseDate, renderSwitch } from "../../../../utils/react";
import { Steps } from "icr-shared-components";
import FormNewEntry from "../../../Forms/FormNewEntry";
import FormCsvImport from "../../../Forms/FormCsvImport";
import FormExcelImport from "../../../Forms/FormExcelImport";
import PdfViewer from "../../../PdfViewer";
import { mutationFormTypes, modalPageTypes } from "../../../../constants";
import FormDeath from "../../../Forms/FormDeath";
import FormAddress from "../../../Forms/FormAddress";
import { ProcessingStates } from "../../../../models";
import FormSalary from "../../../Forms/FormSalary";
import FormCivilStatus from "../../../Forms/FormCivilStatus";
import FormLeaving from "../../../Forms/FormLeaving";
import FormUnpaidVacation from "../../../Forms/FormUnpaidVacation";
import FormRetirement from "../../../Forms/FormRetirement";
import FormInsurantInfo from "../../../Forms/FormInsurantInfo";
import { RootState } from "../../../../reducers";
import { Dispatch } from "redux";
import * as actions from "../../../../actions";
import { connect } from "react-redux";
import MutationsMenu from "../MutationsMenu";
import { withRouter } from "react-router-dom";
import SuccessModalContent from "../SuccessModalContent";
import InsurantDetailsModalContent from "../InsurantDetailsModalContent";
import InsurantDetailsModalContentPK from "../../../Pensionskasse/Versicherte/InsurantDetailsModalContent";
import CompanyDetailsModalContent from "../../../Pensionskasse/Companies/CompanyDetailsModalContent";
import { INSURANCES_TABS } from "../../../../constants/pensionskasse";
import FormIncapacity from "../../../Forms/FormIncapacity";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { ValidationFormProps } from "../../../../@types/forms/agp/formValidation";
import { fetchFormCustomization } from "../../../../utils/form";
import formName from "../../../../constants/forms/formName.json";
import FormExcel from "../../../Forms/FormExcel";
import BatchJob from "../../../Forms/BatchJob";
import NewAddressModalContent from "../NewAddressModalContent";
import NewPayrollModalContent from "../NewPayrollModalContent";
import NewUnpaidVacationModalContent from "../NewUnpaidVacationModalContent";
import AddEditOrRemoveSalary from "../../../Forms/FormSalary/Modals/AddEditOrRemoveSalary";

interface Props {
  intl: any;
  location: any;
  history: any;
  onClose: any;
  employers: any;
  entities: any;
  modalContent: any;
  entryFormStep: any;
  insurantData: any;
  backendValidation: any;
  insurantDataLoaded: boolean;
  validationMessageConfig: any;
  eintrittErfassenForm: any;
  currentTab?: any;
  provider: any;
  pdfFileId: any;
  modalMessage: any;
  modalContentId: any;
  selectedAddress: any;
  selectedPayroll: any;
  newAddress: any;
  newUnpaidVacation: any;
  selectedUnpaidVacation: any;
  unpaidVacations: any;
  payrolls: any;
  addresses: any;
  unpaidVacationsTableData: any;
  payrollsTableData: any;
  addressesTableData: any;
  onFormSubmit: any;
  loading: boolean;
  removeBackendValidation: any;
  nextEntryFormStep: any;
  previousEntryFormStep: any;
  resetEntryForm: any;
  insurantMutations: any;
  onEditClick: any;
  companyData: any;
  companyInvoices: any;
  companyDataLoaded: any;
  onPdfClick: any;
  onAddressEditClick: any;
  onAddressDeleteClick: any;
  onPayrollEditClick: any;
  onNewPayrollClick: any;
  payrollMode: any;
  onPayrollDeleteClick: any;
  onUnpaidVacationEditClick: any;
  onUnpaidVacationDeleteClick: any;
  submitExportExcel: any;
  onSubmitRevert;
  handlePayrollAddEditOrRemoveModal;
  openExcelOptions: any;
  excelGoBack: () => void;
  // excelImportSubmit: () => void;
  excelSuccessRedirect: () => void;
}

const ModalContent = ({
  intl,
  modalContent,
  history,
  entities,
  payrollMode,
  currentTab,
  insurantDataLoaded,
  companyDataLoaded,
  pdfFileId,
  provider,
  onEditClick,
  onClose,
  onFormSubmit,
  loading,
  onPdfClick,
  insurantData,
  insurantMutations,
  backendValidation,
  modalMessage,
  companyInvoices,
  companyData,
  entryFormStep,
  nextEntryFormStep,
  previousEntryFormStep,
  eintrittErfassenForm,
  resetEntryForm,
  payrolls,
  onPayrollDeleteClick,
  onPayrollEditClick,
  selectedPayroll,
  onNewPayrollClick,
  unpaidVacations,
  onUnpaidVacationDeleteClick,
  onUnpaidVacationEditClick,
  selectedUnpaidVacation,
  newUnpaidVacation,
  addresses,
  onAddressDeleteClick,
  onAddressEditClick,
  newAddress,
  selectedAddress,
  submitExportExcel,
  onSubmitRevert,
  openExcelOptions,
  handlePayrollAddEditOrRemoveModal,
  excelGoBack,
  excelSuccessRedirect,
}: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });

  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.mutationOptions"]
    );
    if (response.data) {
      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            formUnpaidVacation: {
              hidden: false,
            },
            formNewEntry: {
              hidden: false,
            },
            formSalary: {
              hidden: false,
            },
            formLeaving: {
              hidden: false,
            },
            formDisability: {
              hidden: false,
            },
            formCivilStatus: {
              hidden: false,
            },
            formRetirement: {
              hidden: false,
            },
            formDeath: {
              hidden: false,
            },
            formAddress: {
              hidden: false,
            },
            formInsurantInfo: {
              hidden: false,
            },
          },
          messages: {},
        },
      });
    }
  };

  const newEntryFormSteps = [
    {
      number: 1,
      text: intl.formatMessage({
        id: "app.personalinfo",
        defaultMessage: "Personalien",
      }),
    },
    {
      number: 2,
      text: intl.formatMessage({
        id: "app.insurance",
        defaultMessage: "Versicherung",
      }),
    },
    {
      number: 3,
      text: intl.formatMessage({
        id: "app.salary",
        defaultMessage: "Lohn",
      }),
    },
    {
      number: 4,
      text: intl.formatMessage({
        id: "app.address",
        defaultMessage: "Adresse",
      }),
    },
  ];

  const modalTitles = {
    [mutationFormTypes.NewEntry]: intl.formatMessage({
      id: "app.addnewentry",
      defaultMessage: "Eintritt erfassen",
    }),
    [mutationFormTypes.EditNewEntry]: intl.formatMessage({
      id: "app.editnewentry",
      defaultMessage: "Wiedereintritt",
    }),
    [mutationFormTypes.EditPayroll]: intl.formatMessage({
      id: "app.modals.title.editPayroll",
      defaultMessage: "Hochgerechneter AHV-Jahreslohn Bearbeiten",
    }),
    [mutationFormTypes.NewPayroll]: intl.formatMessage({
      id: "app.modals.title.createPayroll",
      defaultMessage: "Neuer hochgerechneter AHV-Jahreslohn",
    }),
    [mutationFormTypes.EditOrAddSalary]: intl.formatMessage({
      id: "app.modals.title.newPayroll.salary",
      defaultMessage: "Arbeitspensum/Lohn",
    }),
    [mutationFormTypes.RemoveSalary]: intl.formatMessage({
      id: "app.modals.title.newPayroll.salary",
      defaultMessage: "Arbeitspensum/Lohn",
    }),
    [mutationFormTypes.NewAddress]: intl.formatMessage({
      id: "app.newaddress",
      defaultMessage: "Neue Adresse",
    }),
    [mutationFormTypes.EditAddress]: intl.formatMessage({
      id: "app.editaddress",
      defaultMessage: "Adresse bearbeiten",
    }),
    [mutationFormTypes.Invalidity]: intl.formatMessage({
      id: "app.invalidity",
      defaultMessage: "Invalidität",
    }),
    [mutationFormTypes.Austritt]: intl.formatMessage({
      id: "app.exit",
      defaultMessage: "Austritt",
    }),
    [mutationFormTypes.NewUnpaidVacation]: intl.formatMessage({
      id: "app.newUnpaidVacation",
      defaultMessage: "Neuer Unbezahlter Urlaub",
    }),
    [mutationFormTypes.EditUnpaidVacation]: intl.formatMessage({
      id: "app.editunpaidvacation",
      defaultMessage: "Unbezahlter Urlaub bearbeiten",
    }),
    [mutationFormTypes.Employment]: intl.formatMessage({
      id: "app.employment",
      defaultMessage: "Anstellungsänderung",
    }),
    [mutationFormTypes.Pensionierung]: intl.formatMessage({
      id: "app.pension",
      defaultMessage: "Pensionierung",
    }),
    [mutationFormTypes.Versicherteninfos]: intl.formatMessage({
      id: "app.insuranceinfo",
      defaultMessage: "Versicherteninfos bearbeiten",
    }),
    [mutationFormTypes.Personnel]: intl.formatMessage({
      id: "app.unpaidvacation",
      defaultMessage: "Unbezahlter Urlaub",
    }),
    [mutationFormTypes.Zivilstand]: intl.formatMessage({
      id: "app.civilstate",
      defaultMessage: "Zivilstand",
    }),
    [mutationFormTypes.Todesfall]: intl.formatMessage({
      id: "app.death",
      defaultMessage: "Todesfall",
    }),
    "export-excel": intl.formatMessage({
      id: "app.generateExcel",
      defaultMessage: "Lohnliste Massenanpassung",
    }),
    [mutationFormTypes.Lohnrunde]: intl.formatMessage({
      id: "app.excel.lohnrunde",
      defaultMessage: "Lohnrunde erfassen (Stapelverarbeitung)",
    }),
    [mutationFormTypes.ExportExcel]: intl.formatMessage({
      id: "app.excel.excelExport",
      defaultMessage: "Excel-Datei erstellen",
    }),
    [mutationFormTypes.ImportExcel]: !loading
      ? intl.formatMessage({
          id: "app.excel.importExport",
          defaultMessage: "Excel-Datei importieren",
        })
      : " ",
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalClass = () => {
    if (
      modalContent == "Beschäftigungsgrad/Lohn" ||
      modalContent == "Adresse" ||
      modalContent == "Unbezahlter Urlaub" ||
      modalContent == "pdf" ||
      modalContent == modalPageTypes.INSURANT_DETAILS ||
      modalContent == modalPageTypes.INSURANT_DETAILS_PK ||
      modalContent == modalPageTypes.COMPANY_DETAILS ||
      modalContent == mutationFormTypes.NewPayroll ||
      modalContent == mutationFormTypes.NewAddress ||
      modalContent == mutationFormTypes.NewUnpaidVacation
    ) {
      return "modal-content";
    } else if (modalContent == "success") {
      return "modal-content success";
    } else {
      return "modal-content form";
    }
  };

  const renderModalContent = (content, message?) => {
    const isPensionskasse =
      history.location.pathname.includes("/pensionskasse/");

    return renderSwitch(
      content,
      {
        "": () => (
          <MutationsMenu
            onMenuItemClick={(id, type) => onEditClick(id, type)}
            formConfig={formConfig}
          />
        ),
        success: () => (
          <SuccessModalContent onClose={onClose} modalMessage={modalMessage} />
        ),
        pdf: () => (
          <div className="Overview">
            <PdfViewer
              closePdf={onClose}
              title="overview"
              fileId={pdfFileId}
              provider={provider}
            />
          </div>
        ),
        [modalPageTypes.INSURANT_DETAILS]: () =>
          insurantDataLoaded && (
            <InsurantDetailsModalContent
              onSubmitRevert={onSubmitRevert}
              onPdfClick={(id) => onPdfClick(id)}
              onEditClick={(id, content) => onEditClick(id, content)}
              onClose={onClose}
              entities={entities}
              insurantMutations={insurantMutations}
              insurantData={insurantData}
            />
          ),
        [modalPageTypes.INSURANT_DETAILS_PK]: () =>
          insurantDataLoaded && (
            <InsurantDetailsModalContentPK
              onPdfClick={(id) => onPdfClick(id)}
              onEditClick={(id, content) => onEditClick(id, content)}
              onClose={onClose}
              entities={entities}
              insurantMutations={insurantMutations}
              insurantData={insurantData}
            />
          ),
        [modalPageTypes.COMPANY_DETAILS]: () =>
          companyDataLoaded && (
            <CompanyDetailsModalContent
              filtersChanged={1}
              onPdfClick={() => null}
              onEditClick={(type) => onEditClick(type)}
              onClose={onClose}
              companyInvoices={companyInvoices}
              companyData={companyData}
            />
          ),
        [mutationFormTypes.Lohnrunde]: () => (
          <BatchJob
            importButton={() => openExcelOptions("import")}
            exportButton={() => openExcelOptions("export")}
          />
        ),
        [mutationFormTypes.ExportExcel]: () => (
          <FormExcel
            goBack={() => excelGoBack()}
            initialData={insurantData}
            backendValidation={backendValidation}
            submitAction={(form) => submitExportExcel(form)}
          />
        ),
        [mutationFormTypes.ImportExcel]: () => (
          <FormExcelImport
            goBack={() => excelGoBack()}
            loading={loading}
            closeModal={onClose}
            submitAction={(e, formObj) => onFormSubmit(e, formObj)}
          />
        ),
        [mutationFormTypes.ImportExcelSuccess]: () => (
          <SuccessModalContent
            onClose={excelSuccessRedirect}
            modalMessage={modalMessage}
          />
        ),
        [mutationFormTypes.CsvImport]: () => (
          <FormCsvImport
            closeModal={onClose}
            submitAction={(e, formObj) => onFormSubmit(e, formObj)}
          />
        ),
        [mutationFormTypes.NewEntry]: () =>
          (insurantDataLoaded || currentTab == INSURANCES_TABS.PersonenTab) && (
            <FormNewEntry
              isMutationsTab={currentTab == INSURANCES_TABS.MutationenTab}
              backendValidation={backendValidation}
              step={entryFormStep}
              closeModal={onClose}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              initialData={eintrittErfassenForm}
              reset={resetEntryForm}
              goToNextStep={nextEntryFormStep}
              goToPreviousStep={previousEntryFormStep}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Todesfall]: () =>
          insurantDataLoaded && (
            <FormDeath
              backendValidation={backendValidation}
              closeModal={onClose}
              initialData={insurantData}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.EditAddress]: () =>
          selectedAddress &&
          Object.keys(selectedAddress).length > 0 &&
          insurantDataLoaded && (
            <FormAddress
              backendValidation={backendValidation}
              closeModal={onClose}
              disabled={
                insurantData.employee.processingState ===
                  ProcessingStates.Committed ||
                insurantData.employee.processingState ===
                  ProcessingStates.Exported
              }
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              initialData={{
                ...selectedAddress,
                employerId: insurantData.employee.employerId,
                entryDate: insurantData.employee.entryDate,
              }}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        // We can probably use FormAddress here ?
        [mutationFormTypes.NewAddress]: () =>
          insurantDataLoaded && (
            <NewAddressModalContent
              addresses={Object.values(addresses)}
              onDeleteClick={onAddressDeleteClick}
              onEditClick={onAddressEditClick}
              closeModal={onClose}
              disabled={
                insurantData.employee.processingState ===
                  ProcessingStates.Committed ||
                insurantData.employee.processingState ===
                  ProcessingStates.Exported
              }
              initialData={{
                ...newAddress,
                employerId: insurantData.employee.employerId,
                entryDate: insurantData.employee.entryDate,
              }}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        // add new salary item
        [mutationFormTypes.EditOrAddSalary]: () =>
          insurantDataLoaded && (
            <AddEditOrRemoveSalary
              history={history}
              selectedPayroll={selectedPayroll}
              onNewPayrollClick={onNewPayrollClick}
              editButton={onPayrollEditClick}
              deleteButton={onPayrollDeleteClick}
              modalType={content}
            />
          ),
        [mutationFormTypes.RemoveSalary]: () =>
          insurantDataLoaded && (
            <AddEditOrRemoveSalary
              onNewPayrollClick={onNewPayrollClick}
              history={history}
              editButton={onPayrollEditClick}
              deleteButton={onPayrollDeleteClick}
              modalType={content}
            />
          ),
        [mutationFormTypes.EditPayroll]: () =>
          selectedPayroll &&
          Object.keys(selectedPayroll).length > 0 &&
          insurantDataLoaded && (
            <FormSalary
              backendValidation={backendValidation}
              closeModal={onClose}
              disabled={
                insurantData.employee.processingState ===
                  ProcessingStates.Committed ||
                insurantData.employee.processingState ===
                  ProcessingStates.Exported
              }
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              initialData={{
                ...selectedPayroll,
                employerId: insurantData.employee.employerId,
                entryDate: insurantData.employee.entryDate,
              }}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.NewPayroll]: () =>
          selectedPayroll &&
          Object.keys(selectedPayroll).length > 0 &&
          insurantDataLoaded && (
            <NewPayrollModalContent
              payrolls={Object.values(payrolls)}
              closeModal={onClose}
              disabled={
                insurantData.employee.processingState ===
                  ProcessingStates.Committed ||
                insurantData.employee.processingState ===
                  ProcessingStates.Exported
              }
              isDirty={payrollMode === "new" ? false : true}
              initialData={{
                id: payrollMode === "edit" ? selectedPayroll.id : "",
                validFrom:
                  payrollMode === "edit" ? selectedPayroll.validFrom : "",
                grossSalary:
                  payrollMode === "new" ? "" : selectedPayroll.grossSalary,
                activityRate:
                  payrollMode === "new" ? "" : selectedPayroll.activityRate,
                employerId: insurantData.employee.employerId,
                entryDate: insurantData.employee.entryDate,
              }}
              handlePayrollAddEditOrRemoveModal={
                handlePayrollAddEditOrRemoveModal
              }
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Zivilstand]: () =>
          insurantDataLoaded && (
            <FormCivilStatus
              backendValidation={backendValidation}
              closeModal={onClose}
              initialData={insurantData}
              history={history}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Austritt]: () =>
          insurantDataLoaded && (
            <FormLeaving
              backendValidation={backendValidation}
              closeModal={onClose}
              initialData={insurantData}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.EditNewEntry]: () =>
          insurantDataLoaded && (
            <FormNewEntry
              wiedereintritt
              backendValidation={backendValidation}
              step={entryFormStep}
              closeModal={onClose}
              goToNextStep={nextEntryFormStep}
              goToPreviousStep={previousEntryFormStep}
              initialData={eintrittErfassenForm}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.NewUnpaidVacation]: () =>
          insurantDataLoaded && (
            <NewUnpaidVacationModalContent
              unpaidVacations={Object.values(unpaidVacations)}
              closeModal={onClose}
              initialData={{
                ...newUnpaidVacation,
                employerId: insurantData.employee.employerId,
                entryDate: insurantData.employee.entryDate
              }}
              disabled={
                insurantData.employee.processingState ===
                  ProcessingStates.Committed ||
                insurantData.employee.processingState ===
                  ProcessingStates.Exported
              }
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
              onEditClick={onUnpaidVacationEditClick}
              onDeleteClick={onUnpaidVacationDeleteClick}
            />
          ),
        [mutationFormTypes.EditUnpaidVacation]: () =>
          selectedUnpaidVacation &&
          Object.keys(selectedUnpaidVacation).length > 0 &&
          insurantDataLoaded && (
            <FormUnpaidVacation
              backendValidation={backendValidation}
              closeModal={onClose}
              initialData={{
                ...selectedUnpaidVacation,
                employerId: insurantData.employee.employerId,
                entryDate: insurantData.employee.entryDate
              }}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              disabled={
                insurantData.employee.processingState ===
                  ProcessingStates.Committed ||
                insurantData.employee.processingState ===
                  ProcessingStates.Exported
              }
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Pensionierung]: () =>
          insurantDataLoaded && (
            <FormRetirement
              backendValidation={backendValidation}
              closeModal={onClose}
              initialData={insurantData}
              history={history}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Versicherteninfos]: () =>
          insurantDataLoaded && (
            <FormInsurantInfo
              backendValidation={backendValidation}
              closeModal={onClose}
              initialData={insurantData}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Employment]: () =>
          insurantDataLoaded && (
            <FormInsurantInfo
              backendValidation={backendValidation}
              closeModal={onClose}
              anstellungsanderung
              initialData={insurantData}
              deleteMutation={() => null}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Personnel]: () =>
          insurantDataLoaded && (
            <FormInsurantInfo
              personalienanderung
              backendValidation={backendValidation}
              closeModal={onClose}
              deleteMutation={() => null}
              initialData={insurantData}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
        [mutationFormTypes.Unemployability]: () =>
          insurantDataLoaded &&
          !isPensionskasse && (
            <FormIncapacity
              closeModal={onClose}
              initialData={insurantData}
              backendValidation={backendValidation}
              removeBackendValidationMessage={(
                formName,
                modelName,
                inputName
              ) => console.log(formName, modelName, inputName)}
              submitAction={(e, formObj) => onFormSubmit(e, formObj)}
            />
          ),
      }
      //   ,
      //   () => {
      //     const pathname = location.pathname;
      //     return <Redirect to={pathname} />;
      //   }
    );
  };

  const isEintrittForm = !!(
    modalContent == "Eintritt erfassen" || modalContent == "Wiedereintritt"
  );
  const isSuccessModal = modalContent == "success";
  const isCsvImportModal = modalContent == "CSV Importieren";
  const isPdfModal = modalContent == "pdf";
  const isDetailsModal =
    modalContent == modalPageTypes.INSURANT_DETAILS ||
    modalContent == modalPageTypes.INSURANT_DETAILS_PK ||
    modalContent == modalPageTypes.COMPANY_DETAILS;
  const showInsurantData =
    insurantDataLoaded &&
    !isEintrittForm &&
    !isPdfModal &&
    !isCsvImportModal &&
    !isSuccessModal &&
    !isDetailsModal;

  return (
    <div className="modal">
      {modalContent != "success" && (
        <div className="modal-header">
          <Button
            className={classNames({ "z-index-1000": modalContent === "pdf" })}
            onClick={onClose}
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
          >
            <ArrowRight className="mr-16 rotate-180" />
            <FormattedMessage
              id="app.backtooverview"
              defaultMessage="Zurück zur Übersicht"
            />
          </Button>

          <div className="d-flex mt-32 mt-md-0">
            {showInsurantData && (
              <>
                <p className="mr-16">
                  <b>
                    {insurantData.person.name +
                      " " +
                      insurantData.person.firstname}
                  </b>
                </p>
                <p className="mr-16">
                  {parseDate(insurantData.person.birthDate)}
                </p>
                <p>{insurantData.person.socialSecurityNumber}</p>
              </>
            )}
          </div>
        </div>
      )}

      {modalClass() != "modal-content" && (
        <div className="modal-sub-header">
          {modalContent !== "success" &&
            modalContent !== "ImportExcelSuccess" && (
              <div className="modal-sub-header-left">
                {modalTitles[modalContent] || "Was möchten Sie ändern?"}
              </div>
            )}
          {(isEintrittForm || modalContent == "details") && (
            <div className="modal-sub-header-right stepIndicator active">
              <Steps steps={newEntryFormSteps} current={entryFormStep} />
            </div>
          )}
        </div>
      )}

      <div className={modalClass()}>{renderModalContent(modalContent)}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState, props: any) => ({
  ...props,
  employers: state.app.employers,
  loading: state.app.loading,
  entities: state.app.entities,
  addresses: state.addresses.addresses,
  payrolls: state.payrolls.payrolls,
  unpaidVacations: state.unpaidvacations.unpaidVacations,
  insurantMutations: state.mutations.insurantMutations,
});

const mapDispatchToProps = (dispatch: Dispatch<actions.ACTION>) => ({
  //
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(injectIntl(ModalContent)));
