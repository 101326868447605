import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../actions";
import Header from "../../../components/Header";
import { injectIntl } from "react-intl";
import "./Cockpit.scss";
import { setSidebar } from "../../../actions";
import { StatisticsCircle } from "icr-shared-components";
import {
  fetchInvoices,
  setInvoicesFilter,
  setInvoicesPageNo,
} from "../../../actions/pensionskasse/invoices";
import {
  fetchAcceptedMutationsCockpit,
  fetchForReviewMutationsCockpit,
  fetchDeletedMutations,
  setAcceptedMutationsPageNo,
  setDeletedMutationsPageNo,
  setForReviewMutationsPageNo,
} from "../../../actions/pensionskasse/mutations";

interface State {
  modalIsOpen: boolean;
  modalFadeOut: boolean;
  filtersOpened: boolean;
}

interface Props {
  intl: any;
  history: any;
  sidebarOpened: any;
  setSidebar: any;
  invoices: any;
  forReviewMutationsCount: number;
  acceptedMutationsCount: number;
  fetchInvoices: any;
  setInvoicesPageNo: any;
  setInvoicesFilter: any;
  overdueInvoicesCount: number;
  paidInvoicesCount: number;
  openInvoicesCount: number;
  invoicesFetched: boolean;
  forReviewMutationsFetched: boolean;
  acceptedMutationsFetched: boolean;
  deletedMutations: boolean;
  fetchAcceptedMutationsCockpit: any;
  fetchForReviewMutationsCockpit: any;
  fetchDeletedMutations: any;
  setForReviewMutationsPageNo: any;
  setAcceptedMutationsPageNo: any;
  setDeletedMutationsPageNo: any;
  forReviewMutationsCountFetched: boolean;
  acceptedMutationsCountFetched: boolean;
  deletedMutationsCountFetched: boolean;
}

const hasInvoices = process.env.REACT_APP_INVOICES == "true";

class Cockpit extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalFadeOut: false,
      filtersOpened: window.innerWidth >= 1400,
    };
  }

  private drawer = null;

  componentDidMount(): void {
    this.fetchData();
  }

  componentWillUnmount() {}

  fetchData = () => {
    Promise.all([
      this.props.fetchInvoices(),
      this.props.fetchForReviewMutationsCockpit(),
      this.props.fetchAcceptedMutationsCockpit(),
    ]);
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openInvoicesWithAppliedStatusFilter = (status) => {
    this.props.setInvoicesFilter({ name: status, value: true });
    this.props.setInvoicesPageNo(0);
    this.props.fetchInvoices();
    this.props.history.push("/pensionskasse/rechnungen");
  };

  render() {
    let { overdueInvoicesCount, paidInvoicesCount, openInvoicesCount } =
      this.props;
    return (
      <div className="main-container pensionskasse">
        <Header title="Cockpit" locales={null} />

        <div className="main-content-container pensionskasse-cockpit">
          <div className="main-content-container-left pensionskasse">
            <div className="d-flex align-items-center justify-content-between first-row mb-16  px-md-0 px-4">
              <h1>Mutationen</h1>
            </div>
            <div className="cockpit-circles-row">
              <StatisticsCircle
                onClick={() =>
                  this.props.history.push(
                    "/pensionskasse/mutationen/uberprufung-erforderlich"
                  )
                }
                number={this.props.forReviewMutationsCount}
                header={"Prüfung erforderlich"}
                class={"pink"}
                to="/pensionskasse/mutationen/uberprufung-erforderlich"
              />

              <StatisticsCircle
                onClick={() =>
                  this.props.history.push(
                    "/pensionskasse/mutationen/ubernommen"
                  )
                }
                number={this.props.acceptedMutationsCount}
                header={"Verarbeitet"}
                class={"green"}
                to="/pensionskasse/mutationen/ubernommen"
              />

              <div
                style={{ width: "160px", height: "160px" }}
                className="d-none d-md-block"
              />
            </div>

            {hasInvoices && (
              <div className="d-flex align-items-center justify-content-between mb-16 px-md-0 px-4">
                <h1>Rechnungen</h1>
              </div>
            )}
            {hasInvoices && (
              <div className="cockpit-circles-row second-row">
                <StatisticsCircle
                  onClick={() =>
                    this.openInvoicesWithAppliedStatusFilter(
                      "invoicesOnlyOverdueFilter"
                    )
                  }
                  number={overdueInvoicesCount}
                  header={"Überfällig"}
                  class={"pink"}
                  to={"/link"}
                />

                <StatisticsCircle
                  onClick={() =>
                    this.openInvoicesWithAppliedStatusFilter(
                      "invoicesOnlyOpenFilter"
                    )
                  }
                  number={openInvoicesCount}
                  header={"Offen"}
                  class={"blue"}
                  to={"/link"}
                />

                <StatisticsCircle
                  onClick={() =>
                    this.openInvoicesWithAppliedStatusFilter(
                      "invoicesOnlyPaidFilter"
                    )
                  }
                  number={paidInvoicesCount}
                  header={"Bezahlt"}
                  class={"green"}
                  to={"/link"}
                />
              </div>
            )}
          </div>

          {/*<div className="drawer" ref={node => this.drawer = node}>*/}
          {/*    <Drawer opened={this.state.filtersOpened} >*/}
          {/*        <Sidebar />*/}
          {/*    </Drawer>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    sidebarOpened: state.app.sidebarOpened,
    invoices: state.invoicesPK.invoices,
    invoicesFetched: state.invoicesPK.invoicesFetched,
    forReviewMutationsFetched:
      state.forReviewMutations.forReviewMutationsFetched,
    acceptedMutationsFetched: state.acceptedMutations.acceptedMutationsFetched,
    deletedMutations: state.deletedMutations.deletedMutationsFetched,
    forReviewMutationsCount: state.forReviewMutationsCockpit.total,
    acceptedMutationsCount: state.acceptedMutationsCockpit.total,
    overdueInvoicesCount: state.invoicesPK.overdueInvoicesCount,
    paidInvoicesCount: state.invoicesPK.paidInvoicesCount,
    openInvoicesCount: state.invoicesPK.openInvoicesCount,
    forReviewMutationsCountFetched:
      state.forReviewMutations.forReviewMutationsCountFetched,
    acceptedMutationsCountFetched:
      state.acceptedMutations.acceptedMutationsCountFetched,
    deletedMutationsCountFetched:
      state.deletedMutations.deletedMutationsCountFetched,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    fetchInvoices: bindActionCreators(fetchInvoices, dispatch),
    fetchForReviewMutationsCockpit: bindActionCreators(
      fetchForReviewMutationsCockpit,
      dispatch
    ),
    fetchAcceptedMutationsCockpit: bindActionCreators(
      fetchAcceptedMutationsCockpit,
      dispatch
    ),
    fetchDeletedMutations: bindActionCreators(fetchDeletedMutations, dispatch),
    setSidebar: (val) => dispatch(setSidebar(val)),
    setInvoicesPageNo: (val) => dispatch(setInvoicesPageNo(val)),
    setInvoicesFilter: (val) => dispatch(setInvoicesFilter(val)),
    setForReviewMutationsPageNo: (val) =>
      dispatch(setForReviewMutationsPageNo(val)),
    setAcceptedMutationsPageNo: (val) =>
      dispatch(setAcceptedMutationsPageNo(val)),
    setDeletedMutationsPageNo: (val) =>
      dispatch(setDeletedMutationsPageNo(val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Cockpit));
