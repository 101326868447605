import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../reducers";
import * as actions from "../../../../actions";
import {
  ButtonColors,
  ButtonTypes,
  Button,
  Input,
  ButtonStyles,
} from "icr-shared-components";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { dateFilter, parseDate } from "../../../../utils/react";
import { ValidationMessage } from "../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../utils/validationTools";
import { EValidationTestType } from "../../../../utils/validationConfigs";
import memoize from "memoize-one";
import { ProcessingStates } from "../../../../models";

type Props = {
  formConfig: any;
  getValues: any;
  setValue: any;
  setFormConfig: any;
  setStep: any;
  closeModal: any;

  employers: any;
  entities: any;
  initialData: any;
  showValidations: boolean;
};

function Start(props: Props) {
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [requiredFields, setRequiredFields] = useState({});

  useEffect(() => {
    if (JSON.stringify(requiredFields) !== JSON.stringify({})) {
      setIsDisabledButton(
        !Object.keys(requiredFields).every((key) => {
          return requiredFields[key];
        })
      );
    } else {
      setIsDisabledButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFields]);
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      employee: {
        reasonForLeaving: props.initialData.employee.reasonForLeaving
          ? props.initialData.employee.reasonForLeaving
          : "0",
        quittingDate: props.initialData.employee.quittingDate
          ? parseDate(props.initialData.employee.quittingDate)
          : "",
      },
    },
  });

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      startRequiredFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formConfig.formCustomization, getValues("employee")]);

  const startRequiredFields = useCallback(() => {
    const requiredForStart = {};
    Object.keys({
      reasonForLeaving: "",
      quittingDate: "",
    }).map(
      (key) =>
        props.formConfig.formCustomization.components[key]?.required &&
        (requiredForStart[key] = !!getValues("employee")[key])
    );
    setRequiredFields(requiredForStart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, setValue, getValues("employee")]);

  const handleExitReasonRadioChange = (name, value) => {
    setValue(`employee.reasonForLeaving`, value);
    const validation: ValidationMessage = validateInputMessage(
      props.formConfig.formCustomization,
      getValues("employee"),
      "reasonForLeaving",
      null,
      requiredFields
    );
    setError(`employee.reasonForLeaving`, validation);
    validation?.valid && setRequiredFields(validation?.valid);
  };

  const prepareExitReasonOptions = memoize((entities) => {
    const options = [];
    if (
      entities &&
      entities.ReasonsForLeaving &&
      Object.keys(entities.ReasonsForLeaving.values).length
    ) {
      const leavingCodes = entities.ReasonsForLeaving.values;
      Object.keys(leavingCodes).forEach((leavingCodeKey) => {
        options.push({
          label: leavingCodes[leavingCodeKey],
          id: leavingCodeKey,
          value: leavingCodeKey,
          validationMessageConfig: {
            visible: true,
            config: {
              message: errors?.employee?.reasonForLeaving?.message,
              type: errors?.employee?.reasonForLeaving?.type,
            },
          },
          validationTests: [EValidationTestType.hasValue],
          type: "radio",
          name: "reasonForLeaving",
          inputWrapperClass: "w-100 high",
          onChange: handleExitReasonRadioChange,
        });
      });
    }
    return options;
  });

  const haveCustomValidationsQuittingDate = (
    formInput: string
  ): EValidationTestType[] => {
    const validations = [];
    !!props.employers[props.initialData.employerId]?.attributes
      .changesAllowedFrom && validations.push(EValidationTestType.dateBetween);
    !!props.formConfig.formCustomization.components[formInput]?.date
      ?.dayFilter &&
      validations.push(
        dateFilter(
          props.formConfig.formCustomization.components[formInput]?.date
            ?.dayFilter
        )
      );
    return validations;
  };

  const formJourney = {
    "0": "termination",
    "1": "disease",
  };

  return (
    <form
      className="form"
      onSubmit={(e) => {
        console.log("onsubmit", e);
        e.preventDefault();
      }}
    >
      <fieldset
        disabled={
          props.initialData.employee.processingState ===
            ProcessingStates.Committed ||
          props.initialData.employee.processingState ===
            ProcessingStates.Exported
        }
      >
        <div className="row">
          {/* Austrittsdatum */}
          <div className="col-12 col-sm-6">
            <Controller
              control={control}
              name="employee.quittingDate"
              render={({ field: { value } }) => (
                <Input
                  isFocused
                  type="date"
                  maxLength={10}
                  hidden={
                    props.formConfig.formCustomization.components[
                      "quittingDate"
                    ]?.hidden
                  }
                  inputWrapperClass="text-input"
                  id={"quittingDate"}
                  name={"quittingDate"}
                  placeholder=""
                  value={value && value.length >= 10 ? parseDate(value) : value}
                  onChange={(name, valueInput) => {
                    setValue("employee.quittingDate", valueInput);
                    const validation: ValidationMessage = validateInputMessage(
                      props.formConfig.formCustomization,
                      getValues("employee"),
                      "quittingDate",
                      haveCustomValidationsQuittingDate,
                      requiredFields
                    );
                    setError("employee.quittingDate", validation);
                    validation?.valid && setRequiredFields(validation?.valid);
                  }}
                  onBlur={() => {
                    const validation: ValidationMessage = validateInputMessage(
                      props.formConfig.formCustomization,
                      getValues("employee"),
                      "quittingDate",
                      haveCustomValidationsQuittingDate,
                      requiredFields
                    );
                    setError("employee.quittingDate", validation);
                    validation?.valid && setRequiredFields(validation?.valid);
                  }}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: errors?.employee?.quittingDate?.message,
                      type: errors?.employee?.quittingDate?.type,
                    },
                  }}
                >
                  <FormattedMessage
                    id="app.leavingdate"
                    defaultMessage="Austrittsdatum"
                  />
                  {props.formConfig.formCustomization.components["quittingDate"]
                    ?.required
                    ? " *"
                    : ""}
                </Input>
              )}
            />
          </div>

          {/* Austrittsgrund */}
          <div className="col-12 col-sm-6">
            <Controller
              control={control}
              name="employee.reasonForLeaving"
              render={({ field: { value } }) => (
                <Input
                  inputWrapperClass="radio-input"
                  id={"reasonForLeaving"}
                  hidden={
                    props.formConfig.formCustomization.components[
                      "reasonForLeaving"
                    ]?.hidden
                  }
                  name={"reasonForLeaving"}
                  radioOptions={prepareExitReasonOptions(props.entities)}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: errors?.employee?.reasonForLeaving?.message,
                      type: errors?.employee?.reasonForLeaving?.type,
                    },
                  }}
                  forcedValue={value.toString()}
                >
                  <FormattedMessage
                    id="app.reasonforleaving"
                    defaultMessage="Austrittsgrund"
                  />
                  {props.formConfig.formCustomization.components[
                    "reasonForLeaving"
                  ]?.required
                    ? " *"
                    : ""}
                </Input>
              )}
            />
          </div>

          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-md-6">
            <div className="modal-buttons">
              <Button
                onClick={props.closeModal}
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
              >
                <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
              </Button>
              <Button
                submit={true}
                type={ButtonTypes.Standard}
                color={ButtonColors.Action}
                style={ButtonStyles.Primary}
                isDisabled={isDisabledButton}
                // isDisabled={
                //   props.isLoading ||
                //   isDisabledButton ||
                //   !formHasChanged(
                //     initialFormToCompare,
                //     getValues("insurantDataPatch")
                //   )
                // }
                onClick={(e) => {
                  props.setValue("insurantDataPatch.employee", {
                    ...props.getValues("insurantDataPatch.employee"),
                    ...getValues("employee"),
                  });

                  props.setStep(
                    formJourney[getValues("employee.reasonForLeaving")]
                  );
                }}
              >
                <FormattedMessage id="app.continue" defaultMessage="" />
              </Button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Start);
