import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../../actions";
import Header from "../../../components/Header";
import { injectIntl } from "react-intl";
import "./Companies.scss";
import Drawer from "../../../components/Drawer";
import classNames from "classnames";
import Modal from "react-modal";
import Spinner from "../../../components/Spinner";
import {
  COMPANIES_MODAL_TABS,
  INSURANCES_TABS,
} from "../../../constants/pensionskasse";
import Sidebar from "../../../components/Pensionskasse/Companies/Sidebar";
import invoices from "../../../constants/DummyData/invoices.json";
import EmployerModal from "../../../components/Pensionskasse/Modal/EmployerModal";
import {
  fetchCompanies,
  fetchCompanyById,
  setCompaniesFilter,
  setCompaniesPageNo,
} from "../../../actions/pensionskasse/companies";
import CompaniesList from "../../../components/Pensionskasse/Companies/CompaniesList";
import ModalContent from "../../../components/Arbeitgeber/Versicherte/ModalContent";
import { mutationToFormMap, modalPageTypes } from "../../../constants";
import { minimumInsurantPayload } from "../../../constants/payloads";
import { isObjEmpty } from "../../../utils/react";
import { getParameterByName } from "../../../utils/toSearchParams";

interface State {
  modalIsOpen: boolean;
  modalFadeOut: boolean;
  currentTab: string;
  filtersOpened: boolean;
  modalContentId: any;
  isModalPopupOpen: boolean;
  filtersChanged: number;
  modalContent: any;
  modalInvoices: any;
  modalEvents: any;
  loading: boolean;
  companyData: any;
  companyDataLoaded: boolean;
  modalMessage: string;
}

interface Props {
  location: any;
  history: any;
  employers: any;
  entities: any;
  insurants: any;
  intl: any;
  companies: any;
  companiesFetched: boolean;
  moreCompanies: boolean;
  fetchCompanies: any;
  companiesPageNo: any;
  setCompaniesPageNo: any;
  companyInvoices: any;
  fetchCompanyById: any;
}

class Companies extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      filtersChanged: 0,
      modalIsOpen: false,
      modalFadeOut: false,
      modalContentId: "",
      filtersOpened: window.innerWidth >= 1400,
      currentTab: COMPANIES_MODAL_TABS.InvoicesTab,
      isModalPopupOpen: false,
      modalContent: null,
      modalInvoices: [],
      modalEvents: [],
      loading: false,
      companyData: {},
      companyDataLoaded: false,
      modalMessage: "",
    };
  }

  private drawer = null;
  private scrollableContainerParent = null;

  componentDidMount(): void {
    this.fetchData();
    let id = getParameterByName("edit", this.props.location.search);
    if (id) {
      this.props.fetchCompanyById(id).then((res) => {
        this.setState({
          companyData: res["data"],
          companyDataLoaded: true,
        });
      });
    }
    window.addEventListener("resize", this.viewPortChanged);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.viewPortChanged);
  }

  static getDerivedStateFromProps(props, state) {
    // Update state so the next render will show the fallback UI.
    // console.log("getDerivedStateFromProps", props.location.search, state.eintrittErfassenForm, props.match.params.tab);
    if (state.modalIsOpen && props.location.search === "") {
      // Will hide modal as search is empty
      return {
        modalIsOpen: false,
      };
    } else {
      let modalContentId = getParameterByName("edit", props.location.search);
      let modalContent = getParameterByName("content", props.location.search);
      let pdfFileId = getParameterByName("fileId", props.location.search);
      let modalIsOpen = !!(modalContentId || modalContent);
      let filtersChanged = state.filtersChanged + 1;
      let provider = getParameterByName("provider", props.location.search);

      return {
        modalContentId: modalContentId,
        modalContent: modalContent,
        modalIsOpen: modalIsOpen,
        pdfFileId: pdfFileId,
        filtersChanged: filtersChanged,
        provider: provider,
      };
    }
  }

  fetchData = () => {
    if (!this.props.companiesFetched) {
      this.props
        .fetchCompanies()
        .then((res) => this.props.setCompaniesPageNo(1));
    }
  };

  viewPortChanged = (e) => {
    setTimeout(() => {
      if (e.target.innerWidth >= 1400 && this.state.filtersOpened === false) {
        this.setState({ filtersOpened: true });
      }
    }, 500);
  };

  openModal = (id, content, message?) => {
    console.log("openModal", id, content);
    const { companies } = this.props;
    let entity: any = {};

    if (id) {
      entity = companies.find((company) => company.id == id);
    }
    console.log(entity);
    if ((id && !isObjEmpty(entity)) || !id) {
      this.setState(
        {
          modalMessage: message,
          companyData: entity,
          companyDataLoaded: !!id,
        },
        () => {
          this.props.history.push({
            pathname: `/pensionskasse/firmen`,
            search:
              "?" +
              new URLSearchParams({
                edit: id || "",
                content: content || "",
              }).toString(),
          });
        }
      );
    }
  };

  loadMore = () => {
    const { moreCompanies } = this.props;
    if (moreCompanies) {
      this.setState({ loading: true });
      this.props.fetchCompanies().then((res) => {
        this.setState({ loading: false });
        this.props.setCompaniesPageNo(this.props.companiesPageNo + 1);
      });
    }
  };

  handleScroll = (e) => {
    const element = e.target;
    const { loading } = this.state;
    const bottom =
      Math.floor(element.scrollHeight - element.scrollTop) <=
        element.clientHeight + 200 &&
      Math.floor(element.scrollHeight - element.scrollTop) >=
        element.clientHeight;
    if (bottom && !loading) {
      this.loadMore();
    }
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    this.props.history.push({ pathname: `/pensionskasse/firmen`, search: "" });
  };

  activateTab = (tab) => {
    this.setState({ currentTab: tab });
  };

  handleRowClick = (company) => {
    this.setState({
      modalIsOpen: true,
      modalContent: company,
      modalInvoices: invoices.data,
      modalEvents: [],
    });
  };

  toggleModalPopup = () => {
    const { isModalPopupOpen } = this.state;
    this.setState({ isModalPopupOpen: !isModalPopupOpen });
  };

  handleSetLoading = (value) => {
    this.setState({
      loading: value,
      filtersChanged: this.state.filtersChanged + 1,
    });
  };

  handlePopupItemClicked = (item) => {};

  render() {
    const isLoading = !this.props.companiesFetched || this.state.loading;
    return (
      <div className="main-container pensionskasse">
        <Header
          title="Firmen"
          locales={null}
          onFiltersButtonClick={() =>
            this.setState({ filtersOpened: !this.state.filtersOpened })
          }
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Large Modal"
          className={classNames("large-modal", {
            "modal-fade-out": this.state.modalFadeOut,
          })}
          overlayClassName={"large-modal-overlay"}
        >
          <ModalContent
            onClose={this.closeModal}
            modalContent={this.state.modalContent}
            modalContentId={this.state.modalContentId}
            companyData={this.state.companyData}
            companyDataLoaded={this.state.companyDataLoaded}
            companyInvoices={this.props.companyInvoices}
          />
        </Modal>
        <div className="main-content-container pensionskasse-companies">
          <div className="relative-container">
            {isLoading && <Spinner relative />}
            <div
              className="main-content-container-left pensionskasse"
              onScroll={this.handleScroll}
            >
              <CompaniesList
                companies={this.props.companies}
                fetched={this.props.companiesFetched}
                filtersChanged={this.state.filtersChanged}
                onDetailsClick={(id) =>
                  this.openModal(id, modalPageTypes.COMPANY_DETAILS)
                }
              />
            </div>
          </div>

          <div className="drawer" ref={(node) => (this.drawer = node)}>
            <Drawer opened={this.state.filtersOpened}>
              <Sidebar setLoading={(value) => this.handleSetLoading(value)} />
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    companies: state.companies.companies,
    companiesFetched: state.companies.companiesFetched,
    companyInvoices: state.companies.companyInvoices,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    fetchCompanyById: bindActionCreators(fetchCompanyById, dispatch),
    setCompaniesPageNo: (val) => dispatch(setCompaniesPageNo(val)),
    setCompaniesFilter: (val) => dispatch(setCompaniesFilter(val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Companies));
