import { authApiCaller } from "../../utils/api";
import { wrap } from "../index";
import * as constants from "../../constants";
const appUrl = window.location.origin;
// employee === insurant

export const passwordForgotInit = (username) =>
  wrap((dispatch) => {
    return authApiCaller("POST", `password-forgot/`, {
      username,
      url: `${appUrl}/login?password-reset=2&token=[token]`,
    })
      .then((response) => {
        dispatch({ type: constants.POST_SUCCESS, payload: response["data"] });
        return "OK";
      })
      .catch((err) => {
        throw err;
      });
  }, "POST_ERROR");

export const passwordReset = (password, token) =>
  wrap((dispatch) => {
    return authApiCaller("POST", `password-reset/`, { password, token })
      .then((response) => {
        dispatch({ type: constants.POST_SUCCESS, payload: response["data"] });
        return "OK";
      })
      .catch((err) => {
        throw err;
      });
  }, "POST_ERROR");

export const updateFieldValues = (payload) => {
  return { type: "UPDATE_ENTRY_FORM_VALUES", payload };
};

export const clearFieldValues = (payload) => {
  return { type: "CLEAR_ENTRY_FORM_VALUE", payload };
};
